var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"container"},[_c('div',{staticClass:"row py-5"},[_c('div',{staticClass:"col"},[_c('div',{staticClass:"card-b mb-4 p-5"},[_c('div',{staticClass:"option-btns"},[_c('button',{staticClass:"btn px-4 px-md-5",class:{ active: _vm.option === _vm.tabOptions.customizePages },style:({
              background:
                _vm.option === _vm.tabOptions.customizePages
                  ? _vm.$store.getters.theme[2]
                  : _vm.$store.getters.theme[0],
              color:
                _vm.option === _vm.tabOptions.customizePages ? '#ffffff' : '#999999'
            }),on:{"click":function($event){return _vm.changeTab(_vm.tabOptions.customizePages)}}},[_vm._v(" "+_vm._s(_vm.translation.customize_community.customize_pages)+" ")]),_c('button',{staticClass:"btn px-4 px-md-5",class:{ active: _vm.option === _vm.tabOptions.addMessage },style:({
              background:
                _vm.option === _vm.tabOptions.addMessage
                  ? _vm.$store.getters.theme[2]
                  : _vm.$store.getters.theme[0],
              color: _vm.option === _vm.tabOptions.addMessage ? '#ffffff' : '#999999'
            }),on:{"click":function($event){return _vm.changeTab(_vm.tabOptions.addMessage)}}},[_vm._v(" "+_vm._s(_vm.translation.customize_community.add_message)+" ")])]),_c('transition',{attrs:{"mode":"out-in"}},[(_vm.option === _vm.tabOptions.customizePages)?_c('customize-pages'):_vm._e(),(_vm.option === _vm.tabOptions.addMessage)?_c('add-message'):_vm._e()],1)],1)])])])}
var staticRenderFns = []

export { render, staticRenderFns }