<template>
  <div class="row align-items-center justify-content-between p-2 p-md-5 pt-4">
    <div class="col-md-6 order-md-1 order-2 d-flex pages mobile">
      {{ activeTaskIndex + 1 }}{{ !isSequenced ? `/${total}` : "" }}
    </div>
    <div class="col-md-6 order-md-2 order-1 d-flex justify-content-end">
      <b-button
        v-if="!+isSequenced"
        :disabled="activeTaskIndex >= total - 1"
        @click="skipTask"
        class="btn-task mr-2 save px-4 py-2"
      >
        {{ translation.pagination.skip }}
      </b-button>

      <b-button
        @click="prev"
        :disabled="activeTaskIndex <= 0"
        class="btn-task mr-2 text-white px-4 py-2"
        :style="{
          background: $store.getters.theme[2],
          'border-color': $store.getters.theme[2]
        }"
      >
        {{ translation.pagination.previous }}
      </b-button>

      <b-button
        @click="next"
        class="btn-task text-white px-4 py-2"
        :style="{
          background: $store.getters.theme[2],
          'border-color': $store.getters.theme[2]
        }"
      >
        {{
          is_answered ||
          $store.getters.info.user.type !== userTypesText.participant
            ? translation.pagination.continue
            : translation.pagination.save_and_continue
        }}
      </b-button>
    </div>
  </div>
</template>

<script>
import { userTypesText } from "@/constants";

export default {
  name: "PaginationTask",
  props: {
    total: {
      required: true
    },
    is_answered: {
      required: true
    },
    is_sequenced: {
      required: true
    },
    activity: {
      required: true
    }
  },
  data() {
    return {
      userTypesText
    };
  },
  computed: {
    activeTaskIndex() {
      return this.$store.getters.activeTaskIndex;
    },
    isParticipant() {
      return (
        this.$store.getters.info.user.type === this.userTypesText.participant
      );
    },
    isSequenced() {
      return +this.is_sequenced;
    },
    isPreview() {
      return this.$route.query._preview;
    }
  },
  methods: {
    skipTask() {
      if (this.activeTaskIndex < this.total - 1) {
        this.$store.commit(
          "UPDATE_ACTIVE_TASK_INDEX",
          this.activeTaskIndex + 1
        );
      }
    },
    prev() {
      if (this.activeTaskIndex > 0) {
        if (this.isPreview) this.$emit("deletePrevAnswer");
        else {
          this.$store.commit(
            "UPDATE_ACTIVE_TASK_INDEX",
            this.activeTaskIndex - 1
          );
        }
      }
    },
    next() {
      const notLastPage = this.activeTaskIndex < this.total - 1;

      if (this.isParticipant) {
        if (this.is_answered && notLastPage) {
          this.goToNext();
        } else if (!this.is_answered) {
          this.$emit("save");
        } else {
          this.$toast.success(
            this.translation.answer_task.success.assignment_completed
          );
          this.completeTask();
        }
      }

      if (!this.isParticipant) {
        if (this.isPreview && !this.is_answered) {
          this.$emit("savePreview");
        } else if (notLastPage) {
          this.goToNext();
        } else {
          this.completeTask();
        }
      }
    },
    goToNext() {
      this.$store.commit("UPDATE_ACTIVE_TASK_INDEX", this.activeTaskIndex + 1);
    },
    completeTask() {
      this.$router.push({ name: "feed" });
    }
  }
};
</script>

<style lang="scss" scoped>
.pages {
  color: #999999;
}

.btn-task {
  letter-spacing: 0.12em;
  text-transform: uppercase;
  font-size: 0.75rem;
  font-weight: bold;
  color: #6b6b6b;
  border-radius: 30px;
  min-width: 185px;
  white-space: nowrap;
  &.save {
    background: #fff;
    border: 1px solid #c8dada;
  }
}

@media (max-width: 991.98px) {
  .mobile {
    justify-content: flex-end;
    margin-top: 30px;
  }
  .btn-task {
    font-size: 0.6rem;
    white-space: unset;
    min-width: unset;
  }
}
</style>
