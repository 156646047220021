<template>
  <div>
    <div
      class="media-item p-3 m-3"
      :style="{ background: $store.getters.theme[0] }"
    >
      <get-task-data :task="activeMedia.fk_task_id" />

      <div v-if="results" class="modal-transcription">
        <video
          class="mt-md-4 ml-md-2"
          v-if="transcriptVideo.length"
          ref="videoTranscription"
          :src="transcriptVideo"
          @timeupdate="getCurrentTime"
          controls
        ></video>
        <div class="d-flex mt-md-0 mt-3">
          <router-link
            :to="{
              name: 'participant',
              query: { _id: activeMedia.participant.id, _page: 1 }
            }"
            class="participant-info"
          >
            <div
              :style="{
                'background-color': $store.getters.theme[1],
                color: $store.getters.theme[3]
              }"
            >
              <img
                v-if="activeMedia.participant.picture.length"
                :src="activeMedia.participant.picture"
                alt="Profile Picture"
              />
              <b-icon
                v-else
                class="icon-profile"
                icon="person-fill"
                aria-hidden="true"
              ></b-icon>
            </div>
            <p
              :style="{
                color: $store.getters.theme[3]
              }"
            >
              {{ activeMedia.participant.name.split(" ")[0] }}
            </p>
          </router-link>
          <div class="text">
            <div v-for="(result, rIndex) in results" :key="rIndex">
              <p
                v-for="(alternative, aIndex) in result.alternatives"
                :key="`${aIndex}${rIndex}`"
              >
                <span
                  :id="`span${rIndex}${aIndex}${wIndex}`"
                  :style="{
                    background: highlightWord(
                      word,
                      `span${rIndex}${aIndex}${wIndex}`
                    )
                      ? $store.getters.theme[2]
                      : '',
                    color: highlightWord(
                      word,
                      `span${rIndex}${aIndex}${wIndex}`
                    )
                      ? '#ffffff'
                      : '#6b6b6b'
                  }"
                  class="word"
                  v-for="(word, wIndex) in alternative.words"
                  :key="`${wIndex}${aIndex}`"
                >
                  {{ word.word }}
                </span>
              </p>
            </div>
          </div>
        </div>
      </div>
      <div v-else>
        <span>{{ translation.replies.this_video_doesnt }}</span>
      </div>
    </div>
    <div class="d-flex justify-content-end">
      <button
        @click="toggleDownload = !toggleDownload"
        :class="results ? 'hover' : ''"
        class=" btn mr-1 btn-export"
        :disabled="!results"
      >
        <b-icon icon="download" aria-hidden="true"></b-icon>
        <span>{{ translation.replies.export }}</span>
      </button>
    </div>
    <div :class="{ active: toggleDownload }" class="download pt-3 mx-4 mt-4">
      <div class="d-flex flex-wrap">
        <label class="m-0 mr-2">{{ translation.replies.timestamp }}:</label>
        <b-form-checkbox v-model="timestamp" switch>
          {{ timestamp ? translation.global.yes : translation.global.no }}
        </b-form-checkbox>
      </div>
      <div class="d-flex flex-wrap">
        <label class="m-0 mr-4">
          {{ translation.replies.choose_the_format }}:
        </label>
        <b-form-group v-slot="{ ariaDescribedby }">
          <b-form-radio
            v-model="format"
            :aria-describedby="ariaDescribedby"
            :value="exportMediaFormats.docx"
          >
            DOCX
          </b-form-radio>
          <b-form-radio
            v-model="format"
            :aria-describedby="ariaDescribedby"
            :value="exportMediaFormats.xlsx"
          >
            XLSX
          </b-form-radio>
        </b-form-group>
      </div>
      <div class="d-flex align-items-center">
        <button
          :style="{ background: $store.getters.theme[2] }"
          :disabled="loader || !results"
          :class="{ hover: !loader }"
          class="btn mb-3"
          @click="getVideoTranscriptFile"
        >
          <loader2 v-if="loader" color="#ffffff" />
          <p v-if="!loader" class="m-0">
            {{ translation.replies.export }}
          </p>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import getTaskData from "@/components/task/getTaskData.vue";
import { exportMediaFormats, exportMediaTimestamp } from "@/constants";
import { downloadFile } from "@/helpers";
import loader2 from "@/components/general/loader2.vue";

export default {
  props: {
    activeMedia: {
      requred: true
    },
    transcriptVideo: {
      requred: true
    },
    results: {
      requred: true
    },
    jobName: {
      requred: true
    }
  },
  components: {
    getTaskData,
    loader2
  },
  data() {
    return {
      currentTime: 0,
      format: "",
      timestamp: false,
      loader: false,
      toggleDownload: false,
      exportMediaFormats,
      exportMediaTimestamp
    };
  },
  methods: {
    downloadFile,
    highlightWord(word, elementId) {
      const startTime = +word.startTime.replace("s", "");
      const endTime = +word.endTime.replace("s", "");

      const isCurrentTIme =
        startTime <= this.currentTime && endTime >= this.currentTime;

      if (isCurrentTIme) this.scrollText(elementId);

      return isCurrentTIme;
    },
    scrollText(elementId) {
      const span = document.querySelector(`#${elementId}`);

      if (span) {
        span.scrollIntoView({
          behavior: "smooth",
          block: "end"
        });
      }
    },
    getCurrentTime({ target }) {
      this.currentTime = target.currentTime;
    },
    getVideoTranscriptFile() {
      this.loader = true;

      const timestamp = this.timestamp
        ? this.exportMediaTimestamp.yes
        : this.exportMediaTimestamp.no;

      axios
        .get(
          `${this.exportMediaHost}voices/transcript/file?jobName=${this.jobName}&type=${this.format}&timestamp=${timestamp}`,
          {
            headers: {
              Authorization: `Bearer ${this.$store.getters.token}`
            }
          }
        )
        .then(r => {
          if (r.status === 200) {
            this.timestamp = false;
            this.format = this.exportMediaFormats.docx;

            if (r.data.docxUrl) {
              const docxUrl = r.data.docxUrl.url;
              this.downloadFile(docxUrl);
            }

            if (r.data.xlsxUrl) {
              const xlsxUrl = r.data.xlsxUrl.url;
              this.downloadFile(xlsxUrl);
            }

            this.loader = false;
          }
        })
        .catch(() => {
          this.loader = false;
          this.$toast.error(
            this.translation.errors_global.something_went_wrong
          );
        });
    }
  },
  mounted() {
    this.format = this.exportMediaFormats.docx;
  }
};
</script>

<style lang="scss" scoped>
.media-item {
  border-radius: 10px;
  color: #6b6b6b;
}

.modal-transcription {
  display: flex;
  align-items: flex-start;
  video {
    width: 100%;
    max-width: 300px;
    margin-right: 60px;
    margin-top: 10px;
  }
  .participant-info {
    div {
      width: 50px;
      height: 50px;
      img {
        width: 50px;
        height: 50px;
      }
    }
    p {
      word-wrap: break-word;
      word-break: break-word;
    }
  }
  .text {
    height: 290px;
    overflow-y: auto;
    padding: 0 10px;
    p {
      margin-bottom: 0;
    }
    span {
      border-radius: 3px;
      transition: all 0.3s ease;
    }
    &::-webkit-scrollbar {
      width: 8px;
      overflow: hidden;
    }
    &::-webkit-scrollbar-track {
      background: rgba(0, 0, 0, 0);
      border-radius: 10px;
    }
    &::-webkit-scrollbar-thumb {
      background: #ccc;
      border-radius: 10px;
      height: 10px;
    }
    &::-webkit-scrollbar-thumb:hover {
      background: #aaa;
    }
  }
}

@media (max-width: 767.98px) {
  .modal-transcription {
    flex-direction: column;
  }
}

.participant-info {
  display: flex;
  flex-direction: column;
  align-items: center;
  div {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 60px;
    height: 60px;
    border-radius: 50%;
    overflow: hidden;
    img {
      object-fit: cover;
      height: 60px;
      width: 60px;
    }
  }
  p {
    font-weight: bold;
    font-size: 0.81rem;
    margin-top: 5px;
    width: 90px;
    text-align: center;
    margin-bottom: 0;
    word-wrap: break-word;
    word-break: break-word;
  }
}

.download {
  display: none;
  border-top: 1px solid #ccc;
  animation: show 0.3s linear;
  button {
    display: flex;
    justify-content: center;
    font-weight: bold;
    border-radius: 25px;
    padding: 5px 30px;
    color: #ffffff;
  }
  &.active {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }
}

.btn-export {
  color: #6b6b6b;
  span {
    margin-left: 8px;
    font-weight: bold;
  }
  &:focus {
    box-shadow: none;
  }
}
</style>
