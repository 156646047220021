var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.show)?_c('div',{ref:"cropperbg",staticClass:"cropper-bg"},[_c('div',{staticClass:"cropper-wrapper",attrs:{"id":"cropper-wrapper"}},[(_vm.width && _vm.height)?_c('cropper',{ref:"cropper",attrs:{"src":_vm.img,"auto-zoom":true,"stencil-size":{
        width: _vm.width,
        height: _vm.height
      },"image-restriction":"stencil"}}):_c('cropper',{ref:"cropper",attrs:{"src":_vm.img,"auto-zoom":true}}),_c('div',{staticClass:"btns"},[_c('button',{staticClass:"hover",style:({
          background: ("" + (_vm.$store.getters.theme ? _vm.$store.getters.theme[2] : '#108FC3'))
        }),on:{"click":_vm.trash}},[_c('b-icon',{attrs:{"icon":"trash"}})],1),_c('button',{staticClass:"hover",style:({
          background: ("" + (_vm.$store.getters.theme ? _vm.$store.getters.theme[2] : '#108FC3'))
        }),on:{"click":_vm.apply}},[(!_vm.loader)?_c('span',[_c('b-icon',{attrs:{"icon":"check"}})],1):_c('span',{staticClass:"d-flex justify-content-center"},[_c('loader2',{attrs:{"color":"#ffffff"}})],1)])])],1)]):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }