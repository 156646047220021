var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"container"},[_c('div',{staticClass:"row py-5"},[_c('div',{staticClass:"col"},[_c('div',{staticClass:"card-b mb-4 p-md-5 p-4"},[_c('div',{staticClass:"option-btns"},[_c('button',{staticClass:"btn px-4 px-md-5",class:{ active: _vm.option === _vm.tabOptions.privacy },style:({
              background:
                _vm.option === _vm.tabOptions.privacy
                  ? _vm.$store.getters.theme[2]
                  : _vm.$store.getters.theme[0],
              color: _vm.option === _vm.tabOptions.privacy ? '#ffffff' : '#999999'
            }),on:{"click":function($event){return _vm.changeTab(_vm.tabOptions.privacy)}}},[_vm._v(" "+_vm._s(_vm.translation.privacy_and_permissions.privacy)+" ")]),_c('button',{staticClass:"btn px-4 px-md-5",class:{ active: _vm.option === _vm.tabOptions.permissions },style:({
              background:
                _vm.option === _vm.tabOptions.permissions
                  ? _vm.$store.getters.theme[2]
                  : _vm.$store.getters.theme[0],
              color: _vm.option === _vm.tabOptions.permissions ? '#ffffff' : '#999999'
            }),on:{"click":function($event){return _vm.changeTab(_vm.tabOptions.permissions)}}},[_vm._v(" "+_vm._s(_vm.translation.privacy_and_permissions.permissions)+" ")])]),_c('transition',{attrs:{"mode":"out-in"}},[(_vm.option === _vm.tabOptions.privacy)?_c('privacy'):_vm._e(),(_vm.option === _vm.tabOptions.permissions)?_c('permissions'):_vm._e()],1)],1)])])])}
var staticRenderFns = []

export { render, staticRenderFns }