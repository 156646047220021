var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"media-item p-3 m-3",style:({ background: _vm.$store.getters.theme[0] })},[_c('get-task-data',{attrs:{"task":_vm.activeMedia.fk_task_id}}),(_vm.results)?_c('div',{staticClass:"modal-transcription"},[(_vm.transcriptVideo.length)?_c('video',{ref:"videoTranscription",staticClass:"mt-md-4 ml-md-2",attrs:{"src":_vm.transcriptVideo,"controls":""},on:{"timeupdate":_vm.getCurrentTime}}):_vm._e(),_c('div',{staticClass:"d-flex mt-md-0 mt-3"},[_c('router-link',{staticClass:"participant-info",attrs:{"to":{
            name: 'participant',
            query: { _id: _vm.activeMedia.participant.id, _page: 1 }
          }}},[_c('div',{style:({
              'background-color': _vm.$store.getters.theme[1],
              color: _vm.$store.getters.theme[3]
            })},[(_vm.activeMedia.participant.picture.length)?_c('img',{attrs:{"src":_vm.activeMedia.participant.picture,"alt":"Profile Picture"}}):_c('b-icon',{staticClass:"icon-profile",attrs:{"icon":"person-fill","aria-hidden":"true"}})],1),_c('p',{style:({
              color: _vm.$store.getters.theme[3]
            })},[_vm._v(" "+_vm._s(_vm.activeMedia.participant.name.split(" ")[0])+" ")])]),_c('div',{staticClass:"text"},_vm._l((_vm.results),function(result,rIndex){return _c('div',{key:rIndex},_vm._l((result.alternatives),function(alternative,aIndex){return _c('p',{key:("" + aIndex + rIndex)},_vm._l((alternative.words),function(word,wIndex){return _c('span',{key:("" + wIndex + aIndex),staticClass:"word",style:({
                  background: _vm.highlightWord(
                    word,
                    ("span" + rIndex + aIndex + wIndex)
                  )
                    ? _vm.$store.getters.theme[2]
                    : '',
                  color: _vm.highlightWord(
                    word,
                    ("span" + rIndex + aIndex + wIndex)
                  )
                    ? '#ffffff'
                    : '#6b6b6b'
                }),attrs:{"id":("span" + rIndex + aIndex + wIndex)}},[_vm._v(" "+_vm._s(word.word)+" ")])}),0)}),0)}),0)],1)]):_c('div',[_c('span',[_vm._v(_vm._s(_vm.translation.replies.this_video_doesnt))])])],1),_c('div',{staticClass:"d-flex justify-content-end"},[_c('button',{staticClass:" btn mr-1 btn-export",class:_vm.results ? 'hover' : '',attrs:{"disabled":!_vm.results},on:{"click":function($event){_vm.toggleDownload = !_vm.toggleDownload}}},[_c('b-icon',{attrs:{"icon":"download","aria-hidden":"true"}}),_c('span',[_vm._v(_vm._s(_vm.translation.replies.export))])],1)]),_c('div',{staticClass:"download pt-3 mx-4 mt-4",class:{ active: _vm.toggleDownload }},[_c('div',{staticClass:"d-flex flex-wrap"},[_c('label',{staticClass:"m-0 mr-2"},[_vm._v(_vm._s(_vm.translation.replies.timestamp)+":")]),_c('b-form-checkbox',{attrs:{"switch":""},model:{value:(_vm.timestamp),callback:function ($$v) {_vm.timestamp=$$v},expression:"timestamp"}},[_vm._v(" "+_vm._s(_vm.timestamp ? _vm.translation.global.yes : _vm.translation.global.no)+" ")])],1),_c('div',{staticClass:"d-flex flex-wrap"},[_c('label',{staticClass:"m-0 mr-4"},[_vm._v(" "+_vm._s(_vm.translation.replies.choose_the_format)+": ")]),_c('b-form-group',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var ariaDescribedby = ref.ariaDescribedby;
return [_c('b-form-radio',{attrs:{"aria-describedby":ariaDescribedby,"value":_vm.exportMediaFormats.docx},model:{value:(_vm.format),callback:function ($$v) {_vm.format=$$v},expression:"format"}},[_vm._v(" DOCX ")]),_c('b-form-radio',{attrs:{"aria-describedby":ariaDescribedby,"value":_vm.exportMediaFormats.xlsx},model:{value:(_vm.format),callback:function ($$v) {_vm.format=$$v},expression:"format"}},[_vm._v(" XLSX ")])]}}])})],1),_c('div',{staticClass:"d-flex align-items-center"},[_c('button',{staticClass:"btn mb-3",class:{ hover: !_vm.loader },style:({ background: _vm.$store.getters.theme[2] }),attrs:{"disabled":_vm.loader || !_vm.results},on:{"click":_vm.getVideoTranscriptFile}},[(_vm.loader)?_c('loader2',{attrs:{"color":"#ffffff"}}):_vm._e(),(!_vm.loader)?_c('p',{staticClass:"m-0"},[_vm._v(" "+_vm._s(_vm.translation.replies.export)+" ")]):_vm._e()],1)])])])}
var staticRenderFns = []

export { render, staticRenderFns }