<template>
  <div class="card-b p-3 mb-4 guide-card">
    <div v-if="!feedList && !isPreview && !loader" class="p-2 card-b-header">
      <h2
        class="h5 font-weight-bold mb-0"
        :style="{ color: $store.getters.theme[3] }"
      >
        {{ translation.community_guide.community_guide_text }}
      </h2>
    </div>

    <div v-if="!loader">
      <b-carousel
        id="carousel-1"
        :interval="isPreview ? 0 : 4000"
        :controls="isPreview && !feedList.length ? false : true"
        background="#fff"
        img-width="300"
        img-height="300"
        ref="myCarousel"
        class="guide-carousel"
        v-model="carousel"
        :key="keyCarousel"
      >
        <div
          class="control prev"
          :style="{ color: $store.getters.theme[2] }"
          @click="$refs.myCarousel.prev()"
        >
          <b-icon
            class="mr-2 h4"
            icon="chevron-left"
            aria-hidden="true"
          ></b-icon>
        </div>

        <div
          class="control next"
          :style="{ color: $store.getters.theme[2] }"
          @click="$refs.myCarousel.next()"
        >
          <b-icon
            class="ml-2 h4"
            icon="chevron-right"
            aria-hidden="true"
          ></b-icon>
        </div>

        <div v-if="!feedList && !isPreview">
          <b-carousel-slide img-blank img-blank-color="white">
            <div class="item-text d-flex">
              <span
                class="mt-1"
                :style="{ 'background-color': $store.getters.theme[2] }"
                >1</span
              >
              <p class="text-left small" style="color: #6b6b6b">
                {{ translation.community_guide.carrousel_text_1 }}
              </p>
            </div>

            <img class="img-carousel" src="@/img/community-guide-1.svg" />
          </b-carousel-slide>

          <b-carousel-slide img-blank img-blank-color="white">
            <div class="item-text d-flex">
              <span
                class="mt-1"
                :style="{ 'background-color': $store.getters.theme[2] }"
                >2</span
              >
              <p class="text-left small" style="color: #6b6b6b ">
                {{ translation.community_guide.carrousel_text_2 }}
              </p>
            </div>

            <img class="img-carousel" src="@/img/community-guide-2.svg" />
          </b-carousel-slide>

          <b-carousel-slide img-blank img-blank-color="white">
            <div class="item-text d-flex">
              <span
                class="mt-1"
                :style="{ 'background-color': $store.getters.theme[2] }"
                >3</span
              >
              <p class="text-left small" style="color: #6b6b6b">
                {{ translation.community_guide.carrousel_text_3 }}
              </p>
            </div>

            <img class="img-carousel" src="@/img/community-guide-3.svg" />
          </b-carousel-slide>
        </div>

        <div v-else-if="feedList && feedList.length">
          <b-carousel-slide
            img-blank
            img-blank-color="white"
            class="b-carousel-slide"
            :class="{ isPreview: isPreview }"
            v-for="(data, index) in feedList"
            :key="data.id"
            :id="`carousel-slide-${index}`"
          >
            <div class="pb-2 card-b-header">
              <h2
                class="h5 font-weight-bold mb-0 slider-title"
                :class="{
                  isPreview: isPreview
                }"
                :style="{ color: $store.getters.theme[3] }"
              >
                {{
                  data.title.length
                    ? data.title
                    : translation.community_guide.ex_message_title
                }}
              </h2>
            </div>

            <div
              class="item-text d-flex mt-3"
              :class="{
                isPreview: isPreview
              }"
            >
              <span :style="{ 'background-color': $store.getters.theme[2] }">
                {{ index + 1 }}
              </span>

              <p class="text-left small slider-text" style="color: #6b6b6b">
                {{
                  data.text.length
                    ? data.text
                    : translation.community_guide.ex_message_support_text
                }}
              </p>
            </div>

            <div
              v-if="(data.img.length && !imgLoaded) || loadingImg"
              class="loader-container d-flex align-items-center justify-content-center"
            >
              <loader2
                :color="$store.getters.theme[2] || '#6b6b6b'"
                class="mx-auto"
              />
            </div>

            <img
              class="img-carousel img-dynamic"
              :src="data.img"
              v-if="data.img.length && !loadingImg"
              @load="imgLoaded = true"
              :class="{ imgIsLoaded: imgLoaded }"
              :key="data.img"
            />

            <p
              v-else-if="!data.img.length && !loadingImg"
              class="add-a-message add-an-image"
            >
              {{ translation.community_guide.add_an_image }}
            </p>
          </b-carousel-slide>
        </div>

        <p class="add-a-message">
          {{ translation.community_guide.add_an_message }}
        </p>
      </b-carousel>
    </div>

    <loader
      v-else
      :color="$store.getters.theme[2] || '#6b6b6b'"
      class="mx-auto"
    />
  </div>
</template>

<script>
import loader2 from "@/components/general/loader2.vue";
import loader from "@/components/general/loader.vue";
import { api } from "@/services.js";
import { v4 as uuidv4 } from "uuid";

export default {
  name: "Guide",

  components: { loader2, loader },

  props: {
    feedPreviewData: {},
    isPreview: {},
    selectedMessage: {},
    loadingImg: {}
  },

  data() {
    return {
      feedList: [],
      carousel: null,
      keyCarousel: 0,
      imgLoaded: false,
      loader: false
    };
  },

  watch: {
    feedPreviewData() {
      if (!this.feedPreviewData.length) return;

      this.feedList = this.feedPreviewData.filter(data => !data.show);
      this.keyCarousel++;
    },

    selectedMessage() {
      if (!this.selectedMessage?.length && !this.isPreview) return;

      const message = this.feedList.find(
        guide => guide.id === this.selectedMessage
      );

      if (!message) return;

      this.carousel = this.feedList.indexOf(message);
    },

    carousel() {
      if (this.isPreview) return;

      setTimeout(() => {
        this.resizeCarousel();
      }, 150);
    }
  },

  methods: {
    resizeCarousel(index = this.carousel) {
      const height = document.getElementsByClassName("carousel-caption")[index]
        .clientHeight;

      const carouselSlide = document.getElementById(`carousel-slide-${index}`);

      carouselSlide.style.height = height + 15 + "px";
    },

    async fetchFeed() {
      try {
        this.loader = true;

        const response = await api.get(
          `/community/${this.$store.getters.community.id}/feed`,
          {
            headers: {
              Authorization: `Bearer ${this.$store.getters.token}`
            }
          }
        );

        const { data, status } = response;

        if (status === 200) {
          if (data?.length) {
            this.feedList = response.data
              .map(item => ({
                ...item,
                id: uuidv4(),
                show: item.show ? false : true
              }))
              .filter(data => !data.show);

            setTimeout(() => {
              this.resizeCarousel(0);
            }, 200);
          }
        }
      } catch (error) {
        const { status } = error.response;

        if (status === 404) {
          this.feedList = this.defaultFeed;

          return;
        }

        this.$toast.error(this.translation.errors_global.something_went_wrong);
      } finally {
        this.loader = false;
      }
    }
  },

  created() {
    this.feedList = this.feedPreviewData?.length
      ? this.feedPreviewData.filter(data => !data.show)
      : this.fetchFeed();
  },

  mounted() {}
};
</script>

<style lang="scss" scoped>
.guide-card {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.img-carousel {
  min-height: 110px;
  max-height: 110px;

  &.img-dynamic {
    display: none;

    &.imgIsLoaded {
      display: unset;
    }
  }
}

.b-carousel-slide {
  &.isPreview {
    height: 290px;
  }
}

.slider-title {
  display: block;
  align-items: center;
  align-self: flex-start;

  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  line-clamp: 2;
  -webkit-box-orient: vertical;
  word-break: normal;

  text-align: start;

  max-height: 48px;
  height: 100%;

  &.isPreview {
    height: 48px;
  }
}

.slider-text {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 4;
  line-clamp: 4;
  -webkit-box-orient: vertical;
  word-break: normal;
}

.item-text {
  max-height: 90px;
  min-height: 60px;
  height: 100%;

  span {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 4px 8px;
    border-radius: 3px;
    width: 25px;
    height: 25px;
    margin-right: 10px;
    border-radius: 50%;
  }

  &.isPreview {
    height: 90px;
  }
}

#carousel-1 {
  position: relative;
}

.control {
  position: absolute;
  top: 150px;
  z-index: 5;
  cursor: pointer;
  &.prev {
    left: 0;
  }

  &.next {
    right: 0;
  }
}

.add-a-message {
  margin: 0;

  color: #999999;
  font-weight: bold;
  text-align: center;
}

.add-an-image {
  height: 110px;
}

.loader-container {
  height: 110px;
}
</style>
