<template>
  <div class="row task-wrapper">
    <div v-if="!loading" class="col">
      <div class="task-text img-question">
        <p v-html="text"></p>
      </div>
      <div class="d-flex flex-wrap">
        <attachment :attachments="task.attachments" :deleteFile="null" />
      </div>
      <div class="d-flex justify-content-center py-4">
        <div
          class="select-box"
          :style="{ background: $store.getters.theme[1] }"
        >
          <p class="mb-1">
            {{ translation.answer_task.reaction.minimum_number }}: {{ min }}
          </p>
          <p class="mb-0">
            {{ translation.answer_task.reaction.maximum_number }}: {{ max }}
          </p>
        </div>
      </div>
      <div>
        <div class="image-wrapper py-4">
          <div v-if="disabled" class="disabled"></div>
          <div
            :style="{ width: `${imageWidth}px`, height: `${imageHeight}px` }"
            ref="image"
            class="image"
            @mouseleave="handleMouseLeave"
          >
            <img
              v-if="src"
              :style="{ width: `${imageWidth}px`, height: `${imageHeight}px` }"
              @click="setReactionPosition"
              :src="src"
            />
            <div
              class="reaction-icon"
              v-for="(option, index) in reactions"
              :key="index"
              :style="{
                position: 'absolute',
                top: `${option.clickMetadata.y - 15}px`,
                left: `${option.clickMetadata.x - 15}px`
              }"
              @click="editReaction(option, index)"
            >
              {{ option.icon }}
            </div>
            <div
              :class="{ active: showDrop }"
              :style="{
                position: 'absolute',
                top: modalTopPosition,
                left: modalLeftPosition
              }"
              class="dropdown-reaction"
            >
              <span v-if="!editingReaction" class="dot"></span>
              <div
                :class="{
                  right: imageWidth - modalLeft < 200,
                  top: imageHeight - modalTop < 200 && modalTop > 120
                }"
                class="box"
              >
                <transition mode="out-in">
                  <div
                    class="select-reaction"
                    v-if="dropStep === tabOptions.selectReaction"
                  >
                    <button
                      class="btn"
                      v-for="(option, index) in options"
                      :key="index"
                      @click="selectOption(option)"
                    >
                      <span>
                        {{ option.icon }}
                      </span>
                      <span>
                        {{ option.label }}
                      </span>
                    </button>
                  </div>
                  <div
                    class="fill-comment"
                    v-if="dropStep === tabOptions.fillComment"
                  >
                    <b-form-textarea
                      style="overflow:hidden"
                      id="comment-temp"
                      :placeholder="translation.answer_task.comments"
                      v-model="commentTemp"
                    ></b-form-textarea>
                    <div class="pt-2">
                      <button @click="deleteReaction" class="btn mr-2">
                        <b-icon icon="x"></b-icon>
                      </button>
                      <button @click="addReaction" class="btn">
                        <b-icon icon="check"></b-icon>
                      </button>
                    </div>
                  </div>
                </transition>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        class="d-flex align-items-center my-4"
        v-for="(option, index) in reactions"
        :key="index"
      >
        <div
          :style="{
            backgroundImage: bgUrl,
            backgroundSize: `${imageWidth}px ${imageHeight}px`,
            backgroundPosition: `${(option.clickMetadata.x - 65) *
              -1}px ${(option.clickMetadata.y - 65) * -1}px`
          }"
          class="crop-image"
          :data-icon="option.icon"
        />
        <div class="break-label">
          <p class="title mb-1">
            {{ translation.task.reaction_type }} {{ index + 1 }}
          </p>
          <p>
            {{ option.label }}
          </p>
          <b-form-textarea
            :disabled="task.is_answered"
            id="comment"
            :placeholder="translation.answer_task.comments"
            v-model="option.comment"
          ></b-form-textarea>
        </div>
        <button
          :disabled="task.is_answered"
          @click="removeOption(index)"
          class="btn btn-remove"
        >
          <b-icon icon="x"></b-icon>
        </button>
      </div>
      <div class="comments mt-4">
        <label
          for="comments"
          v-if="
            task.question_object.has_custom_comment_label &&
              task.question_object.comment_label &&
              task.question_object.comment_label.length
          "
          >{{ task.question_object.comment_label }}</label
        >
        <label for="comments" v-else>
          {{ translation.answer_task.comments }}
        </label>
        <textarea
          class="form-control"
          id="textarea"
          v-model="comments"
          :maxlength="maxCharacters"
          rows="3"
          max-rows="6"
          :disabled="task.is_answered"
        ></textarea>
        <characterAndWordCounter
          :count="
            hasWordsLimitation
              ? this.comments.length
                ? this.comments.trim().split(/\s+/).length
                : 0
              : comments.length
          "
          :max="hasWordsLimitation ? maxWords : maxCharacters"
          :label="
            hasWordsLimitation
              ? translation.answer_task.words
              : translation.answer_task.characters
          "
        />
      </div>
    </div>
    <div v-if="loading" class="w-100 d-flex justify-content-center">
      <loader />
    </div>
  </div>
</template>

<script>
import { api } from "@/services.js";
import loader from "@/components/general/loader.vue";
import attachment from "@/components/general/attachment.vue";
import characterAndWordCounter from "@/components/general/characterAndWordCounter.vue";
import { tabOptions } from "@/constants";
import { outsideClick } from "@/helpers.js";

import { userTypesText } from "@/constants";

export default {
  components: { loader, attachment, characterAndWordCounter },
  props: {
    task: {
      required: true
    },
    save: {
      required: true
    },
    savePreview: {
      required: true
    },
    getTasks: {
      required: true
    }
  },
  data() {
    return {
      tabOptions,
      outsideClick,
      question: "",
      loading: false,
      showDrop: false,
      modalTop: 0,
      modalLeft: 0,
      dropStep: 0,
      reactions: [],
      reactionTemp: {},
      commentTemp: "",
      editedReaction: 0,
      comments: "",
      disabled: false
    };
  },
  computed: {
    text() {
      const regexpVideo = /(?:(<iframe.*?src="))(.*?)((?:".*?>))((?:<\/iframe>))?/g;

      return this.$store.getters.info.user.type === userTypesText.participant
        ? this.task.raw_question.replace(
            regexpVideo,
            "<video src='$2' width='350' height='197' frameborder='0' allowfullscreen='allowfullscreen' controls controlsList='nodownload'></iframe>"
          )
        : this.task.raw_question;
    },
    page() {
      return this.$store.getters.activeTaskIndex;
    },
    modalTopPosition() {
      return `${this.modalTop - 15}px`;
    },
    modalLeftPosition() {
      return `${this.modalLeft - 15}px`;
    },
    src() {
      return this.task.question_object.image;
    },
    bgUrl() {
      return `url(${this.src})`;
    },
    options() {
      return this.task.question_object.options;
    },
    imageWidth() {
      return this.task.question_object.image_width;
    },
    imageHeight() {
      return this.task.question_object.image_height;
    },
    min() {
      return this.task.question_object.min_answers
        ? this.task.question_object.min_answers
        : 1;
    },
    max() {
      return this.task.question_object.max_answers
        ? this.task.question_object.max_answers
        : 10;
    },
    mandatoryComment() {
      return this.task.question_object.mandatory_comment;
    },
    editingReaction() {
      const filter = this.reactions.filter(
        reaction =>
          reaction.clickMetadata.y === this.modalTop &&
          reaction.clickMetadata.x === this.modalLeft
      );
      return filter.length;
    },
    obligatoryComment() {
      return this.task.question_object?.has_obligatory_comments;
    },

    hasCharactersLimitation() {
      return this.task.question_object?.has_characters_limitation;
    },

    maxCharacters() {
      return this.hasCharactersLimitation
        ? this.task.question_object?.characters_count?.maximum
        : 5000;
    },

    minCharacters() {
      return this.hasCharactersLimitation
        ? this.task.question_object?.characters_count?.minimum
        : 0;
    },

    hasWordsLimitation() {
      return this.task.question_object?.has_words_limitation;
    },

    maxWords() {
      return this.hasWordsLimitation
        ? this.task.question_object?.words_count?.maximum
        : 2000;
    },

    minWords() {
      return this.hasWordsLimitation
        ? this.task.question_object?.words_count?.minimum
        : 0;
    }
  },
  watch: {
    save() {
      this.answer();
    },
    savePreview() {
      this.answerPreview();
    },
    page() {
      this.init();
    },
    comments() {
      if (this.comments.length >= this.maxCharacters) {
        this.comments = this.comments
          .split("")
          .splice(0, this.maxCharacters)
          .join("");
      }

      const words = this.comments.split(/\s+/);

      const wordsCount = words.length;

      if (wordsCount > this.maxWords) {
        this.comments = words.splice(0, this.maxWords).join(" ");
      }
    }
  },
  methods: {
    handleMouseLeave() {
      this.showDrop = false;
      this.dropStep = this.tabOptions.selectReaction;
    },
    removeOption(index) {
      this.reactions.splice(index, 1);
    },
    editReaction(option, index) {
      this.modalTop = option.clickMetadata.y;
      this.modalLeft = option.clickMetadata.x;
      this.showDrop = true;
      this.commentTemp = option.comment;
      this.editedReaction = index;
    },
    deleteReaction() {
      if (this.editingReaction) {
        this.reactions.splice(this.editedReaction, 1);
      }
      this.showDrop = false;
      this.dropStep = this.tabOptions.selectReaction;
    },
    addReaction() {
      if (!this.mandatoryComment || this.commentTemp.length) {
        if (!this.editingReaction) {
          this.reactions.push({
            icon: this.reactionTemp.icon,
            label: this.reactionTemp.label,
            clickMetadata: {
              x: this.modalLeft,
              y: this.modalTop
            },
            comment: this.commentTemp
          });
        } else {
          this.reactions[this.editedReaction].icon = this.reactionTemp.icon;
          this.reactions[this.editedReaction].label = this.reactionTemp.label;
          this.reactions[this.editedReaction].comment = this.commentTemp;
        }

        this.commentTemp = "";
        this.showDrop = false;
        this.dropStep = this.tabOptions.selectReaction;
      } else {
        this.$toast.error(this.translation.answer_task.error.empty_answer);
      }
    },
    selectOption(option) {
      this.reactionTemp = option;
      this.dropStep = this.tabOptions.fillComment;
    },
    setReactionPosition(e) {
      this.modalTop = e.layerY;
      this.modalLeft = e.layerX;
      this.showDrop = true;
      this.commentTemp = "";
    },
    answerPreview() {
      const missingComments = this.reactions
        .map((reaction, index) => ({ ...reaction, index }))
        .filter(reaction => !reaction.comment.length);

      if (!this.loading) {
        if (this.reactions.length) {
          if (this.reactions.length >= this.min) {
            if (this.reactions.length <= this.max) {
              if (!missingComments.length || !this.mandatoryComment) {
                if (this.obligatoryComment && !this.comments.length) {
                  this.$toast.error(
                    this.translation.answer_task.obligatory_comment_tost
                  );

                  return;
                }

                if (
                  this.comments.length &&
                  this.hasCharactersLimitation &&
                  this.comments.length < this.minCharacters
                ) {
                  this.$toast.error(
                    `${this.translation.answer_task.min_characters} ${this.minCharacters} ${this.translation.answer_task.characters}`
                  );

                  return;
                }

                if (this.comments.length && this.hasWordsLimitation) {
                  const wordsCount = this.comments.split(/\s+/).length;

                  if (wordsCount < this.minWords) {
                    this.$toast.error(
                      `${this.translation.answer_task.min_word} ${this.minWords} ${this.translation.answer_task.words}`
                    );

                    return;
                  }
                }

                this.loading = true;

                const answer = {
                  comment: this.comments,
                  image: this.src,
                  image_width: this.imageWidth,
                  image_height: this.imageHeight,
                  options: this.reactions.map((reaction, index) => ({
                    ...reaction,
                    index
                  }))
                };

                const body = {
                  task_id: this.task.id,
                  has_attachment: 0,
                  has_video: 0,
                  has_image: 0,
                  raw_answer: JSON.stringify(answer),
                  answer
                };

                api
                  .post("/answer/send/preview", body, {
                    headers: {
                      "Content-Type": "application/json",
                      Authorization: `Bearer ${this.$store.getters.token}`
                    }
                  })
                  .then(r => {
                    if (r.status === 200) {
                      this.$toast.success(
                        this.translation.answer_task.success.reply_registered
                      );
                      this.getTasks("save");
                      this.loading = false;
                    }
                  })
                  .catch(() => {
                    this.$toast.error(
                      this.translation.answer_task.error.reply_not_registered
                    );
                    this.loading = false;
                  });
              } else {
                let labels = "";
                missingComments.forEach(
                  (comment, index) =>
                    (labels +=
                      index === missingComments.length - 1
                        ? ` ${
                            this.translation.task.reaction_type
                          } ${comment.index + 1}.`
                        : ` ${
                            this.translation.task.reaction_type
                          } ${comment.index + 1},`)
                );
                this.$toast.error(
                  `${this.translation.answer_task.error.missing_comments}: ${labels}`
                );
              }
            } else {
              this.$toast.error(
                `${this.translation.answer_task.reaction.maximum_number}: ${this.max}`
              );
            }
          } else {
            this.$toast.error(
              `${this.translation.answer_task.reaction.minimum_number}: ${this.min}`
            );
          }
        } else {
          this.$toast.error(this.translation.answer_task.error.empty_answer);
        }
      }
    },
    answer() {
      const missingComments = this.reactions
        .map((reaction, index) => ({ ...reaction, index }))
        .filter(reaction => !reaction.comment.length);

      if (!this.loading) {
        if (this.reactions.length) {
          if (this.reactions.length >= this.min) {
            if (this.reactions.length <= this.max) {
              if (!missingComments.length || !this.mandatoryComment) {
                if (this.obligatoryComment && !this.comments.length) {
                  this.$toast.error(
                    this.translation.answer_task.obligatory_comment_tost
                  );

                  return;
                }

                if (
                  this.comments.length &&
                  this.hasCharactersLimitation &&
                  this.comments.length < this.minCharacters
                ) {
                  this.$toast.error(
                    `${this.translation.answer_task.min_characters} ${this.minCharacters} ${this.translation.answer_task.characters}`
                  );

                  return;
                }

                if (this.comments.length && this.hasWordsLimitation) {
                  const wordsCount = this.comments.split(/\s+/).length;

                  if (wordsCount < this.minWords) {
                    this.$toast.error(
                      `${this.translation.answer_task.min_word} ${this.minWords} ${this.translation.answer_task.words}`
                    );

                    return;
                  }
                }

                this.loading = true;

                const answer = {
                  comment: this.comments,
                  image: this.src,
                  image_width: this.imageWidth,
                  image_height: this.imageHeight,
                  options: this.reactions.map((reaction, index) => ({
                    ...reaction,
                    index
                  }))
                };

                const body = {
                  task_id: this.task.id,
                  participant_id: this.$store.getters.info.user.sub,
                  has_attachment: 0,
                  has_video: 0,
                  has_image: 0,
                  raw_answer: JSON.stringify(answer),
                  answer
                };

                api
                  .post("/answer/send", body, {
                    headers: {
                      "Content-Type": "application/json",
                      Authorization: `Bearer ${this.$store.getters.token}`
                    }
                  })
                  .then(r => {
                    if (r.status === 200) {
                      this.$toast.success(
                        this.translation.answer_task.success.reply_registered
                      );
                      this.getTasks("save");
                      this.loading = false;
                    }
                  })
                  .catch(() => {
                    this.$toast.error(
                      this.translation.answer_task.error.reply_not_registered
                    );
                    this.loading = false;
                  });
              } else {
                let labels = "";
                missingComments.forEach(
                  (comment, index) =>
                    (labels +=
                      index === missingComments.length - 1
                        ? ` ${
                            this.translation.task.reaction_type
                          } ${comment.index + 1}.`
                        : ` ${
                            this.translation.task.reaction_type
                          } ${comment.index + 1},`)
                );
                this.$toast.error(
                  `${this.translation.answer_task.error.missing_comments}: ${labels}`
                );
              }
            } else {
              this.$toast.error(
                `${this.translation.answer_task.reaction.maximum_number}: ${this.max}`
              );
            }
          } else {
            this.$toast.error(
              `${this.translation.answer_task.reaction.minimum_number}: ${this.min}`
            );
          }
        } else {
          this.$toast.error(this.translation.answer_task.error.empty_answer);
        }
      }
    },
    init() {
      if (this.task.is_answered) {
        this.comments = this.task.answer.answer_object.comment;
        this.reactions = this.task.answer.answer_object.options;
        this.showDrop = false;
        this.dropStep = this.tabOptions.selectReaction;
        this.disabled = true;
      } else if (this.task.answer && this.$route.query._preview) {
        this.comments = this.task.answer.answer_object.comment;
        this.reactions = this.task.answer.answer_object.options;
        this.showDrop = false;
        this.dropStep = this.tabOptions.selectReaction;
      } else {
        this.comments = "";
        this.disabled = false;
        this.showDrop = false;
        this.dropStep = this.tabOptions.selectReaction;
        this.reactions = [];
      }
    }
  },
  created() {
    this.init();
  },
  mounted() {
    if (this.$store.getters.info.user.type === userTypesText.participant) {
      const taskWrapper = document.querySelector(".task-wrapper");

      taskWrapper.addEventListener("contextmenu", e => {
        e.preventDefault();
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.task-text {
  display: flex;
  flex-direction: column;
  color: #6b6b6b;
  overflow-wrap: break-word;
}

.comments {
  label {
    color: #6b6b6b;
  }
  textarea {
    width: 100%;
    display: block;
    border: 1px solid #b3b3b3;
    border-radius: 10px;
  }
}

.image-wrapper {
  overflow-x: auto;
  display: flex;
  justify-content: center;
  position: relative;
  &::-webkit-scrollbar {
    height: 8px;
    width: 8px;
    overflow: hidden;
  }
  &::-webkit-scrollbar-track {
    background: rgba(0, 0, 0, 0.1);
    border-radius: 10px;
  }
  &::-webkit-scrollbar-thumb {
    background: #ccc;
    border-radius: 10px;
    height: 10px;
  }
  &::-webkit-scrollbar-thumb:hover {
    background: #ccc;
  }
}

@media (max-width: 991.98px) {
  .image-wrapper {
    justify-content: flex-start;
  }
}

.image {
  border-radius: 20px;
  position: relative;
  cursor: pointer;
  img {
    border-radius: 20px;
    object-fit: cover;
  }
}

.reaction-icon {
  font-size: 1.5rem;
  &:hover {
    transform: scale(1.1);
  }
}

.dropdown-reaction {
  display: none;
  animation: show 0.3s linear;
  transition: all 0.3s ease;
  position: relative;
  .box {
    background: #fff;
    border-radius: 10px;
    padding: 10px 5px 0px;
    width: 200px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
    position: absolute;
    &.right {
      right: 0px;
    }
    &.top {
      top: -120px;
    }

    div {
      max-height: 130px;
      overflow-y: auto;
      padding: 5px 10px 5px 5px;

      &::-webkit-scrollbar {
        width: 8px;
      }
      &::-webkit-scrollbar-track {
        background: rgba(0, 0, 0, 0);
        border-radius: 10px;
      }
      &::-webkit-scrollbar-thumb {
        background: #e6e6e6;
        border-radius: 10px;
        height: 40px;
      }
      &::-webkit-scrollbar-thumb:hover {
        background: #ffffff;
      }
    }
  }

  .dot {
    display: block;
    background: red;
    width: 1.5rem;
    height: 1.5rem;
    border-radius: 50%;
    margin: 5px;
    background: rgba(255, 255, 255, 0.3);
    border: 3px solid #fff;
  }

  &.active {
    display: block;
  }
}

.select-reaction {
  button {
    text-align: left;
    font-size: 0.9rem;
    color: #6b6b6b;
    display: block;
    width: 100%;
    margin-bottom: 5px;
    word-wrap: break-word;
    word-break: break-word;
    span {
      &:nth-of-type(1) {
        font-size: 1.2rem;
      }
    }
    &:hover {
      background: rgba(0, 0, 0, 0.1);
    }
  }
}

.fill-comment {
  div {
    display: flex;
    justify-content: flex-end;
  }
  textarea {
    height: 77px;
  }
  button {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 30px;
    height: 30px;
    font-weight: bold;
    color: #fff;
    font-size: 1rem;
    border-radius: 50%;
    white-space: nowrap;
    background: #10c398;
    &:nth-of-type(1) {
      background: #df2938;
    }
  }
}

.crop-image {
  background-color: #f9f9f9;
  background-repeat: no-repeat;
  border: 1px solid #ccc;
  float: left;
  height: 128px;
  margin-right: 20px;
  position: relative;
  transition: background-position 0.3s ease-in-out;
  width: 128px;
  display: flex;
  justify-content: center;
  align-items: center;
  &::before {
    content: attr(data-icon);
    display: block;
    font-size: 28px;
  }
}

.break-label {
  p {
    color: #6b6b6b;
    word-wrap: break-word;
    word-break: break-word;
  }
  .title {
    font-weight: bold;
  }
}

.disabled {
  position: absolute;
  width: 100%;
  height: 100%;
  background: #fff;
  opacity: 0.3;
  z-index: 2;
}

.btn-remove {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 30px;
  height: 30px;
  font-weight: bold;
  color: #fff;
  font-size: 1rem;
  border-radius: 50%;
  white-space: nowrap;
  background: #df2938;
  align-self: flex-start;
  margin-left: 10px;
}

.select-box {
  display: inline-block;
  padding: 20px;
  border-radius: 20px;
  color: #6b6b6b;
  font-weight: bold;
  font-size: 1.2rem;
}
</style>
