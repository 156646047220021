import { render, staticRenderFns } from "./paginationTask.vue?vue&type=template&id=41f76c66&scoped=true&"
import script from "./paginationTask.vue?vue&type=script&lang=js&"
export * from "./paginationTask.vue?vue&type=script&lang=js&"
import style0 from "./paginationTask.vue?vue&type=style&index=0&id=41f76c66&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "41f76c66",
  null
  
)

export default component.exports