<template>
  <div class="editor-tinymce">
    <div :class="{ active: imageMessage }" class="error-box bg-danger p-2 my-3">
      <b-icon class="mr-1" icon="exclamation-triangle-fill"></b-icon>
      {{ imageMessage }}
    </div>
    <div :class="{ active: videoMessage }" class="error-box bg-danger p-2 my-3">
      <b-icon class="mr-1" icon="exclamation-triangle-fill"></b-icon>
      {{ videoMessage }}
    </div>
    <div v-if="loader" class="d-flex justify-content-center py-5">
      <loader />
    </div>
    <editor
      :tinymce-script-src="this.tinySelfHostedUrl"
      ref="tinymce"
      v-model="content"
      :init="{
        height: 350,
        menubar: false,
        placeholder: placeholder,
        relative_urls: false,
        remove_script_host: false,
        paste_as_text: true,
        content_style: 'pre { white-space: pre-wrap; }',
        plugins: [
          'advlist autolink lists link image media charmap print preview anchor',
          'searchreplace visualblocks code fullscreen emoticons',
          'insertdatetime media table paste code help wordcount'
        ],
        toolbar:
          'undo redo | formatselect | bold italic backcolor forecolor | \
           alignleft aligncenter alignright alignjustify | \
           bullist numlist outdent indent | emoticons | removeformat | help',
        setup: editor => {
          editor.on('init', e => {
            loader = false;
          });
        }
      }"
    />

    <div class="d-flex justify-content-end align-items-center">
      <characterAndWordCounter
        v-if="isTextTask"
        :count="
          hasWordsLimitation
            ? characterCount
              ? wordsCount
              : 0
            : characterCount
        "
        :max="hasWordsLimitation ? maxWords : maxCharacters"
        :label="
          hasWordsLimitation
            ? translation.answer_task.words
            : translation.answer_task.characters
        "
      />

      <character-counter
        v-else
        :max="maxCharacters"
        :min="minCharacters"
        :count="characterCount"
      />
      <div class="credits ml-3">
        Powered by Tiny
      </div>
    </div>
  </div>
</template>

<script>
import { api } from "@/services.js";
import characterCounter from "@/components/general/characterCounter.vue";
import characterAndWordCounter from "@/components/general/characterAndWordCounter.vue";
import editor from "@tinymce/tinymce-vue";
import loader from "@/components/general/loader.vue";
import { inputHtml, regexpClearTags } from "@/constants";
import { mapState } from "vuex";

export default {
  props: {
    text: {
      required: true
    },
    placeholder: {
      required: true
    },
    hasCharactersLimitation: {},
    maxCharacters: {
      required: true
    },
    minCharacters: {
      default: 0
    },
    hasWordsLimitation: {},
    maxWords: {},
    minWords: {},
    isTextTask: {},
    blank: {}
  },
  components: {
    editor,
    characterCounter,
    characterAndWordCounter,
    loader
  },
  data() {
    return {
      content: "",
      characterCount: 0,
      loader: true,
      inputHtml,
      regexpClearTags
    };
  },
  computed: {
    ...mapState(["img", "video", "imageUploaderFolder", "videoUploaderFolder"]),
    imgCount() {
      const re = /<img\s+[^>]*src="([^"]*)"[^>]*>/g;
      let count = 0;

      while (re.exec(this.content) !== null) {
        ++count;
      }

      return count;
    },
    imageMessage() {
      let msg = null;
      if (this.imgCount > 10)
        msg = this.translation.errors_global.limit_exceeded_images;
      return msg;
    },
    videoCount() {
      const re = /(?:<iframe[^>]*)(?:(?:\/>)|(?:>.*?<\/iframe>))/g;
      let count = 0;

      while (re.exec(this.content) !== null) {
        ++count;
      }

      return count;
    },
    videoMessage() {
      let msg = null;
      if (this.videoCount > 5)
        msg = this.translation.errors_global.limit_exceeded_video;
      return msg;
    },
    wordsCount() {
      return this.text
        .replace(this.regexpClearTags, "")
        .trim()
        .split(/\s+/).length;
    }
  },
  watch: {
    imageMessage() {
      this.$emit("imageError", this.imageMessage);
    },
    videoMessage() {
      this.$emit("videoError", this.videoMessage);
    },
    img() {
      if (this.img) this.insertImage();
      else this.$store.commit("UPDATE_IMG", null);
    },
    video() {
      if (this.video) this.insertVideo();
      else this.$store.commit("UPDATE_VIDEO", null);
    },
    content() {
      this.$emit("update", this.content);
      this.updateCharactersCount();
    },
    text() {
      this.content = this.text;
      this.updateCharactersCount();
    },
    blank() {
      this.$refs.tinymce.editor.selection.setContent(this.inputHtml);
      this.$refs.tinymce.editor.focus();
    }
  },
  methods: {
    updateCharactersCount() {
      if (this.$refs.tinymce.editor)
        this.characterCount = this.$refs.tinymce.editor.plugins.wordcount.body.getCharacterCount();
      this.$emit("charactersCount", this.characterCount);
    },
    insertImage() {
      api
        .post(
          "task/s3/image",
          {
            object: `${this.imageUploaderFolder}/${this.img}`
          },
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${this.$store.getters.token}`
            }
          }
        )
        .then(r => {
          if (r.data[0]) {
            this.$refs.tinymce.editor.selection.setContent(
              `<img src="${r.data[0]}" width="300" >`
            );
            this.$refs.tinymce.editor.focus();
            this.$store.commit("UPDATE_IMG", null);
          }
        });
    },
    insertVideo() {
      api
        .post(
          "task/s3/video",
          {
            object: `${this.videoUploaderFolder}/${this.video}`
          },
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${this.$store.getters.token}`
            }
          }
        )
        .then(r => {
          if (r.data[0]) {
            const videoElement = document.createElement("video");
            videoElement.src = r.data[0];

            videoElement.addEventListener("loadedmetadata", () => {
              const width = videoElement.videoWidth;
              const height = videoElement.videoHeight;

              const videoIsVertical = height > width;

              const iframeWidth = videoIsVertical ? 197 : 350;
              const iframeHeight = videoIsVertical ? 350 : 197;

              this.$refs.tinymce.editor.selection.setContent(
                `<iframe src="${r.data[0]}" frameborder="0" width="${iframeWidth}" height="${iframeHeight}" controlsList="nodownload" allowfullscreen="allowfullscreen"></iframe>`
              );
              this.$refs.tinymce.editor.focus();
              this.$store.commit("UPDATE_VIDEO", null);
            });
          }
        });
    }
  },
  created() {
    this.content = this.text;
  }
};
</script>

<style lang="scss">
.editor-tinymce {
  position: relative;
  max-width: 100%;
  font-family: "PT Sans", sans-serif !important;
}
.tox .tox-statusbar {
  font-family: "PT Sans", sans-serif !important;
  color: #999999 !important;
  font-size: 0.75rem !important;
  border-top: none !important;
  div div,
  button,
  span {
    display: none;
  }
}

.tox-tinymce {
  border: 1px solid #efefef !important;
  border-radius: 3px !important;
  color: #6b6b6b !important;
  max-width: 100% !important;
}

.tox:not([dir="rtl"]) .tox-toolbar__group:not(:last-of-type) {
  border: none !important;
  border-right: 1px solid #efefef !important;
}

.tox .tox-toolbar,
.tox .tox-toolbar__overflow,
.tox .tox-toolbar__primary {
  background: none !important;
  border-bottom: 1px solid #efefef !important;
  word-wrap: break-word !important;
  word-break: break-word !important;
}

.tox .tox-dialog {
  border: none !important;
}

.tox-dialog__footer-end {
  button {
    &:last-of-type {
      letter-spacing: 0.13em !important;
      text-transform: uppercase !important;
      font-size: 0.8rem !important;
      font-weight: bold !important;
      background: #222f3e !important;
      border: 1px solid #222f3e !important;
    }
  }
}
</style>
