<template>
  <div class="create-community">
    <div class="container">
      <div class="row py-5">
        <div class="col">
          <router-link :to="{ name: 'communities' }" class="mb-2 back"
            ><b-icon class="mr-1" icon="arrow-left-circle"></b-icon>
            {{ translation.communities.communities }}
          </router-link>
          <div class="card-b mt-2">
            <form v-if="!loaderArgs" class="px-md-4 pb-4 pt-4">
              <div class="d-flex flex-wrap justify-content-center pt-4">
                <div class="col-md-5 mr-md-4 pb-4">
                  <h2
                    class="title mb-4"
                    :style="{ color: $store.getters.theme[3] }"
                  >
                    {{
                      community
                        ? `${translation.communities.edit_community}: ${community.name}`
                        : translation.communities.create_community
                    }}
                  </h2>

                  <label @click="focusInput('communityName')">
                    {{ translation.communities.name_text }}
                  </label>
                  <b-form-input
                    ref="communityName"
                    style="margin-top: 3px"
                    maxlength="20"
                    v-model="form.name"
                  ></b-form-input>
                  <character-counter
                    :max="20"
                    :min="1"
                    :count="form.name.length"
                  />

                  <label for="description" class="mt-4">
                    {{ translation.communities.description_text }}
                  </label>
                  <b-form-textarea
                    v-model="form.description"
                    rows="1"
                    max-rows="1"
                    id="description"
                    :maxlength="115"
                  ></b-form-textarea>
                  <character-counter
                    :max="115"
                    :min="1"
                    :count="form.description.length"
                  />

                  <label class="mt-4" for="timezone">
                    {{ translation.communities.timezone_text }}
                  </label>
                  <b-form-input
                    v-model="form.timezone"
                    list="timezone"
                  ></b-form-input>
                  <datalist id="timezone">
                    <option v-for="timezone in timezoneOptions" :key="timezone">
                      {{ timezone }}
                    </option>
                  </datalist>
                </div>
                <div class="col-md-5 space">
                  <div class="date-input">
                    <p class="m-0 p-0">
                      {{ translation.communities.available_period_text }}
                    </p>
                    <div>
                      <div class="border-right mr-1">
                        <label for="datepicker-start" class="m-0">
                          {{ translation.communities.start_time }}
                        </label>
                        <div class="d-flex align-items-center">
                          <img
                            class="calendar pb-1 pr-2"
                            src="@/img/calendar.svg"
                            alt="Calendar Icon"
                          />
                          <datetime
                            v-model="form.dateStart"
                            :disabled="!form.timezone.length || !hasTimezone"
                            type="datetime"
                            class="theme"
                            input-class="datetime-picker"
                            :value-zone="form.timezone"
                            :zone="form.timezone"
                            :format="{
                              year: 'numeric',
                              month: 'numeric',
                              day: 'numeric',
                              hour: 'numeric',
                              minute: '2-digit'
                            }"
                            :phrases="{ ok: 'Continue', cancel: 'Exit' }"
                            :hour-step="1"
                            :minute-step="1"
                            :min-datetime="today"
                            :max-datetime="maxDate"
                            :week-start="7"
                            auto
                          ></datetime>
                        </div>
                      </div>
                      <div class="ml-1">
                        <label for="datepicker-end" class="m-0">
                          {{ translation.communities.end_time }}
                        </label>
                        <div class="d-flex align-items-center">
                          <img
                            class="calendar pb-1 pr-2"
                            src="@/img/calendar.svg"
                            alt="Calendar Icon"
                          />
                          <datetime
                            ref="dateEnd"
                            :disabled="!form.dateStart.length"
                            v-model="form.dateEnd"
                            type="datetime"
                            class="theme"
                            input-class="datetime-picker"
                            :value-zone="form.timezone"
                            :zone="form.timezone"
                            :format="{
                              year: 'numeric',
                              month: 'numeric',
                              day: 'numeric',
                              hour: 'numeric',
                              minute: '2-digit'
                            }"
                            :phrases="{ ok: 'Continue', cancel: 'Exit' }"
                            :hour-step="1"
                            :minute-step="1"
                            :min-datetime="minDate"
                            :week-start="7"
                            auto
                          ></datetime>
                        </div>
                      </div>
                    </div>
                  </div>
                  <label for="language" class="mt-4">
                    {{ translation.communities.language_text }}
                  </label>
                  <b-form-select
                    class="select-language"
                    id="language"
                    v-model="form.language"
                    :options="languageOptions"
                  ></b-form-select>
                  <label class="mt-4" @click="focusInput('communityDomain')">
                    {{ translation.communities.domain_text }}
                  </label>
                  <div class="d-flex align-items-center">
                    <b-form-input
                      ref="communityDomain"
                      v-model="form.domain"
                      :disabled="!!community"
                      :state="!isDomainValid && !!form.domain.length"
                      v-b-tooltip.hover="{ variant: 'secondary' }"
                      :title="translation.communities.error.domain_validation"
                    ></b-form-input>
                    <span class="mx-2"> - </span>
                    <b-form-select
                      id="label"
                      v-model="form.label"
                      :options="labelOptions"
                      :disabled="!labelOptions.length || !!community"
                    ></b-form-select>
                    <span class="mx-2"> . </span>
                    <b-form-input
                      class="small"
                      placeholder="owvoices.offerwise.com"
                      disabled
                    ></b-form-input>
                  </div>
                </div>
              </div>
              <div class="col d-flex align-items-end justify-content-end">
                <button
                  v-if="community"
                  @click.prevent="editCommunity"
                  class="btn save-btn px-5 d-flex justify-content-center"
                >
                  <loader2 v-if="loaderEdit" color="#6b6b6b" />
                  <p v-if="!loaderEdit" class="m-0">
                    {{ translation.global.save }}
                  </p>
                </button>
                <button
                  v-else
                  @click.prevent="saveCommunity"
                  class="btn save-btn px-5 d-flex justify-content-center"
                >
                  <loader2 v-if="loaderSave" color="#6b6b6b" />
                  <p v-if="!loaderSave" class="m-0">
                    {{ translation.global.save }}
                  </p>
                </button>
              </div>
            </form>
            <div v-else class="d-flex justify-content-center py-5">
              <loader />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { api } from "@/services.js";
import { Datetime } from "vue-datetime";
import "vue-datetime/dist/vue-datetime.css";
import { Settings } from "luxon";
import loader from "@/components/general/loader.vue";
import loader2 from "@/components/general/loader2.vue";
import characterCounter from "@/components/general/characterCounter.vue";

export default {
  components: {
    Datetime,
    loader,
    loader2,
    characterCounter
  },
  data() {
    return {
      form: {
        name: "",
        description: "",
        dateStart: "",
        dateEnd: "",
        timezone: "",
        language: "",
        domain: "",
        label: ""
      },
      timezoneOptions: [],
      languageOptions: [],
      labelOptions: [],
      loaderArgs: false,
      loaderSave: false,
      loaderEdit: false,
      regexpDomainValidation: /[()!^&$@=;:’+, ?\\{\\}^%`\]'">[~>#|A-Z]/g
    };
  },
  computed: {
    hasTimezone() {
      return this.timezoneOptions.filter(
        timezone => this.form.timezone === timezone
      ).length;
    },
    community() {
      return this.$store.getters.activeCommunity;
    },
    minDate() {
      if (this.form.dateStart !== "") {
        const crop1 = this.form.dateStart.split("T");
        const crop2 = crop1[1].split(":");

        if (+crop2[0] + 1 > 23) crop2[0] = "00";
        else if (+crop2[0] + 1 < 10)
          crop2[0] = "0" + (+crop2[0] + 1).toString();
        else crop2[0] = (+crop2[0] + 1).toString();

        return crop1[0] + "T" + crop2.join(":");
      } else {
        return "";
      }
    },
    maxDate() {
      return this.form.dateEnd;
    },
    today() {
      const date = new Date();
      return date.toISOString();
    },
    isDomainValid() {
      return this.form.domain.match(this.regexpDomainValidation);
    }
  },
  methods: {
    focusInput(ref) {
      const input = this.$refs[`${ref}`];
      input.focus();
    },
    getNeededArgs() {
      this.loaderArgs = true;
      api
        .get(`community/create-community/needed-args`, {
          headers: {
            Authorization: `Bearer ${this.$store.getters.token}`
          }
        })
        .then(r => {
          if (r.status === 200) {
            this.timezoneOptions = r.data.zones;
            this.languageOptions = r.data.languages.map(language => ({
              value: language.id,
              text: language.name
            }));

            this.labelOptions = r.data.labels.map(label => ({
              value: label.id,
              text: label.name
            }));

            if (this.languageOptions.length)
              this.form.language = this.languageOptions[0].value;

            if (this.labelOptions.length)
              this.form.label = this.labelOptions[0].value;

            if (this.community) this.form.language = this.community.language.id;
          }
          this.loaderArgs = false;
        })
        .catch(() => {
          this.loaderArgs = false;
        });
    },
    formatDate(d) {
      let date = new Date(d);
      let string = date
        .toLocaleString("en-US", {
          hour12: true,
          year: "numeric",
          month: "2-digit",
          day: "2-digit",
          minute: "2-digit",
          hour: "2-digit"
        })
        .toString();

      const crop1 = string.split(",");
      const crop2 = crop1[0].split("/");
      const crop3 = crop1[1].split(" ");
      return `${crop2[2]}-${crop2[0]}-${crop2[1]} ${crop3[1]}:00 ${crop3[2]}`;
    },
    saveCommunity() {
      if (!this.loaderSave) {
        if (
          this.form.name.length &&
          this.form.description.length &&
          this.form.dateStart.length &&
          this.form.dateEnd.length &&
          this.form.domain.length &&
          this.form.timezone.length &&
          this.form.label.length
        ) {
          if (!this.isDomainValid) {
            this.loaderSave = true;
            api
              .post(
                "community",
                {
                  name: this.form.name,
                  description: this.form.description,
                  start_at: this.formatDate(this.form.dateStart),
                  end_at: this.formatDate(this.form.dateEnd),
                  logo: "",
                  home_banner: "",
                  theme: 4,
                  status_id: 1,
                  language_id: this.form.language,
                  zone_name: this.form.timezone,
                  domain: this.form.domain,
                  domain_label_id: this.form.label
                },
                {
                  headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${this.$store.getters.token}`
                  }
                }
              )
              .then(r => {
                if (r.status === 201) {
                  this.$toast.success(
                    this.translation.communities.success.community_created
                  );

                  this.$router.push({
                    name: "communities"
                  });
                }
                this.loaderSave = false;
              })
              .catch(error => {
                if (error.response.status === 422) {
                  this.$toast.error(
                    this.translation.communities.error.domain_already_being_used
                  );
                } else {
                  this.$toast.error(
                    this.translation.errors_global.something_went_wrong
                  );
                }
                this.loaderSave = false;
              });
          } else {
            this.$toast.error(
              this.translation.communities.error.domain_validation
            );
          }
        } else {
          this.$toast.error(this.translation.errors_global.fill_fields);
        }
      }
    },
    editCommunity() {
      if (!this.loaderEdit) {
        if (
          this.form.name.length &&
          this.form.description.length &&
          this.form.dateStart.length &&
          this.form.dateEnd.length &&
          this.form.domain.length &&
          this.form.timezone.length &&
          this.form.label.length
        ) {
          if (!this.isDomainValid) {
            this.loaderEdit = true;
            api
              .put(
                `community/${this.community.id}`,
                {
                  name: this.form.name,
                  description: this.form.description,
                  start_at: this.formatDate(this.form.dateStart),
                  end_at: this.formatDate(this.form.dateEnd),
                  language_id: this.form.language,
                  zone_name: this.form.timezone,
                  domain: this.form.domain,
                  domain_label_id: this.form.label
                },
                {
                  headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${this.$store.getters.token}`
                  }
                }
              )
              .then(r => {
                if (r.status === 200) {
                  this.$store.commit("UPDATE_ACTIVE_COMMUNITY", null);
                  this.$toast.success(
                    this.translation.success_global.changes_saved
                  );

                  this.$router.push({
                    name: "communities"
                  });
                }
                this.loaderEdit = false;
              })
              .catch(() => {
                this.loaderEdit = false;
                this.$toast.error(
                  this.translation.errors_global.something_went_wrong
                );
              });
          } else {
            this.$toast.error(
              this.translation.communities.error.domain_validation
            );
          }
        } else {
          this.$toast.error(this.translation.errors_global.fill_fields);
        }
      }
    },
    init() {
      this.getNeededArgs();
      Settings.defaultLocale = this.$store.getters.language
        ? this.$store.getters.language
        : "en";
      if (this.community) {
        const startAtDate = this.community.start_at.replace(/-/g, "/");
        const endAtDate = this.community.end_at.replace(/-/g, "/");
        this.form.name = this.community.name;
        this.form.description = this.community.description;
        this.form.dateStart = new Date(startAtDate).toISOString();
        this.form.dateEnd = new Date(endAtDate).toISOString();
        this.form.timezone = this.community.zone_name;
        this.form.domain = this.community.domain;
        this.form.label = this.community.domain_label_id;
      } else {
        this.form.name = "";
        this.form.description = "";
        this.form.dateStart = "";
        this.form.dateEnd = "";
        this.form.timezone = "";
        this.form.domain = "";
        this.form.label = "";
      }
    }
  },
  created() {
    this.init();
  }
};
</script>

<style lang="scss" scoped>
label {
  color: #6b6b6b;
}

.title {
  font-weight: bold;
  font-size: 1.3rem;
}

.calendar {
  height: 18px;
  position: absolute;
}

.date-input {
  position: relative;
  border-bottom: 1px solid #e6e6e6;
  color: #6b6b6b;
  margin-bottom: 18.1px;

  > div {
    display: flex;
    div {
      width: 50%;
      color: #b3b3b3;
      border: none;
      label {
        font-size: 0.8rem;
      }
      &:nth-of-type(2) {
        position: relative;
      }
    }
  }
}

@media (max-width: 767.98px) {
  .date-input {
    img {
      display: none;
    }
  }
}

.space {
  margin-top: 48px;
}

.save-btn {
  text-align: center;
  text-transform: uppercase;
  color: #6b6b6b;
  font-weight: bold;
  background: #ebf2f2;
  letter-spacing: 0.16em;
  border-radius: 17px;
  border: none;
  padding: 5px 20px;
  align-self: flex-end;
  margin-top: 3rem;
  min-width: 162px;
}

datalist {
  &::-webkit-scrollbar {
    height: 8px;
    overflow: hidden;
  }
  &::-webkit-scrollbar-track {
    background: rgba(0, 0, 0, 0);
    border-radius: 10px;
  }
  &::-webkit-scrollbar-thumb {
    background: #ccc;
    border-radius: 10px;
    height: 10px;
  }
  &::-webkit-scrollbar-thumb:hover {
    background: #aaa;
  }
}

textarea {
  height: 38px;
}

.back {
  color: #6b6b6b;
  cursor: pointer;
}

#label {
  max-width: 100px;
}

.small {
  font-size: 0.65rem;
}

.select-language {
  margin-bottom: 15.1px;
}
</style>
