var render = function () {
var _obj;
var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"row task-wrapper"},[(!_vm.loading)?_c('div',{staticClass:"col"},[_c('div',{staticClass:"task-text img-question"},[_c('p',{domProps:{"innerHTML":_vm._s(_vm.text)}})]),_c('div',{staticClass:"d-flex flex-wrap"},[_c('attachment',{attrs:{"attachments":_vm.task.attachments,"deleteFile":null}})],1),_c('div',{staticClass:"d-flex justify-content-center py-4"},[_c('div',{staticClass:"select-box",style:({ background: _vm.$store.getters.theme[1] })},[_c('p',{staticClass:"mb-1"},[_vm._v(" "+_vm._s(_vm.translation.answer_task.reaction.minimum_number)+": "+_vm._s(_vm.min)+" ")]),_c('p',{staticClass:"mb-3"},[_vm._v(" "+_vm._s(_vm.translation.answer_task.reaction.maximum_number)+": "+_vm._s(_vm.max)+" ")]),_c('p',{staticClass:"mb-1"},[_vm._v(" "+_vm._s(_vm.translation.answer_task.audio.minimum_duration)+": "+_vm._s(_vm.formatDuration(_vm.minDuration))+" ")]),_c('p',{staticClass:"mb-0"},[_vm._v(" "+_vm._s(_vm.translation.answer_task.audio.maximum_duration)+": "+_vm._s(_vm.formatDuration(_vm.maxDuration))+" ")])])]),_c('div',[_c('div',{staticClass:"recording"},[_c('button',{staticClass:"mic-recording-button mb-4",class:( _obj = {}, _obj['is-recording'] = _vm.recording, _obj.hover = !_vm.task.is_answered, _obj ),style:({
            background: _vm.recording ? '#df2938' : _vm.$store.getters.theme[2]
          }),attrs:{"disabled":_vm.task.is_answered},on:{"click":_vm.toggleRecording}},[(_vm.paused || !_vm.started)?_c('b-icon',{staticClass:"play-icon",attrs:{"icon":"play-fill"}}):_c('b-icon',{attrs:{"icon":"pause"}})],1),_c('p',{staticClass:"bytes mb-4"},[_vm._v(" "+_vm._s(_vm.formatDuration(_vm.currentTime / 100))+" ")]),_c('button',{staticClass:"finish-button",class:{ hover: _vm.started },style:({
            background: _vm.$store.getters.theme[2]
          }),attrs:{"disabled":_vm.task.is_answered || !_vm.started || _vm.loaderUploadAudio},on:{"click":_vm.stop}},[_vm._v(" OK ")])]),(_vm.audios.length)?_c('div',_vm._l((_vm.audios),function(audio,index){return _c('div',{key:audio.url,staticClass:"audio"},[_c('p',{staticClass:"mb-0"},[_vm._v(" "+_vm._s(_vm.translation.answer_task.screen_recording.recording)+" "+_vm._s(index + 1)+" ")]),_c('div',[_c('audio',{attrs:{"controls":""}},[_c('source',{attrs:{"src":audio.url,"type":"audio/mpeg"}}),_vm._v(" Your browser does not support the audio tag. ")]),_c('div',{staticClass:"delete-button"},[_c('button',{staticClass:"btn",class:{ hover: !_vm.task.is_answered },attrs:{"disabled":_vm.task.is_answered},on:{"click":function($event){return _vm.deleteAudio(index)}}},[_c('b-icon',{attrs:{"icon":"x"}})],1)])]),_c('p',{staticClass:"bytes mb-0"},[_vm._v(" "+_vm._s(_vm.formatBytes(audio.size))+" ")]),_c('p',{staticClass:"bytes mb-0"},[_vm._v(" "+_vm._s(_vm.formatDuration(audio.duration))+" ")])])}),0):_vm._e()]),(_vm.loaderUploadAudio)?_c('div',{staticClass:"d-flex justify-content-center my-5"},[_c('loader')],1):_vm._e(),_c('div',{staticClass:"comments mt-4"},[(
          _vm.task.question_object.has_custom_comment_label &&
            _vm.task.question_object.comment_label &&
            _vm.task.question_object.comment_label.length
        )?_c('label',{attrs:{"for":"comments"}},[_vm._v(_vm._s(_vm.task.question_object.comment_label))]):_c('label',{attrs:{"for":"comments"}},[_vm._v(" "+_vm._s(_vm.translation.answer_task.comments)+" ")]),_c('textarea',{directives:[{name:"model",rawName:"v-model",value:(_vm.comments),expression:"comments"}],staticClass:"form-control",attrs:{"id":"textarea","maxlength":_vm.maxCharacters,"rows":"3","max-rows":"6","disabled":_vm.task.is_answered},domProps:{"value":(_vm.comments)},on:{"input":function($event){if($event.target.composing){ return; }_vm.comments=$event.target.value}}}),_c('characterAndWordCounter',{attrs:{"count":_vm.hasWordsLimitation
            ? this.comments.length
              ? this.comments.trim().split(/\s+/).length
              : 0
            : _vm.comments.length,"max":_vm.hasWordsLimitation ? _vm.maxWords : _vm.maxCharacters,"label":_vm.hasWordsLimitation
            ? _vm.translation.answer_task.words
            : _vm.translation.answer_task.characters}})],1)]):_vm._e(),(_vm.loading)?_c('div',{staticClass:"w-100 d-flex justify-content-center"},[_c('loader')],1):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }