<template>
  <div>
    <p v-for="group in dragAndDropData" :key="group.group_value">
      <span class="font-weight-bold">{{ group.group_value }}:</span>
      <span
        class="option"
        v-for="card in group.cards"
        :key="card.card_value.text"
      >
        {{ card.card_value.text }}
      </span>
    </p>
    <p
      v-if="
        answer.answer_object &&
          answer.answer_object.comment &&
          answer.answer_object.comment.length
      "
    >
      <span :style="{ color: $store.getters.theme[3] }" class="comments">
        {{ translation.answer_task.comments }}
      </span>
      {{ answer.answer_object.comment }}
    </p>
  </div>
</template>

<script>
export default {
  props: {
    answer: {
      required: true
    }
  },
  computed: {
    dragAndDropData() {
      const array = this.answer.answer_object.options;

      return array;
    }
  }
};
</script>

<style lang="scss" scoped>
.option {
  display: block;
}

.comments {
  margin-top: 20px;
  display: block;
  text-transform: uppercase;
  font-weight: bold;
}
</style>
