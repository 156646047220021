<template>
  <div>
    <b-modal id="modal-warn-login" hide-footer>
      <template #modal-title>
        {{
          subdomain === "nulab-nubank"
            ? translation.modal_welcome.nubank_title
            : translation.modal_welcome.default_title
        }}
        🥳
      </template>
      <div class="d-block text-center">
        <p>
          {{
            subdomain === "nulab-nubank"
              ? translation.modal_welcome.nubank
              : translation.modal_welcome.default
          }}
        </p>
      </div>
    </b-modal>
    <div class="logo">
      <img
        v-if="
          $store.getters.community &&
            $store.getters.community.logo &&
            $store.getters.community.logo.length
        "
        class="img-fluid"
        :src="$store.getters.community.logo"
        alt="Logo"
      />
      <img v-else src="@/img/logo-communities.png" alt="Logo" />
    </div>
    <div class="wrapper d-flex align-items-center">
      <div class="col-lg-6 d-flex justify-content-center px-lg-5">
        <div v-if="$store.getters.community">
          <img
            v-if="+$store.getters.community.theme === themesCodes.themeOrange"
            class="img-fluid px-lg-5"
            src="@/img/login-theme1.svg"
            alt="Login
        image"
          />
          <img
            v-if="+$store.getters.community.theme === themesCodes.themeGreen"
            class="img-fluid px-lg-5"
            src="@/img/login-theme2.svg"
            alt="Login
        image"
          />
          <img
            v-if="+$store.getters.community.theme === themesCodes.themeYellow"
            class="img-fluid px-lg-5"
            src="@/img/login-theme3.svg"
            alt="Login
        image"
          />
          <img
            v-if="+$store.getters.community.theme === themesCodes.themeBlue"
            class="img-fluid px-lg-5"
            src="@/img/login-theme4.svg"
            alt="Login
        image"
          />
          <img
            v-if="+$store.getters.community.theme === themesCodes.themePink"
            class="img-fluid px-lg-5"
            src="@/img/login-theme5.svg"
            alt="Login
        image"
          />
          <img
            v-if="+$store.getters.community.theme === themesCodes.themeGray"
            class="img-fluid px-lg-5"
            src="@/img/login-theme6.svg"
            alt="Login
        image"
          />
          <img
            v-if="+$store.getters.community.theme === themesCodes.themePurple"
            class="img-fluid px-lg-5"
            src="@/img/login-theme7.svg"
            alt="Login
        image"
          />
        </div>
        <div v-else>
          <img
            class="img-fluid px-lg-5"
            src="@/img/login-theme4.svg"
            alt="Login
        image"
          />
        </div>
        <div
          :style="{
            background: `${
              $store.getters.theme ? $store.getters.theme[0] : '#F7FAFA'
            }`
          }"
        ></div>
      </div>
      <div class="col-lg-6 px-lg-5">
        <div class="px-lg-5 w-100 py-5">
          <div
            :style="{
              background: `${
                $store.getters.theme ? $store.getters.theme[2] : '#108FC3'
              }`
            }"
            class="style"
          ></div>
          <h2>
            {{ translation.login.login_text }}
          </h2>
          <form>
            <div class="mb-3 mt-4 input p-relative">
              <input class="input-hidden" type="text" name="email" />
              <input class="input-hidden" type="password" name="password" />
              <input
                style="z-index: 2"
                type="email"
                class="form-control p-4"
                id="email"
                :placeholder="translation.login.email_text"
                v-model="email"
              />
              <img
                style="z-index: 2"
                src="@/img/person.svg"
                alt="E-mail Icon"
              />
            </div>
            <div class="mb-3 input">
              <input
                :type="eyePass ? 'text' : 'password'"
                class="form-control p-4"
                id="password"
                :placeholder="translation.login.password_text"
                v-model="pass"
              />
              <div class="eye-pass" @click="eyePass = !eyePass">
                <b-icon v-if="eyePass" icon="eye" aria-hidden="true"></b-icon>
                <b-icon v-else icon="eye-slash" aria-hidden="true"></b-icon>
              </div>
            </div>
            <button
              :style="{
                background: `${
                  $store.getters.theme ? $store.getters.theme[0] : '#F7FAFA'
                }`
              }"
              @click.prevent="login"
              class="
                btn
                mt-4
                hover
                d-flex
                justify-content-center
                align-items-center
              "
            >
              <loader2 v-if="loader" color="#6b6b6b" />
              <p v-if="!loader" class="m-0">
                {{ translation.login.login_button }}
              </p>
            </button>
          </form>
          <router-link :to="{ name: 'email' }" class="mt-4">
            {{ translation.login.forgot_password_text }}
          </router-link>
          <p v-if="+redirect === 1" class="mt-3 text-center text-success">
            {{ translation.login.success.account_registered }}
          </p>
          <p v-if="+redirect === 2" class="mt-3 text-center text-danger">
            {{ translation.login.error.update_failed }}
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { api } from "@/services.js";
import loader2 from "@/components/general/loader2.vue";
import { userTypes, themes, userTypesText, themesCodes } from "@/constants.js";
import jwt_decode from "jwt-decode";
import { mapMutations } from "vuex";

export default {
  name: "Login",
  components: {
    loader2
  },
  data() {
    return {
      email: "",
      pass: "",
      loader: false,
      eyePass: false,
      themes,
      userTypes,
      userTypesText,
      themesCodes
    };
  },
  computed: {
    redirect() {
      return this.$route.query.redirect;
    },

    subdomain() {
      const hostname = window.location.hostname.split(".");
      const subdomain = hostname[0];
      return subdomain;
    }
  },

  methods: {
    ...mapMutations(["UPDATE_PRIVACY", "UPDATE_PERMISSIONS"]),

    login() {
      if (!this.loader) {
        if (this.email !== "" && this.pass !== "") {
          this.loader = true;
          api
            .post(
              "token",
              {
                email: this.email,
                password: this.pass
              },
              {
                headers: {
                  "Content-Type": "application/json"
                }
              }
            )
            .then(r => {
              if (r.status === 200) {
                let info;
                const token = r.data.token;
                try {
                  info = jwt_decode(token);
                  this.$store.commit("UPDATE_IS_LOGOUT", false);
                  this.$store.dispatch("login", { token, info });

                  if (
                    this.$store.getters.info.user.type ===
                    this.userTypesText.moderator
                  )
                    window.fcWidget.init({
                      token: "330cb03b-af05-4e0e-b457-cb00ac387fa4",
                      host: "https://wchat.freshchat.com",
                      email: this.$store.getters.info.user.email,
                      firstName: this.$store.getters.info.user.name,
                      siteId: "owvoices"
                    });

                  if (
                    this.$store.getters.info.user.type ===
                    this.userTypesText.admin
                  ) {
                    this.$store.commit("UPDATE_COMMUNITY", null);
                    this.$store.commit(
                      "UPDATE_THEME",
                      JSON.stringify(this.themes[4])
                    );

                    this.$router.push({ name: "communities" });
                  } else {
                    this.$store.commit(
                      "UPDATE_COMMUNITY",
                      this.$store.getters.info.community
                    );

                    const lang = this.$store.getters.community.language
                      .abbreviation;
                    this.$store.commit("UPDATE_LANGUAGE", lang);
                    const userTheme = +this.$store.getters.community.theme;
                    let theme = this.themes[userTheme];

                    this.$store.commit("UPDATE_THEME", JSON.stringify(theme));
                    this.$router.push({ name: "feed" });
                  }

                  this.getPrivacy();
                  this.getPermissions();
                } catch (error) {
                  this.loader = false;
                  this.$store.dispatch("logOut");
                  this.$toast.error(
                    this.translation.errors_global.something_went_wrong
                  );
                }
              }
            })
            .catch(error => {
              this.loader = false;
              if (error.response.status === 403) {
                if (error.response.data.code === 0) {
                  this.$toast.error(
                    this.translation.login.error
                      .community_ended_for_participants
                  );
                } else if (error.response.data.code === 1) {
                  this.$toast.error(
                    this.translation.login.error.community_hasnt_started
                  );
                } else if (error.response.data.code === 4) {
                  this.$toast.error(
                    this.translation.login.error.community_ended_for_moderators
                  );
                } else if (error.response.data.code === 5) {
                  this.$toast.error(
                    this.translation.login.error.community_not_active
                  );
                } else {
                  this.$toast.error(
                    this.translation.errors_global.invalid_user_or_pwd
                  );
                }
              } else {
                this.$toast.error(
                  this.translation.errors_global.invalid_user_or_pwd
                );
              }
            });
        } else {
          this.$toast.error(this.translation.errors_global.fill_fields);
        }
      }
    },

    getPrivacy() {
      if (this.$store.getters.community) {
        api
          .get(`community/${this.$store.getters.community.id}/privacy`, {
            headers: {
              Authorization: `Bearer ${this.$store.getters.token}`
            }
          })
          .then(r => {
            if (r.status === 200) {
              this.UPDATE_PRIVACY(r.data);
            }
          });
      }
    },

    getPermissions() {
      if (this.$store.getters.community) {
        api
          .get(`community/${this.$store.getters.community.id}/permission`, {
            headers: {
              Authorization: `Bearer ${this.$store.getters.token}`
            }
          })
          .then(r => {
            if (r.status === 200) {
              this.UPDATE_PERMISSIONS(r.data);
            }
          });
      }
    },

    init() {
      window.fcWidget.destroy();
      const label = window.location.hostname.split("-");
      const hostname = window.location.hostname.split(".");
      const subdomain = hostname[0];
      if (
        hostname.length > 1 &&
        label.length &&
        label[0] !== "dev" &&
        label[0] !== "stg" &&
        (!this.$store.getters.info ||
          this.$store.getters.info.user.type !== this.userTypesText.admin)
      ) {
        api
          .get(`community/data/${subdomain}`, {
            headers: {
              Authorization: `Bearer ${this.$store.getters.token}`
            }
          })
          .then(r => {
            if (r.status === 200) {
              if (r.data.community) {
                this.$store.commit("UPDATE_COMMUNITY", r.data.community);
                let userTheme = 4;
                if (this.$store.getters.token && this.$store.getters.community)
                  userTheme = +this.$store.getters.community.theme;
                let theme = themes[userTheme];

                this.$store.commit("UPDATE_THEME", JSON.stringify(theme));
                if (
                  this.$store.getters.token &&
                  this.$store.getters.community
                ) {
                  const lang = this.$store.getters.community.language
                    .abbreviation;
                  this.$store.commit("UPDATE_LANGUAGE", lang);
                } else {
                  const lang = (
                    navigator.language || navigator.userLanguage
                  ).split("-")[0];

                  if (
                    !this.$store.getters.token &&
                    (lang === "es" || lang === "pt" || lang === "en")
                  ) {
                    this.$store.commit("UPDATE_LANGUAGE", lang);
                  } else {
                    this.$store.commit("UPDATE_LANGUAGE", "en");
                  }
                }
              }
            }
          });
      }
    }
  },
  created() {
    this.init();
  },
  mounted() {
    this.$bvModal.show("modal-warn-login");
  }
};
</script>
<style lang="scss" scoped>
.logo {
  margin-top: 30px;
  margin-left: 30px;
  display: flex;
  img {
    max-height: 55px;
  }
}

@media (max-width: 991.98px) {
  .logo {
    margin-top: 20px;
    margin-left: 20px;
  }
}

.wrapper {
  height: calc(100vh - 110px);
}

.col-lg-6 {
  display: flex;
  &:nth-of-type(1) {
    div {
      &:nth-of-type(2) {
        display: block;
        height: 545px;
        width: 3px;
        position: absolute;
        right: -1.5px;
        top: -100px;
      }
    }
  }
}

@media (max-width: 991.98px) {
  .col-lg-6 {
    &:nth-of-type(1) {
      display: none !important;
    }
    &:nth-of-type(2) {
      margin-top: -30px;
    }
  }
}

.btn {
  height: 50px;
}

.style {
  display: block;
  width: 31px;
  height: 4px;
  border-radius: 4px;
  background: #639696;
  margin-bottom: 8px;
}

h2 {
  color: #6b6b6b;
  font-size: 1.3rem;
  font-weight: bold;
}

.input {
  display: flex;
  align-items: center;
  border: 1px solid #e6e6e6;
  color: #6b6b6b;
  font-size: 1rem;
  border-radius: 33px;
  position: relative;
  img {
    position: absolute;
    right: 20px;
  }
}

input {
  width: 100%;
  border: none;
  border-radius: 33px;
}

button {
  width: 100%;
  border-radius: 33px;
  letter-spacing: 0.46em;
  text-transform: uppercase;
  font-weight: bold;
  color: #6b6b6b;
}

a {
  display: block;
  text-align: center;
  color: #6b6b6b;
  text-decoration: underline;
}

.eye-pass {
  position: absolute;
  right: 20px;
  font-size: 24px;
  color: #bbb;
  cursor: pointer;
}
</style>
