<template>
  <div class="container">
    <div class="row py-5">
      <div class="col">
        <div class="mx-md-5">
          <search />
          <div>
            <div class="table-wrapper">
              <div class="table">
                <div
                  class="table-header"
                  :style="{ color: $store.getters.theme[3] }"
                >
                  <div id="order">
                    <a @click="handleDrop('order')">
                      {{ translation.replies.dashboard.name }}
                      <b-icon
                        icon="caret-down-fill"
                        aria-hidden="true"
                      ></b-icon>
                    </a>

                    <div class="box p-2">
                      <b-form-group
                        class="m-0"
                        :label="`${translation.global.order_by}:`"
                        v-slot="{ ariaDescribedby }"
                      >
                        <b-form-radio
                          v-model="order"
                          :aria-describedby="ariaDescribedby"
                          name="order-radios"
                          value="sort"
                          >A-Z</b-form-radio
                        >
                        <b-form-radio
                          v-model="order"
                          :aria-describedby="ariaDescribedby"
                          name="order-radios"
                          value="reverse"
                          >Z-A</b-form-radio
                        >
                        <b-form-radio
                          v-model="order"
                          :aria-describedby="ariaDescribedby"
                          name="order-radios"
                          value="none"
                        >
                          {{ translation.replies.dashboard.initial }}
                        </b-form-radio>
                      </b-form-group>
                    </div>
                  </div>
                  <div>ID</div>
                  <div class="email">
                    {{ translation.replies.dashboard.email }}
                  </div>
                  <div></div>
                </div>
                <div v-if="total">
                  <div
                    class="table-content mb-2 p-2 mt-3"
                    v-for="participant in participantsArray"
                    :key="participant.participant.id"
                  >
                    <div>
                      <span
                        v-if="
                          $store.getters.info.user.type ===
                            userTypesText.moderator
                        "
                      >
                        {{
                          namePrivacy(
                            privacy.moderators.participants_identifier_type_id,
                            participant.participant.name,
                            participant.participant.id
                          )
                        }}
                      </span>
                      <span
                        v-else-if="
                          $store.getters.info.user.type ===
                            userTypesText.participant
                        "
                      >
                        {{
                          namePrivacy(
                            privacy.participants
                              .participants_identifier_type_id,
                            participant.participant.name,
                            participant.participant.id
                          )
                        }}
                      </span>
                      <span
                        v-else-if="
                          $store.getters.info.user.type ===
                            userTypesText.observer
                        "
                      >
                        {{
                          namePrivacy(
                            privacy.observers.participants_identifier_type_id,
                            participant.participant.name,
                            participant.participant.id
                          )
                        }}
                      </span>
                      <span v-else>
                        {{ participant.participant.name }}
                      </span>
                    </div>
                    <div>
                      {{ participant.participant.id }}
                    </div>
                    <div
                      class="email"
                      :title="participant.participant.email"
                      v-b-tooltip.hover="{ variant: 'secondary' }"
                    >
                      {{ participant.participant.email | maxLength }}
                    </div>
                    <div>
                      <router-link
                        class="chat"
                        :style="{
                          color: $store.getters.theme[2]
                        }"
                        :to="{
                          name: 'chat',
                          query: { _participant: participant.participant.id }
                        }"
                      >
                        {{ translation.replies.dashboard.send_message }}
                      </router-link>
                    </div>
                  </div>
                </div>
                <div
                  v-else-if="!total && queryValue"
                  class="d-flex justify-content-center py-5"
                ></div>
                <div
                  v-else-if="loader"
                  class="d-flex justify-content-center mt-4"
                >
                  <loader />
                </div>
                <div
                  v-else-if="!queryValue"
                  class="result d-flex justify-content-center py-5"
                >
                  No Data
                </div>
                <div
                  v-if="!participantsArray.length && queryValue"
                  class="d-flex justify-content-center py-5"
                >
                  {{ translation.global.sorry_we_have }}
                  "{{ queryValue }}"
                </div>
              </div>
              <pagination-query
                class="px-3"
                v-if="!queryValue && total && minPerPage < total"
                :perPage="perPage"
                :total="total"
                queryName="_pageParticipant"
                :options="[5, 10, 20, 50, 100]"
                @perPageUpdate="newPerPage => (perPage = newPerPage)"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { api } from "@/services.js";
import paginationQuery from "@/components/general/paginationQuery.vue";
import loader from "@/components/general/loader.vue";
import search from "@/components/general/search.vue";
import { outsideClick, namePrivacy } from "@/helpers.js";
import { mapState } from "vuex";
import { userTypesText } from "@/constants";

export default {
  props: {
    task: {
      required: true
    }
  },

  components: { paginationQuery, loader, search },

  data() {
    return {
      minPerPage: 5,
      perPage: 5,
      participants: [],
      participantsArray: [],
      loader: false,
      order: "none",
      filter: "none",
      total: 0,
      outsideClick,
      userTypesText
    };
  },

  filters: {
    maxLength(value) {
      if (value.length > 28) {
        return (
          value
            .split("")
            .splice(0, 28)
            .join("") + "..."
        );
      } else return value;
    }
  },

  computed: {
    ...mapState(["privacy"]),

    page() {
      return this.$route.query._pageParticipant;
    },

    queryValue() {
      if (this.$route.query._q && this.$route.query._q.length)
        return this.$route.query._q;
      else return 0;
    }
  },

  watch: {
    queryValue() {
      this.filterParticipants();
    },

    order() {
      this.filterParticipants();
    },

    filter() {
      this.filterParticipants();
    },

    page() {
      this.filterParticipants();
    },

    perPage() {
      this.filterParticipants();
    }
  },

  methods: {
    namePrivacy,

    goToChat(taskId, participantId, index) {
      this.$router.push({
        name: "conversation",
        query: {
          _task: taskId,
          _participant: participantId,
          _index: index
        }
      });
    },

    handleDrop(id) {
      const dropItem = document.querySelector(`#${id}`);
      dropItem.children[1].classList.toggle("active");
      this.outsideClick(dropItem, ["click"], () => {
        dropItem.children[1].classList.remove("active");
      });
    },

    getinsights() {
      this.loader = true;
      api
        .get(`activity/${this.$route.query._activity}/insights`, {
          headers: {
            Authorization: `Bearer ${this.$store.getters.token}`
          }
        })
        .then(r => {
          this.participants = r.data;
          this.filterParticipants();
          this.loader = false;
        })
        .catch(() => {
          this.loader = false;
        });
    },

    filterParticipants() {
      let array = [];

      if (this.participants.length) {
        if (this.queryValue) {
          array = this.participants.filter(item => {
            return this.queryValue
              .toLowerCase()
              .split(" ")
              .every(v => item.participant.name.toLowerCase().includes(v));
          });
        } else {
          let participants = this.participants;

          if (this.order !== "none") {
            let participantsTemp = [];
            this.participants.forEach(participant => {
              participantsTemp.push(participant);
            });
            participantsTemp.sort((a, b) =>
              a.participant.name > b.participant.name ? 1 : -1
            );

            if (this.order === "reverse") participantsTemp.reverse();
            participants = participantsTemp;
          }

          if (this.filter !== "none") {
            participants = participants.filter(
              participant => participant.status === this.filter
            );
            if (+this.$route.query._pageAnswer !== 1) {
              let obj = {};

              const queryArray = Object.keys(this.$route.query);

              queryArray.forEach(query => {
                if (query !== "_pageParticipant") {
                  obj[query] = this.$route.query[query];
                }
              });

              this.$router.replace({
                query: Object.assign(obj, { _pageParticipant: 1 })
              });
            }
          }

          const start = this.perPage * (this.page - 1);
          participants.forEach((participant, index) => {
            if (index >= start && index < start + this.perPage)
              array.push(participant);
          });
          this.total = participants.length;
        }
      }

      this.participantsArray = array;
    }
  },

  created() {
    this.getinsights();
  }
};
</script>

<style lang="scss" scoped>
.back {
  color: #6b6b6b;
  position: absolute;
  top: 0;
  right: 0;
  cursor: pointer;
}

@media (max-width: 767.98px) {
  .back {
    top: -15px;
  }
}
.title {
  font-weight: bold;
  font-size: 1.25rem;
}

.table-wrapper {
  min-height: 200px;
  overflow-x: auto;
  &::-webkit-scrollbar {
    height: 8px;
    overflow: hidden;
  }
  &::-webkit-scrollbar-track {
    background: rgba(0, 0, 0, 0);
    border-radius: 10px;
  }
  &::-webkit-scrollbar-thumb {
    background: #ccc;
    border-radius: 10px;
    height: 10px;
  }
  &::-webkit-scrollbar-thumb:hover {
    background: #aaa;
  }
}

.table {
  width: 100%;
}

.table-header {
  min-width: 100%;
  margin-top: 20px;
  display: flex;
  width: fit-content;
  > div {
    position: relative;
    min-width: 150px;
    flex: 1;
    display: flex;
    justify-content: center;
    font-weight: bold;
    &#order {
      cursor: pointer;
    }
    &.email {
      min-width: 250px;
    }
  }
}

#filter {
  cursor: pointer;
}

.table-content {
  min-width: 100%;
  width: fit-content;
  display: flex;
  border-radius: 50px;
  border: 1px solid #999999;
  > div {
    min-width: 150px;
    flex: 1;
    display: flex;
    justify-content: center;
    color: #6b6b6b;
    word-wrap: break-word;
    word-break: break-word;
    &.email {
      min-width: 250px;
    }
  }
}

.box {
  display: none !important;
  position: absolute;
  top: 30px;
  right: calc(50% - 65px);
  background: #ffffff;
  box-shadow: 0px 4px 29px rgba(0, 0, 0, 0.1);
  border-radius: 11px;
  width: 130px;
  animation: grow 400ms ease-in-out forwards;
  transform-origin: top center;
  z-index: 5;
  &.active {
    display: block !important;
  }
}

.chat {
  font-weight: bold;
  text-decoration: underline;
  cursor: pointer;
}

.pagination {
  select {
    width: 60px;
  }
}
</style>
