<template>
  <div>
    <search class="mt-5 search-position" />
    <div class="table-wrapper" v-if="filterOperators.length && !loaderOperator">
      <div class="table mt-5">
        <div :style="{ color: $store.getters.theme[3] }" class="table-header">
          <div>{{ translation.operators.manage_operators.name }}</div>
          <div>E-mail</div>
          <div>{{ translation.operators.add_operators.type_access }}</div>
          <div></div>
        </div>

        <div v-for="operator in filterOperators" :key="operator.id">
          <div
            class="table-content mt-2 p-1 d-flex align-items-center"
            :class="{
              disable: operator.is_active === isActiveCode.notActive
            }"
          >
            <div>
              <b-form-input
                size="sm"
                v-model="operator.name"
                v-if="operator.isEditable"
              ></b-form-input>
              <p
                v-else
                class="m-0"
                :title="operator.name"
                v-b-tooltip.hover="{ variant: 'secondary' }"
              >
                {{ operator.name | maxLength }}
              </p>
            </div>
            <div>
              <b-form-input
                size="sm"
                v-model="operator.email"
                v-if="operator.isEditable"
              ></b-form-input>
              <p
                v-else
                class="m-0"
                :title="operator.email"
                v-b-tooltip.hover="{ variant: 'secondary' }"
              >
                {{ operator.email | maxLength }}
              </p>
            </div>
            <div>
              <div
                class="d-block"
                v-if="
                  operator.isEditable &&
                    operator.operator_type !== userTypesText.admin
                "
              >
                <b-form-radio
                  v-model="operator.operator_type_id"
                  :name="`order-radios${operator.id}`"
                  :id="`observer${operator.id}`"
                  :value="2"
                  >{{
                    translation.operators.add_operators.observer
                  }}</b-form-radio
                >
                <b-form-radio
                  v-model="operator.operator_type_id"
                  :name="`order-radios${operator.id}`"
                  :id="`operator${operator.id}`"
                  :value="1"
                  >{{
                    translation.operators.add_operators.moderator
                  }}</b-form-radio
                >
              </div>
              <p v-else class="m-0">
                <span v-if="operator.operator_type === userTypesText.moderator">
                  {{ translation.operators.add_operators.moderator }}
                </span>
                <span
                  v-else-if="operator.operator_type === userTypesText.observer"
                >
                  {{ translation.operators.add_operators.observer }}
                </span>
                <span v-else>{{ operator.operator_type }}</span>
              </p>
            </div>

            <div>
              <span class="edit-operator d-flex" v-if="operator.isEditable">
                <button
                  @click="cancelEditOperator(operator)"
                  class="btn btn-sm delete"
                >
                  <b-icon icon="x"></b-icon>
                </button>
                <button @click="editOperador(operator)" class="btn btn-sm">
                  <b-icon icon="check"></b-icon>
                </button>
              </span>
              <div
                class="dropdown-actions"
                :id="`dropdown-actions-participant-${operator.id}`"
              >
                <button
                  :style="{ color: $store.getters.theme[2] }"
                  :class="{
                    active: operator.email === $store.getters.info.user.email
                  }"
                  class="btn-actions hover"
                  @click="
                    operator.email !== $store.getters.info.user.email
                      ? handleDrop(
                          `dropdown-actions-participant-${operator.id}`
                        )
                      : ''
                  "
                >
                  {{ translation.global.actions }}
                  <b-icon
                    class="m-0"
                    icon="caret-right-fill"
                    aria-hidden="true"
                  ></b-icon>
                </button>
                <div id="dropdown">
                  <a
                    class="d-flex align-items-center"
                    @click="openEditOperator(operator)"
                    v-if="operator.operator_type !== userTypesText.admin"
                  >
                    <b-icon icon="pencil" class="mr-2"></b-icon>
                    {{ translation.operators.manage_operators.edit }}
                  </a>
                  <a
                    class="d-flex align-items-center"
                    @click="resetPassword(operator)"
                  >
                    <b-icon icon="arrow-repeat" class="mr-2"></b-icon>
                    {{ translation.operators.manage_operators.reset_password }}
                  </a>
                  <a
                    class="d-flex align-items-center"
                    @click="resendInvitation(operator)"
                  >
                    <b-icon class="mr-2" icon="envelope"></b-icon>
                    {{
                      translation.operators.manage_operators.resend_invitation
                    }}
                  </a>
                  <a
                    class="d-flex align-items-center"
                    @click="toggleActivateOperator(operator)"
                  >
                    <b-icon
                      v-if="operator.is_active === isActiveCode.active"
                      icon="x-circle"
                      class="mr-2"
                    ></b-icon>
                    <b-icon v-else icon="play-fill" class="mr-2"></b-icon>
                    {{
                      operator.is_active === isActiveCode.active
                        ? translation.operators.manage_operators.disable_access
                        : translation.global.activate
                    }}
                  </a>
                  <a
                    v-if="operator.operator_type !== userTypesText.admin"
                    class="d-flex align-items-center"
                    @click="$bvModal.show(`deleteOperator${operator.id}`)"
                  >
                    <b-icon icon="trash" class="mr-2"></b-icon>
                    {{ translation.operators.manage_operators.delete_access }}
                  </a>
                  <b-modal
                    @ok="deleteOperator(operator)"
                    :cancel-title="translation.global.cancel"
                    :id="`deleteOperator${operator.id}`"
                    :title="
                      translation.operators.manage_operators.delete_operator
                    "
                  >
                    {{
                      translation.operators.manage_operators
                        .delete_operator_label
                    }}
                  </b-modal>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <pagination-query
        v-if="minPerPage < total"
        :perPage="perPage"
        :total="total"
        :options="[10, 20, 50, 100]"
        @perPageUpdate="newPerPage => (perPage = newPerPage)"
      />
    </div>
    <div v-else-if="loaderOperator" class="d-flex justify-content-center py-5">
      <loader />
    </div>
    <div
      v-else-if="!filterOperators.length && queryValue"
      class="d-flex justify-content-center py-5"
    >
      {{ translation.global.sorry_we_have }}
      "{{ queryValue }}"
    </div>
    <div class="d-flex justify-content-center py-5" v-else>
      {{ translation.operators.manage_operators.no_operator }}
    </div>
  </div>
</template>

<script>
import paginationQuery from "@/components/general/paginationQuery.vue";
import { api } from "@/services.js";
import loader from "@/components/general/loader.vue";
import search from "@/components/general/search.vue";
import { outsideClick } from "@/helpers.js";
import { userTypesText, isActiveCode } from "@/constants";

export default {
  components: { paginationQuery, loader, search },
  data() {
    return {
      minPerPage: 10,
      perPage: 10,
      total: 0,
      loaderOperator: false,
      operators: [],
      currentOperatorName: {},
      currentOperatorEmail: {},
      currentOperatortypeAccess: {},
      outsideClick,
      userTypesText,
      isActiveCode
    };
  },
  filters: {
    maxLength(value) {
      if (value.length > 22) {
        return (
          value
            .split("")
            .splice(0, 22)
            .join("") + "..."
        );
      } else return value;
    }
  },
  computed: {
    page() {
      return +this.$route.query._page;
    },
    queryValue() {
      if (this.$route.query._q === "" || this.$route.query._q)
        return this.$route.query._q;
      else return -1;
    },
    filterOperators() {
      let array = [];

      if (this.queryValue) {
        array = this.operators.filter(item => {
          return this.queryValue
            .toLowerCase()
            .split(" ")
            .every(v => item.name.toLowerCase().includes(v));
        });
      } else {
        const start = this.perPage * (this.page - 1);
        this.operators.forEach((operator, index) => {
          if (index >= start && index < start + this.perPage)
            array.push(operator);
        });
      }
      return array;
    }
  },
  methods: {
    getOperators() {
      this.loaderOperator = true;
      api
        .get(`community/${this.$store.getters.community.id}/operators`, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${this.$store.getters.token}`
          }
        })
        .then(r => {
          if (r.status === 200) {
            this.operators = r.data.operators.map(operator => ({
              ...operator,
              isEditable: false
            }));

            r.data.admins.map(admin => {
              this.operators.unshift(admin);
            });

            let userOperator = [];

            this.operators.map((operator, index) => {
              if (operator.id === this.$store.getters.info.user.sub) {
                userOperator = operator;
                this.operators.splice(index, 1);
              }
            });

            this.operators.unshift(userOperator);
            this.total = this.operators.length;
            this.loaderOperator = false;
          }
        })
        .catch(() => {
          this.loaderOperator = false;
        });
    },
    openEditOperator(operator) {
      this.currentOperatorName[operator.id] = operator.name;
      this.currentOperatorEmail[operator.id] = operator.email;
      this.currentOperatortypeAccess[operator.id] = operator.operator_type;
      operator.isEditable = true;
    },
    editOperador(operator) {
      api
        .put(
          `operator/${operator.id}`,
          {
            name: operator.name,
            email: operator.email,
            operator_type_id: +operator.operator_type_id
          },
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${this.$store.getters.token}`
            }
          }
        )
        .then(r => {
          if (r.status === 200) {
            this.$toast.success(this.translation.success_global.changes_saved);
            this.getOperators();
            operator.isEditable = false;
          }
        })
        .catch(() => {
          operator.name = this.currentOperatorName[operator.id];
          operator.email = this.currentOperatorEmail[operator.id];
          operator.operator_type = this.currentOperatortypeAccess[operator.id];
          this.$toast.error(
            this.translation.errors_global.something_went_wrong
          );
        });
    },
    cancelEditOperator(operator) {
      operator.name = this.currentOperatorName[operator.id];
      operator.email = this.currentOperatorEmail[operator.id];
      operator.operator_type = this.currentOperatortypeAccess[operator.id];
      operator.isEditable = false;
    },
    resendInvitation(operator) {
      const resend = `admin/manage/operator/${operator.id}/re-invite`;
      api
        .post(
          resend,
          {},
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${this.$store.getters.token}`
            }
          }
        )
        .then(r => {
          if (r.status === 201) {
            this.$toast.success(this.translation.email.success.sent_email);
          }
        })
        .catch(error => {
          if (error.response.status === 422) {
            this.$toast.error(
              this.translation.operators.manage_operators.user_has_already
            );
          } else {
            this.$toast.error(
              this.translation.errors_global.something_went_wrong
            );
          }
        });
    },
    resetPassword(operator) {
      const resetAdmin = `admin/reset-password/${operator.id}`;
      const resetModeratorOrObserver = `admin/manage/operator/${operator.id}/reset-password`;
      api
        .put(
          `${
            operator.operator_type !== "Admin"
              ? resetModeratorOrObserver
              : resetAdmin
          }`,
          {},
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${this.$store.getters.token}`
            }
          }
        )
        .then(r => {
          if (r.status === 200) {
            this.$toast.success(
              this.translation.operators.manage_operators
                .password_reset_successfully
            );
          }
        })
        .catch(() => {
          this.$toast.error(
            this.translation.operators.manage_operators.error_reset_password
          );
        });
    },
    toggleActivateOperator(operator) {
      const toggleActivateAdmin = `admin/${operator.id}`;
      const toggleActivateModeratorOrObserver = `operator/${operator.id}`;
      api
        .put(
          `${
            operator.operator_type !== "Admin"
              ? toggleActivateModeratorOrObserver
              : toggleActivateAdmin
          }`,
          {
            is_active:
              operator.is_active === this.isActiveCode.notActive ? 1 : 0
          },
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${this.$store.getters.token}`
            }
          }
        )
        .then(r => {
          if (r.status === 200) {
            this.$toast.success(this.translation.success_global.changes_saved);
            operator.is_active =
              operator.is_active === this.isActiveCode.notActive ? "1" : "0";
          }
        })
        .catch(() => {
          this.$toast.error(
            this.translation.errors_global.something_went_wrong
          );
        });
    },
    deleteOperator(operator) {
      const deleteAdmin = `admin/${operator.id}`;
      const deleteModeratorOrObserver = `operator/${operator.id}`;
      api
        .delete(
          `${
            operator.operator_type !== "Admin"
              ? deleteModeratorOrObserver
              : deleteAdmin
          }`,

          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${this.$store.getters.token}`
            }
          }
        )
        .then(r => {
          if (r.status === 200) {
            this.$toast.success(
              this.translation.operators.manage_operators
                .successfully_deleted_access
            );
            this.getOperators();
          }
        })
        .catch(() => {
          this.$toast.error(
            this.translation.operators.manage_operators.error_deleting_access
          );
        });
    },
    handleDrop(drop) {
      const dropItem = document.querySelector(`#${drop}`);
      dropItem.children[1].classList.toggle("active");

      this.outsideClick(dropItem, ["click"], () => {
        dropItem.children[1].classList.remove("active");
      });
    }
  },
  created() {
    this.getOperators();
  }
};
</script>

<style lang="scss" scoped>
.table-wrapper {
  overflow-x: auto;
  overflow-y: hidden;
  padding-bottom: 114px;
  &::-webkit-scrollbar {
    height: 8px;
    overflow: hidden;
  }
  &::-webkit-scrollbar-track {
    background: rgba(0, 0, 0, 0);
    border-radius: 10px;
  }
  &::-webkit-scrollbar-thumb {
    background: #ccc;
    border-radius: 10px;
    height: 10px;
  }
  &::-webkit-scrollbar-thumb:hover {
    background: #aaa;
  }
}

.table {
  width: 100%;
}

.table-header {
  min-width: 100%;
  display: flex;
  width: fit-content;
  div {
    min-width: 200px;
    flex: 1;
    display: flex;
    justify-content: center;
    font-weight: bold;
  }
}

.table-content {
  min-width: 100%;
  width: fit-content;
  display: flex;
  border-radius: 50px;
  border: 1px solid #999999;
  div {
    min-width: 200px;
    flex: 1;
    display: flex;
    justify-content: center;
    color: #6b6b6b;
  }
  &.disable {
    border: 1px solid #cccccc;
    div,
    button {
      color: #cccccc !important;
    }
  }
}

.btn-actions {
  display: flex;
  align-items: center;
  font-weight: bold;
  background: none;
  border: none;
  &:focus {
    outline: none;
  }
  &.active {
    color: #ccc !important;
  }
}

.dropdown-actions {
  position: relative;
  display: flex;
  justify-content: center;
}

#dropdown {
  display: none;
  animation: grow 400ms ease-in-out forwards;
  transform-origin: top center;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 4px;
  position: absolute;
  top: 40px;
  right: 10px;
  z-index: 5;
  background: #fff;
  width: 170px;
  &.active {
    display: block;
  }
  a {
    display: flex;
    align-items: center;
    cursor: pointer;
    padding: 5px 10px;
    color: #999;
    text-decoration: none;
    font-weight: normal;
    &:hover {
      background: rgba(193, 193, 193, 0.4);
    }
    &:first-of-type {
      border-top-right-radius: 4px;
      border-top-left-radius: 4px;
    }
    &:last-of-type {
      border-bottom-right-radius: 4px;
      border-bottom-left-radius: 4px;
    }
    &.active {
      background: rgba(193, 193, 193, 0.25);
    }
    .circle {
      width: 1rem;
      height: 1rem;
      display: flex;
      align-items: center;
      justify-content: center;
      border: 1px solid #999;
      border-radius: 50%;
      font-size: 0.9rem;
    }
  }
}
input {
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
  max-width: 200px !important;
}
.edit-operator {
  button {
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    background: #10c398;
    border: 1px solid #10c398;
    border-radius: 0 !important;
    border-top-right-radius: 0.2rem !important;
    border-bottom-right-radius: 0.2rem !important;
    &.delete {
      background: #df2938;
      border: 1px solid #df2938;
      border-top-left-radius: 0.2rem !important;
      border-bottom-left-radius: 0.2rem !important;
      border-top-right-radius: 0 !important;
      border-bottom-right-radius: 0 !important;
    }
  }
}
@media (max-width: 567.98px) {
  .search-position {
    justify-content: center !important;
  }
}
</style>
