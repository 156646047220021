var render = function () {
var _obj;
var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"d-flex align-items-center flex-wrap py-4 px-2",staticStyle:{"min-height":"85px"}},[_c('div',{staticClass:"d-flex align-items-center pr-3"},[_c('span',{staticClass:"pb-1"},[_vm._v(_vm._s(_vm.translation.create_task.slider.points)+":")]),_c('dropdown',{attrs:{"width":60,"dropId":"dot","options":_vm.options,"value":_vm.dataDot},on:{"update":function (data) { return (_vm.dataDot = data); }}})],1),_c('div',{staticClass:"d-flex mr-2 my-3 my-md-0"},[_c('span',{staticClass:"pr-2"},[_vm._v("Emoji")]),_c('b-form-checkbox',{attrs:{"name":"check-button","switch":""},model:{value:(_vm.sliderEmoji),callback:function ($$v) {_vm.sliderEmoji=$$v},expression:"sliderEmoji"}})],1),_c('div',{staticClass:"error-box bg-danger p-2",class:{ active: _vm.sliderError }},[_c('b-icon',{staticClass:"mr-1",attrs:{"icon":"exclamation-triangle-fill"}}),_vm._v(" "+_vm._s(_vm.sliderError)+" ")],1)]),_c('div',{staticClass:"slider"},[_c('div',{staticClass:"d-flex flex-wrap align-items-center pb-5"},[_c('div',{staticClass:"d-flex flex-column flex-lg-row align-items-center",class:( _obj = {}, _obj['col-lg-8'] = _vm.sliderEmoji, _obj['col-12'] = !_vm.sliderEmoji, _obj )},_vm._l((_vm.slider),function(dot,index){
        var _obj;
return _c('div',{key:index,staticClass:"input-slider",style:({
            'border-color': ("" + (_vm.$store.getters.theme[2]))
          })},[_c('b-form-input',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",value:({ variant: 'secondary' }),expression:"{ variant: 'secondary' }",modifiers:{"hover":true}}],staticClass:"code",attrs:{"maxlength":"10","state":!_vm.isCodeValid(dot.codeString) &&
                !_vm.hasDuplicate(_vm.slider, 'codeString') &&
                !!dot.codeString.length &&
                dot.codeString !== '0',"title":_vm.translation.create_task.error.code_validation},model:{value:(dot.codeString),callback:function ($$v) {_vm.$set(dot, "codeString", $$v)},expression:"dot.codeString"}}),_c('input',{directives:[{name:"model",rawName:"v-model",value:(dot.value),expression:"dot.value"}],class:( _obj = {
              active: _vm.activeSliderInput === index
            }, _obj['text-danger'] = _vm.redText, _obj ),attrs:{"maxlength":"28","id":("dot-" + index),"type":"text"},domProps:{"value":(dot.value)},on:{"click":function($event){return _vm.activeInput(index)},"keyup":function($event){return _vm.activeInput(index)},"input":function($event){if($event.target.composing){ return; }_vm.$set(dot, "value", $event.target.value)}}}),_c('div',{staticClass:"dot",style:({
              background: _vm.$store.getters.theme[2]
            })})],1)}),0),_c('div',{staticClass:"col-lg-4 pt-5 d-flex justify-content-center"},[(_vm.sliderEmoji)?_c('VEmojiPicker',{on:{"select":_vm.selectEmoji}}):_vm._e()],1)])])])}
var staticRenderFns = []

export { render, staticRenderFns }