<template>
  <div>
    <div v-if="question.length" class="question">
      {{ question }}
    </div>
    <Pie
      ref="chart"
      v-if="chartData && chartOptions"
      :chart-options="chartOptions"
      :chart-data="chartData"
      :chart-id="chartId"
      :dataset-id-key="datasetIdKey"
      :plugins="plugins"
      :css-classes="cssClasses"
      :styles="styles"
      :width="width"
      :height="height"
    />
    <div class="d-flex justify-content-end">
      <button
        :class="{ hover: !pptLoader }"
        class="btn-download"
        :disabled="pptLoader"
        @click="exportPPT"
      >
        <div
          v-if="pptLoader"
          class="d-flex justify-content-center align-items-center"
        >
          <loader2 color="#6b6b6b" class="mr-1" />
          {{ translation.replies.charts_tab.download_chart }}
        </div>
        <div v-else>
          <b-icon icon="download" aria-hidden="true"></b-icon>
          {{ translation.replies.charts_tab.download_chart }}
        </div>
      </button>
    </div>
  </div>
</template>

<script>
import { Pie } from "vue-chartjs/legacy";
import pptxgen from "pptxgenjs";
import loader2 from "@/components/general/loader2.vue";

import {
  Chart as ChartJS,
  Title,
  Tooltip,
  Legend,
  ArcElement,
  CategoryScale
} from "chart.js";

ChartJS.register(Title, Tooltip, Legend, ArcElement, CategoryScale);

export default {
  name: "PieChart",
  components: {
    Pie,
    loader2
  },
  props: {
    chartId: {
      type: String,
      default: "pie-chart"
    },
    datasetIdKey: {
      type: String,
      default: "label"
    },
    width: {
      type: Number,
      default: 400
    },
    height: {
      type: Number,
      default: 400
    },
    cssClasses: {
      default: "",
      type: String
    },
    styles: {
      type: Object,
      default: () => {}
    },
    plugins: {
      type: Array,
      default: () => []
    },
    colors: {
      required: true
    },
    hasOneColor: {
      required: true
    },
    data: {
      required: true
    },
    getPercent: {
      required: true
    },
    isPercent: {
      required: true
    },
    question: {
      default: ""
    },
    statement: {
      required: true
    }
  },
  data() {
    return {
      chartData: null,
      chartOptions: null,
      pptLoader: false
    };
  },
  methods: {
    async exportPPT() {
      const backgroundColor = this.hasOneColor ? [this.colors[0]] : this.colors;
      this.pptLoader = true;
      let slide;
      let pres = new pptxgen();

      slide = pres.addSlide();
      slide.addText(this.statement, {
        x: 0.2,
        y: 0.2,
        color: "6b6b6b",
        fontSize: 10
      });

      slide.addText(this.question, {
        x: 0.5,
        y: 0.5,
        color: "6b6b6b",
        fontSize: 10
      });

      const labels = this.data.map(data => data.label);
      const data = this.data.map(item =>
        this.isPercent ? this.getPercent(item, this.data) : item.value
      );

      let options = {
        x: 0.8,
        y: 0.8,
        w: 6.0,
        h: 3.0,
        chartColors: backgroundColor,
        showLegend: true,
        showPercent: this.isPercent,
        showValue: !this.isPercent
      };

      slide.addChart(
        pres.charts.PIE,
        [
          {
            name: "",
            labels: labels,
            values: data
          }
        ],
        options
      );

      pres.writeFile({ fileName: "chart" });

      this.pptLoader = false;
    },
    setData() {
      const backgroundColor = this.hasOneColor ? [this.colors[0]] : this.colors;

      const labels = this.data.map(data => data.label);
      const data = this.data.map(item =>
        this.isPercent ? this.getPercent(item, this.data) : item.value
      );

      this.chartData = {
        labels: labels,
        datasets: [
          {
            backgroundColor: backgroundColor,
            data: data,
            borderWidth: 1
          }
        ]
      };
    },
    setOptions() {
      this.chartOptions = {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
          legend: {
            align: "start",
            labels: {
              boxWidth: 10,
              boxHeight: 10
            }
          }
        }
      };

      if (this.isPercent) {
        this.chartOptions.plugins.tooltip = {
          callbacks: {
            label: tooltipItem =>
              `${tooltipItem.label}: ${tooltipItem.raw.toFixed(2)}%`
          }
        };
      }
    }
  },
  mounted() {
    this.setOptions();
    this.setData();
  }
};
</script>

<style lang="scss" scoped>
.btn-download {
  display: flex;
  align-items: center;
  background: none;
  border: none;
  font-size: 1.2rem;
  font-weight: bold;
  color: #6b6b6b;
  margin-top: 30px;
  &:focus {
    outline: none;
  }
  svg {
    margin-right: 5px;
  }
}

.question {
  color: #6b6b6b;
  text-align: center;
  margin-bottom: 30px;
}
</style>
