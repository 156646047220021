var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"pb-0 px-md-5 px-3"},[_c('b-modal',{attrs:{"id":"modal-has-logic-ordering","cancel-title":_vm.translation.global.cancel},on:{"ok":function($event){$event.preventDefault();return _vm.applyOrdering(true)}},scopedSlots:_vm._u([{key:"modal-title",fn:function(){return [_c('b-icon',{staticClass:"mr-1",attrs:{"icon":"exclamation-triangle"}})]},proxy:true}])},[_c('div',[_vm._v(" "+_vm._s(_vm.translation.create_task.reorder_modal)+" ")])]),_c('div',{staticClass:"d-flex flex-wrap justify-content-between align-items-center mb-4"},[_c('p',{staticClass:"title-task mb-0",style:({ color: _vm.$store.getters.theme[3] })},[_vm._v(" "+_vm._s(_vm.translation.task.tasks)+" ")]),_c('div',{staticClass:"d-flex flex-wrap align-items-center mt-2"},[_c('button',{staticClass:"btn btn-ordering hover mr-3 my-1",on:{"click":function($event){return _vm.openTasks({
            _activity: _vm.activity.id,
            _pageParticipant: 1,
            _pageReplies: 1,
            _preview: true
          })}}},[_vm._v(" Preview ")]),_c('button',{staticClass:"btn btn-ordering hover my-1",on:{"click":function($event){(_vm.ordering = !_vm.ordering), (_vm.selectTask = null)}}},[_vm._v(" "+_vm._s(_vm.translation.create_task.reorder_tasks)+" ")])])]),(!_vm.loaderTasks)?_c('div',{staticClass:"p-3 drag-area",style:({
      border: _vm.ordering ? "1px dashed  #6b6b6b" : '1px solid white'
    })},[_c('div',{staticClass:"mb-4 d-flex justify-content-end"},[_c('button',{staticClass:"btn btn-apply hover",class:{ active: _vm.ordering },style:({ background: _vm.$store.getters.theme[2] }),on:{"click":function($event){return _vm.applyOrdering()}}},[(_vm.loaderOrdering)?_c('loader2',{attrs:{"color":"#ffffff"}}):_vm._e(),(!_vm.loaderOrdering)?_c('p',{staticClass:"m-0"},[_vm._v(" "+_vm._s(_vm.translation.global.apply)+" ")]):_vm._e()],1),_c('button',{staticClass:"btn btn-close hover",class:{ active: _vm.ordering },on:{"click":_vm.closeOrdering}},[_c('b-icon',{attrs:{"icon":"x"}})],1)]),_c('draggable',{staticClass:"d-flex flex-wrap",attrs:{"handle":".handle","list":_vm.tasksTemp,"group":"task"}},[_vm._l((_vm.tasksTemp),function(task,index){return _c('div',{key:task.id,staticClass:"col-md-3 col-lg-2",class:{ handle: _vm.ordering }},[_c('b-modal',{attrs:{"id":("modal-has-logic-delete-" + (task.id)),"cancel-title":_vm.translation.global.cancel},on:{"ok":function($event){$event.preventDefault();return _vm.deleteTask(task, true)}},scopedSlots:_vm._u([{key:"modal-title",fn:function(){return [_c('b-icon',{staticClass:"mr-1",attrs:{"icon":"exclamation-triangle"}})]},proxy:true}],null,true)},[_c('div',[_vm._v(" "+_vm._s(_vm.translation.create_task.delete_modal)+" ")])]),_c('b-modal',{attrs:{"id":("modal-delete-task-" + (task.id)),"cancel-title":_vm.translation.global.cancel},on:{"ok":function($event){$event.preventDefault();return _vm.deleteTask(task, true)}},scopedSlots:_vm._u([{key:"modal-title",fn:function(){return [_c('b-icon',{staticClass:"mr-1",attrs:{"icon":"exclamation-triangle"}})]},proxy:true}],null,true)},[_c('div',[_vm._v(" "+_vm._s(_vm.translation.create_task.delete_task_modal)+" ")])]),_c('div',{staticClass:"task px-2 py-3 mb-3",class:{ active: _vm.selectTask && _vm.selectTask.id === task.id },style:({
            background:
              _vm.selectTask && _vm.selectTask.id === task.id
                ? _vm.$store.getters.theme[0]
                : '#ffffff'
          })},[_c('img',{attrs:{"src":_vm.getImgUrl(task.type_id)}}),_c('div',{staticClass:"menu-wrapper",attrs:{"id":("menu-task-" + (task.id))}},[_c('button',{staticClass:"hover",style:({
                color: _vm.$store.getters.theme[2]
              }),on:{"click":function($event){return _vm.toggleMenu(task)}}},[_vm._v(" "+_vm._s(task.code_string ? task.code_string : ("T" + (+task.index + 1)))+" "),_c('b-icon',{attrs:{"icon":"caret-down-fill","aria-hidden":"true"}})],1),_c('div',{staticClass:"menu card",class:{ active: task.showMenu === task.id }},[_c('button',{on:{"click":function($event){return _vm.getTask(task.id, index)}}},[_vm._v(" "+_vm._s(_vm.translation.global.edit)+" ")]),_c('button',{on:{"click":function($event){return _vm.copyTask(task)}}},[_vm._v(" "+_vm._s(_vm.translation.global.copy)+" ")]),(!task.total_answers)?_c('button',{on:{"click":function($event){return _vm.deleteTask(task)}}},[_vm._v(" "+_vm._s(_vm.translation.global.delete)+" ")]):_vm._e()])]),_c('p',[_vm._v("ID "+_vm._s(task.id))]),_c('p',[_vm._v(" "+_vm._s(_vm.getText(task.type_id))+" ")])])],1)}),_c('div',{staticClass:"col-md-3 col-lg-2"},[_c('div',{staticClass:"task px-2 py-3 mb-3 add-btn hover",style:({
            background:
              _vm.selectTask === _vm.addTaskText ? _vm.$store.getters.theme[0] : '#ffffff'
          }),on:{"click":_vm.addTask}},[_c('b-icon',{attrs:{"icon":"plus"}})],1)])],2)],1):_c('div',{staticClass:"py-3 pl-3"},[_c('loader')],1),_c('div',{ref:"taskEditor"},[_c('task-editor',{attrs:{"tasks":_vm.tasks,"getTasks":_vm.getTasks,"task":_vm.selectTask,"activityId":_vm.activityId,"activity":_vm.activity,"loaderTask":_vm.loaderTask,"addTask":_vm.addTask,"getActivity":_vm.getActivity}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }