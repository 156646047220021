<template>
  <div class="pt-5">
    <h2 class="title my-1 my-md-0" :style="{ color: $store.getters.theme[3] }">
      {{ translation.add_participant.invite_participants }}
    </h2>
    <p class="description-text pt-2">
      {{ translation.add_participant.edit_as_many_fields }}
    </p>
    <div v-if="jsonFile" class="table-wrapper mb-5">
      <div class="table mt-5">
        <div :style="{ color: $store.getters.theme[3] }" class="table-header">
          <div>external_id</div>
          <div class="email">email</div>
          <div v-for="segment in segments" :key="segment.id">
            {{ segment.name }}
          </div>
        </div>
        <div
          class="table-content mt-2 p-1"
          v-for="(line, index) in jsonFile"
          :key="index"
        >
          <div>
            <input v-model="line.external_id" type="text" />
          </div>
          <div class="email">
            <input v-model="line.email" type="text" />
          </div>
          <div v-for="segment in segments" :key="segment.id">
            <input
              v-model="line[getKey(segment, line)]"
              type="number"
              min="0"
              max="1"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    next: {
      required: true
    },
    segments: {
      required: true
    }
  },
  data() {
    return {
      jsonFile: null
    };
  },
  watch: {
    next() {
      this.importJSON();
    }
  },
  methods: {
    getKey(segment, line) {
      const keys = Object.keys(line);

      const key = keys.filter(key => {
        const regexpMatch = key.match(/\[(\d+)]/);
        const id = regexpMatch ? regexpMatch[1] : -1;

        return segment.id === id;
      })[0];

      return key;
    },
    hasDuplicate(array) {
      const valuesSoFar = [];
      for (let i = 0; i < array.length; ++i) {
        const value = array[i].trim();
        if (valuesSoFar.indexOf(value) !== -1) {
          return true;
        }
        valuesSoFar.push(value);
      }
      return false;
    },
    fileErrorMessage(parsedFile) {
      const allEmails = parsedFile.map(line => line.email);
      if (this.hasDuplicate(allEmails)) {
        this.errorMessage = this.translation.add_participant.errors.duplicated_email;
      }

      return this.errorMessage;
    },
    importJSON() {
      if (!this.fileErrorMessage(this.jsonFile)) {
        const removedSpacesEmail = this.jsonFile.map(line => ({
          ...line,
          email: line.email.replace(/ /, "")
        }));

        this.$store.commit("UPDATE_IMPORT_LOT_JOSN", removedSpacesEmail);
        this.$emit("goFowards");
      } else {
        this.$toast.error(this.fileErrorMessage(this.jsonFile));
      }
    }
  },
  created() {
    this.jsonFile = this.$store.getters.importLotJson.map(line => ({
      ...line,
      email: line.email.replace(/ /, "")
    }));

    if (!this.jsonFile) {
      this.$emit("goBack");
    }
  }
};
</script>

<style lang="scss" scoped>
.title {
  font-weight: bold;
  font-size: 1.3rem;
}

.description-text {
  color: #6b6b6b;
}

.table-wrapper {
  overflow-x: auto;
  &::-webkit-scrollbar {
    height: 8px;
    overflow: hidden;
  }
  &::-webkit-scrollbar-track {
    background: rgba(0, 0, 0, 0);
    border-radius: 10px;
  }
  &::-webkit-scrollbar-thumb {
    background: #ccc;
    border-radius: 10px;
    height: 10px;
  }
  &::-webkit-scrollbar-thumb:hover {
    background: #aaa;
  }
}

.table {
  width: 100%;
}

.table-header {
  min-width: 100%;
  display: flex;
  width: fit-content;
  div {
    min-width: 150px;
    flex: 1;
    display: flex;
    justify-content: center;
    font-weight: bold;
    font-size: 0.9rem;
    &.email {
      min-width: 200px;
    }
  }
}

.table-content {
  min-width: 100%;
  width: fit-content;
  display: flex;
  border-radius: 50px;
  border: 1px solid #999999;
  div {
    min-width: 150px;
    flex: 1;
    display: flex;
    justify-content: center;
    input {
      color: #6b6b6b;
      font-size: 0.9rem;
      width: 100%;
      text-align: center;
      background: none;
      border: none;
      background: #ffffff;
      transition: all 0.3s ease;
      &:focus {
        outline: none;
        background: #f7fafa;
        color: #000;
      }
    }
    &.email {
      min-width: 200px;
    }
  }
}
</style>
