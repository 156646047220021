var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"header py-3"},[_c('div',{staticClass:"container"},[_c('div',{staticClass:"row"},[_c('div',{ref:"menuOutside",staticClass:"col"},[_c('slide',[_c('div',{staticClass:"d-flex align-items-center justify-content-between"},[_c('div',{staticClass:"user"},[_c('div',{staticClass:"profile-img"},[(_vm.$store.getters.info.user.picture)?_c('img',{attrs:{"src":_vm.$store.getters.info.user.picture,"alt":"Profile Picture"}}):_c('b-icon',{staticClass:"icon-profile",attrs:{"icon":"person-fill","aria-hidden":"true"}})],1),_c('p',{staticClass:"pb-1"},[_vm._v(" "+_vm._s(_vm.$store.getters.info.user.name.split(" ")[0])+" ")])]),(
                _vm.$route.name !== 'communities' &&
                  _vm.$route.name !== 'createcommunity'
              )?_c('notification',{attrs:{"mobile":true}}):_vm._e()],1),_c('nav',[(
                _vm.$route.name !== 'communities' &&
                  _vm.$route.name !== 'createcommunity'
              )?_c('div',[_c('router-link',{attrs:{"to":{ name: 'feed' }}},[_c('img',{attrs:{"src":require("@/img/feed.svg"),"alt":"Feed"}}),_vm._v(" "+_vm._s(_vm.translation.header.feed_text)+" ")]),_c('a',{on:{"click":_vm.openActivities}},[_c('img',{attrs:{"src":require("@/img/activities.svg"),"alt":"Activities"}}),_vm._v(" "+_vm._s(_vm.translation.header.assignments_text)+" ")]),_c('sub-header-activities-mobile',{attrs:{"showActivities":_vm.showActivities}}),_c('router-link',{attrs:{"to":{ name: 'forum' }}},[_c('img',{attrs:{"src":require("@/img/forum.svg"),"alt":"Forum"}}),_vm._v(" "+_vm._s(_vm.translation.header.forum_text)+" ")]),(
                  _vm.$store.getters.info.user.type === _vm.userTypesText.admin ||
                    _vm.$store.getters.info.user.type ===
                      _vm.userTypesText.moderator ||
                    (_vm.$store.getters.info.user.type ===
                      _vm.userTypesText.observer &&
                      (!+_vm.permissions.observers.hide_report_replies ||
                        !+_vm.permissions.observers.hide_report_media ||
                        !+_vm.permissions.observers.hide_report_charts))
                )?_c('a',{on:{"click":_vm.goToAnswers}},[_c('img',{attrs:{"src":require("@/img/answers.svg"),"alt":"Answers"}}),_vm._v(" "+_vm._s(_vm.translation.header.reports_text)+" ")]):_vm._e(),(
                  _vm.$store.getters.info.user.type === _vm.userTypesText.moderator ||
                    _vm.$store.getters.info.user.type === _vm.userTypesText.admin ||
                    _vm.$store.getters.info.user.type === _vm.userTypesText.observer
                )?_c('a',{on:{"click":_vm.openAdmin}},[_c('img',{attrs:{"src":require("@/img/admin.svg"),"alt":"Activities"}}),_vm._v(" "+_vm._s(_vm.$store.getters.info.user.type === _vm.userTypesText.admin ? _vm.translation.header.admin_text : _vm.$store.getters.info.user.type === _vm.userTypesText.moderator ? _vm.translation.header.moderator_text : _vm.translation.header.observer_text)+" ")]):_vm._e(),_c('sub-header-admin-mobile',{attrs:{"showAdmin":_vm.showAdmin}}),(
                  _vm.$route.name !== 'communities' &&
                    _vm.$route.name !== 'createcommunity'
                )?_c('router-link',{class:{
                  disabled: _vm.$store.getters.is_over
                },attrs:{"disabled":_vm.$store.getters.is_over,"to":{ name: 'account' }}},[_c('img',{attrs:{"src":require("@/img/account.svg"),"alt":"Account"}}),_vm._v(" "+_vm._s(_vm.translation.header.account_settings_text)+" ")]):_vm._e()],1):_vm._e(),_c('a',{staticClass:"pointer",on:{"click":_vm.logout}},[_c('b-icon',{staticClass:"mr-2",style:({ color: _vm.$store.getters.theme[2] }),attrs:{"icon":"box-arrow-right","aria-hidden":"true"}}),_c('span',{style:({ color: _vm.$store.getters.theme[2] })},[_vm._v(" "+_vm._s(_vm.translation.header.logout)+" ")])],1)])])],1),_c('div',{staticClass:"col"},[_c('div',{staticClass:"logo"},[_c('router-link',{attrs:{"to":{ name: 'feed' }}},[(
                _vm.$store.getters.community &&
                  _vm.$store.getters.community.logo &&
                  _vm.$store.getters.community.logo.length
              )?_c('img',{staticClass:"img-fluid",attrs:{"src":_vm.$store.getters.community.logo,"alt":"Logo"}}):_c('img',{attrs:{"src":require("@/img/logo-communities.png"),"alt":"Logo"}})])],1)])])])])}
var staticRenderFns = []

export { render, staticRenderFns }