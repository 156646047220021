<template>
  <div class="row task-wrapper">
    <div v-if="!loading" class="col">
      <div class="task-text img-question">
        <p v-html="text"></p>
      </div>
      <div class="d-flex flex-wrap">
        <attachment :attachments="attachments" :deleteFile="null" />
      </div>
      <div>
        <div class="d-flex justify-content-center py-4">
          <p
            class="select-box"
            :style="{ background: $store.getters.theme[1] }"
          >
            {{
              onlyChoice
                ? translation.answer_task.survey.choose_alternatives
                : translation.answer_task.survey.select_alternatives
            }}
          </p>
        </div>
        <div>
          <b-form-group v-if="!onlyChoice">
            <b-form-checkbox-group
              id="checkbox-group-2"
              v-model="selectedCheckBox"
              name="flavour-2"
              size="lg"
            >
              <div>
                <div
                  class="d-flex align-items-center mb-3"
                  v-for="(option, index) in options"
                  :key="index"
                >
                  <b-form-checkbox
                    @change="changeOptions(option.value)"
                    class="checkbox-survey w-100"
                    size="lg"
                    :disabled="task.is_answered"
                    :value="option.value"
                    >{{ option.text }}</b-form-checkbox
                  >
                </div>
              </div>
            </b-form-checkbox-group>
          </b-form-group>
          <b-form-group v-if="onlyChoice">
            <b-form-radio-group
              id="radio-group-2"
              v-model="selectedRadio"
              name="radio-sub-component"
              size="lg"
            >
              <div>
                <div
                  class="d-flex align-items-center mb-3"
                  v-for="(option, index) in options"
                  :key="index"
                >
                  <b-form-radio
                    @change="isOther(option.value)"
                    class="checkbox-survey w-100"
                    size="lg"
                    :disabled="task.is_answered"
                    :value="option.value"
                    >{{ option.text }}</b-form-radio
                  >
                </div>
              </div>
            </b-form-radio-group>
          </b-form-group>
        </div>
        <div v-if="otherInput" class="other pb-4">
          <b-form-input
            v-model="otherText"
            :state="isOtherValid && !!otherText.length"
            v-b-tooltip.hover="{ variant: 'secondary' }"
            maxlength="50"
            :title="
              otherText.length
                ? isOtherValid
                  ? translation.answer_task.survey.other
                  : translation.create_task.error.the_field_cannot
                : translation.answer_task.error.other_unanswered
            "
          ></b-form-input>
        </div>
        <div class="comments">
          <label
            for="comments"
            v-if="
              task.question_object.has_custom_comment_label &&
                task.question_object.comment_label &&
                task.question_object.comment_label.length
            "
            >{{ task.question_object.comment_label }}</label
          >
          <label for="comments" v-else>
            {{ translation.answer_task.comments }}
          </label>
          <textarea
            class="form-control"
            id="textarea"
            v-model="comments"
            :maxlength="maxCharacters"
            rows="3"
            max-rows="6"
            :disabled="task.is_answered"
          ></textarea>
          <characterAndWordCounter
            :count="
              hasWordsLimitation
                ? this.comments.length
                  ? this.comments.trim().split(/\s+/).length
                  : 0
                : comments.length
            "
            :max="hasWordsLimitation ? maxWords : maxCharacters"
            :label="
              hasWordsLimitation
                ? translation.answer_task.words
                : translation.answer_task.characters
            "
          />
        </div>
      </div>
    </div>
    <div v-if="loading" class="w-100 d-flex justify-content-center">
      <loader />
    </div>
  </div>
</template>

<script>
import { api } from "@/services.js";
import loader from "@/components/general/loader.vue";
import attachment from "@/components/general/attachment.vue";
import characterAndWordCounter from "@/components/general/characterAndWordCounter.vue";

import { userTypesText } from "@/constants";

export default {
  components: { loader, attachment, characterAndWordCounter },
  props: {
    task: {
      required: true
    },
    save: {
      required: true
    },
    savePreview: {
      required: true
    },
    getTasks: {
      required: true
    }
  },
  data() {
    return {
      selectedCheckBox: [],
      selectedRadio: "",
      comments: "",
      question: "",
      otherInput: false,
      otherText: "",
      loading: false
    };
  },
  computed: {
    isOtherValid() {
      return this.otherText !== "0";
    },
    attachments() {
      return this.task.attachments;
    },
    text() {
      const regexpVideo = /(?:(<iframe.*?src="))(.*?)((?:".*?>))((?:<\/iframe>))?/g;

      return this.$store.getters.info.user.type === userTypesText.participant
        ? this.task.raw_question.replace(
            regexpVideo,
            "<video src='$2' width='350' height='197' frameborder='0' allowfullscreen='allowfullscreen' controls controlsList='nodownload'></iframe>"
          )
        : this.task.raw_question;
    },
    none() {
      return this.task.question_object.has_none ? true : false;
    },
    other() {
      return this.task.question_object.has_other ? true : false;
    },
    options() {
      const options = this.task.question_object.options;
      const array = [];

      options.forEach(option => {
        array.push({ text: option.option, value: option.option });
      });

      if (this.other) {
        array.push({
          text: this.translation.answer_task.survey.other,
          value: "other"
        });
      }

      if (this.none) {
        array.push({
          text: this.translation.answer_task.survey.none,
          value: "none"
        });
      }

      return array;
    },
    onlyChoice() {
      return this.task.question_object.is_single_choice ? true : false;
    },
    page() {
      return this.$store.getters.activeTaskIndex;
    },
    obligatoryComment() {
      return this.task.question_object?.has_obligatory_comments;
    },

    hasCharactersLimitation() {
      return this.task.question_object?.has_characters_limitation;
    },

    maxCharacters() {
      return this.hasCharactersLimitation
        ? this.task.question_object?.characters_count?.maximum
        : 5000;
    },

    minCharacters() {
      return this.hasCharactersLimitation
        ? this.task.question_object?.characters_count?.minimum
        : 0;
    },

    hasWordsLimitation() {
      return this.task.question_object?.has_words_limitation;
    },

    maxWords() {
      return this.hasWordsLimitation
        ? this.task.question_object?.words_count?.maximum
        : 2000;
    },

    minWords() {
      return this.hasWordsLimitation
        ? this.task.question_object?.words_count?.minimum
        : 0;
    }
  },
  watch: {
    save() {
      this.answer();
    },
    savePreview() {
      this.answerPreview();
    },
    page() {
      this.getQuestion();
    },
    comments() {
      if (this.comments.length >= this.maxCharacters) {
        this.comments = this.comments
          .split("")
          .splice(0, this.maxCharacters)
          .join("");
      }

      const words = this.comments.split(/\s+/);

      const wordsCount = words.length;

      if (wordsCount > this.maxWords) {
        this.comments = words.splice(0, this.maxWords).join(" ");
      }
    }
  },
  methods: {
    isOther(option) {
      if (option === "other") {
        this.otherInput = true;
      } else {
        this.otherInput = false;
      }
    },
    changeOptions(option) {
      if (option === "none") {
        this.selectedCheckBox = ["none"];
        this.otherInput = false;
      } else if (this.selectedCheckBox.includes("none")) {
        this.selectedCheckBox.splice(0, 1);
      } else if (option === "other") {
        if (!this.selectedCheckBox.includes("other")) {
          this.otherInput = false;
        } else {
          this.otherInput = true;
        }
      } else if (!this.selectedCheckBox.includes("other")) {
        this.otherInput = false;
      }
    },
    answerPreview() {
      if (!this.loading) {
        if (
          (this.onlyChoice && this.selectedRadio !== "") ||
          (!this.onlyChoice && this.selectedCheckBox.length)
        ) {
          if (
            (this.otherInput && this.otherText.length && this.isOtherValid) ||
            !this.otherInput
          ) {
            if (this.obligatoryComment && !this.comments.length) {
              this.$toast.error(
                this.translation.answer_task.obligatory_comment_tost
              );

              return;
            }

            if (
              this.comments.length &&
              this.hasCharactersLimitation &&
              this.comments.length < this.minCharacters
            ) {
              this.$toast.error(
                `${this.translation.answer_task.min_characters} ${this.minCharacters} ${this.translation.answer_task.characters}`
              );

              return;
            }

            if (this.comments.length && this.hasWordsLimitation) {
              const wordsCount = this.comments.split(/\s+/).length;

              if (wordsCount < this.minWords) {
                this.$toast.error(
                  `${this.translation.answer_task.min_word} ${this.minWords} ${this.translation.answer_task.words}`
                );

                return;
              }
            }

            this.loading = true;

            const answer = {
              comment: this.comments,
              other_answer: this.otherInput ? this.otherText : "",
              options: this.options
                .filter(
                  option => option.value !== "none" && option.value !== "other"
                )
                .map(option => {
                  if (this.onlyChoice) {
                    return {
                      text: option.value,
                      marked: option.value === this.selectedRadio ? 1 : 0
                    };
                  } else {
                    return {
                      text: option.value,
                      marked: this.selectedCheckBox.includes(option.value)
                        ? 1
                        : 0
                    };
                  }
                })
            };

            if (this.onlyChoice) {
              answer.is_other_marked = this.selectedRadio === "other" ? 1 : 0;
              answer.is_none_marked = this.selectedRadio === "none" ? 1 : 0;
            } else {
              answer.is_other_marked = this.selectedCheckBox.includes("other")
                ? 1
                : 0;
              answer.is_none_marked = this.selectedCheckBox.includes("none")
                ? 1
                : 0;
            }

            const body = {
              task_id: this.task.id,
              has_attachment: 0,
              has_video: 0,
              has_image: 0,
              raw_answer: JSON.stringify(answer),
              answer
            };

            api
              .post("/answer/send/preview", body, {
                headers: {
                  "Content-Type": "application/json",
                  Authorization: `Bearer ${this.$store.getters.token}`
                }
              })
              .then(r => {
                if (r.status === 200) {
                  this.$toast.success(
                    this.translation.answer_task.success.reply_registered
                  );
                  this.getTasks("save");
                  this.loading = false;
                }
              })
              .catch(() => {
                this.$toast.error(
                  this.translation.answer_task.error.reply_not_registered
                );
                this.loading = false;
              });
          } else {
            if (!this.isOtherValid) {
              this.$toast.error(
                this.translation.create_task.error.the_field_cannot
              );
            } else {
              this.$toast.error(
                this.translation.answer_task.error.other_unanswered
              );
            }
          }
        } else {
          this.$toast.error(this.translation.answer_task.error.empty_answer);
        }
      }
    },
    answer() {
      if (!this.loading) {
        if (
          (this.onlyChoice && this.selectedRadio !== "") ||
          (!this.onlyChoice && this.selectedCheckBox.length)
        ) {
          if (
            (this.otherInput && this.otherText.length && this.isOtherValid) ||
            !this.otherInput
          ) {
            if (this.obligatoryComment && !this.comments.length) {
              this.$toast.error(
                this.translation.answer_task.obligatory_comment_tost
              );

              return;
            }

            if (
              this.comments.length &&
              this.hasCharactersLimitation &&
              this.comments.length < this.minCharacters
            ) {
              this.$toast.error(
                `${this.translation.answer_task.min_characters} ${this.minCharacters} ${this.translation.answer_task.characters}`
              );

              return;
            }

            if (this.comments.length && this.hasWordsLimitation) {
              const wordsCount = this.comments.split(/\s+/).length;

              if (wordsCount < this.minWords) {
                this.$toast.error(
                  `${this.translation.answer_task.min_word} ${this.minWords} ${this.translation.answer_task.words}`
                );

                return;
              }
            }

            this.loading = true;

            const answer = {
              comment: this.comments,
              other_answer: this.otherInput ? this.otherText : "",
              options: this.options
                .filter(
                  option => option.value !== "none" && option.value !== "other"
                )
                .map(option => {
                  if (this.onlyChoice) {
                    return {
                      text: option.value,
                      marked: option.value === this.selectedRadio ? 1 : 0
                    };
                  } else {
                    return {
                      text: option.value,
                      marked: this.selectedCheckBox.includes(option.value)
                        ? 1
                        : 0
                    };
                  }
                })
            };

            if (this.onlyChoice) {
              answer.is_other_marked = this.selectedRadio === "other" ? 1 : 0;
              answer.is_none_marked = this.selectedRadio === "none" ? 1 : 0;
            } else {
              answer.is_other_marked = this.selectedCheckBox.includes("other")
                ? 1
                : 0;
              answer.is_none_marked = this.selectedCheckBox.includes("none")
                ? 1
                : 0;
            }

            const body = {
              task_id: this.task.id,
              participant_id: this.$store.getters.info.user.sub,
              has_attachment: 0,
              has_video: 0,
              has_image: 0,
              raw_answer: JSON.stringify(answer),
              answer
            };

            api
              .post("/answer/send", body, {
                headers: {
                  "Content-Type": "application/json",
                  Authorization: `Bearer ${this.$store.getters.token}`
                }
              })
              .then(r => {
                if (r.status === 200) {
                  this.$toast.success(
                    this.translation.answer_task.success.reply_registered
                  );
                  this.getTasks("save");
                  this.loading = false;
                }
              })
              .catch(() => {
                this.$toast.error(
                  this.translation.answer_task.error.reply_not_registered
                );
                this.loading = false;
              });
          } else {
            if (!this.isOtherValid) {
              this.$toast.error(
                this.translation.create_task.error.the_field_cannot
              );
            } else {
              this.$toast.error(
                this.translation.answer_task.error.other_unanswered
              );
            }
          }
        } else {
          this.$toast.error(this.translation.answer_task.error.empty_answer);
        }
      }
    },
    getQuestion() {
      this.selectedCheckBox = [];
      this.selectedRadio = "";
      this.otherInput = false;
      this.otherText = "";
      this.comments = "";
      this.loading = false;

      this.init();
    },
    init() {
      if (
        this.task.is_answered ||
        (this.task.answer && this.$route.query._preview)
      ) {
        if (this.onlyChoice) {
          this.comments = this.task.answer.answer_object.comment;
          this.selectedRadio = this.task.answer.answer_object.options.filter(
            option => option.marked
          )[0].text;

          if (this.task.answer.answer_object.is_none_marked)
            this.selectedRadio = "none";

          if (this.task.answer.answer_object.is_other_marked) {
            this.selectedRadio = "other";
            this.otherInput = true;
            this.otherText = this.task.answer.answer_object.other_answer;
          }
        } else {
          this.comments = this.task.answer.answer_object.comment;
          this.selectedCheckBox = this.task.answer.answer_object.options
            .filter(option => option.marked)
            .map(option => option.text);

          if (this.task.answer.answer_object.is_none_marked)
            this.selectedCheckBox.push("none");

          if (this.task.answer.answer_object.is_other_marked) {
            this.selectedCheckBox.push("other");
            this.otherInput = true;
            this.otherText = this.task.answer.answer_object.other_answer;
          }
        }
      }
    }
  },
  created() {
    this.getQuestion();
  },
  mounted() {
    if (this.$store.getters.info.user.type === userTypesText.participant) {
      const taskWrapper = document.querySelector(".task-wrapper");

      taskWrapper.addEventListener("contextmenu", e => {
        e.preventDefault();
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.task-text {
  display: flex;
  flex-direction: column;
  color: #6b6b6b;
  overflow-wrap: break-word;
}

.select-box {
  display: inline-block;
  padding: 20px;
  border-radius: 20px;
  color: #6b6b6b;
  font-weight: bold;
  font-size: 1.2rem;
}

.comments {
  label {
    color: #6b6b6b;
  }
  textarea {
    width: 100%;
    display: block;
    border: 1px solid #b3b3b3;
    border-radius: 10px;
  }
}

.other {
  position: relative;
  z-index: 20;
  input {
    width: 100%;
    display: block;
    border: 1px solid #b3b3b3;
    border-radius: 10px;
    cursor: auto !important;
    text-align: left;
    color: #6b6b6b;
  }
}

.custom-control-label {
  color: red;
}

.checkbox-survey {
  min-height: 50px;
  word-wrap: break-word;
  word-break: break-word;
}

input,
input:before,
input:after {
  -webkit-user-select: initial;
  -khtml-user-select: initial;
  -moz-user-select: initial;
  -ms-user-select: initial;
  user-select: initial;
}
</style>
