<template>
  <div class="create-community">
    <div class="container">
      <div class="row py-5">
        <div class="col">
          <div class="card-b p-5">
            <h2 class="title mb-4" :style="{ color: $store.getters.theme[3] }">
              {{ translation.segmentation.segmentation }}
            </h2>
            <form>
              <label for="segments">
                {{ translation.segmentation.create_segmentation }}
              </label>
              <p>{{ translation.segmentation.name_a_segment }}</p>
              <div class="d-flex flex-md-nowrap flex-wrap align-items-center">
                <b-form-textarea
                  class="mr-3 px-3 py-1 my-1"
                  id="segments"
                  size="sm"
                  :placeholder="translation.segmentation.segments_placeholder"
                  v-model="segmentsText"
                ></b-form-textarea>
                <button @click.prevent="addSegment" class="btn px-5 hover my-1">
                  <div
                    v-if="loaderAddSegment"
                    class="d-flex justify-content-center"
                  >
                    <loader2 color="#6b6b6b" />
                  </div>
                  <p v-else class="m-0">
                    {{ translation.global.add }}
                  </p>
                </button>
              </div>
            </form>
            <div class="d-flex justify-content-center">
              <div class="segments-table col-md-8">
                <div
                  v-if="filterSegments.length && !loaderSegments"
                  class="table-wrapper"
                >
                  <div class="table mt-5">
                    <div
                      :style="{ color: $store.getters.theme[3] }"
                      class="table-header"
                    >
                      <div>ID</div>
                      <div>{{ translation.segmentation.table.name }}</div>
                      <div></div>
                    </div>
                    <div
                      v-for="segment in filterSegments"
                      :key="segment.id"
                      :class="{ disable: !segment.is_active }"
                      class="table-content mt-2 p-1"
                    >
                      <div>{{ segment.id }}</div>
                      <div>
                        <div class="edit-segment" v-if="segment.isEditable">
                          <b-form-input
                            size="sm"
                            v-model="segment.name"
                          ></b-form-input>
                          <button
                            @click="cancelEditSegment(segment)"
                            class="btn btn-sm delete"
                          >
                            <b-icon icon="x"></b-icon>
                          </button>
                          <button
                            @click="editSegment(segment)"
                            class="btn btn-sm"
                          >
                            <b-icon icon="check"></b-icon>
                          </button>
                        </div>
                        <p v-else class="m-0">{{ segment.name }}</p>
                      </div>
                      <div>
                        <div
                          class="dropdown-actions"
                          :id="`dropdown-actions-${segment.id}`"
                        >
                          <button
                            :style="{ color: $store.getters.theme[2] }"
                            class="btn-actions hover"
                            @click="
                              handleDrop(`dropdown-actions-${segment.id}`)
                            "
                          >
                            {{ translation.global.actions }}
                            <b-icon
                              class="m-0"
                              icon="caret-right-fill"
                              aria-hidden="true"
                            ></b-icon>
                          </button>
                          <div id="dropdown">
                            <a @click="openEditSegment(segment)">
                              <b-icon class="mr-1" icon="pencil-fill"></b-icon>
                              {{ translation.global.edit }}
                            </a>
                            <a @click="toggleActivateSegment(segment)">
                              <b-icon
                                v-if="segment.is_active"
                                class="mr-1 circle"
                                icon="slash"
                              ></b-icon>
                              <b-icon
                                v-else
                                class="mr-1 circle"
                                icon="play-fill"
                              ></b-icon>
                              {{
                                segment.is_active
                                  ? translation.global.disable
                                  : translation.global.activate
                              }}
                            </a>

                            <a
                              v-if="segment.can_be_deleted"
                              @click="
                                $bvModal.show(`deleteSegment${segment.id}`)
                              "
                            >
                              <b-icon class="mr-1" icon="trash-fill"></b-icon>
                              {{ translation.global.delete }}
                            </a>
                            <b-modal
                              @ok="deleteSegment(segment)"
                              :cancel-title="translation.global.cancel"
                              :id="`deleteSegment${segment.id}`"
                              :title="
                                translation.segmentation.modal.delete_segment
                              "
                            >
                              {{
                                translation.segmentation.modal
                                  .do_you_really_want_to_delete
                              }}
                            </b-modal>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <pagination-query
                    v-if="minPerPage < total"
                    :perPage="perPage"
                    :total="total"
                    :options="[10, 20, 50, 100]"
                    @perPageUpdate="newPerPage => (perPage = newPerPage)"
                  />
                </div>
                <div
                  v-else-if="loaderSegments"
                  class="d-flex justify-content-center py-5"
                >
                  <loader />
                </div>
                <div class="d-flex justify-content-center py-5" v-else>
                  {{ translation.segmentation.no_segments }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { api } from "@/services.js";
import paginationQuery from "@/components/general/paginationQuery.vue";
import loader from "@/components/general/loader.vue";
import loader2 from "@/components/general/loader2.vue";
import { outsideClick } from "@/helpers.js";

export default {
  components: { paginationQuery, loader, loader2 },
  data() {
    return {
      segmentsText: "",
      segments: [],
      loaderSegments: false,
      loaderAddSegment: false,
      minPerPage: 10,
      perPage: 10,
      total: 0,
      currentSegmentName: {},
      outsideClick
    };
  },
  computed: {
    page() {
      return +this.$route.query._page;
    },
    filterSegments() {
      let array = [];

      const start = this.perPage * (this.page - 1);
      this.segments.forEach((segment, index) => {
        if (index >= start && index < start + this.perPage) array.push(segment);
      });

      return array;
    }
  },
  methods: {
    openEditSegment(segment) {
      this.currentSegmentName[segment.id] = segment.name;
      segment.isEditable = true;
    },
    editSegment(segment) {
      api
        .put(
          `admin/manage/community/segment/${segment.id}`,
          {
            name: segment.name
          },
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${this.$store.getters.token}`
            }
          }
        )
        .then(r => {
          if (r.status === 200) {
            this.$toast.success(this.translation.success_global.changes_saved);
            segment.isEditable = false;
          }
        })
        .catch(() => {
          segment.name = this.currentSegmentName[segment.id];
          this.$toast.error(
            this.translation.errors_global.something_went_wrong
          );
        });
    },
    cancelEditSegment(segment) {
      segment.name = this.currentSegmentName[segment.id];
      segment.isEditable = false;
    },
    toggleActivateSegment(segment) {
      api
        .put(
          `admin/manage/community/segment/${segment.id}`,
          {
            is_active: !segment.is_active ? 1 : 0
          },
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${this.$store.getters.token}`
            }
          }
        )
        .then(r => {
          if (r.status === 200) {
            this.$toast.success(this.translation.success_global.changes_saved);
            segment.is_active = !segment.is_active ? 1 : 0;
          }
        })
        .catch(() => {
          this.$toast.error(
            this.translation.errors_global.something_went_wrong
          );
        });
    },
    deleteSegment(segment) {
      api
        .delete(`admin/manage/community/segment/${segment.id}`, {
          headers: {
            Authorization: `Bearer ${this.$store.getters.token}`,
            "Content-Type": "application/json"
          }
        })
        .then(r => {
          if (r.status === 200) {
            this.$toast.success(
              this.translation.segmentation.modal.success.segment_deleted
            );
            this.getSegments();
          }
        });
    },
    addSegment() {
      if (!this.loaderAddSegment) {
        this.loaderAddSegment = true;
        const splitSegments = this.segmentsText
          .split(";")
          .filter(segment => segment.length);

        api
          .post(
            `admin/manage/community/segment`,
            {
              segments: splitSegments,
              community_id: this.$store.getters.community.id
            },
            {
              headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${this.$store.getters.token}`
              }
            }
          )
          .then(r => {
            if (r.status === 201) {
              this.segmentsText = "";
              this.getSegments();
              this.$toast.success(
                this.translation.segmentation.success.added_segment
              );
            }
            this.loaderAddSegment = false;
          })
          .catch(() => {
            this.loaderAddSegment = false;
            this.$toast.error(
              this.translation.errors_global.something_went_wrong
            );
          });
      }
    },
    getSegments() {
      this.loaderSegments = true;
      api
        .get(`community/${this.$store.getters.community.id}/segments`, {
          headers: {
            Authorization: `Bearer ${this.$store.getters.token}`
          }
        })
        .then(r => {
          if (r.status === 200) {
            this.segments = r.data.map(segment => ({
              ...segment,
              isEditable: false,
              is_active: +segment.is_active
            }));
            this.total = this.segments.length;
          }
          this.loaderSegments = false;
        })
        .catch(() => {
          this.loaderSegments = false;
        });
    },
    handleDrop(drop) {
      const dropItem = document.querySelector(`#${drop}`);
      dropItem.children[1].classList.toggle("active");

      this.outsideClick(dropItem, ["click"], () => {
        dropItem.children[1].classList.remove("active");
      });
    }
  },
  created() {
    this.getSegments();
  }
};
</script>

<style lang="scss" scoped>
.title {
  font-weight: bold;
  font-size: 1.3rem;
}

form {
  label {
    color: #6b6b6b;
    font-weight: bold;
    font-size: 1.1rem;
  }
  p {
    color: #6b6b6b;
  }
  textarea {
    height: 38px;
    border-radius: 10px;
    border: 1px solid #e6e6e6;
  }
  button {
    background: #ebf2f2;
    border-radius: 25px;
    text-transform: uppercase;
    font-weight: bold;
    color: #6b6b6b;
    &:hover {
      color: #6b6b6b;
    }
  }
}

.table-wrapper {
  overflow-x: auto;
  overflow-y: hidden;
  padding-bottom: 110px;
  &::-webkit-scrollbar {
    height: 8px;
    overflow: hidden;
  }
  &::-webkit-scrollbar-track {
    background: rgba(0, 0, 0, 0);
    border-radius: 10px;
  }
  &::-webkit-scrollbar-thumb {
    background: #ccc;
    border-radius: 10px;
    height: 10px;
  }
  &::-webkit-scrollbar-thumb:hover {
    background: #aaa;
  }
}

.table {
  width: 100%;
}

.table-header {
  min-width: 100%;
  display: flex;
  width: fit-content;
  div {
    min-width: 100px;
    flex: 1;
    display: flex;
    justify-content: center;
    font-weight: bold;
  }
}

.table-content {
  min-width: 100%;
  width: fit-content;
  display: flex;
  border-radius: 50px;
  border: 1px solid #999999;
  div {
    min-width: 100px;
    flex: 1;
    display: flex;
    justify-content: center;
    color: #6b6b6b;
  }
  &.disable {
    border: 1px solid #cccccc;
    div,
    button {
      color: #cccccc !important;
    }
  }
}

.btn-actions {
  display: flex;
  align-items: center;
  font-weight: bold;
  background: none;
  border: none;
  &:focus {
    outline: none;
  }
}

.dropdown-actions {
  position: relative;
  display: flex;
  justify-content: center;
}

#dropdown {
  display: none;
  animation: grow 400ms ease-in-out forwards;
  transform-origin: top center;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 4px;
  position: absolute;
  top: 40px;
  right: 10px;
  z-index: 5;
  background: #fff;
  width: 110px;
  &.active {
    display: block;
  }
  a {
    display: flex;
    align-items: center;
    cursor: pointer;
    padding: 5px 10px;
    color: #999;
    text-decoration: none;
    font-weight: normal;
    &:hover {
      background: rgba(193, 193, 193, 0.4);
    }
    &:first-of-type {
      border-top-right-radius: 4px;
      border-top-left-radius: 4px;
    }
    &:last-of-type {
      border-bottom-right-radius: 4px;
      border-bottom-left-radius: 4px;
    }
    &.active {
      background: rgba(193, 193, 193, 0.25);
    }
    .circle {
      width: 1rem;
      height: 1rem;
      display: flex;
      align-items: center;
      justify-content: center;
      border: 1px solid #999;
      border-radius: 50%;
      font-size: 0.9rem;
    }
  }
}

.edit-segment {
  input {
    border-top-right-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
  }
  button {
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    background: #10c398;
    border: 1px solid #10c398;
    border-radius: 0 !important;
    border-top-right-radius: 0.2rem !important;
    border-bottom-right-radius: 0.2rem !important;
    &.delete {
      background: #df2938;
      border: 1px solid #df2938;
      border-top-right-radius: 0 !important;
      border-bottom-right-radius: 0 !important;
    }
  }
}
</style>
