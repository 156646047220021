var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"card-b p-3 mb-3"},[_c('div',{staticClass:"p-2 card-b-header"},[_c('h2',{staticClass:"h5 font-weight-bold mb-0",style:({ color: _vm.$store.getters.theme[3] })},[_vm._v(" "+_vm._s(_vm.translation.next_assignment.next_assignment_text)+" ")])]),_c('div',{staticClass:"links pt-1 mb-2"},[_c('div',{on:{"click":function($event){return _vm.openTasks({
          _activity: _vm.activity.id,
          _pageParticipant: 1,
          _pageReplies: 1,
          _q: ''
        })}}},[_c('div',{staticClass:"item d-flex align-items-center p-2 rounded pointer",on:{"mouseover":_vm.mouseOver,"mouseleave":_vm.mouseLeave}},[_c('div',{staticClass:"size-img"},[_c('div',{staticClass:"check-icon ",style:({
              color: _vm.$store.getters.theme[3]
            })},[_c('b-icon',{staticClass:"h2 m-0",attrs:{"icon":"caret-right-fill","aria-hidden":"true"}})],1)]),_c('div',[_c('p',{staticClass:"m-0"},[_vm._v(_vm._s(_vm.activity.name))]),_c('p',{staticClass:"m-0",style:({
              color: _vm.$store.getters.theme[2]
            })},[_vm._v(" "+_vm._s(_vm.translation.next_assignment.begin_assignment)+" ")])])])]),_c('div',{staticClass:"d-flex align-items-center p-2 rounded"},[_vm._m(0),_c('p',{staticClass:"m-0 ml-3 text-grey"},[_vm._v(" "+_vm._s(_vm.translation.next_assignment.glad_back_text)+" ")])])]),_c('div',{staticClass:"complete-activities pt-2 pr-3"},[_c('radial-progress-bar',{attrs:{"diameter":70,"strokeWidth":5,"innerStrokeWidth":5,"innerStrokeColor":"#ffffff","startColor":_vm.$store.getters.theme[2],"stopColor":_vm.$store.getters.theme[2],"completed-steps":_vm.completed,"total-steps":_vm.activities.length}},[_c('div',{staticClass:"circle"},[_c('img',{attrs:{"src":require("@/img/complete-activities.svg")}})])]),(!_vm.loader2)?_c('p',[_vm._v(" "+_vm._s(_vm.completed)+"/"+_vm._s(_vm.activities.length)+" "+_vm._s(_vm.translation.next_assignment.completed_assignments)+" ")]):_vm._e(),(_vm.loader2)?_c('div',{staticClass:"w-100 pl-2"},[_c('loader2',{attrs:{"color":_vm.$store.getters.theme[2]}})],1):_vm._e()],1)])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"size-img"},[_c('img',{staticClass:"img-fluid",attrs:{"src":require("@/img/hand.svg"),"alt":""}})])}]

export { render, staticRenderFns }