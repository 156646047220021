<template>
  <div>
    <b-modal
      modal-class="modalTranscription"
      hide-footer
      id="modalTranscription"
    >
      <template #modal-title>
        <h2 class="modal-title" :style="{ color: $store.getters.theme[2] }">
          {{ translation.replies.transcription }}
        </h2>
      </template>
      <modal-transcript
        v-if="!loader && activeMedia"
        :activeMedia="activeMedia"
        :transcriptVideo="transcriptVideo"
        :results="results"
        :jobName="activejobName"
      />
      <div class="d-flex justify-content-center py-5" v-else>
        <loader />
      </div>
    </b-modal>
    <div v-if="participants.length">
      <div
        v-for="(participant, index) in participants"
        :key="index"
        class="mt-4 d-flex flex-wrap"
      >
        <div class="col-md-2 py-1">
          <router-link
            :to="{
              name: 'participant',
              query: { _id: participant[0].participant.id, _page: 1 }
            }"
            class="participant-info"
          >
            <div
              :style="{
                'background-color': $store.getters.theme[0],
                color: $store.getters.theme[3]
              }"
            >
              <img
                v-if="participant[0].participant.picture.length"
                :src="participant[0].participant.picture"
                alt="Profile Picture"
              />
              <b-icon
                v-else
                class="icon-profile"
                icon="person-fill"
                aria-hidden="true"
              ></b-icon>
            </div>
            <p
              :style="{
                color: $store.getters.theme[3]
              }"
            >
              <span
                v-if="$store.getters.info.user.type === userTypesText.moderator"
              >
                {{
                  namePrivacy(
                    privacy.moderators.participants_identifier_type_id,
                    participant[0].participant.name,
                    participant[0].participant.id
                  )
                }}
              </span>
              <span
                v-else-if="
                  $store.getters.info.user.type === userTypesText.observer
                "
              >
                {{
                  namePrivacy(
                    privacy.observers.participants_identifier_type_id,
                    participant[0].participant.name,
                    participant[0].participant.id
                  )
                }}
              </span>
              <span v-else>
                {{ participant[0].participant.name }}
              </span>
            </p>
          </router-link>
        </div>

        <div class="col-md-10 py-1">
          <div class="media-wrapper mb-4">
            <div
              class="answer-wrapper"
              v-for="(media, mIndex) in participant"
              :key="mIndex"
            >
              <div class="activity px-3 py-2 mt-3">
                <img
                  class="task-icon"
                  :style="{ background: $store.getters.theme[1] }"
                  v-b-tooltip.hover="{ variant: 'secondary' }"
                  :title="taskText(media.fk_task_id)"
                  :src="taskImage(media.fk_task_id)"
                />
                <div :style="{ background: $store.getters.theme[1] }">
                  <span :style="{ color: $store.getters.theme[3] }">
                    {{ media.fk_task_id.activity.name }}
                  </span>
                  -
                  <span :style="{ color: $store.getters.theme[2] }">
                    {{ media.fk_task_id.code_string }}
                  </span>
                </div>
              </div>

              <div class="media-item p-3 m-3">
                <get-task-data :task="media.fk_task_id" />
                <div class="answer-box">
                  <p :style="{ color: $store.getters.theme[3] }">
                    {{ translation.replies.replies }}
                  </p>

                  <div v-if="+media.has_image" class="d-flex flex-wrap pt-4">
                    <div v-if="media.answer_object.files">
                      <div
                        v-for="image in media.answer_object.files"
                        :key="image.url"
                        class="pb-4 mr-3"
                      >
                        <img class="img-fluid" :src="image.url" />
                        <p class="m-0 mt-2">{{ image.description }}</p>
                      </div>
                    </div>
                    <div v-else>
                      <div
                        v-for="(image, index) in media.answer_object.images"
                        :key="index"
                        class="col-md-3 pb-4"
                      >
                        <div>
                          <img class="img-fluid" :src="image.URL" />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    class="d-flex flex-wrap mb-3 mt-3"
                    v-if="+media.has_attachment"
                  >
                    <div
                      v-if="
                        +media.fk_task_id.type_id === taskEnum.multimedia.id
                      "
                    >
                      <div v-if="media.transcript.length">
                        <div
                          v-for="transcript in media.transcript"
                          :key="transcript.jobName"
                          class="pb-3 mr-3"
                        >
                          <video
                            @click.prevent="
                              openModalTranscription(media, transcript)
                            "
                            controls
                            :src="transcript.midiaLink"
                          ></video>
                          <button
                            class="transcript-button hover"
                            :style="{ color: $store.getters.theme[2] }"
                            @click="openModalTranscription(media, transcript)"
                          >
                            {{ translation.replies.transcription }}
                          </button>
                        </div>
                        <div
                          class="pb-3 mr-3"
                          v-for="media in media.answer_object.files.filter(
                            file => isImage(file)
                          )"
                          :key="media.url"
                        >
                          <img :src="media.url" />
                          <p class="m-0 mt-2">
                            {{ media.description }}
                          </p>
                        </div>
                      </div>
                      <div v-else>
                        <div
                          class="pb-3 mr-3"
                          v-for="file in media.answer_object.files"
                          :key="file.url"
                        >
                          <img v-if="isImage(file)" :src="file.url" />
                          <video v-else :src="file.url" controls></video>
                          <p class="m-0 mt-2">
                            {{ file.description }}
                          </p>
                        </div>
                      </div>
                    </div>
                    <div v-else-if="media.answer_object.files">
                      <div
                        v-for="attachment in media.answer_object.files"
                        :key="attachment.name"
                      >
                        <div class="attachment mr-3 mb-2">
                          <div
                            class="d-flex py-2 px-3"
                            @click="download(attachment.url)"
                          >
                            <b-icon
                              icon="paperclip"
                              aria-hidden="true"
                            ></b-icon>
                            <div class="ml-2">
                              <a class="mb-0">{{ attachment.name }}</a>
                              <p class="mb-0">
                                {{ formatBytes(attachment.size) }}
                              </p>
                            </div>
                          </div>
                        </div>
                        <p class="mb-4 ml-2 mt-2">
                          {{ attachment.description }}
                        </p>
                      </div>
                    </div>
                    <div v-else>
                      <attachment
                        :attachments="media.answer_object.attachments"
                        :deleteFile="null"
                      />
                    </div>
                  </div>
                  <div v-if="+media.has_video">
                    <div
                      v-if="media.transcript.length"
                      class="d-flex flex-wrap pt-4"
                    >
                      <div
                        v-for="transcript in media.transcript"
                        :key="transcript.jobName"
                        class="pb-3 mr-3"
                      >
                        <video
                          @click.prevent="
                            openModalTranscription(media, transcript)
                          "
                          controls
                          :src="transcript.midiaLink"
                        ></video>
                        <button
                          class="transcript-button hover"
                          :style="{ color: $store.getters.theme[2] }"
                          @click="openModalTranscription(media, transcript)"
                        >
                          {{ translation.replies.transcription }}
                        </button>
                      </div>
                    </div>
                    <div v-else class="d-flex flex-wrap pt-4">
                      <div v-if="media.answer_object.files">
                        <div
                          v-for="video in media.answer_object.files"
                          :key="video.url"
                          class="pb-4 mr-3"
                        >
                          <div>
                            <video :src="video.url" controls></video>
                            <p class="m-0 mt-2">{{ video.description }}</p>
                          </div>
                        </div>
                      </div>
                      <div v-else>
                        <div
                          v-for="video in media.answer_object.videos"
                          :key="video.URL"
                          class="pb-4 mr-3"
                        >
                          <div>
                            <video :src="video.URL" controls></video>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-else class="d-flex justify-content-center no-media">
      {{ translation.replies.no_media }}
    </div>
  </div>
</template>

<script>
import axios from "axios";
import loader from "@/components/general/loader.vue";
import getTaskData from "@/components/task/getTaskData.vue";
import attachment from "@/components/general/attachment.vue";
import modalTranscript from "@/components/answers/modalTranscript.vue";
import { formatBytes, namePrivacy } from "@/helpers.js";
import { imgTypes, taskEnum, userTypesText } from "@/constants.js";
import { mapState } from "vuex";

export default {
  components: { loader, getTaskData, attachment, modalTranscript },

  props: {
    participants: {
      required: true
    }
  },

  data() {
    return {
      activeMedia: null,
      activejobName: null,
      transcriptVideo: "",
      results: [],
      loader: false,
      taskEnum,
      userTypesText
    };
  },

  computed: {
    ...mapState(["privacy"])
  },

  methods: {
    formatBytes,

    namePrivacy,

    taskText(task) {
      const type = Object.values(this.taskEnum).filter(
        typeObj => typeObj.id === +task.type_id
      )[0];
      return type.text[this.langAbbreviation];
    },

    taskImage(task) {
      const type = Object.values(this.taskEnum).filter(
        typeObj => typeObj.id === +task.type_id
      )[0];

      const images = require.context("../../img/", false, /\.svg$/);
      return images("./" + type.icon + ".svg");
    },

    download(url) {
      window.open(url, "_blank");
      window.focus();
    },

    isImage(media) {
      if (!media.url) return false;
      const extension = media.url.split(".").pop();
      return imgTypes.includes(extension);
    },

    getTranscription(transcript) {
      this.loader = true;

      axios
        .get(
          `${this.exportMediaHost}voices/transcript?jobName=${transcript.jobName}`,
          {
            headers: {
              Authorization: `Bearer ${this.$store.getters.token}`
            }
          }
        )
        .then(r => {
          if (r.status === 200) {
            this.transcriptVideo = r.data.midiaLink;
            this.results = r.data.transcript.results;
            this.activejobName = transcript.jobName;
            this.loader = false;
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },

    openModalTranscription(media, transcript) {
      this.activeMedia = media;
      this.results = [];
      this.transcriptVideo = "";
      this.getTranscription(transcript);
      this.$bvModal.show("modalTranscription");
    }
  },

  mounted() {}
};
</script>

<style lang="scss" scoped>
.task-icon {
  object-fit: scale-down;
  display: block;
  width: 35px;
  height: 35px;
  border-radius: 50%;
  margin-right: 10px;
  padding: 3px;
  flex-shrink: 0;
}

.answer-wrapper {
  border: 1px solid #c4c4c4;
  border-radius: 11px;
  margin-bottom: 30px;
}

.modal-title {
  font-weight: bold;
  font-size: 1.3rem;
}

.media-item {
  video {
    width: 100%;
    max-width: 300px;
  }
  img {
    width: 100%;
    max-width: 300px;
  }
}

.answer-box {
  display: flex;
  flex-wrap: wrap;
  p {
    margin-right: 20px;
    text-transform: uppercase;
    font-weight: bold;
  }
}

.activity {
  display: inline-flex;
  align-items: center;
  div {
    display: flex;
    align-items: center;
    font-weight: bold;
    border-top-right-radius: 20px;
    border-bottom-right-radius: 20px;
    height: 35px;
    padding: 0 10px 2px;
    span {
      margin: 5px;
    }
  }
}

@media (max-width: 567.98px) {
  .activity {
    font-size: 0.8rem;
    div {
      padding: 0 5px 2px;
    }
  }
}

.media-item {
  border-radius: 10px;
  color: #6b6b6b;
}

.participant-info {
  display: flex;
  flex-direction: column;
  align-items: center;
  div {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 60px;
    height: 60px;
    border-radius: 50%;
    overflow: hidden;
    img {
      object-fit: cover;
      height: 60px;
      width: 60px;
    }
  }
  p {
    font-weight: bold;
    font-size: 0.81rem;
    margin-top: 5px;
    width: 90px;
    text-align: center;
    margin-bottom: 0;
    word-wrap: break-word;
    word-break: break-word;
  }
}

@media (max-width: 567.98px) {
  .participant-info {
    flex-direction: row;
  }
}

.no-media {
  color: #6b6b6b;
}

.transcript-button {
  display: block;
  background: none;
  border: none;
  font-weight: bold;
  cursor: pointer;
  text-decoration: underline;
  &:focus {
    outline: none;
  }
}
</style>
