var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"pt-4"},[(_vm.answer.answer_object.files)?_c('div',_vm._l((_vm.answer.answer_object.files),function(media,index){return _c('div',{key:index,staticClass:"mb-4"},[(_vm.isImage(media))?_c('img',{attrs:{"src":media.url,"alt":""}}):_c('video',{attrs:{"controlsList":_vm.$store.getters.info.user.type !== _vm.userTypesText.admin &&
            _vm.$store.getters.info.user.type !== _vm.userTypesText.moderator
              ? 'nodownload'
              : '',"controls":"","src":media.url}}),_c('p',[_vm._v(" "+_vm._s(media.description)+" ")])])}),0):_vm._l((_vm.answer.answer_object.attachments),function(media,index){return _c('div',{key:index},[(_vm.isImage(media))?_c('img',{attrs:{"src":media.URL,"alt":""}}):_c('video',{attrs:{"controlsList":_vm.$store.getters.info.user.type !== _vm.userTypesText.admin &&
          _vm.$store.getters.info.user.type !== _vm.userTypesText.moderator
            ? 'nodownload'
            : '',"controls":"","src":media.URL}})])})],2),(
      _vm.answer.answer_object &&
        _vm.answer.answer_object.comment &&
        _vm.answer.answer_object.comment.length
    )?_c('p',[_c('span',{staticClass:"comments",style:({ color: _vm.$store.getters.theme[3] })},[_vm._v(" "+_vm._s(_vm.translation.answer_task.comments)+" ")]),_vm._v(" "+_vm._s(_vm.answer.answer_object.comment)+" ")]):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }