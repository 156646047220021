var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"row task-wrapper"},[(!_vm.loading)?_c('div',{staticClass:"col"},[_c('div',{staticClass:"task-text img-question"},[_c('p',{domProps:{"innerHTML":_vm._s(_vm.text)}})]),_c('div',{staticClass:"d-flex flex-wrap"},[_c('attachment',{attrs:{"attachments":_vm.task.attachments,"deleteFile":null}})],1),_c('div',{staticClass:"wrapper"},[(_vm.disabled)?_c('div',{staticClass:"disabled"}):_vm._e(),_c('div',{staticClass:"d-flex justify-content-center py-4"},[_c('p',{staticClass:"select-box",style:({ background: _vm.$store.getters.theme[1] })},[_vm._v(" "+_vm._s(_vm.translation.answer_task.drag_and_drop.drag_and_drop)+" ")])]),_c('div',{staticClass:"mb-4"},[_c('draggable',{staticClass:"cards dragArea d-flex justify-content-center flex-wrap",attrs:{"list":_vm.cards,"group":_vm.optionsDraggable,"handle":".handle"}},_vm._l((_vm.cards),function(card){return _c('div',{key:card.text.replace(_vm.clearTextRegexp, ''),staticClass:"card-item",class:{ hasImage: _vm.image },attrs:{"id":("card-" + (card.text.replace(_vm.clearTextRegexp, '')))}},[_c('div',{staticClass:"groups",attrs:{"id":("groups-" + (card.text.replace(_vm.clearTextRegexp, '')))}},[_c('div',[_c('div',_vm._l((_vm.groups),function(group){return _c('div',{key:group.groupText.replace(_vm.clearTextRegexp, ''),class:{ active: group.cards.includes(card) }},[_c('button',{on:{"click":function($event){_vm.toggleGroup(
                          card,
                          group,
                          ("groups-" + (card.text.replace(_vm.clearTextRegexp, '')))
                        )},"touchend":function($event){_vm.toggleGroup(
                          card,
                          group,
                          ("groups-" + (card.text.replace(_vm.clearTextRegexp, '')))
                        )}}},[(!group.cards.includes(card))?_c('b-icon',{attrs:{"icon":"circle"}}):_c('b-icon',{attrs:{"icon":"check-circle"}})],1),_c('span',{on:{"click":function($event){_vm.toggleGroup(
                          card,
                          group,
                          ("groups-" + (card.text.replace(_vm.clearTextRegexp, '')))
                        )},"touchend":function($event){_vm.toggleGroup(
                          card,
                          group,
                          ("groups-" + (card.text.replace(_vm.clearTextRegexp, '')))
                        )}}},[_vm._v(" "+_vm._s(group.groupText)+" ")])])}),0)])]),_c('div',{staticClass:"handle"},[(_vm.image && card.img.code.length)?_c('div',{staticClass:"img"},[_c('img',{attrs:{"src":card.img.code}})]):_vm._e(),_c('div',{staticClass:"text"},[_c('div',{style:({
                    'background-color': _vm.$store.getters.theme[2]
                  })}),_c('p',[_vm._v(_vm._s(card.text))])])]),_c('div',{staticClass:"open-btn"},[_c('button',{on:{"click":function($event){_vm.openGroups(
                    ("groups-" + (card.text.replace(_vm.clearTextRegexp, ''))),
                    ("card-" + (card.text.replace(_vm.clearTextRegexp, '')))
                  )},"touchstart":function($event){_vm.openGroups(
                    ("groups-" + (card.text.replace(_vm.clearTextRegexp, ''))),
                    ("card-" + (card.text.replace(_vm.clearTextRegexp, '')))
                  )}}},[_c('b-icon',{attrs:{"icon":"menu-button-wide-fill"}})],1)])])}),0)],1),_c('div',{staticClass:"d-flex justify-content-center flex-wrap"},_vm._l((_vm.groups),function(group){return _c('div',{key:group.groupText.replace(_vm.clearTextRegexp, ''),staticClass:"col-md-3 mb-4"},[_c('div',{staticClass:"group py-3 px-2"},[_c('h3',[_vm._v(_vm._s(group.groupText))]),_c('draggable',{staticClass:"\n                dragArea\n                d-flex\n                justify-content-center justify-content-md-start\n                flex-wrap\n              ",attrs:{"list":group.cards,"group":"cards"},on:{"change":function (added) { return _vm.groupVerification(added, group); }}},_vm._l((group.cards),function(card){return _c('div',{key:card.text.replace(_vm.clearTextRegexp, ''),staticClass:"card-wrapper"},[_c('div',{staticClass:"card-item group-card",class:{ hasImage: _vm.image }},[(card.text.length > 10)?_c('div',{staticClass:"card-hover"},[_vm._v(" "+_vm._s(card.text)+" ")]):_vm._e(),_c('div',{staticClass:"trash",style:({ color: _vm.$store.getters.theme[3] }),on:{"click":function($event){return _vm.deleteCard(group, card)},"touchend":function($event){return _vm.deleteCard(group, card)}}},[_c('b-icon',{attrs:{"icon":"trash-fill"}})],1),(_vm.image && card.img.code.length)?_c('div',{staticClass:"img",style:({
                      pointerEvents:
                        _vm.$store.getters.info.user.type ===
                        _vm.userTypesText.participant
                          ? 'none'
                          : 'unset'
                    })},[_c('img',{attrs:{"src":card.img.code}})]):_vm._e(),_c('div',{staticClass:"text"},[_c('div',{style:({
                        'background-color': _vm.$store.getters.theme[2]
                      })}),_c('p',[_vm._v(_vm._s(_vm._f("groupText")(card.text)))])])])])}),0)],1)])}),0),_c('div',{staticClass:"comments"},[(
            _vm.task.question_object.has_custom_comment_label &&
              _vm.task.question_object.comment_label &&
              _vm.task.question_object.comment_label.length
          )?_c('label',{attrs:{"for":"comments"}},[_vm._v(_vm._s(_vm.task.question_object.comment_label))]):_c('label',{attrs:{"for":"comments"}},[_vm._v(" "+_vm._s(_vm.translation.answer_task.comments)+" ")]),_c('textarea',{directives:[{name:"model",rawName:"v-model",value:(_vm.comments),expression:"comments"}],staticClass:"form-control",attrs:{"id":"textarea","maxlength":_vm.maxCharacters,"rows":"3","max-rows":"6","disabled":_vm.task.is_answered},domProps:{"value":(_vm.comments)},on:{"input":function($event){if($event.target.composing){ return; }_vm.comments=$event.target.value}}}),_c('characterAndWordCounter',{attrs:{"count":_vm.hasWordsLimitation
              ? this.comments.length
                ? this.comments.trim().split(/\s+/).length
                : 0
              : _vm.comments.length,"max":_vm.hasWordsLimitation ? _vm.maxWords : _vm.maxCharacters,"label":_vm.hasWordsLimitation
              ? _vm.translation.answer_task.words
              : _vm.translation.answer_task.characters}})],1)])]):_vm._e(),(_vm.loading)?_c('div',{staticClass:"w-100 d-flex justify-content-center"},[_c('loader')],1):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }