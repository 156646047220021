<template>
  <div :class="{ active: showAdmin }" class="sub-header">
    <div>
      <div v-if="$store.getters.info.user.type === userTypesText.admin">
        <button
          @click="showPrivacy = !showPrivacy"
          :style="{ color: $store.getters.theme[2] }"
        >
          {{ translation.privacy_and_permissions.privacy }}
        </button>
        <div :class="{ active: showPrivacy }" class="links">
          <router-link
            :to="{
              name: 'privacyandpermissions',
              query: { _option: tabOptions.privacy }
            }"
          >
            {{ translation.privacy_and_permissions.privacy_and_permissions }}
          </router-link>
        </div>
      </div>

      <div v-if="$store.getters.info.user.type === userTypesText.admin">
        <button
          @click="showCommunity = !showCommunity"
          :style="{ color: $store.getters.theme[2] }"
        >
          {{ translation.header.community_text }}
        </button>
        <div :class="{ active: showCommunity }" class="links">
          <router-link :to="{ name: 'segmentation', query: { _page: 1 } }">
            {{ translation.header.segmentation_text }}
          </router-link>
          <router-link
            :to="{ name: 'logs', query: { _page: 1 } }"
            v-if="$store.getters.info.user.type !== userTypesText.participant"
          >
            {{ translation.header.logs }}
          </router-link>
        </div>
      </div>
    </div>

    <div>
      <div v-if="$store.getters.info.user.type === userTypesText.admin">
        <button
          @click="showOperations = !showOperations"
          :style="{ color: $store.getters.theme[2] }"
        >
          {{ translation.header.moderation_text }}
        </button>
        <div :class="{ active: showOperations }" class="links">
          <router-link
            :to="{
              name: 'operators',
              query: { _option: tabOptions.addOperator }
            }"
          >
            {{ translation.operators.add_manage_operators }}
          </router-link>
        </div>
      </div>
    </div>

    <div>
      <div
        v-if="
          $store.getters.info.user.type === userTypesText.admin ||
            ($store.getters.info.user.type === userTypesText.moderator &&
              (!+permissions.moderators.hide_customization ||
                !+permissions.moderators.hide_terms_and_policies))
        "
      >
        <button
          @click="showPersonalize = !showPersonalize"
          :style="{ color: $store.getters.theme[2] }"
        >
          {{ translation.header.personalize_text }}
        </button>
        <div :class="{ active: showPersonalize }" class="links">
          <router-link
            v-if="
              $store.getters.info.user.type === userTypesText.admin ||
                ($store.getters.info.user.type === userTypesText.moderator &&
                  !+permissions.moderators.hide_customization)
            "
            :to="{
              name: 'customization',
              query: { _option: tabOptions.customizePages }
            }"
            :disabled="$store.getters.is_over"
          >
            {{ translation.header.customize_text }}
          </router-link>
          <router-link
            v-if="
              $store.getters.info.user.type === userTypesText.admin ||
                ($store.getters.info.user.type === userTypesText.moderator &&
                  !+permissions.moderators.hide_terms_and_policies)
            "
            :to="{ name: 'terms-and-policy' }"
            :disabled="$store.getters.is_over"
          >
            {{ translation.header.terms_and_policies }}
          </router-link>
        </div>
      </div>
    </div>

    <div
      v-if="
        $store.getters.info.user.type === userTypesText.admin ||
          $store.getters.info.user.type === userTypesText.moderator ||
          ($store.getters.info.user.type === userTypesText.observer &&
            !+permissions.observers.hide_activities_stats)
      "
    >
      <div>
        <button
          @click="showParticipant = !showParticipant"
          :style="{ color: $store.getters.theme[2] }"
        >
          {{ translation.header.participant_text }}
        </button>
        <div :class="{ active: showParticipant }" class="links">
          <router-link
            v-if="$store.getters.info.user.type === userTypesText.admin"
            :to="{
              name: 'addparticipant',
              query: { _option: tabOptions.importOption, _step: 1 }
            }"
          >
            {{ translation.header.add_participant_text }}
          </router-link>
          <router-link
            :to="{
              name: 'manageparticipants',
              query: { _option: tabOptions.registeredOption, _page: 1 }
            }"
          >
            {{ translation.header.manage_participant_text }}
          </router-link>
        </div>
      </div>
    </div>

    <div>
      <button
        @click="showChat = !showChat"
        :style="{ color: $store.getters.theme[2] }"
      >
        Chat
      </button>
      <div :class="{ active: showChat }" class="links">
        <router-link :to="{ name: 'chat' }" :disabled="$store.getters.is_over">
          Chat
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
import { tabOptions, userTypesText } from "@/constants";
import { mapState } from "vuex";

export default {
  props: {
    showAdmin: {
      required: true
    }
  },

  computed: {
    ...mapState(["permissions"])
  },

  data() {
    return {
      tabOptions,
      userTypesText,
      showPersonalize: false,
      showCommunity: false,
      showParticipant: false,
      showOperations: false,
      showChat: false,
      showPrivacy: false
    };
  }
};
</script>

<style lang="scss" scoped>
.sub-header {
  display: none;
  animation: show 0.3s linear;
  background: linear-gradient(180deg, #f1f8f8 0%, rgba(235, 242, 242, 0) 100%);
  button {
    display: block;
    width: 100%;
    background: none;
    border: none;
    padding: 10px;
    font-weight: bold;
    font-size: 1rem;
    border-bottom: 1px solid #e5e5e5;
    text-decoration: none;
    text-align: left;
    &:focus {
      outline: none;
    }
  }
  a {
    display: block;
    text-transform: capitalize;
    font-weight: normal;
    color: #999 !important;
    padding: 10px;
    font-size: 1rem;
    border-bottom: 1px solid #e5e5e5;
    text-decoration: none;
  }
  &.active {
    display: block;
  }
}

.links {
  display: none;
  animation: show 0.3s linear;
  &.active {
    display: block;
  }
}
</style>
