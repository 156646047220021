<template>
  <div>
    <h2 class="title">
      {{ translation.replies.charts_tab.limit_the_number_of_words }}
    </h2>
    <div class="words-wrapper">
      <div
        :style="{ color: $store.getters.theme[2] }"
        class="range-numbers d-flex justify-content-between"
      >
        <span class="mr-2">1</span>
        <span>{{ wordsCount }}</span>
      </div>
      <b-form-input
        type="range"
        v-model="words"
        min="1"
        :max="wordsCount"
      ></b-form-input>

      <b-form-input
        :class="{ red: !validRange }"
        type="number"
        v-model="words"
        min="1"
        :max="wordsCount"
      ></b-form-input>

      <div class="add-btn">
        <button
          @click="apply"
          :style="{ background: $store.getters.theme[2] }"
          class="btn btn-sm hover px-4"
          :disabled="!validRange"
        >
          {{ translation.global.apply }}
        </button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    config: {
      required: true
    },
    wordsCount: {
      required: true
    }
  },
  computed: {
    validRange() {
      return this.words >= 1 && this.words <= this.wordsCount;
    }
  },
  data() {
    return {
      words: null
    };
  },
  methods: {
    apply() {
      if (this.validRange) this.setWords();
    },
    setWords() {
      this.$emit("update", this.words);
    }
  },
  created() {
    this.words = this.config.words;
  }
};
</script>

<style lang="scss" scoped>
.title {
  font-size: 1rem;
  color: #6b6b6b;
  font-weight: bold;
  margin-bottom: 10px;
}

.words-wrapper {
  max-width: 400px;
  margin: 20px 0;
  .red {
    color: #df2938 !important;
  }
}

.range-numbers {
  font-size: 0.8rem;
  font-weight: bold;
}

.add-btn {
  display: flex;
  justify-content: flex-end;
  margin-top: 10px;
  button {
    color: #ffffff;
    font-weight: bold;
  }
}
</style>
