<template>
  <div>
    <div
      v-if="
        $store.getters.info.user.type === userTypesText.moderator ||
          $store.getters.info.user.type === userTypesText.admin
      "
      class="filters mx-md-3 0 pt-4"
    >
      <label for="filter-radios" class="pr-md-3 pr-2 m-0"
        >{{ translation.global.filter_by }}:</label
      >
      <b-form-radio
        class="pr-md-4 pr-2"
        v-model="filterCheck"
        name="filter-radios"
        value="checked"
        >{{ translation.replies.solved }}</b-form-radio
      >
      <b-form-radio
        class="pr-md-4 pr-2"
        v-model="filterCheck"
        name="filter-radios"
        value="unchecked"
        >{{ translation.replies.not_solved }}</b-form-radio
      >
      <b-form-radio v-model="filterCheck" name="filter-radios" value="all">{{
        translation.replies.all
      }}</b-form-radio>
    </div>
    <div ref="scroll">
      <div
        class="container pt-4"
        v-if="
          ($store.getters.info.user.type !== userTypesText.participant ||
            totalAnswers(activeTask.id)) &&
            answers.length
        "
      >
        <div v-for="(answer, index) in answers" :key="answer.answer_id">
          <div
            v-if="
              activity.visibility.id === visibilityCodes.all_visible ||
                (activity.visibility.id === visibilityCodes.after_complete &&
                  is_completed) ||
                answer.participant.id === $store.getters.info.user.sub ||
                $store.getters.info.user.type !== userTypesText.participant
            "
            class="row justify-content-center pb-3 px-md-5 px-2"
          >
            <div
              :class="{
                pointer:
                  $store.getters.info.user.type === userTypesText.moderator ||
                  $store.getters.info.user.type === userTypesText.admin
              }"
              @click="goToParticipant(answer)"
              class="col-md-2 participant p-2"
            >
              <div
                :style="{
                  'background-color': $store.getters.theme[0],
                  color: $store.getters.theme[3]
                }"
              >
                <img
                  v-if="answer.participant.picture"
                  :src="answer.participant.picture"
                  alt="Picture"
                />
                <b-icon
                  v-else
                  class="icon-profile"
                  icon="person-fill"
                  aria-hidden="true"
                ></b-icon>
              </div>
              <p
                :style="{
                  color: $store.getters.theme[3]
                }"
              >
                <span
                  v-if="
                    $store.getters.info.user.type === userTypesText.moderator
                  "
                >
                  {{
                    namePrivacy(
                      privacy.moderators.participants_identifier_type_id,
                      answer.participant.name,
                      answer.participant.id
                    )
                  }}
                </span>
                <span
                  v-else-if="
                    $store.getters.info.user.type === userTypesText.participant
                  "
                >
                  {{
                    namePrivacy(
                      privacy.participants.participants_identifier_type_id,
                      answer.participant.name,
                      answer.participant.id
                    )
                  }}
                </span>
                <span
                  v-else-if="
                    $store.getters.info.user.type === userTypesText.observer
                  "
                >
                  {{
                    namePrivacy(
                      privacy.observers.participants_identifier_type_id,
                      answer.participant.name,
                      answer.participant.id
                    )
                  }}
                </span>
                <span v-else>
                  {{ answer.participant.name }}
                </span>
              </p>
            </div>

            <div
              class="col-md-10 data px-3"
              :style="{
                'background-color': $store.getters.theme[0]
              }"
            >
              <get-answer-data :answer="answer" />
              <div
                class="buttons-comment d-flex justify-content-between align-items-center mt-3"
              >
                <div class="d-flex align-items-center">
                  <div
                    v-if="
                      $store.getters.info.user.type ===
                        userTypesText.moderator ||
                        $store.getters.info.user.type === userTypesText.admin ||
                        $store.getters.info.user.type ===
                          userTypesText.observer ||
                        answer.participant.id === $store.getters.info.user.sub
                    "
                  >
                    <button
                      :title="
                        $store.getters.info.user.type ===
                          userTypesText.moderator ||
                        $store.getters.info.user.type === userTypesText.admin
                          ? translation.replies.reply_viewed
                          : $store.getters.info.user.type ===
                            userTypesText.participant
                          ? translation.replies.moderate_viewed_answer
                          : ''
                      "
                      v-b-tooltip.hover="{ variant: 'secondary' }"
                      @click="toggleValues(answer, index, 'viewed')"
                      class="viewed-button blue"
                      :class="{
                        active: +answer.viewed,
                        participant: !(
                          $store.getters.info.user.type ===
                            userTypesText.moderator ||
                          $store.getters.info.user.type === userTypesText.admin
                        )
                      }"
                    >
                      <b-icon class="mr-1" icon="eye"></b-icon>
                    </button>
                    <button
                      :title="
                        $store.getters.info.user.type ===
                          userTypesText.moderator ||
                        $store.getters.info.user.type === userTypesText.admin
                          ? translation.replies.mark_reply_viewed
                          : ''
                      "
                      v-b-tooltip.hover="{ variant: 'secondary' }"
                      @click="toggleValues(answer, index, 'viewed')"
                      class="viewed-button"
                      :class="{
                        active: !+answer.viewed,
                        participant: !(
                          $store.getters.info.user.type ===
                            userTypesText.moderator ||
                          $store.getters.info.user.type === userTypesText.admin
                        )
                      }"
                    >
                      <img src="@/img/eye-closed.svg" alt="Not viewed" />
                    </button>
                  </div>
                  <reject-answer
                    :activeTask="activeTask"
                    :anwserIndex="index"
                    :answer="answer"
                    :getAnswers="getAnswers"
                  />

                  <button
                    v-if="
                      $store.getters.info.user.type !==
                        userTypesText.participant ||
                        (answer.participant.id ===
                          $store.getters.info.user.sub &&
                          +answer.total_sub_replies)
                    "
                    class="btn-chat hover"
                    @click="toggleSubReplies(answer.answer_id)"
                    :style="{ color: $store.getters.theme[2] }"
                  >
                    <span
                      v-if="
                        $store.getters.info.user.type !== userTypesText.observer
                      "
                    >
                      {{
                        answer.has_sub_replies
                          ? translation.replies.see_comments
                          : translation.replies.comment
                      }}
                    </span>
                    <span v-else>
                      {{ translation.replies.see_comments }}
                    </span>
                  </button>
                </div>
                <div
                  v-if="
                    $store.getters.info.user.type === userTypesText.moderator ||
                      $store.getters.info.user.type === userTypesText.admin
                  "
                >
                  <button
                    @click="toggleValues(answer, index, 'check')"
                    class="check-button blue"
                    :class="{
                      active: +answer.checked,
                      participant: !(
                        $store.getters.info.user.type ===
                          userTypesText.moderator ||
                        $store.getters.info.user.type === userTypesText.admin
                      )
                    }"
                  >
                    <b-icon class="mr-1" icon="check-circle"></b-icon>
                    {{ translation.replies.solved }}
                  </button>
                  <button
                    @click="toggleValues(answer, index, 'check')"
                    class="check-button"
                    :class="{
                      active: !+answer.checked,
                      participant: !(
                        $store.getters.info.user.type ===
                          userTypesText.moderator ||
                        $store.getters.info.user.type === userTypesText.admin
                      )
                    }"
                  >
                    <b-icon class="mr-1" icon="circle"></b-icon>
                    {{ translation.replies.solved }}
                  </button>
                </div>
              </div>
              <sub-replies
                :showSubReplies="showSubReplies"
                :task="activeTask"
                :answer="answer"
                @close="showSubReplies = false"
              />
            </div>
            <div class="col-md-2 col-0"></div>
            <div class="col-md-10 col-12 my-2">
              <labels :answerProp="answer" />
            </div>
          </div>
        </div>
      </div>

      <div
        class="d-flex justify-content-center py-5"
        v-else-if="
          $store.getters.info.user.type !== userTypesText.participant &&
            !loaderAnswer
        "
      >
        {{ translation.task.no_answers }}
      </div>
      <div class="d-flex justify-content-center py-5" v-if="loaderAnswer">
        <loader />
      </div>
      <div class="px-5 pb-5" v-if="answers.length">
        <pagination-query
          v-if="
            minPerPage < total &&
              (activity.visibility.id === visibilityCodes.all_visible ||
                (activity.visibility.id === visibilityCodes.after_complete &&
                  is_completed) ||
                $store.getters.info.user.type !== userTypesText.participant)
          "
          :perPage="perPage"
          :total="total"
          queryName="_pageReplies"
          :options="[5, 10, 20, 50, 100]"
          @perPageUpdate="newPerPage => (perPage = newPerPage)"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { api } from "@/services.js";
import getAnswerData from "@/components/task/getAnswerData.vue";
import loader from "@/components/general/loader.vue";
import labels from "@/components/task/labels.vue";
import rejectAnswer from "@/components/task/rejectAnswer.vue";
import subReplies from "@/components/task/subReplies.vue";
import paginationQuery from "@/components/general/paginationQuery.vue";
import { userTypesText, visibilityCodes } from "@/constants";
import { namePrivacy } from "@/helpers.js";
import { mapState } from "vuex";

export default {
  components: {
    getAnswerData,
    loader,
    labels,
    rejectAnswer,
    subReplies,
    paginationQuery
  },
  props: {
    activity: {
      required: true
    },
    tasks: {
      required: true
    },
    activeTask: {
      required: true
    }
  },

  data() {
    return {
      loaderAnswer: false,
      answers: [],
      answersPage: 1,
      filterCheck: "all",
      showSubReplies: false,
      total: 0,
      perPage: 5,
      minPerPage: 5,
      triggerGetLabels: false,
      userTypesText,
      visibilityCodes
    };
  },

  computed: {
    ...mapState(["privacy"]),

    callToggleSubReplies() {
      return this.$route.query._scroll && JSON.parse(this.$route.query._scroll);
    },

    is_completed() {
      const tasks = this.tasks.filter(task => !task.is_answered).length;
      return tasks === 0;
    },

    pageReplies() {
      return this.$route.query._pageReplies;
    },

    page() {
      return this.$store.getters.activeTaskIndex;
    }
  },

  watch: {
    callToggleSubReplies() {
      this.getAnswers();
    },

    page() {
      this.getAnswers();
    },

    filterCheck() {
      this.getAnswers();
    },

    perPage() {
      this.getAnswers();
    },

    pageReplies() {
      this.getAnswers();
    }
  },
  methods: {
    namePrivacy,

    toggleSubReplies(id) {
      if (this.showSubReplies === id) this.showSubReplies = false;
      else this.showSubReplies = id;
    },

    totalAnswers(id) {
      return this.answers.filter(answer => answer.task && answer.task.id === id)
        .length;
    },

    toggleValues(answer, index, type) {
      const key = type === "check" ? "checked" : "viewed";
      const url =
        type === "check"
          ? `answer/${answer.answer_id}/change-${type}-status?status=${
              +answer[key] ? 0 : 1
            }`
          : `answer/${answer.answer_id}/viewed/change-status?viewed=${
              +answer[key] ? 0 : 1
            }`;

      if (
        this.$store.getters.info.user.type === this.userTypesText.moderator ||
        this.$store.getters.info.user.type === this.userTypesText.admin
      ) {
        api
          .put(
            url,
            {},
            {
              headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${this.$store.getters.token}`
              }
            }
          )
          .then(r => {
            if (r.status === 200) {
              this.answers[index][key] = +this.answers[index][key] ? 0 : 1;
            }
          })
          .catch(() => {
            this.$toast.error(
              this.translation.errors_global.something_went_wrong
            );
          });
      }
    },

    getAnswers() {
      this.loaderAnswer = true;

      const firstP =
        +this.pageReplies === 1 && this.$route.query._firstP
          ? `&firstP=${this.$route.query._firstP}`
          : this.$store.getters.info.user.type === userTypesText.participant
          ? `&firstP=${this.$store.getters.info.user.sub}`
          : "";

      api
        .get(
          `activity/${this.$route.query._activity}/answers?offset=${this
            .perPage *
            (this.pageReplies - 1)}&limit=${this.perPage}&tasks_id[]=${
            this.activeTask.id
          }&filter=${this.filterCheck}${firstP}`,
          {
            headers: {
              Authorization: `Bearer ${this.$store.getters.token}`
            }
          }
        )
        .then(r => {
          if (r.status === 200) {
            this.answers = r.data.answers;
            this.total = +Object.values(r.data.totalAnswersByTask)[0];
            this.scrollToAnswer();
          }
          this.loaderAnswer = false;
        })
        .catch(error => {
          if (error.response.status === 404) this.answers = [];
          this.loaderAnswer = false;
        });
    },

    goToParticipant(answer) {
      if (
        this.$store.getters.info.user.type === this.userTypesText.moderator ||
        this.$store.getters.info.user.type === this.userTypesText.admin
      )
        this.$router.push({
          name: "participant",
          query: { _id: answer.participant.id, _page: 1 }
        });
    },

    scrollToAnswer() {
      if (this.callToggleSubReplies) {
        this.$refs.scroll.scrollIntoView({
          behavior: "smooth",
          block: "start"
        });

        this.$router.replace({
          query: {
            ...this.$route.query,
            _scroll: false
          }
        });

        this.showSubReplies = this.answers[0].answer_id;
      }
    }
  },
  mounted() {
    this.getAnswers();
  }
};
</script>

<style lang="scss" scoped>
.filters {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  color: #6b6b6b;
  label {
    font-weight: bold;
  }
}

@media (max-width: 767.98px) {
  .filters {
    font-size: 0.8rem;
    justify-content: center;
  }
}

.participant {
  display: flex;
  flex-direction: column;
  align-items: center;
  div {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 60px;
    height: 60px;
    border-radius: 50%;
    overflow: hidden;
    img {
      object-fit: cover;
      height: 60px;
      width: 60px;
    }
  }
  p {
    font-weight: bold;
    font-size: 0.81rem;
    margin-top: 5px;
    width: 90px;
    text-align: center;
    margin-bottom: 0;
    word-wrap: break-word;
    word-break: break-word;
  }
}

@media (max-width: 767.98px) {
  .participant {
    flex-direction: row;
  }
}

.data {
  background: #f5f5f5;
  border-radius: 20px;
  color: #6b6b6b;
  font-size: 0.81rem;
  padding: 10px;
  p {
    margin: 0;
  }
  span {
    font-weight: bold;
    margin-right: 10px;
  }
}

.viewed-button {
  display: none;
  background: none;
  border: none;
  font-size: 1.3rem;
  color: #6b6b6b;
  animation: show 0.3s linear;
  img {
    width: 20.8px;
  }
  &:focus {
    outline: none;
  }
  &.active {
    display: block;
  }
  &.participant {
    cursor: default;
  }
  &.blue {
    color: #0087bf;
  }
}

.check-button {
  display: none;
  background: none;
  border: none;
  color: #6b6b6b;
  animation: show 0.3s linear;
  &:focus {
    outline: none;
  }
  &.active {
    display: block;
  }
  &.participant {
    cursor: default;
  }
  &.blue {
    color: #0087bf;
  }
}

.btn-chat {
  border: none;
  background: none;
  font-weight: bold;
  text-decoration: underline;
  &:focus {
    outline: none;
  }
}

.pagination {
  ul {
    display: flex;
    align-items: center;
    list-style: none;
    margin: 0;
    padding: 0;
    li {
      display: flex;
      justify-content: center;
      align-items: center;
      background: #c4c4c4;
      color: #fff;
      margin: 0 5px;
      width: 22px;
      height: 22px;
      border-radius: 50%;
      font-size: 0.81rem;
      font-weight: bold;
      text-decoration: none;
      transition: all 0.3s ease;
      cursor: pointer;
      &.active {
        background: #8e99a3;
        color: #fff;
        transform: scale(1.1);
      }
      &:hover {
        transform: scale(1.1);
      }
    }
  }
  select {
    width: 60px;
  }
}

@media (max-width: 567.98px) {
  .buttons-comment {
    font-size: 0.8rem;
  }
}
</style>
