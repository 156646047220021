<template>
  <div class="my-5">
    <div class="d-flex justify-content-between">
      <h2 class="title" :style="{ color: $store.getters.theme[3] }">
        {{ translation.replies.exported_files }}
      </h2>
      <a class="hover btn-return" @click="$emit('return')">
        {{ translation.replies.return_to_replies }}
      </a>
    </div>
    <div class="mt-4">
      <div class="d-flex justify-content-end">
        <button
          :style="{ background: $store.getters.theme[2] }"
          class="btn hover mb-2 btn-reload"
          @click="getJobsList"
        >
          <b-icon icon="arrow-repeat"></b-icon>
        </button>
      </div>
      <div v-if="!loader && jobs.length">
        <file-item
          class="mb-2"
          v-for="job in jobs"
          :key="job.id"
          :job="job"
          :option="option"
          :getJobsList="getJobsList"
        />
      </div>
      <div v-else-if="loader" class="d-flex justify-content-center py-5">
        <loader />
      </div>
      <div class="d-flex justify-content-center py-5" v-else>
        {{ translation.replies.no_reports }}
      </div>
    </div>
  </div>
</template>

<script>
import fileItem from "@/components/answers/fileItem.vue";
import loader from "@/components/general/loader.vue";
import axios from "axios";
import { tabOptions } from "@/constants";

export default {
  props: {
    option: {
      required: true
    }
  },
  components: {
    fileItem,
    loader
  },
  data() {
    return {
      jobs: [],
      loader: false,
      tabOptions
    };
  },
  watch: {
    option() {
      this.getJobsList();
    }
  },
  methods: {
    getJobsList() {
      this.option === tabOptions.media && this.getJobsListMedia();
      this.option === tabOptions.reports && this.getJobsListReport();
    },
    orderingByDate(array) {
      return array.sort(
        (a, b) => new Date(b.startedAt) - new Date(a.startedAt)
      );
    },
    getJobsListMedia() {
      this.loader = true;
      const communityId = this.$store.getters.community.id;
      axios
        .get(`${this.exportMediaHost}media/list?communityId=${communityId}`, {
          headers: {
            Authorization: `Bearer ${this.$store.getters.token}`
          }
        })
        .then(r => {
          if (r.status === 200) {
            const orderedJobs = this.orderingByDate(r.data);
            this.jobs = orderedJobs;
            this.loader = false;
          }
        })
        .catch(() => {
          this.jobs = [];
          this.loader = false;
        });
    },
    getJobsListReport() {
      this.loader = true;
      const communityId = this.$store.getters.community.id;
      axios
        .get(
          `${this.exportAnswersHost}get-community-jobs?communityId=${communityId}`,
          {
            headers: {
              Authorization: `Bearer ${this.$store.getters.token}`
            }
          }
        )
        .then(r => {
          if (r.status === 200) {
            const orderedJobs = this.orderingByDate(r.data);
            this.jobs = orderedJobs;
            this.loader = false;
          }
        })
        .catch(() => {
          this.jobs = [];
          this.loader = false;
        });
    }
  },
  created() {
    this.getJobsList();
  }
};
</script>

<style lang="scss" scoped>
.title {
  font-weight: bold;
  font-size: 1.3rem;
}

.btn-return {
  color: #6b6b6b;
  font-weight: bold;
  text-decoration: underline;
  cursor: pointer;
}

.btn-reload {
  border-radius: 23px;
  color: #fff;
  font-weight: bold;
}
</style>
