var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.post.user)?_c('div',{staticClass:"d-flex w-100"},[_c('div',{staticClass:"d-flex flex-column align-items-center"},[_c('div',{staticClass:"profile-img",style:({ 'background-color': _vm.$store.getters.theme[0] })},[(_vm.post.user.picture)?_c('img',{attrs:{"src":_vm.post.user.picture,"alt":"Profile Picture"}}):_c('b-icon',{staticClass:"icon-profile",attrs:{"icon":"person-fill","aria-hidden":"true"}})],1),_c('p',{staticClass:"name"},[(_vm.$store.getters.info.user.type === _vm.userTypesText.participant)?_c('span',[_vm._v(" "+_vm._s(_vm.namePrivacy( _vm.privacy.participants.admins_identifier_type_id, _vm.post.user.name, _vm.post.user.id ))+" ")]):_c('span',[_vm._v(" "+_vm._s(_vm.post.user.name)+" ")])])]),_c('div',{staticClass:"ml-3 w-100"},[_c('div',{staticClass:"desc d-flex justify-content-between align-items-center"},[_c('h2',[_vm._v(_vm._s(_vm.post.title))]),_c('div',[(
              _vm.$store.getters.info.user.type === _vm.userTypesText.moderator ||
                _vm.$store.getters.info.user.type === _vm.userTypesText.admin
            )?_c('router-link',{staticClass:"btn-post underline",attrs:{"to":{ name: 'newpost', query: { pId: _vm.post.id } }}},[_vm._v(" "+_vm._s(_vm.translation.global.edit)+" ")]):_vm._e(),(
              !_vm.post.hasInteraction &&
                (_vm.$store.getters.info.user.sub === _vm.post.user.id ||
                  _vm.$store.getters.info.user.type === _vm.userTypesText.moderator ||
                  _vm.$store.getters.info.user.type === _vm.userTypesText.admin)
            )?_c('button',{staticClass:"btn-post underline",on:{"click":function($event){return _vm.$bvModal.show(("deletePostModal" + (_vm.post.id)))}}},[_vm._v(" "+_vm._s(_vm.translation.global.delete)+" ")]):_vm._e(),_c('b-modal',{attrs:{"cancel-title":_vm.translation.global.cancel,"id":("deletePostModal" + (_vm.post.id)),"title":_vm.translation.forum.delete_post},on:{"ok":_vm.deletePost}},[_vm._v(" "+_vm._s(_vm.translation.forum.are_you_sure_delete_post)+" ")])],1)]),_c('div',{staticClass:"post-info"},[_c('span',{staticClass:"mr-3"},[_vm._v(_vm._s(_vm._f("formatDate")(_vm.post.created_at)))]),(+_vm.post.was_edited)?_c('span',[_vm._v(" "+_vm._s(_vm.translation.forum.edited))]):_vm._e()]),_c('p',{staticClass:"text",domProps:{"innerHTML":_vm._s(_vm.post.post)}}),_c('attachment',{attrs:{"attachments":_vm.post.attachments,"deleteFile":null}}),_c('div',{staticClass:"d-flex"},[_c('div',{staticClass:"likes-wrapper"},[(_vm.post.likes.length)?_c('div',{staticClass:"likes-list"},[_c('b-modal',{attrs:{"cancel-title":_vm.translation.global.cancel,"id":("likeModal" + (_vm.post.id)),"title":"Likes","hide-footer":""}},[_c('div',{staticClass:"user-modal-scroll"},_vm._l((_vm.post.likes),function(likePost,index){return _c('div',{key:index,staticClass:"user-modal"},[_c('div',[_c('div',{staticClass:"profile-img",style:({ 'background-color': _vm.$store.getters.theme[0] })},[(likePost.user.picture)?_c('img',{attrs:{"src":likePost.user.picture,"alt":"Profile Picture"}}):_c('b-icon',{staticClass:"icon-profile",attrs:{"icon":"person-fill","aria-hidden":"true"}})],1),_c('p',[_vm._v(" "+_vm._s(likePost.user.name)+" ")])]),_c('p',[(likePost.user.type !== _vm.userTypesText.participant)?_c('span',[_vm._v(" "+_vm._s(likePost.user.type)+" ")]):_c('router-link',{staticClass:"font-weight-bold",style:({ color: _vm.$store.getters.theme[2] }),attrs:{"to":{
                        name: 'chat',
                        query: { _participant: likePost.user.id }
                      }}},[_vm._v(" "+_vm._s(_vm.translation.replies.dashboard.send_message)+" ")])],1)])}),0)]),_c('div',{style:({ 'background-color': _vm.$store.getters.theme[2] })},[_c('div',_vm._l((_vm.likesPost),function(likePost,index){return _c('p',{key:index},[_vm._v(" "+_vm._s(likePost.user.name)+" ")])}),0),_c('button',{on:{"click":function($event){return _vm.$bvModal.show(("likeModal" + (_vm.post.id)))}}},[_c('span',[_vm._v(_vm._s(_vm.translation.global.show_more))])])])],1):_vm._e(),_c('button',{staticClass:"d-flex align-items-center mr-2 btn-post",attrs:{"disabled":!+_vm.post.is_active ||
                _vm.$store.getters.info.user.type === _vm.userTypesText.observer},on:{"click":_vm.toggleLike}},[_c('img',{staticClass:"mr-2 like",class:{ active: !_vm.post.liked },attrs:{"src":require("@/img/like.svg"),"alt":"Like"}}),_c('img',{staticClass:"mr-2 like",class:{ active: _vm.post.liked },attrs:{"src":require("@/img/liked.svg"),"alt":"Like"}}),_c('p',{staticClass:"m-0"},[_vm._v(_vm._s(_vm.post.likes ? _vm.post.likes.length : 0))])])]),_c('button',{staticClass:"d-flex align-items-center btn-post",attrs:{"disabled":_vm.$route.name !== 'forum' &&
              _vm.$store.getters.info.user.type === _vm.userTypesText.observer},on:{"click":_vm.goToPost}},[_c('img',{staticClass:"mr-2",attrs:{"src":require("@/img/comment.svg"),"alt":"Comment"}}),_c('p',{staticClass:"m-0"},[_vm._v(_vm._s(_vm.post.comments.length))])]),_c('comments',{attrs:{"id":_vm.post.id,"open":_vm.open,"type":1,"edit":_vm.edit,"post":_vm.post},on:{"reply":_vm.updateComment}})],1)],1)]):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }