import Vue from "vue";
import Vuex from "vuex";

import Cookies from "js-cookie";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    token: null,
    theme: '["#F6F8F9", "#F1F1F1", "#108FC3", "#163F59"]',
    language: null,
    goToTask: false,
    community: null,
    isLogOut: false,
    img: null,
    video: null,
    attachment: false,
    updateNotification: false,
    activeCommunity: null,
    importLotJson: null,
    chatIsConnected: false,
    info: null,
    chatNotifications: [],
    chatSelectedUser: null,
    chatSelectedSegments: [],
    showTransmissionList: false,
    triggerGetTransmissionsList: false,
    activeTransmissionListId: null,
    showCreateTransmission: false,
    activeTaskIndex: null,
    screenRecordingTaskFolder: null,
    configChart: null,
    imageTaskFolder: null,
    attachmentTaskFolder: null,
    videoTaskFolder: null,
    multimediaTaskFolder: null,
    imageUploaderFolder: null,
    videoUploaderFolder: null,
    attachmentUploaderFolder: null,
    videoEvaluationTaskFolder: null,
    fileName: "",
    labels: [],
    audioRecordingTaskFolder: null,
    privacy: {
      participants: {
        participants_identifier_type_id: "1",
        admins_identifier_type_id: "1"
      },
      moderators: {
        participants_identifier_type_id: "1",
        participants_hide_email: "0",
        participants_hide_login_info: "0"
      },
      observers: {
        participants_identifier_type_id: "1",
        participants_hide_email: "0",
        participants_hide_login_info: "0",
        participants_hide_segments: "0",
        participants_hide_personal_progress: "0",
        participants_hide_operators_comment: "0"
      }
    },
    permissions: {
      moderators: {
        hide_customization: "0",
        hide_terms_and_policies: "0",
        hide_create_activity: "0"
      },
      observers: {
        hide_activities_stats: "0",
        hide_report_replies: "0",
        hide_report_media: "0",
        hide_report_charts: "0",
        hide_manage_participants: "0"
      }
    }
  },

  mutations: {
    UPDATE_ACTIVE_TASK_INDEX(state, payload) {
      state.activeTaskIndex = payload;
      window.localStorage.setItem("activeTaskIndex", JSON.stringify(payload));
    },

    UPDATE_IMPORT_LOT_JOSN(state, payload) {
      state.importLotJson = payload;
      window.localStorage.setItem("importLotJson", JSON.stringify(payload));
    },

    UPDATE_NOTIFICATION(state) {
      state.updateNotification = !state.updateNotification;
    },

    UPDATE_COMMUNITY(state, payload) {
      state.community = payload;
      window.localStorage.setItem("community", JSON.stringify(payload));
    },

    UPDATE_ACTIVE_COMMUNITY(state, payload) {
      state.activeCommunity = payload;
      window.localStorage.setItem("activeCommunity", JSON.stringify(payload));
    },

    UPDATE_THEME(state, payload) {
      state.theme = payload;
      window.localStorage.setItem("theme", JSON.stringify(payload));
    },

    UPDATE_CHAT_IS_CONNECTED(state, payload) {
      state.chatIsConnected = payload;
      window.localStorage.setItem("chatIsConnected", JSON.stringify(payload));
    },

    UPDATE_IS_LOGOUT(state, payload) {
      state.isLogOut = payload;
    },

    UPDATE_GO_TO_TASK(state, payload) {
      state.goToTask = payload;
      window.localStorage.setItem("goToTask", payload);
    },

    UPDATE_LANGUAGE(state, payload) {
      state.language = payload;
      window.localStorage.setItem("language", payload);
    },

    UPDATE_IMG(state, payload) {
      state.img = payload;
    },

    UPDATE_VIDEO(state, payload) {
      state.video = payload;
    },

    UPDATE_ATTACHMENT(state, payload) {
      state.attachment = payload;
    },

    UPDATE_CHAT_NOTIFICATIONS(state, payload) {
      state.chatNotifications = payload;
    },

    UPDATE_CHAT_SELECTED_USER(state, payload) {
      state.chatSelectedUser = payload;
    },

    UPDATE_CHAT_SELECTED_SEGMENTS(state, payload) {
      state.chatSelectedSegments = payload;
    },

    UPDATE_SHOW_TRANSMISSION_LIST(state, payload) {
      state.showTransmissionList = payload;
    },

    UPDATE_TRIGGER_GET_TRANSMISSIONS_LIST(state, payload) {
      state.triggerGetTransmissionsList = payload;
    },
    UPDATE_ACTIVE_TRANSMISSION_LIST_ID(state, payload) {
      state.activeTransmissionListId = payload;
    },

    UPDATE_SHOW_CREATE_TRANSMISSION(state, payload) {
      state.showCreateTransmission = payload;
    },

    UPDATE_TOKEN(state, payload) {
      state.token = payload;
    },

    UPDATE_INFO(state, payload) {
      state.info = payload;
    },

    UPDATE_CONFIG_CHART(state, payload) {
      state.configChart = payload;
    },

    UPDATE_IMAGE_TASK_FOLDER(state, payload) {
      state.imageTaskFolder = payload;
    },

    UPDATE_ATTACHMENT_TASK_FOLDER(state, payload) {
      state.attachmentTaskFolder = payload;
    },

    UPDATE_VIDEO_TASK_FOLDER(state, payload) {
      state.videoTaskFolder = payload;
    },

    UPDATE_MULTIMEDIA_TASK_FOLDER(state, payload) {
      state.multimediaTaskFolder = payload;
    },

    UPDATE_IMAGE_UPLOADER_FOLDER(state, payload) {
      state.imageUploaderFolder = payload;
    },

    UPDATE_VIDEO_UPLOADER_FOLDER(state, payload) {
      state.videoUploaderFolder = payload;
    },

    UPDATE_ATTACHMENT_UPLOADER_FOLDER(state, payload) {
      state.attachmentUploaderFolder = payload;
    },

    UPDATE_FILE_NAME(state, payload) {
      state.fileName = payload;
    },

    UPDATE_VIDEO_EVALUATION_TASK_FOLDER(state, payload) {
      state.videoEvaluationTaskFolder = payload;
    },

    UPDATE_SCREEN_RECORDING_TASK_FOLDER(state, payload) {
      state.screenRecordingTaskFolder = payload;
    },
    UPDATE_AUDIO_RECORDING_TASK_FOLDER(state, payload) {
      state.audioRecordingTaskFolder = payload;
    },
    UPDATE_LABELS(state, payload) {
      state.labels = payload;
    },

    UPDATE_PRIVACY(state, payload) {
      state.privacy = payload;
    },

    UPDATE_PERMISSIONS(state, payload) {
      state.permissions = payload;
    }
  },

  actions: {
    login(context, { token, info }) {
      context.commit("UPDATE_TOKEN", token);
      let date = new Date(info.expires_at);
      Cookies.set("token", token, { expires: date });
      context.commit("UPDATE_INFO", info);
      window.localStorage.setItem("info", JSON.stringify(info));
    },

    logOut(context) {
      Cookies.remove("token");
      localStorage.clear();

      const lang = (navigator.language || navigator.userLanguage).split("-")[0];

      if (lang === "es" || lang === "pt" || lang === "en") {
        context.commit("UPDATE_LANGUAGE", lang);
        window.localStorage.setItem("language", lang);
      } else {
        context.commit("UPDATE_LANGUAGE", "en");
        window.localStorage.setItem("language", "en");
      }
      context.commit("UPDATE_TOKEN", null);
      context.commit("UPDATE_COMMUNITY", null);
      context.commit("UPDATE_INFO", null);
      context.commit(
        "UPDATE_THEME",
        '["#F6F8F9", "#F1F1F1", "#108FC3", "#163F59"]'
      );
      window.localStorage.setItem(
        "theme",
        '["#F6F8F9", "#F1F1F1", "#108FC3", "#163F59"]'
      );
    }
  },

  getters: {
    activeTaskIndex: state =>
      state.activeTaskIndex ||
      JSON.parse(window.localStorage.getItem("activeTaskIndex")),

    importLotJson: state =>
      state.importLotJson ||
      JSON.parse(window.localStorage.getItem("importLotJson")),

    theme: state =>
      JSON.parse(state.theme) ||
      JSON.parse(window.localStorage.getItem("theme")),

    community: state =>
      state.community || JSON.parse(window.localStorage.getItem("community")),

    activeCommunity: state =>
      state.activeCommunity ||
      JSON.parse(window.localStorage.getItem("activeCommunity")),

    token: state => state.token || Cookies.get("token"),

    language: state =>
      state.language || window.localStorage.getItem("language"),

    info: state =>
      state.info || JSON.parse(window.localStorage.getItem("info")),

    goToTask: state =>
      state.goToTask || JSON.parse(window.localStorage.getItem("goToTask")),

    is_over: (state, getters) => {
      return getters.info.community_expired;
    },

    chatIsConnected: state =>
      state.chatIsConnected ||
      JSON.parse(window.localStorage.getItem("chatIsConnected"))
  }
});
