<template>
  <div class="chart">
    <config
      :id="id"
      :activityId="chartData.activityId"
      :taskId="chartData.taskId"
      :optionsCount="getOptionsCount()"
      :isBubble="true"
    />
    <p class="answered">
      {{ translation.replies.charts_tab.answered }}:
      {{ chartData.answersCount }}
    </p>
    <div v-if="config && !loaderConfig">
      <bubble-chart
        :chartId="id"
        :colors="config.theme.colors"
        :hasOneColor="config.theme.hasOneColor"
        :reactions="reactions"
      />
      <div>
        <div class="table-wrapper">
          <div class="header">
            <div>
              {{ translation.replies.charts_tab.option }}
            </div>
            <div>
              {{ translation.replies.timestamp }}
            </div>
            <div>
              {{ translation.replies.charts_tab.answers }}
            </div>
          </div>
          <div class="table-scroll">
            <div
              class="item-wrapper"
              v-for="(reaction, rIndex) in reactions"
              :key="rIndex"
            >
              <div
                v-for="(item, iIndex) in reaction.data"
                :key="iIndex"
                class="item"
              >
                <div>{{ reaction.icon }} {{ reaction.label }}</div>
                <div>
                  {{ item.label | filterTimestamp }}
                </div>
                <div>
                  {{ item.value }}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="footer">
          <div>
            {{ translation.replies.charts_tab.total_responses }}:
            {{ getTotal(pieData) }}
          </div>
        </div>
      </div>
      <div class="d-flex justify-content-end mt-4">
        <button
          :class="{ hover: !xlsxPieLoader }"
          class="btn-download"
          @click="exportXLSXBubble()"
        >
          <div
            v-if="xlsxPieLoader"
            class="d-flex justify-content-center align-items-center"
          >
            <loader2 color="#6b6b6b" class="mr-1" />
            {{ translation.replies.charts_tab.download_table }}
          </div>
          <div v-else>
            <b-icon icon="download" aria-hidden="true"></b-icon>
            {{ translation.replies.charts_tab.download_table }}
          </div>
        </button>
      </div>
      <pie-chart
        :colors="config.theme.colors"
        :hasOneColor="config.theme.hasOneColor"
        :data="pieData"
        :getPercent="getPercent"
        :isPercent="true"
        :statement="chartData.taskObject.question_object.statement"
      />
      <div>
        <div class="table-wrapper">
          <div class="header">
            <div>
              {{ translation.replies.charts_tab.option }}
            </div>
            <div>
              {{ translation.replies.charts_tab.frequency_in_percentage }}
            </div>
            <div>
              {{ translation.replies.charts_tab.frequency_in_numbers }}
            </div>
          </div>
          <div class="table-scroll">
            <div class="item-wrapper">
              <div v-for="(item, iIndex) in pieData" :key="iIndex" class="item">
                <div>
                  {{ item.label }}
                </div>
                <div>{{ getPercent(item, pieData) | filterPercent }}%</div>
                <div>{{ item.value }}</div>
              </div>
            </div>
          </div>
        </div>
        <div class="footer">
          <div>
            {{ translation.replies.charts_tab.total_responses }}:
            {{ getTotal(pieData) }}
          </div>
        </div>
      </div>
      <div class="d-flex justify-content-end mt-4">
        <button
          :class="{ hover: !xlsxPieLoader }"
          class="btn-download"
          @click="exportXLSXPie(pieData)"
        >
          <div
            v-if="xlsxPieLoader"
            class="d-flex justify-content-center align-items-center"
          >
            <loader2 color="#6b6b6b" class="mr-1" />
            {{ translation.replies.charts_tab.download_table }}
          </div>
          <div v-else>
            <b-icon icon="download" aria-hidden="true"></b-icon>
            {{ translation.replies.charts_tab.download_table }}
          </div>
        </button>
      </div>
    </div>
    <div v-else class="d-flex justify-content-center py-5">
      <loader />
    </div>
  </div>
</template>

<script>
import bubbleChart from "@/components/charts/bubbleChart.vue";
import pieChart from "@/components/charts/pieChart.vue";
import config from "@/components/charts/config.vue";
import * as XLSX from "xlsx/xlsx.mjs";
import loader from "@/components/general/loader.vue";
import loader2 from "@/components/general/loader2.vue";
import { mapState } from "vuex";

export default {
  props: {
    chartData: {
      required: true
    },
    id: {
      required: true
    }
  },
  components: {
    bubbleChart,
    pieChart,
    loader,
    loader2,
    config
  },
  filters: {
    filterPercent(value) {
      return value.toFixed(2);
    },
    filterTimestamp(value) {
      const date = new Date(0);
      date.setSeconds(value);

      return date.toISOString().substr(11, 8);
    }
  },
  data() {
    return {
      xlsxPieLoader: false,
      reactions: [],
      loaderConfig: false,
      xlsxBubbleLoader: false
    };
  },
  computed: {
    ...mapState(["configChart"]),
    config() {
      if (!this.configChart) return false;
      return this.configChart[this.id];
    },
    pieData() {
      return this.reactions.map(reaction => ({
        question: "",
        label: `${reaction.icon} ${reaction.label}`,
        value: this.getTotal(reaction.data)
      }));
    }
  },
  watch: {
    config() {
      this.loaderConfig = true;
      setTimeout(() => {
        this.loaderConfig = false;
      }, "1");
    }
  },
  methods: {
    getOptionsCount() {
      const options = this.chartData.taskObject.question_object.options;
      if (options) return options.length;
      else {
        return 5;
      }
    },
    async exportXLSXBubble() {
      this.xlsxBubbleLoader = true;
      const json = [];

      this.reactions.forEach(reaction => {
        reaction.data.forEach(data => {
          json.push({
            option: `${reaction.icon} ${reaction.label}`,
            timestamp: `${data.label}s`,
            count: data.value
          });
        });
      });

      const dataWS = XLSX.utils.json_to_sheet(json);
      const wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, dataWS, "worksheet");
      XLSX.writeFile(wb, `data.xlsx`);
      this.xlsxBubbleLoader = false;
    },
    async exportXLSXPie() {
      this.xlsxPieLoader = true;
      const json = this.pieData.map(item => ({
        option: item.label,
        percentage: `${this.getPercent(item, this.pieData).toFixed(2)}%`,
        count: item.value
      }));

      const dataWS = XLSX.utils.json_to_sheet(json);
      const wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, dataWS, "worksheet");
      XLSX.writeFile(wb, `data.xlsx`);
      this.xlsxPieLoader = false;
    },
    getPercent({ value }, data) {
      const total = (value * 100) / this.getTotal(data);
      return total;
    },
    getTotal(data) {
      return data.reduce((acc, curr) => (acc += curr.value), 0);
    }
  },
  created() {
    this.reactions = this.chartData.answersValues;
  }
};
</script>

<style lang="scss" scoped>
.table-wrapper {
  margin-top: 60px;
  margin-bottom: 20px;
}

.answered {
  color: #6b6b6b;
}

.header {
  display: flex;
  margin-bottom: 10px;
  > div {
    flex: 1;
    background: #e6e6e6;
    padding: 5px 10px;
    font-weight: bold;
    color: #6b6b6b;
    margin-right: 10px;
    &:last-of-type {
      margin-right: 0;
    }
  }
}

.footer {
  background: #e6e6e6;
  padding: 5px 10px;
  font-weight: bold;
  color: #6b6b6b;
  margin-top: 10px;
}

.table-scroll {
  max-height: 400px;
  overflow-x: auto;
  overflow-y: scroll;

  &::-webkit-scrollbar {
    width: 8px;
    height: 10px;
  }
  &::-webkit-scrollbar-track {
    background: rgba(0, 0, 0, 0.05);
    border-radius: 10px;
  }
  &::-webkit-scrollbar-thumb {
    background: #e6e6e6;
    border-radius: 10px;
    height: 10px;
  }
  &::-webkit-scrollbar-thumb:hover {
    background: #ffffff;
  }
}

.item-wrapper {
  &:last-of-type {
    .item {
      &:last-of-type {
        > div {
          border-bottom: none;
        }
      }
    }
  }
}

.item {
  display: flex;
  > div {
    display: flex;
    flex: 1;
    padding: 5px 10px;
    color: #6b6b6b;
    border-bottom: 1px solid #e6e6e6;
    &:nth-of-type(3) {
      padding: 5px 0 5px 10px;
    }
  }
}

.btn-download {
  display: flex;
  align-items: center;
  background: none;
  border: none;
  font-size: 1.2rem;
  font-weight: bold;
  color: #6b6b6b;

  &:focus {
    outline: none;
  }
  svg {
    margin-right: 5px;
  }
}
</style>
