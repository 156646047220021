var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"d-flex w-100 py-2"},[_c('div',{staticClass:"d-flex flex-column align-items-center"},[_c('div',{staticClass:"profile-img",class:{
        pointer:
          (_vm.$store.getters.info.user.type === _vm.userTypesText.moderator ||
            _vm.$store.getters.info.user.type === _vm.userTypesText.admin) &&
          _vm.comment.user.type === _vm.userTypesText.participant
      },style:({ 'background-color': _vm.$store.getters.theme[0] }),on:{"click":function($event){return _vm.goToParticipant(_vm.comment.user)}}},[(_vm.comment.user.picture)?_c('img',{attrs:{"src":_vm.comment.user.picture,"alt":"Profile Picture"}}):_c('b-icon',{staticClass:"icon-profile",attrs:{"icon":"person-fill","aria-hidden":"true"}})],1)]),_c('div',{staticClass:"ml-3 w-100"},[_c('div',{staticClass:"desc d-flex justify-content-between align-items-center"},[_c('h2',{staticClass:"mb-1"},[(_vm.comment.user.type === _vm.userTypesText.participant)?_c('span',[(_vm.$store.getters.info.user.type === _vm.userTypesText.moderator)?_c('span',[_vm._v(" "+_vm._s(_vm.namePrivacy( _vm.privacy.moderators.participants_identifier_type_id, _vm.comment.user.name, _vm.comment.user.id ))+" ")]):(
              _vm.$store.getters.info.user.type === _vm.userTypesText.participant
            )?_c('span',[_vm._v(" "+_vm._s(_vm.namePrivacy( _vm.privacy.participants.participants_identifier_type_id, _vm.comment.user.name, _vm.comment.user.id ))+" ")]):(
              _vm.$store.getters.info.user.type === _vm.userTypesText.observer
            )?_c('span',[_vm._v(" "+_vm._s(_vm.namePrivacy( _vm.privacy.observers.participants_identifier_type_id, _vm.comment.user.name, _vm.comment.user.id ))+" ")]):_c('span',[_vm._v(" "+_vm._s(_vm.comment.user.name)+" ")])]):_c('span',[(_vm.$store.getters.info.user.type === _vm.userTypesText.participant)?_c('span',[_vm._v(" "+_vm._s(_vm.namePrivacy( _vm.privacy.participants.admins_identifier_type_id, _vm.comment.user.name, _vm.comment.user.id ))+" ")]):_c('span',[_vm._v(" "+_vm._s(_vm.comment.user.name)+" ")])])]),_c('div',[(
            _vm.$store.getters.info.user.sub === _vm.comment.user.id && _vm.isEditable
          )?_c('button',{staticClass:"btn-post underline",on:{"click":function($event){_vm.edit = !_vm.edit}}},[_vm._v(" "+_vm._s(_vm.translation.global.edit)+" ")]):_vm._e(),(
            _vm.$store.getters.info.user.sub === _vm.comment.user.id ||
              _vm.$store.getters.info.user.type === _vm.userTypesText.moderator ||
              _vm.$store.getters.info.user.type === _vm.userTypesText.admin
          )?_c('button',{staticClass:"btn-post underline",on:{"click":function($event){return _vm.$bvModal.show(("deleteCommentModal" + (_vm.comment.id)))}}},[_vm._v(" "+_vm._s(_vm.translation.global.delete)+" ")]):_vm._e(),_c('b-modal',{attrs:{"id":("deleteCommentModal" + (_vm.comment.id)),"cancel-title":_vm.translation.global.cancel,"title":_vm.translation.forum.subreply.delete_reply},on:{"ok":_vm.deletePost}},[_vm._v(" "+_vm._s(_vm.translation.forum.subreply.are_you_sure_delete_reply)+" ")])],1)]),_c('div',{staticClass:"post-info"},[_c('span',{staticClass:"mr-3"},[_vm._v(_vm._s(_vm._f("formatDate")(_vm.comment.created_at)))]),(+_vm.comment.was_edited)?_c('span',[_vm._v(_vm._s(_vm.translation.forum.edited))]):_vm._e()]),_c('p',{staticClass:"text",domProps:{"innerHTML":_vm._s(_vm.comment.comment)}}),_c('attachment',{attrs:{"attachments":_vm.comment.attachments,"deleteFile":null}}),_c('div',{staticClass:"d-flex"},[_c('div',{staticClass:"likes-wrapper"},[(_vm.comment.likes.length)?_c('div',{staticClass:"likes-list"},[_c('b-modal',{attrs:{"cancel-title":_vm.translation.global.cancel,"id":("likeModalComment" + (_vm.comment.id)),"title":"Likes","hide-footer":""}},[_c('div',{staticClass:"user-modal-scroll"},_vm._l((_vm.comment.likes),function(likePost,index){return _c('div',{key:index,staticClass:"user-modal"},[_c('div',[_c('div',{staticClass:"profile-img",style:({ 'background-color': _vm.$store.getters.theme[0] })},[(likePost.user.picture)?_c('img',{attrs:{"src":likePost.user.picture,"alt":"Profile Picture"}}):_c('b-icon',{staticClass:"icon-profile",attrs:{"icon":"person-fill","aria-hidden":"true"}})],1),_c('p',[_vm._v(" "+_vm._s(likePost.user.name)+" ")])]),_c('p',[(likePost.user.type !== _vm.userTypesText.participant)?_c('span',[_vm._v(" "+_vm._s(likePost.user.type)+" ")]):_c('router-link',{staticClass:"font-weight-bold",style:({ color: _vm.$store.getters.theme[2] }),attrs:{"to":{
                      name: 'chat',
                      query: { _participant: likePost.user.id }
                    }}},[_vm._v(" "+_vm._s(_vm.translation.replies.dashboard.send_message)+" ")])],1)])}),0)]),_c('div',{style:({ 'background-color': _vm.$store.getters.theme[2] })},[_c('div',_vm._l((_vm.likesPost),function(likePost,index){return _c('p',{key:index},[_vm._v(" "+_vm._s(likePost.user.name)+" ")])}),0),_c('button',{on:{"click":function($event){return _vm.$bvModal.show(("likeModalComment" + (_vm.comment.id)))}}},[_c('span',[_vm._v(_vm._s(_vm.translation.global.show_more))])])])],1):_vm._e(),_c('button',{staticClass:"d-flex align-items-center mr-2 btn-post",attrs:{"disabled":_vm.$store.getters.info.user.type === _vm.userTypesText.observer},on:{"click":_vm.toggleLike}},[_c('img',{staticClass:"mr-2 like",class:{ active: !_vm.comment.liked },attrs:{"src":require("@/img/like.svg"),"alt":"Like"}}),_c('img',{staticClass:"mr-2 like",class:{ active: _vm.comment.liked },attrs:{"src":require("@/img/liked.svg"),"alt":"Like"}}),_c('p',{staticClass:"m-0"},[_vm._v(_vm._s(_vm.comment.likes ? _vm.comment.likes.length : 0))])])]),(_vm.hasComment)?_c('button',{staticClass:"d-flex align-items-center btn-post",attrs:{"disabled":_vm.$store.getters.info.user.type === _vm.userTypesText.observer},on:{"click":function($event){_vm.open = !_vm.open}}},[_c('img',{staticClass:"mr-2",attrs:{"src":require("@/img/comment.svg"),"alt":"Comment"}}),_c('p',{staticClass:"m-0"},[_vm._v(" "+_vm._s(_vm.comment.comments ? _vm.comment.comments.length : 0)+" ")])]):_vm._e(),_c('comments',{attrs:{"id":("" + (_vm.comment.id) + _vm.index + _vm.type),"type":2,"open":_vm.open,"edit":_vm.edit,"post":_vm.comment},on:{"reply":_vm.updateComment}})],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }