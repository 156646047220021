var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"container"},[_c('div',{staticClass:"row py-5"},[_c('div',{staticClass:"col"},[_c('div',{staticClass:"card-b p-5"},[_c('div',[_c('div',[_c('h2',{staticClass:"h5 font-weight-bold mb-0",style:({ color: _vm.$store.getters.theme[3] })},[_vm._v(" "+_vm._s(_vm.translation.stats_assignments.assignments_statistics)+" ")]),_c('search',{staticClass:"search-position"})],1),(_vm.filterActivities.length && !_vm.loaderActivities)?_c('div',{staticClass:"table-wrapper"},[_c('div',{staticClass:"table mt-5"},[_c('div',{staticClass:"table-header",style:({ color: _vm.$store.getters.theme[3] })},[_c('div',{staticClass:"lg"}),_c('div',{staticClass:"md"},[_vm._v(" "+_vm._s(_vm.translation.stats_assignments.start_and_end)+" ")]),_c('div',[_vm._v(" "+_vm._s(_vm.translation.stats_assignments.number_of_participants)+" ")]),_c('div',[_vm._v(" "+_vm._s(_vm.translation.stats_assignments.complete)+" ")]),_c('div',[_vm._v(" "+_vm._s(_vm.translation.stats_assignments.incomplete)+" ")]),_c('div',[_vm._v(" "+_vm._s(_vm.translation.stats_assignments.not_started)+" ")]),_c('div',{staticClass:"sm"}),_c('div',{staticClass:"sm"})]),_vm._l((_vm.filterActivities),function(activity){return _c('div',{key:activity.id},[_c('div',{staticClass:"table-content mt-2 p-1 d-flex align-items-center",class:{ active: _vm.showTaskStats === activity.id },style:({
                    background:
                      _vm.showTaskStats === activity.id
                        ? _vm.$store.getters.theme[0]
                        : 'rgba(0,0,0,0)',
                    'border-color':
                      _vm.showTaskStats === activity.id
                        ? _vm.$store.getters.theme[0]
                        : '#999999'
                  }),on:{"click":function($event){return _vm.toggleTaskStats(activity.id)}}},[_c('div',{staticClass:"lg name"},[_c('span',{style:({ color: _vm.$store.getters.theme[3] })},[_vm._v(" "+_vm._s(_vm.translation.stats_assignments.assignment)+" - ")]),_c('span',{staticClass:"ml-1",style:({ color: _vm.$store.getters.theme[2] })},[_vm._v(" "+_vm._s(activity.name)+" ")])]),_c('div',{staticClass:"md"},[_vm._v(" "+_vm._s(_vm._f("formatDate")(activity.dates.start_at))+" - "+_vm._s(_vm._f("formatDate")(activity.dates.end_at))+" ")]),_c('div',[_vm._v(" "+_vm._s(activity.participant_count)+" ")]),_c('div',[_vm._v(" "+_vm._s(activity.completes)+" ")]),_c('div',[_vm._v(" "+_vm._s(activity.incomplete)+" ")]),_c('div',[_vm._v(" "+_vm._s(activity.not_started)+" ")]),_c('div',{staticClass:"sm"},[(_vm.loaderExportData !== activity.id)?_c('button',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",value:({ variant: 'secondary' }),expression:"{ variant: 'secondary' }",modifiers:{"hover":true}}],staticClass:"btn-export hover",style:({ color: _vm.$store.getters.theme[2] }),attrs:{"title":_vm.translation.stats_assignments.export_statistics},on:{"click":function($event){return _vm.exportData(activity)}}},[_c('b-icon',{attrs:{"icon":"file-earmark-arrow-down-fill","aria-hidden":"true"}})],1):_c('loader2',{attrs:{"color":_vm.$store.getters.theme[2]}})],1),(
                      _vm.$store.getters.info.user.type !==
                        _vm.userTypesText.observer ||
                        !+_vm.privacy.observers
                          .participants_hide_personal_progress
                    )?_c('div',{staticClass:"sm"},[_c('b-icon',{staticClass:"caret",style:({ color: _vm.$store.getters.theme[2] }),attrs:{"icon":"caret-right-fill","aria-hidden":"true"}})],1):_vm._e()]),(
                    _vm.showTaskStats &&
                      _vm.showTaskStats === activity.id &&
                      (_vm.$store.getters.info.user.type !==
                        _vm.userTypesText.observer ||
                        !+_vm.privacy.observers
                          .participants_hide_personal_progress)
                  )?_c('task-stats',{attrs:{"activity":activity,"showTaskStats":_vm.showTaskStats}}):_vm._e()],1)})],2),(_vm.minPerPage < _vm.total)?_c('pagination-query',{attrs:{"perPage":_vm.perPage,"total":_vm.total,"options":[10, 20, 50, 100]},on:{"perPageUpdate":function (newPerPage) { return (_vm.perPage = newPerPage); }}}):_vm._e()],1):(_vm.loaderActivities)?_c('div',{staticClass:"d-flex justify-content-center py-5"},[_c('loader')],1):(!_vm.filterActivities.length && _vm.queryValue)?_c('div',{staticClass:"d-flex justify-content-center py-5"},[_vm._v(" "+_vm._s(_vm.translation.global.sorry_we_have)+" \""+_vm._s(_vm.queryValue)+"\" ")]):_c('div',{staticClass:"d-flex justify-content-center py-5"},[_vm._v(" "+_vm._s(_vm.translation.stats_assignments.no_assignment)+" ")])])])])])])}
var staticRenderFns = []

export { render, staticRenderFns }