var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"container"},[_c('div',{staticClass:"row py-5"},[_c('div',{staticClass:"col"},[_c('div',{staticClass:"card-b mb-4 p-5"},[_c('div',{staticClass:"option-btns"},[_c('button',{staticClass:"btn px-4 px-md-5",class:{ active: _vm.option === _vm.tabOptions.addOperator },style:({
              background:
                _vm.option === _vm.tabOptions.addOperator
                  ? _vm.$store.getters.theme[2]
                  : _vm.$store.getters.theme[0],
              color: _vm.option === _vm.tabOptions.addOperator ? '#ffffff' : '#999999'
            }),on:{"click":function($event){return _vm.changeTab(_vm.tabOptions.addOperator)}}},[_vm._v(" "+_vm._s(_vm.translation.operators.add_operators.add_operators)+" ")]),_c('button',{staticClass:"btn px-4 px-md-5",class:{ active: _vm.option === _vm.tabOptions.manageOperator },style:({
              background:
                _vm.option === _vm.tabOptions.manageOperator
                  ? _vm.$store.getters.theme[2]
                  : _vm.$store.getters.theme[0],
              color:
                _vm.option === _vm.tabOptions.manageOperator ? '#ffffff' : '#999999'
            }),on:{"click":function($event){return _vm.changeTab(_vm.tabOptions.manageOperator)}}},[_vm._v(" "+_vm._s(_vm.translation.operators.manage_operators.manage_operators)+" ")])]),_c('transition',{attrs:{"mode":"out-in"}},[(_vm.option === _vm.tabOptions.addOperator)?_c('add-operators'):_vm._e(),(_vm.option === _vm.tabOptions.manageOperator)?_c('manage-operators'):_vm._e()],1)],1)])])])}
var staticRenderFns = []

export { render, staticRenderFns }