<template>
  <div v-if="!isPreview" class="bg-white">
    <div class="container py-3">
      <div class="row align-items-center">
        <div
          class="col d-flex justify-content-around justify-content-md-start text"
        >
          <router-link :to="{ name: 'privacypolicy' }" class="mr-md-5" href="">
            {{ translation.footer.privacy_policy }}
          </router-link>
          <router-link :to="{ name: 'termsofuse' }" class="mr-md-5" href="">
            {{ translation.footer.terms_of_use }}
          </router-link>
        </div>
        <div class="col text-right logo">
          <router-link :to="{ name: 'feed' }">
            <img
              v-if="
                $store.getters.community &&
                  $store.getters.community.logo &&
                  $store.getters.community.logo.length
              "
              class="img-fluid "
              :src="$store.getters.community.logo"
              alt="Logo"
            />
            <img v-else src="@/img/logo-communities.png" alt="Logo" />
          </router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "c-footer",
  computed: {
    isPreview() {
      return this.$route.query._preview;
    }
  }
};
</script>

<style lang="scss" scoped>
.text {
  color: #a0a0a0;
  white-space: nowrap;
  a {
    color: #a0a0a0;
  }
}
.logo {
  display: flex;
  justify-content: flex-end;
  margin-left: 30px;

  img {
    max-height: 55px;
  }
}

@media (max-width: 991.98px) {
  .logo {
    margin-left: 20px;
  }
}

@media (max-width: 767.98px) {
  .logo {
    display: none;
  }
}
</style>
