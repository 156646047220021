<template>
  <div class="row">
    <div v-if="!loading" class="col">
      <div class="task-text img-question">
        <p v-html="text"></p>
      </div>
      <div class="d-flex flex-wrap">
        <attachment :attachments="task.attachments" :deleteFile="null" />
      </div>
      <div class="d-flex justify-content-center py-4">
        <div
          class="select-box"
          :style="{ background: $store.getters.theme[1] }"
        >
          <p class="mb-1">
            {{ translation.answer_task.reaction.minimum_number }}: {{ min }}
          </p>
          <p class="mb-0">
            {{ translation.answer_task.reaction.maximum_number }}: {{ max }}
          </p>
        </div>
      </div>

      <div class="video-task">
        <video
          ref="videoTask"
          controls
          :src="task.question_object.video_url"
        ></video>
      </div>

      <div class="add-reaction">
        <b-form-select
          :disabled="task.is_answered"
          @change="pauseVideo"
          v-model="reactionOption"
          :options="options"
        ></b-form-select>
        <b-form-input
          :disabled="task.is_answered"
          v-model="commentTask"
          :placeholder="translation.answer_task.comments"
        ></b-form-input>

        <button :disabled="task.is_answered" class="btn" @click="addReaction">
          {{ translation.global.add }}
        </button>
      </div>

      <div class="reactions">
        <div class="mb-4" v-for="(reaction, index) in reactions" :key="index">
          <div class="time">{{ reaction.timestamp | formatTime }}</div>

          <div class="comment">
            <div class="break-label">
              <div>
                <p class="title">
                  {{ translation.task.video_evaluation_type }} {{ index + 1 }}
                </p>
                <p>{{ reaction.icon }} {{ reaction.label }}</p>
              </div>
              <button
                :disabled="task.is_answered"
                @click="removeReaction(index)"
                class="btn btn-remove"
              >
                <b-icon icon="x"></b-icon>
              </button>
            </div>

            <b-form-textarea
              v-model="reaction.comment"
              :placeholder="translation.answer_task.comments"
              :disabled="task.is_answered"
            ></b-form-textarea>
          </div>
        </div>
      </div>

      <div class="comments mt-4">
        <label
          for="comments"
          v-if="
            task.question_object.has_custom_comment_label &&
              task.question_object.comment_label &&
              task.question_object.comment_label.length
          "
          >{{ task.question_object.comment_label }}</label
        >
        <label for="comments" v-else>
          {{ translation.answer_task.comments }}
        </label>
        <textarea
          class="form-control"
          id="textarea"
          v-model="comments"
          :maxlength="maxCharacters"
          rows="3"
          max-rows="6"
          :disabled="task.is_answered"
        ></textarea>
        <characterAndWordCounter
          :count="
            hasWordsLimitation
              ? this.comments.length
                ? this.comments.trim().split(/\s+/).length
                : 0
              : comments.length
          "
          :max="hasWordsLimitation ? maxWords : maxCharacters"
          :label="
            hasWordsLimitation
              ? translation.answer_task.words
              : translation.answer_task.characters
          "
        />
      </div>
    </div>
    <div v-if="loading" class="w-100 d-flex justify-content-center">
      <loader />
    </div>
  </div>
</template>

<script>
import { api } from "@/services.js";
import loader from "@/components/general/loader.vue";
import attachment from "@/components/general/attachment.vue";
import characterAndWordCounter from "@/components/general/characterAndWordCounter.vue";
import { tabOptions } from "@/constants";
import { outsideClick } from "@/helpers.js";

import { userTypesText } from "@/constants";

export default {
  components: { loader, attachment, characterAndWordCounter },
  props: {
    task: {
      required: true
    },
    save: {
      required: true
    },
    savePreview: {
      required: true
    },
    getTasks: {
      required: true
    }
  },
  filters: {
    formatTime(value) {
      const date = new Date(0);
      date.setSeconds(value);

      return date.toISOString().substr(11, 8);
    }
  },
  data() {
    return {
      tabOptions,
      outsideClick,
      question: "",
      loading: false,
      reactions: [],
      commentTask: "",
      reactionOption: null,
      comments: "",
      disabled: false
    };
  },

  computed: {
    text() {
      const regexpVideo = /(?:(<iframe.*?src="))(.*?)((?:".*?>))((?:<\/iframe>))?/g;

      return this.$store.getters.info.user.type === userTypesText.participant
        ? this.task.raw_question.replace(
            regexpVideo,
            "<video src='$2' width='350' height='197' frameborder='0' allowfullscreen='allowfullscreen' controls controlsList='nodownload'></iframe>"
          )
        : this.task.raw_question;
    },
    page() {
      return this.$store.getters.activeTaskIndex;
    },
    options() {
      const options = [
        {
          value: null,
          text: this.translation.answer_task.reaction.select_option
        }
      ];
      options.push(
        ...this.task.question_object.options.map(option => ({
          value: option,
          text: `${option.icon} ${option.label}`
        }))
      );
      return options;
    },
    min() {
      return this.task.question_object.min_answers
        ? this.task.question_object.min_answers
        : 1;
    },
    max() {
      return this.task.question_object.max_answers
        ? this.task.question_object.max_answers
        : 10;
    },
    mandatoryComment() {
      return this.task.question_object.mandatory_comment;
    },
    obligatoryComment() {
      return this.task.question_object?.has_obligatory_comments;
    },

    hasCharactersLimitation() {
      return this.task.question_object?.has_characters_limitation;
    },

    maxCharacters() {
      return this.hasCharactersLimitation
        ? this.task.question_object?.characters_count?.maximum
        : 5000;
    },

    minCharacters() {
      return this.hasCharactersLimitation
        ? this.task.question_object?.characters_count?.minimum
        : 0;
    },

    hasWordsLimitation() {
      return this.task.question_object?.has_words_limitation;
    },

    maxWords() {
      return this.hasWordsLimitation
        ? this.task.question_object?.words_count?.maximum
        : 2000;
    },

    minWords() {
      return this.hasWordsLimitation
        ? this.task.question_object?.words_count?.minimum
        : 0;
    }
  },
  watch: {
    save() {
      this.answer();
    },
    savePreview() {
      this.answerPreview();
    },
    page() {
      this.init();
    },
    comments() {
      if (this.comments.length >= 5000) {
        this.comments = this.comments
          .split("")
          .splice(0, 5000)
          .join("");
      }
    }
  },
  methods: {
    pauseVideo() {
      this.$refs.videoTask.pause();
    },
    removeReaction(index) {
      this.reactions.splice(index, 1);
    },
    addReaction() {
      if (!this.mandatoryComment || this.commentTask.length) {
        if (this.reactionOption) {
          this.$refs.videoTask.pause();
          this.reactions.push({
            icon: this.reactionOption.icon,
            label: this.reactionOption.label,
            timestamp: this.$refs.videoTask.currentTime,
            comment: this.commentTask
          });
          this.reactionOption = null;
          this.commentTask = "";
        } else {
          this.$toast.error(
            this.translation.answer_task.reaction.select_option
          );
        }
      } else {
        this.$toast.error(this.translation.answer_task.error.empty_answer);
      }
    },
    answerPreview() {
      const missingComments = this.reactions
        .map((reaction, index) => ({ ...reaction, index }))
        .filter(reaction => !reaction.comment.length);

      if (!this.loading) {
        if (this.reactions.length) {
          if (this.reactions.length >= this.min) {
            if (this.reactions.length <= this.max) {
              if (!missingComments.length || !this.mandatoryComment) {
                if (this.obligatoryComment && !this.comments.length) {
                  this.$toast.error(
                    this.translation.answer_task.obligatory_comment_tost
                  );

                  return;
                }

                if (
                  this.comments.length &&
                  this.hasCharactersLimitation &&
                  this.comments.length < this.minCharacters
                ) {
                  this.$toast.error(
                    `${this.translation.answer_task.min_characters} ${this.minCharacters} ${this.translation.answer_task.characters}`
                  );

                  return;
                }

                if (this.comments.length && this.hasWordsLimitation) {
                  const wordsCount = this.comments.split(/\s+/).length;

                  if (wordsCount < this.minWords) {
                    this.$toast.error(
                      `${this.translation.answer_task.min_word} ${this.minWords} ${this.translation.answer_task.words}`
                    );

                    return;
                  }
                }

                this.loading = true;

                const answer = {
                  comment: this.comments,
                  options: this.reactions.map((reaction, index) => ({
                    ...reaction,
                    index
                  }))
                };

                const body = {
                  task_id: this.task.id,
                  has_attachment: 0,
                  has_video: 0,
                  has_image: 0,
                  raw_answer: JSON.stringify(answer),
                  answer
                };

                api
                  .post("/answer/send/preview", body, {
                    headers: {
                      "Content-Type": "application/json",
                      Authorization: `Bearer ${this.$store.getters.token}`
                    }
                  })
                  .then(r => {
                    if (r.status === 200) {
                      this.$toast.success(
                        this.translation.answer_task.success.reply_registered
                      );
                      this.getTasks("save");
                      this.loading = false;
                    }
                  })
                  .catch(() => {
                    this.$toast.error(
                      this.translation.answer_task.error.reply_not_registered
                    );
                    this.loading = false;
                  });
              } else {
                let labels = "";
                missingComments.forEach(
                  (comment, index) =>
                    (labels +=
                      index === missingComments.length - 1
                        ? ` ${
                            this.translation.task.video_evaluation_type
                          } ${comment.index + 1}.`
                        : ` ${
                            this.translation.task.video_evaluation_type
                          } ${comment.index + 1},`)
                );
                this.$toast.error(
                  `${this.translation.answer_task.error.missing_comments}: ${labels}`
                );
              }
            } else {
              this.$toast.error(
                `${this.translation.answer_task.reaction.maximum_number}: ${this.max}`
              );
            }
          } else {
            this.$toast.error(
              `${this.translation.answer_task.reaction.minimum_number}: ${this.min}`
            );
          }
        } else {
          this.$toast.error(this.translation.answer_task.error.empty_answer);
        }
      }
    },
    answer() {
      const missingComments = this.reactions
        .map((reaction, index) => ({ ...reaction, index }))
        .filter(reaction => !reaction.comment.length);

      if (!this.loading) {
        if (this.reactions.length) {
          if (this.reactions.length >= this.min) {
            if (this.reactions.length <= this.max) {
              if (!missingComments.length || !this.mandatoryComment) {
                if (this.obligatoryComment && !this.comments.length) {
                  this.$toast.error(
                    this.translation.answer_task.obligatory_comment_tost
                  );

                  return;
                }

                if (
                  this.comments.length &&
                  this.hasCharactersLimitation &&
                  this.comments.length < this.minCharacters
                ) {
                  this.$toast.error(
                    `${this.translation.answer_task.min_characters} ${this.minCharacters} ${this.translation.answer_task.characters}`
                  );

                  return;
                }

                if (this.comments.length && this.hasWordsLimitation) {
                  const wordsCount = this.comments.split(/\s+/).length;

                  if (wordsCount < this.minWords) {
                    this.$toast.error(
                      `${this.translation.answer_task.min_word} ${this.minWords} ${this.translation.answer_task.words}`
                    );

                    return;
                  }
                }

                this.loading = true;

                const answer = {
                  comment: this.comments,
                  options: this.reactions.map((reaction, index) => ({
                    ...reaction,
                    index
                  }))
                };

                const body = {
                  task_id: this.task.id,
                  participant_id: this.$store.getters.info.user.sub,
                  has_attachment: 0,
                  has_video: 0,
                  has_image: 0,
                  raw_answer: JSON.stringify(answer),
                  answer
                };

                api
                  .post("/answer/send", body, {
                    headers: {
                      "Content-Type": "application/json",
                      Authorization: `Bearer ${this.$store.getters.token}`
                    }
                  })
                  .then(r => {
                    if (r.status === 200) {
                      this.$toast.success(
                        this.translation.answer_task.success.reply_registered
                      );
                      this.getTasks("save");
                      this.loading = false;
                    }
                  })
                  .catch(() => {
                    this.$toast.error(
                      this.translation.answer_task.error.reply_not_registered
                    );
                    this.loading = false;
                  });
              } else {
                let labels = "";
                missingComments.forEach(
                  (comment, index) =>
                    (labels +=
                      index === missingComments.length - 1
                        ? ` ${
                            this.translation.task.video_evaluation_type
                          } ${comment.index + 1}.`
                        : ` ${
                            this.translation.task.video_evaluation_type
                          } ${comment.index + 1},`)
                );
                this.$toast.error(
                  `${this.translation.answer_task.error.missing_comments}: ${labels}`
                );
              }
            } else {
              this.$toast.error(
                `${this.translation.answer_task.reaction.maximum_number}: ${this.max}`
              );
            }
          } else {
            this.$toast.error(
              `${this.translation.answer_task.reaction.minimum_number}: ${this.min}`
            );
          }
        } else {
          this.$toast.error(this.translation.answer_task.error.empty_answer);
        }
      }
    },
    init() {
      if (this.task.is_answered) {
        this.comments = this.task.answer.answer_object.comment;
        this.reactions = this.task.answer.answer_object.options;
        this.disabled = true;
      } else if (this.task.answer && this.$route.query._preview) {
        this.comments = this.task.answer.answer_object.comment;
        this.reactions = this.task.answer.answer_object.options;
      } else {
        this.comments = "";
        this.disabled = false;
        this.reactions = [];
      }
    }
  },
  created() {
    this.init();
  }
};
</script>

<style lang="scss" scoped>
.task-text {
  display: flex;
  flex-direction: column;
  color: #6b6b6b;
  overflow-wrap: break-word;
}

.comments {
  label {
    color: #6b6b6b;
  }
  textarea {
    width: 100%;
    display: block;
    border: 1px solid #b3b3b3;
    border-radius: 10px;
  }
}

.select-box {
  display: inline-block;
  padding: 20px;
  border-radius: 20px;
  color: #6b6b6b;
  font-weight: bold;
  font-size: 1.2rem;
}

.video-task {
  display: flex;
  justify-content: center;
  video {
    width: 100%;
    max-width: 600px;
  }
}

.add-reaction {
  margin-top: 40px;
  display: flex;

  select {
    margin-right: 10px;
    max-width: 200px;
  }

  button {
    background: #10c398;
    font-weight: bold;
    color: #fff;
    margin-left: 10px;
  }
}

.reactions {
  margin-top: 20px;
  p {
    margin-right: 10px;
    font-weight: bold;
    color: #6b6b6b;
  }
  div {
    display: flex;
    .time {
      display: flex;
      align-items: center;
      font-size: 2rem;
      font-weight: bold;
      color: #6b6b6b;
      background: #efefef;
      padding: 10px;
      border-radius: 10px;
    }

    .comment {
      display: flex;
      flex-direction: column;
      margin-left: 20px;
      font-weight: bold;
      color: #6b6b6b;
      > div {
        display: flex;
        justify-content: space-between;
        align-items: center;
      }
      textarea {
        margin-top: 10px;
      }
    }
  }
}

.break-label {
  display: flex;
  word-wrap: break-word;
  word-break: break-word;
  div {
    display: flex;
    flex-direction: column;
    margin-bottom: 15px;
    p {
      margin: 0;
    }
  }
}

.btn-remove {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 25px;
  height: 25px;
  font-weight: bold;
  color: #fff;
  font-size: 1rem;
  border-radius: 50%;
  white-space: nowrap;
  background: #df2938;
  align-self: flex-start;
}
</style>
