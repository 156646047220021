<template>
  <div class="row task-wrapper">
    <div v-if="!loading" class="col">
      <div class="task-text img-question">
        <p v-html="text"></p>
      </div>
      <div class="d-flex flex-wrap">
        <attachment :attachments="task.attachments" :deleteFile="null" />
      </div>
      <div class="d-md-block d-none">
        <div
          v-if="matrix.length"
          class="table-responsive pr-3 pb-4"
          :style="{ background: $store.getters.theme[1] }"
        >
          <table class="table table-borderless table-sm">
            <tbody>
              <tr>
                <td colspan="1"></td>
                <td
                  :style="{
                    ['min-width']:
                      col.value.length > 50
                        ? col.value.length > 100
                          ? col.value.length > 200
                            ? '500px'
                            : '250px'
                          : '150px'
                        : '100px'
                  }"
                  class="col-table"
                  scope="col"
                  v-for="(col, cIndex) in cols"
                  :key="`col-${cIndex}`"
                >
                  {{ col.value }}
                </td>
              </tr>
              <tr v-for="(row, rIndex) in rows" :key="`row-${rIndex}`">
                <td
                  :style="{
                    ['min-width']:
                      row.value.length > 50
                        ? row.value.length > 100
                          ? row.value.length > 200
                            ? '500px'
                            : '250px'
                          : '150px'
                        : '100px'
                  }"
                  class="row-table"
                  scope="row"
                >
                  {{ row.value }}
                </td>
                <td
                  :class="{ active: value.value }"
                  class="value-table"
                  v-for="(value, vIndex) in getValues(row)"
                  :key="`value-${vIndex}`"
                  @click="toggleValue(value)"
                >
                  <p :class="{ disabled: isDisabled(value) }">
                    <b-icon icon="x"></b-icon>
                  </p>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="comments">
          <label
            for="comments"
            v-if="
              task.question_object.has_custom_comment_label &&
                task.question_object.comment_label &&
                task.question_object.comment_label.length
            "
            >{{ task.question_object.comment_label }}</label
          >
          <label for="comments" v-else>
            {{ translation.answer_task.comments }}
          </label>
          <textarea
            class="form-control"
            id="textarea"
            v-model="comments"
            :maxlength="maxCharacters"
            rows="3"
            max-rows="6"
            :disabled="task.is_answered"
          ></textarea>
          <characterAndWordCounter
            :count="
              hasWordsLimitation
                ? this.comments.length
                  ? this.comments.trim().split(/\s+/).length
                  : 0
                : comments.length
            "
            :max="hasWordsLimitation ? maxWords : maxCharacters"
            :label="
              hasWordsLimitation
                ? translation.answer_task.words
                : translation.answer_task.characters
            "
          />
        </div>
      </div>
      <div class="d-md-none">
        <div>
          <div v-for="(col, cIndex) in cols" :key="cIndex">
            <button
              :style="{ background: $store.getters.theme[1] }"
              class="btn col-mobile-btn px-2 py-1"
              :id="`col-${cIndex}`"
              @click="openRows(`rows-${cIndex}`, `col-${cIndex}`)"
            >
              <div>
                {{ col.value }}
              </div>
              <b-icon icon="caret-down-fill" aria-hidden="true"></b-icon>
            </button>
            <div class="row-mobile-wrapper" :id="`rows-${cIndex}`">
              <div v-for="(row, rIndex) in rows" :key="rIndex">
                <b-form-checkbox
                  :id="`matrix-${cIndex}-${rIndex}`"
                  v-model="matrix[cIndex][rIndex].value"
                  :name="`matrix-${cIndex}-${rIndex}`"
                  :disabled="
                    (isDisabled(matrix[cIndex][rIndex]) &&
                      !matrix[cIndex][rIndex].value) ||
                      task.is_answered
                  "
                >
                  {{ row.value }}
                </b-form-checkbox>
              </div>
            </div>
          </div>
        </div>
        <div class="comments">
          <label
            for="comments"
            v-if="
              task.question_object.has_custom_comment_label &&
                task.question_object.comment_label &&
                task.question_object.comment_label.length
            "
            >{{ task.question_object.comment_label }}</label
          >
          <label for="comments" v-else>
            {{ translation.answer_task.comments }}
          </label>
          <textarea
            class="form-control"
            id="textarea"
            v-model="comments"
            :maxlength="maxCharacters"
            rows="3"
            max-rows="6"
            :disabled="task.is_answered"
          ></textarea>
          <characterAndWordCounter
            :count="
              hasWordsLimitation
                ? this.comments.length
                  ? this.comments.trim().split(/\s+/).length
                  : 0
                : comments.length
            "
            :max="hasWordsLimitation ? maxWords : maxCharacters"
            :label="
              hasWordsLimitation
                ? translation.answer_task.words
                : translation.answer_task.characters
            "
          />
        </div>
      </div>
    </div>
    <div v-if="loading" class="w-100 d-flex justify-content-center">
      <loader />
    </div>
  </div>
</template>

<script>
import { api } from "@/services.js";
import loader from "@/components/general/loader.vue";
import attachment from "@/components/general/attachment.vue";
import characterAndWordCounter from "@/components/general/characterAndWordCounter.vue";

import { userTypesText } from "@/constants";

export default {
  components: { loader, attachment, characterAndWordCounter },
  props: {
    task: {
      required: true
    },
    save: {
      required: true
    },
    savePreview: {
      required: true
    },
    getTasks: {
      required: true
    }
  },
  data() {
    return {
      comments: "",
      question: "",
      loading: false,
      matrix: []
    };
  },

  computed: {
    text() {
      const regexpVideo = /(?:(<iframe.*?src="))(.*?)((?:".*?>))((?:<\/iframe>))?/g;

      return this.$store.getters.info.user.type === userTypesText.participant
        ? this.task.raw_question.replace(
            regexpVideo,
            "<video src='$2' width='350' height='197' frameborder='0' allowfullscreen='allowfullscreen' controls controlsList='nodownload'></iframe>"
          )
        : this.task.raw_question;
    },
    cols() {
      return this.task.question_object.columns;
    },
    rows() {
      return this.task.question_object.rows;
    },
    type() {
      return this.task.question_object.matrix_type;
    },
    page() {
      return this.$store.getters.activeTaskIndex;
    },
    obligatoryComment() {
      return this.task.question_object?.has_obligatory_comments;
    },

    hasCharactersLimitation() {
      return this.task.question_object?.has_characters_limitation;
    },

    maxCharacters() {
      return this.hasCharactersLimitation
        ? this.task.question_object?.characters_count?.maximum
        : 5000;
    },

    minCharacters() {
      return this.hasCharactersLimitation
        ? this.task.question_object?.characters_count?.minimum
        : 0;
    },

    hasWordsLimitation() {
      return this.task.question_object?.has_words_limitation;
    },

    maxWords() {
      return this.hasWordsLimitation
        ? this.task.question_object?.words_count?.maximum
        : 2000;
    },

    minWords() {
      return this.hasWordsLimitation
        ? this.task.question_object?.words_count?.minimum
        : 0;
    }
  },
  watch: {
    save() {
      this.answer();
    },
    savePreview() {
      this.answerPreview();
    },
    page() {
      this.init();
    },
    comments() {
      if (this.comments.length >= this.maxCharacters) {
        this.comments = this.comments
          .split("")
          .splice(0, this.maxCharacters)
          .join("");
      }

      const words = this.comments.split(/\s+/);

      const wordsCount = words.length;

      if (wordsCount > this.maxWords) {
        this.comments = words.splice(0, this.maxWords).join(" ");
      }
    }
  },
  methods: {
    openRows(idRows, idCol) {
      const rows = document.querySelector(`#${idRows}`);
      const col = document.querySelector(`#${idCol}`);
      rows.classList.toggle("active");
      col.classList.toggle("active");
    },
    getValues(row) {
      const array = [];
      this.matrix.forEach(col => {
        col.forEach(value => {
          if (value.text[1] === row.value) array.push(value);
        });
      });
      return array;
    },
    isEmpty() {
      let empty = false;
      if (this.type === "UC") {
        let cont = 0;
        let filterTemp = [];
        this.matrix.forEach(col => {
          filterTemp = [];
          filterTemp = col.filter(row => row.value === true);
          if (!filterTemp.length) cont++;
        });
        if (cont !== 0) {
          empty = true;
        }
      } else if (this.type === "UR") {
        let filterTemp = [];
        this.matrix.forEach(col => {
          col.forEach(row => {
            if (row.value) filterTemp.push(row);
          });
        });
        if (this.rows.length !== filterTemp.length) empty = true;
      } else {
        let filterTemp = [];
        this.matrix.forEach(col => {
          col.forEach(row => {
            if (row.value) filterTemp.push(row);
          });
        });
        if (!filterTemp.length) empty = true;
      }
      return empty;
    },
    answerPreview() {
      if (!this.loading) {
        if (!this.isEmpty()) {
          if (this.obligatoryComment && !this.comments.length) {
            this.$toast.error(
              this.translation.answer_task.obligatory_comment_tost
            );

            return;
          }

          if (
            this.comments.length &&
            this.hasCharactersLimitation &&
            this.comments.length < this.minCharacters
          ) {
            this.$toast.error(
              `${this.translation.answer_task.min_characters} ${this.minCharacters} ${this.translation.answer_task.characters}`
            );

            return;
          }

          if (this.comments.length && this.hasWordsLimitation) {
            const wordsCount = this.comments.split(/\s+/).length;

            if (wordsCount < this.minWords) {
              this.$toast.error(
                `${this.translation.answer_task.min_word} ${this.minWords} ${this.translation.answer_task.words}`
              );

              return;
            }
          }

          this.loading = true;

          const answer = {
            comment: this.comments
          };

          answer.options = [];

          this.matrix.forEach((col, cIndex) => {
            col.forEach((row, rowIndex) => {
              answer.options.push({
                row_index: rowIndex,
                row_value: row.text[1],
                column_index: cIndex,
                column_value: row.text[0],
                marked: row.value ? 1 : 0
              });
            });
          });

          const body = {
            task_id: this.task.id,
            has_attachment: 0,
            has_video: 0,
            has_image: 0,
            raw_answer: JSON.stringify(answer),
            answer
          };

          api
            .post("/answer/send/preview", body, {
              headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${this.$store.getters.token}`
              }
            })
            .then(r => {
              if (r.status === 200) {
                this.$toast.success(
                  this.translation.answer_task.success.reply_registered
                );
                this.getTasks("save");
                this.loading = false;
              }
            })
            .catch(() => {
              this.$toast.error(
                this.translation.answer_task.error.reply_not_registered
              );
              this.loading = false;
            });
        } else {
          if (this.type === "UC") {
            this.$toast.error(this.translation.answer_task.error.empty_column);
          } else if (this.type === "UR") {
            this.$toast.error(this.translation.answer_task.error.empty_row);
          } else {
            this.$toast.error(this.translation.answer_task.error.fill_one);
          }
        }
      }
    },
    answer() {
      if (!this.loading) {
        if (!this.isEmpty()) {
          if (this.obligatoryComment && !this.comments.length) {
            this.$toast.error(
              this.translation.answer_task.obligatory_comment_tost
            );

            return;
          }

          if (
            this.comments.length &&
            this.hasCharactersLimitation &&
            this.comments.length < this.minCharacters
          ) {
            this.$toast.error(
              `${this.translation.answer_task.min_characters} ${this.minCharacters} ${this.translation.answer_task.characters}`
            );

            return;
          }

          if (this.comments.length && this.hasWordsLimitation) {
            const wordsCount = this.comments.split(/\s+/).length;

            if (wordsCount < this.minWords) {
              this.$toast.error(
                `${this.translation.answer_task.min_word} ${this.minWords} ${this.translation.answer_task.words}`
              );

              return;
            }
          }

          this.loading = true;

          const answer = {
            comment: this.comments
          };

          answer.options = [];

          this.matrix.forEach((col, cIndex) => {
            col.forEach((row, rowIndex) => {
              answer.options.push({
                row_index: rowIndex,
                row_value: row.text[1],
                column_index: cIndex,
                column_value: row.text[0],
                marked: row.value ? 1 : 0
              });
            });
          });

          const body = {
            task_id: this.task.id,
            participant_id: this.$store.getters.info.user.sub,
            has_attachment: 0,
            has_video: 0,
            has_image: 0,
            raw_answer: JSON.stringify(answer),
            answer
          };

          api
            .post("/answer/send", body, {
              headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${this.$store.getters.token}`
              }
            })
            .then(r => {
              if (r.status === 200) {
                this.$toast.success(
                  this.translation.answer_task.success.reply_registered
                );
                this.getTasks("save");
                this.loading = false;
              }
            })
            .catch(() => {
              this.$toast.error(
                this.translation.answer_task.error.reply_not_registered
              );
              this.loading = false;
            });
        } else {
          if (this.type === "UC") {
            this.$toast.error(this.translation.answer_task.error.empty_column);
          } else if (this.type === "UR") {
            this.$toast.error(this.translation.answer_task.error.empty_row);
          } else {
            this.$toast.error(this.translation.answer_task.error.fill_one);
          }
        }
      }
    },
    hasAnswer(arrayMatrix) {
      const array = arrayMatrix.filter(value => value.value);
      return array.length;
    },
    toggleValue(value) {
      if (!this.is_answered) {
        const cIndex = this.matrix.indexOf(
          this.matrix.filter(col => col[0].text[0] === value.text[0])[0]
        );
        const rIndex = this.matrix[cIndex].indexOf(value);

        if (this.type === "UR") {
          let row = [];
          this.matrix.forEach(col => {
            row.push(col[rIndex]);
          });
          if (this.matrix[cIndex][rIndex].value) {
            this.matrix[cIndex][rIndex].value = false;
          } else {
            for (let i = 0; i < row.length; i++) {
              this.matrix[i][rIndex].value = false;
            }
            this.matrix[cIndex][rIndex].value = true;
          }
        } else if (this.type === "UC") {
          if (this.matrix[cIndex][rIndex].value) {
            this.matrix[cIndex][rIndex].value = false;
          } else {
            for (let i = 0; i < this.matrix[cIndex].length; i++) {
              this.matrix[cIndex][i].value = false;
            }
            this.matrix[cIndex][rIndex].value = true;
          }
        } else {
          if (this.matrix[cIndex][rIndex].value) {
            this.matrix[cIndex][rIndex].value = false;
          } else {
            this.matrix[cIndex][rIndex].value = true;
          }
        }
      }
    },
    toggleCol(rowsElement, colElement) {
      const rows = document.querySelectorAll(rowsElement);
      const col = document.querySelector(colElement);
      col.classList.toggle("active");
      rows.forEach(row => {
        row.classList.toggle("active");
      });
    },
    isDisabled(value) {
      let resp = true;

      const cIndex = this.matrix.indexOf(
        this.matrix.filter(col => col[0].text[0] === value.text[0])[0]
      );

      const rIndex = this.matrix[cIndex].indexOf(value);

      if (this.type === "UR") {
        let row = [];
        this.matrix.forEach(col => {
          row.push(col[rIndex]);
        });
        if (!this.hasAnswer(row)) {
          resp = false;
        }
      } else if (this.type === "UC") {
        if (!this.hasAnswer(this.matrix[cIndex])) {
          resp = false;
        }
      } else if (this.type === "MC") {
        resp = false;
      }

      return resp;
    },
    init() {
      this.matrix = [];

      if (
        this.task.is_answered ||
        (this.task.answer && this.$route.query._preview)
      ) {
        this.comments = this.task.answer.answer_object.comment;

        for (let i = 0; i < this.cols.length; i++) {
          this.matrix.push([]);
          for (let j = 0; j < this.rows.length; j++) {
            this.matrix[i].push({
              value: false,
              text: [`${this.cols[i].value}`, `${this.rows[j].value}`]
            });
          }
        }

        this.task.answer.answer_object.options.forEach(option => {
          if (option.marked)
            this.matrix[option.column_index][option.row_index].value = true;
        });
      } else {
        this.comments = "";
        for (let i = 0; i < this.cols.length; i++) {
          this.matrix.push([]);
          for (let j = 0; j < this.rows.length; j++) {
            this.matrix[i].push({
              value: false,
              text: [`${this.cols[i].value}`, `${this.rows[j].value}`]
            });
          }
        }
      }
    }
  },
  created() {
    this.init();
  },
  mounted() {
    if (this.$store.getters.info.user.type === userTypesText.participant) {
      const taskWrapper = document.querySelector(".task-wrapper");

      taskWrapper.addEventListener("contextmenu", e => {
        e.preventDefault();
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.task-text {
  display: flex;
  flex-direction: column;
  color: #6b6b6b;
  overflow-wrap: break-word;
}

.select-box {
  display: inline-block;
  padding: 20px;
  border-radius: 20px;
  color: #6b6b6b;
  font-weight: bold;
  font-size: 1.2rem;
}

.comments {
  label {
    color: #6b6b6b;
  }
  textarea {
    width: 100%;
    display: block;
    border: 1px solid #b3b3b3;
    border-radius: 10px;
  }
}

.custom-control-label {
  color: red;
}

.table {
  td {
    color: #6b6b6b;
  }
  .row-table {
    font-weight: bold;
    padding: 10px;
    width: 200px;
    text-align: center;
    font-size: 0.8rem;
  }
  .col-table {
    font-weight: bold;
    padding: 10px;
    text-align: center;
    font-size: 0.8rem;
  }
  .value-table {
    border-radius: 20px;
    position: relative;

    &.active {
      p {
        color: #6b6b6b;
        svg {
          opacity: 1;
        }
      }
    }
    p {
      display: flex;
      align-items: center;
      justify-content: center;
      text-align: center;
      background: #fff;
      border-radius: 8px;
      cursor: pointer;
      font-size: 2rem;
      transition: all 0.5s ease-in-out;
      margin-bottom: 0;
      color: #fff;
      svg {
        animation: show 0.3s linear;
        opacity: 0;
      }
      &.disabled {
        background: rgba(255, 255, 255, 0.5);
      }
    }
  }
}

.table-responsive {
  border-radius: 20px;
  &::-webkit-scrollbar {
    height: 8px;
    overflow: hidden;
  }
  &::-webkit-scrollbar-track {
    background: rgba(0, 0, 0, 0);
    border-radius: 10px;
  }
  &::-webkit-scrollbar-thumb {
    background: #ccc;
    border-radius: 10px;
    height: 10px;
  }
  &::-webkit-scrollbar-thumb:hover {
    background: #aaa;
  }
}

.col-mobile-btn {
  display: flex;
  align-items: center;
  border-radius: 15px;
  border: none;
  margin-bottom: 10px;
  color: #6b6b6b;
  font-weight: bold;
  font-size: 0.8rem;
  &.active {
    svg {
      transition: all 0.3s ease;
      transform: rotate(-95deg);
    }
  }
}

.row-mobile-wrapper {
  display: none;
  animation: show 0.3s linear;
  .custom-checkbox {
    margin-bottom: 10px;
    font-size: 0.85rem;
  }
  &.active {
    display: block;
  }
}
</style>
