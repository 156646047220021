<template>
  <div class="col-md-6">
    <imageCropper
      :img="img"
      :show="showCropper"
      :loader="loaderInsertImg"
      @updateImg="img => insertImg(img)"
      @trash="close"
    />
    <div class="create-transmission">
      <a
        class="close hover"
        @click="
          $store.commit('UPDATE_SHOW_TRANSMISSION_LIST', false),
            $store.commit('UPDATE_SHOW_CREATE_TRANSMISSION', false)
        "
        ><b-icon class="mr-1" icon="x-circle"></b-icon>
      </a>
      <div class="input mb-3">
        <b-form-input
          :placeholder="translation.chat.list_name"
          size="sm"
          v-model="transmissionName"
        ></b-form-input>
      </div>
      <div class="users-wrapper">
        <div class="top-create-transmission">
          <b-form-checkbox id="allUsers" v-model="allUsers">
            {{ translation.replies.all }}
          </b-form-checkbox>
          <div class="input">
            <b-form-input
              size="sm"
              v-model="search"
              :placeholder="translation.global.search"
            ></b-form-input>
            <b-icon class="mx-2" icon="search" aria-hidden="true"></b-icon>
          </div>
        </div>

        <div class="users" v-if="!loaderUsers && usersSearch.length">
          <div class="users-scroll">
            <button
              v-for="user in usersSearch"
              :key="user.id"
              @click="toggleUser(user)"
              :class="{ active: hasUser(user) }"
            >
              <div class="profile-img-wrapper">
                <div
                  class="profile-img"
                  :style="{
                    color: $store.getters.theme[3]
                  }"
                >
                  <img
                    v-if="user.picture && user.picture.length"
                    :src="user.picture"
                    alt="Profile Picture"
                  />
                  <b-icon
                    v-else
                    class="icon-profile"
                    icon="person-fill"
                    aria-hidden="true"
                  ></b-icon>
                </div>
              </div>
              <div>
                <p>
                  {{ user.name }}
                </p>
                <p>ID {{ user.id }}</p>
              </div>
            </button>
          </div>
        </div>
        <div v-else-if="loaderUsers" class="py-5 d-flex justify-content-center">
          <loader2 :color="$store.getters.theme[2]" />
        </div>
        <div v-else class="text py-5 d-flex justify-content-center">
          {{ translation.chat.no_users }}
        </div>
      </div>
      <form class="send-message mt-3">
        <button
          v-if="img"
          @click="close"
          :style="{ background: $store.getters.theme[2] }"
          class="btn delete-img"
        >
          <b-icon icon="x"></b-icon>
        </button>
        <label for="image-chat">
          <div v-if="!img" class="add-img">
            <b-icon icon="card-image" aria-hidden="true"></b-icon>
          </div>
          <input
            ref="inputImg"
            @change="uploadImg('image-chat')"
            id="image-chat"
            class="d-none"
            type="file"
            accept="image/*"
          />
        </label>
        <div :style="{ padding: img.length ? '10px' : 0 }" class="textarea">
          <b-form-textarea
            @keypress="submit"
            class="px-3"
            style="resize:none; overflow:hidden"
            size="sm"
            :placeholder="translation.chat.enter_a_message"
            v-model="message"
          ></b-form-textarea>
          <div class="img" v-if="img.length">
            <img :src="img" />
          </div>
        </div>
        <button
          ref="sendButton"
          @click.prevent="sendMessage"
          class="btn send"
          :style="{
            'background-color': $store.getters.theme[2]
          }"
        >
          <img src="@/img/send-message.svg" />
        </button>
      </form>
    </div>
  </div>
</template>

<script>
import { v4 as uuidv4 } from "uuid";
import { api } from "@/services.js";
import imageCropper from "@/components/general/imageCropper.vue";
import { regexpImageName } from "@/constants";
import lambda from "@/lambda";
import loader2 from "@/components/general/loader2.vue";
import { userTypes } from "@/constants";
import { mapState } from "vuex";

export default {
  props: {
    userSource: {
      required: true
    }
  },
  components: {
    loader2,
    imageCropper
  },
  data() {
    return {
      users: [],
      selectedUsers: [],
      transmissionName: "",
      allUsers: false,
      search: "",
      loaderUsers: false,
      message: "",
      img: "",
      imgName: "",
      showCropper: false,
      loaderInsertImg: false,
      regexpImageName,
      userTypes
    };
  },
  computed: {
    ...mapState(["chatSelectedSegments"]),
    usersSearch() {
      if (this.search.length) {
        return this.users.filter(item => {
          return this.search
            .toLowerCase()
            .split(" ")
            .every(v => item.name.toLowerCase().includes(v));
        });
      } else {
        return this.users;
      }
    }
  },
  watch: {
    chatSelectedSegments() {
      this.getUsers();
    },
    selectedUsers() {
      const isAllChecked = this.selectedUsers.length === this.users.length;
      if (!isAllChecked) {
        this.allUsers = false;
      }
    },
    allUsers() {
      const isAllChecked = this.selectedUsers.length === this.users.length;
      if (this.allUsers) {
        this.selectedUsers = this.users.map(user => ({
          userId: user.id,
          userType: user.type
        }));
      } else if (isAllChecked) {
        this.selectedUsers = [];
      }
    }
  },
  methods: {
    hasUser(user) {
      return this.selectedUsers.filter(
        selectedUser => selectedUser.userId === user.id
      ).length;
    },
    toggleUser(user) {
      const userTarget = {
        userId: user.id,
        userType: user.type
      };

      if (
        this.selectedUsers.filter(
          selectedUser => selectedUser.userId === userTarget.userId
        ).length
      )
        this.selectedUsers.splice(this.selectedUsers.indexOf(userTarget), 1);
      else this.selectedUsers.push(userTarget);
    },
    getUsers() {
      let segmentsQuery = "";

      this.chatSelectedSegments.forEach(segment => {
        segmentsQuery += `&segments[]=${segment}`;
      });

      this.loaderUsers = true;
      api
        .get(
          `community/${this.$store.getters.community.id}/users?type[]=${this.userTypes.participant}${segmentsQuery}`,
          {
            headers: {
              Authorization: `Bearer ${this.$store.getters.token}`
            }
          }
        )
        .then(r => {
          if (r.status === 200)
            this.users = r.data.filter(
              user => user.id !== this.$store.getters.info.user.sub
            );
          this.loaderUsers = false;
        })
        .catch(() => {
          this.loaderUsers = false;
        });
    },
    submit(e) {
      if (e.which === 13 && !e.shiftKey) {
        e.preventDefault();

        this.$refs.sendButton.click();
      }
    },
    sendMessage() {
      if (
        (this.message.length || this.img.length) &&
        this.transmissionName.length &&
        this.selectedUsers.length
      ) {
        const message = {
          text: this.message,
          image: this.img,
          sourceID: this.userSource.id
        };

        this.$emit("sendMessage", {
          message: message,
          userTargetList: this.selectedUsers,
          name: this.transmissionName
        });

        if (this.$refs.chatScroll)
          this.$refs.chatScroll.scrollTop =
            this.$refs.chatScroll.scrollHeight + 10;

        this.message = "";
        this.img = "";
        this.selectedUsers = [];
        this.transmissionName = "";

        this.$store.commit("UPDATE_SHOW_CREATE_TRANSMISSION", false);
      } else {
        this.$toast.error(this.translation.errors_global.fill_fields);
      }
    },
    close() {
      this.showCropper = false;
      this.$refs.inputImg.value = "";
      this.img = "";
    },
    insertImg(src) {
      this.loaderInsertImg = true;

      let arr = src.split(",");
      let mime = arr[0].match(/:(.*?);/)[1];
      let bstr = atob(arr[1]);
      let n = bstr.length;
      let u8arr = new Uint8Array(n);

      while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
      }

      const imgName = `${uuidv4()}-${this.imgName.replace(
        this.regexpImageName,
        ""
      )}`;

      const imgFile = new File([u8arr], imgName, {
        type: mime
      });

      const folder = `communities/${this.$store.getters.community.id}/Chat/${this.activeConversation.id}/Assets/${this.activeUser.type}/${this.activeUser.id}`;

      lambda
        .getSignedURL(imgFile, folder, imgName)
        .then(url => {
          api
            .put(url, imgFile, {
              headers: {
                "Content-Type": imgFile.type
              }
            })
            .then(r => {
              this.img = r.config.url.split("?")[0];
              this.$refs.inputImg.value = "";
              this.showCropper = false;
              this.loaderInsertImg = false;
            })
            .catch(() => {
              this.$toast.error(
                this.translation.errors_global.something_went_wrong
              );
              this.loaderInsertImg = false;
            });
        })
        .catch(() => {
          this.$toast.error(
            this.translation.errors_global.something_went_wrong
          );
          this.loaderInsertImg = false;
        });
    },
    async uploadImg(element) {
      const file = document.querySelector(`#${element}`).files[0];
      if (file) {
        const toBase64 = file =>
          new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => resolve(reader.result);
            reader.onerror = error => reject(error);
          });
        this.img = await toBase64(file);
        this.imgName = file.name;
        this.showCropper = true;
      }
    }
  },
  created() {
    this.getUsers();
  }
};
</script>

<style lang="scss" scoped>
.create-transmission {
  display: flex;
  flex-direction: column;
  height: calc(100vh - 150px);
  min-height: 550px;
  position: relative;
}

.input {
  display: flex;
  align-items: center;
  position: relative;
  margin-bottom: 20px;
  input {
    border-radius: 20px;
    color: #6b6b6b;
  }
  svg {
    position: absolute;
    right: 0;
    color: #6b6b6b;
  }
}

.top-create-transmission {
  margin-bottom: 5px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #6b6b6b;

  .input {
    margin-bottom: 0;
    margin-left: 20px;
  }
}

.users-wrapper {
  flex: 1;
  max-height: calc(100% - 93px);
  position: relative;
  .users {
    width: 100%;
    height: calc(100% - 35px);
    position: relative;
  }
}

.users-scroll {
  height: 100%;
  overflow-y: scroll;
  padding-right: 10px;
  &::-webkit-scrollbar {
    width: 8px;
  }
  &::-webkit-scrollbar-track {
    background: rgba(0, 0, 0, 0.05);
    border-radius: 10px;
  }
  &::-webkit-scrollbar-thumb {
    background: #e6e6e6;
    border-radius: 10px;
    height: 40px;
  }
  &::-webkit-scrollbar-thumb:hover {
    background: rgba(0, 0, 0, 0.1);
  }
  button {
    display: flex;
    align-items: center;
    width: 100%;
    background: #ffffff;
    margin: 5px 0;
    border: none;
    padding: 10px 15px;
    text-align: left;
    &:focus {
      outline: none;
    }
    .profile-img-wrapper {
      position: relative;
      .notification {
        display: flex;
        align-items: center;
        justify-content: center;
        color: #ffffff;
        font-weight: bold;
        position: absolute;
        background: #df2938;
        top: -4px;
        right: 8px;
        width: 18px;
        height: 18px;
        border-radius: 50%;
        font-size: 0.8rem;
      }
      .profile-img {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 60px;
        height: 60px;
        border-radius: 50%;
        overflow: hidden;
        flex-shrink: 0;
        margin-right: 10px;
        background: #eee;
        img {
          object-fit: cover;
          height: 60px;
          width: 60px;
        }
      }
    }
    p {
      margin: 0;
      color: #6b6b6b;
      font-size: 0.9rem;
      &:nth-of-type(1) {
        font-weight: bold;
        font-size: 1rem;
      }
    }
    &.active {
      background: rgba(150, 150, 150, 0.1);
    }
  }
}

.send-message {
  display: flex;
  align-items: flex-end;
  position: relative;
  .delete-img {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    color: #ffffff;
    margin-right: 10px;
  }
  label {
    margin-bottom: 0;
    cursor: pointer;
    position: relative;
    .add-img {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 30px;
      height: 30px;
      color: #6b6b6b;
      background: #fff;
      border-radius: 50%;
      margin-right: 10px;
    }
  }
  .textarea {
    width: 100%;
    background: #fff;
    border-radius: 40px;
    margin-right: 10px;
    textarea {
      height: 30px;
      background: #fff;
      border: none;
      border-radius: 40px;
      &:focus {
        box-shadow: none;
      }
    }
    .img {
      display: flex;
      flex-direction: column;
      img {
        object-fit: contain;
        width: 200px;
        border-radius: 20px;
      }
    }
  }
  .send {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    flex-shrink: 0;
    padding: 0;
    position: relative;
    img {
      position: absolute;
      object-fit: contain;
      width: 15px;
      height: 15px;
      right: 6px;
      top: 7px;
    }
  }
}

.close {
  color: #df2938;
  cursor: pointer;
  position: absolute;
  top: -25px;
  right: 0px;
  font-size: 1.3rem;
}
</style>
