<template>
  <div class="pb-0 px-md-5 px-3">
    <b-modal
      id="modal-has-logic-ordering"
      @ok.prevent="applyOrdering(true)"
      :cancel-title="translation.global.cancel"
    >
      <template #modal-title>
        <b-icon class="mr-1" icon="exclamation-triangle"></b-icon>
      </template>
      <div>
        {{ translation.create_task.reorder_modal }}
      </div>
    </b-modal>
    <div
      class="d-flex flex-wrap justify-content-between align-items-center mb-4"
    >
      <p class="title-task mb-0" :style="{ color: $store.getters.theme[3] }">
        {{ translation.task.tasks }}
      </p>
      <div class="d-flex flex-wrap align-items-center mt-2">
        <button
          class="btn btn-ordering hover mr-3 my-1"
          @click="
            openTasks({
              _activity: activity.id,
              _pageParticipant: 1,
              _pageReplies: 1,
              _preview: true
            })
          "
        >
          Preview
        </button>
        <button
          class="btn btn-ordering hover my-1"
          @click="(ordering = !ordering), (selectTask = null)"
        >
          {{ translation.create_task.reorder_tasks }}
        </button>
      </div>
    </div>
    <div
      v-if="!loaderTasks"
      class="p-3 drag-area"
      :style="{
        border: ordering ? `1px dashed  #6b6b6b` : '1px solid white'
      }"
    >
      <div class="mb-4 d-flex justify-content-end">
        <button
          :style="{ background: $store.getters.theme[2] }"
          :class="{ active: ordering }"
          class="btn btn-apply hover"
          @click="applyOrdering()"
        >
          <loader2 v-if="loaderOrdering" color="#ffffff" />
          <p v-if="!loaderOrdering" class="m-0">
            {{ translation.global.apply }}
          </p>
        </button>
        <button
          @click="closeOrdering"
          :class="{ active: ordering }"
          class="btn btn-close hover"
        >
          <b-icon icon="x"></b-icon>
        </button>
      </div>
      <draggable
        class="d-flex flex-wrap"
        handle=".handle"
        :list="tasksTemp"
        group="task"
      >
        <div
          :class="{ handle: ordering }"
          class="col-md-3 col-lg-2"
          v-for="(task, index) in tasksTemp"
          :key="task.id"
        >
          <b-modal
            :id="`modal-has-logic-delete-${task.id}`"
            @ok.prevent="deleteTask(task, true)"
            :cancel-title="translation.global.cancel"
          >
            <template #modal-title>
              <b-icon class="mr-1" icon="exclamation-triangle"></b-icon>
            </template>
            <div>
              {{ translation.create_task.delete_modal }}
            </div>
          </b-modal>
          <b-modal
            :id="`modal-delete-task-${task.id}`"
            @ok.prevent="deleteTask(task, true)"
            :cancel-title="translation.global.cancel"
          >
            <template #modal-title>
              <b-icon class="mr-1" icon="exclamation-triangle"></b-icon>
            </template>
            <div>
              {{ translation.create_task.delete_task_modal }}
            </div>
          </b-modal>
          <div
            :class="{ active: selectTask && selectTask.id === task.id }"
            :style="{
              background:
                selectTask && selectTask.id === task.id
                  ? $store.getters.theme[0]
                  : '#ffffff'
            }"
            class="task px-2 py-3 mb-3"
          >
            <img :src="getImgUrl(task.type_id)" />
            <div class="menu-wrapper" :id="`menu-task-${task.id}`">
              <button
                @click="toggleMenu(task)"
                class="hover"
                :style="{
                  color: $store.getters.theme[2]
                }"
              >
                {{
                  task.code_string ? task.code_string : `T${+task.index + 1}`
                }}
                <b-icon icon="caret-down-fill" aria-hidden="true"></b-icon>
              </button>
              <div
                :class="{ active: task.showMenu === task.id }"
                class="menu card"
              >
                <button @click="getTask(task.id, index)">
                  {{ translation.global.edit }}
                </button>
                <button @click="copyTask(task)">
                  {{ translation.global.copy }}
                </button>
                <button v-if="!task.total_answers" @click="deleteTask(task)">
                  {{ translation.global.delete }}
                </button>
              </div>
            </div>
            <p>ID {{ task.id }}</p>
            <p>
              {{ getText(task.type_id) }}
            </p>
          </div>
        </div>
        <div class="col-md-3 col-lg-2">
          <div
            :style="{
              background:
                selectTask === addTaskText ? $store.getters.theme[0] : '#ffffff'
            }"
            class="task px-2 py-3 mb-3 add-btn hover"
            @click="addTask"
          >
            <b-icon icon="plus"></b-icon>
          </div>
        </div>
      </draggable>
    </div>
    <div v-else class="py-3 pl-3">
      <loader />
    </div>
    <div ref="taskEditor">
      <task-editor
        :tasks="tasks"
        :getTasks="getTasks"
        :task="selectTask"
        :activityId="activityId"
        :activity="activity"
        :loaderTask="loaderTask"
        :addTask="addTask"
        :getActivity="getActivity"
      />
    </div>
  </div>
</template>

<script>
import { api } from "@/services.js";
import loader from "@/components/general/loader.vue";
import loader2 from "@/components/general/loader2.vue";
import taskEditor from "@/components/createtask/taskEditor.vue";
import draggable from "vuedraggable";
import { taskEnum, addTaskText } from "@/constants";
import { outsideClick } from "@/helpers.js";

export default {
  props: {
    activityId: {
      required: true
    },
    activity: {
      required: true
    },
    getActivity: {
      required: true
    }
  },
  components: {
    taskEditor,
    loader,
    draggable,
    loader2
  },
  data() {
    return {
      tasks: [],
      loaderTasks: false,
      loaderCopyTask: false,
      selectTask: null,
      tasksTemp: null,
      loaderTask: false,
      ordering: false,
      loaderOrdering: false,
      taskEnum,
      addTaskText,
      outsideClick
    };
  },
  methods: {
    toggleMenu(task) {
      if (!task.showMenu) task.showMenu = task.id;
      else if (task.showMenu === "start") {
        const element = document.querySelector(`#menu-task-${task.id}`);
        this.outsideClick(element, ["click"], () => {
          if (task.showMenu === task.id) task.showMenu = false;
        });
        task.showMenu = task.id;
      } else if (task.id === task.showMenu) task.showMenu = false;
    },
    closeOrdering() {
      this.tasksTemp = this.tasks.map(task => ({ ...task }));
      this.ordering = false;
    },
    applyOrdering(apply) {
      if (!apply) {
        this.$bvModal.show("modal-has-logic-ordering");
      } else {
        if (!this.loaderOrdering) {
          this.loaderOrdering = true;
          api
            .put(
              `task/update/index`,
              this.tasksTemp.map((task, index) => ({
                id: task.id,
                index: index
              })),
              {
                headers: {
                  Authorization: `Bearer ${this.$store.getters.token}`,
                  "Content-Type": "application/json"
                }
              }
            )
            .then(r => {
              if (r.status === 200) {
                this.ordering = false;
                this.tasks = this.tasksTemp.map(task => ({
                  ...task
                }));
                this.$toast.success(
                  this.translation.success_global.changes_saved
                );
                this.getActivity();
              }
              this.selectTask = null;
              this.loaderOrdering = false;
            })
            .catch(() => {
              this.loaderOrdering = false;
              this.$toast.error(
                this.translation.errors_global.something_went_wrong
              );
            });
        }
      }
    },
    getTasks() {
      this.loaderTasks = true;
      api
        .get(`activity/${this.activityId}/all-tasks`, {
          headers: {
            Authorization: `Bearer ${this.$store.getters.token}`
          }
        })
        .then(resp => {
          if (resp.status === 200) {
            this.tasks = resp.data.tasks.map(task => ({
              ...task,
              showMenu: "start"
            }));
            this.tasksTemp = this.tasks.map(task => ({
              ...task,
              showMenu: "start"
            }));
          } else {
            this.tasks = [];
            this.tasksTemp = [];
          }

          this.loaderTasks = false;
        })
        .catch(() => {
          this.tasks = [];
          this.tasksTemp = [];
          this.loaderTasks = false;
        });
    },
    getTask(id, index) {
      this.loaderTask = true;
      api
        .get(`task/${id}`, {
          headers: {
            Authorization: `Bearer ${this.$store.getters.token}`
          }
        })
        .then(resp => {
          if (resp.status === 200) {
            this.selectTask = resp.data.task;
            this.selectTask.index = index;
            this.loaderTask = false;
            this.$refs.taskEditor.style.height = "650px";
            this.$refs.taskEditor.scrollIntoView({
              behavior: "smooth",
              block: "end",
              inline: "end"
            });
            this.$refs.taskEditor.style.height = "unset";
          }
        })
        .catch(() => {
          this.loaderTask = false;
        });
    },
    copyTask({ id }) {
      if (!this.loaderCopyTask) {
        this.loaderCopyTask = true;
        api
          .get(`task/${id}/copy`, {
            headers: {
              Authorization: `Bearer ${this.$store.getters.token}`
            }
          })
          .then(resp => {
            if (resp.status === 201) {
              this.getTasks();
              this.$toast.success(
                this.translation.create_task.success.task_copied
              );
              this.selectTask = null;
              this.loaderCopyTask = false;
            }
          })
          .catch(() => {
            this.loaderCopyTask = false;
            this.$toast.error(
              this.translation.errors_global.something_went_wrong
            );
          });
      }
    },
    deleteTask({ id, total_answers }, deleteTask) {
      if (+this.activity.has_logic && !deleteTask) {
        this.$bvModal.show(`modal-has-logic-delete-${id}`);
      } else if (!total_answers && !deleteTask) {
        this.$bvModal.show(`modal-delete-task-${id}`);
      } else if (!total_answers) {
        api
          .delete(`task/${id}`, {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${this.$store.getters.token}`
            }
          })
          .then(r => {
            if (r.status === 200) {
              this.getTasks();
              this.selectTask = null;

              this.$toast.success(
                this.translation.create_task.success.task_deleted
              );
            }
          })
          .catch(() => {
            this.$toast.error(
              this.translation.errors_global.something_went_wrong
            );
          });
      }
    },
    addTask() {
      if (this.selectTask !== this.addTaskText)
        this.selectTask = this.addTaskText;
      else this.selectTask = "";
    },
    getImgUrl(id) {
      const type = Object.values(this.taskEnum).filter(
        typeObj => typeObj.id === +id
      )[0];

      const images = require.context("../../img/", false, /\.svg$/);
      return images("./" + type.icon + ".svg");
    },
    getText(id) {
      const type = Object.values(this.taskEnum).filter(
        typeObj => typeObj.id === +id
      )[0];

      return type.text[this.langAbbreviation];
    },
    openTasks(query) {
      let routeData = this.$router.resolve({
        name: "tasks",
        query
      });
      window.open(routeData.href, "_blank");
    },
    init() {
      this.getTasks();
    }
  },
  created() {
    this.init();
  }
};
</script>

<style lang="scss" scoped>
.title-task {
  font-weight: bold;
  font-size: 1.325rem;
}

.task {
  background: #ffffff;
  box-shadow: 0px 0px 37px -16px #c4c4c4;
  border-radius: 20px;
  text-align: center;
  position: relative;
  height: 128px;
  transition: all 0.3s ease;
  img {
    object-fit: contain;
    height: 30px;
    width: 50px;
  }

  p {
    color: #999999;
    font-size: 0.7rem;
    margin-bottom: 0;
    white-space: nowrap;
    a {
      text-decoration: underline;
      color: #6b6b6b;
    }
  }
  &.active {
    transform: scale(1.05);
    z-index: 1000;
  }
}

.menu-wrapper {
  position: relative;
  > button {
    font-weight: bold;
    margin-bottom: 0;
    margin-top: 10px;
    position: relative;
    border: none;
    background: none;
    font-size: 0.9rem;
    &:focus {
      outline: none;
    }
  }
  .menu {
    position: absolute;
    right: 8px;
    top: 40px;
    background: #ffffff;
    width: 100px;
    display: none;
    animation: grow 400ms ease-in-out forwards;
    transform-origin: top center;
    button {
      border: none;
      color: #999999;
      text-align: left;
      font-size: 0.9rem;
      background: none;
      display: block;
      width: 100%;
      &:focus {
        outline: none;
      }
      &:hover {
        background: rgba(0, 0, 0, 0.1);
      }
    }
    &.active {
      display: block;
      z-index: 1000;
    }
  }
}

.add-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 5rem;
  color: #999999;
  height: 128px;
  cursor: pointer;
}

.btn-ordering {
  text-align: center;
  text-transform: uppercase;
  color: #6b6b6b;
  font-weight: bold;
  letter-spacing: 0.1rem;
  border-radius: 17px;
  border: none;
  padding: 5px 20px;
  align-self: flex-end;
  border: 1px solid #c8dada;
  background: none;
  white-space: nowrap;
  font-size: 0.9rem;
}

.btn-apply {
  display: none;
  text-align: center;
  text-transform: uppercase;
  color: #ffffff;
  font-weight: bold;
  letter-spacing: 0.1rem;
  border-radius: 17px;
  border: none;
  padding: 5px 20px;
  align-self: flex-end;
  animation: show 0.3s linear;
  margin-right: 2rem;
  margin-top: 1.5rem;
  &.active {
    display: flex;
    justify-content: center;
  }
}

.drag-area {
  border-radius: 10px;
  transition: all 0.3s ease;
  position: relative;
}

.handle {
  cursor: move;
}

.btn-close {
  position: absolute;
  top: -10px;
  right: -10px;
  display: none;
  animation: show 0.3s linear;
  font-size: 2rem;
  color: #6b6b6b;
  &.active {
    display: block;
  }
}
</style>
