<template>
  <div ref="cropperbg" v-if="show" class="cropper-bg">
    <div class="cropper-wrapper" id="cropper-wrapper">
      <cropper
        v-if="width && height"
        ref="cropper"
        :src="img"
        :auto-zoom="true"
        :stencil-size="{
          width: width,
          height: height
        }"
        image-restriction="stencil"
      />
      <cropper v-else ref="cropper" :src="img" :auto-zoom="true" />
      <div class="btns">
        <button
          @click="trash"
          class="hover"
          :style="{
            background: `${
              $store.getters.theme ? $store.getters.theme[2] : '#108FC3'
            }`
          }"
        >
          <b-icon icon="trash"></b-icon>
        </button>
        <button
          @click="apply"
          class="hover"
          :style="{
            background: `${
              $store.getters.theme ? $store.getters.theme[2] : '#108FC3'
            }`
          }"
        >
          <span v-if="!loader">
            <b-icon icon="check"></b-icon>
          </span>
          <span v-else class="d-flex justify-content-center">
            <loader2 color="#ffffff" />
          </span>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { Cropper } from "vue-advanced-cropper";
import loader2 from "@/components/general/loader2.vue";

export default {
  components: {
    Cropper,
    loader2
  },
  props: {
    img: {
      required: true
    },
    show: {
      required: true
    },
    width: {},
    height: {},
    loader: {
      default: false
    }
  },
  data() {
    return {};
  },
  methods: {
    trash() {
      this.$emit("trash", false);
    },
    apply() {
      const canvas = this.$refs.cropper.getResult().canvas;
      let data = canvas.toDataURL("image/png", 0.5);
      this.$emit("updateImg", data);
    },
    defaultSize() {
      return {
        width: this.width,
        height: this.height
      };
    }
  }
};
</script>

<style lang="scss" scoped>
.cropper-bg {
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  position: fixed;
  z-index: 1000;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.3);
}

.cropper-wrapper {
  height: 400px;
  max-width: 800px;
  position: relative;
  display: flex;
  justify-content: center;
}

@media (max-width: 991.98px) {
  .cropper-wrapper {
    height: 160px;
    max-width: 600px;
  }
}

@media (max-width: 640px) {
  .cropper-wrapper {
    height: 160px;
    max-width: 300px;
  }
}

.btns {
  display: flex;
  position: absolute;
  bottom: -90px;
  button {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 10px;
    width: 60px;
    height: 60px;
    border-radius: 50%;
    color: #fff;
    border: none;
    font-size: 1.5rem;
    &:focus {
      outline: none;
    }
    &:nth-of-type(2) {
      font-size: 2rem;
    }
  }
}
</style>
