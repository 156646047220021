var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"communities py-5"},[_c('div',{staticClass:"container"},[(!_vm.loader)?_c('div',{staticClass:"row justify-content-center"},[_c('b-modal',{attrs:{"no-enforce-focus":"","id":"copy-community","cancel-title":_vm.translation.global.cancel,"title":_vm.translation.communities.domain_text},on:{"ok":function($event){$event.preventDefault();return _vm.copyCommunity($event)}}},[_c('b-form-input',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",value:({ variant: 'secondary' }),expression:"{ variant: 'secondary' }",modifiers:{"hover":true}}],attrs:{"id":"domain","state":!_vm.isDomainValid && !!_vm.domain.length,"title":_vm.translation.communities.error.domain_validation},model:{value:(_vm.domain),callback:function ($$v) {_vm.domain=$$v},expression:"domain"}})],1),_vm._l((_vm.communities),function(community){return _c('div',{key:community.id,staticClass:"community col-md-4 col-lg-3 col-sm-6 mb-4"},[_c('div',{staticClass:"card-b p-0"},[_c('div',{staticClass:"card-header p-0 "},[_c('div',{staticClass:"overflow-hidden"},[(community.banner.length)?_c('img',{attrs:{"src":community.banner}}):_vm._e()]),_vm._m(0,true)]),_c('div',{staticClass:"px-3 py-3"},[_c('div',[_c('div',{staticClass:"d-flex align-items-center justify-content-between"},[_c('p',{staticClass:"h5 font-weight-bold community-name mb-0",style:({
                    color: _vm.$store.getters.theme[2]
                  })},[_vm._v(" "+_vm._s(community.name)+" ")]),_c('div',{staticClass:"d-flex"},[_c('button',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",value:({ variant: 'secondary' }),expression:"{ variant: 'secondary' }",modifiers:{"hover":true}}],staticClass:"btn btn-edit hover ml-2",style:({
                      'border-color': _vm.$store.getters.theme[2],
                      color: _vm.$store.getters.theme[2]
                    }),attrs:{"title":_vm.translation.global.copy},on:{"click":function($event){return _vm.openModalCopy(community)}}},[_c('b-icon',{attrs:{"icon":"stickies-fill"}})],1),_c('button',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",value:({ variant: 'secondary' }),expression:"{ variant: 'secondary' }",modifiers:{"hover":true}}],staticClass:"btn btn-edit hover ml-2",style:({
                      'border-color': _vm.$store.getters.theme[2],
                      color: _vm.$store.getters.theme[2]
                    }),attrs:{"title":_vm.translation.global.edit},on:{"click":function($event){return _vm.editCommunity(community)}}},[_c('b-icon',{attrs:{"icon":"pencil-fill"}})],1)])]),_c('p',{staticClass:"date mb-1 mt-3"},[_c('span',{staticClass:"font-weight-bold"},[_vm._v(_vm._s(_vm.translation.communities.start_time)+":")]),_vm._v(" "+_vm._s(_vm._f("formatDate")(community.start_at))+" ")]),_c('p',{staticClass:"date"},[_c('span',{staticClass:"font-weight-bold"},[_vm._v(_vm._s(_vm.translation.communities.end_time)+":")]),_vm._v(" "+_vm._s(_vm._f("formatDate")(community.end_at))+" ")]),_c('button',{staticClass:"btn btn-community hover px-3 py-1",style:({
                  color: _vm.$store.getters.theme[3],
                  'border-color': _vm.$store.getters.theme[3]
                }),on:{"click":function($event){return _vm.goToCommunity(community)}}},[_vm._v(" "+_vm._s(_vm.translation.communities.see_community)+" ")])])])])])}),_c('div',{staticClass:"community col-md-4 col-lg-3 col-sm-6 mb-4"},[_c('a',{staticClass:"card-b p-0 pointer create-community",style:({ color: _vm.$store.getters.theme[2] }),on:{"click":_vm.createCommunity}},[_c('b-icon',{attrs:{"icon":"plus"}}),_vm._v(" "+_vm._s(_vm.translation.communities.create_community)+" ")],1)])],2):(_vm.loader)?_c('div',{staticClass:"d-flex justify-content-center py-5"},[_c('loader')],1):_vm._e()])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"style"},[_c('img',{staticClass:"img-fluid",attrs:{"src":require("@/img/white-curve-small.png")}})])}]

export { render, staticRenderFns }