<template>
  <div class="sub-header py-3">
    <div class="container">
      <div class="row justify-content-center">
        <div class="d-flex justify-content-center">
          <div class="sub-column">
            <div v-if="isAdmin">
              <h2 :style="{ color: $store.getters.theme[2] }">
                {{ translation.privacy_and_permissions.privacy }}
              </h2>
              <router-link
                :to="{
                  name: 'privacyandpermissions',
                  query: { _option: tabOptions.privacy }
                }"
              >
                {{
                  translation.privacy_and_permissions.privacy_and_permissions
                }}
              </router-link>
            </div>
          </div>
          <div class="sub-column">
            <div v-if="isAdmin">
              <h2 :style="{ color: $store.getters.theme[2] }">
                {{ translation.header.community_text }}
              </h2>
              <router-link :to="{ name: 'segmentation', query: { _page: 1 } }">
                {{ translation.header.segmentation_text }}
              </router-link>
              <router-link
                :to="{ name: 'logs', query: { _page: 1 } }"
                v-if="
                  $store.getters.info.user.type !== userTypesText.participant
                "
              >
                {{ translation.header.logs }}
              </router-link>
            </div>
          </div>

          <div
            :class="{ en: $store.getters.language === 'en' }"
            class="sub-column"
          >
            <div v-if="isAdmin">
              <h2 :style="{ color: $store.getters.theme[2] }">
                {{ translation.header.moderation_text }}
              </h2>
              <router-link
                :to="{
                  name: 'operators',
                  query: { _option: tabOptions.addOperator }
                }"
              >
                {{ translation.operators.add_manage_operators }}
              </router-link>
            </div>
          </div>

          <div
            class="sub-column"
            v-if="
              $store.getters.info.user.type === userTypesText.admin ||
                ($store.getters.info.user.type === userTypesText.moderator &&
                  (!+permissions.moderators.hide_customization ||
                    !+permissions.moderators.hide_terms_and_policies))
            "
          >
            <div v-if="!isObserver">
              <h2 :style="{ color: $store.getters.theme[2] }">
                {{ translation.header.personalize_text }}
              </h2>
              <router-link
                v-if="
                  $store.getters.info.user.type === userTypesText.admin ||
                    ($store.getters.info.user.type ===
                      userTypesText.moderator &&
                      !+permissions.moderators.hide_customization)
                "
                :to="{
                  name: 'customization',
                  query: { _option: tabOptions.customizePages }
                }"
                :disabled="$store.getters.is_over"
              >
                {{ translation.header.customize_text }}
              </router-link>
              <router-link
                v-if="
                  $store.getters.info.user.type === userTypesText.admin ||
                    ($store.getters.info.user.type ===
                      userTypesText.moderator &&
                      !+permissions.moderators.hide_terms_and_policies)
                "
                :to="{ name: 'terms-and-policy' }"
                :disabled="$store.getters.is_over"
              >
                {{ translation.header.terms_and_policies }}
              </router-link>
            </div>
          </div>

          <div
            v-if="
              $store.getters.info.user.type === userTypesText.admin ||
                $store.getters.info.user.type === userTypesText.moderator ||
                ($store.getters.info.user.type === userTypesText.observer &&
                  !+permissions.observers.hide_activities_stats)
            "
            class="sub-column"
          >
            <div>
              <h2 :style="{ color: $store.getters.theme[2] }">
                {{ translation.header.participant_text }}
              </h2>
              <router-link
                v-if="isAdmin"
                :to="{
                  name: 'addparticipant',
                  query: { _option: tabOptions.importOption, _step: 1 }
                }"
              >
                {{ translation.header.add_participant_text }}
              </router-link>
              <router-link
                :to="{
                  name: 'manageparticipants',
                  query: { _option: tabOptions.registeredOption, _page: 1 }
                }"
              >
                {{ translation.header.manage_participant_text }}
              </router-link>
            </div>
          </div>

          <div class="sub-column">
            <h2 :style="{ color: $store.getters.theme[2] }">Chat</h2>
            <router-link
              :to="{ name: 'chat' }"
              :disabled="$store.getters.is_over"
            >
              Chat
            </router-link>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { tabOptions, userTypesText } from "@/constants";
import { mapState } from "vuex";

export default {
  data() {
    return {
      tabOptions,
      userTypesText
    };
  },

  computed: {
    ...mapState(["permissions"]),

    userType() {
      return this.$store.getters.info.user.type;
    },

    isAdmin() {
      return this.userType === this.userTypesText.admin;
    },

    isModerator() {
      return this.userType === this.userTypesText.moderator;
    },

    isObserver() {
      return this.userType === this.userTypesText.observer;
    }
  }
};
</script>

<style lang="scss" scoped>
.sub-header {
  background: #ffffff;
  animation: expand-subheader-admin 0.5s;
  height: 160px;
}

.sub-column {
  margin: 0 15px;

  h2 {
    font-size: 1rem;
    font-weight: bold;
    padding: 5px 10px;
    margin-bottom: 0;
  }
  a {
    font-size: 0.8rem;
    cursor: pointer;
    display: block;
    text-decoration: none;
    color: #999;
    font-weight: bold;
    padding: 5px 10px;
    transition: all 0.3s ease;
    white-space: nowrap;
    &:hover {
      color: #bbb;
    }
    &.router-link-exact-active {
      color: #6b6b6b;
      text-decoration: underline;
    }
  }

  &:nth-of-type(1) {
    max-width: 100px;
    a {
      white-space: unset;
    }
  }

  &:nth-of-type(3) {
    max-width: 140px;
    &.en {
      max-width: 115px;
    }
    a {
      white-space: unset;
    }
  }
}
</style>
