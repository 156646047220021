<template>
  <div class="container">
    <div class="row py-3">
      <div class="col d-flex justify-content-end align-items-center">
        <button
          class="btn-navigation"
          @click="prev"
          :class="{ disabled: currentPage === 1 }"
          :disabled="currentPage === 1"
        >
          ᐸ
        </button>
        <router-link
          :style="{
            color: currentPage === page ? $store.getters.theme[2] : '#6b6b6b'
          }"
          class="page"
          v-for="page in pages"
          :to="{ query: query(page) }"
          :key="page"
          >{{ page }}</router-link
        >
        <button
          class="btn-navigation"
          @click="next"
          :class="{ disabled: currentPage === pages[pages.length - 1] }"
          :disabled="currentPage === pages[pages.length - 1]"
        >
          ᐳ
        </button>
        <b-form-select
          class="ml-3"
          v-model="perPageSelected"
          :options="optionsPerPage"
          size="sm"
        ></b-form-select>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "PaginationQuery",
  props: {
    perPage: {
      required: true,
      type: Number,
      default: 1
    },
    total: {
      required: true,
      type: Number,
      default: 1
    },
    options: {
      required: true
    },
    queryName: {
      default: "_page"
    }
  },
  data() {
    return {
      perPageSelected: 0,
      optionsPerPage: []
    };
  },
  computed: {
    currentPage() {
      return +this.$route.query[this.queryName];
    },
    pages() {
      const current = Number(this.$route.query[this.queryName]);
      const range = 3;
      const offset = Math.ceil(range / 2);
      const total = this.pagestotal;
      const pagesArray = [];

      for (let i = 1; i <= total; i++) {
        pagesArray.push(i);
      }
      pagesArray.splice(0, current - offset);
      pagesArray.splice(range, total);
      return pagesArray;
    },
    pagestotal() {
      const total = this.total / this.perPage;
      return total !== Infinity ? Math.ceil(total) : 0;
    }
  },
  watch: {
    perPageSelected() {
      if (+this.$route.query[this.queryName] !== 1)
        this.$router.push({ query: this.query(1) });
      this.$emit("perPageUpdate", this.perPageSelected);
    }
  },
  methods: {
    prev() {
      this.$router.push({ query: this.query(this.currentPage - 1) });
    },
    next() {
      this.$router.push({ query: this.query(this.currentPage + 1) });
    },
    query(page) {
      return {
        ...this.$route.query,
        [this.queryName]: page
      };
    }
  },
  created() {
    this.perPageSelected = this.perPage;
    this.optionsPerPage = this.options.map(option => ({
      value: option,
      text: option
    }));
  }
};
</script>

<style lang="scss" scoped>
.page {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 5px;
  width: 22px;
  height: 22px;
  border-radius: 50%;
  font-size: 0.81rem;
  font-weight: bold;
  text-decoration: none;
  transition: all 0.3s ease;
  &.router-link-exact-active {
    transform: scale(1.1);
  }
  &:hover {
    transform: scale(1.1);
  }
}

.btn-navigation {
  background: none;
  border: none;
  color: #6b6b6b;
  margin: 0 5px;
  cursor: pointer;
  transition: all 0.3s ease;
  font-size: 0.8rem;
  &:hover {
    transform: scale(1.1);
  }
  &:focus {
    outline: none;
  }
  &.disabled {
    &:hover {
      transform: scale(1);
    }
  }
}

select {
  width: 60px;
  border-radius: 20px;
  height: 24px;
  padding: 0 10px;
  border-color: #6b6b6b;
}
</style>
