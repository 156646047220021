<template>
  <div class="sub-header py-3">
    <div class="container">
      <div class="row justify-content-between">
        <div class="col-3"></div>
        <div class="col-7 d-flex justify-content-center">
          <div class="sub-column"></div>
          <div class="sub-column">
            <h2 :style="{ color: $store.getters.theme[2] }">
              {{ translation.header.assignments_text }}
            </h2>
            <router-link
              :to="{ name: 'activities', query: { _page: 1, _q: '' } }"
            >
              {{ translation.header.see_assignments }}
            </router-link>
            <router-link
              v-if="
                $store.getters.info.user.type === userTypesText.admin ||
                  $store.getters.info.user.type === userTypesText.moderator
              "
              :to="{ name: 'createactivity', query: { a: 'new' } }"
            >
              {{ translation.header.add_assignment }}
            </router-link>
          </div>
          <div class="sub-column"></div>
          <div class="sub-column"></div>
          <div class="sub-column"></div>
        </div>
        <div class="col-2"></div>
      </div>
    </div>
  </div>
</template>

<script>
import { userTypesText } from "@/constants";
import { mapState } from "vuex";

export default {
  data() {
    return {
      userTypesText
    };
  },

  computed: {
    ...mapState(["permissions"])
  }
};
</script>

<style lang="scss" scoped>
.sub-header {
  background: #ffffff;
  animation: expand-subheader-activities 0.5s;
  height: 120px;
}

.sub-column {
  width: 120px;
  h2 {
    font-size: 1rem;
    font-weight: bold;
    padding: 5px 10px;
    margin-bottom: 0;
  }
  a {
    font-size: 0.8rem;
    cursor: pointer;
    display: block;
    text-decoration: none;
    color: #999;
    font-weight: bold;
    padding: 5px 10px;
    transition: all 0.3s ease;
    white-space: nowrap;
    &:hover {
      color: #bbb;
    }
    &.router-link-exact-active {
      color: #6b6b6b;
      text-decoration: underline;
    }
  }
}
</style>
