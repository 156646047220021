<template>
  <div>
    <div class="task-statement-formatted">
      <p :class="{ active: showMore }" v-html="getFormattedTask"></p>
      <div class="m-0" v-if="showMore">
        <p class="m-0">
          {{ task.question_object.text }}
        </p>
        <div class="mt-4" v-if="task.question_object.custom_tagging">
          <span class="font-weight-bold mr-2"
            >{{ translation.replies.labels }}:</span
          >
          <span
            class="label"
            v-for="(label, index) in task.question_object.options"
            :key="index"
            :style="{ background: label.color, margin: '0 3px' }"
          >
            {{ label.label }}
          </span>
        </div>
      </div>
      <div class="task-statement-separator">
        <button
          :style="{ color: $store.getters.theme[2] }"
          class="task-statement-show-more hover"
          @click="showMore = !showMore"
        >
          {{
            showMore
              ? translation.global.show_less
              : translation.global.show_more
          }}
        </button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    task: {
      required: true
    },
    getFormattedTask: {
      required: true
    }
  },
  data() {
    return {
      showMore: false
    };
  }
};
</script>

<style lang="scss" scoped>
.label {
  color: #ffffff;
  word-wrap: break-word;
  word-break: break-word;
  padding: 0 5px;
  border-radius: 5px;
}
</style>
