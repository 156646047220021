import axios from "axios";
import config from "./config";

export default {
  getSignedURL(file, filepath, fileName) {
    let endpoint = config.AWS_LAMBDA_GETSIGNEDURL_ENDPOINT;
    let payload = {
      filePath: `${filepath}/${fileName}`,
      contentType: file.type,
      isAttachment: true
    };
    return axios
      .post(endpoint, payload)
      .then(res => {
        return Promise.resolve(res.data.url || "/");
      })
      .catch(err => {
        console.error(err);
        return Promise.reject("/");
      });
  }
};
