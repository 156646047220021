var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('imageCropper',{attrs:{"img":_vm.img.code,"show":_vm.showCropper,"loader":_vm.loaderInsertImg},on:{"updateImg":function (img) { return _vm.insertImg(img); },"trash":_vm.close}}),_c('p',{staticClass:"label mt-4"},[_vm._v(" "+_vm._s(_vm.translation.create_task.reaction.select_image)+": ")]),_c('div',{staticClass:"image-reaction mb-5"},[_c('div',{staticClass:"scroll",style:({
        width: ((_vm.imageWidth + 40) + "px"),
        height: ((_vm.imageHeight + 40) + "px")
      })},[_c('label',{staticClass:"input",style:({
          width: (_vm.imageWidth + "px"),
          height: (_vm.imageHeight + "px"),
          'background-color': _vm.$store.getters.theme[0]
        }),attrs:{"for":"reaction-img"}},[(_vm.img.code.length)?_c('div',{staticClass:"img"},[_c('img',{ref:"img",style:({ width: (_vm.imageWidth + "px"), height: (_vm.imageHeight + "px") }),attrs:{"src":_vm.img.code}})]):_vm._e(),_c('input',{ref:"reactionImg",staticClass:"d-none",style:({ width: (_vm.imageWidth + "px"), height: (_vm.imageHeight + "px") }),attrs:{"id":"reaction-img","type":"file","accept":"image/*"},on:{"change":_vm.uploadImg}})])])]),_c('div',{staticClass:"d-flex align-items-center justify-content-end mt-3"},[_c('div',{staticClass:"error-box bg-danger p-2 mr-3",class:{ active: _vm.reactionError }},[_c('b-icon',{staticClass:"mr-1",attrs:{"icon":"exclamation-triangle-fill"}}),_vm._v(" "+_vm._s(_vm.reactionError)+" ")],1)]),(_vm.reactionOptions.length)?_c('div',{staticClass:"reaction-options"},_vm._l((_vm.reactionOptions),function(option,index){
        var _obj;
return _c('div',{key:index,staticClass:"reaction-option py-2 px-2 px-md-3",class:{ disabled: !option.active }},[_c('div',[_c('div',[_vm._v(" "+_vm._s(index + 1)+" ")]),_c('div',[_c('label',{attrs:{"for":("emoji-" + index)}},[_vm._v("Emoji:")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(option.icon),expression:"option.icon"}],style:({
              background: option.active ? _vm.$store.getters.theme[0] : '#efefef'
            }),attrs:{"type":"text","autocomplete":"off","id":("emoji-" + index)},domProps:{"value":(option.icon)},on:{"keypress":function($event){$event.preventDefault();},"paste":function($event){$event.preventDefault();},"click":function($event){return _vm.showEmojiPicker(option, index)},"keyup":function($event){return _vm.addReactionOption(index)},"input":function($event){if($event.target.composing){ return; }_vm.$set(option, "icon", $event.target.value)}}})]),_c('div',{on:{"click":function($event){return _vm.addReactionOption(index)}}},[_c('label',{attrs:{"for":("label-" + index)}},[_vm._v(" "+_vm._s(_vm.translation.create_task.reaction.reaction_label)+": ")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(option.label),expression:"option.label"}],staticClass:"px-2",class:( _obj = {}, _obj['text-danger'] = _vm.redText && index !== _vm.reactionOptions.length - 1, _obj ),style:({
              background: option.active ? _vm.$store.getters.theme[0] : '#efefef'
            }),attrs:{"autocomplete":"off","id":("label-" + index),"type":"text","maxlength":"255"},domProps:{"value":(option.label)},on:{"keyup":function($event){return _vm.addReactionOption(index)},"click":function($event){return _vm.addReactionOption(index)},"input":function($event){if($event.target.composing){ return; }_vm.$set(option, "label", $event.target.value)}}})]),(option.active && _vm.reactionOptions.length > 3)?_c('div',{staticClass:"p-2",on:{"click":function($event){return _vm.removeReactionOption(index)}}},[_c('b-icon',{attrs:{"icon":"x"}})],1):_vm._e()]),_c('div',{staticClass:"emoji-select mt-4"},[(option.showEmoji)?_c('VEmojiPicker',{on:{"select":function (emoji) { return _vm.selectEmoji(emoji, option); }}}):_vm._e(),(option.showEmoji)?_c('div',{on:{"click":function($event){option.showEmoji = false}}},[_c('b-icon',{attrs:{"icon":"x"}})],1):_vm._e()],1)])}),0):_vm._e(),_c('div',{staticClass:"min-max mt-4 mb-3"},[_c('div',{staticClass:"mb-3"},[_c('label',{attrs:{"for":"min"}},[_vm._v(" "+_vm._s(_vm.translation.create_task.reaction.minimum_number)+": ")]),_c('b-form-input',{attrs:{"name":"min","type":"number","min":"1","max":_vm.max,"size":"sm"},model:{value:(_vm.min),callback:function ($$v) {_vm.min=$$v},expression:"min"}})],1),_c('div',[_c('label',{attrs:{"for":"max"}},[_vm._v(" "+_vm._s(_vm.translation.create_task.reaction.maximum_number)+": ")]),_c('b-form-input',{attrs:{"name":"max","type":"number","min":"1","size":"sm"},model:{value:(_vm.max),callback:function ($$v) {_vm.max=$$v},expression:"max"}})],1)]),_c('div',{staticClass:"switch d-flex"},[_c('span',{staticClass:"pr-2"},[_vm._v(" "+_vm._s(_vm.translation.create_task.reaction.mandatory_comment)+":")]),_c('b-form-checkbox',{attrs:{"name":"check-button","switch":""},model:{value:(_vm.mandatoryComment),callback:function ($$v) {_vm.mandatoryComment=$$v},expression:"mandatoryComment"}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }