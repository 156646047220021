<template>
  <div>
    <h2 class="title">
      {{ translation.replies.charts_tab.choose_your_chart_type }}
    </h2>
    <div class="type-wrapper">
      <button
        :style="{
          background:
            type === chartTypes.line ? $store.getters.theme[3] : 'none'
        }"
        class="btn hover"
        @click="setType(chartTypes.line)"
      >
        <img
          v-if="type === chartTypes.line"
          src="@/img/line-icon-white.svg"
          alt="Pie Chart"
        />
        <img v-else src="@/img/line-icon.svg" alt="Pie Chart" />
      </button>
      <button
        :style="{
          background: type === chartTypes.bar ? $store.getters.theme[3] : 'none'
        }"
        class="btn hover"
        @click="setType(chartTypes.bar)"
      >
        <img
          v-if="type === chartTypes.bar"
          src="@/img/bar-icon-white.svg"
          alt="Bar Chart"
        />
        <img v-else src="@/img/bar-icon.svg" alt="Bar Chart" />
      </button>
      <button
        :style="{
          background:
            type === chartTypes.barHorizontal ? $store.getters.theme[3] : 'none'
        }"
        class="btn hover"
        @click="setType(chartTypes.barHorizontal)"
      >
        <img
          v-if="type === chartTypes.barHorizontal"
          src="@/img/bar-horizontal-icon-white.svg"
          alt="Horizontal Bar Chart"
        />
        <img
          v-else
          src="@/img/bar-horizontal-icon.svg"
          alt="Horizontal Bar Chart"
        />
      </button>
      <button
        :style="{
          background:
            type === chartTypes.stackedBar ? $store.getters.theme[3] : 'none'
        }"
        class="btn hover"
        @click="setType(chartTypes.stackedBar)"
      >
        <img
          v-if="type === chartTypes.stackedBar"
          src="@/img/stacked-bar-icon-white.svg"
          alt="Stacked Bar Chart"
        />
        <img v-else src="@/img/stacked-bar-icon.svg" alt="Stacked Bar Chart" />
      </button>
      <button
        :style="{
          background: type === chartTypes.pie ? $store.getters.theme[3] : 'none'
        }"
        class="btn hover"
        @click="setType(chartTypes.pie)"
      >
        <img
          v-if="type === chartTypes.pie"
          src="@/img/pie-icon-white.svg"
          alt="Pie Chart"
        />
        <img v-else src="@/img/pie-icon.svg" alt="Pie Chart" />
      </button>
    </div>
  </div>
</template>

<script>
import { chartTypes } from "@/chartsConfig";

export default {
  props: {
    config: {
      required: true
    }
  },
  data() {
    return {
      type: null,
      chartTypes
    };
  },
  methods: {
    setType(data) {
      this.type = data;
      this.$emit("update", data);
    }
  },
  created() {
    this.type = this.config.type;
  }
};
</script>

<style lang="scss" scoped>
.title {
  font-size: 1rem;
  color: #6b6b6b;
  font-weight: bold;
  margin-bottom: 20px;
}

.type-wrapper {
  button {
    border: 1px solid rgba(123, 123, 123, 0.25);
    padding: 5px;
    margin-right: 20px;
    border-radius: 0;
    margin-bottom: 10px;
    img {
      display: block;
      width: 60px;
      height: 60px;
    }
  }
}
</style>
