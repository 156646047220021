var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"editor-tinymce"},[_c('div',{staticClass:"error-box bg-danger p-2 my-3",class:{ active: _vm.imageMessage }},[_c('b-icon',{staticClass:"mr-1",attrs:{"icon":"exclamation-triangle-fill"}}),_vm._v(" "+_vm._s(_vm.imageMessage)+" ")],1),_c('div',{staticClass:"error-box bg-danger p-2 my-3",class:{ active: _vm.videoMessage }},[_c('b-icon',{staticClass:"mr-1",attrs:{"icon":"exclamation-triangle-fill"}}),_vm._v(" "+_vm._s(_vm.videoMessage)+" ")],1),(_vm.loader)?_c('div',{staticClass:"d-flex justify-content-center py-5"},[_c('loader')],1):_vm._e(),_c('editor',{ref:"tinymce",attrs:{"tinymce-script-src":this.tinySelfHostedUrl,"init":{
      height: 350,
      menubar: false,
      placeholder: _vm.placeholder,
      relative_urls: false,
      remove_script_host: false,
      paste_as_text: true,
      content_style: 'pre { white-space: pre-wrap; }',
      plugins: [
        'advlist autolink lists link image media charmap print preview anchor',
        'searchreplace visualblocks code fullscreen emoticons',
        'insertdatetime media table paste code help wordcount'
      ],
      toolbar:
        'undo redo | formatselect | bold italic backcolor forecolor | \
         alignleft aligncenter alignright alignjustify | \
         bullist numlist outdent indent | emoticons | removeformat | help',
      setup: function (editor) {
        editor.on('init', function (e) {
          _vm.loader = false;
        });
      }
    }},model:{value:(_vm.content),callback:function ($$v) {_vm.content=$$v},expression:"content"}}),_c('div',{staticClass:"d-flex justify-content-end align-items-center"},[(_vm.isTextTask)?_c('characterAndWordCounter',{attrs:{"count":_vm.hasWordsLimitation
          ? _vm.characterCount
            ? _vm.wordsCount
            : 0
          : _vm.characterCount,"max":_vm.hasWordsLimitation ? _vm.maxWords : _vm.maxCharacters,"label":_vm.hasWordsLimitation
          ? _vm.translation.answer_task.words
          : _vm.translation.answer_task.characters}}):_c('character-counter',{attrs:{"max":_vm.maxCharacters,"min":_vm.minCharacters,"count":_vm.characterCount}}),_c('div',{staticClass:"credits ml-3"},[_vm._v(" Powered by Tiny ")])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }