<template>
  <div class="stats mt-3 pt-4 px-md-4 mb-5">
    <div v-if="!loader" class="table-wrapper">
      <div class="table">
        <div class="table-header">
          <div>
            <span>
              {{ translation.stats_assignments.number_of_assignments }}:
            </span>
            {{ personalProgress.activity_count }}
          </div>
          <div>
            <span>{{ translation.stats_assignments.complete }}:</span>
            {{ personalProgress.completes }}
          </div>
          <div>
            <span>{{ translation.stats_assignments.incomplete }}:</span>
            {{ personalProgress.incompletes }}
          </div>
          <div>
            <span>{{ translation.stats_assignments.not_started }}:</span>
            {{ personalProgress.not_starteds }}
          </div>
        </div>
        <div>
          <personal-task-stats
            :activities="activities"
            :total="activities.length"
          />
        </div>
      </div>
    </div>
    <div v-else-if="loader" class="d-flex justify-content-center py-5">
      <loader />
    </div>
    <div v-else class="d-flex justify-content-center pb-2 no-data">
      {{ translation.replies.dashboard.no_participants }}
    </div>
  </div>
</template>

<script>
import { api } from "@/services.js";
import loader from "@/components/general/loader.vue";
import personalTaskStats from "@/components/participant/personalTaskStats.vue";

export default {
  components: { personalTaskStats, loader },

  props: {
    participant: {
      required: true
    }
  },

  data() {
    return {
      loader: false,
      activities: [],
      personalProgress: {}
    };
  },

  methods: {
    getStats() {
      this.loader = true;
      api
        .get(`/participant/${this.participant.id}/activities/status`, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${this.$store.getters.token}`
          }
        })
        .then(r => {
          if (r.status === 200) {
            this.activities = r.data.activities_status;
            this.personalProgress = r.data.personal_progress;
            this.loader = false;
          }
        })
        .catch(() => {
          this.loader = false;
        });
    }
  },
  created() {
    this.getStats();
  }
};
</script>

<style lang="scss" scoped>
.stats {
  margin: 10px;
  border-radius: 11px;
}
.table {
  width: 100%;
}

.table-header {
  min-width: 100%;
  display: flex;
  width: fit-content;
  border-bottom: 1px solid #c4c4c4;
  padding-bottom: 20px;
  > div {
    min-width: 135px;
    flex: 1;
    display: flex;
    color: #6b6b6b;
    font-size: 0.85rem;
    span {
      font-weight: bold;
      margin-right: 5px;
    }
    &:nth-of-type(2) {
      justify-content: center;
    }
    &:nth-of-type(3) {
      justify-content: center;
    }
    &:nth-of-type(4) {
      justify-content: flex-end;
    }
  }
}

.no-data {
  color: #6b6b6b;
}

@media (max-width: 767.98px) {
  .table-header {
    flex-direction: column;
    > div {
      &:nth-of-type(2) {
        justify-content: flex-start;
      }
      &:nth-of-type(3) {
        justify-content: flex-start;
      }
      &:nth-of-type(4) {
        justify-content: flex-start;
      }
    }
  }
}
</style>
