<template>
  <div></div>
</template>

<script>
export default {
  props: {
    taskText: {
      required: true
    },
    task: {
      required: true
    }
  },
  data() {
    return {};
  },
  computed: {
    taskObj() {
      return {
        statement: this.taskText
      };
    }
  },
  watch: {
    taskObj() {
      this.$emit("update", this.taskObj);
    }
  },
  methods: {
    getDescription() {
      this.$emit("update", this.taskObj);
    }
  },
  created() {
    this.getDescription();
  }
};
</script>

<style></style>
