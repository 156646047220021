<template>
  <div>
    <h2 class="title">
      {{ translation.replies.charts_tab.choose_heatmap }}
    </h2>
    <div class="dot-wrapper">
      <div>
        <div class="input-wrapper">
          <label for="max">{{ translation.replies.charts_tab.max }}: </label>
          <b-form-input
            id="max"
            :class="{ red: !validRangeMax }"
            type="number"
            v-model="max"
            min="1"
            max="10"
            size="sm"
          ></b-form-input>
        </div>
        <div class="add-btn">
          <button
            @click="setMax"
            :style="{ background: $store.getters.theme[2] }"
            class="btn btn-sm hover px-4"
            :disabled="!validRangeMax"
          >
            {{ translation.global.apply }}
          </button>
        </div>
      </div>
      <div>
        <div class="input-wrapper">
          <label for="radius">
            {{ translation.replies.charts_tab.radius }}:
          </label>
          <b-form-input
            id="radius"
            :class="{ red: !validRangeRadius }"
            type="number"
            v-model="radius"
            min="5"
            max="50"
            size="sm"
          ></b-form-input>
        </div>
        <div class="add-btn">
          <button
            @click="setRadius"
            :style="{ background: $store.getters.theme[2] }"
            class="btn btn-sm hover px-4"
            :disabled="!validRangeRadius"
          >
            {{ translation.global.apply }}
          </button>
        </div>
      </div>
      <div>
        <div class="input-wrapper">
          <label for="maxOpacity">
            {{ translation.replies.charts_tab.maxOpacity }}:
          </label>
          <b-form-input
            id="maxOpacity"
            :class="{ red: !validRangeMaxOpacity }"
            type="number"
            v-model="maxOpacity"
            min="0"
            max="1"
            size="sm"
            step="0.1"
          ></b-form-input>
        </div>
        <div class="add-btn">
          <button
            @click="setMaxOpacity"
            :style="{ background: $store.getters.theme[2] }"
            class="btn btn-sm hover px-4"
            :disabled="!validRangeMaxOpacity"
          >
            {{ translation.global.apply }}
          </button>
        </div>
      </div>
      <div>
        <div class="input-wrapper">
          <label for="blur">{{ translation.replies.charts_tab.blur }}:</label>
          <b-form-input
            id="blur"
            :class="{ red: !validRangeBlur }"
            type="number"
            v-model="blur"
            min="0"
            max="1"
            size="sm"
            step="0.1"
          ></b-form-input>
        </div>
        <div class="add-btn">
          <button
            @click="setBlur"
            :style="{ background: $store.getters.theme[2] }"
            class="btn btn-sm hover px-4"
            :disabled="!validRangeBlur"
          >
            {{ translation.global.apply }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    config: {
      required: true
    }
  },
  data() {
    return {
      max: 0,
      radius: 0,
      maxOpacity: 0,
      blur: 0
    };
  },
  computed: {
    validRangeMax() {
      return this.max >= 1 && this.max <= 10;
    },
    validRangeRadius() {
      return this.radius >= 5 && this.radius <= 50;
    },
    validRangeMaxOpacity() {
      return this.maxOpacity >= 0 && this.maxOpacity <= 1;
    },
    validRangeBlur() {
      return this.blur >= 0 && this.blur <= 1;
    }
  },
  methods: {
    setMax() {
      this.$emit("updateMax", this.max);
    },
    setRadius() {
      this.$emit("updateRadius", this.radius);
    },
    setMaxOpacity() {
      this.$emit("updateMaxOpacity", this.maxOpacity);
    },
    setBlur() {
      this.$emit("updateBlur", this.blurBlur);
    }
  },
  created() {
    this.max = this.config.dot.max;
    this.radius = this.config.dot.radius;
    this.maxOpacity = this.config.dot.maxOpacity;
    this.blur = this.config.dot.blur;
  }
};
</script>

<style lang="scss" scoped>
.title {
  font-size: 1rem;
  color: #6b6b6b;
  font-weight: bold;
  margin-bottom: 10px;
}

.dot-wrapper {
  display: flex;
  flex-wrap: wrap;
  > div {
    display: flex;
    align-items: flex-end;
    width: calc(50% - 10px);
    margin-bottom: 10px;
    &:nth-of-type(2n + 1) {
      margin-right: 20px;
    }
    .input-wrapper {
      width: 100%;
      label {
        color: #6b6b6b;
        margin-bottom: 0;
      }
      input {
        width: 100%;
      }
    }
    .red {
      color: #df2938 !important;
    }
  }
}

@media (max-width: 767.98px) {
  .dot-wrapper {
    > div {
      width: 100%;
      &:nth-of-type(2n + 1) {
        margin-right: 0px;
      }
    }
  }
}

.add-btn {
  margin-left: 10px;
  button {
    color: #ffffff;
    font-weight: bold;
  }
}
</style>
