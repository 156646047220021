<template>
  <div :class="{ limit: count === max }" class="max-length">
    <span :class="{ 'text-danger': limit < 0 || (min && count < min) }">
      {{ count }}</span
    >
    / {{ max }}
  </div>
</template>

<script>
export default {
  props: {
    max: {
      required: true
    },
    min: {},
    count: {
      required: true
    }
  },
  computed: {
    limit() {
      return this.max - this.count;
    }
  }
};
</script>

<style lang="scss" scoped>
.max-length {
  text-align: right;
  font-size: 0.6rem;
  margin-top: 2px;
  font-weight: bold;
  color: #999999;
  &.limit {
    color: #666666;
  }
}
</style>
