<template>
  <div id="createtask" class="container">
    <div class="row pt-5">
      <div class="col">
        <div class="d-flex justify-content-end">
          <a class="mb-2 back" @click="$router.go(-1)"
            ><b-icon class="mr-1" icon="arrow-left-circle"></b-icon>
            {{ translation.global.return }}
          </a>
        </div>
      </div>
    </div>
    <div class="row pb-5">
      <welcome class="d-none d-md-block" />

      <div class="col-md-9">
        <div class="p-relative">
          <div class="card-b p-5 border-radius-right">
            <h2 class="title mb-4">
              {{ translation.forum.new_post.post }}
            </h2>

            <div>
              <div class="d-flex align-items-center">
                <input
                  v-model="title"
                  class="input-title"
                  type="text"
                  :placeholder="translation.forum.new_post.title"
                />
                <tooltip
                  class="ml-2"
                  :text="translation.forum.new_post.add_title_post"
                />
                <uploader class="ml-2 py-3" />
              </div>

              <editor
                class="w-100"
                :placeholder="translation.forum.new_post.write_new_post"
                :text="post"
                :maxCharacters="maxCharacters"
                @charactersCount="count => (charactersCount = count)"
                @update="text => (post = text)"
                @imageError="msg => (imageMessage = msg)"
                @videoError="msg => (videoMessage = msg)"
              />
            </div>
            <div
              :class="{ active: attachmentsMessage }"
              class="error-box bg-danger p-2 my-3"
            >
              <b-icon class="mr-1" icon="exclamation-triangle-fill"></b-icon>
              {{ attachmentsMessage }}
            </div>
            <div class="d-flex flex-wrap mb-2 mt-3">
              <attachment
                :attachments="attachmentsPost"
                :deleteFile="deletePostFile"
              />
              <attachment
                :attachments="attachmentsTmp"
                :deleteFile="deleteTmpFile"
              />
            </div>
            <div class="d-flex flex-wrap align-items-center pb-4">
              <div class="d-flex align-items-center text mr-4 my-2">
                <span class="pr-2">
                  {{ translation.forum.new_post.enable_reply }}
                </span>
                <b-form-checkbox
                  v-model="isEditable"
                  name="check-button"
                  switch
                >
                  {{
                    isEditable
                      ? translation.forum.new_post.enabled
                      : translation.forum.new_post.not_enabled
                  }}
                </b-form-checkbox>
              </div>
              <div class="d-flex align-items-center text my-2">
                <span class="pr-2">
                  {{ translation.forum.new_post.activate_post }}
                </span>
                <b-form-checkbox v-model="isActive" name="check-button" switch>
                  {{
                    isActive
                      ? translation.forum.new_post.activate
                      : translation.forum.new_post.desactivate
                  }}
                </b-form-checkbox>
              </div>
            </div>

            <h2 class="title mb-5">
              {{ translation.forum.new_post.segmentation }}
            </h2>
            <div class="d-flex segments">
              <div class="col-md-4">
                <button
                  @click="openSegments = !openSegments"
                  :class="{ active: openSegments }"
                  class="btn btn-add-segment mb-3"
                  :style="{ 'background-color': $store.getters.theme[2] }"
                >
                  {{ translation.forum.new_post.add_segment }}
                  <b-icon icon="caret-down-fill" aria-hidden="true"></b-icon>
                </button>
                <div :class="{ active: openSegments }" class="segment-box py-1">
                  <div>
                    <a
                      :class="{
                        active: segmentsArray.length === selectedSegments.length
                      }"
                      @click="toggleAll()"
                    >
                      {{ translation.forum.new_post.all }}
                    </a>
                    <a
                      class="d-block"
                      v-for="(segment, index) in segmentsArray"
                      @click="toggleSegment(segment)"
                      :key="index"
                      :class="{ active: hasSegment(segment.id) }"
                      >{{ segment.name }}</a
                    >
                  </div>
                </div>
              </div>
              <div class="col-md-8 segments-wrapper p-2 mt-lg-2 mt-1">
                <p>
                  {{ translation.forum.new_post.selected_segments }}
                </p>
                <div class="d-flex flex-wrap">
                  <div
                    class="segment px-2 mx-1 mb-2"
                    v-for="(segment, index) in selectedSegments"
                    :key="index"
                  >
                    {{ segment.name }}
                  </div>
                </div>
              </div>
            </div>
            <div class="btns d-flex justify-content-end mt-4 mb-3">
              <button
                :class="{ active: !postId }"
                class="btn save enter-animation hover"
                @click="savePost"
              >
                <div
                  v-if="loaderSavePost"
                  class="d-flex justify-content-center"
                >
                  <loader2 color="#6b6b6b" />
                </div>
                <p v-else>
                  {{ translation.global.save }}
                </p>
              </button>

              <button
                :class="{ active: postId }"
                class="btn save enter-animation hover"
                @click="editPost"
              >
                <div
                  v-if="loaderEditPost"
                  class="d-flex justify-content-center"
                >
                  <loader2 color="#6b6b6b" />
                </div>
                <p v-else>
                  {{ translation.global.save }}
                </p>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { v4 as uuidv4 } from "uuid";
import { api } from "@/services.js";
import editor from "@/components/general/editor.vue";
import uploader from "@/components/general/uploader.vue";
import welcome from "@/components/forum/welcome.vue";
import attachment from "@/components/general/attachment.vue";
import tooltip from "@/components/general/tooltip.vue";
import loader2 from "@/components/general/loader2.vue";
import { mapState } from "vuex";
import { encodeImage, encodeVideo } from "@/helpers.js";
import { isActiveCode, regexpClearTags } from "@/constants";

export default {
  components: { editor, uploader, attachment, welcome, tooltip, loader2 },
  data() {
    return {
      title: "",
      charactersCount: 0,
      post: "",
      maxCharacters: 1000,
      deleteFiles: [],
      attachmentsTmp: [],
      attachmentsPost: [],
      selectedSegments: [],
      segmentsArray: [],
      loaderEditPost: false,
      loaderSavePost: false,
      isEditable: false,
      isActive: false,
      openSegments: false,
      videoMessage: null,
      imageMessage: null,
      encodeImage,
      encodeVideo,
      isActiveCode,
      regexpClearTags
    };
  },
  computed: {
    ...mapState([
      "attachment",
      "imageUploaderFolder",
      "videoUploaderFolder",
      "attachmentUploaderFolder"
    ]),
    attachmentsCount() {
      const attTemp = this.attachmentsTmp ? this.attachmentsTmp.length : 0;
      const attPost = this.attachmentsPost ? this.attachmentsPost.length : 0;
      return attTemp + attPost;
    },
    attachmentsMessage() {
      let msg = null;
      if (this.attachmentsCount > 5)
        msg = this.translation.errors_global.limit_exceeded_attachments;
      return msg;
    },
    postId() {
      return this.$route.query.pId;
    }
  },
  watch: {
    attachment() {
      this.getAttachmentsTmp();
    }
  },
  methods: {
    toggleAll() {
      if (this.selectedSegments.length === this.segmentsArray.length) {
        this.selectedSegments = [];
      } else {
        this.selectedSegments = [];
        this.segmentsArray.forEach(segment => {
          this.selectedSegments.push(segment);
        });
      }
    },
    toggleSegment(segment) {
      const filter = this.selectedSegments.filter(seg => seg.id === segment.id);
      if (filter.length) {
        this.selectedSegments.splice(
          this.selectedSegments.indexOf(filter[0]),
          1
        );
      } else {
        this.selectedSegments.push(segment);
      }
    },
    async getSegments() {
      return await api
        .get(`community/${this.$store.getters.community.id}/segments`, {
          headers: {
            Authorization: `Bearer ${this.$store.getters.token}`
          }
        })
        .then(r => {
          if (r.status === 200) {
            const segments = [];

            if (r.data.length) {
              r.data.forEach(segment => {
                if (segment.is_active === this.isActiveCode.active)
                  segments.push(segment);
              });
            }
            this.segmentsArray = segments;
            this.selectedSegments = [];
          }
        });
    },
    deletePostFile(filename, index) {
      this.attachmentsPost.splice(index, 1);
      this.deleteFiles.push(filename);
    },
    deleteTmpFile(filename) {
      api
        .post(
          "s3",
          {
            item: `attachments/${this.attachmentUploaderFolder}/${filename}`
          },
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${this.$store.getters.token}`
            }
          }
        )
        .then(r => {
          if (r.status === 200) this.getAttachmentsTmp();
        });
    },
    getAttachmentsTmp() {
      api
        .post(
          "task/s3/attachments",
          {
            folder: `${this.attachmentUploaderFolder}`
          },
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${this.$store.getters.token}`
            }
          }
        )
        .then(r => {
          this.attachmentsTmp = r.data;
        });
    },
    hasSegment(id) {
      let resp = false;
      let selected = this.selectedSegments.filter(segment => segment.id === id);
      if (selected.length) {
        resp = true;
      }
      return resp;
    },
    hasImage(text) {
      return text.match(/(?:(<img.*?src="))(.*?)((?:".*?\/>))/g);
    },
    hasVideo(text) {
      return text.match(/(?:(<iframe.*?src="))(.*?)(?:".*?>)/g);
    },
    getErrors() {
      let errorStr = "";

      errorStr += this.videoMessage ? `• ${this.videoMessage} ` : "";
      errorStr += this.imageMessage ? `• ${this.imageMessage} ` : "";
      errorStr += this.attachmentsMessage
        ? `• ${this.attachmentsMessage} `
        : "";

      errorStr += !this.title.length
        ? `• ${this.translation.forum.new_post.error.empty_title} `
        : "";

      errorStr +=
        this.charactersCount > this.maxCharacters
          ? `• ${this.translation.errors_global.you_have_exceeded} `
          : "";

      errorStr += !this.post.replace(this.regexpClearTags, "").length
        ? `• ${this.translation.forum.new_post.error.empty_post} `
        : "";

      return errorStr.length ? errorStr : null;
    },
    savePost() {
      if (!this.loaderSavePost) {
        if (!this.getErrors()) {
          this.loaderSavePost = true;
          let post = this.post;

          let body = {};

          // codificar as imagem do editor
          if (this.hasImage(post)) {
            post = this.encodeImage(post);
            body.has_image = 1;
            body.image_folder = `tmp/images/${this.imageUploaderFolder}`;
          } else {
            body.has_image = 0;
          }

          // codificar os vídeos do editor
          if (this.hasVideo(post)) {
            post = this.encodeVideo(post);
            body.has_video = 1;
            body.video_folder = `tmp/video/${this.videoUploaderFolder}`;
          } else {
            body.has_video = 0;
          }

          //pegar os attachments da task
          if (this.attachmentsTmp.length) {
            body.has_attachment = 1;
            body.attachment_folder = `tmp/attachments/${this.attachmentUploaderFolder}`;
          } else {
            body.has_attachment = 0;
          }

          body.title = this.title;
          body.post = post;
          body.community_id = this.$store.getters.community.id;

          if (this.isEditable) body.can_comments_be_edited = 1;
          else body.can_comments_be_edited = 0;

          if (this.isActive) body.is_active = 1;
          else body.is_active = 0;

          let segments = [];

          this.selectedSegments.forEach(segment => {
            segments.push(segment.id);
          });

          body.is_segmented = segments;

          api
            .post("forum/post", body, {
              headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${this.$store.getters.token}`
              }
            })
            .then(resp => {
              if (resp.status === 200) {
                this.$toast.success(
                  this.translation.forum.new_post.success.post_created
                );
                this.$router.push({ name: "forum" });
              }
              this.loaderSavePost = false;
            })
            .catch(() => {
              this.loaderSavePost = false;
              this.$toast.error(
                this.translation.errors_global.something_went_wrong
              );
            });
        } else {
          this.$toast.error(this.getErrors());
        }
      }
    },
    editPost() {
      if (!this.loaderEditPost) {
        if (!this.getErrors()) {
          this.loaderEditPost = true;
          let post = this.post;

          let body = {};

          // codificar as imagem do editor
          if (this.hasImage(post)) {
            post = this.encodeImage(post);
            body.has_image = 1;
            body.image_folder = `tmp/images/${this.imageUploaderFolder}`;
          } else {
            body.has_image = 0;
          }

          // codificar os vídeos do editor
          if (this.hasVideo(post)) {
            post = this.encodeVideo(post);
            body.has_video = 1;
            body.video_folder = `tmp/video/${this.videoUploaderFolder}`;
          } else {
            body.has_video = 0;
          }

          //pegar os attachments da task
          if (this.attachmentsTmp.length) {
            body.has_attachment = 1;
            body.attachment_folder = `tmp/attachments/${this.attachmentUploaderFolder}`;
          } else {
            body.has_attachment = 0;
          }

          body.title = this.title;
          body.post = post;

          if (this.isEditable) body.can_comments_be_edited = 1;
          else body.can_comments_be_edited = 0;

          if (this.isActive) body.is_active = 1;
          else body.is_active = 0;

          let segments = [];

          this.selectedSegments.forEach(segment => {
            segments.push(segment.id);
          });

          body.is_segmented = segments;

          if (this.deleteFiles.length) {
            body.delete_attachments = this.deleteFiles;
          }

          api
            .put(`forum/post/${this.postId}`, body, {
              headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${this.$store.getters.token}`
              }
            })
            .then(resp => {
              if (resp.status === 200) {
                this.$toast.success(
                  this.translation.success_global.changes_saved
                );
                this.$router.push({ name: "forum" });
              }
              this.loaderEditPost = false;
            })
            .catch(() => {
              this.loaderEditPost = false;
              this.$toast.error(
                this.translation.errors_global.something_went_wrong
              );
            });
        } else {
          this.$toast.error(this.getErrors());
        }
      }
    },
    getPost() {
      if (this.postId) {
        api
          .get(`forum/${this.postId}`, {
            headers: {
              Authorization: `Bearer ${this.$store.getters.token}`
            }
          })
          .then(r => {
            if (r.status === 200) {
              this.title = r.data.title;
              this.post = r.data.post;
              this.segments = r.data.segments;
              this.selectedSegments = r.data.segments;
              this.attachmentsPost = r.data.attachments;
              this.isEditable = +r.data.can_comments_be_edited ? true : false;
              this.isActive = +r.data.is_active ? true : false;
            }
          });
      } else {
        this.segmentsArray.forEach(segment => {
          this.selectedSegments.push(segment);
        });
      }
    },
    initFolders() {
      this.$store.commit(
        "UPDATE_IMAGE_UPLOADER_FOLDER",
        `post-forum-image-${uuidv4()}`
      );
      this.$store.commit(
        "UPDATE_VIDEO_UPLOADER_FOLDER",
        `post-forum-video-${uuidv4()}`
      );
      this.$store.commit(
        "UPDATE_ATTACHMENT_UPLOADER_FOLDER",
        `post-forum-attachment-${uuidv4()}`
      );
    },
    async init() {
      await this.getSegments();
      this.getPost();
      this.initFolders();
    }
  },
  created() {
    this.init();
  }
};
</script>

<style lang="scss" scoped>
.border-radius-right {
  border-radius: 0;
  border-top-right-radius: 20px;
  border-bottom-right-radius: 20px;
}

.border-radius-left {
  border-radius: 0;
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;
}

@media (max-width: 991.98px) {
  .border-radius-right {
    border-radius: 20px;
  }

  .border-radius-left {
    border-radius: 20px;
  }
}

.profile-img {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100px;
  height: 100px;
  border-radius: 50%;
  overflow: hidden;
  margin-bottom: 5px;

  img {
    object-fit: cover;
    height: 100px;
    width: 100px;
  }
}

.name {
  margin: 0 auto;
  max-width: 100px;
  font-weight: bold;
  color: #6b6b6b;
}

.text {
  font-size: 1rem;
  color: #6b6b6b;
  span {
    font-weight: bold;
  }
  h3 {
    font-size: 1rem;
    font-weight: bold;
  }
}

.back {
  color: #6b6b6b;
  cursor: pointer;
}

@media (max-width: 1199.98px) {
  .segments {
    flex-direction: column;
  }
}

.segments-wrapper {
  border: 1px solid #efefef;
  border-radius: 15px;
  position: relative;
  display: flex;
  flex-direction: column;
  p {
    display: inline-block;
    margin: 0 auto;
    position: relative;
    top: -26px;
    padding: 5px 15px;
    background-color: #fff;
    color: #6b6b6b;
    font-weight: bold;
    margin-bottom: -26px;
  }
  .segment {
    border: 1px solid #efefef;
    border-radius: 3px;
    color: #6b6b6b;
  }
}

.segment-box {
  position: relative;
  z-index: 1000;
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
  background-color: #fff;
  border-radius: 7px;
  padding-right: 5px;
  display: none;
  animation: grow 400ms ease-in-out forwards;
  &.active {
    display: block;
  }
  div {
    overflow-y: auto;
    max-height: 200px;
    &::-webkit-scrollbar {
      width: 8px;
    }
    &::-webkit-scrollbar-track {
      background: rgba(0, 0, 0, 0);
      border-radius: 10px;
    }
    &::-webkit-scrollbar-thumb {
      background: #e6e6e6;
      border-radius: 10px;
      height: 40px;
    }
    &::-webkit-scrollbar-thumb:hover {
      background: #ffffff;
    }
  }
  a {
    display: flex;
    align-items: center;
    padding: 2px 10px;
    margin: 2px 5px 2px 0;
    text-transform: uppercase;
    color: #6b6b6b;
    font-size: 0.7rem;
    cursor: pointer;
    text-decoration: none;
    &::before {
      content: "";
      display: inline-block;
      width: 10px;
      height: 10px;
      border: 2px solid #fff;
      border-radius: 50%;
      margin-right: 6px;
      box-shadow: 0px 0px 1px 0px #6b6b6b;
      transition: all 0.3s ease;
    }
    &:hover {
      background: rgba(0, 0, 0, 0.05);
    }
    &.active {
      background: rgba(0, 0, 0, 0.05);
      &::before {
        background: #6b6b6b;
      }
    }
  }
}

.title {
  display: flex;
  align-items: center;
  color: #848484;
  text-transform: uppercase;
  font-size: 1.2rem;
  font-weight: bold;
  &::after {
    content: "";
    display: block;
    width: 100%;
    height: 1px;
    background-color: #848484;
    margin-left: 10px;
  }
}

.input-title {
  border: 1px solid #efefef;
  border-radius: 10px;
  width: 100%;
  padding: 5px 10px;
  color: #6b6b6b;
  word-wrap: break-word;
  word-break: break-word;
  &:focus {
    outline: none;
  }
}

.enter-animation {
  display: none;
  animation: enter 0.3s ease-in-out forwards;
  &.active {
    display: block;
  }
}

.btns {
  button {
    display: none;
    width: 120px;
    height: 30px;
    border-radius: 17px;
    letter-spacing: 0.13em;
    text-transform: uppercase;
    color: #6b6b6b;
    font-size: 0.8rem;
    font-weight: bold;
    background: #ebf2f2;
    &.active {
      display: block;
    }
  }
}

.btn-add-segment {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 30px;
  border-radius: 17px;
  text-transform: uppercase;
  font-weight: bold;
  color: #fff;
  font-size: 0.9rem;
  white-space: nowrap;
  svg {
    font-size: 1.2rem !important ;
    margin-left: 5px;
    transition: all 0.3s ease;
  }
  &.active {
    svg {
      transform: rotate(-90deg);
    }
  }
}
</style>
