<template>
  <div :class="{ disable: !isActive }" class="link mb-4 p-3">
    <div class="d-flex flex-wrap align-items-center">
      <div class="col-md-6">
        <div @click="copyLink" class="url">
          <input :value="url" type="text" ref="inpuUrl" />
          <p
            class="m-0"
            v-b-tooltip.hover.noninteractive="{ variant: 'secondary' }"
            :title="url"
          >
            {{ url }}
          </p>
        </div>
      </div>
      <div class="col-md-6">
        <p class="amount m-0">
          {{ link.amountOfRegisteredParticipants }}/{{ link.register_limit }}
          {{ translation.add_participant.registered_using_this_link }}
        </p>
      </div>
      <div class="col-md-6 pt-2">
        <div class="d-flex flex-wrap">
          <div v-if="linkSegments.length" class="segments">
            <p>
              {{ translation.add_participant.segments_linked_to_the_link }}:
            </p>
            <div
              class="my-1"
              :title="segment.name"
              v-b-tooltip.hover="{ variant: 'secondary' }"
              v-for="segment in linkSegments"
              :key="segment.id"
            >
              {{ segment.name }}
            </div>
            <button
              v-if="tokenDecoded.segments.length >= 3"
              @click="showMore = !showMore"
              class="show-more hover"
            >
              {{
                showMore
                  ? translation.add_participant.show_less
                  : translation.add_participant.show_more
              }}
            </button>
          </div>
        </div>
      </div>
      <div class="col-md-6 pt-2">
        <div class="btns">
          <button
            @click="copyLink"
            class="hover my-1"
            :style="{ color: $store.getters.theme[2] }"
          >
            <b-icon icon="files" aria-hidden="true"></b-icon>
            {{ translation.add_participant.copy_link }}
          </button>
          <button
            @click="disableLink"
            class="hover my-1"
            :style="{ color: $store.getters.theme[2] }"
          >
            <b-icon icon="slash-circle" aria-hidden="true"></b-icon>
            {{
              isActive
                ? translation.add_participant.disable_link
                : translation.add_participant.activate_link
            }}
          </button>
          <button
            @click="$bvModal.show(`deleteLink${link.id}`)"
            class="hover my-1"
            :style="{ color: $store.getters.theme[2] }"
          >
            <b-icon icon="trash" aria-hidden="true"></b-icon>
            {{ translation.add_participant.delete_link }}
          </button>
          <b-modal
            @ok="deleteLink"
            :cancel-title="translation.global.cancel"
            :id="`deleteLink${link.id}`"
            :title="translation.add_participant.delete_link"
          >
            {{ translation.add_participant.do_you_really_want_to_delete }}
          </b-modal>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { api } from "@/services.js";
import jwt_decode from "jwt-decode";

export default {
  props: {
    link: {
      required: true
    },
    segments: {
      required: true
    }
  },
  data() {
    return {
      loaderLink: false,
      tokenDecoded: null,
      url: "",
      linkSegments: [],
      showMore: false,
      teste: "",
      isActive: 0
    };
  },
  watch: {
    tokenDecoded() {
      if (this.tokenDecoded) {
        this.getLinkSegments();
      }
    },
    showMore() {
      this.getLinkSegments();
    }
  },
  methods: {
    copyLink() {
      if (this.link.amountOfRegisteredParticipants < this.link.register_limit) {
        this.$refs.inpuUrl.select();
        document.execCommand("copy");
        this.$toast.success(
          this.translation.add_participant.success.link_copied
        );
      } else
        this.$toast.error(
          this.translation.add_participant.errors.inactive_logging
        );
    },
    disableLink() {
      api
        .put(
          `admin/manage/register-group/${this.link.id}`,
          {
            is_active: this.isActive ? 0 : 1
          },
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${this.$store.getters.token}`
            }
          }
        )
        .then(r => {
          if (r.status === 200) this.isActive = !this.isActive;
        })
        .catch(() => {
          this.$toast.error(
            this.translation.errors_global.something_went_wrong
          );
        });
    },
    deleteLink() {
      api
        .delete(`admin/manage/register-group/${this.link.id}`, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${this.$store.getters.token}`
          }
        })
        .then(r => {
          if (r.status === 200) {
            this.$emit("getLinks");
            this.$toast.success(
              this.translation.add_participant.success.link_deleted
            );
          }
        })
        .catch(() => {
          this.$toast.error(
            this.translation.errors_global.something_went_wrong
          );
        });
    },
    getLinkSegments() {
      if (this.showMore) {
        this.linkSegments = this.segments.filter(segment =>
          this.tokenDecoded.segments.includes(+segment.id)
        );
      } else {
        this.linkSegments = this.segments
          .filter(segment => this.tokenDecoded.segments.includes(+segment.id))
          .filter((linkSegment, index) => index < 2);
      }
    },
    decodeToken() {
      try {
        this.tokenDecoded = jwt_decode(this.link.url_token);
      } catch (error) {
        this.tokenDecoded = null;
      }
    }
  },
  created() {
    this.url = `${window.location.origin}/createaccount?t=${this.link.url_token}`;
    this.isActive = +this.link.is_active;
    this.decodeToken();
  }
};
</script>

<style lang="scss" scoped>
.link {
  border-radius: 11px;
  border: 1px solid #c4c4c4;
}

.disable {
  opacity: 0.5;
}

.url {
  background: #ebf2f2;
  border-radius: 50px;
  color: #424151;
  padding: 5px 20px;
  cursor: pointer;

  input {
    position: absolute;
    opacity: 0;
    height: 1px;
  }
  p {
    position: relative;
    z-index: 2;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }
}

.amount {
  color: #999;
}

.segments {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  font-size: 0.8rem;
  p {
    color: #6b6b6b;
    margin-bottom: 0;
    margin-right: 10px;
  }
  div {
    animation: show 0.3s linear;
    background: #f3f3f3;
    border-radius: 5px;
    color: #999999;
    width: 80px;
    margin-right: 10px;
    text-align: center;
    padding: 5px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }
}

.show-more {
  background: none;
  color: #6b6b6b;
  border: none;
  font-weight: bold;
  transition: all 0.3s ease;
  &:focus {
    outline: none;
  }
}

.btns {
  display: flex;
  flex-wrap: wrap;
  button {
    text-decoration: underline;
    background: none;
    margin-right: 10px;
    border: none;
    font-weight: bold;
    padding: 5px;
    min-width: 130px;
    &:focus {
      outline: none;
    }
  }
}
</style>
