<template>
  <div>
    <div v-if="heatmapData.length" class="heatmap">
      <div v-for="heatmap in heatmapData" :key="heatmap.id">
        <h3 class="title pb-2">{{ heatmap.emoji }} - {{ heatmap.label }}</h3>

        <div class="heatmap-wrapper">
          <div
            class="tooltip-area"
            @mousemove="e => handleMouseMove(e, heatmap.id)"
            @mouseout="handleMouseOut(heatmap.id)"
          >
            <div
              class="tooltip-value"
              :id="`tooltip-${heatmap.id}-${id}`"
            ></div>
            <div
              :style="{
                width: `${width}px`,
                height: `${height}px`
              }"
              class="heatmap-box"
              :id="`heatmap-${heatmap.id}-${id}`"
            >
              <img
                :style="{
                  width: `${width}px`,
                  height: `${height}px`
                }"
                :src="image"
              />
            </div>
          </div>
        </div>
        <div class="d-flex justify-content-end">
          <button
            :class="{ hover: !pptLoader }"
            class="btn-download"
            :disabled="pptLoader"
            @click="
              exportPPT(heatmap.id, `${heatmap.emoji} - ${heatmap.label}`)
            "
          >
            <div
              v-if="pptLoader"
              class="d-flex justify-content-center align-items-center"
            >
              <loader2 color="#6b6b6b" class="mr-1" />
              {{ translation.replies.charts_tab.download_heatmap }}
            </div>
            <div v-else>
              <b-icon icon="download" aria-hidden="true"></b-icon>
              {{ translation.replies.charts_tab.download_heatmap }}
            </div>
          </button>
        </div>
        <div>
          <div class="table-wrapper">
            <div class="header">
              <div>x</div>
              <div>y</div>
              <div>
                {{ translation.replies.charts_tab.answers }}
              </div>
            </div>
            <div class="table-scroll">
              <div
                v-for="(item, iIndex) in heatmap.data"
                :key="iIndex"
                class="item"
              >
                <div>
                  {{ item.x }}
                </div>
                <div>
                  {{ item.y }}
                </div>
                <div>
                  {{ item.value }}
                </div>
              </div>
            </div>
          </div>
          <div class="footer">
            <div>
              {{ translation.replies.charts_tab.total_responses }}:
              {{ getTotal(heatmap.data) }}
            </div>
          </div>
        </div>
        <div class="d-flex justify-content-end mt-4">
          <button
            :class="{ hover: !xlsxLoader }"
            class="btn-download"
            @click="exportXLSX(heatmap)"
          >
            <div
              v-if="xlsxLoader"
              class="d-flex justify-content-center align-items-center"
            >
              <loader2 color="#6b6b6b" class="mr-1" />
              {{ translation.replies.charts_tab.download_table }}
            </div>
            <div v-else>
              <b-icon icon="download" aria-hidden="true"></b-icon>
              {{ translation.replies.charts_tab.download_table }}
            </div>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import loader2 from "@/components/general/loader2.vue";
import h337 from "@/heatmap.js";
import pptxgen from "pptxgenjs";
import * as XLSX from "xlsx/xlsx.mjs";
import * as htmlToImage from "html-to-image";

export default {
  props: {
    chartData: {
      required: true
    },
    id: {
      required: true
    },
    heatmapData: {
      required: true
    },
    width: {
      required: true
    },
    height: {
      required: true
    },
    image: {
      required: true
    },
    max: {
      required: true
    },
    radius: {
      required: true
    },
    maxOpacity: {
      required: true
    },
    blur: {
      required: true
    }
  },
  components: {
    loader2
  },
  data() {
    return {
      heatmaps: {},
      xlsxLoader: false,
      pptLoader: false
    };
  },
  methods: {
    async getHeatmapImage(id) {
      let node = document.querySelector(`#heatmap-${id}-${this.id}`);
      return await htmlToImage.toPng(node, { cacheBust: true });
    },
    async exportPPT(id, label) {
      this.pptLoader = true;

      let slide;
      let pres = new pptxgen();

      const image = await this.getHeatmapImage(id);

      slide = pres.addSlide();

      slide.addText(this.chartData.taskObject.question_object.statement, {
        x: 0.2,
        y: 0.2,
        h: 0.2,
        color: "6b6b6b",
        fontSize: 10
      });

      slide.addText(label, {
        x: 0.2,
        y: 0.4,
        h: 0.2,
        color: "6b6b6b",
        fontSize: 10
      });

      const w = this.height > this.width ? (6 * this.width) / this.height : 6;
      const h = this.width > this.height ? (6 * this.height) / this.width : 6;

      slide.addImage({
        data: image,
        x: 0.2,
        y: 0.8,
        w: w,
        h: h
      });

      pres.writeFile({ fileName: "heatmap" });

      this.pptLoader = false;
    },
    async exportXLSX(heatmap) {
      this.xlsxLoader = true;
      const json = heatmap.data.map(item => ({
        x: item.x,
        y: item.y,
        answers: item.value
      }));

      const dataWS = XLSX.utils.json_to_sheet(json);
      const wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, dataWS, "worksheet");
      XLSX.writeFile(wb, `data.xlsx`);
      this.xlsxLoader = false;
    },
    getTotal(data) {
      return data.reduce((acc, curr) => (acc += curr.value), 0);
    },
    updateTooltip(x, y, value, id) {
      const tooltip = document.querySelector(`#tooltip-${id}-${this.id}`);
      const transl =
        "translate(" +
        (x + 15 > this.width - 200 ? x - 100 : x + 15) +
        "px, " +
        (y + 15 > this.height - 40 ? y - 40 : y + 15) +
        "px)";
      tooltip.style.webkitTransform = transl;
      tooltip.innerHTML = `x:${x}, y:${y}, ${value}`;
    },
    handleMouseMove(e, id) {
      const tooltip = document.querySelector(`#tooltip-${id}-${this.id}`);
      const x = e.layerX;
      const y = e.layerY;
      const xArray = this.heatmaps[`heatmap-${id}-${this.id}`]._store._data[x];
      const value = xArray && xArray[y] ? xArray[y] : 0;

      tooltip.style.display = "block";
      this.updateTooltip(x, y, value, id);
    },
    handleMouseOut(id) {
      const tooltip = document.querySelector(`#tooltip-${id}-${this.id}`);
      tooltip.style.display = "none";
    },
    createHeatmap() {
      this.heatmapData.forEach(heatmap => {
        this.heatmaps[`heatmap-${heatmap.id}-${this.id}`] = h337.create({
          container: document.querySelector(
            `#heatmap-${heatmap.id}-${this.id}`
          ),
          radius: this.radius,
          maxOpacity: this.maxOpacity,
          minOpacity: 0,
          blur: this.blur
        });

        this.handleMouseOut(heatmap.id);

        this.heatmaps[`heatmap-${heatmap.id}-${this.id}`].setData({
          max: this.max,
          data: heatmap.data
        });
      });
    }
  },
  mounted() {
    this.createHeatmap();
  }
};
</script>

<style lang="scss" scoped>
.heatmap {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  margin-bottom: 30px;
  animation: show 0.3s linear;
}

.title {
  color: #6b6b6b;
  font-size: 1.2rem;
}

.heatmap-wrapper {
  min-width: 800px;
  display: flex;
  justify-content: center;
  overflow-x: auto;
  padding-bottom: 20px;

  &::-webkit-scrollbar {
    height: 10px;
  }
  &::-webkit-scrollbar-track {
    background: rgba(0, 0, 0, 0.1);
    border-radius: 10px;
  }
  &::-webkit-scrollbar-thumb {
    background: #ccc;
    border-radius: 10px;
    width: 40px;
  }
  &::-webkit-scrollbar-thumb:hover {
    background: #bbb;
  }
}

@media (max-width: 976.98px) {
  .heatmap-wrapper {
    max-width: 450px;
  }
}

@media (max-width: 767.98px) {
  .heatmap-wrapper {
    max-width: 350px;
  }
}

@media (max-width: 567.98px) {
  .heatmap-wrapper {
    max-width: 280px;
  }
}

.tooltip-area {
  display: block;
  position: relative;
}

.heatmap-box {
  background-repeat: no-repeat;
  img {
    opacity: 0.9;
  }
}

.tooltip-value {
  z-index: 1000;
  height: 25px;
  width: 100px;
  font-size: 0.9rem;
  background: white;
  position: absolute;
  border-radius: 2px;
  text-align: center;
  box-shadow: 0 0 0 5px rgba(0, 0, 0, 0.1);
  color: #6b6b6b;
  font-weight: bold;
}

.table-wrapper {
  margin-top: 30px;
  margin-bottom: 20px;
}

.header {
  display: flex;
  margin-bottom: 10px;
  > div {
    flex: 1;
    min-width: 80px;
    background: #e6e6e6;
    padding: 5px 10px;
    font-weight: bold;
    color: #6b6b6b;
    margin-right: 10px;
  }
}

.footer {
  background: #e6e6e6;
  padding: 5px 10px;
  font-weight: bold;
  color: #6b6b6b;
  margin-top: 10px;
}

.table-scroll {
  max-height: 400px;
  overflow-x: auto;
  overflow-y: scroll;

  &::-webkit-scrollbar {
    width: 8px;
    height: 10px;
  }
  &::-webkit-scrollbar-track {
    background: rgba(0, 0, 0, 0.05);
    border-radius: 10px;
  }
  &::-webkit-scrollbar-thumb {
    background: #e6e6e6;
    border-radius: 10px;
    height: 10px;
  }
  &::-webkit-scrollbar-thumb:hover {
    background: #ffffff;
  }
}

.item {
  display: flex;
  &:last-of-type {
    > div {
      border-bottom: none;
    }
  }
  > div {
    flex: 1;
    padding: 5px 10px;
    color: #6b6b6b;
    border-bottom: 1px solid #e6e6e6;
    min-width: 90px;
  }
}

.btn-download {
  display: flex;
  align-items: center;
  background: none;
  border: none;
  font-size: 1.2rem;
  font-weight: bold;
  color: #6b6b6b;

  &:focus {
    outline: none;
  }
  svg {
    margin-right: 5px;
  }
}
</style>
