var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"container"},[_c('div',{staticClass:"row py-5"},[_c('div',{staticClass:"col"},[(!_vm.$route.query._chat)?_c('a',{staticClass:"mb-2 back",on:{"click":function($event){return _vm.$router.go(-1)}}},[_c('b-icon',{staticClass:"mr-1",attrs:{"icon":"arrow-left-circle"}}),_vm._v(" "+_vm._s(_vm.translation.global.return)+" ")],1):_c('router-link',{staticClass:"mb-2 back",attrs:{"to":{ name: 'chat', query: { _participant: _vm.participant.id } }}},[_c('b-icon',{staticClass:"mr-1",attrs:{"icon":"arrow-left-circle"}}),_vm._v(" "+_vm._s(_vm.translation.global.return)+" ")],1),_c('div',{staticClass:"card-b"},[_c('div',{staticClass:"participant-info",style:({ background: _vm.$store.getters.theme[3] })},[(_vm.participant)?_c('div',{staticClass:"px-md-5 p-4"},[_c('div',{staticClass:"mr-5",style:({
                'background-color': _vm.$store.getters.theme[0],
                color: _vm.$store.getters.theme[3]
              })},[(_vm.participant.picture && _vm.participant.picture.length)?_c('img',{attrs:{"src":_vm.participant.picture,"alt":"Profile Picture"}}):_c('b-icon',{staticClass:"icon-profile",attrs:{"icon":"person-fill","aria-hidden":"true"}})],1),_c('div',{staticClass:"mr-md-5"},[_c('div',{staticClass:"mr-md-5"},[_c('h3',{staticClass:"mb-1"},[(
                      _vm.$store.getters.info.user.type ===
                        _vm.userTypesText.moderator
                    )?_c('span',[_vm._v(" "+_vm._s(_vm.namePrivacy( _vm.privacy.moderators.participants_identifier_type_id, _vm.participant.name, _vm.participant.id ))+" ")]):(
                      _vm.$store.getters.info.user.type === _vm.userTypesText.observer
                    )?_c('span',[_vm._v(" "+_vm._s(_vm.namePrivacy( _vm.privacy.observers.participants_identifier_type_id, _vm.participant.name, _vm.participant.id ))+" ")]):_c('span',[_vm._v(" "+_vm._s(_vm.participant.name)+" ")])]),(
                    _vm.$store.getters.info.user.type === _vm.userTypesText.admin ||
                      (_vm.$store.getters.info.user.type ===
                        _vm.userTypesText.moderator &&
                        !+_vm.privacy.moderators.participants_hide_email) ||
                      (_vm.$store.getters.info.user.type ===
                        _vm.userTypesText.observer &&
                        !+_vm.privacy.observers.participants_hide_email)
                  )?_c('p',{staticClass:"mb-1"},[_c('span',[_vm._v(_vm._s(_vm.translation.manage_participants.email)+": ")]),_vm._v(_vm._s(_vm.participant.email)+" ")]):_vm._e(),_c('p',[_c('span',[_vm._v("ID: ")]),_vm._v(_vm._s(_vm.participant.id))])]),_c('div',{staticClass:"mr-md-5"},[_c('p',{staticClass:"mb-1"},[_c('span',[_vm._v(" "+_vm._s(_vm.translation.manage_participants.created_at)+": ")]),_vm._v(" "+_vm._s(_vm.participant.created_at)+" ")]),(
                    _vm.$store.getters.info.user.type === _vm.userTypesText.admin ||
                      (_vm.$store.getters.info.user.type ===
                        _vm.userTypesText.moderator &&
                        !+_vm.privacy.moderators.participants_hide_login_info) ||
                      (_vm.$store.getters.info.user.type ===
                        _vm.userTypesText.observer &&
                        !+_vm.privacy.observers.participants_hide_login_info)
                  )?_c('p',[_c('span',[_vm._v(" "+_vm._s(_vm.translation.manage_participants.last_login)+": ")]),_vm._v(" "+_vm._s(_vm.participant.last_login)+" ")]):_vm._e()]),(_vm.$store.getters.info.user.type === _vm.userTypesText.admin)?_c('router-link',{staticClass:"edit-participant mt-2",attrs:{"to":{
                  name: 'manageparticipants',
                  query: {
                    _option: _vm.tabOptions.registeredOption,
                    _page: 1,
                    _first: _vm.participant.id
                  }
                }}},[_c('b-icon',{attrs:{"icon":"pencil-fill","aria-hidden":"true"}}),_vm._v(" "+_vm._s(_vm.translation.global.edit)+" ")],1):_vm._e()],1)]):_vm._e()]),(_vm.participant)?_c('div',{staticClass:"px-md-5 p-3"},[(
              _vm.$store.getters.info.user.type !== _vm.userTypesText.observer ||
                !+_vm.privacy.observers.participants_hide_segments
            )?_c('div',[_c('h2',{staticClass:"title",style:({ color: _vm.$store.getters.theme[3] })},[_vm._v(" "+_vm._s(_vm.translation.manage_participants.segments)+": ")]),_c('div',{staticClass:"d-flex flex-wrap"},_vm._l((_vm.participant.segments),function(segment){return _c('div',{key:segment.id,staticClass:"segment"},[_vm._v(" "+_vm._s(segment.name)+" ")])}),0)]):_vm._e(),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col"},[_c('nav',{staticClass:"tabs my-5",style:({ 'border-color': _vm.$store.getters.theme[2] })},[_c('button',{staticClass:"btn",class:{ active: _vm.tab === _vm.tabOptions.answers },style:({
                    background:
                      _vm.tab === _vm.tabOptions.answers
                        ? _vm.$store.getters.theme[2]
                        : _vm.$store.getters.theme[1],
                    color:
                      _vm.tab === _vm.tabOptions.answers
                        ? '#ffffff'
                        : _vm.$store.getters.theme[3]
                  }),on:{"click":function($event){_vm.tab = _vm.tabOptions.answers}}},[_vm._v(" "+_vm._s(_vm.translation.task.answers)+" ")]),(
                    _vm.$store.getters.info.user.type !==
                      _vm.userTypesText.observer ||
                      !+_vm.privacy.observers.participants_hide_personal_progress
                  )?_c('button',{staticClass:"btn",class:{ active: _vm.tab === _vm.tabOptions.personalStats },style:({
                    background:
                      _vm.tab === _vm.tabOptions.personalStats
                        ? _vm.$store.getters.theme[2]
                        : _vm.$store.getters.theme[1],
                    color:
                      _vm.tab === _vm.tabOptions.personalStats
                        ? '#ffffff'
                        : _vm.$store.getters.theme[3]
                  }),on:{"click":function($event){_vm.tab = _vm.tabOptions.personalStats}}},[_vm._v(" "+_vm._s(_vm.translation.stats_assignments.assignments_statistics)+" ")]):_vm._e()])])]),_c('transition',{attrs:{"mode":"out-in"}},[(_vm.tab === _vm.tabOptions.personalStats)?_c('div',[_c('h2',{staticClass:"title",style:({ color: _vm.$store.getters.theme[3] })},[_vm._v(" "+_vm._s(_vm.translation.stats_assignments.personal_progress)+" ")]),_c('personal-stats',{attrs:{"participant":_vm.participant}})],1):_vm._e(),(_vm.tab === _vm.tabOptions.answers)?_c('div',[_c('h2',{staticClass:"title",style:({ color: _vm.$store.getters.theme[3] })},[_vm._v(" "+_vm._s(_vm.translation.replies.replies)+": ")]),(_vm.loaderAnswers)?_c('div',{staticClass:"d-flex justify-content-center py-5"},[_c('loader')],1):_c('div',[_c('answers',{staticClass:"mt-4",attrs:{"getAnswers":_vm.getAnswers,"participants":_vm.participants}})],1),(_vm.minPerPage < _vm.total)?_c('pagination-query',{attrs:{"perPage":_vm.perPage,"total":_vm.total,"options":[5, 10, 20, 50, 100]},on:{"perPageUpdate":function (newPerPage) { return (_vm.perPage = newPerPage); }}}):_vm._e()],1):_vm._e()])],1):_vm._e()])],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }