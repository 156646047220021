<template>
  <div class="item-wrapper">
    <div class="item">
      <div class="item-name">
        <div>
          <b-icon
            :style="{ color: $store.getters.theme[2] }"
            icon="file-text"
          ></b-icon>
        </div>
        <div>
          <p>{{ job.name }}</p>
          <p>{{ job.startedAt | formatDate }}</p>
        </div>
      </div>
      <div class="item-text">
        <div>
          <p>{{ translation.replies.format }}</p>
          <p>
            {{
              option === tabOptions.media
                ? job.option === exportMediaOptions.both
                  ? translation.replies.transcription_and_media
                  : job.option === exportMediaOptions.transcript
                  ? translation.replies.transcription
                  : translation.replies.media
                : "XLSX"
            }}
          </p>
        </div>
      </div>

      <div class="item-text">
        <p v-if="job.error" class="circle error">
          {{ translation.global.error }}
        </p>
        <p v-else-if="job.ready" class="circle ready">
          {{ translation.global.ready }}
        </p>
        <p v-else class="circle processing">
          {{ translation.global.processing }}
        </p>
      </div>
      <div class="item-show-more">
        <button
          @click="showMore = !showMore"
          class="btn hover"
          :style="{ color: $store.getters.theme[2] }"
        >
          {{
            showMore
              ? translation.global.show_less
              : translation.global.show_more
          }}
        </button>
      </div>
      <div class="item-btns">
        <div class="item-download-button">
          <button
            :class="{ hover: job.ready && link.length }"
            class="btn"
            :style="{ background: $store.getters.theme[2] }"
            :disabled="!job.ready || !link.length"
            @click="downloadFile(link)"
          >
            {{ translation.replies.download }}
          </button>
        </div>
        <div class="item-delete-button">
          <b-modal
            @ok="deleteReport(job.id)"
            :cancel-title="translation.global.cancel"
            :id="`delete-report-${job.id}`"
            :title="translation.global.delete"
          >
            {{ translation.replies.are_you_sure_delete_report }}
          </b-modal>
          <button
            v-if="$store.getters.info.user.type === userTypesText.admin"
            class="btn hover"
            @click="$bvModal.show(`delete-report-${job.id}`)"
          >
            {{ translation.global.delete }}
          </button>
        </div>
      </div>
    </div>
    <div class="filters" :class="{ active: showMore }">
      <h2>
        {{ translation.replies.filters }}
      </h2>
      <div class="mt-3">
        <div v-if="job.activities && job.activities.length">
          <h3>{{ translation.replies.assignments }}:</h3>
          <div class="filter-row">
            <p v-for="activity in job.activities" :key="activity">
              {{ activity }}
            </p>
          </div>
        </div>

        <div v-if="job.participants && job.participants.length">
          <h3>{{ translation.replies.name }}:</h3>
          <div class="filter-row">
            <p v-for="participant in job.participants" :key="participant">
              {{ participant }}
            </p>
          </div>
        </div>

        <div v-if="job.checked && job.checked.length">
          <h3>Status:</h3>
          <div class="filter-row">
            <p v-for="checked in job.checked" :key="checked">{{ checked }}</p>
          </div>
        </div>

        <div v-if="job.labels && job.labels.length">
          <h3>{{ translation.replies.labels }}:</h3>
          <div class="filter-row">
            <p v-for="label in job.labels" :key="label">{{ label }}</p>
          </div>
        </div>

        <div v-if="job.segments && job.segments.length">
          <h3>{{ translation.replies.segments }}:</h3>
          <div class="filter-row">
            <p v-for="segment in job.segments" :key="segment">{{ segment }}</p>
          </div>
        </div>

        <div v-if="job.types && job.types.length">
          <h3>{{ translation.replies.type }}:</h3>
          <div class="filter-row">
            <p v-for="type in job.types" :key="type">
              {{ getTask(type) }}
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import {
  exportMediaOptions,
  tabOptions,
  taskEnum,
  userTypesText
} from "@/constants";
import { downloadFile } from "@/helpers";

export default {
  props: {
    job: {
      required: true
    },
    option: {
      required: true
    },
    getJobsList: {
      required: true
    }
  },
  filters: {
    formatDate(value) {
      const date = new Date(value);
      return date.toLocaleString();
    }
  },
  data() {
    return {
      showMore: false,
      link: "",
      loader: false,
      loaderDelete: false,
      exportMediaOptions,
      tabOptions,
      taskEnum,
      userTypesText
    };
  },
  methods: {
    downloadFile,
    deleteReport(id) {
      if (!this.loaderDelete) {
        this.loaderDelete = true;

        const url =
          this.option === this.tabOptions.media
            ? `${this.exportMediaHost}voices/delete-job`
            : `${this.exportAnswersHost}delete-job`;

        axios
          .delete(
            url,
            {
              data: {
                jobId: id,
                communityId: this.$store.getters.community.id
              }
            },
            {
              headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${this.$store.getters.token}`
              }
            }
          )
          .then(r => {
            if (r.status === 200) {
              this.$toast.success(this.translation.replies.report_deleted);
              this.getJobsList();
              this.loaderDelete = false;
            }
          })
          .catch(() => {
            this.$toast.error(
              this.translation.errors_global.something_went_wrong
            );

            this.loaderDelete = false;
          });
      }
    },
    getTask(type) {
      return Object.values(taskEnum).find(task => task.id === type).text[
        this.langAbbreviation
      ];
    },
    getDownloadLink() {
      const url =
        this.option === this.tabOptions.media
          ? `${this.exportMediaHost}media/download?jobId=${this.job.id}`
          : `${this.exportAnswersHost}download-xlsx?jobId=${this.job.id}`;

      this.loader = true;
      axios
        .get(url, {
          headers: {
            Authorization: `Bearer ${this.$store.getters.token}`
          }
        })
        .then(r => {
          if (r.status === 200) {
            this.link = r.data.url;
            this.loader = false;
          }
        })
        .catch(() => {
          this.link = "";
          this.loader = false;
        });
    }
  },
  created() {
    this.getDownloadLink();
  }
};
</script>

<style lang="scss" scoped>
.item-wrapper {
  border: 1px solid #999999;
  border-radius: 10px;
  padding: 10px;
}

.item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
}

.item-name {
  display: flex;
  align-items: center;
  width: 250px;
  > div {
    margin: 0 10px;
  }
  div {
    &:nth-of-type(1) {
      background: #f7fafa;
      border-radius: 5px;
      padding: 10px;
      margin-right: 15px;
      svg {
        font-size: 2rem;
        position: relative;
      }
    }
    &:nth-of-type(2) {
      p {
        margin-bottom: 0;
        color: #a9a9a9;
        font-size: 0.85rem;
        &:nth-of-type(1) {
          color: #3d3d3d;
          font-weight: bold;
          font-size: 0.9rem;
        }
      }
    }
  }
}
.item-text {
  display: flex;
  justify-content: center;
  width: 100px;
  p {
    color: #3d3d3d;
    margin-bottom: 0;
    text-align: center;
    font-size: 0.9rem;
    &.circle {
      display: flex;
      align-items: center;
      &::before {
        content: "";
        display: block;
        width: 5px;
        height: 5px;
        background-color: #fff;
        border-radius: 50%;
        margin-right: 10px;
        margin-top: 2px;
      }
    }
    &.processing {
      &::before {
        box-shadow: 0 0 1px #fff, 0 0 2px #fff, 0 0 3px rgb(199, 169, 0),
          0 0 4px rgb(255, 255, 0), 0 0 4px rgb(255, 255, 0),
          0 0 4px rgb(255, 255, 0), 0 0 4px rgb(255, 255, 0),
          0 0 4px rgb(255, 255, 0), 0 0 4px rgb(255, 255, 0),
          0 0 4px rgb(255, 255, 0), 0 0 4px rgb(255, 255, 0),
          0 0 4px rgb(255, 255, 0), 0 0 4px rgb(255, 255, 0);
      }
    }
    &.ready {
      &::before {
        box-shadow: 0 0 1px #fff, 0 0 2px #fff, 0 0 3px rgb(40, 199, 0),
          0 0 4px rgb(51, 255, 0), 0 0 4px rgb(51, 255, 0),
          0 0 4px rgb(51, 255, 0), 0 0 4px rgb(51, 255, 0),
          0 0 4px rgb(51, 255, 0), 0 0 4px rgb(51, 255, 0),
          0 0 4px rgb(51, 255, 0), 0 0 4px rgb(51, 255, 0),
          0 0 4px rgb(51, 255, 0), 0 0 4px rgb(51, 255, 0);
      }
    }
    &.error {
      &::before {
        box-shadow: 0 0 1px #fff, 0 0 2px #fff, 0 0 3px rgb(199, 0, 0),
          0 0 4px rgb(255, 0, 0), 0 0 4px rgb(255, 0, 0), 0 0 4px rgb(255, 0, 0),
          0 0 4px rgb(255, 0, 0), 0 0 4px rgb(255, 0, 0), 0 0 4px rgb(255, 0, 0),
          0 0 4px rgb(255, 0, 0), 0 0 4px rgb(255, 0, 0), 0 0 4px rgb(255, 0, 0),
          0 0 4px rgb(255, 0, 0);
      }
    }
  }
}
.item-show-more {
  width: 100px;
  button {
    text-decoration: underline;
    font-weight: bold;
    text-transform: lowercase;
    white-space: nowrap;
    &:focus {
      box-shadow: none;
    }
  }
}

.item-btns {
  display: flex;
  justify-content: center;
  .item-download-button {
    margin-right: 20px;
    button {
      border-radius: 33px;
      color: #ffffff;
      padding: 5px 30px 6px;
      font-weight: bold;
    }
  }
  .item-delete-button {
    button {
      border-radius: 33px;
      font-weight: bold;
      background: #c4c4c4;
      color: #ffffff;
      padding: 5px 30px 6px;
      &:focus {
        box-shadow: none;
      }
    }
  }
}

.filters {
  display: none;
  padding: 20px 10px 0 10px;
  animation: show 0.3s linear;
  h2 {
    display: flex;
    align-items: center;
    color: #6b6b6b;
    font-size: 1rem;
    font-weight: bold;
    white-space: nowrap;
    margin-bottom: 0;
    text-transform: capitalize;
    &::after {
      content: "";
      display: block;
      width: 100%;
      height: 1px;
      background: #6b6b6b;
      margin-left: 10px;
      opacity: 0.3;
    }
  }

  h3 {
    display: block;
    font-size: 1rem;
    font-weight: bold;
    color: #3d3d3d;
    margin-bottom: 0;
  }
  .filter-row {
    display: flex;
    flex-wrap: wrap;
    p {
      margin: 10px;
      color: #999999;
    }
  }
  &.active {
    display: block;
  }
}

@media (max-width: 991.98px) {
  .item-btns {
    margin: 10px 0;
    width: 100%;
  }
}
</style>
