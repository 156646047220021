<template>
  <div class="container">
    <div v-if="isConnected" class="row py-5">
      <user
        :class="{
          'd-none':
            activeTransmissionListId ||
            activeConversation ||
            showCreateTransmission
        }"
        class="d-md-block"
      />
      <chat-box
        v-if="activeConversation && !showTransmissionList"
        :activeConversation="activeConversation"
        :userSource="userSource"
        :userTarget="userTarget"
        :oldMessages="oldMessages"
        :newMessages="newMessages"
        :loaderConversation="loaderConversation"
        @sendMessage="message => sendMessage(message)"
        @deleteMessage="body => deleteMessage(body)"
        @close="activeConversation = null"
      />
      <create-transmission
        :class="{
          'd-none': !showCreateTransmission
        }"
        class="d-md-block"
        v-else-if="showTransmissionList && !activeTransmissionListId"
        :userSource="userSource"
        @sendMessage="body => sendMessageTransmissionList(body)"
      />
      <transmission-list-chat
        v-else-if="showTransmissionList && activeTransmissionListId"
        :activeTransmissionListId="activeTransmissionListId"
        :userSource="userSource"
        :getTransmissionMessages="getTransmissionMessages"
        @sendMessage="body => sendMessageTransmissionList(body)"
        @close="$store.commit('UPDATE_ACTIVE_TRANSMISSION_LIST_ID', null)"
      />
    </div>
    <div v-else class="d-flex justify-content-center py-5">
      <loader />
    </div>
  </div>
</template>

<script>
import axios from "axios";
import user from "@/components/chat/user.vue";
import chatBox from "@/components/chat/chatBox.vue";
import loader from "@/components/general/loader.vue";
import { userTypes } from "@/constants";
import createTransmission from "@/components/chat/createTransmission.vue";
import transmissionListChat from "@/components/chat/transmissionListChat.vue";
import { mapState } from "vuex";

export default {
  components: {
    user,
    chatBox,
    createTransmission,
    transmissionListChat,
    loader
  },
  data() {
    return {
      userTarget: null,
      userSource: null,
      conversations: [],
      notifications: [],
      oldMessages: [],
      newMessages: [],
      activeConversation: null,
      loaderConversations: false,
      loaderCreateConversation: false,
      loaderConversation: false,
      getTransmissionsList: false,
      getTransmissionMessages: false,
      userTypes
    };
  },
  computed: {
    ...mapState([
      "isLogOut",
      "chatSelectedUser",
      "activeTransmissionListId",
      "showCreateTransmission",
      "showTransmissionList"
    ]),
    isConnected() {
      return this.$store.getters.chatIsConnected;
    }
  },
  watch: {
    notifications() {
      this.$store.commit("UPDATE_CHAT_NOTIFICATIONS", this.notifications);
    },
    chatSelectedUser() {
      this.startConversation(this.chatSelectedUser);
    }
  },
  methods: {
    getNotifications() {
      axios(
        `${this.chatHost}get-not-read-messages?communityId=${this.$store.getters.community.id}&userId=${this.userSource.id}&userType=${this.userSource.type}`
      ).then(r => {
        if (r.status === 200) {
          this.notifications = r.data.reduce(
            (acc, conversation) => acc.concat(conversation),
            []
          );
          this.$store.commit("UPDATE_CHAT_NOTIFICATIONS", this.notifications);
        }
      });
    },
    sendMessageTransmissionList(obj) {
      const body = {
        message: obj.message,
        userTargetList: obj.userTargetList,
        userSourceId: this.userSource.id,
        userSourceType: this.userSource.type,
        communityId: this.$store.getters.community.id,
        name: obj.name
      };

      this.$socket.send(JSON.stringify(body));

      this.getTransmissionsList = !this.getTransmissionsList;
    },
    sendMessage(message) {
      const body = {
        message: message,
        userTargetId: this.userTarget.id,
        userTargetType: this.userTarget.type,
        userSourceId: this.userSource.id,
        userSourceType: this.userSource.type,
        communityId: this.$store.getters.community.id,
        conversationId: this.activeConversation.id
      };

      this.$socket.send(JSON.stringify(body));
    },
    deleteMessage(body) {
      this.$socket.send(JSON.stringify(body));
    },
    startConversation(user) {
      if (!this.loaderConversations && !this.loaderCreateConversation) {
        const hasConversation = this.conversations.filter(
          conversation =>
            (conversation.userTargetId === user.id &&
              +conversation.userTargetType === +user.type) ||
            (conversation.userSourceId === user.id &&
              +conversation.userSourceType === +user.type)
        );

        this.userTarget = user;
        this.userTarget.type = this.userTarget.type.toString();

        if (!hasConversation.length) {
          this.createConversation();
        } else {
          this.activeConversation = hasConversation[0];
          this.getConversation();
        }
      }
    },
    createConversation() {
      this.loaderCreateConversation = true;
      axios
        .post(
          `${this.chatHost}create-conversation`,
          {
            communityId: this.$store.getters.community.id,
            userTargetId: this.userTarget.id,
            userTargetType: this.userTarget.type,
            userSourceId: this.userSource.id,
            userSourceType: this.userSource.type
          },
          {
            headers: {
              "Content-Type": "application/json"
            }
          }
        )
        .then(r => {
          if (r.status === 200) {
            this.newMessages = [];
            this.activeConversation = r.data;
            this.getConversations();
            this.getConversation();
          }
          this.loaderCreateConversation = false;
        })
        .catch(() => {
          this.$toast.error(
            this.translation.errors_global.something_went_wrong
          );
          this.loaderCreateConversation = false;
        });
    },
    markMessages() {
      axios(
        `${this.chatHost}mark-messages?conversationId=${this.activeConversation.id}`
      );
    },
    getConversation() {
      this.notifications = this.notifications.filter(
        notification =>
          notification.conversationId !== this.activeConversation.id
      );
      this.newMessages = [];
      this.oldMessages = [];
      this.loaderConversation = true;
      axios(
        `${this.chatHost}conversation-messages?conversationId=${this.activeConversation.id}`
      )
        .then(r => {
          if (r.status === 200) {
            if (r.data.Items) this.oldMessages = r.data.Items;

            this.markMessages();
          }
          this.loaderConversation = false;
        })
        .catch(() => (this.loaderConversation = false));
    },
    getConversations() {
      this.loaderConversations = true;
      axios(
        `${this.chatHost}conversations?communityId=${this.$store.getters.community.id}&userId=${this.userSource.id}&userType=${this.userSource.type}`
      )
        .then(r => {
          if (r.status === 200) {
            this.conversations = r.data;
          }
          this.loaderConversations = false;
        })
        .catch(() => {
          this.loaderConversations = false;
        });
    },
    updateConversations(message) {
      const conversation = this.conversations.filter(
        conversation => conversation.id === message.conversationId
      );

      if (!conversation.length) {
        this.getConversations();
      }
    },
    init() {
      this.$disconnect();
      this.$store.commit("UPDATE_CHAT_IS_CONNECTED", false);

      const userType = this.$store.getters.info.user.type.toLowerCase();

      this.userSource = {
        id: this.$store.getters.info.user.sub,
        type: this.userTypes[userType]
      };

      this.$connect(
        `${this.chatConnect}?communityId=${this.$store.getters.community.id}&userId=${this.userSource.id}&userType=${this.userSource.type}`
      );

      this.$socket.onopen = () => {
        this.$store.commit("UPDATE_CHAT_IS_CONNECTED", true);
      };

      this.$socket.onclose = () => {
        if (this.$store.getters.info && !this.isLogOut) {
          this.$connect(
            `${this.chatConnect}?communityId=${this.$store.getters.community.id}&userId=${this.userSource.id}&userType=${this.userSource.type}`
          );
        }
      };

      this.$options.sockets.onmessage = e => {
        const message = JSON.parse(e.data);

        if (message.action === "DELETE-MESSAGE") {
          const msgOldMessages = this.oldMessages.find(
            msg => msg.id === message.messageId
          );

          const msgNewMessages = this.newMessages.find(
            msg => msg.id === message.messageId
          );

          if (msgOldMessages) {
            const index = this.oldMessages.indexOf(msgOldMessages);
            this.oldMessages.splice(index, 1);
          } else if (msgNewMessages) {
            const index = this.newMessages.indexOf(msgNewMessages);
            this.newMessages.splice(index, 1);
          }
        } else {
          if (message.conversationId === this.activeConversation.id)
            this.newMessages.push(message);

          if (
            !this.activeConversation ||
            message.conversationId !== this.activeConversation.id
          )
            this.notifications.push(message);

          this.updateConversations(message);

          this.$store.commit(
            "UPDATE_TRIGGER_GET_TRANSMISSIONS_LIST",
            this.getTransmissionsList
          );

          if (message.transmissionId && message.transmissionId !== "false") {
            this.$store.commit(
              "UPDATE_ACTIVE_TRANSMISSION_LIST_ID",
              message.transmissionId
            );
            this.getTransmissionMessages = !this.getTransmissionMessages;
          }
        }
      };

      this.getConversations();
      this.getNotifications();
    }
  },
  mounted() {
    this.init();
  }
};
</script>

<style lang="scss" scoped>
.text {
  color: #6b6b6b;
}
</style>
