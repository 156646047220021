<template>
  <div class="container">
    <imageCropper
      :img="form.img"
      :show="showCropper"
      :width="270"
      :height="130"
      :loader="loaderInsertImg"
      @updateImg="img => insertImg(img)"
      @trash="
        t => {
          showCropper = t;
          $refs.inputImg.value = '';
          form.img = '';
        }
      "
    />
    <div class="row py-5">
      <div class="col">
        <div v-if="!loaderActivity" class="card-b">
          <label
            for="banner-activity"
            class="new-activity-header"
            :style="{ 'background-color': $store.getters.theme[1] }"
          >
            <div class="add-img">
              <b-icon icon="plus" aria-hidden="true"></b-icon>
            </div>
            <input
              ref="inputImg"
              @change="uploadImg('banner-activity')"
              id="banner-activity"
              class="d-none"
              type="file"
              accept="image/*"
            />
            <div class="img" v-if="form.img.length">
              <img :src="form.img" />
            </div>
          </label>
          <div class="style">
            <img class="img-fluid" src="@/img/white-curve.png" />
          </div>
          <div class="px-md-4 pb-4 pt-4">
            <div class="d-flex flex-wrap justify-content-center pt-4">
              <div class="col-md-5 mr-md-4 pb-4">
                <h2
                  class="title mb-4"
                  :style="{ color: $store.getters.theme[3] }"
                >
                  {{ translation.create_assignment.add_assignment }}
                </h2>
                <div class="name-input">
                  <input
                    :placeholder="
                      translation.create_assignment.assignment_name_text
                    "
                    type="text"
                    v-model="form.name"
                    maxlength="30"
                  />
                  <b-icon icon="pencil" aria-hidden="true"></b-icon>
                </div>

                <character-counter :count="form.name.length" :max="30" />
                <div class="date-input mb-4">
                  <p class="m-0 p-0">
                    {{ translation.create_assignment.available_period_text }}
                  </p>
                  <div>
                    <div class="border-right mr-1">
                      <label for="datepicker-start" class="m-0">
                        {{ translation.create_assignment.start_time }}
                      </label>
                      <div class="d-flex align-items-center">
                        <img
                          class="calendar pb-1 pr-2"
                          src="@/img/calendar.svg"
                          alt="Calendar Icon"
                        />

                        <datetime
                          v-model="form.dateStart"
                          type="datetime"
                          class="theme"
                          input-class="datetime-picker"
                          :value-zone="$store.getters.community.zone_name"
                          :zone="$store.getters.community.zone_name"
                          :format="{
                            year: 'numeric',
                            month: 'numeric',
                            day: 'numeric',
                            hour: 'numeric',
                            minute: '2-digit'
                          }"
                          :phrases="{ ok: 'Continue', cancel: 'Exit' }"
                          :hour-step="1"
                          :minute-step="1"
                          :min-datetime="minDateBeginAt"
                          :max-datetime="maxDateBeginAt"
                          :week-start="7"
                          auto
                        ></datetime>
                      </div>
                    </div>
                    <div class="ml-1">
                      <label for="datepicker-end" class="m-0">
                        {{ translation.create_assignment.end_time }}
                      </label>
                      <div class="d-flex align-items-center">
                        <img
                          class="calendar pb-1 pr-2"
                          src="@/img/calendar.svg"
                          alt="Calendar Icon"
                        />
                        <datetime
                          ref="dateEnd"
                          :disabled="form.dateStart === ''"
                          v-model="form.dateEnd"
                          type="datetime"
                          class="theme"
                          input-class="datetime-picker"
                          :value-zone="$store.getters.community.zone_name"
                          :zone="$store.getters.community.zone_name"
                          :format="{
                            year: 'numeric',
                            month: 'numeric',
                            day: 'numeric',
                            hour: 'numeric',
                            minute: '2-digit'
                          }"
                          :phrases="{ ok: 'Continue', cancel: 'Exit' }"
                          :hour-step="1"
                          :minute-step="1"
                          :min-datetime="minDateDeadLine"
                          :max-datetime="maxDateDeadLine"
                          :week-start="7"
                          auto
                        ></datetime>
                      </div>
                    </div>
                  </div>
                </div>
                <dropdownButton
                  v-if="!loaderSegments"
                  idDropdown="segments"
                  :text="translation.create_assignment.who_will_see_2"
                  :options="segments"
                  :currentOptions="oldSegments"
                  @update="options => setSegments(options)"
                />
                <div v-else>
                  <loader2 :color="$store.getters.theme[2]" />
                </div>
              </div>

              <div class="col-md-5">
                <div class="radio-input pb-4">
                  <p class="mt-0 pt-0">
                    {{ translation.create_assignment.answer_visibility_text }}
                  </p>
                  <div>
                    <b-form-radio
                      class="pb-3"
                      name="visibility"
                      value="1"
                      v-model="form.visibility"
                    >
                      {{
                        translation.create_assignment.answer_visibility_option_1
                      }}
                    </b-form-radio>
                    <b-form-radio
                      class="pb-3"
                      name="visibility"
                      value="2"
                      v-model="form.visibility"
                    >
                      {{
                        translation.create_assignment.answer_visibility_option_2
                      }}
                    </b-form-radio>
                    <b-form-radio
                      name="visibility"
                      value="3"
                      v-model="form.visibility"
                    >
                      {{
                        translation.create_assignment.answer_visibility_option_3
                      }}
                    </b-form-radio>
                  </div>
                </div>
                <div class="pb-4">
                  <div class="d-flex mb-2">
                    <b-form-checkbox
                      class="toggle"
                      v-model="form.activeActivity"
                      name="check-button"
                      switch
                      :disabled="!activityCanBeActivated"
                    >
                      {{
                        form.activeActivity
                          ? translation.create_assignment.deactivate
                          : translation.create_assignment.activated
                      }}
                    </b-form-checkbox>
                    <tooltip
                      class="ml-2"
                      :left="true"
                      :text="translation.create_assignment.tooltip_activated"
                    />
                  </div>
                  <p class="warn-text" v-if="!activityCanBeActivated">
                    {{ translation.create_assignment.add_tasks_to_activate }}
                  </p>
                  <div class="d-flex mb-2">
                    <b-form-checkbox
                      class="toggle"
                      v-model="form.sequencedActivity"
                      name="check-button"
                      switch
                    >
                      {{ translation.create_assignment.sequenced }}
                    </b-form-checkbox>
                    <tooltip
                      class="ml-2"
                      :left="true"
                      :text="translation.create_assignment.tooltip_sequenced"
                    />
                  </div>
                  <div v-if="form.sequencedActivity" class="d-flex mb-2">
                    <b-form-checkbox
                      class="toggle"
                      v-model="form.hasLogic"
                      name="check-button"
                      switch
                    >
                      {{ translation.create_task.logics.logic }}
                    </b-form-checkbox>
                  </div>
                  <dropdownButton
                    v-if="!loaderActivities && form.dateStart.length"
                    idDropdown="chained"
                    :text="translation.create_assignment.chained"
                    :options="activities"
                    :currentOptions="oldActivities"
                    @update="options => (form.selectedActivities = options)"
                  />
                  <div class="mt-4" v-else-if="form.dateStart.length">
                    <loader2 :color="$store.getters.theme[2]" />
                  </div>
                </div>
              </div>
            </div>
          </div>

          <b-modal
            @ok="editActivity"
            :cancel-title="translation.global.cancel"
            id="editActivity"
            :title="translation.global.edit"
          >
            {{ translation.create_task.edit_confirm_message }}
          </b-modal>
          <div class="d-flex justify-content-end px-4 pb-5">
            <button
              v-if="activityId === 'new'"
              :class="{ active: activityId === 'new' }"
              class="btn save-btn hover d-flex justify-content-center"
              @click="saveActivity"
            >
              <loader2 v-if="loader2" color="#ffffff" />
              <p class="m-0" v-if="!loader2">
                {{ translation.create_assignment.save_and_continue }}
              </p>
            </button>
            <button
              v-if="activityId !== 'new'"
              :class="{ active: activityId !== 'new' }"
              class="btn save-btn hover d-flex justify-content-center"
              @click="openModal"
            >
              <loader2 v-if="loader2" color="#ffffff" />
              <p class="m-0" v-if="!loader2">
                {{ translation.create_assignment.save_and_continue }}
              </p>
            </button>
          </div>
        </div>
        <div class="d-flex justify-content-center py-5" v-else>
          <loader />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { v4 as uuidv4 } from "uuid";
import { api } from "@/services.js";
import loader2 from "@/components/general/loader2.vue";
import loader from "@/components/general/loader.vue";
import { Datetime } from "vue-datetime";
import "vue-datetime/dist/vue-datetime.css";
import { Settings } from "luxon";
import imageCropper from "@/components/general/imageCropper.vue";
import characterCounter from "@/components/general/characterCounter.vue";
import dropdownButton from "@/components/general/dropdownButton.vue";
import tooltip from "@/components/general/tooltip.vue";
import lambda from "@/lambda";
import { regexpImageName, userTypesText } from "@/constants";
import { mapState } from "vuex";

export default {
  components: {
    Datetime,
    loader2,
    loader,
    imageCropper,
    characterCounter,
    tooltip,
    dropdownButton
  },
  data() {
    return {
      form: {
        name: "",
        dateStart: "",
        dateEnd: "",
        visibility: "1",
        selectedSegments: [],
        selectedActivities: [],
        activeActivity: false,
        sequencedActivity: false,
        hasLogic: false,
        img: "",
        imgName: ""
      },
      startAt: "",
      activities: [],
      oldActivities: [],
      segments: [],
      oldSegments: [],
      hasAnswers: false,
      showCropper: false,
      loaderInsertImg: false,
      loaderActivity: false,
      loaderSegments: false,
      loaderActivities: false,
      loader2: false,
      activityCanBeActivated: false,
      updatedLogic: false,
      regexpImageName,
      userTypesText
    };
  },
  filters: {
    capitalize(value) {
      if (!value) return "";
      value = value.toString();
      return value.charAt(0).toUpperCase() + value.slice(1);
    }
  },
  computed: {
    ...mapState(["imageUploaderFolder"]),
    minDateBeginAt() {
      const startAtDate = this.$store.getters.community.start_at.replace(
        /-/g,
        "/"
      );
      const startDate = new Date(startAtDate);
      const today = new Date();
      const date =
        today > startDate ? today.toISOString() : startDate.toISOString();

      return date;
    },
    minDateDeadLine() {
      const today = new Date();
      if (this.form.dateStart !== "") {
        const startDate = new Date(this.form.dateStart);
        return startDate > today ? this.form.dateStart : today.toISOString();
      } else {
        return today.toISOString();
      }
    },
    maxDateBeginAt() {
      const endAtDate = this.$store.getters.community.end_at.replace(/-/g, "/");
      const endDate = new Date(endAtDate);
      if (!this.form.dateEnd.length) return endDate.toISOString();
      return this.form.dateEnd;
    },
    maxDateDeadLine() {
      const endAtDate = this.$store.getters.community.end_at.replace(/-/g, "/");
      const endDate = new Date(endAtDate);
      return endDate.toISOString();
    },
    activityId() {
      return this.$route.query.a;
    },
    formDateStart() {
      return this.form.dateStart;
    },
    hasLogic() {
      return this.form.hasLogic;
    }
  },
  watch: {
    activityId() {
      if (this.activityId !== "new") {
        this.getActivity();
      } else {
        this.init();
      }
    },
    formDateStart() {
      this.getActivities();
    },
    hasLogic() {
      this.updatedLogic = true;
    }
  },
  methods: {
    setSegments(options) {
      this.form.selectedSegments = options;
      if (this.form.dateStart.length) this.getActivities();
    },
    getActivities() {
      this.loaderActivities = true;
      api
        .post(
          "activity/list-chains",
          {
            activityId: this.activityId !== "new" ? this.activityId : false,
            communityId: this.$store.getters.community.id,
            start_at: this.formatDate(this.form.dateStart),
            segments: this.form.selectedSegments.map(segment => segment.id)
          },
          {
            headers: {
              Authorization: `Bearer ${this.$store.getters.token}`
            }
          }
        )
        .then(r => {
          if (r.status === 200) {
            this.activities = r.data.activities;
          }
          this.loaderActivities = false;
        })
        .catch(() => {
          this.loaderActivities = false;
        });
    },
    insertImg(src) {
      this.loaderInsertImg = true;

      let arr = src.split(",");
      let mime = arr[0].match(/:(.*?);/)[1];
      let bstr = atob(arr[1]);
      let n = bstr.length;
      let u8arr = new Uint8Array(n);

      while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
      }

      this.form.imgName = `${uuidv4()}-${this.form.imgName.replace(
        this.regexpImageName,
        ""
      )}`;

      const imgFile = new File([u8arr], this.form.imgName, {
        type: mime
      });

      let folder = `tmp/images/${this.imageUploaderFolder}`;

      if (this.activityId !== "new")
        folder = `Assets/Communities/${this.$store.getters.community.id}`;

      lambda
        .getSignedURL(imgFile, folder, this.form.imgName)
        .then(url => {
          api
            .put(url, imgFile, {
              headers: {
                "Content-Type": imgFile.type
              }
            })
            .then(r => {
              this.form.img = r.config.url.split("?")[0];
              this.$refs.inputImg.value = "";
              this.showCropper = false;
              this.loaderInsertImg = false;
            })
            .catch(() => {
              this.$toast.error(
                this.translation.errors_global.something_went_wrong
              );
              this.loaderInsertImg = false;
            });
        })
        .catch(() => {
          this.$toast.error(
            this.translation.errors_global.something_went_wrong
          );
          this.loaderInsertImg = false;
        });
    },
    async uploadImg(element) {
      const file = document.querySelector(`#${element}`).files[0];
      if (file) {
        const toBase64 = file =>
          new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => resolve(reader.result);
            reader.onerror = error => reject(error);
          });
        this.form.img = await toBase64(file);
        this.form.imgName = file.name;
        this.showCropper = true;
      }
    },
    getSegments() {
      this.loaderSegments = true;
      api
        .get(`community/${this.$store.getters.community.id}/segments`, {
          headers: {
            Authorization: `Bearer ${this.$store.getters.token}`
          }
        })
        .then(r => {
          if (r.status === 200) {
            this.loaderSegments = false;
            this.segments = r.data.filter(segment => +segment.is_active);
          }
        })
        .catch(() => {
          this.loaderSegments = false;
        });
    },
    formatDate(d) {
      let date = new Date(d);
      let string = date
        .toLocaleString("en-US", {
          hour12: true,
          year: "numeric",
          month: "2-digit",
          day: "2-digit",
          minute: "2-digit",
          hour: "2-digit"
        })
        .toString();

      const crop1 = string.split(",");
      const crop2 = crop1[0].split("/");
      const crop3 = crop1[1].split(" ");
      return `${crop2[2]}-${crop2[0]}-${crop2[1]} ${crop3[1]}:00 ${crop3[2]}`;
    },
    saveActivity() {
      if (
        !this.loader2 &&
        this.form.name.length &&
        this.form.dateStart.length &&
        this.form.dateEnd.length
      ) {
        this.loader2 = true;

        let body = {
          name: this.form.name,
          start_at: this.formatDate(this.form.dateStart),
          end_at: this.formatDate(this.form.dateEnd),
          community_id: this.$store.getters.community.id,
          is_active: this.form.activeActivity ? 1 : 0,
          is_segmented: this.form.selectedSegments.map(segment => segment.id),
          is_sequenced: this.form.sequencedActivity ? 1 : 0,
          has_logic: this.form.hasLogic ? 1 : 0,
          is_chained: this.form.selectedActivities.map(activity => activity.id),
          visibility: this.form.visibility
        };

        if (this.form.img.length) {
          body.has_image = 1;
          body.image_folder = `tmp/images/${this.imageUploaderFolder}`;

          body.banner = {
            image: this.form.img,
            name: this.form.imgName
          };
        }

        api
          .post("activity", body, {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${this.$store.getters.token}`
            }
          })
          .then(r => {
            if (r.status === 200 && r.data.activity.id) {
              this.$toast.success(
                this.translation.create_assignment.success.assignment_created
              );

              this.$router.push({
                name: "createtask",
                query: { a: r.data.activity.id }
              });
            }
            this.loader2 = false;
          })
          .catch(() => {
            this.loader2 = false;
            this.$toast.error(
              this.translation.errors_global.something_went_wrong
            );
          });
      } else {
        this.$toast.error(this.translation.errors_global.fill_fields);
      }
    },
    editActivity() {
      if (!this.loader2) {
        if (
          this.form.name.length &&
          this.form.dateStart.length &&
          this.form.dateEnd.length
        ) {
          this.loader2 = true;

          let body = {
            name: this.form.name,
            end_at: this.formatDate(this.form.dateEnd),
            community_id: this.$store.getters.community.id,
            is_active: this.form.activeActivity ? 1 : 0,
            is_segmented: this.form.selectedSegments.map(segment => segment.id),
            is_sequenced: this.form.sequencedActivity ? 1 : 0,
            has_logic: this.form.hasLogic ? 1 : 0,
            is_chained: this.form.selectedActivities.map(
              activity => activity.id
            ),
            visibility: this.form.visibility
          };

          if (this.form.img.length) {
            body.has_image = 1;
            body.image_folder = `${this.imageUploaderFolder}`;
            body.banner = this.form.img;
          }

          if (this.startAt !== this.formatDate(this.form.dateStart))
            body.start_at = this.formatDate(this.form.dateStart);

          api
            .put(`activity/${this.activityId}`, body, {
              headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${this.$store.getters.token}`
              }
            })
            .then(r => {
              if (r.status === 200) {
                this.$toast.success(
                  this.translation.success_global.changes_saved
                );
                this.$router.push({
                  name: "createtask",
                  query: { a: this.activityId }
                });
              }
              this.loader2 = false;
            })
            .catch(() => {
              this.$toast.error(
                this.translation.errors_global.something_went_wrong
              );
              this.loader2 = false;
            });
        } else {
          this.$toast.error(this.translation.errors_global.fill_fields);
        }
      }
    },
    openModal() {
      const newSegments = this.form.selectedSegments.map(segment => segment.id);
      const oldSegments = this.oldSegments.map(segment => segment.id);

      const changedSegments = newSegments
        .filter(x => !oldSegments.includes(x))
        .concat(oldSegments.filter(x => !newSegments.includes(x))).length;

      this.hasAnswers && (changedSegments || this.updatedLogic)
        ? this.$bvModal.show("editActivity")
        : this.editActivity();
    },
    getActivity() {
      this.loaderActivity = true;
      api
        .get(`activity/${this.activityId}`, {
          headers: {
            Authorization: `Bearer ${this.$store.getters.token}`
          }
        })
        .then(r => {
          if (r.status === 200) {
            const startAtDate = r.data.start_at.replace(/-/g, "/");
            const endAtDate = r.data.end_at.replace(/-/g, "/");

            this.hasAnswers = r.data.has_answers;
            this.form.name = r.data.name;
            this.form.img = r.data.banner;
            this.form.dateStart = new Date(startAtDate).toISOString();
            this.form.dateEnd = new Date(endAtDate).toISOString();
            this.startAt = this.formatDate(this.form.dateStart);
            this.form.visibility = r.data.visibility.id;
            if (+r.data.is_active) this.form.activeActivity = true;
            if (+r.data.is_sequenced) this.form.sequencedActivity = true;
            if (+r.data.has_logic) this.form.hasLogic = true;

            if (r.data.segments) {
              this.oldSegments = r.data.segments
                .filter(segment => +segment.is_active)
                .map(segment => ({
                  id: segment.segment_id,
                  name: segment.segment,
                  is_active: segment.is_active
                }));
            }

            if (r.data.dependsOn)
              this.oldActivities = r.data.dependsOn.filter(
                activity => activity.id !== this.activityId
              );

            this.checkIfActivityCanBeActivated();
          }
          this.loaderActivity = false;
        })
        .catch(() => {
          this.loaderActivity = false;
        });
    },

    checkIfActivityCanBeActivated() {
      api
        .get(`activity/${this.activityId}/activate/status`, {
          headers: {
            Authorization: `Bearer ${this.$store.getters.token}`
          }
        })
        .then(r => {
          if (r.data.success) {
            this.activityCanBeActivated = true;
          }
        })
        .catch(() => {
          this;
        });
    },
    initImage() {
      this.$store.commit(
        "UPDATE_IMAGE_UPLOADER_FOLDER",
        `create-activity-image-${uuidv4()}`
      );
    },
    init() {
      this.initImage();
      Settings.defaultLocale = this.$store.getters.language
        ? this.$store.getters.language
        : "en";
      this.getSegments();
      if (this.activityId !== "new") {
        this.getActivity();
      } else {
        this.form = {
          name: "",
          dateStart: "",
          dateEnd: "",
          visibility: "1",
          selectedSegments: [],
          selectedActivities: [],
          activeActivity: false,
          sequencedActivity: false,
          hasLogic: false,
          img: "",
          imgName: ""
        };
        this.oldSegments = [];
        this.oldActivities = [];
      }
    }
  },
  created() {
    this.init();
  }
};
</script>

<style lang="scss" scoped>
.card-b {
  position: relative;
}

.new-activity-header {
  position: relative;
  height: 200px;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  width: 100%;
  cursor: pointer;
  background: url(../img/image.svg) no-repeat center;
  background-size: contain;

  input {
    display: none;
  }
  .img {
    display: flex;
    justify-content: center;
    align-items: center;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    display: flex;
    justify-content: center;
    height: 200px;
    overflow: hidden;
    img {
      object-fit: cover;
      width: 1110px;
      height: 200px;
    }
  }
}

.add-img {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 55px;
  height: 55px;
  position: absolute;
  bottom: -2px;
  left: 15%;
  z-index: 100;
  background: #fff;
  font-size: 3rem;
  color: #999;
  border-radius: 50%;
  box-shadow: 0px 0px 15px 0px rgba(123, 123, 123, 0.35);
}

.style {
  display: flex;
  position: absolute;
  top: 172px;
  height: 30px;
  width: 100%;
  img {
    width: 100%;
  }
}

@media (max-width: 991.98px) {
  .style {
    top: 172px;
    height: 30px;
  }
}

.title {
  font-weight: bold;
  font-size: 1.3rem;
}

.name-input {
  position: relative;
  display: flex;
  align-items: center;
  input {
    width: 100%;
    border: none;
    font-weight: bold;
    font-size: 1.3rem;
    padding: 5px 30px 2px 0px;
    border-bottom: 1px solid #e6e6e6;
    transition: all 0.3s ease;
    position: relative;
    z-index: 100;
    background: rgba(0, 0, 0, 0);
    color: #6b6b6b;
    &::-webkit-input-placeholder {
      color: #ddd;
    }
    &::-moz-placeholder {
      color: #ddd;
    }
    &:focus {
      outline: none;
      border-bottom: 1px solid #6b6b6b;
    }
  }
  svg {
    position: absolute;
    right: 10px;
    color: #6b6b6b;
  }
}

.calendar {
  height: 18px;
  position: absolute;
}

.date-input {
  position: relative;
  border-bottom: 1px solid #e6e6e6;
  color: #6b6b6b;

  > div {
    display: flex;
    div {
      width: 50%;
      color: #b3b3b3;
      border: none;
      label {
        font-size: 0.8rem;
      }
      &:nth-of-type(2) {
        position: relative;
      }
    }
  }
}

@media (max-width: 767.98px) {
  .date-input {
    img {
      display: none;
    }
  }
}

.radio-input {
  font-size: 1rem;
  color: #6b6b6b;
}

.toggle {
  color: #6b6b6b;
}

.save-btn {
  text-align: center;
  text-transform: uppercase;
  color: #ffffff;
  font-weight: bold;
  background: #b3b3b3;
  border-radius: 17px;
  border: none;
  padding: 5px 20px;
  min-width: 210px;
}

.btn-chained {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 30px;
  border-radius: 17px;
  text-transform: uppercase;
  font-weight: bold;
  color: #fff;
  font-size: 0.9rem;
  white-space: nowrap;
  background: #b3b3b3;
  svg {
    font-size: 1.2rem !important ;
    margin-left: 5px;
    transition: all 0.3s ease;
  }
  &.active {
    svg {
      transform: rotate(-90deg);
    }
  }
}

.chained-box {
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
  background-color: #fff;
  border-radius: 7px;
  padding-right: 5px;
  display: none;
  animation: grow 400ms ease-in-out forwards;
  &.active {
    display: block;
  }
  div {
    overflow-y: auto;
    max-height: 200px;
    &::-webkit-scrollbar {
      width: 8px;
    }
    &::-webkit-scrollbar-track {
      background: rgba(0, 0, 0, 0);
      border-radius: 10px;
    }
    &::-webkit-scrollbar-thumb {
      background: #e6e6e6;
      border-radius: 10px;
      height: 40px;
    }
    &::-webkit-scrollbar-thumb:hover {
      background: #ffffff;
    }
  }
  a {
    display: flex;
    align-items: center;
    padding: 2px 10px;
    margin: 2px 5px 2px 0;
    text-transform: uppercase;
    color: #6b6b6b;
    font-size: 0.7rem;
    cursor: pointer;
    text-decoration: none;
    &::before {
      content: "";
      display: inline-block;
      width: 10px;
      height: 10px;
      border: 2px solid #fff;
      border-radius: 50%;
      margin-right: 6px;
      box-shadow: 0px 0px 1px 0px #6b6b6b;
      transition: all 0.3s ease;
    }
    &:hover {
      background: rgba(0, 0, 0, 0.05);
    }
    &.active {
      background: rgba(0, 0, 0, 0.05);
      &::before {
        background: #6b6b6b;
      }
    }
  }
}

.warn-text {
  color: #df2938;
  font-size: 0.9rem;
}
</style>
