<template>
  <div class="row task-wrapper">
    <div v-if="!loading" class="col">
      <div class="task-text img-question">
        <p v-html="text"></p>
      </div>
      <div class="d-flex flex-wrap">
        <attachment :attachments="task.attachments" :deleteFile="null" />
      </div>
      <div>
        <div class="slider mb-5 mt-5 mt-lg-4">
          <div
            class="
              d-flex
              flex-column flex-lg-row
              align-items-center
              justify-content-center
            "
          >
            <div
              :style="{
                'border-color': '#ccc'
              }"
              class="item px-3 py-2"
              v-for="(dot, index) in slider"
              :key="index"
            >
              <p class="text">{{ dot.text }}</p>

              <div
                @click="toggleDot(index)"
                class="dot"
                :style="{
                  background: dot.value ? $store.getters.theme[2] : '#ccc',
                  ['box-shadow']: dot.value
                    ? `0px 0px 8px rgba(100, 100, 100, 0.25)`
                    : 'none'
                }"
              ></div>
            </div>
          </div>
        </div>
        <div class="comments">
          <label
            for="comments"
            v-if="
              task.question_object.has_custom_comment_label &&
                task.question_object.comment_label &&
                task.question_object.comment_label.length
            "
            >{{ task.question_object.comment_label }}</label
          >
          <label for="comments" v-else>
            {{ translation.answer_task.comments }}
          </label>
          <textarea
            class="form-control"
            id="textarea"
            v-model="comments"
            :maxlength="maxCharacters"
            rows="3"
            max-rows="6"
            :disabled="task.is_answered"
          ></textarea>
          <characterAndWordCounter
            :count="
              hasWordsLimitation
                ? this.comments.length
                  ? this.comments.trim().split(/\s+/).length
                  : 0
                : comments.length
            "
            :max="hasWordsLimitation ? maxWords : maxCharacters"
            :label="
              hasWordsLimitation
                ? translation.answer_task.words
                : translation.answer_task.characters
            "
          />
        </div>
      </div>
    </div>
    <div v-if="loading" class="w-100 d-flex justify-content-center">
      <loader />
    </div>
  </div>
</template>

<script>
import { api } from "@/services.js";
import loader from "@/components/general/loader.vue";
import attachment from "@/components/general/attachment.vue";
import characterAndWordCounter from "@/components/general/characterAndWordCounter.vue";

import { userTypesText } from "@/constants";

export default {
  components: { loader, attachment, characterAndWordCounter },
  props: {
    task: {
      required: true
    },
    save: {
      required: true
    },
    savePreview: {
      required: true
    },
    getTasks: {
      required: true
    }
  },
  data() {
    return {
      comments: "",
      question: "",
      slider: [],
      loading: false
    };
  },

  computed: {
    text() {
      const regexpVideo = /(?:(<iframe.*?src="))(.*?)((?:".*?>))((?:<\/iframe>))?/g;

      return this.$store.getters.info.user.type === userTypesText.participant
        ? this.task.raw_question.replace(
            regexpVideo,
            "<video src='$2' width='350' height='197' frameborder='0' allowfullscreen='allowfullscreen' controls controlsList='nodownload'></iframe>"
          )
        : this.task.raw_question;
    },
    sliderArray() {
      return this.task.question_object.points;
    },
    page() {
      return this.$store.getters.activeTaskIndex;
    },
    obligatoryComment() {
      return this.task.question_object?.has_obligatory_comments;
    },

    hasCharactersLimitation() {
      return this.task.question_object?.has_characters_limitation;
    },

    maxCharacters() {
      return this.hasCharactersLimitation
        ? this.task.question_object?.characters_count?.maximum
        : 5000;
    },

    minCharacters() {
      return this.hasCharactersLimitation
        ? this.task.question_object?.characters_count?.minimum
        : 0;
    },

    hasWordsLimitation() {
      return this.task.question_object?.has_words_limitation;
    },

    maxWords() {
      return this.hasWordsLimitation
        ? this.task.question_object?.words_count?.maximum
        : 2000;
    },

    minWords() {
      return this.hasWordsLimitation
        ? this.task.question_object?.words_count?.minimum
        : 0;
    }
  },
  watch: {
    save() {
      this.answer();
    },
    savePreview() {
      this.answerPreview();
    },
    page() {
      this.init();
    },
    comments() {
      if (this.comments.length >= this.maxCharacters) {
        this.comments = this.comments
          .split("")
          .splice(0, this.maxCharacters)
          .join("");
      }

      const words = this.comments.split(/\s+/);

      const wordsCount = words.length;

      if (wordsCount > this.maxWords) {
        this.comments = words.splice(0, this.maxWords).join(" ");
      }
    }
  },
  methods: {
    answerPreview() {
      if (!this.loading) {
        if (this.slider.filter(dot => dot.value).length) {
          if (this.obligatoryComment && !this.comments.length) {
            this.$toast.error(
              this.translation.answer_task.obligatory_comment_tost
            );

            return;
          }

          if (
            this.comments.length &&
            this.hasCharactersLimitation &&
            this.comments.length < this.minCharacters
          ) {
            this.$toast.error(
              `${this.translation.answer_task.min_characters} ${this.minCharacters} ${this.translation.answer_task.characters}`
            );

            return;
          }

          if (this.comments.length && this.hasWordsLimitation) {
            const wordsCount = this.comments.split(/\s+/).length;

            if (wordsCount < this.minWords) {
              this.$toast.error(
                `${this.translation.answer_task.min_word} ${this.minWords} ${this.translation.answer_task.words}`
              );

              return;
            }
          }

          this.loading = true;

          const answer = {
            comment: this.comments,
            options: this.slider.map((dot, index) => ({
              index: index,
              value: dot.text,
              marked: dot.value ? 1 : 0
            }))
          };

          const body = {
            task_id: this.task.id,
            has_attachment: 0,
            has_video: 0,
            has_image: 0,
            raw_answer: JSON.stringify(answer),
            answer
          };

          api
            .post("/answer/send/preview", body, {
              headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${this.$store.getters.token}`
              }
            })
            .then(r => {
              if (r.status === 200) {
                this.$toast.success(
                  this.translation.answer_task.success.reply_registered
                );
                this.getTasks("save");
                this.loading = false;
              }
            })
            .catch(() => {
              this.$toast.error(
                this.translation.answer_task.error.reply_not_registered
              );
              this.loading = false;
            });
        } else {
          this.$toast.error(this.translation.answer_task.error.empty_answer);
        }
      }
    },
    answer() {
      if (!this.loading) {
        if (this.slider.filter(dot => dot.value).length) {
          if (this.obligatoryComment && !this.comments.length) {
            this.$toast.error(
              this.translation.answer_task.obligatory_comment_tost
            );

            return;
          }

          if (
            this.comments.length &&
            this.hasCharactersLimitation &&
            this.comments.length < this.minCharacters
          ) {
            this.$toast.error(
              `${this.translation.answer_task.min_characters} ${this.minCharacters} ${this.translation.answer_task.characters}`
            );

            return;
          }

          if (this.comments.length && this.hasWordsLimitation) {
            const wordsCount = this.comments.split(/\s+/).length;

            if (wordsCount < this.minWords) {
              this.$toast.error(
                `${this.translation.answer_task.min_word} ${this.minWords} ${this.translation.answer_task.words}`
              );

              return;
            }
          }

          this.loading = true;

          const answer = {
            comment: this.comments,
            options: this.slider.map((dot, index) => ({
              index: index,
              value: dot.text,
              marked: dot.value ? 1 : 0
            }))
          };

          const body = {
            task_id: this.task.id,
            participant_id: this.$store.getters.info.user.sub,
            has_attachment: 0,
            has_video: 0,
            has_image: 0,
            raw_answer: JSON.stringify(answer),
            answer
          };

          api
            .post("/answer/send", body, {
              headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${this.$store.getters.token}`
              }
            })
            .then(r => {
              if (r.status === 200) {
                this.$toast.success(
                  this.translation.answer_task.success.reply_registered
                );
                this.getTasks("save");
                this.loading = false;
              }
            })
            .catch(() => {
              this.$toast.error(
                this.translation.answer_task.error.reply_not_registered
              );
              this.loading = false;
            });
        } else {
          this.$toast.error(this.translation.answer_task.error.empty_answer);
        }
      }
    },
    toggleDot(index) {
      if (!this.task.is_answered) {
        if (this.slider[index].value) {
          this.slider[index].value = false;
        } else {
          this.slider.forEach(dot => (dot.value = false));
          this.slider[index].value = true;
        }
      }
    },
    init() {
      this.slider = [];
      if (
        this.task.is_answered ||
        (this.task.answer && this.$route.query._preview)
      ) {
        this.comments = this.task.answer.answer_object.comment;
        this.slider = this.task.answer.answer_object.options.map(option => ({
          value: option.marked ? true : false,
          text: option.value
        }));
      } else {
        this.comments = "";
        this.sliderArray.forEach(dot => {
          this.slider.push({
            value: false,
            text: dot.value
          });
        });
      }
    }
  },
  created() {
    this.init();
  },
  mounted() {
    if (this.$store.getters.info.user.type === userTypesText.participant) {
      const taskWrapper = document.querySelector(".task-wrapper");

      taskWrapper.addEventListener("contextmenu", e => {
        e.preventDefault();
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.task-text {
  display: flex;
  flex-direction: column;
  color: #6b6b6b;
  overflow-wrap: break-word;
}

.select-box {
  display: inline-block;
  padding: 20px;
  border-radius: 20px;
  color: #6b6b6b;
  font-weight: bold;
  font-size: 1.2rem;
}

.comments {
  label {
    color: #6b6b6b;
  }
  textarea {
    width: 100%;
    display: block;
    border: 1px solid #b3b3b3;
    border-radius: 10px;
  }
}

.custom-control-label {
  color: red;
}

.slider {
  color: #6b6b6b;
  > div {
    height: 130px;
    overflow: auto;
    &::-webkit-scrollbar {
      height: 8px;
    }
    &::-webkit-scrollbar-track {
      background: rgba(0, 0, 0, 0);
      border-radius: 10px;
    }
    &::-webkit-scrollbar-thumb {
      background: #ccc;
      border-radius: 10px;
      height: 40px;
    }
    &::-webkit-scrollbar-thumb:hover {
      background: #aaa;
    }
    .item {
      flex: 1;
      text-align: center;
      position: relative;
      border-bottom: 2px solid;
      .text {
        white-space: nowrap;
        font-size: 1.125rem;
        height: 27px;
      }
      .dot {
        transition: all 0.3s ease-in-out;
        position: absolute;
        display: block;
        height: 20px;
        width: 20px;
        border-radius: 50%;
        left: calc(50% - 10px);
        bottom: -10px;
        cursor: pointer;
      }
    }
  }
}

@media (max-width: 991.98px) {
  .slider {
    > div {
      height: unset;
      .item {
        border-bottom: none;
        border-left: 2px solid;
        .text {
          padding-left: 20px;
          white-space: unset;
          width: 175px;
        }
        .dot {
          left: -10px;
          bottom: calc(50% - 2.5px);
        }
      }
    }
  }
}
</style>
