var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"user-profile py-5"},[_c('div',{staticClass:"profile-img",style:({
      color: _vm.$store.getters.theme[3]
    })},[(_vm.$store.getters.info.user.picture)?_c('img',{attrs:{"src":_vm.$store.getters.info.user.picture,"alt":"Profile Picture"}}):_c('b-icon',{staticClass:"icon-profile",attrs:{"icon":"person-fill","aria-hidden":"true"}})],1),_c('div',{staticClass:"info"},[_c('p',{style:({
        color: _vm.$store.getters.theme[3]
      })},[_vm._v(" "+_vm._s(_vm.$store.getters.info.user.name)+" ")]),_c('p',[_vm._v(" "+_vm._s(_vm.$store.getters.info.user.type === _vm.userTypesText.admin ? _vm.translation.chat.admin : _vm.userType === _vm.userTypes.moderator ? _vm.translation.chat.moderator : _vm.translation.chat.observer)+" ")])]),_c('div',{staticClass:"btns mt-4"},[(
        _vm.$store.getters.info.user.type === _vm.userTypesText.admin ||
          _vm.$store.getters.info.user.type === _vm.userTypesText.moderator
      )?_c('button',{staticClass:"btn pl-md-5",class:{ active: _vm.userType === _vm.userTypes.participant },style:({
        color:
          _vm.userType === _vm.userTypes.participant ? _vm.$store.getters.theme[2] : ''
      }),on:{"click":function($event){(_vm.userType = _vm.userTypes.participant),
          _vm.$store.commit('UPDATE_SHOW_TRANSMISSION_LIST', false)}}},[_vm._v(" "+_vm._s(_vm.translation.chat.participant)+" "),_c('span',{staticClass:"target",class:{ active: _vm.userType === _vm.userTypes.participant },style:({
          color:
            _vm.userType === _vm.userTypes.participant ? _vm.$store.getters.theme[2] : ''
        })})]):_vm._e(),(
        (_vm.$store.getters.info.user.type === _vm.userTypesText.admin ||
          _vm.$store.getters.info.user.type === _vm.userTypesText.moderator) &&
          _vm.userType === _vm.userTypes.participant
      )?_c('a',{class:{ active: _vm.showTransmissionList },on:{"click":function($event){_vm.$store.commit('UPDATE_SHOW_TRANSMISSION_LIST', true),
          _vm.$store.commit('UPDATE_ACTIVE_TRANSMISSION_LIST_ID', null)}}},[_vm._v(" "+_vm._s(_vm.translation.chat.transmission_list)+" ")]):_vm._e(),_c('button',{staticClass:"btn pl-md-5",class:{ active: _vm.userType === _vm.userTypes.moderator },style:({
        color: _vm.userType === _vm.userTypes.moderator ? _vm.$store.getters.theme[2] : ''
      }),on:{"click":function($event){(_vm.userType = _vm.userTypes.moderator),
          _vm.$store.commit('UPDATE_SHOW_TRANSMISSION_LIST', false)}}},[_vm._v(" "+_vm._s(_vm.translation.chat.moderator)+" "),_c('span',{staticClass:"target",class:{ active: _vm.userType === _vm.userTypes.moderator },style:({
          color:
            _vm.userType === _vm.userTypes.moderator ? _vm.$store.getters.theme[2] : ''
        })})]),_c('button',{staticClass:"btn pl-md-5",class:{ active: _vm.userType === _vm.userTypes.admin },style:({
        color: _vm.userType === _vm.userTypes.admin ? _vm.$store.getters.theme[2] : ''
      }),on:{"click":function($event){(_vm.userType = _vm.userTypes.admin),
          _vm.$store.commit('UPDATE_SHOW_TRANSMISSION_LIST', false)}}},[_vm._v(" "+_vm._s(_vm.translation.chat.admin)+" "),_c('span',{staticClass:"target",class:{ active: _vm.userType === _vm.userTypes.admin },style:({
          color: _vm.userType === _vm.userTypes.admin ? _vm.$store.getters.theme[2] : ''
        })})]),_c('button',{staticClass:"btn pl-md-5",class:{ active: _vm.userType === _vm.userTypes.observer },style:({
        color: _vm.userType === _vm.userTypes.observer ? _vm.$store.getters.theme[2] : ''
      }),on:{"click":function($event){(_vm.userType = _vm.userTypes.observer),
          _vm.$store.commit('UPDATE_SHOW_TRANSMISSION_LIST', false)}}},[_vm._v(" "+_vm._s(_vm.translation.chat.observer)+" "),_c('span',{staticClass:"target",class:{ active: _vm.userType === _vm.userTypes.observer },style:({
          color:
            _vm.userType === _vm.userTypes.observer ? _vm.$store.getters.theme[2] : ''
        })})])])])}
var staticRenderFns = []

export { render, staticRenderFns }