<template>
  <div class="text-evaluation-answer">
    <p v-html="answer.raw_answer"></p>
    <p v-if="answer.answer_object && answer.answer_object.comment.length">
      <span :style="{ color: $store.getters.theme[3] }" class="comments">
        {{ translation.answer_task.comments }}
      </span>
      {{ answer.answer_object.comment }}
    </p>
  </div>
</template>

<script>
export default {
  props: {
    answer: {
      required: true
    }
  }
};
</script>

<style lang="scss">
.text-evaluation-answer {
  p {
    &:nth-of-type(1) {
      span {
        word-wrap: break-word;
        word-break: break-word;
        padding: 0 5px;
        border-radius: 5px;
      }
    }
  }
}

.comments {
  margin-top: 20px;
  display: block;
  text-transform: uppercase;
  font-weight: bold;
}
</style>
