<template>
  <div>
    <p>
      <span class="option" v-for="option in surveyData" :key="option.text">
        {{ option.text }}
      </span>
    </p>
    <p v-if="answer.answer_object.is_other_marked">
      <span class="font-weight-bold">
        {{ translation.answer_task.survey.other }}:
      </span>
      {{ answer.answer_object.other_answer }}
    </p>
    <p
      v-if="
        answer.answer_object &&
          answer.answer_object.comment &&
          answer.answer_object.comment.length
      "
    >
      <span :style="{ color: $store.getters.theme[3] }" class="comments">
        {{ translation.answer_task.comments }}
      </span>
      {{ answer.answer_object.comment }}
    </p>
  </div>
</template>

<script>
export default {
  props: {
    answer: {
      required: true
    }
  },
  computed: {
    surveyData() {
      const array = this.answer.answer_object.options.filter(
        option => option.marked
      );

      if (this.answer.answer_object.is_other_marked)
        array.push({
          text: this.translation.answer_task.survey.other,
          marked: 1
        });

      if (this.answer.answer_object.is_none_marked)
        array.push({
          text: this.translation.answer_task.survey.none,
          marked: 1
        });
      return array;
    }
  }
};
</script>

<style lang="scss" scoped>
.option {
  display: block;
}

.comments {
  margin-top: 20px;
  display: block;
  text-transform: uppercase;
  font-weight: bold;
}
</style>
