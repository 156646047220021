<template>
  <div class="container">
    <div class="card-b mt-5 mb-4 p-5">
      <div v-if="logsVoicesInitial.length && !loaderLogs" class="table-wrapper">
        <h2 :style="{ color: $store.getters.theme[3] }">
          {{ translation.header.logs }}
        </h2>
        <div class="table mt-5">
          <div :style="{ color: $store.getters.theme[3] }" class="table-header">
            <div class="d-flex align-itens-center" id="dropdown-data">
              <label
                :style="{ color: $store.getters.theme[3] }"
                @click="handleDrop('dropdown-data')"
                class="font-weight-bold"
              >
                {{ translation.logs.date }}
                <b-icon
                  icon="caret-down-fill"
                  class="icon"
                  :style="{ color: $store.getters.theme[3] }"
                ></b-icon>
              </label>
              <div id="dropdownData">
                <div class="p-2 form-radio">
                  <b-form-group
                    class="m-0 font-weight-bold"
                    :label="`${translation.global.order_by}:`"
                    v-slot="{ ariaDescribedby }"
                  >
                    <b-form-radio
                      v-model="order"
                      :aria-describedby="ariaDescribedby"
                      name="order-radios"
                      value="sort"
                      >{{ translation.logs.oldest }}</b-form-radio
                    >
                    <b-form-radio
                      v-model="order"
                      :aria-describedby="ariaDescribedby"
                      name="order-radios"
                      value="reverse"
                      >{{ translation.logs.newest }}</b-form-radio
                    >
                  </b-form-group>
                </div>
              </div>
            </div>
            <div>
              <div class="d-flex align-itens-center" id="dropdown-email">
                <label
                  :style="{ color: $store.getters.theme[3] }"
                  @click="handleDrop('dropdown-email')"
                  class="font-weight-bold"
                >
                  {{ translation.email.email_text }}
                  <b-icon
                    icon="caret-down-fill"
                    class="icon"
                    :style="{ color: $store.getters.theme[3] }"
                  ></b-icon>
                </label>
                <div id="dropdownEmail">
                  <b-form-input
                    class="search mt-2 mb-3"
                    v-model="search"
                    size="sm"
                    :placeholder="translation.global.search"
                  ></b-form-input>

                  <span id="all-checkbox" v-if="logsOptions.length > 1">
                    <b-form-checkbox v-model="all">
                      <span>
                        {{ translation.replies.all }}
                      </span>
                    </b-form-checkbox>
                  </span>

                  <div v-if="logsOptionsTemp.length" class="d-block">
                    <b-form-group class="check">
                      <b-form-checkbox-group
                        v-model="data"
                        :options="logsOptionsTemp"
                        :name="translation.replies.assignments"
                        stacked
                      >
                      </b-form-checkbox-group>
                    </b-form-group>
                  </div>
                  <div
                    v-else-if="!logsOptionsTemp.length"
                    class="
                      d-flex
                      justify-content-center
                      text-center
                      py-3
                      notFound
                    "
                  >
                    {{ translation.global.sorry_we_have }}
                    "{{ search }}"
                  </div>
                </div>
              </div>
            </div>
            <div class="font-weight-bold">
              {{ translation.logs.description }}
            </div>
          </div>
          <div
            class="table-content mt-2 p-1"
            v-for="(log, index) in logsVoicesTemp"
            :key="index"
          >
            <div>{{ log.createdAt | formatDate }}</div>
            <div
              :title="log.email"
              v-b-tooltip.hover="{ variant: 'secondary' }"
            >
              {{ log.email | maxLength }}
            </div>
            <div
              :title="log.actionDescription"
              v-b-tooltip.hover="{ variant: 'secondary' }"
            >
              {{ log.actionDescription | maxLengthDescription }}
            </div>
          </div>
        </div>
        <pagination-query
          v-if="minPerPage < total"
          :perPage="perPage"
          :total="total"
          :options="[10, 20, 50, 100]"
          @perPageUpdate="newPerPage => (perPage = newPerPage)"
        />
      </div>
      <div v-else-if="loaderLogs" class="d-flex justify-content-center py-5">
        <loader />
      </div>
      <div class="d-flex justify-content-center py-5" v-else>
        {{ translation.operators.manage_operators.no_operator }}
      </div>
    </div>
  </div>
</template>

<script>
import paginationQuery from "@/components/general/paginationQuery.vue";
import axios from "axios";
import loader from "@/components/general/loader.vue";
import { outsideClick } from "@/helpers.js";

export default {
  components: { paginationQuery, loader },
  data() {
    return {
      minPerPage: 10,
      perPage: 10,
      total: 0,
      logsVoicesInitial: [],
      logsVoices: [],
      logsVoicesTemp: [],
      loaderLogs: false,
      order: "none",
      logsOptions: [],
      logsOptionsTemp: [],
      data: [],
      all: false,
      search: "",
      outsideClick
    };
  },
  filters: {
    maxLength(value) {
      if (value.length > 25) {
        return (
          value
            .split("")
            .splice(0, 25)
            .join("") + "..."
        );
      } else return value;
    },
    maxLengthDescription(value) {
      if (value.length > 64) {
        return (
          value
            .split("")
            .splice(0, 64)
            .join("") + "..."
        );
      } else return value;
    },
    formatDate(value) {
      const date = new Date(value);
      return date.toLocaleString();
    }
  },
  computed: {
    page() {
      return +this.$route.query._page;
    }
  },
  watch: {
    data() {
      const isAllChecked = this.data.length === this.logsOptions.length;
      if (!isAllChecked) {
        this.all = false;
      }
      this.filterLogVoices();
    },
    all() {
      const isAllChecked = this.data.length === this.logsOptions.length;
      if (this.all) {
        this.data = this.logsOptions.map(option => option.text);
      } else if (isAllChecked) {
        this.data = [];
      }
    },
    page() {
      this.filterLogVoices();
    },
    perPage() {
      this.filterLogVoices();
    },
    order() {
      this.filterLogVoices();
    },
    search() {
      this.logsOptionsTemp = this.logsOptions.filter(item => {
        return this.search
          .toLowerCase()
          .split(" ")
          .every(v => item.text.toLowerCase().includes(v));
      });
    }
  },
  methods: {
    getLogs() {
      this.loaderLogs = true;
      axios
        .get(
          `${this.logs}${this.$store.getters.community.id}`,
          {},
          {
            headers: {}
          }
        )
        .then(r => {
          if (r.status === 200) {
            this.logsVoicesInitial = r.data;

            this.logsVoicesTemp = this.logsVoicesInitial.map(object => ({
              ...object
            }));

            this.logsOptions = this.logsVoicesInitial
              .filter(
                (value, index, array) =>
                  array.findIndex(item => item.email === value.email) === index
              )
              .map(item => ({
                value: item.email,
                text: item.email
              }));

            this.logsOptionsTemp = this.logsOptions.map(log => ({ ...log }));

            this.data = this.logsOptions.map(option => option.text);
            this.all = true;

            this.loaderLogs = false;
            this.order = "reverse";
          }
        })
        .catch(() => {
          this.loaderLogs = false;
        });
    },
    filterLogVoices() {
      const initial = this.logsVoicesInitial.map(object => ({
        ...object
      }));

      if (this.order !== "none") {
        this.logsVoicesTemp = initial.sort((a, b) => {
          const date1 = new Date(a.createdAt);
          const date2 = new Date(b.createdAt);
          return date1 > date2 ? 1 : -1;
        });

        if (this.order === "reverse") this.logsVoicesTemp.reverse();
      } else {
        this.logsVoicesTemp = initial;
      }

      this.logsVoicesTemp = this.logsVoicesTemp.filter(log => {
        return this.data.includes(log.email);
      });

      this.total = this.logsVoicesTemp.length;

      const start = this.perPage * (this.page - 1);
      this.logsVoicesTemp = this.logsVoicesTemp.filter(
        (log, index) => index >= start && index < start + this.perPage
      );
    },
    handleDrop(drop) {
      const dropItem = document.querySelector(`#${drop}`);
      dropItem.children[1].classList.toggle("active");

      this.outsideClick(dropItem, ["click"], () => {
        dropItem.children[1].classList.remove("active");
      });
    }
  },
  created() {
    this.getLogs();
  }
};
</script>

<style lang="scss" scoped>
.table-wrapper {
  overflow-x: auto;
  padding-bottom: 30px;
  h2 {
    font-weight: 700;
    font-size: 1.37rem;
  }
  &::-webkit-scrollbar {
    height: 8px;
    overflow: hidden;
    width: 8px;
  }
  &::-webkit-scrollbar-track {
    background: rgba(0, 0, 0, 0);
    border-radius: 4px;
  }
  &::-webkit-scrollbar-thumb {
    background: #ccc;
    border-radius: 4px;
    height: 10px;
  }
  &::-webkit-scrollbar-thumb:hover {
    background: #aaa;
  }
}

.table {
  width: 100%;
}

.table-header {
  min-width: 100%;
  display: flex;
  width: fit-content;
  div {
    min-width: 100px;
    flex: 1;
    display: flex;
    justify-content: center;
    &:nth-of-type(1) {
      position: relative;
      label {
        position: relative;
        cursor: pointer;
        .icon {
          font-size: 15px;
          position: absolute;
          top: 5px;
          right: -20px;
        }
      }
    }
    &:nth-of-type(2) {
      position: relative;
      label {
        position: relative;
        cursor: pointer;
        .icon {
          font-size: 15px;
          position: absolute;
          top: 5px;
          right: -20px;
        }
      }
    }
    &:nth-of-type(3) {
      flex: 2;
    }
  }
}

#dropdownData {
  display: none !important;
  position: absolute;
  top: 30px;
  background: #ffffff;
  box-shadow: 0px 4px 29px rgba(0, 0, 0, 0.1);
  border-radius: 11px;
  max-width: 130px !important;
  animation: grow 400ms ease-in-out forwards;
  transform-origin: top center;
  z-index: 5;
  &.active {
    display: flex !important;
  }
  > div {
    .custom-control,
    .custom-radio {
      display: flex;
      justify-content: flex-start;
      color: #212529;
      font-weight: normal;
    }
  }
}

#dropdown-data {
  &:hover {
    #dropdownData {
      display: flex !important;
    }
  }
}

.all-checkbox {
  display: flex !important;
  justify-content: flex-end !important;
}

#dropdownEmail {
  display: none !important;
  z-index: 5;
  position: absolute;
  top: 30px;
  padding: 10px;
  color: #212529;
  font-size: 0.81rem;
  background: #ffffff;
  box-shadow: 0px 4px 29px rgba(0, 0, 0, 0.1);
  border-radius: 11px;
  width: 220px;
  max-height: 200px;
  animation: grow 400ms ease-in-out forwards;
  transform-origin: top center;
  overflow-y: auto;
  &::-webkit-scrollbar {
    width: 8px;
  }
  &::-webkit-scrollbar-track {
    background: rgba(0, 0, 0, 0);
    border-radius: 10px;
  }
  &::-webkit-scrollbar-thumb {
    background: #e6e6e6;
    border-radius: 10px;
    height: 40px;
  }
  &::-webkit-scrollbar-thumb:hover {
    background: #ffffff;
  }
  &.active {
    display: block !important;
  }
  .bv-no-focus-ring {
    display: block !important;
  }
  .notFound {
    color: #212529;
  }
}

#dropdown-email {
  &:hover {
    #dropdownEmail {
      display: block !important;
    }
  }
}

.table-content {
  min-width: 100%;
  width: fit-content;
  display: flex;
  border-radius: 50px;
  border: 1px solid #999999;
  div {
    min-width: 100px;
    flex: 1;
    display: flex;
    justify-content: center;
    color: #6b6b6b;
    &:nth-of-type(3) {
      flex: 2;
    }
  }
  &.disable {
    border: 1px solid #cccccc;
    div,
    button {
      color: #cccccc !important;
    }
  }
}
@media (max-width: 976px) {
  .table-header {
    div {
      min-width: 200px;
      &:nth-of-type(3) {
        min-width: 500px;
      }
    }
  }
  .table-content {
    div {
      min-width: 200px;
      &:nth-of-type(3) {
        min-width: 500px;
      }
    }
  }

  #dropdownData {
    min-width: 130px !important;
    div {
      min-width: 130px;
    }
    .custom-control,
    .custom-radio {
      min-width: 130px;
    }
  }
  #dropdownEmail {
    min-width: 130px !important;
    div {
      min-width: 130px;
    }
    .custom-control,
    .custom-checkbox {
      min-width: 130px;
    }
  }
}
</style>
