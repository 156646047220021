var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"row task-wrapper",attrs:{"id":"task"}},[_c('b-modal',{attrs:{"ok-only":"","id":"popupBlocker"},scopedSlots:_vm._u([{key:"modal-title",fn:function(){return [_c('b-icon',{staticClass:"mr-1",attrs:{"icon":"exclamation-triangle"}})]},proxy:true}])},[_vm._v(" "+_vm._s(_vm.translation.answer_task.screen_recording.popup_blocker)+" ")]),(!_vm.loading)?_c('div',{staticClass:"col"},[_c('div',{staticClass:"task-text  img-question"},[_c('p',{domProps:{"innerHTML":_vm._s(_vm.text)}})]),_c('div',{staticClass:"d-flex flex-wrap mb-3"},[_c('attachment',{attrs:{"attachments":_vm.task.attachments,"deleteFile":null}})],1),_c('div',{staticClass:"d-flex justify-content-center py-4"},[_c('div',{staticClass:"select-box",style:({ background: _vm.$store.getters.theme[1] })},[_c('p',{staticClass:"mb-1"},[_vm._v(" *"+_vm._s(_vm.translation.answer_task.screen_recording.task_cannot_be_answered)+"* ")]),_c('div',{staticClass:"d-flex flex-column align-items-center"},[_c('p',{staticClass:"mb-1"},[_vm._v(" "+_vm._s(_vm.translation.answer_task.screen_recording .minimum_number_recordings)+": "+_vm._s(_vm.min)+" ")]),_c('p',{staticClass:"mb-0"},[_vm._v(" "+_vm._s(_vm.translation.answer_task.screen_recording .maximum_number_recordings)+": "+_vm._s(_vm.max)+" ")])])])]),_c('div',[_c('div',{staticClass:"video-wrapper"},[_c('div',{staticClass:"video-box"},[(!_vm.recording)?_c('p',[_vm._v(" "+_vm._s(_vm.translation.answer_task.screen_recording.task_instruction)+" ")]):_vm._e(),_c('video',{ref:"videoElem",attrs:{"autoplay":""}})]),_c('b-form-textarea',{attrs:{"placeholder":_vm.translation.answer_task.screen_recording.description,"disabled":_vm.task.is_answered},model:{value:(_vm.description),callback:function ($$v) {_vm.description=$$v},expression:"description"}}),_c('div',{staticClass:"btns-capture mt-3"},[_c('button',{staticClass:"btn",class:{
              hover: !_vm.task.is_answered && !_vm.recording && !_vm.loaderUploadVideo
            },attrs:{"disabled":_vm.task.is_answered || _vm.recording || _vm.loaderUploadVideo},on:{"click":_vm.startCapture}},[_vm._v(" "+_vm._s(_vm.translation.answer_task.screen_recording.start_recording)+" ")]),_c('button',{staticClass:"btn",class:{
              hover: !_vm.task.is_answered && _vm.recording
            },attrs:{"disabled":_vm.task.is_answered || !_vm.recording},on:{"click":_vm.stopCapture}},[_vm._v(" "+_vm._s(_vm.translation.answer_task.screen_recording.end_screen_recording)+" ")])])],1),_vm._l((_vm.videos),function(video,index){return _c('div',{key:index,staticClass:"videos my-5"},[_c('video',{attrs:{"src":video.url,"controlsList":_vm.$store.getters.info.user.type !== _vm.userTypesText.admin &&
            _vm.$store.getters.info.user.type !== _vm.userTypesText.moderator
              ? 'nodownload'
              : '',"controls":""}}),_c('div',[_c('div',{staticClass:"d-flex align-item-center justify-content-between"},[_c('p',{staticClass:"m-0"},[_vm._v(" "+_vm._s(_vm.translation.answer_task.screen_recording.recording)+" "+_vm._s(index + 1)+" ")]),_c('button',{staticClass:"btn mb-1",class:{ hover: !_vm.task.is_answered },attrs:{"disabled":_vm.task.is_answered},on:{"click":function($event){return _vm.removeVideo(index)}}},[_c('b-icon',{attrs:{"icon":"x"}})],1)]),_c('b-form-textarea',{staticClass:"form-control",attrs:{"placeholder":_vm.translation.answer_task.screen_recording.description,"disabled":_vm.task.is_answered},model:{value:(video.description),callback:function ($$v) {_vm.$set(video, "description", $$v)},expression:"video.description"}})],1)])})],2),(_vm.loaderUploadVideo)?_c('div',{staticClass:"d-flex justify-content-center my-5"},[_c('loader')],1):_vm._e(),_c('div',{staticClass:"comments mt-4"},[(
          _vm.task.question_object.has_custom_comment_label &&
            _vm.task.question_object.comment_label &&
            _vm.task.question_object.comment_label.length
        )?_c('label',{attrs:{"for":"comments"}},[_vm._v(_vm._s(_vm.task.question_object.comment_label))]):_c('label',{attrs:{"for":"comments"}},[_vm._v(" "+_vm._s(_vm.translation.answer_task.comments)+" ")]),_c('textarea',{directives:[{name:"model",rawName:"v-model",value:(_vm.comments),expression:"comments"}],staticClass:"form-control",attrs:{"id":"textarea","maxlength":_vm.maxCharacters,"rows":"3","max-rows":"6","disabled":_vm.task.is_answered},domProps:{"value":(_vm.comments)},on:{"input":function($event){if($event.target.composing){ return; }_vm.comments=$event.target.value}}}),_c('characterAndWordCounter',{attrs:{"count":_vm.hasWordsLimitation
            ? this.comments.length
              ? this.comments.trim().split(/\s+/).length
              : 0
            : _vm.comments.length,"max":_vm.hasWordsLimitation ? _vm.maxWords : _vm.maxCharacters,"label":_vm.hasWordsLimitation
            ? _vm.translation.answer_task.words
            : _vm.translation.answer_task.characters}})],1)]):_vm._e(),(_vm.loading)?_c('div',{staticClass:"w-100 d-flex justify-content-center"},[_c('loader')],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }