var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.answer.answer_object.audios)?_c('div',{staticClass:"pt-4"},_vm._l((_vm.answer.answer_object.audios),function(audio,index){return _c('div',{key:index,staticClass:"audio mb-3"},[_c('audio',{attrs:{"controls":"","controlsList":_vm.$store.getters.info.user.type !== _vm.userTypesText.admin &&
          _vm.$store.getters.info.user.type !== _vm.userTypesText.moderator
            ? 'nodownload'
            : ''}},[_c('source',{attrs:{"src":audio.url,"type":"audio/mpeg"}}),_vm._v(" Your browser does not support the audio tag. ")]),_c('p',[_vm._v(" "+_vm._s(_vm.formatBytes(audio.size))+" ")])])}),0):_vm._e(),(
      _vm.answer.answer_object &&
        _vm.answer.answer_object.comment &&
        _vm.answer.answer_object.comment.length
    )?_c('p',[_c('span',{staticClass:"comments",style:({ color: _vm.$store.getters.theme[3] })},[_vm._v(" "+_vm._s(_vm.translation.answer_task.comments)+" ")]),_vm._v(" "+_vm._s(_vm.answer.answer_object.comment)+" ")]):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }