<template>
  <div>
    <b-modal id="modal-warn-create-acc" hide-footer>
      <template #modal-title>
        {{
          subdomain === "nulab-nubank"
            ? translation.modal_welcome.nubank_title
            : translation.modal_welcome.default_title
        }}
        🥳
      </template>
      <div class="d-block text-center">
        <p>
          {{
            subdomain === "nulab-nubank"
              ? translation.modal_welcome.nubank
              : translation.modal_welcome.default
          }}
        </p>
      </div>
    </b-modal>
    <imageCropper
      :img="imgProfile"
      :show="showCropper"
      :width="155"
      :height="155"
      :loader="loaderInsertImg"
      @updateImg="img => insertImg(img)"
      @trash="close"
    />
    <router-link :to="{ name: 'login' }" class="logo">
      <img
        v-if="$store.getters.community"
        class="img-fluid "
        :src="$store.getters.community.logo"
        alt="Logo"
      />
      <img v-else src="@/img/logo-communities.png" alt="Logo" />
    </router-link>
    <div class="create">
      <form class="wrapper d-flex align-items-center py-5 flex-wrap">
        <div class="col-lg-6 px-lg-5 pt-5">
          <div class="px-lg-5 w-100">
            <div
              :style="{
                background: `${
                  $store.getters.theme ? $store.getters.theme[2] : '#108FC3'
                }`
              }"
              class="style"
            ></div>
            <h2>
              {{ translation.create_account.create_acc }}
            </h2>
            <p class="pt-3 text">
              {{ translation.create_account.fill_info }}
            </p>
            <div>
              <label for="name" class="pt-3 text margin-label d-block">
                {{ translation.create_account.email_text }}:
              </label>
              <div class="mt-1 input">
                <input
                  style="position: relative; z-index: 2;"
                  type="email"
                  class="form-control"
                  id="name"
                  v-model="email"
                  :disabled="hasEmail"
                />
              </div>
              <label for="name" class="pt-3 text margin-label d-block">
                {{ translation.create_account.name_text }}:
              </label>
              <div class="mt-1 input">
                <input class="input-hidden" type="text" name="username" />
                <input class="input-hidden " type="password" name="password" />

                <input
                  style="position: relative; z-index: 2;"
                  type="text"
                  class="form-control"
                  id="name"
                  v-model="name"
                />
              </div>

              <label for="password" class="pt-3 text margin-label d-block">
                {{ translation.create_account.password_text }}:
              </label>
              <div class="mt-1 input">
                <input
                  :type="eyePass ? 'text' : 'password'"
                  class="form-control pr-5"
                  id="password"
                  v-model="pass"
                />
                <div @click="eyePass = !eyePass">
                  <b-icon v-if="eyePass" icon="eye" aria-hidden="true"></b-icon>
                  <b-icon v-else icon="eye-slash" aria-hidden="true"></b-icon>
                </div>
              </div>
              <div
                :class="{
                  'border-success': matchRegexp,
                  'border-danger': !matchRegexp && pass.length
                }"
                class="box-pass text small mt-2 p-1"
              >
                {{ translation.errors_global.pwd_eight_characters }}
              </div>
              <label
                for="confirmepass"
                class="pt-3 pt-lg-3 text margin-label d-block"
              >
                {{ translation.create_account.confirm_pwd }}:
              </label>
              <div class="mt-1 input">
                <input
                  :type="eyeConfirmPass ? 'text' : 'password'"
                  class="form-control p-relative pr-5"
                  id="confirmepass"
                  v-model="confirmpass"
                  style="z-index: 2"
                />
                <div @click="eyeConfirmPass = !eyeConfirmPass">
                  <b-icon
                    v-if="eyeConfirmPass"
                    icon="eye"
                    aria-hidden="true"
                  ></b-icon>
                  <b-icon v-else icon="eye-slash" aria-hidden="true"></b-icon>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-lg-6 d-flex px-lg-5 pt-0 pt-lg-5">
          <div class="px-lg-5 w-100">
            <div class="profile-pic-wrapper">
              <p class="pt-3 text margin-p">
                {{ translation.create_account.profile_pic }}:
              </p>
              <label
                for="icon-create"
                class="profile-pic"
                :style="{
                  'background-color': `${
                    $store.getters.theme ? $store.getters.theme[0] : '#F7FAFA'
                  }`
                }"
              >
                <input
                  ref="inputImg"
                  @change="uploadImg('icon-create')"
                  id="icon-create"
                  class="d-none"
                  type="file"
                  accept="image/*"
                />
                <div class="img" v-if="imgProfile && imgProfile.length">
                  <img :src="imgProfile" />
                </div>
              </label>
            </div>

            <div v-if="termsAndPolicies">
              <div
                :class="{ 'red-text': redTextTerms }"
                class="terms-of-use mt-4"
              >
                <b-form-checkbox id="terms-of-use" v-model="acceptTermsOfUse">
                  {{ translation.terms_and_policies.i_accept_the_terms_os_use }}
                  <a class="hover" @click="$bvModal.show('terms-of-use')">
                    <span>
                      {{ translation.terms_and_policies.terms_of_use }}
                      <b-icon
                        v-if="redTextTerms"
                        icon="exclamation"
                        animation="cylon-vertical"
                        font-scale="1.2"
                      ></b-icon>
                    </span>
                  </a>
                </b-form-checkbox>
                <b-modal
                  @ok="acceptTermsOfUse = true"
                  :cancel-title="translation.global.cancel"
                  id="terms-of-use"
                  :title="translation.terms_and_policies.terms_of_use"
                >
                  <p
                    class="modal-terms-and-policies"
                    v-html="termsAndPolicies.terms"
                  ></p>
                </b-modal>
              </div>
              <div
                :class="{ 'red-text': redTextPrivacy }"
                class="terms-of-use mt-4"
              >
                <b-form-checkbox
                  modal-class="modalComment"
                  id="privacy-policy"
                  v-model="acceptPrivacyPolicy"
                >
                  {{
                    translation.terms_and_policies.i_accept_the_privacy_policy
                  }}
                  <a class="hover" @click="$bvModal.show('privacy-policy')">
                    <span>
                      {{ translation.terms_and_policies.privacy_policy }}
                      <b-icon
                        v-if="redTextPrivacy"
                        icon="exclamation"
                        animation="cylon-vertical"
                        font-scale="1.2"
                      ></b-icon>
                    </span>
                  </a>
                </b-form-checkbox>
                <b-modal
                  modal-class="modalComment"
                  @ok="acceptPrivacyPolicy = true"
                  :cancel-title="translation.global.cancel"
                  id="privacy-policy"
                  :title="translation.terms_and_policies.privacy_policy"
                >
                  <p
                    class="modal-terms-and-policies"
                    v-html="termsAndPolicies.privacy_policy"
                  ></p>
                </b-modal>
              </div>
            </div>

            <button
              :style="{
                background: `${
                  $store.getters.theme ? $store.getters.theme[0] : '#F7FAFA'
                }`
              }"
              type="submit"
              @click.prevent="create"
              class="btn mt-5 hover d-flex justify-content-center align-items-center"
            >
              <loader2 v-if="loader2" color="#6b6b6b" />
              <p v-if="!loader2" class="m-0">
                {{ translation.create_account.save_text }}
              </p>
            </button>
          </div>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import { v4 as uuidv4 } from "uuid";
import { api } from "@/services.js";
import lambda from "@/lambda";
import imageCropper from "@/components/general/imageCropper.vue";
import loader2 from "@/components/general/loader2.vue";
import jwt_decode from "jwt-decode";
import { regexpImageName } from "@/constants";
import { mapState } from "vuex";

export default {
  name: "CreateAccount",
  components: {
    loader2,
    imageCropper
  },
  data() {
    return {
      imgProfile: "",
      imgProfileName: "",
      showCropper: false,
      email: "",
      name: "",
      pass: "",
      confirmpass: "",
      loader2: false,
      eyePass: false,
      eyeConfirmPass: false,
      hasEmail: 0,
      loaderInsertImg: false,
      acceptTermsOfUse: false,
      acceptPrivacyPolicy: false,
      termsAndPolicies: null,
      passRegexp: /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[\W|_]).{8,}$/,
      redTextTerms: false,
      redTextPrivacy: false,
      regexpImageName,
      path: ""
    };
  },
  computed: {
    ...mapState(["imageUploaderFolder"]),
    matchRegexp() {
      let resp = false;
      if (this.pass.match(this.passRegexp)) resp = true;
      return resp;
    },
    subdomain() {
      const hostname = window.location.hostname.split(".");
      const subdomain = hostname[0];
      return subdomain;
    }
  },
  methods: {
    close() {
      this.showCropper = false;
      this.imgProfile = "";
      this.imgProfileName = "";
      this.$refs.inputImg.value = "";
    },
    insertImg(src) {
      this.loaderInsertImg = true;

      let arr = src.split(",");
      let mime = arr[0].match(/:(.*?);/)[1];
      let bstr = atob(arr[1]);
      let n = bstr.length;
      let u8arr = new Uint8Array(n);

      while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
      }

      const imgName = `${uuidv4()}-${this.imgProfileName.replace(
        this.regexpImageName,
        ""
      )}`;

      const imgFile = new File([u8arr], imgName, {
        type: mime
      });

      const folder = `tmp/images/${this.imageUploaderFolder}`;

      lambda
        .getSignedURL(imgFile, folder, imgName)
        .then(url => {
          api
            .put(url, imgFile, {
              headers: {
                "Content-Type": imgFile.type
              }
            })
            .then(r => {
              this.imgProfile = r.config.url.split("?")[0];
              this.imgProfileName = imgName;
              this.showCropper = false;
              this.loaderInsertImg = false;
              this.path = `${folder}/${imgName}`;
            })
            .catch(() => {
              this.$toast.error(
                this.translation.errors_global.something_went_wrong
              );
              this.loaderInsertImg = false;
            });
        })
        .catch(() => {
          this.$toast.error(
            this.translation.errors_global.something_went_wrong
          );
          this.loaderInsertImg = false;
        });
    },
    create() {
      this.redTextTerms = false;
      this.redTextPrivacy = false;
      const token = this.$route.query.t;

      if (!this.loader2) {
        this.loader2 = true;
        if (this.name.length && this.pass.length && this.confirmpass.length) {
          if (this.acceptTermsOfUse && this.acceptPrivacyPolicy) {
            if (this.pass === this.confirmpass) {
              let body = {
                name: this.name,
                password: this.pass,
                profile_picture: this.path
              };

              if (!this.hasEmail) body.email = this.email;

              if (this.pass.match(this.passRegexp)) {
                api
                  .post("participant", body, {
                    headers: {
                      "Content-Type": "application/json",
                      Authorization: `Bearer ${token}`
                    }
                  })
                  .then(r => {
                    if (r.status === 201) {
                      this.$store.commit("UPDATE_IS_LOGOUT", true);
                      this.$router.push({ name: "login" });
                      this.$toast.success(
                        this.translation.create_account.success
                          .acc_successfully_created
                      );
                    }
                    this.loader2 = false;
                  })
                  .catch(error => {
                    if (error.response.status === 409) {
                      this.$toast.error(
                        this.translation.create_account.error
                          .email_already_registered
                      );
                    } else {
                      this.$toast.error(
                        this.translation.errors_global.something_went_wrong
                      );
                    }
                    this.loader2 = false;
                  });
              } else {
                this.loader2 = false;
                this.$toast.error(
                  this.translation.errors_global.pwd_eight_characters
                );
              }
            } else {
              this.loader2 = false;
              this.$toast.error(
                this.translation.errors_global.invalid_combination
              );
            }
          } else {
            this.loader2 = false;
            this.redTextTerms = !this.acceptTermsOfUse;
            this.redTextPrivacy = !this.acceptPrivacyPolicy;
          }
        } else {
          this.loader2 = false;
          this.$toast.error(this.translation.errors_global.fill_fields);
        }
      }
    },
    async uploadImg(element) {
      const file = document.querySelector(`#${element}`).files[0];
      if (file) {
        const toBase64 = file =>
          new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => resolve(reader.result);
            reader.onerror = error => reject(error);
          });
        this.imgProfile = await toBase64(file);
        this.imgProfileName = file.name;
        this.showCropper = true;
      }
    },
    getTermsOfUse(id) {
      api.get(`community/${id}/privacy-policy-terms`).then(r => {
        if (r.status === 200) this.termsAndPolicies = r.data;
      });
    },
    async isNew() {
      const token = this.$route.query.t;
      let info;
      try {
        info = await jwt_decode(token);
      } catch (error) {
        info = null;
      }

      if (info) {
        this.getTermsOfUse(info.community_id);
        this.hasEmail = info.email;

        if (this.hasEmail) {
          this.email = info.email;
          api.get(`participant/attempt/${info.email}`).then(r => {
            if (r.status === 200) {
              if (info.updateToNewCommunity) {
                api
                  .post(
                    "participant",
                    {},
                    {
                      headers: {
                        Authorization: `Bearer ${token}`
                      }
                    }
                  )
                  .then(r => {
                    if (r.status === 200) {
                      this.$store.commit("UPDATE_IS_LOGOUT", true);
                      this.$router.push({
                        name: "login",
                        query: { redirect: 1 }
                      });
                    }
                  })
                  .catch(() => {
                    this.$store.commit("UPDATE_IS_LOGOUT", true);
                    this.$router.push({
                      name: "login",
                      query: { redirect: 2 }
                    });
                  });
              } else {
                this.$store.commit("UPDATE_IS_LOGOUT", true);
                this.$router.push({ name: "login", query: { redirect: 1 } });
              }
            }
          });
        }
      } else {
        this.$router.push({ name: "login" });
      }
    },
    initImage() {
      this.$store.commit(
        `create-account-${uuidv4()}`,
        `send-answer-image-${uuidv4()}`
      );
    },
    init() {
      this.initImage();
      this.isNew();
    }
  },
  beforeCreate() {
    this.$store.dispatch("logOut");
  },
  created() {
    this.init();
  },
  mounted() {
    this.$bvModal.show("modal-warn-create-acc");
  }
};
</script>
<style lang="scss" scoped>
.create {
  min-height: calc(100vh - 150px);
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.logo {
  margin-top: 30px;
  margin-left: 30px;
  display: flex;
  img {
    max-height: 55px;
  }
}

@media (max-width: 991.98px) {
  .logo {
    margin-top: 20px;
    margin-left: 20px;
  }
}

.wrapper {
  height: 100%;
}

.col-lg-6 {
  display: flex;
  position: relative;
}

@media (max-width: 991.98px) {
  .col-lg-6 {
    &:nth-of-type(2) {
      margin-top: -30px;
    }
  }
}

.btn {
  height: 50px;
}

.style {
  display: block;
  width: 31px;
  height: 4px;
  border-radius: 4px;
  background: #639696;
  margin-bottom: 8px;
}

h2 {
  font-size: 1.3rem;
  color: #6b6b6b;
  font-weight: bold;
}

.text {
  color: #6b6b6b;
  font-size: 0.875rem;
}

.profile-pic-wrapper {
  height: 234px;
}

.profile-pic {
  position: relative;
  height: 156px;
  width: 155px;
  border-radius: 50%;
  margin-top: 6px;
  cursor: pointer;
  background: url(../img/image.svg) no-repeat center;
  background-position-y: 20px;
  overflow: hidden;
  input {
    display: none;
  }
  .img {
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    height: 100%;
    img {
      object-fit: cover;
      width: 155px;
      height: 155px;
    }
  }
}

.input {
  display: flex;
  align-items: center;
  border: 1px solid #e6e6e6;
  color: #6b6b6b;
  font-size: 1rem;
  border-radius: 33px;
  position: relative;
  label {
    font-size: 1.875rem;
  }
  svg {
    position: absolute;
    right: 20px;
    bottom: 10px;
    color: #999;
    font-size: 1.2rem;
    cursor: pointer;
    z-index: 5;
  }
}
.box-pass {
  border-radius: 3px;
  border: 1px solid #e6e6e6;
}

input {
  width: 100%;
  border: none;
  border-radius: 33px;
}

.margin-p {
  margin-top: 20px;
}

@media (max-width: 991.98px) {
  .margin-p {
    margin-top: calc(10px + 1rem);
  }
}

.input-2 {
  color: #6b6b6b;
  margin-bottom: 15px;
  width: 100%;
  label {
    font-size: 0.875rem;
  }

  input {
    border: 1px solid #e6e6e6;
    font-size: 1rem;
    border-radius: 33px;
    position: relative;
  }
}

button {
  background: #ebf2f2;
  border-radius: 33px;
  letter-spacing: 0.46em;
  text-transform: uppercase;
  font-weight: bold;
  color: #6b6b6b;
  width: 100%;
}

a {
  display: block;
  text-align: center;
  color: #6b6b6b;
  text-decoration: underline;
}

.terms-of-use {
  display: flex;
  align-items: center;
  color: #6b6b6b;

  a {
    display: inline-flex;
    text-decoration: underline;
    cursor: pointer;
    &:hover {
      text-decoration: underline;
    }
  }
  &.red-text {
    color: red;
    a {
      color: red;
    }
  }
}

.modal-terms-and-policies {
  word-wrap: break-word;
  word-break: break-word;
}
</style>
