<template>
  <div
    id="app"
    :class="
      `${
        $store.getters.info &&
        $store.getters.info.user.type === userTypesText.participant
          ? 'block-ps'
          : ''
      }`
    "
    :style="{
      background:
        hideComponent && $store.getters.theme
          ? $store.getters.theme[0]
          : 'white'
    }"
  >
    <first-login
      v-if="
        hideComponent &&
          $store.getters.info &&
          +$store.getters.info.user.change_password &&
          $store.getters.info.user.type !== userTypesText.participant
      "
    />
    <cHeader v-if="hideComponent && $store.getters.theme" />

    <main id="main" v-if="$store.getters.theme">
      <transition mode="out-in">
        <router-view />
      </transition>
    </main>

    <chat-participant
      v-if="
        hideComponent &&
          $store.getters.info &&
          $store.getters.info.user.type === userTypesText.participant
      "
    />

    <cFooter
      v-if="
        hideComponent && $route.name !== 'communities' && $store.getters.theme
      "
    />
  </div>
</template>

<script>
import { api } from "@/services.js";
import cHeader from "@/components/general/cHeader.vue";
import cFooter from "@/components/general/cFooter.vue";
import FirstLogin from "@/components/general/firstLogin.vue";
import chatParticipant from "@/components/chat/chatParticipant.vue";
import { themes, userTypesText } from "@/constants";
import Cookies from "js-cookie";
import jwt_decode from "jwt-decode";
import { mapMutations, mapState } from "vuex";

export default {
  components: {
    cHeader,
    cFooter,
    FirstLogin,
    chatParticipant
  },

  data() {
    return {
      themes,
      userTypesText
    };
  },

  computed: {
    ...mapState(["permissions"]),

    hideComponent() {
      return (
        this.$route.name !== "login" &&
        this.$route.name !== "pass" &&
        this.$route.name !== "code" &&
        this.$route.name !== "email" &&
        this.$route.name !== "createaccount"
      );
    }
  },

  methods: {
    ...mapMutations(["UPDATE_PRIVACY", "UPDATE_PERMISSIONS"]),

    getCommunity() {
      const label = window.location.hostname.split("-");
      const hostname = window.location.hostname.split(".");
      const subdomain = hostname[0];
      if (
        hostname.length > 1 &&
        label.length &&
        label[0] !== "dev" &&
        label[0] !== "stg" &&
        (!this.$store.getters.info ||
          this.$store.getters.info.user.type !== this.userTypesText.admin)
      ) {
        api
          .get(`community/data/${subdomain}`, {
            headers: {
              Authorization: `Bearer ${this.$store.getters.token}`
            }
          })
          .then(r => {
            if (r.status === 200) {
              if (r.data.community) {
                this.$store.commit("UPDATE_COMMUNITY", r.data.community);
                this.getTheme();
                this.getTranslations();
                this.getPrivacy();
                this.getPermissions();
              }
            }
          });
      } else if (
        this.$store.getters.info &&
        this.$store.getters.info.community
      ) {
        this.$store.commit(
          "UPDATE_COMMUNITY",
          this.$store.getters.info.community
        );
        this.getTheme();
        this.getTranslations();
        this.getPrivacy();
        this.getPermissions();
      } else if (this.$store.getters.token && this.$store.getters.community) {
        this.$store.commit("UPDATE_COMMUNITY", this.$store.getters.community);
        this.getTheme();
        this.getTranslations();
        this.getPrivacy();
        this.getPermissions();
      } else {
        this.getTheme();
        this.getTranslations();
      }
    },

    getTranslations() {
      if (this.$store.getters.token && this.$store.getters.community) {
        const lang = this.$store.getters.community.language.abbreviation;
        this.$store.commit("UPDATE_LANGUAGE", lang);
      } else {
        const lang = (navigator.language || navigator.userLanguage).split(
          "-"
        )[0];

        if (lang === "es" || lang === "pt" || lang === "en") {
          this.$store.commit("UPDATE_LANGUAGE", lang);
        } else {
          this.$store.commit("UPDATE_LANGUAGE", "en");
        }
      }
    },

    getTheme() {
      let userTheme = 4;
      // if (this.$store.getters.token && this.$store.getters.community)
      //   userTheme = +this.$store.getters.community.theme;

      let token = "";
      const community = JSON.parse(localStorage.getItem("community"));

      if (Cookies.get("token")) token = jwt_decode(Cookies.get("token"));

      if (token.theme) userTheme = +token.community.theme;
      else if (community && community.theme) userTheme = +community.theme;

      let theme = themes[userTheme];

      this.$store.commit("UPDATE_THEME", JSON.stringify(theme));
    },

    getPrivacy() {
      if (this.$store.getters.community) {
        api
          .get(`community/${this.$store.getters.community.id}/privacy`, {
            headers: {
              Authorization: `Bearer ${this.$store.getters.token}`
            }
          })
          .then(r => {
            if (r.status === 200) {
              this.UPDATE_PRIVACY(r.data);
            }
          });
      }
    },

    checkRoutes() {
      const routeName = this.$route.name;

      if (
        routeName === "customization" &&
        this.$store.getters.info.user.type === this.userTypesText.moderator &&
        +this.permissions.moderators.hide_customization
      ) {
        this.$router.push({ name: "feed" });
      }

      if (
        routeName === "createactivity" &&
        this.$store.getters.info.user.type === this.userTypesText.moderator &&
        +this.permissions.moderators.hide_create_activity
      ) {
        this.$router.push({ name: "feed" });
      }

      if (
        routeName === "terms-and-policy" &&
        this.$store.getters.info.user.type === this.userTypesText.moderator &&
        +this.permissions.moderators.hide_terms_and_policies
      ) {
        this.$router.push({ name: "feed" });
      }

      if (
        routeName === "statsactivities" &&
        this.$store.getters.info.user.type === this.userTypesText.observer &&
        +this.permissions.observers.hide_activities_stats
      ) {
        this.$router.push({ name: "feed" });
      }

      if (
        routeName === "manageparticipants" &&
        this.$store.getters.info.user.type === this.userTypesText.observer &&
        +this.permissions.observers.hide_manage_participants
      ) {
        this.$router.push({ name: "feed" });
      }
    },

    getPermissions() {
      if (this.$store.getters.community) {
        api
          .get(`community/${this.$store.getters.community.id}/permission`, {
            headers: {
              Authorization: `Bearer ${this.$store.getters.token}`
            }
          })
          .then(r => {
            if (r.status === 200) {
              this.UPDATE_PERMISSIONS(r.data);
              this.checkRoutes();
            }
          });
      }
    },

    goToLogin() {
      if (this.$store.getters.info && this.$store.getters.info.expires_at) {
        const date = new Date();
        const day = date.getDate();
        const month = date.getMonth() + 1;
        const year = date.getFullYear();

        const fullDate = `${year}-${month}-${day}`;

        const expiredToken = this.$store.getters.info.expires_at;

        const currentDate = new Date(fullDate);
        const expiredDate = new Date(expiredToken);

        if (currentDate >= expiredDate) {
          this.$disconnect();
          this.$store.commit("UPDATE_IS_LOGOUT", true);
          this.$store.dispatch("logOut");
          this.$router.push({ name: "login" });
        }
      }
    }
  },

  created() {
    if (window.location.host === "nulab-nubank.owvoices.com") {
      window.location = "https://lab-comunidade.owvoices.com";
    }

    this.getCommunity();
    this.goToLogin();
    this.getTheme();
    this.getPrivacy();
    this.getPermissions();
  },

  mounted() {
    if (
      this.$store.getters.info &&
      (this.$store.getters.info.user.type === this.userTypesText.moderator ||
        this.$store.getters.info.user.type === this.userTypesText.admin)
    )
      window.fcWidget.init({
        token: "330cb03b-af05-4e0e-b457-cb00ac387fa4",
        host: "https://wchat.freshchat.com",
        email: this.$store.getters.info.user.email,
        firstName: this.$store.getters.info.user.name,
        siteId: "owvoices"
      });
  }
};
</script>

<style lang="scss">
@import url("https://fonts.googleapis.com/css2?family=PT+Sans:wght@400;700&display=swap");

/* * {
  outline: 2px dotted red;
} */

#app {
  display: flex;
  flex-direction: column;
  min-height: 110vh;
  font-family: "PT Sans", sans-serif !important;

  &.block-p {
    @media print {
      opacity: 0;
    }
  }
}

#main {
  flex: 1;
}

.card-b {
  border-radius: 20px;
  background: #fff;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.text-grey {
  color: #212529;
}

.pointer {
  cursor: pointer !important;
}

.p-absolute {
  position: absolute;
}

.p-relative {
  position: relative;
}

.input-hidden {
  position: absolute;
  top: 0;
  height: 100%;
  width: 100%;
  opacity: 0;
}

.input-disabled {
  opacity: 0.3;
  cursor: default !important;
  button {
    cursor: default;
  }
}
.v-enter,
.v-leave-to {
  opacity: 0;
}

.v-enter {
  transform: translate3d(0, -20px, 0);
}

.v-leave-to {
  transform: translate3d(0, 20px, 0);
}

.v-enter-active,
.v-leave-active {
  transition: all 0.3s;
}

@keyframes enter {
  0% {
    opacity: 0;
  }
  80% {
    opacity: 0.5;
  }
  100% {
    opacity: 1;
  }
}

@keyframes grow {
  0% {
    transform: scale(0);
  }
  80% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
}

@keyframes show {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes expand {
  0% {
    opacity: 0;
    height: 0px;
  }

  100% {
    opacity: 1;
    height: 406px;
  }
}

@keyframes expand-chat {
  0% {
    opacity: 0;
    height: 0px;
  }

  100% {
    opacity: 1;
    height: 80vh;
  }
}

@keyframes expand-subheader-activities {
  0% {
    opacity: 0;
    height: 0px;
  }
  50% {
    opacity: 0.3;
  }
  100% {
    opacity: 1;
    height: 120px;
  }
}

@keyframes expand-subheader-admin {
  0% {
    opacity: 0;
    height: 0px;
  }
  50% {
    opacity: 0.3;
  }
  100% {
    opacity: 1;
    height: 160px;
  }
}

.carousel-1___BV_inner_ {
  background: #999999;
  color: #999999;
}

.carousel-control-prev,
.carousel-control-next {
  width: 10%;
}

.carousel-caption {
  right: 10%;
  left: 10%;
}

.carousel-item {
  height: 232px;
}

.b-form-btn-label-control.form-control > .btn {
  position: absolute;
  left: 0;
  opacity: 0;
}

.text-muted {
  color: #b3b3b3 !important;
}

.custom-control-input:checked ~ .custom-control-label::before {
  color: #fff;
  border-color: #6b6b6b;
  background-color: #6b6b6b;
}

.checkbox-survey {
  label {
    display: flex;
    align-items: flex-start;
    padding-left: 15px;
    font-size: 1rem;
    color: #6b6b6b;
    &::before {
      width: 30px !important;
      height: 30px !important;
    }
    &::after {
      width: 30px !important;
      height: 30px !important;
    }
  }
}
.whitespace-input {
  border: none;
  border-bottom: 1px solid #b3b3b3;
  color: #6b6b6b;
  margin: 5px 10px;
  min-width: 250px;
  font-size: 0.9rem;
  &:focus {
    outline: 0;
    box-shadow: 0px 3px 0px 0px rgba(123, 123, 123, 0.25);
    transition: all 0.3s ease;
  }
}

.hover {
  transition: all 0.3s ease;
  &:hover {
    transform: scale(1.02);
  }
}

.text {
  word-wrap: break-word;
  word-break: break-word;

  iframe {
    max-width: 100% !important;
  }
}

.radioOperator {
  .custom-control-label {
    min-width: 98px !important;
  }
}

.custom-control-label {
  position: relative !important;
  left: 0px !important;
  span {
    position: relative !important;
    top: 3px;
  }
}

#all-checkbox {
  .custom-control-label {
    position: absolute !important;
    left: 24.5px !important;
  }
}

@media (max-width: 767.98px) {
  .w-md-100 {
    width: 100%;
  }
  .text {
    iframe {
      height: unset !important;
    }
  }
}

.video {
  display: flex;
  justify-content: center;
  padding: 10px;
  iframe {
    width: 100%;
    max-width: 854px !important;
    height: 480px !important;
  }
}

@media (max-width: 767.98px) {
  .video {
    iframe {
      max-width: 426px !important;
      height: 240px !important;
    }
  }
}

@media (max-width: 976.98px) {
  #all-checkbox {
    .custom-control-label {
      position: absolute !important;
      left: 36px !important;
    }
  }
}

@media (max-width: 991.98px) {
  .video {
    iframe {
      max-width: 640px !important;
      height: 360px !important;
    }
  }
}

.img-question {
  img {
    max-width: 100%;
  }
  iframe {
    max-width: calc(100% - 20px);
    padding: 10px;
  }
  pre {
    white-space: pre-wrap;
  }
}

.theme .vdatetime-popup__header,
.theme .vdatetime-calendar__month__day--selected > span > span,
.theme .vdatetime-calendar__month__day--selected:hover > span > span {
  background: #999999;
}

.theme .vdatetime-year-picker__item--selected,
.theme .vdatetime-time-picker__item--selected,
.theme .vdatetime-popup__actions__button {
  color: #999999;
}

.datetime-picker {
  position: relative;
  z-index: 2;
  border: none;
  font-size: 0.8rem;
  color: #6b6b6b;
  cursor: pointer !important;
  background: rgba(0, 0, 0, 0);
  padding-left: 25px;

  &:disabled {
    cursor: initial !important;
    background: rgba(0, 0, 0, 0.1);
  }
}

@media (max-width: 767.98px) {
  .datetime-picker {
    max-width: 125px;
  }
}

.checkbox-input label {
  font-size: 0.8rem;
}

.category.active {
  border-bottom: 3px solid #6b6b6b !important;
}

.btn:focus,
.form-control:focus {
  box-shadow: 0 0 0 0.2rem rgba(123, 123, 123, 0.25);
}

.modal-header {
  .close {
    &:focus {
      outline: none;
    }
  }
}

.modal-dialog {
  font-family: "PT Sans", sans-serif;
  color: #6b6b6b;
}

.credits {
  font-family: "PT Sans", sans-serif !important;
  color: #999999 !important;
  font-size: 0.75rem !important;
}

.modal-footer {
  button {
    letter-spacing: 0.13em !important;
    text-transform: uppercase !important;
    font-size: 0.8rem !important;
    font-weight: bold !important;
    background: #222f3e !important;
    border: 1px solid #222f3e !important;
    &:nth-of-type(2) {
      background-color: #222f3e !important;
    }
    &:nth-of-type(1) {
      background-color: #efefef !important;
      border: 1px solid #efefef !important;
      color: #6b6b6b !important;
    }
  }
}

@media (min-width: 576px) {
  .modalComment {
    .modal-dialog {
      max-width: 700px;
      margin: 1.75rem auto;
    }
  }
}

@media (min-width: 576px) {
  .modalTranscription {
    padding-left: 0px !important;
    .modal-dialog {
      max-width: 900px;
      margin: 1.75rem auto;
    }
  }
}

.attachment {
  display: flex;
  align-items: center;
  color: #6b6b6b;
  text-transform: uppercase;
  font-size: 0.8rem;
  border-radius: 20px;
  border: 1px solid #efefef;
  background-color: #fff;
  svg {
    &:nth-of-type(1) {
      font-size: 1.5rem;
    }
    &:nth-of-type(2) {
      cursor: pointer;
      font-size: 1.2rem;
    }
  }
  a {
    color: #6b6b6b;
    font-weight: bold;
    font-size: 0.8rem;
  }
}

.text {
  .custom-switch {
    .custom-control-label {
      min-width: 110px !important;
    }
  }
  img {
    object-fit: contain;
    max-width: 100%;
    height: unset;
  }
}

.box {
  .custom-control-label {
    color: #6b6b6b;
    font-weight: normal;
    width: 100px;
    text-align: left;
  }
}

.error-box {
  display: none;
  color: #fff;
  font-weight: bold;
  border-radius: 3px;
  font-size: 0.9rem;
  animation: show 0.3s linear;
  &.active {
    display: inline-block;
  }
}

.dropzone .dz-preview .dz-error-message {
  top: 150px !important;
}

.bm-burger-button {
  top: 5px !important;
  left: 20px !important;
}

.bm-menu {
  padding-top: 40px !important;
  background: #fff !important;
  &::-webkit-scrollbar {
    width: 8px;
  }
  &::-webkit-scrollbar-track {
    background: rgba(0, 0, 0, 0);
    border-radius: 10px;
  }
  &::-webkit-scrollbar-thumb {
    background: #e6e6e6;
    border-radius: 10px;
    height: 40px;
  }
  &::-webkit-scrollbar-thumb:hover {
    background: #ffffff;
  }
}

.bm-cross {
  background: #373a47 !important;
}

.bm-item-list {
  margin-left: unset !important;
}

.input-radio {
  div {
    .custom-control-label {
      font-size: 1.18rem !important;
      font-weight: normal;
      line-height: 25px;
      color: #424151;
    }
  }
}

.tox-tinymce-aux {
  z-index: 1000 !important;
}

.box-text-highlight {
  span {
    transition: all 0.3s ease;
    cursor: pointer;
    padding: 0 5px;
    border-radius: 5px;
  }
  &:hover {
    span {
      background: #e6e6e6;
    }
    &.disabled {
      span {
        background: unset;
      }
    }
  }
  &.disabled {
    span {
      cursor: default;
    }
  }
}

.answer-data {
  color: #6b6b6b;
  img {
    width: 100%;
    max-width: 300px;
  }
  video {
    width: 100%;
    max-width: 300px;
  }
  p {
    margin-bottom: 0;
    word-wrap: break-word;
    word-break: break-word;
  }
  pre {
    white-space: pre-wrap;
  }
}

video {
  background: #000;
}

.task-statement-formatted {
  width: 100%;
  > p {
    position: relative;
    word-wrap: break-word;
    word-break: break-word;
    line-height: 20px;
    font-size: 15px;
    max-height: 100px;
    overflow: hidden;
    margin-bottom: 0;
    &.active {
      max-height: unset;
    }
    span {
      display: none;
    }
    iframe {
      display: none;
    }
    img {
      height: 15px;
      border-radius: 5px;
      object-fit: contain;
      margin: 0 5px;
    }
    &.active {
      img {
        display: block;
        height: 100%;
        max-width: 300px;
        width: 100%;
        margin: 20px auto;
      }
      span {
        display: block;
        height: 15px;
      }
      iframe {
        display: block;
        margin: 20px auto;
        max-width: 300px;
        width: 100%;
        height: 225px;
      }
    }
  }
}

.task-statement-separator {
  width: 100%;
  display: flex;
  align-items: flex-end;
  margin: 30px 0;
  &::before {
    content: "";
    display: block;
    width: 100%;
    height: 1px;
    background: #c4c4c4;
  }
}

.task-statement-show-more {
  flex-shrink: 0;
  background: none;
  border: none;
  font-weight: bold;
  transition: all 0.3s ease;
  text-decoration: underline;
  white-space: nowrap;
  width: 100px;
  padding: 0;
  line-height: 1rem;
  font-size: 1rem;
  &:focus {
    outline: none;
  }
}

.stop-display {
  .custom-control-label::before {
    margin-top: -2px;
  }

  .custom-control-label::after {
    margin-top: -2px;
  }
}

.guide-carousel {
  .carousel-caption {
    bottom: 10px;
  }
}

.b-carousel-slide {
  .carousel-caption {
    padding-top: 0px;
    padding-bottom: 0px;
  }
}
</style>
