<template>
  <div class="dropdown-wrapper" :style="{ width: `${width}px` }">
    <div class="outside" v-if="opened"></div>
    <div class="dropdown" :id="dropId">
      <a
        :style="{ width: `${width}px` }"
        class="btn drop-btn py-0"
        @click="handleDrop"
      >
        <p>
          {{ placeholder }}
          <span
            :class="{ hide: !data.length }"
            :style="{ background: $store.getters.theme[2] }"
          >
            {{ data.length }}
          </span>
        </p>
        <b-icon class="m-0" icon="caret-down-fill" aria-hidden="true"></b-icon
      ></a>
      <div :style="{ width: `${width}px` }" class="drop-item-wrapper">
        <div class="drop-item">
          <b-form-input
            :style="{ width: `${width - 25}px` }"
            class="search mt-2"
            v-model="search"
            size="sm"
            :placeholder="translation.global.search"
          ></b-form-input>
          <b-form-checkbox
            class="mx-2 mt-2"
            :id="`all-${dropId}`"
            v-model="all"
          >
            <span>
              {{ translation.replies.all }}
            </span>
          </b-form-checkbox>
          <b-form-group class="mx-2" v-slot="{ ariaDescribedby }">
            <b-form-checkbox-group
              v-model="data"
              :options="optionsTemp"
              :aria-describedby="ariaDescribedby"
              :name="placeholder"
              stacked
            ></b-form-checkbox-group>
          </b-form-group>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { outsideClick } from "@/helpers.js";

export default {
  props: {
    options: {
      required: true
    },
    dropId: {
      required: true
    },
    width: {
      required: true
    },
    placeholder: {
      required: true
    },
    value: {
      default: null
    },
    clear: {},
    reset: {}
  },
  data() {
    return {
      search: "",
      data: [],
      opened: false,
      optionsTemp: [],
      outsideClick,
      all: false
    };
  },
  watch: {
    data() {
      const isAllChecked = this.data.length === this.options.length;
      if (!isAllChecked) {
        this.all = false;
      }
      this.$emit("update", this.data);
    },
    all() {
      const isAllChecked = this.data.length === this.options.length;
      if (this.all) {
        this.data = this.options.map(option => option.value);
      } else if (isAllChecked) {
        this.data = [];
      }
    },
    clear() {
      this.data = [];
    },
    reset() {
      this.data = this.value;
    },
    search() {
      this.optionsTemp = this.options.filter(item => {
        return this.search
          .toLowerCase()
          .split(" ")
          .every(v => item.text.toLowerCase().includes(v));
      });
    }
  },
  methods: {
    handleDrop() {
      const dropItem = document.querySelector(`#${this.dropId}`);
      dropItem.children[1].classList.toggle("active");
      this.opened = !this.opened;
      this.outsideClick(dropItem, ["click"], () => {
        dropItem.children[1].classList.remove("active");
        this.opened = false;
      });
    }
  },
  mounted() {
    this.optionsTemp = this.options;

    const filterSaved = JSON.parse(localStorage.getItem(this.dropId));

    if (filterSaved && filterSaved.length) this.data = filterSaved;
  }
};
</script>

<style lang="scss" scoped>
.outside {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  z-index: 2;
}

.dropdown {
  &:hover {
    .drop-item-wrapper {
      display: block;
    }
  }
}

.drop-btn {
  display: flex;
  align-items: center;
  justify-content: space-between;
  text-transform: uppercase;
  color: #6b6b6b;
  font-size: 0.7rem;
  background: #efefef;
  border-radius: 10px;
  cursor: pointer;
  height: 100%;
  z-index: 3;
  position: relative;
  p {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 0;
    width: 100%;
    span {
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 50%;
      color: #ffffff;
      font-weight: bold;
      width: 20px;
      height: 20px;
      padding-top: 1px;
      padding-right: 1px;
      margin: 5px;
      transition: all 0.3s ease;
      &.hide {
        opacity: 0;
      }
    }
  }
  svg {
    font-size: 1.2rem !important;
  }
}

.drop-item-wrapper {
  position: absolute;
  display: none;
  padding-top: 10px;
  z-index: 9000;
  top: 30px;
  right: 0;

  &.active {
    display: block;
  }
}

.drop-item {
  animation: grow 400ms ease-in-out forwards;
  transform-origin: top center;
  background: #fff;
  border-radius: 4px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
  max-height: 150px;
  overflow: auto;
  overflow-x: hidden;
  font-size: 0.8rem;
  &::-webkit-scrollbar {
    width: 8px;
  }
  &::-webkit-scrollbar-track {
    background: rgba(0, 0, 0, 0);
    border-radius: 10px;
  }
  &::-webkit-scrollbar-thumb {
    background: #e6e6e6;
    border-radius: 10px;
    height: 40px;
  }
  &::-webkit-scrollbar-thumb:hover {
    background: #c9c9c9;
  }
}

.search {
  margin-left: 10px;
}
</style>
