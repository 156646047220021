var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"row task-wrapper"},[(!_vm.loading)?_c('div',{staticClass:"col"},[_c('div',{staticClass:"task-text img-question"},[_c('p',{domProps:{"innerHTML":_vm._s(_vm.text)}})]),_c('div',{staticClass:"d-flex flex-wrap"},[_c('attachment',{attrs:{"attachments":_vm.task.attachments,"deleteFile":null}})],1),_c('div',{staticClass:"d-flex justify-content-center py-4"},[_c('div',{staticClass:"select-box",style:({ background: _vm.$store.getters.theme[1] })},[_c('p',{staticClass:"mb-1"},[_vm._v(" "+_vm._s(_vm.translation.answer_task.reaction.minimum_number)+": "+_vm._s(_vm.min)+" ")]),_c('p',{staticClass:"mb-0"},[_vm._v(" "+_vm._s(_vm.translation.answer_task.reaction.maximum_number)+": "+_vm._s(_vm.max)+" ")])])]),(_vm.customTagging)?_c('div',{staticClass:"d-flex flex-wrap justify-content-center mb-3"},_vm._l((_vm.options),function(option){return _c('button',{key:option.index,staticClass:"btn btn-color mr-3 hover",class:{ active: _vm.colorTask === option.color },style:({ background: option.color }),on:{"click":function($event){(_vm.colorTask = option.color), (_vm.optionLabel = option.label)}}},[_vm._v(" "+_vm._s(option.label)+" ")])}),0):_vm._e(),_c('div',[_c('p',{ref:"taskText",staticClass:"text-wrapper box-text-highlight",class:{ disabled: _vm.task.is_answered },domProps:{"innerHTML":_vm._s(_vm.taggedText)},on:{"click":_vm.markSelection}})]),_c('div',{staticClass:"comments mt-4"},[(
          _vm.task.question_object.has_custom_comment_label &&
            _vm.task.question_object.comment_label &&
            _vm.task.question_object.comment_label.length
        )?_c('label',{attrs:{"for":"comments"}},[_vm._v(_vm._s(_vm.task.question_object.comment_label))]):_c('label',{attrs:{"for":"comments"}},[_vm._v(" "+_vm._s(_vm.translation.answer_task.comments)+" ")]),_c('textarea',{directives:[{name:"model",rawName:"v-model",value:(_vm.comments),expression:"comments"}],staticClass:"form-control",attrs:{"id":"textarea","maxlength":_vm.maxCharacters,"rows":"3","max-rows":"6","disabled":_vm.task.is_answered},domProps:{"value":(_vm.comments)},on:{"input":function($event){if($event.target.composing){ return; }_vm.comments=$event.target.value}}}),_c('characterAndWordCounter',{attrs:{"count":_vm.hasWordsLimitation
            ? this.comments.length
              ? this.comments.trim().split(/\s+/).length
              : 0
            : _vm.comments.length,"max":_vm.hasWordsLimitation ? _vm.maxWords : _vm.maxCharacters,"label":_vm.hasWordsLimitation
            ? _vm.translation.answer_task.words
            : _vm.translation.answer_task.characters}})],1)]):_vm._e(),(_vm.loading)?_c('div',{staticClass:"w-100 d-flex justify-content-center"},[_c('loader')],1):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }