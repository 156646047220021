<template>
  <div>
    <div class="task-statement-formatted">
      <p :class="{ active: showMore }" v-html="getFormattedTask"></p>
      <div class="m-0" v-if="showMore">
        <div class="reaction-img-wrapper my-3">
          <img :src="task.question_object.image" />
        </div>
        <span class="font-weight-bold">{{ translation.replies.labels }}:</span>
        <span
          v-for="(group, index) in task.question_object.options"
          :key="index"
        >
          {{
            index + 1 === task.question_object.options.length
              ? ` ${group.icon} ${group.label}.`
              : ` ${group.icon} ${group.label},`
          }}
        </span>
      </div>
      <div class="task-statement-separator">
        <button
          :style="{ color: $store.getters.theme[2] }"
          class="task-statement-show-more hover"
          @click="showMore = !showMore"
        >
          {{
            showMore
              ? translation.global.show_less
              : translation.global.show_more
          }}
        </button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    task: {
      required: true
    },
    getFormattedTask: {
      required: true
    }
  },
  data() {
    return {
      showMore: false
    };
  }
};
</script>

<style lang="scss" scoped>
.reaction-img-wrapper {
  display: flex;
  justify-content: center;
  img {
    width: 100%;
    max-width: 600px;
    max-height: 400px;
    object-fit: contain;
  }
}
</style>
