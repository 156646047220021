<template>
  <div>
    <imageCropper
      :img="img"
      :show="showCropper"
      :width="300"
      :height="200"
      :loader="loaderInsertImg"
      @updateImg="img => insertImg(img)"
      @trash="close"
    />
    <div
      class="d-flex flex-wrap align-items-center justify-content-between mt-3"
    >
      <div class="d-flex align-items-center">
        <div>
          <div class="switch d-flex">
            <span class="pr-2">Image</span>
            <b-form-checkbox v-model="cardImage" name="check-button" switch>
            </b-form-checkbox>
          </div>
        </div>
        <div>
          <div class="switch d-flex">
            <span class="pr-2">Cards finitos</span>
            <b-form-checkbox v-model="finiteCards" name="check-button" switch>
            </b-form-checkbox>
          </div>
        </div>
        <div
          :class="{ active: dragAndDropError }"
          class="error-box bg-danger p-2 ml-3"
        >
          <b-icon class="m-1" icon="exclamation-triangle-fill"></b-icon>
          {{ dragAndDropError }}
        </div>
      </div>
      <button
        @click="$bvModal.show('quickly-add')"
        :style="{ background: $store.getters.theme[2] }"
        class="btn btn-quickly"
      >
        {{ translation.create_task.survey.quickly_add }}
      </button>
      <b-modal
        @ok="quicklyAdd"
        :cancel-title="translation.global.cancel"
        id="quickly-add"
        :title="translation.create_task.survey.quickly_add"
      >
        <p>{{ translation.create_task.survey.name_a_option }}</p>
        <b-form-textarea
          id="textarea"
          :placeholder="translation.create_task.survey.quickly_add_placeholder"
          v-model="quicklyAddText"
        ></b-form-textarea>
      </b-modal>
    </div>
    <div>
      <label class="label pt-4">
        {{ translation.create_task.drag_and_drop.cards }}:
      </label>
      <div :class="{ noImage: !cardImage }" class="cards d-flex flex-wrap pb-4">
        <div
          class="col-md-3 mb-3"
          v-for="(card, index) in dragAndDrop.cards"
          :key="index"
        >
          <div>
            <button
              v-if="card.img.code && cardImage"
              :style="{ color: $store.getters.theme[3] }"
              class="delete edit hover"
              @click="openCropper(index)"
            >
              <b-icon icon="pencil-fill"></b-icon>
            </button>
            <button
              v-if="dragAndDrop.cards.length > 1"
              :style="{ color: $store.getters.theme[3] }"
              :class="{ hasEdit: card.img.code && cardImage }"
              class="delete hover"
              @click="deleteCard(index)"
            >
              <b-icon icon="trash-fill"></b-icon>
            </button>

            <label
              v-if="cardImage"
              :for="`img-card-${index}`"
              class="input"
              :style="{
                'background-color': $store.getters.theme[0]
              }"
            >
              <div class="img" v-if="card.img.code.length">
                <img :src="card.img.code" />
              </div>
              <input
                @change="uploadImg(`img-card-${index}`, index)"
                :id="`img-card-${index}`"
                class="d-none"
                type="file"
                accept="image/*"
              />
            </label>
            <div class="text">
              <div
                :style="{
                  'background-color': $store.getters.theme[2]
                }"
              ></div>
              <div class="pencil-wrapper">
                <b-icon v-if="!card.text.length" icon="pencil-fill"></b-icon>
                <textarea
                  placeholder="Card"
                  maxlength="110"
                  type="text"
                  v-model="card.text"
                />
              </div>
            </div>
          </div>
          <b-form-input
            class="code"
            v-model="card.codeString"
            maxlength="10"
            :state="
              !isCodeValid(card.codeString) &&
                !hasDuplicate(dragAndDrop.cards, 'codeString') &&
                !!card.codeString.length &&
                card.codeString !== '0'
            "
            v-b-tooltip.hover="{ variant: 'secondary' }"
            :title="translation.create_task.error.code_validation"
          ></b-form-input>
        </div>
        <div class="col-md-3">
          <div class="add-card hover" @click="addCard">
            <b-icon icon="plus"></b-icon>
          </div>
        </div>
      </div>
      <label class="label">
        {{ translation.create_task.drag_and_drop.groups }}:
      </label>
      <div class="groups d-flex flex-wrap pb-4">
        <div
          class="col-md-3 mb-3"
          v-for="(group, index) in dragAndDrop.groups"
          :key="index"
        >
          <div class="p-2">
            <button
              v-if="dragAndDrop.groups.length > 1"
              class="delete hover"
              @click="deleteGroup(index)"
            >
              <b-icon icon="trash-fill"></b-icon>
            </button>
            <div class="pencil-wrapper group">
              <b-icon v-if="!group.text.length" icon="pencil-fill"></b-icon>
              <input
                placeholder="Group"
                type="text"
                v-model="group.text"
                maxlength="50"
              />
            </div>
          </div>
          <b-form-input
            class="code"
            v-model="group.codeString"
            maxlength="10"
            :state="
              !isCodeValid(group.codeString) &&
                !hasDuplicate(dragAndDrop.groups, 'codeString') &&
                !!group.codeString.length &&
                group.codeString !== '0'
            "
            v-b-tooltip.hover="{ variant: 'secondary' }"
            :title="translation.create_task.error.code_validation"
          ></b-form-input>
        </div>
        <div class="col-md-3">
          <div class="hover p-2 addGroup" @click="addGroup">
            <b-icon icon="plus"></b-icon>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { v4 as uuidv4 } from "uuid";
import { api } from "@/services.js";
import lambda from "@/lambda";
import imageCropper from "@/components/general/imageCropper.vue";
import {
  taskEnum,
  regexpImageName,
  addTaskText,
  regexpCodeString
} from "@/constants";
import { mapState } from "vuex";

export default {
  components: {
    imageCropper
  },
  props: {
    taskText: {
      required: true
    },
    task: {
      required: true
    }
  },
  data() {
    return {
      img: "",
      imgName: "",
      showCropper: false,
      finiteCards: false,
      cardImage: false,
      loaderInsertImg: false,
      dragAndDrop: {
        cards: [
          {
            img: {
              code: "",
              name: ""
            },
            text: "",
            codeString: "1"
          }
        ],
        groups: [
          {
            text: "",
            codeString: "1"
          }
        ]
      },
      quicklyAddText: "",
      taskEnum,
      regexpImageName,
      addTaskText,
      regexpCodeString
    };
  },
  computed: {
    ...mapState(["imageUploaderFolder"]),
    taskObj() {
      return {
        statement: this.taskText,
        groups: this.dragAndDrop.groups.map((group, index) => ({
          index: index,
          value: group.text,
          code_string: group.codeString
        })),
        cards: this.dragAndDrop.cards.map((card, index) => ({
          index: index,
          has_image: card.img.code.length ? 1 : 0,
          value: {
            text: card.text,
            img: card.img.code,
            image_name: card.img.name
          },
          code_string: card.codeString
        })),
        finite_cards: this.finiteCards ? 1 : 0
      };
    },
    dragAndDropError() {
      let message = null;
      if (this.hasEmpty(this.dragAndDrop.cards, "text"))
        message = this.translation.create_task.drag_and_drop.error.empty_card;
      else if (this.hasEmpty(this.dragAndDrop.groups, "text"))
        message = this.translation.create_task.drag_and_drop.error.empty_group;
      else if (this.hasDuplicate(this.dragAndDrop.cards, "text"))
        message = this.translation.create_task.drag_and_drop.error
          .duplicated_cards;
      else if (this.hasDuplicate(this.dragAndDrop.groups, "text"))
        message = this.translation.create_task.drag_and_drop.error
          .duplicated_groups;
      else if (this.hasDuplicate(this.dragAndDrop.cards, "codeString"))
        message = this.translation.create_task.drag_and_drop.error
          .duplicated_codes_cards;
      else if (this.hasDuplicate(this.dragAndDrop.groups, "codeString"))
        message = this.translation.create_task.drag_and_drop.error
          .duplicated_codes_groups;
      else if (
        this.hasZeros(this.dragAndDrop.groups) ||
        this.hasZeros(this.dragAndDrop.cards)
      )
        message = this.translation.create_task.error.the_field_cannot;
      else if (
        this.hasEmpty(this.dragAndDrop.cards, "codeString") ||
        this.hasEmpty(this.dragAndDrop.groups, "codeString")
      )
        message = this.translation.create_task.error.error_empty_code_field;
      else if (
        this.dragAndDrop.cards.filter(option => option.codeString === "0")
          .length ||
        this.dragAndDrop.groups.filter(option => option.codeString === "0")
          .length
      )
        message = this.translation.create_task.error.number_zero;
      else if (
        this.dragAndDrop.cards.filter(option =>
          this.isCodeValid(option.codeString)
        ).length ||
        this.dragAndDrop.groups.filter(option =>
          this.isCodeValid(option.codeString)
        ).length
      )
        message = this.translation.create_task.error.code_validation;
      else message = null;
      return message;
    }
  },
  watch: {
    taskObj() {
      this.$emit("update", this.taskObj);
    },
    dragAndDropError() {
      this.$emit("error", this.dragAndDropError);
    }
  },
  methods: {
    isCodeValid(codeString) {
      return codeString.match(this.regexpCodeString);
    },
    quicklyAdd() {
      if (this.quicklyAddText.length) {
        const options = this.quicklyAddText.split(";");

        options.forEach(option => {
          const hasOption = option.length;
          if (hasOption) {
            this.dragAndDrop.cards.push({
              img: {
                code: "",
                name: ""
              },
              text: option,
              codeString: `${this.dragAndDrop.cards.length + 1}`
            });
          }
        });
        this.quicklyAddText = "";
      }
    },
    hasZeros(array) {
      return array.some(option => option.text === "0");
    },
    hasEmpty(array) {
      return array.some(option => !option.text.length);
    },
    hasDuplicate(array, key) {
      const valuesSoFar = [];
      for (let i = 0; i < array.length; ++i) {
        const value = array[i][key].trim();
        if (valuesSoFar.indexOf(value) !== -1) {
          return true;
        }
        valuesSoFar.push(value);
      }
      return false;
    },
    addCard() {
      this.dragAndDrop.cards.push({
        img: {
          code: "",
          name: ""
        },
        text: "",
        codeString: `${this.dragAndDrop.cards.length + 1}`
      });
    },
    deleteCard(index) {
      this.dragAndDrop.cards.splice(index, 1);
    },
    addGroup() {
      this.dragAndDrop.groups.push({
        text: "",
        codeString: `${this.dragAndDrop.groups.length + 1}`
      });
    },
    deleteGroup(index) {
      this.dragAndDrop.groups.splice(index, 1);
    },
    openCropper(index) {
      this.img = this.dragAndDrop.cards[index].img.code;
      this.imgName = this.dragAndDrop.cards[index].img.name;
      this.indexCard = index;
      this.showCropper = true;
    },
    close() {
      this.showCropper = false;
      this.img = "";
      this.imgName = "";
      document.querySelector(`#img-card-${this.indexCard}`).value = "";
    },
    async uploadImg(element, index) {
      const file = document.querySelector(`#${element}`).files[0];
      if (file) {
        const toBase64 = file =>
          new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => resolve(reader.result);
            reader.onerror = error => reject(error);
          });
        this.img = await toBase64(file);
        this.imgName = file.name;
        this.indexCard = index;
        this.showCropper = true;
        document.querySelector(`#${element}`).value = "";
      }
    },
    insertImg(src) {
      this.loaderInsertImg = true;

      let arr = src.split(",");
      let mime = arr[0].match(/:(.*?);/)[1];
      let bstr = atob(arr[1]);
      let n = bstr.length;
      let u8arr = new Uint8Array(n);

      while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
      }

      const imgName = `${uuidv4()}-${this.imgName.replace(
        this.regexpImageName,
        ""
      )}`;

      const imgFile = new File([u8arr], imgName, {
        type: mime
      });

      lambda
        .getSignedURL(
          imgFile,
          `tmp/images/${this.imageUploaderFolder}`,
          imgName
        )
        .then(url => {
          api
            .put(url, imgFile, {
              headers: {
                "Content-Type": imgFile.type
              }
            })
            .then(r => {
              this.dragAndDrop.cards[
                this.indexCard
              ].img.code = r.config.url.split("?")[0];

              this.dragAndDrop.cards[this.indexCard].img.name = imgName;
              this.showCropper = false;
              this.img = "";
              this.loaderInsertImg = false;
            })
            .catch(() => {
              this.$toast.error(
                this.translation.errors_global.something_went_wrong
              );
              this.loaderInsertImg = false;
            });
        })
        .catch(() => {
          this.$toast.error(
            this.translation.errors_global.something_went_wrong
          );
          this.loaderInsertImg = false;
        });
    },
    getDescription() {
      if (
        this.task !== this.addTaskText &&
        +this.task.type_id === this.taskEnum.dragAndDrop.id
      ) {
        this.dragAndDrop.groups = this.task.question_object.groups.map(
          (group, index) => ({
            text: group.value,
            codeString: group.code_string ? group.code_string : `${index + 1}`
          })
        );
        this.dragAndDrop.cards = this.task.question_object.cards.map(
          (card, index) => ({
            img: {
              code: card.value.img,
              name: card.value.image_name
            },
            text: card.value.text,
            codeString: card.code_string ? card.code_string : `${index + 1}`
          })
        );
        if (
          this.task.question_object.cards.filter(card => card.has_image).length
        )
          this.cardImage = true;
        if (this.task.question_object.finite_cards) this.finiteCards = true;
      } else {
        this.setTask();
      }
      this.$emit("update", this.taskObj);
    },
    setTask() {
      if (this.task === this.addTaskText) {
        this.dragAndDrop = {
          cards: [
            {
              img: {
                code: "",
                name: ""
              },
              text: `${this.translation.create_task.reaction.option} 1`,
              codeString: "1"
            },
            {
              img: {
                code: "",
                name: ""
              },
              text: `${this.translation.create_task.reaction.option} 2`,
              codeString: "2"
            }
          ],
          groups: [
            {
              text: `${this.translation.create_task.reaction.option} 1`,
              codeString: "1"
            }
          ]
        };
      }
    },
    init() {
      this.$emit("error", this.dragAndDropError);
      this.setTask();
    }
  },
  created() {
    this.init();
    this.getDescription();
  }
};
</script>

<style lang="scss" scoped>
.label {
  color: #6b6b6b;
  font-weight: bold;
  font-size: 1rem;
}

.switch {
  color: #6b6b6b;
}

.cards {
  > div {
    > div {
      position: relative;
      overflow: hidden;
      border-radius: 20px;
      width: 100%;
      height: 160px;
      box-shadow: 0px 0px 37px -16px #c4c4c4;
      .delete {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 25px;
        height: 25px;
        border-radius: 50%;
        background: #ffffff;
        display: block;
        position: absolute;
        right: 8px;
        top: 8px;
        font-size: 0.8rem;
        border: none;
        font-weight: bold;
        &.hasEdit {
          right: 40px;
        }
        &:focus {
          outline: none;
        }
      }
      .edit {
        right: 8px;
      }
      .input {
        background: url(../../img/image.svg) no-repeat center;
        background-size: contain;
        cursor: pointer;
        padding: 0;
        width: 100%;
        height: 100%;
        input {
          display: none;
        }
        .img {
          display: flex;
          align-items: center;
          justify-content: center;
          img {
            object-fit: cover;
            width: 230px;
            height: 160px;
            background-color: white;
          }
        }
      }
      .text {
        position: absolute;
        bottom: 0;
        z-index: 5;
        height: 45px;
        width: 100%;
        cursor: default;
        div {
          position: absolute;
          top: 0;
          height: 100%;
          width: 100%;
          opacity: 0.8;
        }
        textarea {
          border-bottom: 1px solid #6b6b6b;
          overflow: hidden;
          position: relative;
          z-index: 5;
          font-weight: bold;

          color: #fff;
          padding: 8px 10px 2px;
          background: none;
          border: none;
          width: 100%;
          height: 45px;
          line-height: 1rem;
          font-size: 1rem;
          &:focus {
            outline: none;
          }
        }
      }
    }
  }
  &.noImage {
    > div {
      > div {
        height: 50px;
        .delete {
          z-index: 20;
        }
        .text {
          height: 50px;
          textarea {
            height: 100%;
            padding-right: 40px;
          }
        }
      }
    }
  }
}

.add-card {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 3rem;
  color: #999999;
  cursor: pointer;
}

.add-card {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 3rem;
  color: #999999;
  cursor: pointer;
}

.groups {
  > div {
    > div {
      box-shadow: 0px 0px 37px -16px #c4c4c4;
      width: 100%;
      height: 50px;
      overflow: hidden;
      border-radius: 14px;
      border: 3px solid #ebf2f2;
      position: relative;
      .delete {
        display: block;
        position: absolute;
        right: 0px;
        top: 0px;
        padding: 5px 5px 2px 2px;
        font-size: 0.8rem;
        border: none;
        background: none;
        font-weight: bold;
        z-index: 20;
        color: #6b6b6b;
        &:focus {
          outline: none;
        }
      }
      input {
        border: none;
        color: #6b6b6b;
        font-weight: bold;
        width: 100%;
        height: 100%;
        padding-right: 15px;
        &:focus {
          outline: none;
        }
      }
    }
  }
}

.addGroup {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 3rem;
  color: #999999;
  cursor: pointer;
}

.pencil-wrapper {
  position: relative;
  svg {
    position: absolute;
    right: 10px;
    top: 8px;
    color: #fff;
    font-size: 0.8rem;
    opacity: 0.6;
  }
  ::placeholder {
    color: #fff;
    opacity: 0.6;
  }

  :-ms-input-placeholder {
    color: #fff;
    opacity: 0.6;
  }

  ::-ms-input-placeholder {
    color: #fff;
    opacity: 0.6;
  }
  &.group {
    color: #fff;
    svg {
      top: 5px;
      opacity: 1;
      color: #b3b3b3;
    }
    ::placeholder {
      color: #b3b3b3;
      opacity: 1;
    }

    :-ms-input-placeholder {
      color: #b3b3b3;
    }

    ::-ms-input-placeholder {
      color: #b3b3b3;
    }
  }
}

.btn-quickly {
  border-radius: 17px;
  text-transform: uppercase;
  font-weight: bold;
  color: #fff;
  font-size: 0.9rem;
  white-space: nowrap;
  letter-spacing: 0.1rem;
}

@media (max-width: 567.98px) {
  .btn-quickly {
    font-size: 0.6rem;
    letter-spacing: 0.07rem;
  }
}

.code {
  width: 80px;
  margin: 5px auto 0;
  box-shadow: 0px 0px 37px -16px #c4c4c4;
  border-radius: 14px;
  border: 3px solid #ebf2f2;
  color: #6b6b6b;
  font-weight: bold;
  &:focus {
    outline: none;
    box-shadow: 0px 0px 37px -16px #c4c4c4;
    border: 3px solid #ebf2f2;
  }
}
</style>
