<template>
  <div class="row task-wrapper" id="task">
    <b-modal ok-only id="popupBlocker">
      <template #modal-title>
        <b-icon class="mr-1" icon="exclamation-triangle"></b-icon>
      </template>
      {{ translation.answer_task.screen_recording.popup_blocker }}
    </b-modal>
    <div v-if="!loading" class="col">
      <div class="task-text  img-question">
        <p v-html="text"></p>
      </div>

      <div class="d-flex flex-wrap mb-3">
        <attachment :attachments="task.attachments" :deleteFile="null" />
      </div>

      <div class="d-flex justify-content-center py-4">
        <div
          class="select-box"
          :style="{ background: $store.getters.theme[1] }"
        >
          <p class="mb-1">
            *{{
              translation.answer_task.screen_recording.task_cannot_be_answered
            }}*
          </p>
          <div class="d-flex flex-column align-items-center">
            <p class="mb-1">
              {{
                translation.answer_task.screen_recording
                  .minimum_number_recordings
              }}:
              {{ min }}
            </p>
            <p class="mb-0">
              {{
                translation.answer_task.screen_recording
                  .maximum_number_recordings
              }}:
              {{ max }}
            </p>
          </div>
        </div>
      </div>

      <div>
        <div class="video-wrapper">
          <div class="video-box">
            <p v-if="!recording">
              {{ translation.answer_task.screen_recording.task_instruction }}
            </p>
            <video ref="videoElem" autoplay></video>
          </div>
          <b-form-textarea
            v-model="description"
            :placeholder="translation.answer_task.screen_recording.description"
            :disabled="task.is_answered"
          ></b-form-textarea>
          <div class="btns-capture mt-3">
            <button
              :class="{
                hover: !task.is_answered && !recording && !loaderUploadVideo
              }"
              class="btn"
              @click="startCapture"
              :disabled="task.is_answered || recording || loaderUploadVideo"
            >
              {{ translation.answer_task.screen_recording.start_recording }}
            </button>
            <button
              :class="{
                hover: !task.is_answered && recording
              }"
              class="btn"
              @click="stopCapture"
              :disabled="task.is_answered || !recording"
            >
              {{
                translation.answer_task.screen_recording.end_screen_recording
              }}
            </button>
          </div>
        </div>

        <div class="videos my-5" v-for="(video, index) in videos" :key="index">
          <video
            :src="video.url"
            :controlsList="
              $store.getters.info.user.type !== userTypesText.admin &&
              $store.getters.info.user.type !== userTypesText.moderator
                ? 'nodownload'
                : ''
            "
            controls
          ></video>
          <div>
            <div class="d-flex align-item-center justify-content-between">
              <p class="m-0">
                {{ translation.answer_task.screen_recording.recording }}
                {{ index + 1 }}
              </p>
              <button
                :class="{ hover: !task.is_answered }"
                class="btn mb-1"
                @click="removeVideo(index)"
                :disabled="task.is_answered"
              >
                <b-icon icon="x"></b-icon>
              </button>
            </div>
            <b-form-textarea
              class="form-control"
              v-model="video.description"
              :placeholder="
                translation.answer_task.screen_recording.description
              "
              :disabled="task.is_answered"
            ></b-form-textarea>
          </div>
        </div>
      </div>

      <div class="d-flex justify-content-center my-5" v-if="loaderUploadVideo">
        <loader />
      </div>

      <div class="comments mt-4">
        <label
          for="comments"
          v-if="
            task.question_object.has_custom_comment_label &&
              task.question_object.comment_label &&
              task.question_object.comment_label.length
          "
          >{{ task.question_object.comment_label }}</label
        >
        <label for="comments" v-else>
          {{ translation.answer_task.comments }}
        </label>

        <textarea
          class="form-control"
          id="textarea"
          v-model="comments"
          :maxlength="maxCharacters"
          rows="3"
          max-rows="6"
          :disabled="task.is_answered"
        ></textarea>
        <characterAndWordCounter
          :count="
            hasWordsLimitation
              ? this.comments.length
                ? this.comments.trim().split(/\s+/).length
                : 0
              : comments.length
          "
          :max="hasWordsLimitation ? maxWords : maxCharacters"
          :label="
            hasWordsLimitation
              ? translation.answer_task.words
              : translation.answer_task.characters
          "
        />
      </div>
    </div>
    <div v-if="loading" class="w-100 d-flex justify-content-center">
      <loader />
    </div>
  </div>
</template>

<script>
import { userTypesText } from "@/constants";
import { v4 as uuidv4 } from "uuid";
import { api } from "@/services.js";
import loader from "@/components/general/loader.vue";
import attachment from "@/components/general/attachment.vue";
import characterAndWordCounter from "@/components/general/characterAndWordCounter.vue";
import lambda from "@/lambda";
import { mapState } from "vuex";

export default {
  components: {
    loader,
    attachment,
    characterAndWordCounter
  },
  props: {
    task: {
      required: true
    },
    save: {
      required: true
    },
    savePreview: {
      required: true
    },
    getTasks: {
      required: true
    }
  },
  data() {
    return {
      loading: false,
      comments: "",
      charactersCount: 0,
      question: "",
      options: {
        video: {
          cursor: "always"
        },
        audio: false
      },
      videos: [],
      description: "",
      loaderUploadVideo: false,
      recording: false,
      openedLink: false,
      userTypesText
    };
  },
  computed: {
    ...mapState(["screenRecordingTaskFolder"]),
    text() {
      const regexpVideo = /(?:(<iframe.*?src="))(.*?)((?:".*?>))((?:<\/iframe>))?/g;

      return this.$store.getters.info.user.type === userTypesText.participant
        ? this.task.raw_question.replace(
            regexpVideo,
            "<video src='$2' width='350' height='197' frameborder='0' allowfullscreen='allowfullscreen' controls controlsList='nodownload'></iframe>"
          )
        : this.task.raw_question;
    },
    link() {
      return this.task.question_object.link;
    },
    min() {
      return this.task.question_object.min_answers
        ? this.task.question_object.min_answers
        : 1;
    },
    max() {
      return this.task.question_object.max_answers
        ? this.task.question_object.max_answers
        : 10;
    },
    mandatoryDescription() {
      return this.task.question_object.mandatory_description;
    },
    page() {
      return this.$store.getters.activeTaskIndex;
    },
    obligatoryComment() {
      return this.task.question_object?.has_obligatory_comments;
    },

    hasCharactersLimitation() {
      return this.task.question_object?.has_characters_limitation;
    },

    maxCharacters() {
      return this.hasCharactersLimitation
        ? this.task.question_object?.characters_count?.maximum
        : 5000;
    },

    minCharacters() {
      return this.hasCharactersLimitation
        ? this.task.question_object?.characters_count?.minimum
        : 0;
    },

    hasWordsLimitation() {
      return this.task.question_object?.has_words_limitation;
    },

    maxWords() {
      return this.hasWordsLimitation
        ? this.task.question_object?.words_count?.maximum
        : 2000;
    },

    minWords() {
      return this.hasWordsLimitation
        ? this.task.question_object?.words_count?.minimum
        : 0;
    }
  },
  watch: {
    save() {
      this.answer();
    },
    savePreview() {
      this.answerPreview();
    },
    page() {
      this.init();
    },
    comments() {
      if (this.comments.length >= this.maxCharacters) {
        this.comments = this.comments
          .split("")
          .splice(0, this.maxCharacters)
          .join("");
      }

      const words = this.comments.split(/\s+/);

      const wordsCount = words.length;

      if (wordsCount > this.maxWords) {
        this.comments = words.splice(0, this.maxWords).join(" ");
      }
    }
  },
  methods: {
    removeVideo(index) {
      this.videos.splice(index, 1);
    },
    addVideo(url) {
      this.videos.push({
        url,
        description: this.description
      });

      this.description = "";
    },
    uploadVideo(blob) {
      this.loaderUploadVideo = true;
      lambda
        .getSignedURL(
          blob,
          `tmp/video/${this.screenRecordingTaskFolder}`,
          `${uuidv4()}.mp4`
        )
        .then(url => {
          api
            .put(url, blob, {
              headers: {
                "Content-Type": blob.type
              }
            })
            .then(r => {
              this.addVideo(r.config.url.split("?")[0]);
              this.loaderUploadVideo = false;
            })
            .catch(() => {
              this.$toast.error(
                this.translation.errors_global.something_went_wrong
              );
              this.loaderUploadVideo = false;
            });
        })
        .catch(() => {
          this.$toast.error(
            this.translation.errors_global.something_went_wrong
          );
          this.loaderUploadVideo = false;
        });
    },
    async startCapture() {
      const stream = await navigator.mediaDevices.getDisplayMedia(this.options);
      this.$refs.videoElem.srcObject = stream;

      const mime = MediaRecorder.isTypeSupported("video/webm;")
        ? MediaRecorder.isTypeSupported("video/webm; codecs=vp9")
          ? "video/webm; codecs=vp9"
          : "video/webm"
        : "video/mp4";

      const mediaRecorder = new MediaRecorder(stream, {
        mimeType: mime
      });

      const chunks = [];
      mediaRecorder.addEventListener("dataavailable", e => {
        chunks.push(e.data);
      });

      mediaRecorder.addEventListener("start", () => {
        this.recording = true;

        if (this.link.length) {
          this.openPage();
        }
      });

      mediaRecorder.addEventListener("stop", () => {
        if (this.openedLink || !this.link.length) {
          const blob = new Blob(chunks, {
            type: "video/mp4"
          });

          this.$refs.videoElem.srcObject = null;
          this.uploadVideo(blob);
        }
        this.recording = false;
        this.openedLink = false;
      });

      mediaRecorder.start();
    },
    openPage() {
      const newWin = window.open(this.link, "_blank");

      try {
        newWin.focus();
        this.openedLink = true;
      } catch (e) {
        this.openedLink = false;
        this.stopCapture();
        this.$bvModal.show("popupBlocker");
      }
    },
    stopCapture() {
      let tracks = this.$refs.videoElem.srcObject.getTracks();
      tracks.forEach(track => track.stop());

      this.$refs.videoElem.srcObject = null;
    },
    answerPreview() {
      const emptyDescription = this.videos.filter(
        video => !video.description.length
      );

      if (!this.loading) {
        if (this.videos.length) {
          if (this.videos.length >= this.min) {
            if (this.videos.length <= this.max) {
              if (!this.mandatoryDescription || !emptyDescription.length) {
                if (this.obligatoryComment && !this.comments.length) {
                  this.$toast.error(
                    this.translation.answer_task.obligatory_comment_tost
                  );

                  return;
                }

                if (
                  this.comments.length &&
                  this.hasCharactersLimitation &&
                  this.comments.length < this.minCharacters
                ) {
                  this.$toast.error(
                    `${this.translation.answer_task.min_characters} ${this.minCharacters} ${this.translation.answer_task.characters}`
                  );

                  return;
                }

                if (this.comments.length && this.hasWordsLimitation) {
                  const wordsCount = this.comments.split(/\s+/).length;

                  if (wordsCount < this.minWords) {
                    this.$toast.error(
                      `${this.translation.answer_task.min_word} ${this.minWords} ${this.translation.answer_task.words}`
                    );

                    return;
                  }
                }

                this.loading = true;

                const answer = {
                  comment: this.comments,
                  videos: this.videos.map((video, index) => ({
                    index,
                    ...video
                  })),
                  video_folder: `tmp/video/${this.screenRecordingTaskFolder}`
                };

                const body = {
                  task_id: this.task.id,
                  answer: answer,
                  raw_answer: JSON.stringify(answer),
                  has_attachment: 0,
                  has_image: 0,
                  has_video: 1
                };

                api
                  .post("/answer/send/preview", body, {
                    headers: {
                      "Content-Type": "application/json",
                      Authorization: `Bearer ${this.$store.getters.token}`
                    }
                  })
                  .then(r => {
                    if (r.status === 200) {
                      this.$toast.success(
                        this.translation.answer_task.success.reply_registered
                      );
                      this.getTasks("save");
                      this.loading = false;
                    }
                  })
                  .catch(error => {
                    if (error.response.status === 400) {
                      this.$toast.error(
                        this.translation.answer_task.error.empty_answer
                      );
                    } else {
                      this.$toast.error(
                        this.translation.answer_task.error.reply_not_registered
                      );
                    }
                    this.loading = false;
                  });
              } else {
                this.$toast.error(
                  `${this.translation.answer_task.screen_recording.descriptions_are_mandatory}`
                );
              }
            } else {
              this.$toast.error(
                `${this.translation.answer_task.screen_recording.maximum_number_recordings}: ${this.max}`
              );
            }
          } else {
            this.$toast.error(
              `${this.translation.answer_task.screen_recording.minimum_number_recordings}: ${this.min}`
            );
          }
        } else {
          this.$toast.error(this.translation.answer_task.error.empty_answer);
        }
      }
    },
    answer() {
      const emptyDescription = this.videos.filter(
        video => !video.description.length
      );

      if (!this.loading) {
        if (this.videos.length) {
          if (this.videos.length >= this.min) {
            if (this.videos.length <= this.max) {
              if (!this.mandatoryDescription || !emptyDescription.length) {
                if (this.obligatoryComment && !this.comments.length) {
                  this.$toast.error(
                    this.translation.answer_task.obligatory_comment_tost
                  );

                  return;
                }

                if (
                  this.comments.length &&
                  this.hasCharactersLimitation &&
                  this.comments.length < this.minCharacters
                ) {
                  this.$toast.error(
                    `${this.translation.answer_task.min_characters} ${this.minCharacters} ${this.translation.answer_task.characters}`
                  );

                  return;
                }

                if (this.comments.length && this.hasWordsLimitation) {
                  const wordsCount = this.comments.split(/\s+/).length;

                  if (wordsCount < this.minWords) {
                    this.$toast.error(
                      `${this.translation.answer_task.min_word} ${this.minWords} ${this.translation.answer_task.words}`
                    );

                    return;
                  }
                }

                this.loading = true;

                const answer = {
                  comment: this.comments,
                  videos: this.videos.map((video, index) => ({
                    index,
                    ...video
                  })),
                  video_folder: `tmp/video/${this.screenRecordingTaskFolder}`
                };

                const body = {
                  task_id: this.task.id,
                  participant_id: this.$store.getters.info.user.sub,
                  answer: answer,
                  raw_answer: JSON.stringify(answer),
                  has_attachment: 0,
                  has_image: 0,
                  has_video: 1
                };

                api
                  .post("/answer/send", body, {
                    headers: {
                      "Content-Type": "application/json",
                      Authorization: `Bearer ${this.$store.getters.token}`
                    }
                  })
                  .then(r => {
                    if (r.status === 200) {
                      this.$toast.success(
                        this.translation.answer_task.success.reply_registered
                      );
                      this.getTasks("save");
                      this.loading = false;
                    }
                  })
                  .catch(error => {
                    if (error.response.status === 400) {
                      this.$toast.error(
                        this.translation.answer_task.error.empty_answer
                      );
                    } else {
                      this.$toast.error(
                        this.translation.answer_task.error.reply_not_registered
                      );
                    }
                    this.loading = false;
                  });
              } else {
                this.$toast.error(
                  `${this.translation.answer_task.screen_recording.descriptions_are_mandatory}`
                );
              }
            } else {
              this.$toast.error(
                `${this.translation.answer_task.reaction.maximum_number}: ${this.max}`
              );
            }
          } else {
            this.$toast.error(
              `${this.translation.answer_task.reaction.minimum_number}: ${this.min}`
            );
          }
        } else {
          this.$toast.error(this.translation.answer_task.error.empty_answer);
        }
      }
    },
    init() {
      if (
        this.task.is_answered ||
        (this.task.answer && this.$route.query._preview)
      ) {
        this.comments = this.task.answer.answer_object.comment;
        this.videos = this.task.answer.answer_object.videos;
      } else {
        this.$store.commit(
          "UPDATE_SCREEN_RECORDING_TASK_FOLDER",
          `answer-screen-recording-${uuidv4()}`
        );

        this.comments = "";
        this.videos = [];
      }
    }
  },
  created() {
    this.init();
  },
  mounted() {
    if (this.$store.getters.info.user.type === userTypesText.participant) {
      const taskWrapper = document.querySelector(".task-wrapper");

      taskWrapper.addEventListener("contextmenu", e => {
        e.preventDefault();
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.task-text {
  display: flex;
  flex-direction: column;
  color: #6b6b6b;
  overflow-wrap: break-word;
}

.comments {
  label {
    color: #6b6b6b;
  }
  textarea {
    width: 100%;
    display: block;
    border: 1px solid #b3b3b3;
    border-radius: 10px;
  }
}

.select-box {
  display: inline-block;
  padding: 20px;
  border-radius: 20px;
  color: #6b6b6b;
  font-weight: bold;
  font-size: 1.2rem;
}

.videos {
  display: flex;
  align-items: flex-start;
  video {
    height: 170px;
  }
  > div {
    width: 100%;
    display: flex;
    flex-direction: column;
    margin-left: 10px;
    justify-content: space-between;
    height: 170px;
    p {
      font-weight: bold;
      color: #6b6b6b;
    }
    button {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 30px;
      height: 30px;
      font-weight: bold;
      color: #fff;
      font-size: 1rem;
      border-radius: 50%;
      white-space: nowrap;
      background: #df2938;
    }
    textarea {
      min-height: 120px;
    }
  }
}

@media (max-width: 767.98px) {
  .videos {
    video {
      height: 100px;
    }
    > div {
      height: 100px;
      textarea {
        min-height: 50px;
      }
    }
  }
}

.video-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  textarea {
    max-width: 800px;
    width: 100%;
  }
  .video-box {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    position: relative;
    p {
      position: absolute;
      max-width: 700px;
      text-align: center;
      color: #ffffff;
      top: calc(50% - 65px);
      font-size: 0.9rem;
      padding: 10px;
    }
    video {
      border: 1px solid #999;
      max-width: 800px;
      width: 100%;
      margin: 10px;
      background: black;
    }
  }
  .btns-capture {
    button {
      background: #10c398;
      color: #ffffff;
      font-weight: bold;
      font-size: 0.8rem;
      text-transform: uppercase;
      margin-right: 10px;
      margin-top: 5px;
      &:nth-of-type(2) {
        background: #df2938;
        margin-right: 0px;
      }
    }
  }
}

@media (max-width: 767.98px) {
  .video-wrapper {
    .video-box {
      p {
        font-size: 0.8rem;
      }
    }
  }
}
</style>
