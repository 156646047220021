<template>
  <div>
    <div
      style="min-height: 85px"
      class="d-flex align-items-center flex-wrap py-4 px-2"
    >
      <div class="d-flex align-items-center pr-3">
        <span class="pb-1">{{ translation.create_task.slider.points }}:</span>
        <dropdown
          :width="60"
          dropId="dot"
          :options="options"
          :value="dataDot"
          @update="data => (dataDot = data)"
        />
      </div>
      <div class="d-flex mr-2 my-3 my-md-0">
        <span class="pr-2">Emoji</span>
        <b-form-checkbox v-model="sliderEmoji" name="check-button" switch>
        </b-form-checkbox>
      </div>
      <div :class="{ active: sliderError }" class="error-box bg-danger p-2">
        <b-icon class="mr-1" icon="exclamation-triangle-fill"></b-icon>
        {{ sliderError }}
      </div>
    </div>
    <div class="slider">
      <div class="d-flex flex-wrap align-items-center pb-5">
        <div
          :class="{
            ['col-lg-8']: sliderEmoji,
            ['col-12']: !sliderEmoji
          }"
          class="d-flex flex-column flex-lg-row align-items-center"
        >
          <div
            class="input-slider"
            :style="{
              'border-color': `${$store.getters.theme[2]}`
            }"
            v-for="(dot, index) in slider"
            :key="index"
          >
            <b-form-input
              class="code"
              v-model="dot.codeString"
              maxlength="10"
              :state="
                !isCodeValid(dot.codeString) &&
                  !hasDuplicate(slider, 'codeString') &&
                  !!dot.codeString.length &&
                  dot.codeString !== '0'
              "
              v-b-tooltip.hover="{ variant: 'secondary' }"
              :title="translation.create_task.error.code_validation"
            ></b-form-input>
            <input
              maxlength="28"
              :id="`dot-${index}`"
              :class="{
                active: activeSliderInput === index,
                ['text-danger']: redText
              }"
              @click="activeInput(index)"
              @keyup="activeInput(index)"
              type="text"
              v-model="dot.value"
            />
            <div
              class="dot"
              :style="{
                background: $store.getters.theme[2]
              }"
            ></div>
          </div>
        </div>
        <div class="col-lg-4 pt-5 d-flex justify-content-center">
          <VEmojiPicker v-if="sliderEmoji" @select="selectEmoji" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { VEmojiPicker } from "v-emoji-picker";
import dropdown from "@/components/general/dropdown.vue";
import { outsideClick } from "@/helpers.js";
import { taskEnum, addTaskText, regexpCodeString } from "@/constants";

export default {
  components: {
    dropdown,
    VEmojiPicker
  },
  props: {
    taskText: {
      required: true
    },
    task: {
      required: true
    }
  },
  data() {
    return {
      dataDot: {
        value: 2,
        text: 2
      },
      slider: [],
      sliderEmoji: false,
      activeSliderInput: null,
      outsideClick,
      taskEnum,
      addTaskText,
      regexpCodeString
    };
  },
  computed: {
    taskObj() {
      return {
        statement: this.taskText,
        points: this.slider.map((point, index) => ({
          index: index,
          value: point.value,
          code_string: point.codeString
        })),
        has_emoji: this.sliderEmoji ? 1 : 0
      };
    },
    redText() {
      let resp = false;
      if (
        this.hasEmpty(this.slider) ||
        this.hasZeros(this.slider) ||
        this.hasDuplicate(this.slider, "value") ||
        this.hasDuplicate(this.slider, "codeString")
      )
        resp = true;

      return resp;
    },
    sliderError() {
      let message = null;
      if (this.hasEmpty(this.slider, "value"))
        message = this.translation.create_task.slider.error.empty_field;
      else if (this.hasDuplicate(this.slider, "value"))
        message = this.translation.create_task.slider.error.duplicated_fields;
      else if (this.hasDuplicate(this.slider, "codeString"))
        message = this.translation.create_task.slider.error.duplicated_codes;
      else if (this.hasZeros(this.slider))
        message = this.translation.create_task.error.the_field_cannot;
      else if (this.hasEmpty(this.slider, "codeString"))
        message = this.translation.create_task.error.error_empty_code_field;
      else if (this.slider.filter(dot => dot.codeString === "0").length)
        message = this.translation.create_task.error.number_zero;
      else if (
        this.slider.filter(dot => this.isCodeValid(dot.codeString)).length
      )
        message = this.translation.create_task.error.code_validation;

      return message;
    },
    options() {
      const array = [];
      for (let i = 2; i <= 10; i++) {
        array.push({
          value: i,
          text: i
        });
      }
      return array;
    }
  },
  watch: {
    taskObj() {
      this.$emit("update", this.taskObj);
    },
    sliderError() {
      this.$emit("error", this.sliderError);
    },
    dataDot() {
      let range;
      if (this.dataDot.value > this.slider.length) {
        range = this.dataDot.value - this.slider.length;
        const count = this.slider.length;
        for (let i = 0; i < range; i++) {
          this.slider.push({
            value: "",
            codeString: `${count + i + 1}`
          });
        }
      } else {
        range = this.slider.length - this.dataDot.value;
        this.slider.splice(this.dataDot.value, this.slider.length - 1);
      }
    }
  },
  methods: {
    hasZeros(array) {
      return array.some(option => option.value === "0");
    },
    isCodeValid(codeString) {
      return codeString.match(this.regexpCodeString);
    },
    hasDuplicate(array, key) {
      const valuesSoFar = [];
      for (let i = 0; i < array.length; ++i) {
        const value = array[i][key].trim();
        if (valuesSoFar.indexOf(value) !== -1) {
          return true;
        }
        valuesSoFar.push(value);
      }
      return false;
    },
    hasEmpty(array) {
      return array.some(option => !option.value.length);
    },
    selectEmoji(emoji) {
      const element = document.querySelector(`#dot-${this.activeSliderInput}`);
      if (
        this.activeSliderInput !== null &&
        element.classList.contains("active")
      ) {
        this.slider[this.activeSliderInput].value += emoji.data;
      }
    },
    activeInput(index) {
      this.activeSliderInput = index;
      const element = document.querySelector(`#dot-${index}`);
      element.classList.add("active");
      this.outsideClick(element, ["click"], () => {
        element.classList.remove("active");
      });
    },
    getDescription() {
      if (
        this.task !== this.addTaskText &&
        +this.task.type_id === this.taskEnum.slider.id
      ) {
        this.slider = this.task.question_object.points.map((point, index) => ({
          value: point.value,
          codeString: point.code_string ? point.code_string : `${index + 1}`
        }));

        this.dataDot = {
          value: this.slider.length,
          text: this.slider.length
        };

        if (this.task.question_object.has_emoji) this.sliderEmoji = true;
      } else {
        this.setTask();
      }
      this.$emit("update", this.taskObj);
    },
    setTask() {
      this.dataDot = {
        value: 2,
        text: 2
      };
      this.slider = [
        {
          value: `${this.translation.create_task.slider.point} 1`,
          codeString: "1"
        },
        {
          value: `${this.translation.create_task.slider.point} 2`,
          codeString: "2"
        }
      ];
      this.sliderEmoji = false;
    },
    init() {
      if (this.task === this.addTaskText) {
        this.setTask();
      }
    }
  },
  created() {
    this.init();
    this.getDescription();
  }
};
</script>

<style lang="scss" scoped>
.input-slider {
  flex: 1;
  max-width: 300px;
  position: relative;
  padding-bottom: 10px;
  animation: show 0.3s linear;
  border-bottom: 2px solid;
  input {
    &:nth-of-type(2) {
      border: 1px solid #c4c4c4;
      border-radius: 2px;
      text-align: center;
      color: #999999;
      font-size: 0.8rem;
      padding: 5px;
      width: 100%;
      transition: all 0.3s ease;
      margin-right: 2px;
      &:focus {
        outline: none;
        border: 1px solid #6b6b6b;
        box-shadow: none;
      }
      &.active {
        border: 1px solid #6b6b6b;
      }
    }
  }
  .dot {
    position: absolute;
    width: 15px;
    height: 15px;
    display: block;
    border-radius: 50%;
    left: calc(50% - 7.5px);
    bottom: -9px;
  }
}

@media (max-width: 991.98px) {
  .input-slider {
    border-bottom: none;
    padding-left: 10px;
    border-left: 2px solid;
    .dot {
      bottom: calc(50% - 2.5px);
      left: -9px;
    }
  }
}

.code {
  width: 95px;
  margin: 5px auto 5px;
  box-shadow: 0px 0px 37px -16px #c4c4c4;
  border-radius: 14px;
  border: 3px solid #ebf2f2;
  color: #6b6b6b;
  font-weight: bold;
  &:focus {
    outline: none;
    box-shadow: 0px 0px 37px -16px #c4c4c4;
    border: 3px solid #ebf2f2;
  }
}
</style>
