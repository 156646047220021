var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"row task-wrapper"},[(!_vm.loading)?_c('div',{staticClass:"col"},[_c('div',{staticClass:"task-text img-question"},[_c('p',{domProps:{"innerHTML":_vm._s(_vm.text)}})]),_c('div',{staticClass:"d-flex flex-wrap"},[_c('attachment',{attrs:{"attachments":_vm.task.attachments,"deleteFile":null}})],1),_c('div',[_c('div',{staticClass:"slider mb-5 mt-5 mt-lg-4"},[_c('div',{staticClass:"\n            d-flex\n            flex-column flex-lg-row\n            align-items-center\n            justify-content-center\n          "},_vm._l((_vm.slider),function(dot,index){
var _obj;
return _c('div',{key:index,staticClass:"item px-3 py-2",style:({
              'border-color': '#ccc'
            })},[_c('p',{staticClass:"text"},[_vm._v(_vm._s(dot.text))]),_c('div',{staticClass:"dot",style:(( _obj = {
                background: dot.value ? _vm.$store.getters.theme[2] : '#ccc'
              }, _obj['box-shadow'] = dot.value
                  ? "0px 0px 8px rgba(100, 100, 100, 0.25)"
                  : 'none', _obj )),on:{"click":function($event){return _vm.toggleDot(index)}}})])}),0)]),_c('div',{staticClass:"comments"},[(
            _vm.task.question_object.has_custom_comment_label &&
              _vm.task.question_object.comment_label &&
              _vm.task.question_object.comment_label.length
          )?_c('label',{attrs:{"for":"comments"}},[_vm._v(_vm._s(_vm.task.question_object.comment_label))]):_c('label',{attrs:{"for":"comments"}},[_vm._v(" "+_vm._s(_vm.translation.answer_task.comments)+" ")]),_c('textarea',{directives:[{name:"model",rawName:"v-model",value:(_vm.comments),expression:"comments"}],staticClass:"form-control",attrs:{"id":"textarea","maxlength":_vm.maxCharacters,"rows":"3","max-rows":"6","disabled":_vm.task.is_answered},domProps:{"value":(_vm.comments)},on:{"input":function($event){if($event.target.composing){ return; }_vm.comments=$event.target.value}}}),_c('characterAndWordCounter',{attrs:{"count":_vm.hasWordsLimitation
              ? this.comments.length
                ? this.comments.trim().split(/\s+/).length
                : 0
              : _vm.comments.length,"max":_vm.hasWordsLimitation ? _vm.maxWords : _vm.maxCharacters,"label":_vm.hasWordsLimitation
              ? _vm.translation.answer_task.words
              : _vm.translation.answer_task.characters}})],1)])]):_vm._e(),(_vm.loading)?_c('div',{staticClass:"w-100 d-flex justify-content-center"},[_c('loader')],1):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }