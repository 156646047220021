import Vue from "vue";
import VueRouter from "vue-router";
import Feed from "../views/Feed.vue";
import Tasks from "../views/Tasks.vue";
import completedActivity from "../views/CompletedActivity.vue";
import Activities from "../views/Activities.vue";
import CreateActivity from "../views/CreateActivity.vue";
import CreateTask from "../views/CreateTask.vue";
import Answers from "../views/Answers.vue";
import Account from "../views/Account.vue";
import Customization from "../views/Customization.vue";
import TermsAndPolicy from "../views/TermsAndPolicy.vue";
import PrivacyPolicy from "../views/PrivacyPolicy.vue";
import TermsOfUse from "../views/TermsOfUse.vue";
import Login from "../views/Login.vue";
import Pass from "../views/Pass.vue";
import CreateAccount from "../views/CreateAccount.vue";
import Email from "../views/Email.vue";
import Post from "../views/Post.vue";
import NewPost from "../views/NewPost.vue";
import Forum from "../views/Forum.vue";
import Communities from "../views/Communities.vue";
import CreateCommunity from "../views/CreateCommunity.vue";
import Segmentation from "../views/Segmentation.vue";
import AddParticipant from "../views/AddParticipant.vue";
import ManageParticipants from "../views/ManageParticipants.vue";
import AddManageOperators from "../views/Operation.vue";
import Participant from "../views/Participant.vue";
import Logs from "../views/Logs.vue";
import Chat from "../views/Chat.vue";
import StatsActivities from "../views/StatsActivities.vue";
import PrivacyAndPermissions from "../views/PrivacyAndPermissions.vue";
import NotFound from "../views/NotFound.vue";
import { userTypesText } from "@/constants";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "feed",
    component: Feed
  },
  {
    path: "/customize",
    name: "customization",
    component: Customization,
    beforeRouteEnter: (to, from, next) => {
      const store = require("../store").default;

      if (
        store.getters.info.user.type === userTypesText.admin ||
        store.getters.info.user.type === userTypesText.moderator
      ) {
        next();
      } else {
        next({ name: "feed" });
      }
    }
  },

  {
    path: "/terms-and-policy",
    name: "terms-and-policy",
    component: TermsAndPolicy,
    beforeEnter: (to, from, next) => {
      const store = require("../store").default;

      if (
        store.getters.info.user.type === userTypesText.admin ||
        store.getters.info.user.type === userTypesText.moderator
      ) {
        next();
      } else {
        next({ name: "feed" });
      }
    }
  },
  {
    path: "/account",
    name: "account",
    component: Account,
    beforeEnter: (to, from, next) => {
      const store = require("../store").default;
      if (!store.getters.is_over) {
        next();
      } else {
        next({ name: "feed" });
      }
    }
  },
  {
    path: "/privacy-policy",
    name: "privacypolicy",
    component: PrivacyPolicy
  },
  {
    path: "/terms-of-use",
    name: "termsofuse",
    component: TermsOfUse
  },
  {
    path: "/assignments",
    name: "activities",
    component: Activities
  },
  {
    path: "/assignment",
    name: "createactivity",
    component: CreateActivity,
    beforeEnter: (to, from, next) => {
      const store = require("../store").default;
      if (
        store.getters.info.user.type === userTypesText.admin ||
        store.getters.info.user.type === userTypesText.moderator
      ) {
        next();
      } else {
        next({ name: "" });
      }
    }
  },
  {
    path: "/task",
    name: "createtask",
    component: CreateTask,
    beforeEnter: (to, from, next) => {
      const store = require("../store").default;
      if (
        (store.getters.info.user.type === userTypesText.moderator ||
          store.getters.info.user.type === userTypesText.admin) &&
        !store.getters.is_over
      ) {
        next();
      } else {
        next({ name: "" });
      }
    }
  },
  {
    path: "/replies",
    name: "answers",
    component: Answers,
    beforeEnter: (to, from, next) => {
      const store = require("../store").default;
      if (store.getters.info.user.type !== userTypesText.participant) {
        next();
      } else {
        next({ name: "" });
      }
    }
  },
  {
    path: "/forum",
    name: "forum",
    component: Forum
  },
  {
    path: "/tasks",
    name: "tasks",
    component: Tasks
  },
  {
    path: "/completed",
    name: "completedactivity",
    component: completedActivity
  },
  {
    path: "/createaccount",
    name: "createaccount",
    component: CreateAccount
  },
  {
    path: "/login",
    name: "login",
    component: Login,
    beforeEnter: (to, from, next) => {
      const store = require("../store").default;
      if (store.getters.token && !store.state.isLogOut) {
        next({ name: "" });
      } else {
        next();
      }
    }
  },
  {
    path: "/pass",
    name: "pass",
    component: Pass,
    beforeEnter: (to, from, next) => {
      const store = require("../store").default;
      if (store.getters.token) {
        next({ name: "" });
      } else {
        next();
      }
    }
  },
  {
    path: "/email",
    name: "email",
    component: Email,
    beforeEnter: (to, from, next) => {
      const store = require("../store").default;
      if (store.getters.token) {
        next({ name: "" });
      } else {
        next();
      }
    }
  },
  {
    path: "/post",
    name: "post",
    component: Post
  },
  {
    path: "/newpost",
    name: "newpost",
    component: NewPost,
    beforeEnter: (to, from, next) => {
      const store = require("../store").default;
      if (
        store.getters.info.user.type === userTypesText.moderator ||
        store.getters.info.user.type === userTypesText.admin
      ) {
        next();
      } else {
        next({ name: "" });
      }
    }
  },
  {
    path: "/communities",
    name: "communities",
    component: Communities,
    beforeEnter: (to, from, next) => {
      const store = require("../store").default;
      if (store.getters.info.user.type === userTypesText.admin) {
        next();
      } else {
        next({ name: "feed" });
      }
    }
  },
  {
    path: "/community",
    name: "createcommunity",
    component: CreateCommunity,
    beforeEnter: (to, from, next) => {
      const store = require("../store").default;
      if (store.getters.info.user.type === userTypesText.admin) {
        next();
      } else {
        next({ name: "feed" });
      }
    }
  },
  {
    path: "/segmentation",
    name: "segmentation",
    component: Segmentation,
    beforeEnter: (to, from, next) => {
      const store = require("../store").default;
      if (store.getters.info.user.type === userTypesText.admin) {
        next();
      } else {
        next({ name: "feed" });
      }
    }
  },
  {
    path: "/add-participant",
    name: "addparticipant",
    component: AddParticipant,
    beforeEnter: (to, from, next) => {
      const store = require("../store").default;
      if (store.getters.info.user.type === userTypesText.admin) {
        next();
      } else {
        next({ name: "feed" });
      }
    }
  },
  {
    path: "/manage-participants",
    name: "manageparticipants",
    component: ManageParticipants,
    beforeEnter: (to, from, next) => {
      const store = require("../store").default;
      if (
        store.getters.info.user.type === userTypesText.admin ||
        store.getters.info.user.type === userTypesText.moderator ||
        store.getters.info.user.type === userTypesText.observer
      ) {
        next();
      } else {
        next({ name: "feed" });
      }
    }
  },
  {
    path: "/add-and-manage-operators",
    name: "operators",
    component: AddManageOperators,
    beforeEnter: (to, from, next) => {
      const store = require("../store").default;
      if (store.getters.info.user.type === userTypesText.admin) {
        next();
      } else {
        next({ name: "feed" });
      }
    }
  },
  {
    path: "/participant",
    name: "participant",
    component: Participant,
    beforeEnter: (to, from, next) => {
      const store = require("../store").default;
      if (
        store.getters.info.user.type === userTypesText.admin ||
        store.getters.info.user.type === userTypesText.moderator ||
        store.getters.info.user.type === userTypesText.observer
      ) {
        next();
      } else {
        next({ name: "feed" });
      }
    }
  },
  {
    path: "/logs",
    name: "logs",
    component: Logs,
    beforeEnter: (to, from, next) => {
      const store = require("../store").default;
      if (store.getters.info.user.type !== userTypesText.participant) {
        next();
      } else {
        next({ name: "" });
      }
    }
  },
  {
    path: "/chat",
    name: "chat",
    component: Chat,
    beforeEnter: (to, from, next) => {
      const store = require("../store").default;
      if (store.getters.info.user.type !== userTypesText.participant) {
        next();
      } else {
        next({ name: "" });
      }
    }
  },
  {
    path: "/stats-assignments",
    name: "statsactivities",
    component: StatsActivities,
    beforeEnter: (to, from, next) => {
      const store = require("../store").default;
      if (
        store.getters.info.user.type === userTypesText.admin ||
        store.getters.info.user.type === userTypesText.moderator ||
        store.getters.info.user.type === userTypesText.observer
      ) {
        next();
      } else {
        next({ name: "feed" });
      }
    }
  },
  {
    path: "/privacy-and-permissions",
    name: "privacyandpermissions",
    component: PrivacyAndPermissions,
    beforeEnter: (to, from, next) => {
      const store = require("../store").default;
      if (store.getters.info.user.type === userTypesText.admin) {
        next();
      } else {
        next({ name: "feed" });
      }
    }
  },
  {
    path: "*",
    name: "notFound",
    component: NotFound
  }
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes
});

router.beforeEach((to, from, next) => {
  const store = require("../store").default;
  if (
    to.name !== "login" &&
    to.name !== "pass" &&
    to.name !== "code" &&
    to.name !== "email" &&
    to.name !== "createaccount" &&
    !store.getters.token
  )
    next({ name: "login" });
  else {
    next();
  }
});

router.beforeEach((to, from, next) => {
  const store = require("../store").default;

  if (
    to.name !== "login" &&
    to.name !== "pass" &&
    to.name !== "code" &&
    to.name !== "email" &&
    to.name !== "createaccount" &&
    to.name !== "communities" &&
    to.name !== "createcommunity" &&
    !store.getters.community &&
    store.getters.info &&
    store.getters.info.user.type === userTypesText.admin
  )
    next({ name: "communities" });
  else {
    next();
  }
});

export default router;
