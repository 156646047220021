<template>
  <div class="pb-5">
    <div v-if="participants.length">
      <div
        class="d-flex flex-wrap"
        v-for="(participant, index) in participants"
        :key="index"
      >
        <div class="col-md-2 py-1 px-md-2 px-0">
          <router-link
            :to="{
              name: 'participant',
              query: { _id: participant[0].participant.id, _page: 1 }
            }"
            class="participant-info"
          >
            <div
              :style="{
                'background-color': $store.getters.theme[0],
                color: $store.getters.theme[3]
              }"
            >
              <img
                v-if="participant[0].participant.picture.length"
                :src="participant[0].participant.picture"
                alt="Profile Picture"
              />
              <b-icon
                v-else
                class="icon-profile"
                icon="person-fill"
                aria-hidden="true"
              ></b-icon>
            </div>
            <p
              :style="{
                color: $store.getters.theme[3]
              }"
            >
              <span
                v-if="$store.getters.info.user.type === userTypesText.moderator"
              >
                {{
                  namePrivacy(
                    privacy.moderators.participants_identifier_type_id,
                    participant[0].participant.name,
                    participant[0].participant.id
                  )
                }}
              </span>
              <span
                v-else-if="
                  $store.getters.info.user.type === userTypesText.observer
                "
              >
                {{
                  namePrivacy(
                    privacy.observers.participants_identifier_type_id,
                    participant[0].participant.name,
                    participant[0].participant.id
                  )
                }}
              </span>
              <span v-else>
                {{ participant[0].participant.name }}
              </span>
            </p>
          </router-link>
        </div>
        <div class="col-md-10 py-1 px-md-2 px-0">
          <div class="participant-wrapper mb-4">
            <div
              class="answer-wrapper"
              v-for="(answer, aIndex) in participant"
              :key="answer.answer_id"
            >
              <div class="activity px-md-3 px-2 py-2 mt-3">
                <img
                  class="task-icon"
                  :style="{ background: $store.getters.theme[1] }"
                  v-b-tooltip.hover="{ variant: 'secondary' }"
                  :title="taskText(answer.task)"
                  :src="taskImage(answer.task)"
                />
                <div :style="{ background: $store.getters.theme[1] }">
                  <span :style="{ color: $store.getters.theme[3] }">{{
                    answer.activity.name
                  }}</span>
                  -
                  <span :style="{ color: $store.getters.theme[2] }">
                    {{
                      answer.task.code_string
                        ? answer.task.code_string
                        : `T${+answer.task.index + 1}`
                    }}
                  </span>
                </div>
              </div>
              <div class="answer p-md-3 m-3">
                <get-task-data :task="answer.task" />
                <div class="answer-box">
                  <p :style="{ color: $store.getters.theme[3] }">
                    {{ translation.replies.replies }}
                  </p>
                  <get-answer-data :answer="answer" />
                </div>
                <div
                  class="buttons-comment d-flex justify-content-between align-items-center mt-3"
                >
                  <div class="d-flex align-items-center">
                    <div
                      v-if="
                        $store.getters.info.user.type ===
                          userTypesText.moderator ||
                          $store.getters.info.user.type ===
                            userTypesText.admin ||
                          answer.participant.id === $store.getters.info.user.sub
                      "
                    >
                      <button
                        :title="translation.replies.reply_viewed"
                        v-b-tooltip.hover="{ variant: 'secondary' }"
                        @click="toggleValues(answer, aIndex, 'viewed')"
                        class="viewed-button blue"
                        :class="{
                          active: +answer.viewed,
                          participant: !(
                            $store.getters.info.user.type ===
                              userTypesText.moderator ||
                            $store.getters.info.user.type ===
                              userTypesText.admin
                          )
                        }"
                      >
                        <b-icon class="mr-1" icon="eye"></b-icon>
                      </button>
                      <button
                        :title="translation.replies.mark_reply_viewed"
                        v-b-tooltip.hover="{ variant: 'secondary' }"
                        @click="toggleValues(answer, aIndex, 'viewed')"
                        class="viewed-button"
                        :class="{
                          active: !+answer.viewed,
                          participant: !(
                            $store.getters.info.user.type ===
                              userTypesText.moderator ||
                            $store.getters.info.user.type ===
                              userTypesText.admin
                          )
                        }"
                      >
                        <img src="@/img/eye-closed.svg" alt="Not viewed" />
                      </button>
                    </div>
                    <reject-answer
                      :activeTask="answer.task"
                      :anwserIndex="answer.answer_id"
                      :answer="answer"
                      :getAnswers="getAnswers"
                    />

                    <button
                      v-if="
                        $store.getters.info.user.type !==
                          userTypesText.participant ||
                          answer.participant.id ===
                            $store.getters.info.user.sub ||
                          +answer.has_public_replies
                      "
                      class="btn-chat hover"
                      @click="toggleSubReplies(answer.answer_id)"
                      :style="{ color: $store.getters.theme[2] }"
                    >
                      {{
                        +answer.has_sub_replies
                          ? translation.replies.see_comments
                          : translation.replies.comment
                      }}
                    </button>
                  </div>
                  <div
                    v-if="
                      $store.getters.info.user.type ===
                        userTypesText.moderator ||
                        $store.getters.info.user.type === userTypesText.admin
                    "
                  >
                    <button
                      @click="toggleValues(answer, index, 'check')"
                      class="check-button blue"
                      :class="{
                        active: +answer.checked,
                        participant: !(
                          $store.getters.info.user.type ===
                            userTypesText.moderator ||
                          $store.getters.info.user.type === userTypesText.admin
                        )
                      }"
                    >
                      <b-icon class="mr-1" icon="check-circle"></b-icon>
                      {{ translation.replies.solved }}
                    </button>
                    <button
                      @click="toggleValues(answer, index, 'check')"
                      class="check-button"
                      :class="{
                        active: !+answer.checked,
                        participant: !(
                          $store.getters.info.user.type ===
                            userTypesText.moderator ||
                          $store.getters.info.user.type === userTypesText.admin
                        )
                      }"
                    >
                      <b-icon class="mr-1" icon="circle"></b-icon>
                      {{ translation.replies.solved }}
                    </button>
                  </div>
                </div>
                <sub-replies
                  :showSubReplies="showSubReplies"
                  :task="answer.task"
                  :answer="answer"
                  @close="showSubReplies = false"
                  @updateHasSubReplies="answer.has_sub_replies = 1"
                />
              </div>
              <div class="m-3">
                <labels :answerProp="answer" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-else class="d-flex justify-content-center no-reports">
      {{ translation.replies.no_reports }}
    </div>
  </div>
</template>

<script>
import { api } from "@/services.js";
import getTaskData from "@/components/task/getTaskData.vue";
import getAnswerData from "@/components/task/getAnswerData.vue";
import labels from "@/components/task/labels.vue";
import rejectAnswer from "@/components/task/rejectAnswer.vue";
import subReplies from "@/components/task/subReplies.vue";
import { userTypesText, taskEnum } from "@/constants";
import { namePrivacy } from "@/helpers.js";
import { mapState } from "vuex";

export default {
  props: {
    participants: {
      required: true
    },
    getAnswers: {
      required: true
    }
  },

  components: {
    getTaskData,
    getAnswerData,
    labels,
    rejectAnswer,
    subReplies
  },

  data() {
    return {
      showSubReplies: false,
      userTypesText,
      taskEnum
    };
  },

  computed: {
    ...mapState(["privacy"])
  },

  methods: {
    namePrivacy,

    taskText(task) {
      const type = Object.values(this.taskEnum).filter(
        typeObj => typeObj.id === +task.type_id
      )[0];
      return type.text[this.langAbbreviation];
    },

    taskImage(task) {
      const type = Object.values(this.taskEnum).filter(
        typeObj => typeObj.id === +task.type_id
      )[0];

      const images = require.context("../../img/", false, /\.svg$/);
      return images("./" + type.icon + ".svg");
    },

    toggleSubReplies(id) {
      if (this.showSubReplies === id) this.showSubReplies = false;
      else this.showSubReplies = id;
    },

    toggleValues(answer, index, type) {
      const url =
        type === "check"
          ? `answer/${answer.answer_id}/change-check-status?status=${
              +answer["checked"] ? 0 : 1
            }`
          : `answer/${answer.answer_id}/viewed/change-status?viewed=${
              +answer["viewed"] ? 0 : 1
            }`;

      if (
        this.$store.getters.info.user.type === this.userTypesText.moderator ||
        this.$store.getters.info.user.type === this.userTypesText.admin
      ) {
        api
          .put(
            url,
            {},
            {
              headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${this.$store.getters.token}`
              }
            }
          )
          .then(r => {
            if (r.status === 200) {
              if (type === "check") {
                answer.checked = +answer["checked"] ? 0 : 1;
              } else {
                answer.viewed = +answer["viewed"] ? 0 : 1;
              }
            }
          })
          .catch(() => {
            this.$toast.error(
              this.translation.errors_global.something_went_wrong
            );
          });
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.task-icon {
  object-fit: scale-down;
  display: block;
  width: 35px;
  height: 35px;
  border-radius: 50%;
  margin-right: 10px;
  padding: 3px;
  flex-shrink: 0;
}

.answer-wrapper {
  border: 1px solid #c4c4c4;
  border-radius: 11px;
  margin-bottom: 30px;
}

.answer {
  color: #6b6b6b;
}

.answer-box {
  p {
    margin-right: 10px;
    text-transform: uppercase;
    font-weight: bold;
    margin-bottom: 0;
  }
}

.activity {
  display: inline-flex;
  align-items: center;
  div {
    display: flex;
    align-items: center;
    font-weight: bold;
    border-top-right-radius: 20px;
    border-bottom-right-radius: 20px;
    height: 35px;
    padding: 0 10px 2px;
    span {
      margin: 5px;
    }
  }
}

@media (max-width: 567.98px) {
  .activity {
    font-size: 0.8rem;
    div {
      padding: 0 5px 2px;
    }
  }
}

.viewed-button {
  display: none;
  background: none;
  border: none;
  font-size: 1.3rem;
  color: #6b6b6b;
  animation: show 0.3s linear;
  img {
    width: 20.8px;
  }
  &:focus {
    outline: none;
  }
  &.active {
    display: block;
  }
  &.participant {
    cursor: default;
  }
  &.blue {
    color: #0087bf;
  }
}

.check-button {
  display: none;
  background: none;
  border: none;
  color: #6b6b6b;
  animation: show 0.3s linear;
  &:focus {
    outline: none;
  }
  &.active {
    display: block;
  }
  &.participant {
    cursor: default;
  }
  &.blue {
    color: #0087bf;
  }
}

.participant {
  display: flex;
  flex-direction: column;
  align-items: center;
  div {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 60px;
    height: 60px;
    border-radius: 50%;
    overflow: hidden;
    img {
      object-fit: cover;
      height: 60px;
      width: 60px;
    }
  }
  p {
    font-weight: bold;
    font-size: 0.81rem;
    margin-top: 5px;
    width: 90px;
    text-align: center;
    margin-bottom: 0;
  }
}

.btn-chat {
  border: none;
  background: none;
  color: #0087bf;
  font-weight: bold;
  text-decoration: underline;
  &:focus {
    outline: none;
  }
}

.participant-info {
  display: flex;
  flex-direction: column;
  align-items: center;
  div {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 60px;
    height: 60px;
    border-radius: 50%;
    overflow: hidden;
    img {
      object-fit: cover;
      height: 60px;
      width: 60px;
    }
  }
  p {
    font-weight: bold;
    font-size: 0.81rem;
    margin-top: 5px;
    width: 90px;
    text-align: center;
    margin-bottom: 0;
    word-wrap: break-word;
    word-break: break-word;
  }
}

.no-reports {
  color: #6b6b6b;
}

@media (max-width: 567.98px) {
  .participant-info {
    flex-direction: row;
  }

  .buttons-comment {
    font-size: 0.8rem;
  }
}
</style>
