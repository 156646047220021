<template>
  <div class="col-md-6">
    <imageCropper
      :img="img"
      :show="showCropper"
      :loader="loaderInsertImg"
      @updateImg="img => insertImg(img)"
      @trash="close"
    />
    <a v-if="!loaderConversation" class="close hover" @click="$emit('close')"
      ><b-icon class="mr-1" icon="x-circle"></b-icon>
    </a>
    <div
      v-if="!loaderConversation && activeTransmissionList"
      class="chat p-4 mt-2 mt-lg-0"
    >
      <div :style="{ color: $store.getters.theme[2] }" class="chat-user">
        {{ activeTransmissionList.name }}
      </div>

      <ul class="chat-scroll" ref="chatScroll">
        <li
          class="right"
          v-for="messageObj in oldMessages"
          :key="messageObj.id"
        >
          {{ messageObj.message.text }}
          <img
            v-if="messageObj.message.image"
            :src="messageObj.message.image"
            alt=""
          />
        </li>
      </ul>

      <form class="send-message">
        <button
          v-if="img"
          @click="close"
          :style="{ background: $store.getters.theme[2] }"
          class="btn delete-img"
        >
          <b-icon icon="x"></b-icon>
        </button>
        <label for="image-chat">
          <div v-if="!img" class="add-img">
            <b-icon icon="card-image" aria-hidden="true"></b-icon>
          </div>
          <input
            ref="inputImg"
            @change="uploadImg('image-chat')"
            id="image-chat"
            class="d-none"
            type="file"
            accept="image/*"
          />
        </label>
        <div :style="{ padding: img.length ? '10px' : 0 }" class="textarea">
          <b-form-textarea
            @keypress="submit"
            class="px-3"
            style="resize:none; overflow:hidden"
            size="sm"
            :placeholder="translation.chat.enter_a_message"
            v-model="message"
          ></b-form-textarea>
          <div class="img" v-if="img.length">
            <img :src="img" />
          </div>
        </div>
        <button
          ref="sendButton"
          @click.prevent="sendMessage"
          class="btn send"
          :style="{
            'background-color': $store.getters.theme[2]
          }"
        >
          <img src="@/img/send-message.svg" />
        </button>
      </form>
    </div>
    <div v-if="loaderConversation" class="py-5 d-flex justify-content-center">
      <loader2 :color="$store.getters.theme[2]" />
    </div>
  </div>
</template>

<script>
import { v4 as uuidv4 } from "uuid";
import axios from "axios";
import { api } from "@/services.js";
import loader2 from "@/components/general/loader2.vue";
import imageCropper from "@/components/general/imageCropper.vue";
import lambda from "@/lambda";
import { regexpImageName } from "@/constants";

export default {
  props: {
    userSource: {
      required: true
    },
    activeTransmissionListId: {
      required: true
    },
    getTransmissionMessages: {
      required: true
    }
  },
  components: {
    imageCropper,
    loader2
  },
  data() {
    return {
      message: "",
      img: "",
      imgName: "",
      showCropper: false,
      loaderInsertImg: false,
      regexpImageName,
      loaderConversation: false,
      activeTransmissionList: null,
      loaderTransmissionsList: false,
      transmissionsList: [],
      oldMessages: []
    };
  },
  watch: {
    activeTransmissionListId() {
      this.getTransmissionsList();
    },
    getTransmissionMessages() {
      if (this.activeTransmissionList) this.getOldMessages();
    }
  },
  methods: {
    getOldMessages() {
      this.loaderConversation = true;
      axios(
        `${this.chatHost}get-transmission-list-messages?id=${this.activeTransmissionList.id}`
      )
        .then(r => {
          if (r.status === 200) {
            this.oldMessages = r.data;
          }
          this.loaderConversation = false;
        })
        .catch(() => {
          this.loaderConversation = false;
        });
    },
    getTransmissionsList() {
      this.loaderTransmissionsList = true;

      axios(
        `${this.chatHost}get-transmissions-list?communityId=${this.$store.getters.community.id}&userId=${this.userSource.id}&userType=${this.userSource.type}`
      )
        .then(r => {
          if (r.status === 200) {
            this.transmissionsList = r.data;
            if (this.activeTransmissionListId)
              this.activeTransmissionList = this.transmissionsList.filter(
                list => list.id === this.activeTransmissionListId
              )[0];

            this.getOldMessages();
          }
          this.loaderTransmissionsList = false;
        })
        .catch(() => {
          this.loaderTransmissionsList = false;
        });
    },
    submit(e) {
      if (e.which === 13 && !e.shiftKey) {
        e.preventDefault();

        this.$refs.sendButton.click();
      }
    },
    sendMessage() {
      if (this.message.length || this.img.length) {
        const message = {
          text: this.message,
          image: this.img,
          sourceID: this.userSource.id
        };

        this.$emit("sendMessage", {
          message: message,
          userTargetList: this.activeTransmissionList.userTargetList,
          name: this.activeTransmissionList.name
        });

        if (this.$refs.chatScroll)
          this.$refs.chatScroll.scrollTop =
            this.$refs.chatScroll.scrollHeight + 10;

        this.message = "";
        this.img = "";
      }
    },
    close() {
      this.showCropper = false;
      this.$refs.inputImg.value = "";
      this.img = "";
    },
    insertImg(src) {
      this.loaderInsertImg = true;

      let arr = src.split(",");
      let mime = arr[0].match(/:(.*?);/)[1];
      let bstr = atob(arr[1]);
      let n = bstr.length;
      let u8arr = new Uint8Array(n);

      while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
      }

      const imgName = `${uuidv4()}-${this.imgName.replace(
        this.regexpImageName,
        ""
      )}`;

      const imgFile = new File([u8arr], imgName, {
        type: mime
      });

      const folder = `communities/${this.$store.getters.community.id}/Chat/${this.activeConversation.id}/Assets/${this.activeUser.type}/${this.activeUser.id}`;

      lambda
        .getSignedURL(imgFile, folder, imgName)
        .then(url => {
          api
            .put(url, imgFile, {
              headers: {
                "Content-Type": imgFile.type
              }
            })
            .then(r => {
              this.img = r.config.url.split("?")[0];
              this.$refs.inputImg.value = "";
              this.showCropper = false;
              this.loaderInsertImg = false;
            })
            .catch(() => {
              this.$toast.error(
                this.translation.errors_global.something_went_wrong
              );
              this.loaderInsertImg = false;
            });
        })
        .catch(() => {
          this.$toast.error(
            this.translation.errors_global.something_went_wrong
          );
          this.loaderInsertImg = false;
        });
    },
    async uploadImg(element) {
      const file = document.querySelector(`#${element}`).files[0];
      if (file) {
        const toBase64 = file =>
          new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => resolve(reader.result);
            reader.onerror = error => reject(error);
          });
        this.img = await toBase64(file);
        this.imgName = file.name;
        this.showCropper = true;
      }
    }
  },
  created() {
    this.getTransmissionsList();
  }
};
</script>
<style lang="scss" scoped>
.chat {
  width: 100%;
  display: flex;
  flex-direction: column;
  height: calc(100vh - 150px);
  min-height: 550px;
  background: #ffffff;
  box-shadow: 0px 0px 45px rgba(0, 0, 0, 0.1);
  border-top-right-radius: 40px;
  border-bottom-right-radius: 40px;

  .chat-user {
    border-bottom: 1px solid #c4c4c4;
    padding-bottom: 10px;
    font-weight: bold;
  }

  @media (max-width: 991.98px) {
    border-top-left-radius: 40px;
    border-bottom-left-radius: 40px;
  }

  .chat-scroll {
    display: flex;
    flex-direction: column;
    flex: 1;
    overflow-y: scroll;
    padding: 20px 0 55px;
    margin-bottom: 5px;
    &::-webkit-scrollbar {
      width: 8px;
    }
    &::-webkit-scrollbar-track {
      background: rgba(0, 0, 0, 0);
      border-radius: 10px;
    }
    &::-webkit-scrollbar-thumb {
      background: rgba(0, 0, 0, 0.1);
      border-radius: 10px;
      height: 40px;
    }
    &::-webkit-scrollbar-thumb:hover {
      background: rgba(0, 0, 0, 0.2);
    }
    li {
      max-width: 250px;
      background: #f4f5f7;
      border-radius: 15px;
      border-top-left-radius: 0;
      padding: 10px;
      margin-bottom: 10px;
      list-style: none;
      word-wrap: break-word;
      word-break: break-word;
      &.right {
        align-self: flex-end;
        border-top-right-radius: 0;
        border-top-left-radius: 15px;
        margin-right: 20px;
      }
      img {
        object-fit: contain;
        max-width: 230px;
        border-radius: 20px;
      }
    }
  }

  .send-message {
    display: flex;
    align-items: flex-end;
    position: relative;
    .delete-img {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 30px;
      height: 30px;
      border-radius: 50%;
      color: #ffffff;
      margin-right: 10px;
    }
    label {
      margin-bottom: 0;
      cursor: pointer;
      position: relative;
      .add-img {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 30px;
        height: 30px;
        color: #6b6b6b;
        background: #f4f5f6;
        border-radius: 50%;
        margin-right: 10px;
      }
    }
    .textarea {
      width: 100%;
      background: #f4f5f6;
      border-radius: 40px;
      margin-right: 10px;
      textarea {
        height: 30px;
        background: #f4f5f6;
        border: none;
        border-radius: 40px;
        &:focus {
          box-shadow: none;
        }
      }
      .img {
        display: flex;
        flex-direction: column;
        img {
          object-fit: contain;
          width: 200px;
          border-radius: 20px;
        }
      }
    }
    .send {
      width: 30px;
      height: 30px;
      border-radius: 50%;
      flex-shrink: 0;
      padding: 0;
      position: relative;
      img {
        position: absolute;
        object-fit: contain;
        width: 15px;
        height: 15px;
        right: 6px;
        top: 7px;
      }
    }
  }
}

.close {
  color: #df2938;
  cursor: pointer;
  position: absolute;
  top: 20px;
  right: 30px;
  font-size: 1.3rem;
}
</style>
