var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"sub-replies px-4 pb-2 pt-4",class:{ active: _vm.showSubReplies && _vm.showSubReplies === _vm.answer.answer_id }},[(
      _vm.subReplies.length ||
        _vm.$store.getters.info.user.type === _vm.userTypesText.moderator ||
        _vm.$store.getters.info.user.type === _vm.userTypesText.admin
    )?_c('div',[_vm._l((_vm.subReplies),function(subReply){return _c('div',{key:subReply.id},[_c('div',{staticClass:"d-flex align-items-center mb-3 subReply"},[_c('div',{staticClass:"participant"},[_c('div',{style:({
              'background-color': _vm.$store.getters.theme[0],
              color: _vm.$store.getters.theme[3]
            })},[(_vm.userOrigin(subReply).picture)?_c('img',{attrs:{"src":_vm.userOrigin(subReply).picture,"alt":"Picture"}}):_c('b-icon',{staticClass:"icon-profile",attrs:{"icon":"person-fill","aria-hidden":"true"}})],1),_c('p',{style:({
              color: _vm.$store.getters.theme[3]
            })},[(subReply.fk_origin_type_id === _vm.userTypes.participant)?_c('span',[(
                  _vm.$store.getters.info.user.type === _vm.userTypesText.moderator
                )?_c('span',[_vm._v(" "+_vm._s(_vm.namePrivacy( _vm.privacy.moderators.participants_identifier_type_id, _vm.userOrigin(subReply).name, _vm.userOrigin(subReply).id ))+" ")]):(
                  _vm.$store.getters.info.user.type === _vm.userTypesText.participant
                )?_c('span',[_vm._v(" "+_vm._s(_vm.namePrivacy( _vm.privacy.participants.participants_identifier_type_id, _vm.userOrigin(subReply).name, _vm.userOrigin(subReply).id ))+" ")]):(
                  _vm.$store.getters.info.user.type === _vm.userTypesText.observer
                )?_c('span',[_vm._v(" "+_vm._s(_vm.namePrivacy( _vm.privacy.observers.participants_identifier_type_id, _vm.userOrigin(subReply).name, _vm.userOrigin(subReply).id ))+" ")]):_c('span',[_vm._v(" "+_vm._s(_vm.userOrigin(subReply).name)+" ")])]):_c('span',[(
                  _vm.$store.getters.info.user.type === _vm.userTypesText.participant
                )?_c('span',[_vm._v(" "+_vm._s(_vm.namePrivacy( _vm.privacy.participants.admins_identifier_type_id, _vm.userOrigin(subReply).name, _vm.userOrigin(subReply).id ))+" ")]):_c('span',[_vm._v(" "+_vm._s(_vm.userOrigin(subReply).name)+" ")])])])]),_c('div',{staticClass:"ml-4 w-100"},[_c('div',{staticClass:"\n              pl-3\n              reply\n              d-flex\n              align-items-center\n              justify-content-between\n            "},[_vm._v(" "+_vm._s(subReply.reply)+" "),(
                +_vm.userOrigin(subReply).id === +_vm.$store.getters.info.user.sub
              )?_c('button',{staticClass:"btn-trash hover",on:{"click":function($event){return _vm.deleteSubReply(subReply.id)}}},[(_vm.loaderDelete && _vm.loaderDelete === subReply.id)?_c('loader2',{attrs:{"color":"#6b6b6b"}}):_vm._e(),(!_vm.loaderDelete)?_c('p',{staticClass:"m-0"},[_c('b-icon',{attrs:{"icon":"trash-fill"}})],1):_vm._e()],1):_vm._e()]),_c('div',{staticClass:"date-reply my-1"},[_vm._v(_vm._s(_vm._f("formatDate")(subReply.date)))])])])])}),(_vm.loaderSubReplies)?_c('div',{staticClass:"d-flex justify-content-center py-5"},[_c('loader')],1):_vm._e(),(_vm.$store.getters.info.user.type !== _vm.userTypesText.observer)?_c('b-form-textarea',{staticClass:"mt-4",attrs:{"id":"textarea","size":"sm","placeholder":_vm.translation.answer_task.comments},model:{value:(_vm.text),callback:function ($$v) {_vm.text=$$v},expression:"text"}}):_vm._e(),(_vm.$store.getters.info.user.type !== _vm.userTypesText.observer)?_c('div',{staticClass:"btns d-flex align-items-center justify-content-end mt-3 mb-4"},[_c('button',{staticClass:"btn btn-sm mr-2 hover",on:{"click":function($event){return _vm.$emit('close')}}},[_vm._v(" "+_vm._s(_vm.translation.global.cancel)+" ")]),_c('button',{staticClass:"btn btn-sm hover",on:{"click":_vm.send}},[(_vm.loaderSend)?_c('loader2',{attrs:{"color":"#6b6b6b"}}):_vm._e(),(!_vm.loaderSend)?_c('p',{staticClass:"m-0"},[_vm._v(" "+_vm._s(_vm.translation.global.send)+" ")]):_vm._e()],1)]):_vm._e(),(_vm.total > _vm.subReplies.length)?_c('div',{staticClass:"d-flex justify-content-center mb-4"},[_c('button',{staticClass:"btn btn-sm btn-load-more hover",style:({
          'background-color': _vm.$store.getters.theme[2]
        }),on:{"click":_vm.loadMore}},[_vm._v(" "+_vm._s(_vm.translation.global.load_more)+" ")])]):_vm._e()],2):_c('div',[_vm._v(" "+_vm._s(_vm.translation.forum.comments.no_comments)+" ")])])}
var staticRenderFns = []

export { render, staticRenderFns }