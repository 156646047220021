<template>
  <div class="pt-5">
    <div class="d-flex flex-wrap align-items-center justify-content-between">
      <h2
        class="title my-1 mb-3 mb-md-0"
        :style="{ color: $store.getters.theme[3] }"
      >
        {{ translation.add_participant.invite_participants }}
      </h2>

      <button class="btn btn-pattern my-1 my-md-0 hover" @click="exportData">
        <img class="mr-1" src="@/img/download-icon.svg" alt="Download icon" />
        {{ translation.add_participant.download_pattern }}
      </button>
    </div>

    <div class="mt-5 import-box-wrapper">
      <h3 class="mb-3">{{ translation.add_participant.import_lot_xlsx }}</h3>
      <p>
        {{ translation.add_participant.if_you_have_not }}
        <router-link :to="{ name: 'segmentation', query: { _page: 1 } }">
          {{ translation.global.click_here }}.
        </router-link>
      </p>

      <div class="import-box my-5">
        <div v-if="!fileName.length">
          <img src="@/img/drag-icon.svg" alt="Drag and drop icon" />
          <p>{{ translation.add_participant.drag_and_drop_the_XLSX }}</p>
        </div>
        <div v-else>
          <p class="d-flex flex-column align-items-center m-0">
            <b-icon icon="file-earmark-spreadsheet-fill"></b-icon>
            {{ fileName }}
          </p>
        </div>
        <input @change="readFile" type="file" accept=".xlsx, .xls" />
      </div>
    </div>
  </div>
</template>

<script>
import xlsxParser from "xlsx-parse-json";
import * as XLSX from "xlsx/xlsx.mjs";

export default {
  props: {
    next: {
      required: true
    },
    segments: {
      required: true
    }
  },
  data() {
    return {
      fileJSON: "",
      fileName: "",
      errorMessage: ""
    };
  },
  computed: {
    json_data() {
      const data = [
        {
          external_id: "",
          email: ""
        }
      ];

      this.segments.forEach(
        segment => (data[0][`${segment.name} [${segment.id}]`] = "")
      );

      return data;
    },
    segmentsIds() {
      return this.segments.map(segment => segment.id);
    }
  },
  watch: {
    next() {
      this.importJSON();
    }
  },
  methods: {
    exportData() {
      const dataWS = XLSX.utils.json_to_sheet(this.json_data);
      const wb = XLSX.utils.book_new();

      XLSX.utils.book_append_sheet(wb, dataWS, "data");
      XLSX.writeFile(wb, "pattern.xlsx");
    },
    readFile({ target }) {
      if (target.files.length) {
        this.fileJSON = "";

        const file = target.files[0];
        this.fileName = file.name;

        xlsxParser.onFileSelection(file).then(data => {
          const values = Object.values(data);
          if (values.length)
            this.fileJSON = values[0].map(line => ({
              ...line,
              external_id: line.external_id ? line.external_id : " "
            }));
        });
      }
    },
    hasDiffSegments() {
      const diffSegments = this.fileJSON.filter(line => {
        const keys = Object.keys(line);

        const segmentsCount = keys.filter(key => {
          const regexpMatch = key.match(/\[(\d+)]/);
          const id = regexpMatch ? regexpMatch[1] : -1;

          if (!regexpMatch) return false;
          return !this.segmentsIds.includes(id);
        }).length;

        return segmentsCount;
      });

      const hasDiffSegments = diffSegments.length;

      return hasDiffSegments;
    },
    hasMissingkeys() {
      const invalidKeys = this.fileJSON.filter(line => {
        if (!line.email) return true;
        if (!line.external_id) return true;

        const keys = Object.keys(line);

        const segmentsCount = keys.filter(key => {
          const regexpMatch = key.match(/\[(\d+)]/);
          const id = regexpMatch ? regexpMatch[1] : -1;
          return this.segmentsIds.includes(id);
        }).length;

        const hasAllSegments = this.segments.length === segmentsCount;
        if (!hasAllSegments) return true;

        return false;
      });

      const hasInvalidKeys = invalidKeys.length;

      return hasInvalidKeys;
    },
    hasDuplicate(array) {
      const valuesSoFar = [];
      for (let i = 0; i < array.length; ++i) {
        const value = array[i].trim();
        if (valuesSoFar.indexOf(value) !== -1) {
          return true;
        }
        valuesSoFar.push(value);
      }
      return false;
    },
    fileErrorMessage() {
      this.errorMessage = null;

      try {
        if (this.hasMissingkeys()) {
          this.errorMessage = this.translation.add_participant.errors.missing_keys;
        }

        if (this.hasDiffSegments()) {
          this.errorMessage = this.translation.add_participant.errors.different_segments;
        }

        const allEmails = this.fileJSON.map(line => line.email);
        if (this.hasDuplicate(allEmails)) {
          this.errorMessage = this.translation.add_participant.errors.duplicated_email;
        }
      } catch {
        this.errorMessage = this.translation.add_participant.errors.not_within_the_pattern;
      }

      return this.errorMessage;
    },
    importJSON() {
      if (this.fileJSON.length) {
        if (!this.fileErrorMessage()) {
          this.$store.commit("UPDATE_IMPORT_LOT_JOSN", this.fileJSON);
          this.$emit("goFowards");
        } else {
          this.$toast.error(this.fileErrorMessage());
        }
      } else {
        this.$toast.error(this.translation.add_participant.errors.empty_xlsx);
      }
    }
  },
  created() {
    this.$store.commit("UPDATE_IMPORT_LOT_JOSN", null);
  }
};
</script>

<style lang="scss" scoped>
.title {
  font-weight: bold;
  font-size: 1.3rem;
}

.import-box-wrapper {
  h3 {
    font-size: 1.1rem;
    color: #6b6b6b;
    font-weight: bold;
  }
  p {
    color: #6b6b6b;

    a {
      font-weight: bold;
      color: #6b6b6b;
      text-decoration: underline;
    }
  }
}

.import-box {
  width: 100%;
  position: relative;
  background: #f7fafa;
  border-radius: 10px;
  height: 150px;
  padding: 10px;

  p {
    text-align: center;
  }

  div {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 150px;
    svg {
      font-size: 2rem;
    }
  }

  input {
    display: block;
    position: absolute;
    background: red;
    top: 0;
    right: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    cursor: pointer;
  }
}

.btn-pattern {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 200px;
  background: #ebf2f2;
  border-radius: 25px;
  text-transform: uppercase;
  color: #6b6b6b;
  font-weight: bold;
  white-space: nowrap;
}
</style>
