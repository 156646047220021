var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"row"},[_c('div',{staticClass:"col"},[_c('search',{staticClass:"mt-5 mb-4"}),(!_vm.loaderParticipants && _vm.participantsPages.length)?_c('div',{staticClass:"d-flex flex-wrap"},[_vm._l((_vm.participantsPages),function(participant){return _c('div',{key:participant.id,staticClass:"col-lg-4 col-md-6"},[_c('router-link',{staticClass:"participant p-3 mb-4 hover",attrs:{"to":{
            name: 'participant',
            query: { _id: participant.id, _page: 1 }
          }}},[_c('div',{style:({
              'background-color': _vm.$store.getters.theme[0],
              color: _vm.$store.getters.theme[3]
            })},[(participant.picture && participant.picture.length)?_c('img',{attrs:{"src":participant.picture,"alt":"Profile Picture"}}):_c('b-icon',{staticClass:"icon-profile",attrs:{"icon":"person-fill","aria-hidden":"true"}})],1),_c('div',[_c('h3',{style:({ color: _vm.$store.getters.theme[2] })},[(
                  _vm.$store.getters.info.user.type === _vm.userTypesText.moderator
                )?_c('span',[_vm._v(" "+_vm._s(_vm.namePrivacy( _vm.privacy.moderators.participants_identifier_type_id, participant.name, participant.id ))+" ")]):(
                  _vm.$store.getters.info.user.type === _vm.userTypesText.observer
                )?_c('span',[_vm._v(" "+_vm._s(_vm.namePrivacy( _vm.privacy.observers.participants_identifier_type_id, participant.name, participant.id ))+" ")]):_c('span',[_vm._v(" "+_vm._s(participant.name)+" ")])]),(
                _vm.$store.getters.info.user.type === _vm.userTypesText.admin ||
                  (_vm.$store.getters.info.user.type ===
                    _vm.userTypesText.moderator &&
                    !+_vm.privacy.moderators.participants_hide_email) ||
                  (_vm.$store.getters.info.user.type === _vm.userTypesText.observer &&
                    !+_vm.privacy.observers.participants_hide_email)
              )?_c('p',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",value:({ variant: 'secondary' }),expression:"{ variant: 'secondary' }",modifiers:{"hover":true}}],attrs:{"title":participant.email}},[_vm._v(" "+_vm._s(participant.email)+" ")]):_vm._e(),_c('p',[_vm._v("ID "+_vm._s(participant.id))]),_c('a',{staticClass:"hover",style:({ color: _vm.$store.getters.theme[2] })},[_vm._v(_vm._s(_vm.translation.manage_participants.view_participant))])])])],1)}),(!_vm.queryValue && _vm.minPerPage < _vm.total)?_c('pagination-query',{attrs:{"perPage":_vm.perPage,"total":_vm.total,"options":[12, 24, 48, 96]},on:{"perPageUpdate":function (newPerPage) { return (_vm.perPage = newPerPage); }}}):_vm._e()],2):(_vm.loaderParticipants)?_c('div',{staticClass:"d-flex justify-content-center py-5"},[_c('loader')],1):(_vm.queryValue)?_c('div',{staticClass:"result d-flex justify-content-center py-5"},[_vm._v(" "+_vm._s(_vm.translation.global.sorry_we_have)+" \""+_vm._s(_vm.queryValue)+"\" ")]):_c('div',{staticClass:"d-flex justify-content-center py-5"},[_vm._v(" "+_vm._s(_vm.translation.manage_participants.no_participants)+" ")])],1)])}
var staticRenderFns = []

export { render, staticRenderFns }