var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"container"},[_c('div',{staticClass:"row py-5"},[_c('div',{staticClass:"col"},[_c('div',{staticClass:"card-b mb-4 p-md-5 p-3"},[_c('div',{staticClass:"option-btns"},[(
              _vm.$store.getters.info.user.type === _vm.userTypesText.admin ||
                _vm.$store.getters.info.user.type === _vm.userTypesText.moderator ||
                (_vm.$store.getters.info.user.type === _vm.userTypesText.observer &&
                  !+_vm.permissions.observers.hide_report_replies)
            )?_c('button',{staticClass:"btn px-4 px-md-5",class:{ active: _vm.option === _vm.tabOptions.reports },style:({
              background:
                _vm.option === _vm.tabOptions.reports
                  ? _vm.$store.getters.theme[2]
                  : _vm.$store.getters.theme[0],
              color: _vm.option === _vm.tabOptions.reports ? '#ffffff' : '#999999'
            }),on:{"click":function($event){return _vm.changeTab(_vm.tabOptions.reports)}}},[_vm._v(" "+_vm._s(_vm.translation.replies.replies)+" ")]):_vm._e(),(
              _vm.$store.getters.info.user.type === _vm.userTypesText.admin ||
                _vm.$store.getters.info.user.type === _vm.userTypesText.moderator ||
                (_vm.$store.getters.info.user.type === _vm.userTypesText.observer &&
                  !+_vm.permissions.observers.hide_report_media)
            )?_c('button',{staticClass:"btn px-4 px-md-5",class:{ active: _vm.option === _vm.tabOptions.media },style:({
              background:
                _vm.option === _vm.tabOptions.media
                  ? _vm.$store.getters.theme[2]
                  : _vm.$store.getters.theme[0],
              color: _vm.option === _vm.tabOptions.media ? '#ffffff' : '#999999'
            }),on:{"click":function($event){return _vm.changeTab(_vm.tabOptions.media)}}},[_vm._v(" "+_vm._s(_vm.translation.replies.media)+" ")]):_vm._e(),(
              (_vm.$store.getters.info.user.type === _vm.userTypesText.admin ||
                _vm.$store.getters.info.user.type === _vm.userTypesText.moderator ||
                (_vm.$store.getters.info.user.type === _vm.userTypesText.observer &&
                  !+_vm.permissions.observers.hide_report_charts)) &&
                !_vm.exportedFiles
            )?_c('button',{staticClass:"btn px-4 px-md-5",class:{ active: _vm.option === _vm.tabOptions.charts },style:({
              background:
                _vm.option === _vm.tabOptions.charts
                  ? _vm.$store.getters.theme[2]
                  : _vm.$store.getters.theme[0],
              color: _vm.option === _vm.tabOptions.charts ? '#ffffff' : '#999999'
            }),on:{"click":function($event){return _vm.changeTab(_vm.tabOptions.charts)}}},[_vm._v(" "+_vm._s(_vm.translation.replies.charts)+" ")]):_vm._e()]),(
            _vm.$store.getters.info.user.type === _vm.userTypesText.admin ||
              _vm.$store.getters.info.user.type === _vm.userTypesText.moderator ||
              (_vm.$store.getters.info.user.type === _vm.userTypesText.observer &&
                (!+_vm.permissions.observers.hide_report_media ||
                  !+_vm.permissions.observers.hide_report_charts ||
                  !+_vm.permissions.observers.hide_report_replies))
          )?_c('div',[_c('transition',{attrs:{"mode":"out-in"}},[(_vm.exportedFiles)?_c('exported-files',{attrs:{"option":_vm.option},on:{"return":_vm.toggleExportedFiles}}):_c('div',[(_vm.option !== _vm.tabOptions.charts)?_c('div',{staticClass:"\n                d-sm-flex\n                align-items-center\n                justify-content-between\n                pt-5\n              "},[_c('div',{staticClass:"exported-files pb-3 mr-4"},[_c('a',{staticClass:"btn hover py-2 px-md-5 mr-4",style:({ background: _vm.$store.getters.theme[0] }),attrs:{"target":"blank"},on:{"click":_vm.toggleExportedFiles}},[_vm._v(" "+_vm._s(_vm.translation.replies.exported_files)+" ")]),_c('p',{staticClass:"m-0"},[_vm._v(" "+_vm._s(_vm.translation.replies.track_the_status)+" ")])])]):_vm._e(),_c('transition',{attrs:{"mode":"out-in"}},[(_vm.filterStep === _vm.filterSteps.initial)?_c('filters',{staticClass:"mb-4 mt-5",attrs:{"option":_vm.option,"participants":_vm.participants,"medias":_vm.medias},on:{"queryParams":_vm.triggerRequests,"nextStep":function($event){_vm.filterStep = _vm.filterSteps.confirm}}}):_vm._e(),(_vm.filterStep === _vm.filterSteps.confirm)?_c('filters-confirm',{staticClass:"mb-4",attrs:{"option":_vm.option},on:{"previousStep":function($event){_vm.filterStep = _vm.filterSteps.initial},"nextStep":function($event){_vm.filterStep = _vm.filterSteps.export}}}):_vm._e(),(_vm.filterStep === _vm.filterSteps.export)?_c('filters-export',{staticClass:"mb-4",attrs:{"option":_vm.option},on:{"initialStep":function($event){_vm.filterStep = _vm.filterSteps.initial},"openExportedFiles":_vm.toggleExportedFiles}}):_vm._e()],1),(_vm.loader)?_c('div',{staticClass:"d-flex justify-content-center py-5"},[_c('loader')],1):_c('div',[_c('transition',{attrs:{"mode":"out-in"}},[(
                      (_vm.$store.getters.info.user.type ===
                        _vm.userTypesText.moderator ||
                        _vm.$store.getters.info.user.type ===
                          _vm.userTypesText.admin ||
                        (_vm.$store.getters.info.user.type ===
                          _vm.userTypesText.observer &&
                          !+_vm.permissions.observers.hide_report_replies)) &&
                        _vm.option === _vm.tabOptions.reports &&
                        !_vm.showExportedFiles
                    )?_c('answers',{staticClass:"mt-4",attrs:{"getAnswers":_vm.getAnswers,"participants":_vm.participants}}):_vm._e(),(
                      (_vm.$store.getters.info.user.type ===
                        _vm.userTypesText.moderator ||
                        _vm.$store.getters.info.user.type ===
                          _vm.userTypesText.admin ||
                        (_vm.$store.getters.info.user.type ===
                          _vm.userTypesText.observer &&
                          !+_vm.permissions.observers.hide_report_media)) &&
                        _vm.option === _vm.tabOptions.media &&
                        !_vm.showExportedFiles
                    )?_c('media',{attrs:{"participants":_vm.medias}}):_vm._e(),(
                      (_vm.$store.getters.info.user.type ===
                        _vm.userTypesText.moderator ||
                        _vm.$store.getters.info.user.type ===
                          _vm.userTypesText.admin ||
                        (_vm.$store.getters.info.user.type ===
                          _vm.userTypesText.observer &&
                          !+_vm.permissions.observers.hide_report_charts)) &&
                        _vm.option === _vm.tabOptions.charts &&
                        !_vm.showExportedFiles
                    )?_c('charts',{attrs:{"charts":_vm.charts}}):_vm._e()],1)],1),(_vm.scrollLoader)?_c('div',{staticClass:"d-flex justify-content-center py-5"},[_c('loader')],1):_vm._e(),(_vm.minPerPage < _vm.total)?_c('pagination-query',{attrs:{"id":"pagination-charts","perPage":_vm.perPage,"total":_vm.total,"options":[5, 10, 20, 50, 100]},on:{"perPageUpdate":function (newPerPage) { return (_vm.perPage = newPerPage); }}}):_vm._e()],1)],1)],1):_c('div',{staticClass:"warn"},[_vm._v(" "+_vm._s(_vm.translation.errors_global.observers_are_not_allowed)+" ")])])])])])}
var staticRenderFns = []

export { render, staticRenderFns }