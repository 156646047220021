<template>
  <div class="task-stats px-md-4 pb-2 pt-4">
    <div class="table-wrapper">
      <div
        v-for="activity in filterActivities"
        :key="activity.activity_id"
        class="table"
      >
        <div class="table-header pb-1 mt-4">
          <div class="lg">
            {{ translation.replies.dashboard.name }}
          </div>
          <div class="md">
            {{ translation.replies.dashboard.general_status }}
          </div>
        </div>
        <div>
          <div
            :style="{ background: $store.getters.theme[1] }"
            class="table-content py-1"
          >
            <div class="lg">
              {{ activity.name }}
            </div>
            <div class="md">
              <div
                v-if="activity.activity_status === statusDashboard.completed"
              >
                {{ translation.replies.dashboard.completed }}
              </div>
              <div
                v-if="activity.activity_status === statusDashboard.incomplete"
              >
                {{ translation.replies.dashboard.incomplete }}
              </div>
              <div
                v-if="activity.activity_status === statusDashboard.notStarted"
              >
                {{ translation.replies.dashboard.not_started }}
              </div>
            </div>

            <div class="circle-wrapper">
              <div class="sm" v-for="task in activity.tasks" :key="task.index">
                <span class="task-code">
                  {{
                    task.code_string ? task.code_string : `T${+task.index + 1}`
                  }}
                </span>
                <span
                  :style="{
                    background: +task.is_completed
                      ? $store.getters.theme[2]
                      : 'rgba(0, 0, 0, 0)'
                  }"
                  class="circle"
                ></span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <pagination-query
        v-if="minPerPage < total"
        :perPage="perPage"
        :total="total"
        :options="[5, 10, 20, 50, 100]"
        @perPageUpdate="newPerPage => (perPage = newPerPage)"
      />
    </div>
  </div>
</template>

<script>
import paginationQuery from "@/components/general/paginationQuery.vue";
import { statusDashboard } from "@/constants";
export default {
  props: {
    activities: {
      required: true
    },
    total: {
      required: true
    }
  },
  components: { paginationQuery },
  data() {
    return {
      statusDashboard,
      perPage: 10,
      minPerPage: 5
    };
  },
  computed: {
    page() {
      return +this.$route.query._page;
    },
    filterActivities() {
      let array = [];
      const start = this.perPage * (this.page - 1);
      this.activities.forEach((activity, index) => {
        if (index >= start && index < start + this.perPage)
          array.push(activity);
      });
      return array;
    }
  },
  methods: {}
};
</script>

<style lang="scss" scoped>
.table {
  width: 100%;
}

.table-header {
  min-width: 100%;
  display: flex;
  width: fit-content;
  color: #6b6b6b;
  padding: 5px 10px;
  div {
    min-width: 140px;
    font-weight: bold;
    font-size: 0.9rem;
    &.lg {
      width: 200px;
    }
    &.md {
      width: 100px;
    }
    &.sm {
      display: flex;
      justify-content: center;
      width: 50px;
    }
  }
}

.table-content {
  position: relative;
  min-width: 100%;
  width: fit-content;
  display: flex;
  align-items: center;
  padding: 5px 10px;
  border-radius: 20px;
  height: 30px;
  div {
    min-width: 140px;
    color: #6b6b6b;
    font-size: 0.9rem;
    position: relative;
    &.lg {
      width: 200px;
    }
    &.md {
      width: 100px;
    }
    &.sm {
      display: flex;
      justify-content: center;
      width: 50px;
    }
    .task-code {
      font-weight: bold;
      position: absolute;
      top: -25px;
    }
    .circle {
      display: block;
      width: 15px;
      height: 15px;
      border-radius: 50%;
      margin-top: 7px;
      border: 3px solid #d8d8d8;
    }
  }
}

.circle-wrapper {
  display: flex;
  overflow-x: scroll;
  overflow-y: visible;
  position: relative;
  width: 40vw;
  padding: 20px 0px 0px;
  max-width: 500px;
  height: 70px;

  &::-webkit-scrollbar {
    height: 6px;
    overflow: hidden;
  }
  &::-webkit-scrollbar-track {
    background: rgba(0, 0, 0, 0);
    border-radius: 10px;
  }
  &::-webkit-scrollbar-thumb {
    background: rgba(0, 0, 0, 0);
    border-radius: 10px;
    height: 10px;
  }
  &:hover {
    &::-webkit-scrollbar-track {
      background: #f6f8f9;
    }
    &::-webkit-scrollbar-thumb {
      background: #d8d8d8;
    }
    &::-webkit-scrollbar-thumb:hover {
      background: #ccc;
    }
  }
}

@media (max-width: 1199px) {
  .circle-wrapper {
    width: 35vw;
  }
}

@media (max-width: 993px) {
  .table-header {
    div {
      &.lg {
        width: 50%;
      }
      &.md {
        width: 50%;
      }
    }
  }

  .table-content {
    flex-wrap: wrap;
    height: unset;
    div {
      .task-code {
        top: -20px;
      }
      &.lg {
        width: 50%;
      }
      &.md {
        width: 50%;
      }
      &.sm {
        width: 50%;
      }
    }
  }

  .circle-wrapper {
    width: 45vw;
    height: unset;
  }
}

@media (max-width: 900px) {
  .circle-wrapper {
    width: 55vw;
  }
}

@media (max-width: 600px) {
  .circle-wrapper {
    width: 75vw;
  }
}

@media (max-width: 400px) {
  .circle-wrapper {
    width: 75vw;
  }
}
</style>
