<template>
  <div>
    <div v-if="post.user" class="d-flex w-100">
      <div class="d-flex flex-column align-items-center">
        <div
          class="profile-img"
          :style="{ 'background-color': $store.getters.theme[0] }"
        >
          <img
            v-if="post.user.picture"
            :src="post.user.picture"
            alt="Profile Picture"
          />
          <b-icon
            v-else
            class="icon-profile"
            icon="person-fill"
            aria-hidden="true"
          ></b-icon>
        </div>
        <p class="name">
          <span
            v-if="$store.getters.info.user.type === userTypesText.participant"
          >
            {{
              namePrivacy(
                privacy.participants.admins_identifier_type_id,
                post.user.name,
                post.user.id
              )
            }}
          </span>
          <span v-else>
            {{ post.user.name }}
          </span>
        </p>
      </div>
      <div class="ml-3 w-100">
        <div class="desc d-flex justify-content-between align-items-center">
          <h2>{{ post.title }}</h2>
          <div>
            <router-link
              v-if="
                $store.getters.info.user.type === userTypesText.moderator ||
                  $store.getters.info.user.type === userTypesText.admin
              "
              :to="{ name: 'newpost', query: { pId: post.id } }"
              class="btn-post underline"
            >
              {{ translation.global.edit }}
            </router-link>
            <button
              v-if="
                !post.hasInteraction &&
                  ($store.getters.info.user.sub === post.user.id ||
                    $store.getters.info.user.type === userTypesText.moderator ||
                    $store.getters.info.user.type === userTypesText.admin)
              "
              @click="$bvModal.show(`deletePostModal${post.id}`)"
              class="btn-post underline"
            >
              {{ translation.global.delete }}
            </button>
            <b-modal
              @ok="deletePost"
              :cancel-title="translation.global.cancel"
              :id="`deletePostModal${post.id}`"
              :title="translation.forum.delete_post"
            >
              {{ translation.forum.are_you_sure_delete_post }}
            </b-modal>
          </div>
        </div>
        <div class="post-info">
          <span class="mr-3">{{ post.created_at | formatDate }}</span
          ><span v-if="+post.was_edited"> {{ translation.forum.edited }}</span>
        </div>

        <p class="text" v-html="post.post"></p>
        <attachment :attachments="post.attachments" :deleteFile="null" />

        <div class="d-flex">
          <div class="likes-wrapper">
            <div class="likes-list" v-if="post.likes.length">
              <b-modal
                :cancel-title="translation.global.cancel"
                :id="`likeModal${post.id}`"
                title="Likes"
                hide-footer
              >
                <div class="user-modal-scroll">
                  <div
                    class="user-modal"
                    v-for="(likePost, index) in post.likes"
                    :key="index"
                  >
                    <div>
                      <div
                        class="profile-img"
                        :style="{ 'background-color': $store.getters.theme[0] }"
                      >
                        <img
                          v-if="likePost.user.picture"
                          :src="likePost.user.picture"
                          alt="Profile Picture"
                        />
                        <b-icon
                          v-else
                          class="icon-profile"
                          icon="person-fill"
                          aria-hidden="true"
                        ></b-icon>
                      </div>
                      <p>
                        {{ likePost.user.name }}
                      </p>
                    </div>
                    <p>
                      <span
                        v-if="likePost.user.type !== userTypesText.participant"
                      >
                        {{ likePost.user.type }}
                      </span>
                      <router-link
                        class="font-weight-bold"
                        v-else
                        :style="{ color: $store.getters.theme[2] }"
                        :to="{
                          name: 'chat',
                          query: { _participant: likePost.user.id }
                        }"
                      >
                        {{ translation.replies.dashboard.send_message }}
                      </router-link>
                    </p>
                  </div>
                </div>
              </b-modal>
              <div :style="{ 'background-color': $store.getters.theme[2] }">
                <div>
                  <p v-for="(likePost, index) in likesPost" :key="index">
                    {{ likePost.user.name }}
                  </p>
                </div>
                <button @click="$bvModal.show(`likeModal${post.id}`)">
                  <span>{{ translation.global.show_more }}</span>
                </button>
              </div>
            </div>
            <button
              @click="toggleLike"
              class="d-flex align-items-center mr-2 btn-post"
              :disabled="
                !+post.is_active ||
                  $store.getters.info.user.type === userTypesText.observer
              "
            >
              <img
                :class="{ active: !post.liked }"
                class="mr-2 like"
                src="@/img/like.svg"
                alt="Like"
              />
              <img
                :class="{ active: post.liked }"
                class="mr-2 like"
                src="@/img/liked.svg"
                alt="Like"
              />
              <p class="m-0">{{ post.likes ? post.likes.length : 0 }}</p>
            </button>
          </div>

          <button
            class="d-flex align-items-center btn-post"
            @click="goToPost"
            :disabled="
              $route.name !== 'forum' &&
                $store.getters.info.user.type === userTypesText.observer
            "
          >
            <img class="mr-2" src="@/img/comment.svg" alt="Comment" />
            <p class="m-0">{{ post.comments.length }}</p>
          </button>

          <comments
            :id="post.id"
            :open="open"
            :type="1"
            :edit="edit"
            :post="post"
            @reply="updateComment"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { api } from "@/services.js";
import comments from "@/components/forum/comments.vue";
import attachment from "@/components/general/attachment.vue";
import { userTypesText } from "@/constants";
import { namePrivacy } from "@/helpers";
import { mapState } from "vuex";

export default {
  props: {
    post: {
      required: true
    },
    getPost: {
      required: true
    }
  },
  components: { comments, attachment },
  data() {
    return {
      open: false,
      edit: false,
      maxLikes: 10,
      userTypesText
    };
  },

  filters: {
    formatDate(value) {
      const date = new Date(value);
      return date.toLocaleString();
    }
  },

  computed: {
    ...mapState(["privacy"]),

    likesPost() {
      return this.post.likes.filter((post, index) => index < this.maxLikes);
    }
  },
  methods: {
    namePrivacy,

    goToPost() {
      if (this.$route.name !== "post")
        this.$router.push({ name: "post", query: { pId: this.post.id } });
      else this.open = !this.open;
    },

    updateComment() {
      this.$emit("updateComment");
    },

    deletePost() {
      api
        .delete(`forum/post/${this.post.id}`, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${this.$store.getters.token}`
          }
        })
        .then(r => {
          if (r.status === 200) {
            if (this.$route.name === "post")
              this.$router.push({ name: "forum" });
            this.getPost();

            this.$toast.success(this.translation.forum.success.post_deleted);
          }
        })
        .catch(() => {
          this.$toast.error(
            this.translation.errors_global.something_went_wrong
          );
        });
    },

    toggleLike() {
      if (this.post.liked) {
        api
          .delete(`forum/post/${this.post.id}/like?t=post`, {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${this.$store.getters.token}`
            }
          })
          .then(r => {
            if (r.status === 200) {
              this.getPost();
            } else {
              this.$toast.error(
                this.translation.errors_global.something_went_wrong
              );
            }
          })
          .catch(() => {
            this.$toast.error(
              this.translation.errors_global.something_went_wrong
            );
          });
      } else {
        api
          .put(
            `forum/post/${this.post.id}/like?t=post`,
            {},
            {
              headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${this.$store.getters.token}`
              }
            }
          )
          .then(r => {
            if (r.status === 200) {
              this.getPost();
            } else {
              this.$toast.error(
                this.translation.errors_global.something_went_wrong
              );
            }
          })
          .catch(() => {
            this.$toast.error(
              this.translation.errors_global.something_went_wrong
            );
          });
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.name {
  margin: 0 auto;
  max-width: 100px;
  font-weight: bold;
  color: #6b6b6b;
  text-align: center;
}

.btn-post {
  background: rgba(0, 0, 0, 0);
  border: none;
  color: #424151;
  text-transform: uppercase;
  font-size: 0.8rem;
  &.underline {
    text-decoration: underline;
    font-size: 0.7rem;
  }
  &:hover {
    text-decoration: underline;
  }
  &:focus {
    outline: none;
  }
}

.desc {
  h2 {
    font-weight: bold;
    font-size: 1.2rem;
  }
}

.post-info {
  span {
    color: #999999;
    font-size: 0.8rem;
  }
}

.text {
  color: #6b6b6b;
  word-wrap: break-word;
  word-break: break-word;
  margin-right: 20px;
}

.profile-img {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 80px;
  height: 80px;
  border-radius: 50%;
  overflow: hidden;
  margin-bottom: 5px;
  z-index: 5;
  img {
    object-fit: cover;
    height: 80px;
    width: 80px;
  }
}

.likes-wrapper {
  position: relative;
  &:hover {
    .likes-list {
      display: block;
    }
  }
}

.like {
  width: 24px;
  animation: show 0.3s linear;
  display: none;

  &.active {
    display: block;
  }
}

.likes-list {
  display: none;
  position: absolute;
  bottom: 20px;
  animation: show 0.2s linear;
  padding-bottom: 20px;
  > div {
    padding: 10px;
    border-radius: 15px;
    box-shadow: 0px 0px 37px -16px #c4c4c4;
    p {
      margin-bottom: 0;
      color: #ffffff;
      white-space: nowrap;
    }
    button {
      background: none;
      border: none;
      color: #ffffff;
      white-space: nowrap;
      padding: 0;
      font-size: 0.8rem;
      text-decoration: underline;
      span {
        font-weight: bold;
        margin-left: 2px;
      }
      &:focus {
        outline: none;
      }
    }
  }
}

.user-modal {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
  padding: 10px 15px 10px 10px;
  border-radius: 10px;
  transition: all 0.3 ease;
  p {
    margin-bottom: 0;
  }
  > div {
    display: flex;
    align-items: center;
    p {
      margin-left: 10px;
      font-weight: bold;
    }
  }
  .profile-img {
    width: 60px;
    height: 60px;
    img {
      width: 60px;
      height: 60px;
    }
  }

  &:hover {
    background: rgba(0, 0, 0, 0.03);
  }
}

.user-modal-scroll {
  max-height: 400px;
  overflow-y: auto;
  padding: 0 10px;
  &::-webkit-scrollbar {
    width: 6px;
  }
  &::-webkit-scrollbar-track {
    background: rgba(0, 0, 0, 0.05);
    border-radius: 10px;
  }
  &::-webkit-scrollbar-thumb {
    background: rgba(0, 0, 0, 0.2);
    border-radius: 10px;
    height: 40px;
  }
  &::-webkit-scrollbar-thumb:hover {
    background: rgba(0, 0, 0, 0.1);
  }
}
</style>
