<template>
  <div>
    <h2 class="title">
      {{
        isWordcloud
          ? translation.replies.charts_tab.choose_colors_wordcloud
          : translation.replies.charts_tab.choose_colors_chart
      }}
    </h2>
    <div class="buttons-options">
      <button
        :style="{ color: buttonOptionColor(options.one) }"
        :class="{ active: hasOneColor }"
        class="btn btn-sm hover"
        @click="setHasOneColor(options.one)"
      >
        {{ translation.replies.charts_tab.one }}
      </button>
      <button
        :style="{ color: buttonOptionColor(options.multiple) }"
        :class="{ active: !hasOneColor }"
        class="btn btn-sm hover"
        @click="setHasOneColor(options.multiple)"
      >
        {{ translation.replies.charts_tab.multiple }}
      </button>
    </div>
    <div v-if="newTheme.length" class="colors">
      <div class="btn btn-color">
        <div
          :style="{ background: newTheme[index] }"
          v-for="(color, index) in colorsCount"
          :key="index"
        >
          <b-form-input
            @change="currentNewTheme"
            type="color"
            v-model="newTheme[index]"
          ></b-form-input>
        </div>
      </div>
      <button
        @click="setColors"
        :style="{ background: $store.getters.theme[2] }"
        class="btn btn-sm btn-add-theme hover"
      >
        {{ translation.global.apply }}
      </button>
    </div>
    <div class="themes">
      <div
        class="item-theme"
        v-for="(colorsTheme, tIndex) in themes"
        :key="tIndex"
      >
        <button class="btn btn-remove" @click="removeTheme(tIndex)">
          <b-icon icon="x"></b-icon>
        </button>
        <button
          :class="{ active: isColors(colorsTheme) }"
          class="btn btn-theme hover"
          @click="selectTheme(colorsTheme)"
        >
          <div
            :style="{ background: colorsTheme[cIndex] }"
            v-for="(color, cIndex) in colorsCount"
            :key="cIndex"
          ></div>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    config: {
      required: true
    },
    isWordcloud: {
      default: false
    },
    optionsCount: {
      required: true
    },
    max: {
      default: false
    }
  },
  data() {
    return {
      colorsCount: 0,
      newTheme: [],
      colors: [],
      hasOneColor: false,
      themes: [],
      options: {
        one: true,
        multiple: false
      }
    };
  },
  methods: {
    currentNewTheme() {
      this.$emit("currentNewTheme", this.newTheme);
    },
    setHasOneColor(option) {
      this.hasOneColor = option;
      this.colorsCount = this.hasOneColor ? 1 : this.optionsCount;
      this.$emit("updateHasOneColor", this.hasOneColor);
    },
    buttonOptionColor(option) {
      if (this.hasOneColor === option) return this.$store.getters.theme[3];
      else return "#6b6b6b";
    },
    setColors() {
      this.colors = [...this.newTheme];
      this.$emit("updateColors", this.colors);

      const hasTheme = this.themes.some(theme => this.isColors(theme));

      if (!hasTheme) {
        this.themes.push(this.colors);
        this.$emit("updateThemes", this.themes);
      }
      this.$emit("currentNewTheme", this.newTheme);
    },
    removeTheme(index) {
      this.themes.splice(index, 1);
      this.$emit("updateThemes", this.themes);
    },
    selectTheme(colors) {
      this.newTheme = [...colors];
      this.setColors();
    },
    isColors(colors) {
      return (
        this.colors.length === colors.length &&
        !this.colors.some((color, index) => colors[index] !== color)
      );
    }
  },
  created() {
    this.colors = this.config.theme.colors;
    this.hasOneColor = this.config.theme.hasOneColor;
    this.colorsCount = this.hasOneColor
      ? 1
      : this.max && this.max < 5
      ? this.max
      : this.optionsCount;
    this.themes = this.config.theme.themes;
    this.newTheme = [...this.colors];
  }
};
</script>

<style lang="scss" scoped>
.title {
  font-size: 1rem;
  color: #6b6b6b;
  font-weight: bold;
  margin-bottom: 10px;
}

.buttons-options {
  margin-bottom: 5px;
  button {
    font-weight: bold;
    color: #6b6b6b;
    &:focus {
      box-shadow: none;
    }
    &.active {
      text-decoration: underline;
    }
  }
}

.colors {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}

.btn-color {
  background: #ffffff;
  border-radius: 3px;
  margin-right: 10px;
  display: flex;
  overflow-x: auto;
  overflow-y: hidden;
  padding: 5px 15px;

  &::-webkit-scrollbar {
    height: 8px;
    overflow: hidden;
  }
  &::-webkit-scrollbar-track {
    background: rgba(0, 0, 0, 0.1);
    border-radius: 10px;
  }
  &::-webkit-scrollbar-thumb {
    background: #ccc;
    border-radius: 10px;
    height: 10px;
  }
  &::-webkit-scrollbar-thumb:hover {
    background: #aaa;
  }
  > div {
    width: 30px;
    height: 30px;
    margin-right: 10px;
    animation: show 0.3s linear;
    input {
      width: 45px;
      height: 45px;
      opacity: 0;
      cursor: pointer;
    }
    &:last-of-type {
      margin-right: 0px;
    }
  }
}

.btn-add-theme {
  font-weight: bold;
  color: #ffffff;
  margin: 5px;
}

.themes {
  margin-top: 20px;
  display: flex;
  flex-wrap: wrap;
  overflow-y: scroll;
  height: 72px;
  background: #ffffff;
  padding: 5px;
  border-radius: 3px;
  &::-webkit-scrollbar {
    width: 8px;
    overflow: hidden;
  }
  &::-webkit-scrollbar-track {
    background: rgba(0, 0, 0, 0.1);
    border-radius: 10px;
  }
  &::-webkit-scrollbar-thumb {
    background: #ccc;
    border-radius: 10px;
    height: 10px;
  }
  &::-webkit-scrollbar-thumb:hover {
    background: #aaa;
  }
}

.item-theme {
  position: relative;
  &:hover {
    .btn-remove {
      display: flex;
    }
  }
}

.btn-remove {
  display: none;
  justify-content: center;
  align-items: center;
  background: #fff;
  height: 20px;
  width: 20px;
  padding: 0;
  color: #df2938;
  position: absolute;
  top: 0px;
  right: 5px;
  box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.1);
  animation: show 0.3s linear;
  z-index: 2;
}

.btn-theme {
  position: relative;
  display: flex;
  border: 3px solid #ffffff;
  border-radius: 3px;
  padding: 0;
  margin-right: 30px;
  margin-bottom: 10px;
  box-shadow: 0px 0px 0px 1px rgba(0, 0, 0, 0.2);
  div {
    width: 20px;
    height: 20px;
  }
}
</style>
