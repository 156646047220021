<template>
  <div class="container">
    <div class="row py-5">
      <div class="col">
        <div class="notfound card-b p-5 d-flex flex-column align-items-center">
          <p>
            404
          </p>
          <p>Oops! Page not found</p>
          <p>
            <b-icon icon="emoji-frown" aria-hidden="true"></b-icon>
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
.notfound {
  p {
    margin: 0;
    color: #999;

    font-weight: bold;
    &:nth-of-type(1) {
      font-size: 4rem;
    }
    &:nth-of-type(3) {
      font-size: 4rem;
    }
  }
}
</style>
