<template>
  <div v-if="user" class="chat-user">
    <div class="profile-img-wrapper">
      <div class="notification" v-if="notification">
        {{ notification }}
      </div>
      <div
        class="profile-img"
        :style="{
          color: $store.getters.theme[3]
        }"
      >
        <img
          v-if="user.picture && user.picture.length"
          :src="user.picture"
          alt="Profile Picture"
        />
        <b-icon
          v-else
          class="icon-profile"
          icon="person-fill"
          aria-hidden="true"
        ></b-icon>
      </div>
    </div>
    <div>
      <p>
        {{
          namePrivacy(
            privacy.participants.admins_identifier_type_id,
            user.name,
            user.id
          )
        }}
      </p>
      <p>
        {{
          sourceType === userTypes.admin
            ? translation.chat.admin
            : translation.chat.moderator
        }}
      </p>
    </div>
  </div>
</template>

<script>
import { api } from "@/services.js";
import { userTypes } from "@/constants";
import { mapState } from "vuex";
import { namePrivacy } from "@/helpers";

export default {
  props: {
    sourceId: {
      required: true
    },
    sourceType: {
      required: true
    },
    notifications: {
      required: true
    }
  },

  data() {
    return {
      user: null,
      userTypes
    };
  },

  computed: {
    ...mapState(["privacy"]),

    notification() {
      return this.notifications.filter(
        notification => notification.message.sourceID === this.sourceId
      ).length;
    }
  },

  methods: {
    namePrivacy,

    getUser() {
      const type =
        this.sourceType === this.userTypes.admin ? "admin" : "operator";
      api
        .get(`${type}/${this.sourceId}`, {
          headers: {
            Authorization: `Bearer ${this.$store.getters.token}`
          }
        })
        .then(r => {
          if (r.status === 200) {
            this.user = r.data;
            this.$emit("user", this.user);
          }
        });
    }
  },
  created() {
    this.getUser();
  }
};
</script>

<style lang="scss" scoped>
.chat-user {
  display: flex;
  align-items: center;
  justify-content: space-between;
  &::after {
    content: "";
    display: block;
    width: 16px;
    height: 16px;
    border: 5px solid #b1b1b1;
    margin-left: 20px;
    border-radius: 50%;
    transition: all 0.3s ease;
  }
  .profile-img-wrapper {
    position: relative;
    .notification {
      display: flex;
      align-items: center;
      justify-content: center;
      color: #ffffff;
      font-weight: bold;
      position: absolute;
      background: #df2938;
      top: -4px;
      right: 8px;
      width: 18px;
      height: 18px;
      border-radius: 50%;
      font-size: 0.8rem;
    }
    .profile-img {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 60px;
      height: 60px;
      border-radius: 50%;
      overflow: hidden;
      flex-shrink: 0;
      margin-right: 10px;
      background: #eee;
      img {
        object-fit: cover;
        height: 60px;
        width: 60px;
      }
    }
  }
  p {
    margin: 0;
    color: #6b6b6b;
    font-size: 0.9rem;
    &:nth-of-type(1) {
      font-weight: bold;
      font-size: 1rem;
    }
  }
}
</style>
