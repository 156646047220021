<template>
  <div>
    <button
      v-if="
        $store.getters.info.user.type === userTypesText.moderator ||
          $store.getters.info.user.type === userTypesText.admin
      "
      class="btn-reject hover"
      @click="$bvModal.show(`modal-${activeTask.id}-${anwserIndex}`)"
      :style="{ color: $store.getters.theme[2] }"
    >
      {{ translation.global.delete }}
    </button>
    <b-modal
      class="box"
      :id="`modal-${activeTask.id}-${anwserIndex}`"
      hide-footer
    >
      <template #modal-title>
        <p class="font-weight-bold m-0">
          <b-icon
            icon="exclamation-triangle-fill"
            class="mr-2 text-danger"
            aria-hidden="true"
          ></b-icon>
          {{ translation.replies.assignments_tab.modal.delete_reply }}
        </p>
      </template>
      <div class="d-block text-center">
        <p>
          {{
            translation.replies.assignments_tab.modal
              .do_you_really_want_to_delete
          }}
        </p>
      </div>
      <div class="d-flex justify-content-center pt-2">
        <a
          class="btn btn-cancel mx-2"
          @click="$bvModal.hide(`modal-${activeTask.id}-${anwserIndex}`)"
        >
          {{ translation.global.cancel }}
        </a>

        <b-button class="btn-delete mx-2" variant="danger" @click="deleteReply">
          <p v-if="!loaderDelete" class="m-0">
            {{ translation.global.delete }}
          </p>
          <div class="d-flex justify-content-center">
            <loader2 v-if="loaderDelete" color="#ffffff" />
          </div>
        </b-button>
      </div>
    </b-modal>
  </div>
</template>

<script>
import loader2 from "@/components/general/loader2.vue";
import { api } from "@/services.js";
import { userTypesText } from "@/constants";

export default {
  components: {
    loader2
  },
  props: {
    activeTask: {
      required: true
    },
    anwserIndex: {
      required: true
    },
    answer: {
      required: true
    },
    getAnswers: {
      required: true
    }
  },
  data() {
    return {
      loaderDelete: false,
      userTypesText
    };
  },
  methods: {
    deleteReply() {
      if (!this.loaderDelete) {
        this.loaderDelete = true;
        api
          .delete(`task/answer/${this.answer.answer_id}/reject`, {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${this.$store.getters.token}`
            }
          })
          .then(r => {
            if (r.status === 200) {
              this.$bvModal.hide(
                `modal-${this.activeTask.id}-${this.anwserIndex}`
              );
              this.getAnswers();
              this.$store.commit("UPDATE_NOTIFICATION");
              this.$toast.success(
                this.translation.replies.assignments_tab.modal.success
                  .reply_deleted
              );
            }
            this.loaderDelete = false;
          })
          .catch(() => {
            this.loaderDelete = false;
            this.$toast.error(
              this.translation.errors_global.something_went_wrong
            );
          });
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.btn-reject {
  border: none;
  background: none;
  color: #0087bf;
  font-weight: bold;
  text-decoration: underline;
  &:focus {
    outline: none;
  }
}

.btn-cancel {
  background: #c4c4c4;
  color: #fff;
  min-width: 100px;
  &:hover {
    background: #b3b2b2;
  }
}

.btn-delete {
  min-width: 100px;
}
</style>
