<template>
  <div class="d-flex justify-content-end">
    <div class="search mr-3">
      <input
        v-model="search"
        class="pl-2"
        :placeholder="translation.global.search"
        type="text"
      />
      <b-icon class="mx-2" icon="search" aria-hidden="true"></b-icon>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      search: ""
    };
  },
  computed: {
    queryValue() {
      if (this.$route.query._q && this.$route.query._q.length)
        return this.$route.query._q;
      else return 0;
    }
  },

  watch: {
    search() {
      this.$router.push({
        query: this.query(this.search)
      });
    }
  },
  methods: {
    query(q) {
      return {
        ...this.$route.query,
        _q: q
      };
    }
  },
  created() {
    if (this.queryValue) this.search = this.queryValue;
  }
};
</script>

<style lang="scss" scoped>
.search {
  display: flex;
  align-items: center;
  border: 1px solid #676767;
  color: #6b6b6b;
  border-radius: 27px;
  overflow: hidden;
  input {
    color: #6b6b6b;
    width: 100%;
    border: none;
    background-color: rgba(0, 0, 0, 0);
    &:focus {
      outline: none;
      color: #6b6b6b;
    }
  }
}
</style>
