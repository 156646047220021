<template>
  <div>
    <b-modal ok-only id="screenshotModal">
      {{ translation.task.you_cant_take_screenshot }}
    </b-modal>

    <div class="container py-5">
      <div class="card-b p-relative pt-3 pt-md-0">
        <a v-if="!isPreview" class="m-4 mt-4 back" @click="$router.go(-1)">
          <b-icon class="mr-1" icon="arrow-left-circle"></b-icon>
          {{ translation.global.return }}
        </a>

        <div v-if="tasks.length && activeTask && !loaderTasks && !loaderAnswer">
          <p
            class="h5 font-weight-bold title text-white"
            :style="{
              background: $store.getters.theme[2]
            }"
          >
            {{ activity.name }} -
            <span>
              {{
                activeTask.code_string
                  ? activeTask.code_string
                  : `T${+activeTask.index + 1}`
              }}
            </span>
          </p>

          <div class="px-md-5 px-3 pt-3">
            <text-task
              v-if="+activeTask.type_id === taskEnum.textTask.id"
              :task="activeTask"
              :save="save"
              :savePreview="savePreview"
              :getTasks="getTasks"
            />

            <survey
              v-if="+activeTask.type_id === taskEnum.surveyTask.id"
              :task="activeTask"
              :save="save"
              :savePreview="savePreview"
              :getTasks="getTasks"
            />

            <whitespace
              v-if="+activeTask.type_id === taskEnum.whitespace.id"
              :task="activeTask"
              :save="save"
              :savePreview="savePreview"
              :getTasks="getTasks"
            />

            <matrix
              v-if="+activeTask.type_id === taskEnum.matrix.id"
              :task="activeTask"
              :save="save"
              :savePreview="savePreview"
              :getTasks="getTasks"
            />

            <slider
              v-if="+activeTask.type_id === taskEnum.slider.id"
              :task="activeTask"
              :save="save"
              :savePreview="savePreview"
              :getTasks="getTasks"
            />

            <ranking
              v-if="+activeTask.type_id === taskEnum.ranking.id"
              :task="activeTask"
              :save="save"
              :savePreview="savePreview"
              :getTasks="getTasks"
            />

            <drag-and-drop
              v-if="+activeTask.type_id === taskEnum.dragAndDrop.id"
              :task="activeTask"
              :save="save"
              :savePreview="savePreview"
              :getTasks="getTasks"
            />

            <imageTask
              v-if="+activeTask.type_id === taskEnum.imageTask.id"
              :task="activeTask"
              :save="save"
              :savePreview="savePreview"
              :getTasks="getTasks"
            />

            <videoTask
              v-if="+activeTask.type_id === taskEnum.videoTask.id"
              :task="activeTask"
              :save="save"
              :savePreview="savePreview"
              :getTasks="getTasks"
            />

            <attachment
              v-if="+activeTask.type_id === taskEnum.attachment.id"
              :task="activeTask"
              :save="save"
              :savePreview="savePreview"
              :getTasks="getTasks"
            />

            <reactions
              v-if="+activeTask.type_id === taskEnum.reactions.id"
              :task="activeTask"
              :save="save"
              :savePreview="savePreview"
              :getTasks="getTasks"
            />

            <video-evaluation-task
              v-if="+activeTask.type_id === taskEnum.videoEvaluation.id"
              :task="activeTask"
              :save="save"
              :savePreview="savePreview"
              :getTasks="getTasks"
            />

            <text-evaluation-task
              v-if="+activeTask.type_id === taskEnum.textEvaluation.id"
              :task="activeTask"
              :save="save"
              :savePreview="savePreview"
              :getTasks="getTasks"
            />

            <screen-recording-task
              v-if="+activeTask.type_id === taskEnum.screenRecording.id"
              :task="activeTask"
              :save="save"
              :savePreview="savePreview"
              :getTasks="getTasks"
            />

            <multimedia
              v-if="+activeTask.type_id === taskEnum.multimedia.id"
              :task="activeTask"
              :save="save"
              :savePreview="savePreview"
              :getTasks="getTasks"
            />

            <audio-task
              v-if="+activeTask.type_id === taskEnum.audio.id"
              :task="activeTask"
              :save="save"
              :savePreview="savePreview"
              :getTasks="getTasks"
            />
          </div>

          <pagination-task
            v-if="activity"
            :total="tasks.length"
            :is_sequenced="activity.is_sequenced"
            :is_answered="activeTask.is_answered"
            :activity="activity"
            @save="save = !save"
            @savePreview="savePreview = !savePreview"
            @deletePrevAnswer="deletePrevAnswer = true"
          />
          <div
            v-if="
              $store.getters.info.user.type !== userTypesText.participant &&
                !isPreview
            "
            class="row"
          >
            <div class="col">
              <nav
                :style="{ 'border-color': $store.getters.theme[2] }"
                class="tabs mx-md-5 mx-2"
              >
                <button
                  :style="{
                    background:
                      tab === tabOptions.participants
                        ? $store.getters.theme[2]
                        : $store.getters.theme[1],
                    color:
                      tab === tabOptions.participants
                        ? '#ffffff'
                        : $store.getters.theme[3]
                  }"
                  :class="{ active: tab === tabOptions.participants }"
                  class="btn"
                  @click="tab = tabOptions.participants"
                >
                  {{ translation.task.participants }}
                </button>
                <button
                  :style="{
                    background:
                      tab === tabOptions.answers
                        ? $store.getters.theme[2]
                        : $store.getters.theme[0],
                    color:
                      tab === tabOptions.answers
                        ? '#ffffff'
                        : $store.getters.theme[3]
                  }"
                  :class="{ active: tab === tabOptions.answers }"
                  class="btn"
                  @click="tab = tabOptions.answers"
                >
                  {{ translation.task.answers }}
                </button>

                <button
                  :style="{
                    background:
                      tab === tabOptions.charts
                        ? $store.getters.theme[2]
                        : $store.getters.theme[0],
                    color:
                      tab === tabOptions.charts
                        ? '#ffffff'
                        : $store.getters.theme[3]
                  }"
                  :class="{ active: tab === tabOptions.charts }"
                  class="btn"
                  @click="tab = tabOptions.charts"
                >
                  {{ translation.task.chart }}
                </button>
              </nav>
            </div>
          </div>

          <transition v-if="!isPreview" mode="out-in">
            <participants-table
              :task="activeTask"
              v-if="tab === tabOptions.participants"
            />
            <task-answers
              :activity="activity"
              :tasks="tasks"
              :activeTask="activeTask"
              v-if="tasks && tab === tabOptions.answers"
            />
            <charts-tab
              :activity="activity"
              :task="activeTask"
              :activeTask="activeTask"
              v-if="tasks && tab === tabOptions.charts"
            />
          </transition>
        </div>
        <div
          v-else-if="loaderTasks || loaderAnswer"
          class="d-flex justify-content-center py-5"
        >
          <loader />
        </div>
        <div
          class="d-flex justify-content-center py-5"
          v-else-if="!loaderTasks && !loaderAnswer"
        >
          {{ translation.task.no_tasks }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { api } from "@/services.js";
import { taskEnum, userTypesText, tabOptions } from "@/constants";
import paginationTask from "@/components/task/paginationTask.vue";
import loader from "@/components/general/loader.vue";
import participantsTable from "@/components/task/participantsTable.vue";
import taskAnswers from "@/components/task/taskAnswers.vue";
import chartsTab from "@/components/task/chartsTab.vue";
import textTask from "@/components/task/textTask.vue";
import survey from "@/components/task/survey.vue";
import whitespace from "@/components/task/whitespace.vue";
import matrix from "@/components/task/matrix.vue";
import slider from "@/components/task/slider.vue";
import ranking from "@/components/task/ranking.vue";
import dragAndDrop from "@/components/task/dragAndDrop.vue";
import imageTask from "@/components/task/image.vue";
import videoTask from "@/components/task/video.vue";
import attachment from "@/components/task/attachment.vue";
import reactions from "@/components/task/reactions.vue";
import videoEvaluationTask from "@/components/task/videoEvaluationTask.vue";
import textEvaluationTask from "@/components/task/textEvaluationTask.vue";
import screenRecordingTask from "@/components/task/screenRecordingTask.vue";
import multimedia from "@/components/task/multimedia.vue";
import audioTask from "@/components/task/audioTask.vue";

export default {
  components: {
    paginationTask,
    loader,
    participantsTable,
    taskAnswers,
    chartsTab,
    textTask,
    survey,
    whitespace,
    matrix,
    slider,
    ranking,
    dragAndDrop,
    imageTask,
    videoTask,
    attachment,
    reactions,
    videoEvaluationTask,
    textEvaluationTask,
    screenRecordingTask,
    multimedia,
    audioTask
  },
  data() {
    return {
      activity: {},
      tasks: [],
      participantAnswers: [],
      userTypesText,
      save: false,
      savePreview: false,
      prev: false,
      tab: tabOptions.answers,
      loaderTasks: false,
      loaderAnswer: false,
      activeTask: null,
      deletePrevAnswer: false,
      taskEnum,
      tabOptions
    };
  },

  computed: {
    page() {
      return this.$route.query._page;
    },

    previous() {
      return this.$route.query._previous;
    },

    activeTaskIndex() {
      return this.$store.getters.activeTaskIndex;
    },

    isPreview() {
      return this.$route.query._preview;
    },

    isParticipant() {
      return (
        this.$store.getters.info.user.type === this.userTypesText.participant
      );
    },

    goToTask() {
      return this.$store.getters.goToTask;
    },

    activityId() {
      return this.$route.query._activity;
    }
  },

  watch: {
    activityId() {
      this.init();
    },

    page() {
      this.redirectTask();
    },

    activeTaskIndex() {
      if (this.tasks.length && this.activeTaskIndex !== null) {
        this.activeTask = this.tasks[this.activeTaskIndex];
      } else this.activeTask = null;
    },

    activeTask() {
      if (this.isParticipant || this.isPreview) this.getAnswer();
    },

    deletePrevAnswer() {
      if (this.deletePrevAnswer) {
        this.tasks[this.activeTaskIndex - 1].is_answered = false;
        this.activeTask = this.tasks[this.activeTaskIndex - 1];
        this.$store.commit(
          "UPDATE_ACTIVE_TASK_INDEX",
          this.activeTaskIndex - 1
        );
      }
    }
  },

  methods: {
    redirectTask() {
      if (!this.isPreview && this.page && this.page !== "") {
        const task = this.tasks.find(task => +task.index === +this.page);

        const index = this.tasks.indexOf(task);

        if (task !== -1 && index !== this.activeTaskIndex) {
          this.activeTask = this.tasks[index];
          this.$store.commit("UPDATE_ACTIVE_TASK_INDEX", index);
        }

        this.$router.push({
          query: {
            ...this.$route.query,
            _page: ""
          }
        });
      }
    },

    removePrevious() {
      if ((this.previous || this.previous === 0) && this.previous !== "") {
        this.$router.push({
          query: {
            ...this.$route.query,
            _previous: ""
          }
        });
      }
    },

    async deleteAllAnswers() {
      this.loaderAnswer = true;

      return await api
        .delete(`activity/${this.$route.query._activity}/delete/preview`, {
          headers: {
            Authorization: `Bearer ${this.$store.getters.token}`
          }
        })
        .then(resp => {
          if (resp.status === 200) {
            this.activeTask = this.tasks[0];
            this.$store.commit("UPDATE_ACTIVE_TASK_INDEX", 0);
            this.loaderAnswer = false;
          }
        })
        .catch(() => {
          this.loaderAnswer = false;
        });
    },

    deleteAnswer() {
      if (this.activeTask) {
        this.loaderAnswer = true;

        api
          .delete(`answer/${this.activeTask.answer.id}/delete/preview`, {
            headers: {
              Authorization: `Bearer ${this.$store.getters.token}`
            }
          })
          .then(resp => {
            if (resp.status === 200) {
              this.tasks[this.activeTaskIndex].is_answered = false;

              this.loaderAnswer = false;
            }
          })
          .catch(() => {
            this.loaderAnswer = false;
          });
      }
    },

    getAnswer() {
      if (this.activeTask) {
        this.loaderAnswer = true;

        const userType =
          this.$store.getters.info.user.type === this.userTypesText.participant
            ? "participant"
            : "user";

        api
          .get(
            `task/${this.activeTask.id}/answer/${userType}/${this.$store.getters.info.user.sub}`,
            {
              headers: {
                Authorization: `Bearer ${this.$store.getters.token}`
              }
            }
          )
          .then(resp => {
            if (resp.status === 200) {
              this.tasks[this.activeTaskIndex].answer = resp.data.answer;

              if (this.deletePrevAnswer) {
                this.deleteAnswer();
                this.deletePrevAnswer = false;
              } else if (
                this.isPreview &&
                (this.previous || this.previous === 0)
              ) {
                this.deleteAnswer();
                this.removePrevious();
              } else {
                this.tasks[this.activeTaskIndex].is_answered = true;
              }

              this.loaderAnswer = false;
            }
          })
          .catch(() => {
            this.tasks[this.activeTaskIndex].is_answered = false;

            this.loaderAnswer = false;
          });
      }
    },

    unansweredTask() {
      const unansweredTask = this.tasks.indexOf(
        this.tasks.find(task => !task.is_answered)
      );

      return unansweredTask;
    },

    completeActivity() {
      if (this.isParticipant) {
        this.$router.push({ name: "feed" });
        this.$toast.success(
          this.translation.answer_task.success.assignment_completed
        );
      } else {
        this.$router.push({
          name: "completedactivity",
          query: {
            _p: this.activeTaskIndex,
            _activity: this.$route.query._activity
          }
        });
      }
    },

    async getParticipantAnswers() {
      this.loaderTasks = true;
      return api
        .get(
          `activity/${this.$route.query._activity}/participant/${this.$store.getters.info.user.sub}/tasks`,
          {
            headers: {
              Authorization: `Bearer ${this.$store.getters.token}`
            }
          }
        )
        .then(resp => {
          if (resp.status === 200) {
            this.participantAnswers = resp.data.tasks.map(task => task.id);
          }
          this.loaderTasks = false;
        })
        .catch(() => {
          this.participantAnswers = [];
          this.loaderTasks = false;
        });
    },

    viewBehavior() {
      const hasActiveTaskIndex =
        this.activeTaskIndex || this.activeTaskIndex === 0;

      if (this.goToTask || !hasActiveTaskIndex) {
        this.activeTask = this.tasks[0];
        this.$store.commit("UPDATE_ACTIVE_TASK_INDEX", 0);
        this.$store.commit("UPDATE_GO_TO_TASK", false);
      } else {
        this.activeTask = this.tasks[this.activeTaskIndex];
        this.$store.commit("UPDATE_ACTIVE_TASK_INDEX", this.activeTaskIndex);
      }
    },

    answerBehavior(save) {
      const hasActiveTaskIndex =
        this.activeTaskIndex || this.activeTaskIndex === 0;
      const hasLogic = +this.activity.has_logic;
      const isLast =
        this.tasks[this.activeTaskIndex] === this.tasks[this.tasks.length - 1];
      const hasUnansweredTask = this.unansweredTask() !== -1;

      if (save) {
        if (this.activity.is_completed || !hasUnansweredTask) {
          this.completeActivity();
        } else if (hasLogic) {
          this.activeTask = this.tasks[this.tasks.length - 1];
          this.$store.commit("UPDATE_ACTIVE_TASK_INDEX", this.tasks.length - 1);
        } else if (isLast && hasUnansweredTask) {
          this.activeTask = this.tasks[this.unansweredTask()];
          this.$store.commit("UPDATE_ACTIVE_TASK_INDEX", this.unansweredTask());
        } else {
          this.activeTask = this.tasks[this.activeTaskIndex + 1];
          this.$store.commit(
            "UPDATE_ACTIVE_TASK_INDEX",
            this.activeTaskIndex + 1
          );
        }
      } else {
        if (this.goToTask && hasUnansweredTask) {
          this.activeTask = this.tasks[this.unansweredTask()];
          this.$store.commit("UPDATE_ACTIVE_TASK_INDEX", this.unansweredTask());
          this.$store.commit("UPDATE_GO_TO_TASK", false);
        } else if (!this.goToTask && hasActiveTaskIndex) {
          this.activeTask = this.tasks[this.activeTaskIndex];
          this.$store.commit("UPDATE_ACTIVE_TASK_INDEX", this.activeTaskIndex);
        } else {
          this.activeTask = this.tasks[0];
          this.$store.commit("UPDATE_ACTIVE_TASK_INDEX", 0);
          this.$store.commit("UPDATE_GO_TO_TASK", false);
        }
      }
    },

    async getTasks(save) {
      this.loaderTasks = true;

      return api
        .get(
          `activity/${this.$route.query._activity}/${
            this.isPreview || this.isParticipant ? "tasks" : "all-tasks"
          }`,
          {
            headers: {
              Authorization: `Bearer ${this.$store.getters.token}`
            }
          }
        )
        .then(async resp => {
          if (resp.status === 200) {
            this.activity = resp.data.activity;

            if (this.isParticipant) await this.getParticipantAnswers();

            this.tasks = resp.data.tasks.map(task => {
              const taskObj = task;
              if (!taskObj.is_answered)
                taskObj.is_answered = this.participantAnswers.includes(task.id);
              if (!taskObj.answer) taskObj.answer = null;
              return taskObj;
            });

            if (this.isParticipant || this.isPreview) {
              this.answerBehavior(save);
            } else {
              this.viewBehavior();
            }

            this.loaderTasks = false;
          }
        })
        .catch(() => {
          this.tasks = [];
          this.$toast.error(
            this.translation.errors_global.something_went_wrong
          );
          this.loaderTasks = false;
        });
    },

    async init() {
      if (this.isPreview) {
        if ((!this.previous && this.previous !== 0) || this.previous === "")
          await this.deleteAllAnswers();
        await this.getTasks();
      } else {
        await this.getTasks();
      }

      this.redirectTask();
    },

    handleKeyUp() {
      const { keyCode, shiftKey } = event;

      if (shiftKey && keyCode === 83) {
        this.$bvModal.show("screenshotModal");
      }
    }
  },

  created() {
    this.init();
  },

  mounted() {
    if (this.$store.getters.info.user.type === userTypesText.participant) {
      window.addEventListener("keyup", this.handleKeyUp);
    }
  },
  beforeDestroy() {
    window.removeEventListener("keyup", this.handleKeyUp);
  }
};
</script>

<style lang="scss" scoped>
.back {
  color: #6b6b6b;
  position: absolute;
  top: 0;
  right: 0;
  cursor: pointer;
}

@media (max-width: 767.98px) {
  .back {
    top: -15px;
  }
}

.title {
  display: inline-block;
  padding: 10px 30px 10px 20px;
  border-top-right-radius: 20px;
  border-bottom-right-radius: 20px;
  margin-top: 30px;
}

.tabs {
  display: flex;
  border-bottom: 2px solid;
  button {
    margin-right: 10px;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;
    background: #f5f5f5;
    font-weight: bold;
    transition: all 0.3s ease;
    min-width: 120px;
    &:hover {
      transform: scale(1.01);
    }
  }
}

@media (max-width: 567.98px) {
  .tabs {
    display: flex;
    border-bottom: 2px solid;
    margin-top: 30px;
    button {
      min-width: 100px;
      font-size: 0.8rem;
    }
  }
}
</style>
