<template>
  <div>
    <div class="d-flex justify-content-end align-items-center flex-wrap mb-2">
      <p v-for="param in queryParams" :key="param" class="param">
        {{ filtersTranslation[param] ? filtersTranslation[param] : "Status" }}
        <b-icon class="m-0" icon="caret-right-fill" aria-hidden="true"></b-icon>
      </p>
      <button
        @click="$emit('previousStep')"
        class="btn hover mr-1 btn-download"
      >
        <b-icon icon="download" aria-hidden="true"></b-icon>
        <span>{{ translation.replies.export }}</span>
      </button>
    </div>
    <div class="confirm-box">
      <p>
        {{ translation.replies.filters_confirm_message }}
      </p>
      <div>
        <button class="btn hover" @click="$emit('previousStep')">
          {{ translation.global.no }}
        </button>
        <button
          :style="{ background: $store.getters.theme[2] }"
          class="btn hover"
          @click="$emit('nextStep')"
        >
          {{ translation.global.yes }}
        </button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    option: {
      required: true
    }
  },
  data() {
    return {};
  },
  computed: {
    queryParams() {
      const communityId = this.$store.getters.community.id;
      if (!localStorage.getItem(`${this.option}-queryParams-${communityId}`))
        return [];
      const queryParams = localStorage
        .getItem(`${this.option}-queryParams-${communityId}`)
        .split("&")
        .map(param => param.split("[]")[0]);
      const filtersUsed = [];
      queryParams.forEach(param => {
        if (param.length && !filtersUsed.includes(param))
          filtersUsed.push(param);
      });
      return filtersUsed;
    },
    filtersTranslation() {
      return {
        activities: this.translation.replies.assignments,
        participants: this.translation.replies.name,
        labels: this.translation.replies.labels,
        segments: this.translation.replies.segments,
        types: this.translation.replies.type
      };
    }
  }
};
</script>

<style lang="scss" scoped>
.btn-download {
  color: #6b6b6b;
  font-weight: bold;
  span {
    text-decoration: underline;
    margin-left: 8px;
  }
  &:hover {
    box-shadow: none;
  }
}
.confirm-box {
  display: flex;
  flex-direction: column;
  align-items: center;
  background: #fbfafa;
  border-radius: 5px;
  padding: 20px 10px;
  color: #6b6b6b;
  > div {
    display: flex;
    justify-content: center;
    width: 100%;
    button {
      background: #e7e7e7;
      font-weight: bold;
      border-radius: 25px;
      width: 100%;
      max-width: 200px;
      margin: 0 10px;
      color: #6b6b6b;
      &:nth-of-type(2) {
        color: #ffffff;
      }
    }
  }
}

.param {
  color: #6b6b6b;
  margin: 0 30px 0 0;
  font-weight: bold;
  opacity: 0.3;
}
</style>
