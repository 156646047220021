<template>
  <div class="image-evaluation-wrapper">
    <config-heatmap
      :id="id"
      :activityId="chartData.activityId"
      :taskId="chartData.taskId"
    />
    <div v-if="config && !loaderConfig">
      <heatmap
        :chartData="chartData"
        :id="id"
        :heatmapData="chartData.answersValues.heatmapData"
        :image="chartData.answersValues.imageMetadata.image.URL"
        :width="chartData.answersValues.imageMetadata.dimensions.width"
        :height="chartData.answersValues.imageMetadata.dimensions.height"
        :max="config.dot.max"
        :radius="config.dot.radius"
        :maxOpacity="config.dot.maxOpacity"
        :blur="config.dot.blur"
        v-if="chartData.config.isHeatmap"
      />
    </div>
    <div v-else class="d-flex justify-content-center py-5">
      <loader />
    </div>
  </div>
</template>

<script>
import heatmap from "@/components/charts/heatmap.vue";
import configHeatmap from "@/components/charts/configHeatmap.vue";
import loader from "@/components/general/loader.vue";
import { mapState } from "vuex";

export default {
  props: {
    chartData: {
      required: true
    },
    id: {
      required: true
    }
  },
  components: {
    heatmap,
    configHeatmap,
    loader
  },
  data() {
    return {
      loaderConfig: false
    };
  },
  computed: {
    ...mapState(["configChart"]),
    config() {
      if (!this.configChart) return false;
      return this.configChart[this.id];
    }
  },
  watch: {
    config() {
      this.loaderConfig = true;
      setTimeout(() => {
        this.loaderConfig = false;
      }, "50");
    }
  }
};
</script>

<style></style>
