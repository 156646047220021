var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"container"},[_c('div',{staticClass:"row py-5"},[_c('div',{staticClass:"col"},[_c('div',{staticClass:"notfound card-b p-5 d-flex flex-column align-items-center"},[_c('p',[_vm._v(" "+_vm._s(_vm.translation.assignment.completed)+" ")]),_c('p',[_c('b-icon',{attrs:{"icon":"check-circle"}})],1),_c('router-link',{staticClass:"btn btn-return",attrs:{"to":{
            name: 'tasks',
            query: {
              _activity: this.$route.query._activity,
              _pageParticipant: 1,
              _pageReplies: 1,
              _preview: true,
              _previous: this.$route.query._p
            }
          }}},[_vm._v(" "+_vm._s(_vm.translation.pagination.previous)+" ")])],1)])])])}
var staticRenderFns = []

export { render, staticRenderFns }