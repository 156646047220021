<template>
  <div class="container">
    <div class="row py-5">
      <div class="col">
        <div class="card-b mb-4 p-md-5 p-3">
          <div class="option-btns">
            <button
              v-if="
                $store.getters.info.user.type === userTypesText.admin ||
                  $store.getters.info.user.type === userTypesText.moderator ||
                  ($store.getters.info.user.type === userTypesText.observer &&
                    !+permissions.observers.hide_report_replies)
              "
              class="btn px-4 px-md-5"
              :class="{ active: option === tabOptions.reports }"
              :style="{
                background:
                  option === tabOptions.reports
                    ? $store.getters.theme[2]
                    : $store.getters.theme[0],
                color: option === tabOptions.reports ? '#ffffff' : '#999999'
              }"
              @click="changeTab(tabOptions.reports)"
            >
              {{ translation.replies.replies }}
            </button>
            <button
              v-if="
                $store.getters.info.user.type === userTypesText.admin ||
                  $store.getters.info.user.type === userTypesText.moderator ||
                  ($store.getters.info.user.type === userTypesText.observer &&
                    !+permissions.observers.hide_report_media)
              "
              class="btn px-4 px-md-5"
              :class="{ active: option === tabOptions.media }"
              :style="{
                background:
                  option === tabOptions.media
                    ? $store.getters.theme[2]
                    : $store.getters.theme[0],
                color: option === tabOptions.media ? '#ffffff' : '#999999'
              }"
              @click="changeTab(tabOptions.media)"
            >
              {{ translation.replies.media }}
            </button>
            <button
              v-if="
                ($store.getters.info.user.type === userTypesText.admin ||
                  $store.getters.info.user.type === userTypesText.moderator ||
                  ($store.getters.info.user.type === userTypesText.observer &&
                    !+permissions.observers.hide_report_charts)) &&
                  !exportedFiles
              "
              class="btn px-4 px-md-5"
              :class="{ active: option === tabOptions.charts }"
              :style="{
                background:
                  option === tabOptions.charts
                    ? $store.getters.theme[2]
                    : $store.getters.theme[0],
                color: option === tabOptions.charts ? '#ffffff' : '#999999'
              }"
              @click="changeTab(tabOptions.charts)"
            >
              {{ translation.replies.charts }}
            </button>
          </div>
          <div
            v-if="
              $store.getters.info.user.type === userTypesText.admin ||
                $store.getters.info.user.type === userTypesText.moderator ||
                ($store.getters.info.user.type === userTypesText.observer &&
                  (!+permissions.observers.hide_report_media ||
                    !+permissions.observers.hide_report_charts ||
                    !+permissions.observers.hide_report_replies))
            "
          >
            <transition mode="out-in">
              <exported-files
                v-if="exportedFiles"
                :option="option"
                @return="toggleExportedFiles"
              />
              <div v-else>
                <div
                  v-if="option !== tabOptions.charts"
                  class="
                  d-sm-flex
                  align-items-center
                  justify-content-between
                  pt-5
                "
                >
                  <div class="exported-files pb-3 mr-4">
                    <a
                      :style="{ background: $store.getters.theme[0] }"
                      @click="toggleExportedFiles"
                      target="blank"
                      class="btn hover py-2 px-md-5 mr-4"
                    >
                      {{ translation.replies.exported_files }}
                    </a>
                    <p class="m-0">
                      {{ translation.replies.track_the_status }}
                    </p>
                  </div>
                </div>

                <transition mode="out-in">
                  <filters
                    class="mb-4 mt-5"
                    v-if="filterStep === filterSteps.initial"
                    :option="option"
                    @queryParams="triggerRequests"
                    @nextStep="filterStep = filterSteps.confirm"
                    :participants="participants"
                    :medias="medias"
                  />
                  <filters-confirm
                    class="mb-4"
                    v-if="filterStep === filterSteps.confirm"
                    :option="option"
                    @previousStep="filterStep = filterSteps.initial"
                    @nextStep="filterStep = filterSteps.export"
                  />
                  <filters-export
                    class="mb-4"
                    v-if="filterStep === filterSteps.export"
                    :option="option"
                    @initialStep="filterStep = filterSteps.initial"
                    @openExportedFiles="toggleExportedFiles"
                  />
                </transition>

                <div class="d-flex justify-content-center py-5" v-if="loader">
                  <loader />
                </div>
                <div v-else>
                  <transition mode="out-in">
                    <answers
                      v-if="
                        ($store.getters.info.user.type ===
                          userTypesText.moderator ||
                          $store.getters.info.user.type ===
                            userTypesText.admin ||
                          ($store.getters.info.user.type ===
                            userTypesText.observer &&
                            !+permissions.observers.hide_report_replies)) &&
                          option === tabOptions.reports &&
                          !showExportedFiles
                      "
                      class="mt-4"
                      :getAnswers="getAnswers"
                      :participants="participants"
                    />
                    <media
                      v-if="
                        ($store.getters.info.user.type ===
                          userTypesText.moderator ||
                          $store.getters.info.user.type ===
                            userTypesText.admin ||
                          ($store.getters.info.user.type ===
                            userTypesText.observer &&
                            !+permissions.observers.hide_report_media)) &&
                          option === tabOptions.media &&
                          !showExportedFiles
                      "
                      :participants="medias"
                    />
                    <charts
                      v-if="
                        ($store.getters.info.user.type ===
                          userTypesText.moderator ||
                          $store.getters.info.user.type ===
                            userTypesText.admin ||
                          ($store.getters.info.user.type ===
                            userTypesText.observer &&
                            !+permissions.observers.hide_report_charts)) &&
                          option === tabOptions.charts &&
                          !showExportedFiles
                      "
                      :charts="charts"
                    />
                  </transition>
                </div>

                <div
                  class="d-flex justify-content-center py-5"
                  v-if="scrollLoader"
                >
                  <loader />
                </div>

                <pagination-query
                  id="pagination-charts"
                  v-if="minPerPage < total"
                  :perPage="perPage"
                  :total="total"
                  :options="[5, 10, 20, 50, 100]"
                  @perPageUpdate="newPerPage => (perPage = newPerPage)"
                />
              </div>
            </transition>
          </div>
          <div v-else class="warn">
            {{ translation.errors_global.observers_are_not_allowed }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import axios from "axios";
import { api } from "@/services.js";
import filters from "@/components/answers/filters.vue";
import filtersConfirm from "@/components/answers/filtersConfirm.vue";
import filtersExport from "@/components/answers/filtersExport.vue";
import answers from "@/components/general/answers.vue";
import media from "@/components/answers/media.vue";
import charts from "@/components/answers/charts.vue";
import exportedFiles from "@/components/answers/exportedFiles.vue";
import paginationQuery from "@/components/general/paginationQuery.vue";
import loader from "@/components/general/loader.vue";
import { tabOptions, userTypesText } from "@/constants";
import { mapState } from "vuex";

export default {
  components: {
    filters,
    filtersConfirm,
    filtersExport,
    answers,
    media,
    charts,
    exportedFiles,
    paginationQuery,
    loader
  },
  data() {
    return {
      participants: [],
      medias: [],
      charts: [],
      total: 0,
      perPage: 5,
      minPerPage: 5,
      loader: false,
      scrollLoader: false,
      showExportedFiles: false,
      filterStep: 1,
      filterSteps: {
        initial: 1,
        confirm: 2,
        export: 3
      },
      tabOptions,
      userTypesText
    };
  },

  computed: {
    ...mapState(["permissions"]),

    page() {
      return this.$route.query._page;
    },

    option() {
      return this.$route.query._option;
    },

    exportedFiles() {
      return (
        this.$route.query._exportedFiles &&
        JSON.parse(this.$route.query._exportedFiles)
      );
    }
  },

  watch: {
    showExportedFiles() {
      if (this.showExportedFiles) this.filterStep = this.filterSteps.initial;
    },

    option() {
      this.triggerRequests();
      if (this.filterStep !== this.filterSteps.initial)
        this.filterStep = this.filterSteps.initial;
    },

    page() {
      this.triggerRequests();
    },

    perPage() {
      this.triggerRequests(true);
    }
  },

  methods: {
    triggerRequests(noScroll) {
      this.option === this.tabOptions.reports && this.getAnswers(noScroll);
      this.option === this.tabOptions.media && this.getMedia(noScroll);
      this.option === this.tabOptions.charts && this.getCharts(noScroll);
    },

    changeTab(option) {
      const isDifferentRoute = this.option !== option;

      if (isDifferentRoute) {
        this.$router.push({
          query: {
            ...this.$route.query,
            _option: option,
            _page: 1,
            _q: ""
          }
        });
      }
    },

    toggleExportedFiles() {
      this.showExportedFiles = !this.showExportedFiles;

      this.$router.push({
        query: {
          ...this.$route.query,
          _exportedFiles: this.showExportedFiles
        }
      });
    },

    getCharts(noScroll) {
      const communityId = this.$store.getters.community.id;
      const queryParams = localStorage.getItem(
        `${this.option}-queryParams-${communityId}`
      );

      if (queryParams) {
        if (!noScroll) this.loader = true;
        else this.scrollLoader = true;

        let url = `${this.exportChartHost}get-community-charts?communityId=${communityId}`;

        url += `&${queryParams}`;

        url += `offset=${this.perPage * (this.page - 1)}&limit=${this.perPage}`;

        axios
          .get(url, {
            headers: {
              Authorization: `Bearer ${this.$store.getters.token}`
            }
          })
          .then(r => {
            if (r.status === 200) {
              this.charts = r.data.charts;
              this.total = r.data.chartsTotal;
              this.loader = false;
              this.scrollLoader = false;
            }
          })
          .catch(() => {
            this.loader = false;
            this.scrollLoader = false;
          });
      }
    },

    getMedia(noScroll) {
      const communityId = this.$store.getters.community.id;
      const queryParams = localStorage.getItem(
        `${this.option}-queryParams-${communityId}`
      );

      if (queryParams) {
        if (!noScroll) this.loader = true;
        else this.scrollLoader = true;

        let url = `${this.exportMediaHost}media/all?communityId=${communityId}`;
        url += `&${queryParams}`;
        url += `offset=${this.perPage * (this.page - 1)}&limit=${this.perPage}`;

        axios
          .get(url, {
            headers: {
              Authorization: `Bearer ${this.$store.getters.token}`
            }
          })
          .then(r => {
            if (r.status === 200) {
              this.medias = Object.values(r.data.medias);
              this.total = r.data.total_answers;
              this.loader = false;
              this.scrollLoader = false;
            }
          })
          .catch(() => {
            this.loader = false;
            this.scrollLoader = false;
          });
      }
    },

    getAnswers(noScroll) {
      const communityId = this.$store.getters.community.id;
      const queryParams = localStorage.getItem(
        `${this.option}-queryParams-${communityId}`
      );

      if (queryParams) {
        if (!noScroll) this.loader = true;
        else this.scrollLoader = true;

        let url = `report/community/${communityId}`;
        url += `?${queryParams}`;
        url += `&offset=${this.perPage * (this.page - 1)}&limit=${
          this.perPage
        }`;

        api
          .get(url, {
            headers: {
              Authorization: `Bearer ${this.$store.getters.token}`
            }
          })
          .then(r => {
            if (r.status === 200) {
              this.participants = Object.values(r.data.report);
              this.total = r.data.total_answers;
              this.loader = false;
              this.scrollLoader = false;
            }
          })
          .catch(() => {
            this.loader = false;
            this.scrollLoader = false;
          });
      }
    }
  },

  created() {
    this.triggerRequests();
  }
};
</script>

<style lang="scss" scoped>
.option-btns {
  display: flex;
  justify-content: center;
  button {
    font-weight: bold;
    font-size: 1.3rem;
    transition: all 0.3s ease;
    min-width: 245px;
    border: none;
    &:nth-of-type(1) {
      border-top-left-radius: 35px;
      border-bottom-left-radius: 35px;
      border-right: 1px solid rgba(0, 0, 0, 0.1);
    }
    &:last-of-type {
      border-top-right-radius: 35px;
      border-bottom-right-radius: 35px;
      border-left: 1px solid rgba(0, 0, 0, 0.1);
    }
    &.active {
      z-index: 2;
    }
    &:hover {
      color: #666 !important;
    }
  }
}

@media (max-width: 991.98px) {
  .option-btns {
    button {
      min-width: 150px;
      font-size: 0.8rem;
    }
  }
}

@media (max-width: 767.98px) {
  .option-btns {
    button {
      min-width: 80px;
      font-size: 0.8rem;
    }
  }
}

@media (max-width: 567.98px) {
  .option-btns {
    margin-top: 20px;
    button {
      min-width: 60px;
      font-size: 0.8rem;
    }
  }
}

.exported-files {
  display: flex;
  align-items: center;
  a {
    cursor: pointer;
    border-radius: 7px;
    font-weight: bold;
    color: #6b6b6b;
    text-decoration: none;
    text-transform: uppercase;
  }
  p {
    max-width: 180px;
    font-size: 0.8rem;
    color: #6b6b6b;
    span {
      font-weight: bold;
    }
  }
}

@media (max-width: 767.98px) {
  .exported-files {
    flex-direction: column;
    margin-right: 0 !important;
    a {
      font-size: 0.8rem;
      white-space: nowrap;
      margin-right: 0 !important;
      margin-bottom: 10px;
    }
    p {
      text-align: center;
    }
  }
}

.warn {
  color: #6b6b6b;
}
</style>
