<template>
  <div class="whitespace-data" v-html="whitespaceData"></div>
</template>

<script>
import { blankRegexpGlobal } from "@/constants";

export default {
  props: {
    answer: {
      required: true
    }
  },
  data() {
    return {
      blankRegexpGlobal
    };
  },
  computed: {
    whitespaceData() {
      return this.answer.raw_answer.replace(
        this.blankRegexpGlobal,
        `<b>$2</b>`
      );
    }
  }
};
</script>

<style lang="scss">
.whitespace-data {
  * {
    font-size: 1rem !important;
  }
}
</style>
