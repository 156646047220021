<template>
  <div>
    <p>
      <span class="option" v-for="(option, index) in matrixData" :key="index">
        <b>{{ option.column_value }}</b
        >:
        {{ option.row_value }}
      </span>
    </p>
    <p
      v-if="
        answer.answer_object &&
          answer.answer_object.comment &&
          answer.answer_object.comment.length
      "
    >
      <span :style="{ color: $store.getters.theme[3] }" class="comments">
        {{ translation.answer_task.comments }}
      </span>
      {{ answer.answer_object.comment }}
    </p>
  </div>
</template>

<script>
export default {
  props: {
    answer: {
      required: true
    }
  },
  computed: {
    matrixData() {
      let array = [];
      if (this.answer.answer_object) {
        array = this.answer.answer_object.options.filter(
          option => option.marked
        );
      }

      return array;
    }
  }
};
</script>

<style lang="scss" scoped>
.option {
  display: block;
}

.comments {
  margin-top: 20px;
  display: block;
  text-transform: uppercase;
  font-weight: bold;
}
</style>
