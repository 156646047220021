<template>
  <div class="communities py-5">
    <div class="container">
      <div v-if="!loader" class="row justify-content-center">
        <b-modal
          no-enforce-focus
          id="copy-community"
          @ok.prevent="copyCommunity"
          :cancel-title="translation.global.cancel"
          :title="translation.communities.domain_text"
        >
          <b-form-input
            id="domain"
            v-model="domain"
            :state="!isDomainValid && !!domain.length"
            v-b-tooltip.hover="{ variant: 'secondary' }"
            :title="translation.communities.error.domain_validation"
          ></b-form-input>
        </b-modal>
        <div
          v-for="community in communities"
          :key="community.id"
          class="community col-md-4 col-lg-3 col-sm-6 mb-4"
        >
          <div class="card-b p-0">
            <div class="card-header p-0 ">
              <div class="overflow-hidden">
                <img v-if="community.banner.length" :src="community.banner" />
              </div>
              <div class="style">
                <img class="img-fluid" src="@/img/white-curve-small.png" />
              </div>
            </div>
            <div class="px-3 py-3">
              <div>
                <div class="d-flex align-items-center justify-content-between">
                  <p
                    class="h5 font-weight-bold community-name mb-0"
                    :style="{
                      color: $store.getters.theme[2]
                    }"
                  >
                    {{ community.name }}
                  </p>
                  <div class="d-flex">
                    <button
                      :style="{
                        'border-color': $store.getters.theme[2],
                        color: $store.getters.theme[2]
                      }"
                      class="btn btn-edit hover ml-2"
                      :title="translation.global.copy"
                      v-b-tooltip.hover="{ variant: 'secondary' }"
                      @click="openModalCopy(community)"
                    >
                      <b-icon icon="stickies-fill"></b-icon>
                    </button>

                    <button
                      :style="{
                        'border-color': $store.getters.theme[2],
                        color: $store.getters.theme[2]
                      }"
                      class="btn btn-edit hover ml-2"
                      :title="translation.global.edit"
                      v-b-tooltip.hover="{ variant: 'secondary' }"
                      @click="editCommunity(community)"
                    >
                      <b-icon icon="pencil-fill"></b-icon>
                    </button>
                  </div>
                </div>
                <p class="date mb-1 mt-3">
                  <span class="font-weight-bold"
                    >{{ translation.communities.start_time }}:</span
                  >
                  {{ community.start_at | formatDate }}
                </p>
                <p class="date">
                  <span class="font-weight-bold"
                    >{{ translation.communities.end_time }}:</span
                  >
                  {{ community.end_at | formatDate }}
                </p>
                <button
                  :style="{
                    color: $store.getters.theme[3],
                    'border-color': $store.getters.theme[3]
                  }"
                  class="btn btn-community hover px-3 py-1"
                  @click="goToCommunity(community)"
                >
                  {{ translation.communities.see_community }}
                </button>
              </div>
            </div>
          </div>
        </div>
        <div class="community col-md-4 col-lg-3 col-sm-6 mb-4">
          <a
            class="card-b p-0 pointer create-community"
            :style="{ color: $store.getters.theme[2] }"
            @click="createCommunity"
          >
            <b-icon icon="plus"></b-icon>
            {{ translation.communities.create_community }}
          </a>
        </div>
      </div>

      <div class="d-flex justify-content-center py-5" v-else-if="loader">
        <loader />
      </div>
    </div>
  </div>
</template>

<script>
import { api } from "@/services.js";
import loader from "@/components/general/loader.vue";
import { themes } from "@/constants";
import { mapMutations } from "vuex";

export default {
  components: { loader },
  data() {
    return {
      loader: false,
      communities: [],
      regexpDomainValidation: /[()!^&$@=;:’+, ?\\{\\}^%`\]'">[~>#|A-Z]/g,
      domain: "",
      currentCommunity: null,
      themes
    };
  },
  filters: {
    formatDate(value) {
      if (!value) return "";

      return value.replace(/-/g, "/");
    }
  },
  computed: {
    isDomainValid() {
      return this.domain.match(this.regexpDomainValidation);
    }
  },
  methods: {
    ...mapMutations(["UPDATE_PRIVACY", "UPDATE_PERMISSIONS"]),

    createCommunity() {
      this.$store.commit("UPDATE_ACTIVE_COMMUNITY", null);
      this.$router.push({ name: "createcommunity" });
    },

    openModalCopy(community) {
      this.domain = "";
      this.currentCommunity = community;
      this.$bvModal.show("copy-community");
    },

    copyCommunity() {
      if (!this.domain.length)
        this.$toast.error(this.translation.errors_global.fill_fields);
      else if (this.isDomainValid)
        this.$toast.error(this.translation.communities.error.domain_validation);
      else {
        this.loader = true;
        this.$nextTick(() => {
          this.$bvModal.hide("copy-community");
        });
        api
          .post(
            `community/${this.currentCommunity.id}/copy`,
            {
              domain: this.domain
            },
            {
              headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${this.$store.getters.token}`
              }
            }
          )
          .then(r => {
            if (r.status === 200) {
              this.getCommunities();
              this.$toast.success(
                this.translation.communities.success.community_copied
              );
              this.loader = false;
            }
          })
          .catch(() => {
            this.loader = false;
            this.$toast.error(
              this.translation.errors_global.something_went_wrong
            );
          });
      }
    },
    editCommunity(community) {
      this.$store.commit("UPDATE_ACTIVE_COMMUNITY", community);
      this.$router.push({ name: "createcommunity" });
    },

    getPrivacy() {
      if (this.$store.getters.community) {
        api
          .get(`community/${this.$store.getters.community.id}/privacy`, {
            headers: {
              Authorization: `Bearer ${this.$store.getters.token}`
            }
          })
          .then(r => {
            if (r.status === 200) {
              this.UPDATE_PRIVACY(r.data);
            }
          });
      }
    },

    getPermissions() {
      if (this.$store.getters.community) {
        api
          .get(`community/${this.$store.getters.community.id}/permission`, {
            headers: {
              Authorization: `Bearer ${this.$store.getters.token}`
            }
          })
          .then(r => {
            if (r.status === 200) {
              this.UPDATE_PERMISSIONS(r.data);
            }
          });
      }
    },

    goToCommunity(community) {
      this.$store.commit("UPDATE_COMMUNITY", community);

      const lang = community.language.abbreviation;
      this.$store.commit("UPDATE_LANGUAGE", lang);

      let userTheme = 4;
      if (this.$store.getters.token && this.$store.getters.community)
        userTheme = +this.$store.getters.community.theme;
      let theme = themes[userTheme];

      this.$store.commit("UPDATE_THEME", JSON.stringify(theme));

      this.getPrivacy();
      this.getPermissions();

      this.$router.push({ name: "feed" });
    },

    getCommunities() {
      this.loader = true;

      api
        .get(`client/${this.$store.getters.info.user.client.id}/communities`, {
          headers: {
            Authorization: `Bearer ${this.$store.getters.token}`
          }
        })
        .then(r => {
          if (r.status === 200) {
            this.communities = r.data.communities;
          }
          this.loader = false;
        })
        .catch(() => {
          this.loader = false;
        });
    }
  },
  created() {
    this.getCommunities();
    this.$store.commit("UPDATE_COMMUNITY", null);
  }
};
</script>

<style lang="scss" scoped>
.community {
  min-height: 325px;
  p {
    color: #6b6b6b;
  }
}

.card-b {
  min-height: 325px;
}

.card-header {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 130px;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  > div {
    &:nth-of-type(1) {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 100%;
      width: 100%;
      border-top-left-radius: 20px;
      border-top-right-radius: 20px;
      overflow: hidden;
      img {
        object-fit: cover;
        width: 270px;
        height: 130px;
      }
    }
  }
}

@media (max-width: 767.98px) {
  .card-header {
    div {
      &:nth-of-type(1) {
        img {
          width: 540px;
          height: 130px;
        }
      }
    }
  }
}

.style {
  position: absolute;
  bottom: -9px;
  height: 25px;
  overflow: hidden;
  width: 100%;
  img {
    width: 110%;
    height: 100%;
  }
}

@media (max-width: 1199.98px) {
  .card-header {
    height: 100px;
    div {
      &:nth-of-type(1) {
        height: 100px;
      }
    }
  }
}

@media (max-width: 767.98px) {
  .card-header {
    height: 130px;
    div {
      &:nth-of-type(1) {
        height: 130px;
      }
    }
  }
}

.community-name {
  word-wrap: break-word;
  word-break: break-word;
}

.create-community {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  text-decoration: none;
  font-size: 1.2rem;
  svg {
    font-size: 5rem;
  }
}

.date {
  font-size: 0.9rem;
}

.btn-community {
  position: absolute;
  bottom: 20px;
  letter-spacing: 0.12em;
  text-transform: uppercase;
  font-size: 0.75rem;
  font-weight: bold;
  border-radius: 30px;
  white-space: nowrap;
  background: none;
  border: 1px solid;
}

.btn-edit {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 30px;
  height: 30px;
  border: 2px solid;
  border-radius: 50%;
  svg {
    font-size: 0.9rem !important;
  }
}
</style>
