<template>
  <div class="row task-wrapper">
    <div v-if="!loading" class="col">
      <div class="task-text img-question">
        <p v-html="text"></p>
      </div>
      <div class="d-flex flex-wrap">
        <attachment :attachments="task.attachments" :deleteFile="null" />
      </div>
      <div class="wrapper">
        <div v-if="disabled" class="disabled"></div>
        <div class="d-flex justify-content-center py-4">
          <p
            class="select-box"
            :style="{ background: $store.getters.theme[1] }"
          >
            {{ translation.answer_task.drag_and_drop.drag_and_drop }}
          </p>
        </div>
        <div class="mb-4">
          <draggable
            class="cards dragArea d-flex justify-content-center flex-wrap"
            :list="cards"
            :group="optionsDraggable"
            handle=".handle"
          >
            <div
              :class="{ hasImage: image }"
              class="card-item"
              :id="`card-${card.text.replace(clearTextRegexp, '')}`"
              v-for="card in cards"
              :key="card.text.replace(clearTextRegexp, '')"
            >
              <div
                class="groups"
                :id="`groups-${card.text.replace(clearTextRegexp, '')}`"
              >
                <div>
                  <div>
                    <div
                      v-for="group in groups"
                      :key="group.groupText.replace(clearTextRegexp, '')"
                      :class="{ active: group.cards.includes(card) }"
                    >
                      <button
                        @click="
                          toggleGroup(
                            card,
                            group,
                            `groups-${card.text.replace(clearTextRegexp, '')}`
                          )
                        "
                        @touchend="
                          toggleGroup(
                            card,
                            group,
                            `groups-${card.text.replace(clearTextRegexp, '')}`
                          )
                        "
                      >
                        <b-icon
                          v-if="!group.cards.includes(card)"
                          icon="circle"
                        ></b-icon>
                        <b-icon v-else icon="check-circle"></b-icon>
                      </button>
                      <span
                        @click="
                          toggleGroup(
                            card,
                            group,
                            `groups-${card.text.replace(clearTextRegexp, '')}`
                          )
                        "
                        @touchend="
                          toggleGroup(
                            card,
                            group,
                            `groups-${card.text.replace(clearTextRegexp, '')}`
                          )
                        "
                      >
                        {{ group.groupText }}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <div class="handle">
                <div class="img" v-if="image && card.img.code.length">
                  <img :src="card.img.code" />
                </div>
                <div class="text">
                  <div
                    :style="{
                      'background-color': $store.getters.theme[2]
                    }"
                  ></div>
                  <p>{{ card.text }}</p>
                </div>
              </div>
              <div class="open-btn">
                <button
                  @click="
                    openGroups(
                      `groups-${card.text.replace(clearTextRegexp, '')}`,
                      `card-${card.text.replace(clearTextRegexp, '')}`
                    )
                  "
                  @touchstart="
                    openGroups(
                      `groups-${card.text.replace(clearTextRegexp, '')}`,
                      `card-${card.text.replace(clearTextRegexp, '')}`
                    )
                  "
                >
                  <b-icon icon="menu-button-wide-fill"></b-icon>
                </button>
              </div>
            </div>
          </draggable>
        </div>
        <div class="d-flex justify-content-center flex-wrap">
          <div
            class="col-md-3 mb-4"
            v-for="group in groups"
            :key="group.groupText.replace(clearTextRegexp, '')"
          >
            <div class="group py-3 px-2">
              <h3>{{ group.groupText }}</h3>
              <draggable
                class="
                  dragArea
                  d-flex
                  justify-content-center justify-content-md-start
                  flex-wrap
                "
                :list="group.cards"
                group="cards"
                @change="added => groupVerification(added, group)"
              >
                <div
                  class="card-wrapper"
                  v-for="card in group.cards"
                  :key="card.text.replace(clearTextRegexp, '')"
                >
                  <div
                    :class="{ hasImage: image }"
                    class="card-item group-card"
                  >
                    <div class="card-hover" v-if="card.text.length > 10">
                      {{ card.text }}
                    </div>
                    <div
                      @click="deleteCard(group, card)"
                      @touchend="deleteCard(group, card)"
                      class="trash"
                      :style="{ color: $store.getters.theme[3] }"
                    >
                      <b-icon icon="trash-fill"></b-icon>
                    </div>
                    <div
                      :style="{
                        pointerEvents:
                          $store.getters.info.user.type ===
                          userTypesText.participant
                            ? 'none'
                            : 'unset'
                      }"
                      class="img"
                      v-if="image && card.img.code.length"
                    >
                      <img :src="card.img.code" />
                    </div>
                    <div class="text">
                      <div
                        :style="{
                          'background-color': $store.getters.theme[2]
                        }"
                      ></div>
                      <p>{{ card.text | groupText }}</p>
                    </div>
                  </div>
                </div>
              </draggable>
            </div>
          </div>
        </div>

        <div class="comments">
          <label
            for="comments"
            v-if="
              task.question_object.has_custom_comment_label &&
                task.question_object.comment_label &&
                task.question_object.comment_label.length
            "
            >{{ task.question_object.comment_label }}</label
          >
          <label for="comments" v-else>
            {{ translation.answer_task.comments }}
          </label>
          <textarea
            class="form-control"
            id="textarea"
            v-model="comments"
            :maxlength="maxCharacters"
            rows="3"
            max-rows="6"
            :disabled="task.is_answered"
          ></textarea>
          <characterAndWordCounter
            :count="
              hasWordsLimitation
                ? this.comments.length
                  ? this.comments.trim().split(/\s+/).length
                  : 0
                : comments.length
            "
            :max="hasWordsLimitation ? maxWords : maxCharacters"
            :label="
              hasWordsLimitation
                ? translation.answer_task.words
                : translation.answer_task.characters
            "
          />
        </div>
      </div>
    </div>
    <div v-if="loading" class="w-100 d-flex justify-content-center">
      <loader />
    </div>
  </div>
</template>

<script>
import { api } from "@/services.js";
import loader from "@/components/general/loader.vue";
import draggable from "vuedraggable";
import attachment from "@/components/general/attachment.vue";
import characterAndWordCounter from "@/components/general/characterAndWordCounter.vue";
import { outsideClick } from "@/helpers.js";

import { userTypesText } from "@/constants";

export default {
  components: {
    draggable,
    loader,
    attachment,
    characterAndWordCounter
  },
  props: {
    task: {
      required: true
    },
    save: {
      required: true
    },
    savePreview: {
      required: true
    },
    getTasks: {
      required: true
    }
  },
  filters: {
    groupText(value) {
      if (!value) return "";

      let text = value.toString();
      if (text.length > 7) value = `${text.substring(0, 7)}...`;
      else value = text;
      return value;
    }
  },
  data() {
    return {
      comments: "",
      question: "",
      groups: [],
      trash: [],
      loading: false,
      disabled: false,
      clearTextRegexp: /[!()^.&$@=;:’+, ?\\{\\}^%`\]'">[~>#|áàâãéèêíïóôõöúçñ]/g,
      outsideClick,
      userTypesText
    };
  },

  computed: {
    optionsDraggable() {
      if (this.finite) {
        return {
          name: "cards"
        };
      } else {
        return {
          name: "cards",
          pull: "clone",
          put: false
        };
      }
    },
    text() {
      const regexpVideo = /(?:(<iframe.*?src="))(.*?)((?:".*?>))((?:<\/iframe>))?/g;

      return this.$store.getters.info.user.type ===
        this.userTypesText.participant
        ? this.task.raw_question.replace(
            regexpVideo,
            "<video src='$2' width='350' height='197' frameborder='0' allowfullscreen='allowfullscreen' controls controlsList='nodownload'></iframe>"
          )
        : this.task.raw_question;
    },
    groupsArray() {
      return this.task.question_object.groups.map(group => ({
        text: group.value
      }));
    },
    cards() {
      return this.task.question_object.cards.map(card => ({
        img: {
          code: card.value.img,
          name: card.value.image_name
        },
        text: card.value.text
      }));
    },
    finite() {
      return this.task.question_object.finite_cards ? true : false;
    },
    image() {
      return this.task.question_object.cards.filter(card => card.has_image)
        .length;
    },
    page() {
      return this.$store.getters.activeTaskIndex;
    },
    obligatoryComment() {
      return this.task.question_object?.has_obligatory_comments;
    },

    hasCharactersLimitation() {
      return this.task.question_object?.has_characters_limitation;
    },

    maxCharacters() {
      return this.hasCharactersLimitation
        ? this.task.question_object?.characters_count?.maximum
        : 5000;
    },

    minCharacters() {
      return this.hasCharactersLimitation
        ? this.task.question_object?.characters_count?.minimum
        : 0;
    },

    hasWordsLimitation() {
      return this.task.question_object?.has_words_limitation;
    },

    maxWords() {
      return this.hasWordsLimitation
        ? this.task.question_object?.words_count?.maximum
        : 2000;
    },

    minWords() {
      return this.hasWordsLimitation
        ? this.task.question_object?.words_count?.minimum
        : 0;
    }
  },
  watch: {
    save() {
      this.answer();
    },
    savePreview() {
      this.answerPreview();
    },
    page() {
      this.init();
    },
    comments() {
      if (this.comments.length >= this.maxCharacters) {
        this.comments = this.comments
          .split("")
          .splice(0, this.maxCharacters)
          .join("");
      }

      const words = this.comments.split(/\s+/);

      const wordsCount = words.length;

      if (wordsCount > this.maxWords) {
        this.comments = words.splice(0, this.maxWords).join(" ");
      }
    }
  },
  methods: {
    toggleGroup(card, group, idGroups) {
      const groups = document.querySelector(`#${idGroups}`);
      if (groups) groups.classList.remove("active");
      if (this.finite) {
        if (group.cards.includes(card)) {
          group.cards.splice(group.cards.indexOf(card), 1);
        } else {
          this.cards.splice(this.cards.indexOf(card), 1);
          group.cards.push(card);
        }
      } else {
        if (group.cards.includes(card)) {
          group.cards.splice(group.cards.indexOf(card), 1);
        } else {
          group.cards.push(card);
        }
      }
    },
    openGroups(idGroups, idCard) {
      const card = document.querySelector(`#${idCard}`);
      const groups = document.querySelector(`#${idGroups}`);
      if (groups) groups.classList.add("active");
      if (card)
        this.outsideClick(card, ["click"], () => {
          groups.classList.remove("active");
        });
    },
    groupVerification(added, group) {
      if (added.added) {
        const element = added.added.element;
        if (group.cards.filter(card => card === element).length > 1) {
          group.cards.splice(group.cards.indexOf(element), 1);
        }
      }
    },
    deleteCard(group, card) {
      group.cards.splice(group.cards.indexOf(card), 1);
      if (this.finite) this.cards.push(card);
    },
    answerPreview() {
      if (!this.loading) {
        const groupCards = [];
        this.groups.forEach(group =>
          group.cards.forEach(card => {
            if (!groupCards.includes(card)) groupCards.push(card);
          })
        );

        if (groupCards.length === this.task.question_object.cards.length) {
          if (this.obligatoryComment && !this.comments.length) {
            this.$toast.error(
              this.translation.answer_task.obligatory_comment_tost
            );

            return;
          }

          if (
            this.comments.length &&
            this.hasCharactersLimitation &&
            this.comments.length < this.minCharacters
          ) {
            this.$toast.error(
              `${this.translation.answer_task.min_characters} ${this.minCharacters} ${this.translation.answer_task.characters}`
            );

            return;
          }

          if (this.comments.length && this.hasWordsLimitation) {
            const wordsCount = this.comments.split(/\s+/).length;

            if (wordsCount < this.minWords) {
              this.$toast.error(
                `${this.translation.answer_task.min_word} ${this.minWords} ${this.translation.answer_task.words}`
              );

              return;
            }
          }

          this.loading = true;
          const answer = {
            comment: this.comments
          };

          answer.options = this.groups.map((group, gIndex) => ({
            group_index: gIndex,
            group_value: group.groupText,
            cards: group.cards.map((card, cIndex) => ({
              card_index: cIndex,
              card_value: {
                text: card.text,
                img: card.img.code,
                image_name: card.img.name
              }
            }))
          }));

          const body = {
            task_id: this.task.id,
            has_attachment: 0,
            has_video: 0,
            has_image: 0,
            raw_answer: JSON.stringify(answer),
            answer
          };

          api
            .post("/answer/send/preview", body, {
              headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${this.$store.getters.token}`
              }
            })
            .then(r => {
              if (r.status === 200) {
                this.$toast.success(
                  this.translation.answer_task.success.reply_registered
                );
                this.getTasks("save");
                this.loading = false;
              }
            })
            .catch(() => {
              this.$toast.error(
                this.translation.answer_task.error.reply_not_registered
              );
              this.loading = false;
            });
        } else {
          this.$toast.error(this.translation.answer_task.error.use_all_cards);
        }
      }
    },
    answer() {
      if (!this.loading) {
        const groupCards = [];
        this.groups.forEach(group =>
          group.cards.forEach(card => {
            if (!groupCards.includes(card)) groupCards.push(card);
          })
        );

        if (groupCards.length === this.task.question_object.cards.length) {
          if (this.obligatoryComment && !this.comments.length) {
            this.$toast.error(
              this.translation.answer_task.obligatory_comment_tost
            );

            return;
          }

          if (
            this.comments.length &&
            this.hasCharactersLimitation &&
            this.comments.length < this.minCharacters
          ) {
            this.$toast.error(
              `${this.translation.answer_task.min_characters} ${this.minCharacters} ${this.translation.answer_task.characters}`
            );

            return;
          }

          if (this.comments.length && this.hasWordsLimitation) {
            const wordsCount = this.comments.split(/\s+/).length;

            if (wordsCount < this.minWords) {
              this.$toast.error(
                `${this.translation.answer_task.min_word} ${this.minWords} ${this.translation.answer_task.words}`
              );

              return;
            }
          }

          this.loading = true;
          const answer = {
            comment: this.comments
          };

          answer.options = this.groups.map((group, gIndex) => ({
            group_index: gIndex,
            group_value: group.groupText,
            cards: group.cards.map((card, cIndex) => ({
              card_index: cIndex,
              card_value: {
                text: card.text,
                img: card.img.code,
                image_name: card.img.name
              }
            }))
          }));

          const body = {
            task_id: this.task.id,
            participant_id: this.$store.getters.info.user.sub,
            has_attachment: 0,
            has_video: 0,
            has_image: 0,
            raw_answer: JSON.stringify(answer),
            answer
          };

          api
            .post("/answer/send", body, {
              headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${this.$store.getters.token}`
              }
            })
            .then(r => {
              if (r.status === 200) {
                this.$toast.success(
                  this.translation.answer_task.success.reply_registered
                );
                this.getTasks("save");
                this.loading = false;
              }
            })
            .catch(() => {
              this.$toast.error(
                this.translation.answer_task.error.reply_not_registered
              );
              this.loading = false;
            });
        } else {
          this.$toast.error(this.translation.answer_task.error.use_all_cards);
        }
      }
    },
    init() {
      this.groups = [];

      if (this.task.is_answered) {
        this.comments = this.task.answer.answer_object.comment;
        this.groups = this.task.answer.answer_object.options.map(group => ({
          groupText: group.group_value,
          cards: group.cards.map(card => ({
            img: {
              code: card.card_value.img,
              name: card.card_value.image_name
            },
            text: card.card_value.text
          }))
        }));

        this.disabled = true;
      } else if (this.task.answer && this.$route.query._preview) {
        this.comments = this.task.answer.answer_object.comment;

        if (this.finite) {
          this.groupsArray.forEach(group => {
            this.groups.push({
              groupText: group.text,
              cards: []
            });
          });
        } else {
          this.groups = this.task.answer.answer_object.options.map(group => ({
            groupText: group.group_value,
            cards: group.cards.map(card => ({
              img: {
                code: card.card_value.img,
                name: card.card_value.image_name
              },
              text: card.card_value.text
            }))
          }));
        }
        this.disabled = false;
      } else {
        this.comments = "";
        this.disabled = false;
        this.groupsArray.forEach(group => {
          this.groups.push({
            groupText: group.text,
            cards: []
          });
        });
      }
    }
  },
  created() {
    this.init();
  },
  mounted() {
    if (this.$store.getters.info.user.type === this.userTypesText.participant) {
      const taskWrapper = document.querySelector(".task-wrapper");

      taskWrapper.addEventListener("contextmenu", e => {
        e.preventDefault();
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.task-text {
  display: flex;
  flex-direction: column;
  color: #6b6b6b;
  overflow-wrap: break-word;
}

.select-box {
  display: inline-block;
  padding: 20px;
  border-radius: 20px;
  color: #6b6b6b;
  font-weight: bold;
  font-size: 1.2rem;
}

.wrapper {
  position: relative;
}

.comments {
  label {
    color: #6b6b6b;
  }
  textarea {
    width: 100%;
    display: block;
    border: 1px solid #b3b3b3;
    border-radius: 10px;
  }
}

.custom-control-label {
  color: red;
}

.group {
  text-align: center;
  width: 100%;
  border-radius: 22px;
  min-height: 100px;
  padding: 20px;
  position: relative;
  margin-right: 20px;
  background-color: #f3f4f6;
  h3 {
    color: #6b6b6b;
    font-weight: bold;
    font-size: 1rem;
    word-wrap: break-word;
    word-break: break-word;
  }
  .dragArea {
    height: 100%;
    min-height: 60px;
  }
}

.card-wrapper {
  display: flex;
  justify-content: center;
  padding-right: auto;
  position: relative;
  width: 100%;
}

.card-item {
  box-shadow: 0px 0px 37px -16px #c4c4c4;
  border-radius: 10px;
  margin: 10px 6px;
  position: relative;
  background-color: white;

  width: 230px;
  height: 100px;
  &.hasImage {
    width: 300px;
    height: 200px;
    .text {
      height: 80px;

      p {
        height: 80px;
      }

      div {
        height: 80px;
        border-top-left-radius: 0px;
        border-top-right-radius: 0px;
      }
    }
  }
  .open-btn {
    right: -2px;
    position: absolute;
    z-index: 20;
    bottom: -8px;
    font-size: 1.1rem;
    button {
      border: none;
      background: none;
      color: #fff;
      padding: 10px;
      &:focus {
        outline: none;
      }
    }
  }
  .handle {
    cursor: move;
    height: 100%;
    width: 100%;
  }
  .groups {
    width: 100%;
    position: absolute;
    bottom: -100px;
    z-index: 9000;
    height: 100px;
    padding-top: 10px;
    display: none;
    animation: grow 400ms ease-in-out forwards;
    transform-origin: top center;
    &.active {
      display: block;
    }
    > div {
      border-radius: 10px;
      margin: 10px 6px 6px;
      background-color: white;
      font-size: 1rem;
      word-wrap: break-word;
      word-break: break-word;
      box-shadow: 0px 0px 37px -16px #c4c4c4;
      padding: 5px 5px 0 5px;
      overflow: hidden;

      button {
        border: none;
        background: none;
        cursor: pointer;

        &:focus {
          outline: none;
        }
      }
      span {
        cursor: pointer;
      }
      > div {
        height: 100px;
        overflow-y: scroll;

        &::-webkit-scrollbar {
          width: 8px;
        }
        &::-webkit-scrollbar-track {
          background: rgba(0, 0, 0, 0);
          border-radius: 10px;
        }
        &::-webkit-scrollbar-thumb {
          background: #e6e6e6;
          border-radius: 10px;
          height: 40px;
        }
        &::-webkit-scrollbar-thumb:hover {
          background-color: rgba(0, 0, 0, 0.2);
        }
        div {
          overflow: hidden;
          padding: 5px;
          cursor: default;
          margin-bottom: 5px;
          border-bottom: 1px solid rgba(0, 0, 0, 0.1);
          &:last-of-type {
            border-bottom: none;
          }
          &.active {
            background-color: rgba(0, 0, 0, 0.1);
          }
        }
      }
    }
  }
  .img {
    display: flex;
    align-items: center;
    justify-content: center;
    img {
      border-radius: 10px;
      object-fit: cover;
      width: 300px;
      height: 200px;
    }
  }
  .text {
    position: absolute;
    bottom: 0;
    z-index: 5;
    height: 100px;
    width: 100%;
    div {
      border-radius: 10px;
      position: absolute;
      top: 0;
      height: 100%;
      width: 100%;
      opacity: 0.8;
      border-radius: 10px;
    }
    p {
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 0;
      font-size: 1rem;
      position: relative;
      z-index: 5;
      font-weight: bold;
      text-align: center;
      color: #fff;
      padding: 5px 10px 5px;
      background: none;
      border: none;
      width: 100%;
      height: 100px;
      line-height: 1rem;
      font-size: 1rem;
      word-wrap: break-word;
      word-break: break-word;
      &:focus {
        outline: none;
      }
    }
  }

  &.group-card {
    height: 40px;
    width: 100%;
    max-width: 130px;
    margin: 0 0 10px 0;
    .card-hover {
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 0.8rem;
      bottom: -70px;
      width: 130px;
      height: 65px;
      padding: 5px 10px;
      display: none;
      position: absolute;
      border-radius: 10px;
      z-index: 25;
      background: #fff;
      word-wrap: break-word;
      word-break: break-word;
      box-shadow: 0px 0px 37px -16px #c4c4c4;
      animation: show 0.3s linear;
      &::before {
        content: "";
        position: absolute;
        display: block;
        width: 5px;
        height: 5px;
        top: -10px;
        border: 5px solid rgba(0, 0, 0, 0);
        border-bottom: 5px solid #fff;
        right: calc(50% - 2.5px);
      }
    }
    &:hover {
      .card-hover {
        display: block;
      }
    }
    &.hasImage {
      height: 90px;
      .text {
        height: 20px;
        div {
          height: 20px;
        }
        p {
          height: 20px;
          width: 100%;
        }
      }
    }
    img {
      object-fit: cover;
      width: 100%;
      height: 90px;
    }
    .text {
      height: 40px;
      p {
        height: 40px;
        width: calc(100% - 20px);
      }
    }
  }
}

.trash {
  cursor: pointer;
  position: absolute;
  right: 10px;
  top: 6px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 25px;
  height: 25px;
  color: #6b6b6b;
  font-size: 1rem;
  box-shadow: 0px 0px 37px -16px #c4c4c4;
  border-radius: 50%;
  background-color: #ffffff;
  z-index: 10;
}

.disabled {
  position: absolute;
  width: 100%;
  height: 100%;
  background: #fff;
  opacity: 0.3;
  z-index: 12;
}

.cards.dragArea {
  border: 1px dashed #6b6b6b;
  min-height: 80px;
  border-radius: 20px;
  padding: 20px;
}
</style>
