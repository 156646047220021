<template>
  <div class="user-profile py-5">
    <div
      class="profile-img"
      :style="{
        color: $store.getters.theme[3]
      }"
    >
      <img
        v-if="$store.getters.info.user.picture"
        :src="$store.getters.info.user.picture"
        alt="Profile Picture"
      />
      <b-icon
        v-else
        class="icon-profile"
        icon="person-fill"
        aria-hidden="true"
      ></b-icon>
    </div>
    <div class="info">
      <p
        :style="{
          color: $store.getters.theme[3]
        }"
      >
        {{ $store.getters.info.user.name }}
      </p>
      <p>
        {{
          $store.getters.info.user.type === userTypesText.admin
            ? translation.chat.admin
            : userType === userTypes.moderator
            ? translation.chat.moderator
            : translation.chat.observer
        }}
      </p>
    </div>
    <div class="btns mt-4">
      <button
        v-if="
          $store.getters.info.user.type === userTypesText.admin ||
            $store.getters.info.user.type === userTypesText.moderator
        "
        @click="
          (userType = userTypes.participant),
            $store.commit('UPDATE_SHOW_TRANSMISSION_LIST', false)
        "
        :class="{ active: userType === userTypes.participant }"
        :style="{
          color:
            userType === userTypes.participant ? $store.getters.theme[2] : ''
        }"
        class="btn pl-md-5"
      >
        {{ translation.chat.participant }}

        <span
          class="target"
          :class="{ active: userType === userTypes.participant }"
          :style="{
            color:
              userType === userTypes.participant ? $store.getters.theme[2] : ''
          }"
        ></span>
      </button>
      <a
        :class="{ active: showTransmissionList }"
        v-if="
          ($store.getters.info.user.type === userTypesText.admin ||
            $store.getters.info.user.type === userTypesText.moderator) &&
            userType === userTypes.participant
        "
        @click="
          $store.commit('UPDATE_SHOW_TRANSMISSION_LIST', true),
            $store.commit('UPDATE_ACTIVE_TRANSMISSION_LIST_ID', null)
        "
      >
        {{ translation.chat.transmission_list }}
      </a>
      <button
        @click="
          (userType = userTypes.moderator),
            $store.commit('UPDATE_SHOW_TRANSMISSION_LIST', false)
        "
        :class="{ active: userType === userTypes.moderator }"
        :style="{
          color: userType === userTypes.moderator ? $store.getters.theme[2] : ''
        }"
        class="btn pl-md-5"
      >
        {{ translation.chat.moderator }}

        <span
          class="target"
          :class="{ active: userType === userTypes.moderator }"
          :style="{
            color:
              userType === userTypes.moderator ? $store.getters.theme[2] : ''
          }"
        ></span>
      </button>
      <button
        @click="
          (userType = userTypes.admin),
            $store.commit('UPDATE_SHOW_TRANSMISSION_LIST', false)
        "
        :class="{ active: userType === userTypes.admin }"
        :style="{
          color: userType === userTypes.admin ? $store.getters.theme[2] : ''
        }"
        class="btn pl-md-5"
      >
        {{ translation.chat.admin }}

        <span
          class="target"
          :class="{ active: userType === userTypes.admin }"
          :style="{
            color: userType === userTypes.admin ? $store.getters.theme[2] : ''
          }"
        ></span>
      </button>
      <button
        @click="
          (userType = userTypes.observer),
            $store.commit('UPDATE_SHOW_TRANSMISSION_LIST', false)
        "
        :class="{ active: userType === userTypes.observer }"
        :style="{
          color: userType === userTypes.observer ? $store.getters.theme[2] : ''
        }"
        class="btn pl-md-5"
      >
        {{ translation.chat.observer }}

        <span
          class="target"
          :class="{ active: userType === userTypes.observer }"
          :style="{
            color:
              userType === userTypes.observer ? $store.getters.theme[2] : ''
          }"
        ></span>
      </button>
    </div>
  </div>
</template>

<script>
import { userTypes, userTypesText } from "@/constants";
import { mapState } from "vuex";

export default {
  data() {
    return {
      userTypes,
      userTypesText,
      userType: null
    };
  },
  computed: {
    ...mapState(["showTransmissionList"])
  },
  watch: {
    userType() {
      this.$emit("userType", this.userType);
    }
  },
  created() {
    if (this.$store.getters.info.user.type === userTypesText.observer)
      this.userType = this.userTypes.moderator;
    else this.userType = this.userTypes.participant;
  }
};
</script>

<style lang="scss" scoped>
.user-profile {
  height: calc(100vh - 150px);
  min-height: 550px;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: #ffffff;
  box-shadow: 0px 0px 45px rgba(0, 0, 0, 0.1);
  border-top-left-radius: 40px;
  border-bottom-left-radius: 40px;
  .profile-img {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100px;
    height: 100px;
    border-radius: 50%;
    overflow: hidden;
    margin-bottom: 5px;
    flex-shrink: 0;
    border: 3px solid #c4c4c4;
    background: #eee;
    img {
      object-fit: cover;
      height: 100px;
      width: 100px;
    }
  }
  .info {
    p {
      margin-bottom: 0;
      text-align: center;
      color: #6b6b6b;
      font-size: 0.8rem;
      &:nth-of-type(1) {
        font-size: 1.1rem;
        font-weight: bold;
      }
    }
  }
  .btns {
    a {
      display: block;
      width: 100%;
      color: #6b6b6b;
      font-weight: bold;
      padding: 10px;
      text-align: center;
      cursor: pointer;
      &.active {
        text-decoration: underline;
      }
    }
    button {
      display: flex;
      align-items: center;
      justify-content: space-between;
      background: #fafafa;
      width: 100%;
      margin: 5px 0;
      text-transform: uppercase;
      color: #6b6b6b;
      padding-top: 10px;
      padding-bottom: 10px;
      transition: all 0.3s ease;

      &:hover {
        font-weight: bold;
        background: #ffffff;
      }
      &.active {
        background: #ffffff;
        box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.12);
        font-weight: bold;
      }
    }
  }
}

.target {
  content: "";
  display: block;
  width: 16px;
  height: 16px;
  border: 5px solid #b1b1b1;
  margin-left: 40px;
  border-radius: 50%;
  transition: all 0.3s ease;
  &.active {
    border: 5px solid;
  }
}

@media (max-width: 991.98px) {
  .user-profile {
    .btns {
      button {
        font-size: 0.8rem;
        &::after {
          margin-left: 10px;
        }
      }
    }
  }
}
</style>
