var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"d-flex flex-wrap pt-4"},[(_vm.answer.answer_object.files)?_c('div',_vm._l((_vm.answer.answer_object.files),function(video,index){return _c('div',{key:index,staticClass:"pb-3"},[_c('div',[_c('video',{attrs:{"controlsList":_vm.$store.getters.info.user.type !== _vm.userTypesText.admin &&
              _vm.$store.getters.info.user.type !== _vm.userTypesText.moderator
                ? 'nodownload'
                : '',"src":video.url,"controls":""}}),_c('p',{staticClass:"mt-1"},[_vm._v(" "+_vm._s(video.description)+" ")])])])}),0):_vm._l((_vm.answer.answer_object.videos),function(video,index){return _c('div',{key:index},[_c('div',{staticClass:"pb-3 mr-3"},[_c('video',{attrs:{"controlsList":_vm.$store.getters.info.user.type !== _vm.userTypesText.admin &&
            _vm.$store.getters.info.user.type !== _vm.userTypesText.moderator
              ? 'nodownload'
              : '',"src":video.URL,"controls":""}})])])})],2),(
      _vm.answer.answer_object &&
        _vm.answer.answer_object.comment &&
        _vm.answer.answer_object.comment.length
    )?_c('p',[_c('span',{staticClass:"comments",style:({ color: _vm.$store.getters.theme[3] })},[_vm._v(" "+_vm._s(_vm.translation.answer_task.comments)+" ")]),_vm._v(" "+_vm._s(_vm.answer.answer_object.comment)+" ")]):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }