<template>
  <div class="dropdown-wrapper">
    <div class="outside" v-if="opened"></div>
    <div class="dropdown" id="media">
      <a
        class="btn btn-uploader"
        :style="{ 'background-color': $store.getters.theme[2] }"
        @click="handleDrop('media')"
      >
        {{ translation.uploader.add_media }}
        <b-icon
          class="m-0 pl-2"
          icon="caret-down-fill"
          aria-hidden="true"
        ></b-icon
      ></a>
      <div class="drop-item-wrapper">
        <div class="drop-item">
          <a
            v-for="(option, index) in optionsArray"
            :key="index"
            @click="openModal(option)"
            >{{ option.text }}</a
          >
        </div>
      </div>
      <b-modal
        no-close-on-backdrop
        @close="cancel(option.value)"
        @cancel="cancel(option.value)"
        @ok.prevent="upload(option.value)"
        id="modalUploader"
        :title="option.text"
        :cancel-title="translation.global.cancel"
      >
        <dropzone-image
          v-if="option.value === options.image.value"
          @files="files => (images = files)"
          @processingFiles="files => (processingImages = files)"
        ></dropzone-image>
        <dropzone-video
          v-if="!image && option.value === options.video.value"
          @files="files => (videos = files)"
          @processingFiles="files => (processingVideos = files)"
        ></dropzone-video>
        <dropzone-attachment
          v-if="!image && option.value === options.attachment.value"
          @files="files => (attachments = files)"
          @processingFiles="files => (processingAttachments = files)"
        ></dropzone-attachment>
      </b-modal>
      <b-modal
        @ok="closeModal"
        id="warnUploaderModal"
        :title="option.text"
        :cancel-title="translation.global.cancel"
      >
        {{ translation.uploader.warn_uploader_modal }}
      </b-modal>
    </div>
  </div>
</template>

<script>
import { api } from "@/services.js";
import dropzoneImage from "@/components/general/dropzoneImage.vue";
import dropzoneVideo from "@/components/general/dropzoneVideo.vue";
import dropzoneAttachment from "@/components/general/dropzoneAttachment.vue";
import { outsideClick } from "@/helpers.js";
import { mapState } from "vuex";

export default {
  props: {
    image: {}
  },
  components: {
    dropzoneImage,
    dropzoneVideo,
    dropzoneAttachment
  },
  data() {
    return {
      img: null,
      option: null,
      opened: false,
      images: [],
      videos: [],
      attachments: [],
      processingImages: [],
      processingVideos: [],
      processingAttachments: [],
      outsideClick
    };
  },
  computed: {
    ...mapState(["attachmentUploaderFolder"]),
    optionsArray() {
      return Object.values(this.options);
    },
    options() {
      if (this.image)
        return {
          image: {
            value: 1,
            text: this.translation.task.image_type
          }
        };
      else
        return {
          image: {
            value: 1,
            text: this.translation.task.image_type
          },
          video: {
            value: 2,
            text: this.translation.task.video_type
          },
          attachment: {
            value: 3,
            text: this.translation.task.attachment_type
          }
        };
    }
  },
  methods: {
    cancel(option) {
      if (!this.image && option === this.options.attachment.value) {
        this.attachments.forEach(file => {
          api.post(
            "s3",
            {
              item: `attachments/${this.attachmentUploaderFolder}/${file}`
            },
            {
              headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${this.$store.getters.token}`
              }
            }
          );
        });
      }
    },
    closeModal() {
      this.images = [];
      this.videos = [];
      this.attachments = [];
      this.$store.commit("UPDATE_IMG", null);
      this.$store.commit("UPDATE_VIDEO", null);
      this.$store.commit("UPDATE_ATTACHMENT", null);
      this.$bvModal.hide("modalUploader");
    },
    openModal(option) {
      this.option = option;
      this.$bvModal.show("modalUploader");
    },
    upload(option) {
      if (option === this.options.image.value) {
        if (!this.processingImages.length) {
          this.$store.commit("UPDATE_IMG", this.images[0]);
          this.$nextTick(() => {
            this.$bvModal.hide("modalUploader");
          });
        } else {
          this.$bvModal.show("warnUploaderModal");
        }
      } else if (option === this.options.video.value) {
        if (!this.processingVideos.length) {
          this.$store.commit("UPDATE_VIDEO", this.videos[0]);
          this.$nextTick(() => {
            this.$bvModal.hide("modalUploader");
          });
        } else {
          this.$bvModal.show("warnUploaderModal");
        }
      } else if (option === this.options.attachment.value) {
        if (!this.processingAttachments.length) {
          this.$store.commit("UPDATE_ATTACHMENT", this.attachments);
          this.$nextTick(() => {
            this.$bvModal.hide("modalUploader");
          });
        } else {
          this.$bvModal.show("warnUploaderModal");
        }
      }
    },
    handleDrop(id) {
      const dropItem = document.querySelector(`#${id}`);
      dropItem.children[1].classList.toggle("active");
      this.opened = !this.opened;
      this.outsideClick(dropItem, ["click"], () => {
        dropItem.children[1].classList.remove("active");
        this.opened = false;
      });
    }
  },
  created() {
    this.option = this.options.image;
  }
};
</script>

<style lang="scss" scoped>
.dropdown {
  width: 120px;
  &:hover {
    .drop-item-wrapper {
      display: block;
    }
  }
}

.btn-uploader {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 120px;
  height: 30px;
  border-radius: 17px;
  text-transform: uppercase;
  font-weight: bold;
  color: #fff;
  font-size: 0.65rem;
  white-space: nowrap;
  svg {
    font-size: 1.2rem !important ;
  }
}

.outside {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  z-index: 2;
}

.drop-item-wrapper {
  position: absolute;
  display: none;
  padding-top: 10px;
  z-index: 5;
  top: 30px;
  right: 0;
  &.active {
    display: block;
  }
}

.drop-item {
  animation: grow 400ms ease-in-out forwards;
  transform-origin: top center;
  background: #fff;
  border-radius: 4px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
  overflow: hidden;
  width: 120px;
  a {
    background: #fff;
    display: block;
    color: #495057;
    padding: 2px 10px;
    text-decoration: none;
    cursor: pointer;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    &:hover,
    &.active {
      color: #666;
      background: rgba(0, 0, 0, 0.1);
    }
  }
}
</style>
