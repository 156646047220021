<template>
  <div class="user-list ml-md-4 ml-2">
    <div class="input">
      <b-form-input
        size="sm"
        v-model="search"
        :placeholder="translation.global.search"
      ></b-form-input>
      <b-icon class="mx-2" icon="search" aria-hidden="true"></b-icon>
    </div>
    <div class="users" v-if="!loaderUsers && usersSearch.length">
      <div class="users-scroll">
        <button
          v-for="user in usersSearch"
          :key="user.id"
          @click="chatSelectedUser = user"
        >
          <div class="profile-img-wrapper">
            <div class="notification" v-if="getNotification(user.id)">
              {{ getNotification(user.id) }}
            </div>
            <div
              class="profile-img"
              :style="{
                color: $store.getters.theme[3]
              }"
            >
              <img
                v-if="user.picture && user.picture.length"
                :src="user.picture"
                alt="Profile Picture"
              />
              <b-icon
                v-else
                class="icon-profile"
                icon="person-fill"
                aria-hidden="true"
              ></b-icon>
            </div>
          </div>
          <div>
            <p>
              <span
                v-if="
                  $store.getters.info.user.type === userTypesText.moderator &&
                    !user.operator_type_id
                "
              >
                {{
                  namePrivacy(
                    privacy.moderators.participants_identifier_type_id,
                    user.name,
                    user.id
                  )
                }}
              </span>
              <span
                v-else-if="
                  $store.getters.info.user.type === userTypesText.observer &&
                    !user.operator_type_id
                "
              >
                {{
                  namePrivacy(
                    privacy.observers.participants_identifier_type_id,
                    user.name,
                    user.id
                  )
                }}
              </span>
              <span v-else>
                {{ user.name }}
              </span>
            </p>
            <p>ID {{ user.id }}</p>
          </div>
        </button>
      </div>
    </div>
    <div v-else-if="loaderUsers" class="py-5 d-flex justify-content-center">
      <loader2 :color="$store.getters.theme[2]" />
    </div>
    <div v-else class="text py-5 d-flex justify-content-center">
      {{ translation.chat.no_users }}
    </div>
  </div>
</template>

<script>
import { api } from "@/services.js";
import loader2 from "@/components/general/loader2.vue";
import { userTypes, userTypesText } from "@/constants.js";
import { mapState } from "vuex";
import { namePrivacy } from "@/helpers.js";

export default {
  props: {
    userType: {
      required: true
    }
  },

  components: {
    loader2
  },

  data() {
    return {
      chatSelectedUser: null,
      search: "",
      users: [],
      loaderUsers: false,
      userTypes,
      userTypesText
    };
  },

  computed: {
    ...mapState(["chatNotifications", "privacy"]),

    usersSearch() {
      if (this.search.length) {
        return this.users.filter(item => {
          return this.search
            .toLowerCase()
            .split(" ")
            .every(v => item.name.toLowerCase().includes(v));
        });
      } else {
        return this.users;
      }
    }
  },

  watch: {
    userType() {
      this.getUsers();
    },

    chatSelectedUser() {
      this.$store.commit("UPDATE_CHAT_SELECTED_USER", this.chatSelectedUser);
    }
  },

  methods: {
    namePrivacy,

    getNotification(id) {
      return this.chatNotifications.filter(
        notification =>
          (notification.message && notification.message.sourceID === id) ||
          notification.sourceID === id
      ).length;
    },

    getUsers() {
      this.loaderUsers = true;
      api
        .get(
          `community/${this.$store.getters.community.id}/users?type[]=${this.userType}`,
          {
            headers: {
              Authorization: `Bearer ${this.$store.getters.token}`
            }
          }
        )
        .then(r => {
          if (r.status === 200) {
            const notModerator = this.userType !== this.userTypes.moderator;
            if (notModerator) {
              this.users = r.data.filter(
                user => user.id !== this.$store.getters.info.user.sub
              );
            } else {
              this.users = r.data.filter(
                user =>
                  user.id !== this.$store.getters.info.user.sub &&
                  user.operator_type !== this.userTypesText.observer
              );
            }

            const participantID = this.$route.query._participant;
            if (participantID && this.userType === this.userTypes.participant) {
              const user = this.users.filter(
                user => user.id === participantID
              )[0];
              this.chatSelectedUser = user;
              this.$router.replace({ name: "chat" });
            }
          }
          this.loaderUsers = false;
        })
        .catch(() => {
          this.loaderUsers = false;
        });
    }
  },

  created() {
    this.getUsers();
  }
};
</script>

<style lang="scss" scoped>
.user-list {
  height: calc(100vh - 200px);
  position: relative;
  width: 100%;

  .users {
    width: 100%;
    height: 100%;
    position: relative;
  }
}

.input {
  width: 100%;
  display: flex;
  align-items: center;
  position: relative;
  margin-bottom: 20px;
  input {
    border-radius: 20px;
    color: #6b6b6b;
  }
  svg {
    position: absolute;
    right: 0;
    color: #6b6b6b;
  }
}

.users-scroll {
  width: 100%;
  height: 100%;
  overflow-y: scroll;
  padding-right: 10px;
  &::-webkit-scrollbar {
    width: 8px;
  }
  &::-webkit-scrollbar-track {
    background: rgba(0, 0, 0, 0.05);
    border-radius: 10px;
  }
  &::-webkit-scrollbar-thumb {
    background: #e6e6e6;
    border-radius: 10px;
    height: 40px;
  }
  &::-webkit-scrollbar-thumb:hover {
    background: rgba(0, 0, 0, 0.1);
  }
  button {
    display: flex;
    align-items: center;
    width: 100%;
    background: #ffffff;
    margin: 5px 0;
    border: none;
    padding: 10px 15px;
    text-align: left;
    &:focus {
      outline: none;
    }
    .profile-img-wrapper {
      position: relative;
      .notification {
        display: flex;
        align-items: center;
        justify-content: center;
        color: #ffffff;
        font-weight: bold;
        position: absolute;
        background: #df2938;
        top: -4px;
        right: 8px;
        width: 18px;
        height: 18px;
        border-radius: 50%;
        font-size: 0.8rem;
      }
      .profile-img {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 60px;
        height: 60px;
        border-radius: 50%;
        overflow: hidden;
        flex-shrink: 0;
        margin-right: 10px;
        background: #eee;
        img {
          object-fit: cover;
          height: 60px;
          width: 60px;
        }
      }
    }
    p {
      margin: 0;
      color: #6b6b6b;
      font-size: 0.9rem;
      &:nth-of-type(1) {
        font-weight: bold;
        font-size: 1rem;
      }
    }
  }
}

@media (max-width: 991.98px) {
  .user-list {
    max-width: 180px;
  }

  .users-scroll {
    button {
      .profile-img-wrapper {
        position: relative;

        .profile-img {
          width: 40px;
          height: 40px;
          img {
            object-fit: cover;
            height: 40px;
            width: 40px;
          }
        }
      }
    }
  }
}
</style>
