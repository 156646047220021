<template>
  <div class="transmissions">
    <h3 :style="{ color: $store.getters.theme[3] }">
      {{ translation.chat.transmission_list }}
    </h3>
    <div class="list">
      <p>{{ translation.chat.my_lists }}:</p>
      <div
        v-if="!loaderTransmissionsList && transmissionsList.length"
        class="scroll"
      >
        <button
          :class="{
            active:
              activeTransmissionListId &&
              activeTransmissionListId === transmissionList.id
          }"
          @click="selectList(transmissionList)"
          class="transmission-button"
          v-for="transmissionList in transmissionsList"
          :key="transmissionList.id"
        >
          {{ transmissionList.name }}
        </button>

        <a
          @click="$store.commit('UPDATE_SHOW_CREATE_TRANSMISSION', true)"
          class="add-button d-md-none"
          >{{ translation.global.add }}</a
        >
      </div>
      <div
        v-if="!transmissionsList.length && !loaderTransmissionsList"
        class="text"
      >
        {{ translation.chat.no_users }}
      </div>
      <div
        v-if="loaderTransmissionsList"
        class="py-5 d-flex justify-content-center"
      >
        <loader2 :color="$store.getters.theme[2]" />
      </div>
    </div>

    <div class="segments" v-if="!loaderSegments">
      <p>{{ translation.chat.select }}:</p>
      <div class="scroll">
        <b-form-checkbox id="allSegments" v-model="allSegments">
          {{ translation.replies.all }}
        </b-form-checkbox>
        <b-form-group v-slot="{ ariaDescribedby }">
          <b-form-checkbox-group
            v-model="selectedSegments"
            :options="segments"
            :aria-describedby="ariaDescribedby"
            stacked
          ></b-form-checkbox-group>
        </b-form-group>
      </div>
    </div>
    <div v-else class="py-5 d-flex justify-content-center">
      <loader2 :color="$store.getters.theme[2]" />
    </div>
  </div>
</template>

<script>
import { api } from "@/services.js";
import axios from "axios";
import loader2 from "@/components/general/loader2.vue";
import { userTypes } from "@/constants";
import { mapState } from "vuex";

export default {
  components: {
    loader2
  },
  data() {
    return {
      transmissionsList: [],
      selectedSegments: [],
      allSegments: false,
      segments: [],
      loaderSegments: false,
      loaderTransmissionsList: false,
      activeTransmissionList: null,
      userTypes
    };
  },
  computed: {
    ...mapState(["triggerGetTransmissionsList", "activeTransmissionListId"])
  },
  watch: {
    activeTransmissionListId() {
      if (this.activeTransmissionListId)
        this.activeTransmissionList = this.transmissionsList.filter(
          list => list.id === this.activeTransmissionListId
        )[0];
    },
    triggerGetTransmissionsList() {
      this.getTransmissionsList();
    },
    selectedSegments() {
      const isAllChecked =
        this.selectedSegments.length === this.segments.length;
      if (!isAllChecked) {
        this.allSegments = false;
      }

      this.$store.commit(
        "UPDATE_CHAT_SELECTED_SEGMENTS",
        this.selectedSegments
      );
    },
    allSegments() {
      const isAllChecked =
        this.selectedSegments.length === this.segments.length;
      if (this.allSegments) {
        this.selectedSegments = this.segments.map(option => option.value);
      } else if (isAllChecked) {
        this.selectedSegments = [];
      }
    }
  },
  methods: {
    selectList(list) {
      this.$store.commit("UPDATE_ACTIVE_TRANSMISSION_LIST_ID", list.id);
    },
    getSegments() {
      this.loaderSegments = true;
      api
        .get(`community/${this.$store.getters.community.id}/segments`, {
          headers: {
            Authorization: `Bearer ${this.$store.getters.token}`
          }
        })
        .then(r => {
          if (r.status === 200) {
            this.segments = r.data
              .filter(segment => +segment.is_active)
              .map(segment => ({ value: segment.id, text: segment.name }));
          }
          this.loaderSegments = false;
        })
        .catch(() => {
          this.loaderSegments = false;
        });
    },
    getTransmissionsList() {
      this.loaderTransmissionsList = true;

      const userType = this.$store.getters.info.user.type.toLowerCase();

      this.userSource = {
        id: this.$store.getters.info.user.sub,
        type: this.userTypes[userType]
      };

      axios(
        `${this.chatHost}get-transmissions-list?communityId=${this.$store.getters.community.id}&userId=${this.userSource.id}&userType=${this.userSource.type}`
      )
        .then(r => {
          if (r.status === 200) {
            this.transmissionsList = r.data;
          }
          this.loaderTransmissionsList = false;
        })
        .catch(() => {
          this.loaderTransmissionsList = false;
        });
    }
  },
  created() {
    this.getTransmissionsList();
    this.getSegments();
  }
};
</script>

<style lang="scss" scoped>
.transmissions {
  height: calc(100vh - 150px);
  min-height: 550px;
  width: 100%;
  background: #fff;
  padding: 20px 0px 20px 25px;
  h3 {
    font-weight: bold;
    font-size: 1.1rem;
  }
  p {
    font-weight: bold;
    color: #6b6b6b;
  }

  > div {
    padding: 10px 10px 10px 0;
  }

  .list {
    border-bottom: 1px solid #e5e5e5;
    .scroll {
      height: 100px;
      overflow-y: scroll;
    }
  }

  .segments {
    .scroll {
      height: calc(100vh - 430px);
      overflow-y: scroll;
      padding: 5px;
    }
  }
  .scroll {
    padding: 0 10px 0 0;
    &::-webkit-scrollbar {
      width: 8px;
    }
    &::-webkit-scrollbar-track {
      background: rgba(0, 0, 0, 0);
      border-radius: 10px;
    }
    &::-webkit-scrollbar-thumb {
      background: rgba(0, 0, 0, 0.1);
      border-radius: 10px;
      height: 40px;
    }
    &::-webkit-scrollbar-thumb:hover {
      background: rgba(0, 0, 0, 0.2);
    }
  }

  .transmission-button {
    display: block;
    width: 100%;
    margin-bottom: 5px;
    background: #fafafa;
    border: none;
    color: #6b6b6b;
    text-align: left;
    &:focus {
      outline: none;
    }
    &.active {
      color: #0087bf;
      background: #fff;
      font-weight: bold;
    }
  }
}

@media (max-width: 991.98px) {
  .transmissions {
    padding: 20px 0px 20px 10px;
    min-width: 180px;
    border-top-right-radius: 40px;
    border-bottom-right-radius: 40px;
  }
}

.text {
  color: #6b6b6b;
}

.add-button {
  font-weight: bold;
  color: #0087bf;
}
</style>
