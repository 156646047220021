<template>
  <div class="pt-5">
    <form>
      <label class="mb-3" for="nameOperator">
        {{ translation.operators.add_operators.name_operator }}:</label
      >
      <b-form-input
        size="md"
        id="nameOperator"
        v-model="name"
        :placeholder="translation.operators.add_operators.placeholder_operator"
        :state="!hasSpecialCharacters && !!name.length"
        v-b-tooltip.hover="{ variant: 'secondary' }"
        :title="translation.operators.name_validation"
      ></b-form-input>

      <label class="mb-3 mt-5" for="emailOperator"> E-mail:</label>
      <b-form-input
        size="md"
        id="emailOperator"
        v-model="email"
        :placeholder="translation.operators.add_operators.placeholder_email"
      ></b-form-input>

      <label class="mb-3 mt-5">
        {{ translation.operators.add_operators.type_access }}:</label
      >
      <div class="row col-sm-12 col-lg-9 m-0 input-radio">
        <div
          class="col-sm-8 col-lg-4"
          v-if="
            $store.getters.info.user.type === userTypesText.admin &&
              $store.getters.info.user.is_root === isRoot
          "
        >
          <b-form-radio
            v-model="typeAccess"
            name="order-radios"
            id="admin"
            value="admin"
            >{{ translation.operators.add_operators.admin }}</b-form-radio
          >
          <label for="admin">
            <span
              :style="{ color: $store.getters.theme[2] }"
              class="label-attention"
              >{{
                translation.operators.add_operators.admin_text.attention_label
              }}</span
            >
            {{
              translation.operators.add_operators.admin_text.admin_text
            }}</label
          >
        </div>
        <div class="col-sm-8 col-lg-4">
          <b-form-radio
            v-model="typeAccess"
            name="order-radios"
            id="observer"
            value="2"
            >{{ translation.operators.add_operators.observer }}</b-form-radio
          >
        </div>
        <div class="col-sm-8 col-lg-4">
          <b-form-radio
            v-model="typeAccess"
            name="order-radios"
            id="moderator"
            value="1"
            >{{ translation.operators.add_operators.moderator }}</b-form-radio
          >
        </div>
      </div>
    </form>
    <div class="row mt-3 justify-content-end align-btn">
      <b-button
        @click="typeAccess !== 'admin' ? create() : createAdmin()"
        class="create-access text-white hover"
        :style="{
          background: $store.getters.theme[2],
          'border-color': $store.getters.theme[2]
        }"
      >
        <div v-if="loaderCreate" class="d-flex justify-content-center">
          <loader2 color="#fff" />
        </div>
        <p v-else class="m-0">{{ translation.operators.create_access }}</p>
      </b-button>
    </div>
  </div>
</template>

<script>
import { api } from "@/services.js";
import loader2 from "@/components/general/loader2.vue";
import { userTypesText, isRoot } from "@/constants";

export default {
  components: {
    loader2
  },
  data() {
    return {
      name: "",
      email: "",
      typeAccess: "",
      loaderCreate: false,
      isRoot,
      userTypesText,
      regexpNameValidation: /[()!&$@=;:’“+,?\\{\\}%`\]'">[~>#|]/g
    };
  },
  computed: {
    hasSpecialCharacters() {
      return this.name.match(this.regexpNameValidation);
    }
  },
  methods: {
    create() {
      if (this.name.length && this.email.length && this.typeAccess.length) {
        if (!this.hasSpecialCharacters) {
          this.loaderCreate = true;
          api
            .post(
              "admin/manage/operator/create",
              {
                name: this.name,
                email: this.email,
                operator_type: +this.typeAccess,
                community_id: this.$store.getters.community.id
              },
              {
                headers: {
                  "Content-Type": "application/json",
                  Authorization: `Bearer ${this.$store.getters.token}`
                }
              }
            )
            .then(r => {
              if (r.status === 200 || r.status === 201) {
                this.name = "";
                this.email = "";
                this.typeAccess = "";
                this.loaderCreate = false;
                this.$toast.success(
                  this.translation.operators.operator_created
                );
              }
            })
            .catch(error => {
              this.loaderCreate = false;
              if (error.response.status === 409) {
                this.$toast.error(this.translation.operators.email_already);
              } else {
                this.$toast.error(
                  this.translation.errors_global.something_went_wrong
                );
              }
            });
        } else {
          this.$toast.error(
            this.$toast.error(this.translation.operators.invalid_name)
          );
        }
      } else this.$toast.error(this.translation.errors_global.fill_fields);
    },
    createAdmin() {
      if (this.name.length && this.email.length && this.typeAccess.length) {
        if (!this.hasSpecialCharacters) {
          this.loaderCreate = true;
          api
            .post(
              "admin/create/common-admin",
              {
                name: this.name,
                email: this.email,
                profile_picture: ""
              },
              {
                headers: {
                  "Content-Type": "application/json",
                  Authorization: `Bearer ${this.$store.getters.token}`
                }
              }
            )
            .then(resp => {
              if (resp.status === 200 || resp.status === 201) {
                this.name = "";
                this.email = "";
                this.typeAccess = "";

                this.loaderCreate = false;
                this.$toast.success(
                  this.translation.operators.operator_created
                );
              }
            })
            .catch(error => {
              this.loaderCreate = false;
              if (error.response.status === 409) {
                this.$toast.error(this.translation.operators.email_already);
              } else {
                this.$toast.error(
                  this.translation.errors_global.something_went_wrong
                );
              }
            });
        } else {
          this.$toast.error(
            this.$toast.error(this.translation.operators.invalid_name)
          );
        }
      } else this.$toast.error(this.translation.errors_global.fill_fields);
    }
  }
};
</script>

<style lang="scss" scoped>
form {
  label {
    display: block;
    color: #6b6b6b;
    font-weight: bold;
    font-size: 1.1rem;
  }
  input {
    border: 1px solid #e6e6e6;
    border-radius: 10px;
    color: #6b6b6b;
    max-width: 380px;
    &::-webkit-input-placeholder {
      color: #999;
    }
    &::-moz-placeholder {
      color: #999;
    }
  }
  .input-radio {
    > div {
      margin-bottom: 20px;
      label {
        font-size: 0.75rem;
        font-weight: normal;
        line-height: 16px;
        color: #6b6b6b;
        padding: 8px 0 0 0;
        margin: 0;
      }
    }
  }
}
.create-access {
  letter-spacing: 0.12em;
  text-transform: uppercase;
  font-size: 0.75rem;
  font-weight: bold;
  color: #6b6b6b;
  border-radius: 30px;
  width: 170px;
  height: 36px;
  white-space: nowrap;
}
.label-attention {
  font-weight: bold;
}
@media (max-width: 991.98px) {
  .create-access {
    font-size: 0.6rem;
    white-space: unset;
  }
  .input-radio {
    > div {
      label {
        text-align: center;
      }
    }
  }
  .align-btn {
    justify-content: center !important;
    margin-top: 25px !important;
  }
}
</style>
