<template>
  <div :class="{ active: showActivities }" class="sub-header">
    <button @click="show = !show" :style="{ color: $store.getters.theme[2] }">
      {{ translation.header.assignments_text }}
    </button>
    <div :class="{ active: show }" class="links">
      <router-link :to="{ name: 'activities', query: { _page: 1, _q: '' } }">
        {{ translation.header.see_assignments }}
      </router-link>
      <router-link
        v-if="
          $store.getters.info.user.type === userTypesText.admin ||
            ($store.getters.info.user.type === userTypesText.moderator &&
              !+permissions.moderators.hide_create_activity)
        "
        :to="{ name: 'createactivity', query: { a: 'new' } }"
      >
        {{ translation.header.add_assignment }}
      </router-link>
    </div>
  </div>
</template>

<script>
import { userTypesText } from "@/constants";
import { mapState } from "vuex";

export default {
  props: {
    showActivities: {
      required: true
    }
  },

  data() {
    return {
      show: true,
      userTypesText
    };
  },

  computed: {
    ...mapState(["permissions"])
  }
};
</script>

<style lang="scss" scoped>
.sub-header {
  display: none;
  animation: show 0.3s linear;
  background: linear-gradient(180deg, #f1f8f8 0%, rgba(235, 242, 242, 0) 100%);
  button {
    display: block;
    width: 100%;
    background: none;
    border: none;
    padding: 10px;
    font-weight: bold;
    font-size: 1rem;
    border-bottom: 1px solid #e5e5e5;
    text-decoration: none;
    text-align: left;
    &:focus {
      outline: none;
    }
  }
  a {
    display: block;
    text-transform: capitalize;
    font-weight: normal;
    color: #999 !important;
    padding: 10px;
    font-size: 1rem;
    border-bottom: 1px solid #e5e5e5;
    text-decoration: none;
  }
  &.active {
    display: block;
  }
}

.links {
  display: none;
  animation: show 0.3s linear;
  &.active {
    display: block;
  }
}
</style>
