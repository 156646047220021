var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"container pt-5"},[(
      _vm.$store.getters.is_over &&
        (_vm.$store.getters.info.user.type === _vm.userTypesText.moderator ||
          _vm.$store.getters.info.user.type === _vm.userTypesText.admin)
    )?_c('div',{staticClass:"row mb-4"},[_c('div',{staticClass:"col"},[_c('div',{staticClass:"card-b p-3 text-danger"},[_vm._v(" "+_vm._s(_vm.translation.feed.expired_community)+" ")])])]):_vm._e(),(_vm.$store.getters.info.user.type === _vm.userTypesText.admin)?_c('a',{staticClass:"back",on:{"click":_vm.goToCommunities}},[_c('b-icon',{staticClass:"mr-1",attrs:{"icon":"arrow-left-circle"}}),_vm._v(" "+_vm._s(_vm.translation.communities.communities)+" ")],1):_vm._e(),_c('div',{staticClass:"row pb-5 pt-2"},[_c('div',{staticClass:"p-0",class:{
        'col-lg-8':
          _vm.$store.getters.info.user.type === _vm.userTypesText.participant,
        col: _vm.$store.getters.info.user.type !== _vm.userTypesText.participant
      }},[_c('div',{staticClass:"card-b img-feed mb-4",class:{
          participant:
            _vm.$store.getters.info.user.type === _vm.userTypesText.participant
        }},[(
            _vm.$store.getters.community.banner &&
              _vm.$store.getters.community.banner.length
          )?_c('img',{attrs:{"src":_vm.$store.getters.community.banner,"alt":"Banner"}}):_vm._e(),_c('div',{staticClass:"desc"},[_c('div',{staticClass:"box",style:({
              'background-color': _vm.$store.getters.theme[2],
              opacity: 0.78
            })}),_c('div',{staticClass:"text"},[_c('p',[_vm._v(_vm._s(_vm.$store.getters.community.description))])])])]),_c('activities',{attrs:{"activities":_vm.filterActivities,"openNewActivity":_vm.openNewActivity,"feed":true,"getActivities":_vm.getActivities,"loader":_vm.loader},on:{"edit":function (id) { return _vm.handleEdit(id); },"task":function (id) { return _vm.handleTask(id); }}})],1),(_vm.$store.getters.info.user.type === _vm.userTypesText.participant)?_c('div',{staticClass:"col-lg-4"},[_c('next-activity',{attrs:{"loader2":_vm.loader,"activity":_vm.nextActivity,"activities":_vm.activities}}),_c('guide')],1):_vm._e()])])}
var staticRenderFns = []

export { render, staticRenderFns }