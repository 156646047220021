<template>
  <div class="row">
    <div class="col">
      <search class="mt-5" />

      <div
        v-if="
          !loaderParticipants && !loaderSegments && participantsPages.length
        "
      >
        <div :class="{ 'is-editable': isEditable }" class="table-wrapper">
          <div class="table mt-4">
            <div
              :style="{ color: $store.getters.theme[3] }"
              class="table-header"
            >
              <div>Voices ID</div>
              <div class="md">
                {{ translation.manage_participants.external_id }}
              </div>
              <div class="md">{{ translation.manage_participants.name }}</div>
              <div
                class="lg"
                v-if="
                  $store.getters.info.user.type === userTypesText.admin ||
                    ($store.getters.info.user.type ===
                      userTypesText.moderator &&
                      !+privacy.moderators.participants_hide_email)
                "
              >
                {{ translation.manage_participants.email }}
              </div>
              <div class="md" v-for="segment in segments" :key="segment.id">
                {{ segment.name }}
              </div>
            </div>
            <div
              v-for="participant in participantsPages"
              :key="participant.id"
              :class="{
                disable: participant.is_active === isActiveCode.notActive,
                'is-editable': isEditable,
                first: firstParticipant && participant.id === firstParticipant
              }"
              class="table-content mt-2 p-1"
            >
              <div>{{ participant.id }}</div>

              <div class="md">
                <div v-if="participant.isEditable">
                  <b-form-input
                    size="sm"
                    v-model="participant.external_id"
                  ></b-form-input>
                </div>
                <p v-else class="m-0">{{ participant.external_id }}</p>
              </div>

              <div class="md">
                <div v-if="participant.isEditable">
                  <b-form-input
                    class="mx-1"
                    size="sm"
                    v-model="participant.name"
                  ></b-form-input>
                </div>
                <p
                  v-else
                  class="m-0"
                  :title="getNameTitle(participant)"
                  v-b-tooltip.hover="{ variant: 'secondary' }"
                >
                  <span
                    v-if="
                      $store.getters.info.user.type === userTypesText.moderator
                    "
                  >
                    {{
                      namePrivacy(
                        privacy.moderators.participants_identifier_type_id,
                        participant.name,
                        participant.id
                      ) | maxLength
                    }}
                  </span>
                  <span
                    v-else-if="
                      $store.getters.info.user.type === userTypesText.observer
                    "
                  >
                    {{
                      namePrivacy(
                        privacy.observers.participants_identifier_type_id,
                        participant.name,
                        participant.id
                      ) | maxLength
                    }}
                  </span>
                  <span v-else>
                    {{ participant.name | maxLength }}
                  </span>
                </p>
              </div>

              <div
                class="lg"
                v-if="
                  $store.getters.info.user.type === userTypesText.admin ||
                    ($store.getters.info.user.type ===
                      userTypesText.moderator &&
                      !+privacy.moderators.participants_hide_email) ||
                    ($store.getters.info.user.type === userTypesText.observer &&
                      !+privacy.observers.participants_hide_email)
                "
              >
                <div v-if="participant.isEditable">
                  <b-form-input
                    class="mx-1"
                    size="sm"
                    v-model="participant.email"
                  ></b-form-input>
                </div>
                <p
                  v-else
                  class="m-0"
                  :title="participant.email"
                  v-b-tooltip.hover="{ variant: 'secondary' }"
                >
                  {{ participant.email | maxLength }}
                </p>
              </div>

              <div
                class="md"
                v-for="segment in getParticipantSegments(participant.segment)"
                :key="segment.id"
              >
                <div v-if="participant.isEditable">
                  <b-form-input
                    type="number"
                    class="mx-1"
                    size="sm"
                    max="1"
                    min="0"
                    v-model="segment.value"
                  ></b-form-input>
                </div>
                <p v-else class="m-0">{{ segment.value }}</p>
              </div>

              <div class="right pr-3">
                <div
                  class="dropdown-actions"
                  :id="`dropdown-actions-participants-${participant.id}`"
                  v-if="$store.getters.info.user.type === userTypesText.admin"
                >
                  <button
                    :style="{ color: $store.getters.theme[2] }"
                    class="btn-actions hover"
                    @click="
                      handleDrop(
                        `dropdown-actions-participants-${participant.id}`
                      )
                    "
                  >
                    {{ translation.global.actions }}
                    <b-icon
                      class="m-0"
                      icon="caret-right-fill"
                      aria-hidden="true"
                    ></b-icon>
                  </button>
                  <div id="dropdown">
                    <a @click="openEditParticipant(participant)">
                      <b-icon class="mr-1" icon="pencil-fill"></b-icon>
                      {{ translation.global.edit }}
                    </a>
                    <a @click="toggleActivateParticipant(participant)">
                      <b-icon
                        v-if="participant.is_active === isActiveCode.active"
                        class="mr-1 circle"
                        icon="x"
                      ></b-icon>
                      <b-icon
                        v-else
                        class="mr-1 circle"
                        icon="play-fill"
                      ></b-icon>
                      {{
                        participant.is_active === isActiveCode.active
                          ? translation.global.disable
                          : translation.global.activate
                      }}
                    </a>
                  </div>
                </div>
                <div class="mt-1" v-if="participant.isEditable">
                  <button
                    @click="cancelEditParticipant(participant)"
                    class="btn btn-sm btn-red mx-1"
                  >
                    <b-icon icon="x"></b-icon>
                  </button>
                  <button
                    @click="editParticipant(participant)"
                    class="btn btn-sm btn-green mx-1"
                  >
                    <b-icon icon="check"></b-icon>
                  </button>
                </div>
              </div>
            </div>
          </div>
          <pagination-query
            v-if="!queryValue && minPerPage < total"
            :perPage="perPage"
            :total="total"
            :options="[10, 20, 50, 100]"
            @perPageUpdate="newPerPage => (perPage = newPerPage)"
          />
        </div>
      </div>
      <div
        class="d-flex justify-content-center py-5"
        v-else-if="loaderParticipants || loaderSegments"
      >
        <loader />
      </div>
      <div
        v-else-if="queryValue"
        class="result d-flex justify-content-center py-5"
      >
        {{ translation.global.sorry_we_have }}
        "{{ queryValue }}"
      </div>
      <div class="d-flex justify-content-center py-5" v-else>
        {{ translation.manage_participants.no_participants }}
      </div>
    </div>
  </div>
</template>

<script>
import { api } from "@/services.js";
import paginationQuery from "@/components/general/paginationQuery.vue";
import loader from "@/components/general/loader.vue";
import search from "@/components/general/search.vue";
import { userTypesText, isActiveCode } from "@/constants";
import { outsideClick, namePrivacy } from "@/helpers.js";
import { mapState } from "vuex";

export default {
  components: { paginationQuery, search, loader },

  data() {
    return {
      loaderParticipants: false,
      loaderSegments: false,
      segments: [],
      participantsPages: [],
      participants: [],
      perPage: 10,
      minPerPage: 10,
      total: 0,
      currentParticipantExternalId: {},
      currentParticipantName: {},
      currentParticipantEmail: {},
      currentParticipantSegment: {},
      outsideClick,
      userTypesText,
      isActiveCode
    };
  },

  filters: {
    maxLength(value) {
      if (value && value.length > 25) {
        return (
          value
            .split("")
            .splice(0, 25)
            .join("") + "..."
        );
      } else return value;
    }
  },

  computed: {
    ...mapState(["privacy"]),

    page() {
      return this.$route.query._page;
    },

    firstParticipant() {
      return this.$route.query._first;
    },

    queryValue() {
      if (this.$route.query._q && this.$route.query._q.length)
        return this.$route.query._q;
      else return 0;
    },

    isEditable() {
      return this.participantsPages.filter(
        participant => participant.isEditable
      ).length;
    }
  },

  watch: {
    queryValue() {
      this.getParticipantsPages();
    },

    page() {
      this.getParticipantsPages();
    },

    perPage() {
      this.getParticipantsPages();
    },

    participants() {
      this.getParticipantsPages();
    }
  },

  methods: {
    namePrivacy,

    getParticipantSegments(segments) {
      if (
        this.$store.getters.info.user.type === this.userTypesText.observer &&
        +this.privacy.observers.participants_hide_segments
      )
        return [];

      return segments;
    },

    openEditParticipant(participant) {
      if (!participant.isEditable) {
        this.currentParticipantExternalId[participant.id] =
          participant.external_id;
        this.currentParticipantName[participant.id] = participant.name;
        this.currentParticipantEmail[participant.id] = participant.email;
        this.currentParticipantSegment[participant.id] = participant.segment;
        participant.isEditable = true;
      } else {
        participant.isEditable = false;
      }
    },

    cancelEditParticipant(participant) {
      participant.external_id = this.currentParticipantExternalId[
        participant.id
      ];
      participant.name = this.currentParticipantName[participant.id];
      participant.email = this.currentParticipantEmail[participant.id];
      participant.segment = this.currentParticipantSegment[participant.id];
      participant.isEditable = false;
    },

    editParticipant(participant) {
      const segments = participant.segment
        .filter(segment => +segment.value === 1)
        .map(segment => segment.id);

      api
        .put(
          `participant/${participant.id}`,
          {
            external_id: participant.external_id,
            name: participant.name,
            email: participant.email,
            segments: segments
          },
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${this.$store.getters.token}`
            }
          }
        )
        .then(r => {
          if (r.status === 200) {
            this.$toast.success(this.translation.success_global.changes_saved);
            participant.isEditable = false;
          }
        })
        .catch(() => {
          participant.external_id = this.currentParticipantExternalId[
            participant.id
          ];
          participant.name = this.currentParticipantName[participant.id];
          participant.email = this.currentParticipantEmail[participant.id];
          participant.segment = this.currentParticipantSegment[participant.id];
          this.$toast.error(
            this.translation.errors_global.something_went_wrong
          );
        });
    },

    toggleActivateParticipant(participant) {
      api
        .put(
          `participant/${participant.id}`,
          {
            is_active:
              participant.is_active === this.isActiveCode.notActive ? 1 : 0
          },
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${this.$store.getters.token}`
            }
          }
        )
        .then(r => {
          if (r.status === 200) {
            this.$toast.success(this.translation.success_global.changes_saved);
            participant.is_active =
              participant.is_active === this.isActiveCode.notActive ? "1" : "0";
          }
        })
        .catch(() => {
          this.$toast.error(
            this.translation.errors_global.something_went_wrong
          );
        });
    },

    getParticipantsPages() {
      let array = [];

      if (this.queryValue) {
        array = this.participants.filter(item => {
          return this.queryValue
            .toLowerCase()
            .split(" ")
            .every(v => item.name.toLowerCase().includes(v));
        });
      } else {
        const start = this.perPage * (this.page - 1);
        this.participants.forEach((participant, index) => {
          if (index >= start && index < start + this.perPage)
            array.push(participant);
        });
      }

      this.participantsPages = array;
    },

    getParticipants() {
      this.loaderParticipants = true;
      api
        .get(`community/${this.$store.getters.community.id}/participants`, {
          headers: {
            Authorization: `Bearer ${this.$store.getters.token}`
          }
        })
        .then(r => {
          if (r.status === 200) {
            let participants = r.data;
            const firstParticipant = participants.filter(
              participant => participant.id == this.firstParticipant
            );

            if (firstParticipant.length) {
              participants = participants.filter(
                participant => participant.id !== this.firstParticipant
              );
              participants.unshift(firstParticipant[0]);
            }

            this.participants = participants.map(participant => ({
              ...participant,
              isEditable: false,
              segment: this.segments.map(segment => ({
                ...segment,
                value: participant.segment
                  .map(segment => segment.id)
                  .includes(segment.id)
                  ? 1
                  : 0
              }))
            }));

            if (firstParticipant.length) {
              this.openEditParticipant(this.participants[0]);
            }

            this.getParticipantsPages();
            this.total = this.participants.length;
          }
          this.loaderParticipants = false;
        })
        .catch(() => {
          this.loaderParticipants = false;
        });
    },

    getSegments() {
      this.loaderSegments = true;
      api
        .get(`community/${this.$store.getters.community.id}/segments`, {
          headers: {
            Authorization: `Bearer ${this.$store.getters.token}`
          }
        })
        .then(r => {
          if (r.status === 200) {
            if (
              this.$store.getters.info.user.type !==
                this.userTypesText.observer ||
              !+this.privacy.observers.participants_hide_segments
            )
              this.segments = r.data;
            this.getParticipants();
          }
          this.loaderSegments = false;
        })
        .catch(error => {
          if (error.response.status === 404) this.getParticipants();
          this.loaderSegments = false;
        });
    },

    handleDrop(drop) {
      const dropItem = document.querySelector(`#${drop}`);
      dropItem.children[1].classList.toggle("active");

      this.outsideClick(dropItem, ["click"], () => {
        dropItem.children[1].classList.remove("active");
      });
    },

    getNameTitle(participant) {
      if (this.$store.getters.info.user.type === this.userTypesText.moderator) {
        return this.namePrivacy(
          this.privacy.moderators.participants_identifier_type_id,
          participant.name,
          participant.id
        );
      } else if (
        this.$store.getters.info.user.type === this.userTypesText.observer
      ) {
        return namePrivacy(
          this.privacy.observers.participants_identifier_type_id,
          participant.name,
          participant.id
        );
      } else {
        return participant.name;
      }
    }
  },
  created() {
    this.getSegments();
  }
};
</script>

<style lang="scss" scoped>
.table-wrapper {
  overflow-x: auto;
  margin-right: 100px;
  &.is-editable {
    margin-right: 110px;
  }
  &::-webkit-scrollbar {
    height: 8px;
    overflow: hidden;
  }
  &::-webkit-scrollbar-track {
    background: rgba(0, 0, 0, 0);
    border-radius: 10px;
  }
  &::-webkit-scrollbar-thumb {
    background: #ccc;
    border-radius: 10px;
    height: 10px;
  }
  &::-webkit-scrollbar-thumb:hover {
    background: #aaa;
  }
}

.table {
  width: 100%;
}

.table-header {
  min-width: 100%;
  display: flex;
  width: fit-content;
  div {
    min-width: 80px;
    flex: 1;
    display: flex;
    justify-content: center;
    font-weight: bold;
    font-size: 0.9rem;
    &.md {
      min-width: 180px;
    }
    &.lg {
      min-width: 200px;
    }
  }
}

.table-content {
  min-width: 100%;
  width: fit-content;
  display: flex;
  align-items: center;
  border-top-left-radius: 50px;
  border-bottom-left-radius: 50px;
  border: 1px solid #999999;
  border-right: 0;
  height: 40px;
  > div {
    min-width: 80px;
    flex: 1;
    display: flex;
    justify-content: center;
    color: #6b6b6b;
    animation: show 0.3s linear;
    font-size: 0.9rem;
    &.md {
      min-width: 180px;
    }
    &.lg {
      min-width: 200px;
    }
    &.right {
      flex-direction: column;
      position: absolute;
      right: 45px;
      background: #fff;
      min-width: 80px;
      border: 1px solid #999999;
      border-top-right-radius: 50px;
      border-bottom-right-radius: 50px;
      border-left: 0;
      height: 40px;
    }
  }
  &.first {
    border: 3px solid rgba(120, 120, 120, 0.4);
    > div {
      &.right {
        border: 3px solid rgba(120, 120, 120, 0.4);
        border-left: 0;
      }
    }
  }
  &.is-editable {
    height: 80px;
    > div {
      &.right {
        height: 80px;
      }
    }
  }
  &.disable {
    border: 1px solid #cccccc;
    div,
    button {
      color: #cccccc !important;
    }

    > div {
      &.right {
        border: 1px solid #cccccc;
        border-left: 0;
      }
    }
  }
}

.btn-actions {
  display: flex;
  align-items: center;
  font-weight: bold;
  background: none;
  border: none;
  &:focus {
    outline: none;
  }
}

.dropdown-actions {
  position: relative;
  display: flex;
  justify-content: center;
}

#dropdown {
  display: none;
  animation: grow 400ms ease-in-out forwards;
  transform-origin: top center;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 4px;
  position: absolute;
  top: 40px;
  right: 0px;
  z-index: 5;
  background: #fff;
  width: 100px;
  &.active {
    display: block;
  }
  a {
    display: flex;
    align-items: center;
    cursor: pointer;
    padding: 5px 10px;
    color: #999;
    text-decoration: none;
    font-weight: normal;
    &:hover {
      background: rgba(193, 193, 193, 0.4);
    }
    &:first-of-type {
      border-top-right-radius: 4px;
      border-top-left-radius: 4px;
    }
    &:last-of-type {
      border-bottom-right-radius: 4px;
      border-bottom-left-radius: 4px;
    }
    &.active {
      background: rgba(193, 193, 193, 0.25);
    }
    .circle {
      width: 1rem;
      height: 1rem;
      display: flex;
      align-items: center;
      justify-content: center;
      border: 1px solid #999;
      border-radius: 50%;
      font-size: 0.9rem;
    }
  }
}

.btn-red {
  background: #df2938;
  color: #fff;
}

.btn-green {
  background: #10c398;
  color: #fff;
}
</style>
