<template>
  <div>
    <div class="task-statement-formatted">
      <p :class="{ active: showMore }" v-html="getFormattedTask"></p>
      <ul class="m-0" v-if="showMore">
        <li v-for="option in task.question_object.options" :key="option.option">
          {{ option.option }}
        </li>
      </ul>
      <div class="task-statement-separator">
        <button
          :style="{ color: $store.getters.theme[2] }"
          class="task-statement-show-more hover"
          @click="showMore = !showMore"
        >
          {{
            showMore
              ? translation.global.show_less
              : translation.global.show_more
          }}
        </button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    task: {
      required: true
    },
    getFormattedTask: {
      required: true
    }
  },
  data() {
    return {
      showMore: false
    };
  }
};
</script>

<style lang="scss" scoped></style>
