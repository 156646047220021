<template>
  <div>
    <imageCropper
      :img="imgLogo"
      :show="showCropperLogo"
      :width="300"
      :height="70"
      @updateImg="
        img => {
          imgLogo = img;
          imgLogoPreCropped = img;
          $refs.inputImgLogo.value = '';
          showCropperLogo = false;
        }
      "
      @trash="trash('logo')"
    />
    <imageCropper
      :img="imgBanner"
      :show="showCropperBanner"
      :width="730"
      :height="300"
      @updateImg="
        img => {
          imgBanner = img;
          imgBannerPreCropped = img;
          $refs.inputImgBanner.value = '';
          showCropperBanner = false;
        }
      "
      @trash="trash('banner')"
    />
    <div class="customize-wrapper pl-0 pr-0 col d-flex flex-wrap">
      <div class="col-md-12 col-lg-7">
        <label class="label">
          {{ translation.customize_community.choose_text }}
        </label>
        <div class="radius pb-2">
          <button
            @click="previewTheme(1)"
            :class="{ active: theme === themes[1] }"
            class="hover"
          >
            <img class="img-fluid" src="@/img/theme-1.png" alt="Theme 1" />
          </button>
          <button
            @click="previewTheme(2)"
            :class="{ active: theme === themes[2] }"
            class="hover"
          >
            <img class="img-fluid" src="@/img/theme-2.png" alt="Theme 2" />
          </button>
          <button
            @click="previewTheme(3)"
            :class="{ active: theme === themes[3] }"
            class="hover"
          >
            <img class="img-fluid" src="@/img/theme-3.png" alt="Theme 3" />
          </button>
          <button
            @click="previewTheme(4)"
            :class="{ active: theme === themes[4] }"
            class="hover"
          >
            <img class="img-fluid" src="@/img/theme-4.png" alt="Theme 4" />
          </button>
          <button
            @click="previewTheme(5)"
            :class="{ active: theme === themes[5] }"
            class="hover"
          >
            <img class="img-fluid" src="@/img/theme-5.png" alt="Theme 5" />
          </button>
          <button
            @click="previewTheme(6)"
            :class="{ active: theme === themes[6] }"
            class="hover"
          >
            <img class="img-fluid" src="@/img/theme-6.png" alt="Theme 6" />
          </button>
          <button
            @click="previewTheme(7)"
            :class="{ active: theme === themes[7] }"
            class="hover"
          >
            <img class="img-fluid" src="@/img/theme-7.png" alt="Theme 7" />
          </button>
        </div>
        <div class="py-5">
          <div class="label">
            {{ translation.customize_community.upload_logo }}
          </div>
          <div class="logo-pic mb-4" v-if="imgLogo && imgLogo.length">
            <img class="img-fluid" :src="imgLogo" />
          </div>
          <input
            ref="inputImgLogo"
            class="d-none"
            id="img-logo"
            type="file"
            @change="uploadLogo('img-logo')"
            accept="image/*"
          />
          <label
            for="img-logo"
            class="
                    logo-upload
                    mb-4
                    d-flex
                    align-items-center
                    justify-content-center
                  "
          >
            <img src="@/img/plus.svg" class="mr-2" />
            {{ uploadText }}
          </label>
        </div>
      </div>
      <div class="col-md-12 col-lg-5">
        <div class="">
          <div class="label">
            {{ translation.customize_community.upload_banner }}
          </div>
          <div class="banner-pic mb-4" v-if="imgBanner && imgBanner.length">
            <img class="img-fluid" :src="imgBanner" />
          </div>
          <input
            ref="inputImgBanner"
            class="d-none"
            id="img-banner"
            type="file"
            @change="uploadBanner('img-banner')"
            accept="image/*"
          />
          <label
            for="img-banner"
            class="
                    logo-upload
                    mb-4
                    d-flex
                    align-items-center
                    justify-content-center
                  "
          >
            <img src="@/img/plus.svg" class="mr-2" />
            {{ uploadTextBanner }}
          </label>
        </div>
        <div class="pt-4 banner-description">
          <div class="label">
            {{ translation.customize_community.banner_description }}:
          </div>
          <div>
            <input
              type="text"
              class="input-description"
              maxlength="60"
              v-model="bannerDescription"
              :placeholder="
                translation.customize_community.description_placeholder
              "
            />
            <div class="d-flex justify-content-end mt-2">
              {{ bannerDescription.length }}/60
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="d-flex justify-content-end align-btn-mobile">
      <button
        @click.prevent="save"
        class="btn save-btn px-5 d-flex justify-content-center"
        :style="{ background: $store.getters.theme[2] }"
      >
        <loader2 v-if="loadingLogo || loading || loadingBanner" color="#fff" />
        <p v-if="!loadingLogo && !loading && !loadingBanner" class="m-0">
          {{ translation.global.save }}
        </p>
      </button>
    </div>
  </div>
</template>

<script>
import { v4 as uuidv4 } from "uuid";
import { api } from "@/services.js";
import loader2 from "@/components/general/loader2.vue";
import imageCropper from "@/components/general/imageCropper.vue";
import lambda from "@/lambda";
import { regexpImageName, themes, userTypesText } from "@/constants";
import jwt_decode from "jwt-decode";
import { mapState } from "vuex";

export default {
  name: "Feed",
  components: { loader2, imageCropper },
  data() {
    return {
      fileLogo: {},
      fileBanner: {},
      imgLogo: "",
      imgLogoName: "",
      imgLogoCropped: "",
      imgLogoPreCropped: "",
      imgBanner: "",
      imgBannerName: "",
      imgBannerCropped: "",
      imgBannerPreCropped: "",
      uploadText: "Upload",
      uploadTextBanner: "Upload",
      showCropperBanner: false,
      showCropperLogo: false,
      activities: [],
      type: "",
      reload: false,
      loading: false,
      loadingLogo: false,
      loadingBanner: false,
      loader: false,
      finish: false,
      theme: this.$store.getters.theme,
      activeTheme: this.$store.getters.theme,
      bannerDescription: "",
      activeBannerDescription: this.$store.getters.community.description,
      activeCommunity: null,
      regexpImageName,
      themes,
      userTypesText
    };
  },

  computed: {
    ...mapState(["permissions"]),

    nextActivity() {
      if (this.activities && this.activities.length) return this.activities[0];
      else return {};
    },

    filterActivities() {
      if (this.activities && this.activities.length >= 3)
        return this.activities.slice(0, 3);
      else return this.activities;
    },

    success() {
      if (!this.loading && !this.loadingLogo && !this.loadingBanner) {
        return true;
      }
      return false;
    }
  },

  watch: {
    reload() {
      this.getActivities();
    },
    success() {
      if (this.success) {
        this.$toast.success(this.translation.success_global.changes_saved);
      }
    }
  },

  methods: {
    trash(type) {
      if (type === "logo") {
        this.showCropperLogo = false;
        this.$refs.inputImgLogo.value = "";
        this.uploadText = "Upload";
        this.imgLogoName = "";
        if (this.$store.getters.community.logo)
          this.imgLogo = this.$store.getters.community.logo;
      } else {
        this.showCropperBanner = false;
        this.$refs.inputImgBanner.value = "";
        this.uploadTextBanner = "Upload";
        this.imgBannerName = "";
        if (this.$store.getters.community.banner)
          this.imgBanner = this.$store.getters.community.banner;
      }
    },

    previewTheme(theme) {
      this.theme = this.themes[theme];
      this.$store.commit("UPDATE_THEME", JSON.stringify(this.theme));
    },

    save() {
      this.applyTheme();
      this.applyLogo();
      this.applyBanner();
      this.applyDescription();
    },

    getActivities() {
      this.loader = true;

      let url = `community/${this.$store.getters.community.id}/activities`;
      if (this.$store.getters.info.user.type === this.userTypesText.participant)
        url = `community/${this.$store.getters.community.id}/participant/${this.$store.getters.info.user.sub}/activities`;

      api
        .get(url, {
          headers: {
            Authorization: `Bearer ${this.$store.getters.token}`
          }
        })
        .then(r => {
          if (r.status === 200) {
            this.activities = r.data.community.activities;
          }
          this.loader = false;
        })
        .catch(() => {
          this.loader = false;
        });
    },

    applyLogo() {
      if (!this.loadingLogo && this.imgLogoName !== "") {
        this.loadingLogo = true;

        let arr = this.imgLogoPreCropped.split(",");
        let mime = arr[0].match(/:(.*?);/)[1];
        let bstr = atob(arr[1]);
        let n = bstr.length;
        let u8arr = new Uint8Array(n);

        while (n--) {
          u8arr[n] = bstr.charCodeAt(n);
        }

        const imgName = `${uuidv4()}-${this.fileLogo.name.replace(
          this.regexpImageName,
          ""
        )}`;

        this.imgLogoCropped = new File([u8arr], imgName, {
          type: mime
        });

        lambda
          .getSignedURL(
            this.fileLogo,
            `Assets/Community/${this.$store.getters.community.client.id}`,
            imgName
          )
          .then(url => {
            api
              .put(url, this.imgLogoCropped, {
                headers: {
                  "Content-Type": this.fileLogo.type
                }
              })
              .then(r => {
                api
                  .put(
                    `/community/${this.$store.getters.community.id}`,
                    {
                      logo: r.config.url.split("?")[0]
                    },
                    {
                      headers: {
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${this.$store.getters.token}`
                      }
                    }
                  )
                  .then(() => {
                    api
                      .put(
                        `token/refresh`,
                        {},
                        {
                          headers: {
                            "Content-Type": "application/json",
                            Authorization: `Bearer ${this.$store.getters.token}`
                          }
                        }
                      )
                      .then(respRefresh => {
                        if (respRefresh.status === 200) {
                          const token = respRefresh.data.token;
                          try {
                            const info = jwt_decode(token);
                            this.$store.commit("UPDATE_IS_LOGOUT", false);
                            this.$store.dispatch("login", { token, info });
                          } catch (error) {
                            this.$store.dispatch("logOut");
                            this.$toast.error(
                              this.translation.errors_global
                                .something_went_wrong
                            );
                          }
                          const activeCommunity = this.$store.getters.community;
                          activeCommunity.logo = r.config.url.split("?")[0];

                          this.$store.commit(
                            "UPDATE_COMMUNITY",
                            activeCommunity
                          );
                        }
                        this.loadingLogo = false;
                      })
                      .catch(() => {
                        this.loadingLogo = false;
                      });
                  })
                  .catch(() => {
                    this.$toast.error(
                      this.$toast.error(
                        this.translation.errors_global.something_went_wrong
                      )
                    );
                    this.loadingLogo = false;
                  });
              })
              .catch(() => {
                this.loadingLogo = false;
              });
          })
          .catch(() => {
            this.$toast.error(
              this.translation.errors_global.something_went_wrong
            );
            this.loadingLogo = false;
          });
      }
    },

    applyBanner() {
      if (!this.loadingBanner && this.imgBannerName !== "") {
        this.loadingBanner = true;

        let arr = this.imgBannerPreCropped.split(",");
        let mime = arr[0].match(/:(.*?);/)[1];
        let bstr = atob(arr[1]);
        let n = bstr.length;
        let u8arr = new Uint8Array(n);

        while (n--) {
          u8arr[n] = bstr.charCodeAt(n);
        }

        const imgName = `${uuidv4()}-${this.fileBanner.name.replace(
          this.regexpImageName,
          ""
        )}`;

        this.imgBannerCropped = new File([u8arr], imgName, {
          type: mime
        });

        lambda
          .getSignedURL(
            this.fileBanner,
            `Assets/Communities/${this.$store.getters.community.id}`,
            imgName
          )
          .then(url => {
            api
              .put(url, this.imgBannerCropped, {
                headers: {
                  "Content-Type": this.fileBanner.type
                }
              })
              .then(r => {
                api
                  .put(
                    `community/${this.$store.getters.community.id}`,
                    {
                      banner: r.config.url.split("?")[0]
                    },
                    {
                      headers: {
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${this.$store.getters.token}`
                      }
                    }
                  )
                  .then(() => {
                    api
                      .put(
                        `token/refresh`,
                        {},
                        {
                          headers: {
                            "Content-Type": "application/json",
                            Authorization: `Bearer ${this.$store.getters.token}`
                          }
                        }
                      )
                      .then(respRefresh => {
                        if (respRefresh.status === 200) {
                          const token = respRefresh.data.token;
                          try {
                            const info = jwt_decode(token);
                            this.$store.commit("UPDATE_IS_LOGOUT", false);
                            this.$store.dispatch("login", { token, info });
                          } catch (error) {
                            this.$store.dispatch("logOut");
                            this.$toast.error(
                              this.translation.errors_global
                                .something_went_wrong
                            );
                          }
                          const activeCommunity = this.$store.getters.community;
                          activeCommunity.banner = r.config.url.split("?")[0];
                          this.$store.commit(
                            "UPDATE_COMMUNITY",
                            activeCommunity
                          );
                        }
                        this.loadingBanner = false;
                      })
                      .catch(() => {
                        this.loadingBanner = false;
                      });
                  })
                  .catch(() => {
                    this.$toast.error(
                      this.translation.errors_global.something_went_wrong
                    );
                    this.loadingLogo = false;
                  });
              })
              .catch(() => {
                this.loadingBanner = false;
              });
          })
          .catch(() => {
            this.$toast.error(
              this.translation.errors_global.something_went_wrong
            );
            this.loadingLogo = false;
          });
      }
    },

    applyTheme() {
      if (!this.loading && this.theme !== this.activeTheme) {
        this.loading = true;
        let theme = 4;

        theme = Object.entries(this.themes).filter(
          theme => theme[1] === this.theme
        )[0][0];

        api
          .put(
            `community/${this.$store.getters.community.id}`,
            {
              theme: +theme
            },
            {
              headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${this.$store.getters.token}`
              }
            }
          )
          .then(() => {
            api
              .put(
                `token/refresh`,
                {},
                {
                  headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${this.$store.getters.token}`
                  }
                }
              )
              .then(respRefresh => {
                if (respRefresh.status === 200) {
                  const token = respRefresh.data.token;
                  try {
                    const info = jwt_decode(token);
                    this.$store.commit("UPDATE_IS_LOGOUT", false);
                    this.$store.dispatch("login", { token, info });
                  } catch (error) {
                    this.$store.dispatch("logOut");
                    this.$toast.error(
                      this.translation.errors_global.something_went_wrong
                    );
                  }
                  const activeCommunity = this.$store.getters.community;
                  activeCommunity.theme = theme;
                  this.$store.commit("UPDATE_COMMUNITY", activeCommunity);
                }
                this.loading = false;
              })
              .catch(() => {
                this.loading = false;
              });
          })
          .catch(() => {
            this.$toast.error(
              this.translation.errors_global.something_went_wrong
            );
            this.loading = false;
          });
      }
    },

    applyDescription() {
      if (
        !this.loading &&
        this.bannerDescription !== "" &&
        this.bannerDescription !== this.activeBannerDescription
      ) {
        this.loading = true;
        api
          .put(
            `community/${this.$store.getters.community.id}`,
            {
              description: this.bannerDescription
            },
            {
              headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${this.$store.getters.token}`
              }
            }
          )
          .then(() => {
            api
              .put(
                `token/refresh`,
                {},
                {
                  headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${this.$store.getters.token}`
                  }
                }
              )
              .then(respRefresh => {
                if (respRefresh.status === 200) {
                  const token = respRefresh.data.token;
                  try {
                    const info = jwt_decode(token);
                    this.$store.commit("UPDATE_IS_LOGOUT", false);
                    this.$store.dispatch("login", { token, info });
                  } catch (error) {
                    this.$store.dispatch("logOut");
                    this.$toast.error(
                      this.translation.errors_global.something_went_wrong
                    );
                  }
                  const activeCommunity = this.$store.getters.community;
                  activeCommunity.description = this.bannerDescription;
                  this.$store.commit("UPDATE_COMMUNITY", activeCommunity);
                }
                this.loading = false;
              })
              .catch(() => {
                this.loading = false;
              });
          })
          .catch(() => {
            this.$toast.error(
              this.translation.errors_global.something_went_wrong
            );
            this.loading = false;
          });
      }
    },

    async uploadLogo(element) {
      const file = document.querySelector(`#${element}`).files[0];
      this.fileLogo = file;

      if (file) {
        const toBase64 = file =>
          new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => resolve(reader.result);
            reader.onerror = error => reject(error);
          });
        this.imgLogo = await toBase64(file);
        this.imgLogoName = file.name;
        this.showCropperLogo = true;
        this.uploadText = file.name;
      }
    },

    async uploadBanner(element) {
      const file = document.querySelector(`#${element}`).files[0];
      this.fileBanner = file;

      if (file) {
        const toBase64 = file =>
          new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => resolve(reader.result);
            reader.onerror = error => reject(error);
          });

        this.imgBanner = await toBase64(file);
        this.imgBannerName = file.name;
        this.showCropperBanner = true;
        this.uploadTextBanner = file.name;
      }
    },

    init() {
      if (this.$store.getters.community.logo)
        this.imgLogo = this.$store.getters.community.logo;
      if (this.$store.getters.community.banner)
        this.imgBanner = this.$store.getters.community.banner;
      if (this.$store.getters.community.description)
        this.bannerDescription = this.$store.getters.community.description;
      this.getActivities();
    }
  },

  created() {
    this.init();
  },

  beforeDestroy() {
    if (this.$store.getters.community) {
      const userTheme = +this.$store.getters.community.theme;
      let theme = themes[userTheme];
      this.$store.commit("UPDATE_THEME", JSON.stringify(theme));
    } else {
      this.$store.commit("UPDATE_THEME", JSON.stringify(this.themes[4]));
    }
  }
};
</script>

<style lang="scss" scoped>
.save-btn {
  text-align: center;
  text-transform: uppercase;
  color: #fff;
  font-weight: bold;
  letter-spacing: 0.16em;
  border-radius: 17px;
  border: none;
  padding: 5px 20px;
  align-self: flex-end;
  min-width: 162px;
}

.label {
  font-size: 1.05rem;
  font-weight: bold;
  line-height: 25px;
  color: #6b6b6b;
  margin-bottom: 30px;
}

.radius {
  button {
    border: none;
    margin: 5px;
    background: none;
    img {
      width: 50px;
      height: 50px;
      object-fit: contain;
    }
    &:focus,
    &.active {
      outline: 0;
      img {
        box-shadow: 0 0 0 0.2rem rgba(123, 123, 123, 0.25);
        border-radius: 50%;
      }
    }
  }
}

.logo-upload {
  display: block;
  max-width: 220px;
  color: #6b6b6b;
  font-weight: bold;
  padding: 9px;
  cursor: pointer;
  text-align: center;
  text-transform: uppercase;
  border: 1px solid #6b6b6b !important;
  border-radius: 10px !important ;
  background: none !important;
  img {
    width: 14px;
    height: 14px;
  }
}

.logo-pic {
  img {
    padding: 10px;
    border: 1px solid #ececec;
    border-radius: 9px;
    max-width: 300px;
    max-height: 80px;
  }
}

.banner-pic {
  img {
    object-fit: cover;
    width: 390px;
    height: 200px;
    border-radius: 9px;
  }
}

.banner-description {
  .label {
    font-weight: normal;
  }
  div {
    max-width: 480px;
    input {
      color: #6b6b6b;
      font-size: 0.875rem;
      width: 100%;
      background: none;
      border: 1px solid #e6e6e6;
      border-radius: 10px;
      transition: all 0.3s ease;
      padding: 10px;
      &::placeholder {
        font-size: 0.875rem;
        font-weight: normal;
        color: #999999;
      }
      &:focus {
        outline: none;
      }
    }
    div {
      width: 100%;
      font-size: 14px;
      font-weight: normal;
      color: #6b6b6b;
    }
  }
}

.customize-wrapper {
  margin: 35px 0;
}

@media (max-width: 767.98px) {
  .banner-pic {
    img {
      height: 100px;
      width: 300px;
    }
  }
}

@media (max-width: 567.98px) {
  .align-btn-mobile {
    justify-content: center !important;
  }

  .logo-pic {
    img {
      padding: 10px;
      border: 1px solid #ececec;
      border-radius: 9px;
      max-width: 200px;
      max-height: 80px;
    }
  }
}
</style>
