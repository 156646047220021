<template>
  <div class="container">
    <div class="row py-5">
      <div class="col">
        <div class="card-b mb-4 p-5">
          <div class="option-btns">
            <button
              class="btn px-4 px-md-5"
              :class="{ active: option === tabOptions.customizePages }"
              :style="{
                background:
                  option === tabOptions.customizePages
                    ? $store.getters.theme[2]
                    : $store.getters.theme[0],
                color:
                  option === tabOptions.customizePages ? '#ffffff' : '#999999'
              }"
              @click="changeTab(tabOptions.customizePages)"
            >
              {{ translation.customize_community.customize_pages }}
            </button>

            <button
              class="btn px-4 px-md-5"
              :class="{ active: option === tabOptions.addMessage }"
              :style="{
                background:
                  option === tabOptions.addMessage
                    ? $store.getters.theme[2]
                    : $store.getters.theme[0],
                color: option === tabOptions.addMessage ? '#ffffff' : '#999999'
              }"
              @click="changeTab(tabOptions.addMessage)"
            >
              {{ translation.customize_community.add_message }}
            </button>
          </div>

          <transition mode="out-in">
            <customize-pages v-if="option === tabOptions.customizePages" />
            <add-message v-if="option === tabOptions.addMessage" />
          </transition>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { tabOptions } from "@/constants";
import customizePages from "@/components/customization/customizePages.vue";
import addMessage from "@/components/customization/addMessage.vue";

export default {
  name: "Customization",

  components: { customizePages, addMessage },

  data() {
    return {
      tabOptions
    };
  },

  computed: {
    option() {
      return this.$route.query._option;
    }
  },

  methods: {
    changeTab(option) {
      this.$router.push({ query: { _option: option } });
    }
  }
};
</script>

<style lang="scss" scoped>
.option-btns {
  display: flex;
  justify-content: center;

  button {
    font-weight: bold;
    font-size: 1.3rem;
    transition: all 0.3s ease;
    min-width: 245px;
    &:nth-of-type(1) {
      border-top-left-radius: 35px;
      border-bottom-left-radius: 35px;
    }
    &:nth-of-type(2) {
      border-top-right-radius: 35px;
      border-bottom-right-radius: 35px;
    }
    &.active {
      z-index: 2;
    }
    &:hover {
      color: #666 !important;
    }
  }

  @media (max-width: 400px) {
    flex-direction: column;
    gap: 20px;

    button {
      border-radius: 35px;
    }
  }
}

@media (max-width: 767.98px) {
  .option-btns {
    button {
      min-width: 140px;
      font-size: 0.8rem;
    }
  }
}
</style>
