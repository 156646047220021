var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('router-link',{staticClass:"logo",attrs:{"to":{ name: 'login' }}},[(
        _vm.$store.getters.community &&
          _vm.$store.getters.community.logo &&
          _vm.$store.getters.community.logo.length
      )?_c('img',{staticClass:"img-fluid ",attrs:{"src":_vm.$store.getters.community.logo,"alt":"Logo"}}):_c('img',{attrs:{"src":require("@/img/logo-communities.png"),"alt":"Logo"}})]),_c('div',{staticClass:"wrapper d-flex align-items-center"},[_c('div',{staticClass:"col-lg-6 px-lg-5"},[_c('div',{staticClass:"px-lg-5 w-100"},[_c('div',{staticClass:"style",style:({
            background: ("" + (_vm.$store.getters.theme ? _vm.$store.getters.theme[2] : '#108FC3'))
          })}),_c('h2',[_vm._v(" "+_vm._s(_vm.translation.email.reset_password_text)+" ")]),_c('p',{staticClass:"pt-3 text"},[_vm._v(" "+_vm._s(_vm.translation.email.type_your_email_text)+" ")]),_c('form',[_c('div',{staticClass:"mb-4 mt-4 input"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.email),expression:"email"}],staticClass:"form-control p-4",attrs:{"type":"email","id":"email","placeholder":_vm.translation.email.email_text},domProps:{"value":(_vm.email)},on:{"input":function($event){if($event.target.composing){ return; }_vm.email=$event.target.value}}})]),_c('button',{staticClass:"btn mt-4 hover d-flex justify-content-center align-items-center",style:({
              background: ("" + (_vm.$store.getters.theme ? _vm.$store.getters.theme[0] : '#F7FAFA'))
            }),on:{"click":function($event){$event.preventDefault();return _vm.send($event)}}},[(_vm.loader2)?_c('loader2',{attrs:{"color":"#6b6b6b"}}):_vm._e(),(!_vm.loader2)?_c('p',{staticClass:"m-0"},[_vm._v(" "+_vm._s(_vm.translation.email.send_email_button)+" ")]):_vm._e()],1)])])]),_c('div',{staticClass:"col-lg-6 d-flex justify-content-center align-self-end px-lg-5"},[(_vm.$store.getters.community)?_c('div',[(+_vm.$store.getters.community.theme === _vm.themesCodes.themeOrange)?_c('img',{staticClass:"img-fluid pb-lg-5",attrs:{"src":require("@/img/pass1.svg"),"alt":"Login\n      image"}}):_vm._e(),(+_vm.$store.getters.community.theme === _vm.themesCodes.themeGreen)?_c('img',{staticClass:"img-fluid pb-lg-5",attrs:{"src":require("@/img/pass2.svg"),"alt":"Login\n      image"}}):_vm._e(),(+_vm.$store.getters.community.theme === _vm.themesCodes.themeYellow)?_c('img',{staticClass:"img-fluid pb-lg-5",attrs:{"src":require("@/img/pass3.svg"),"alt":"Login\n      image"}}):_vm._e(),(+_vm.$store.getters.community.theme === _vm.themesCodes.themeBlue)?_c('img',{staticClass:"img-fluid pb-lg-5",attrs:{"src":require("@/img/pass4.svg"),"alt":"Login\n      image"}}):_vm._e(),(+_vm.$store.getters.community.theme === _vm.themesCodes.themePink)?_c('img',{staticClass:"img-fluid pb-lg-5",attrs:{"src":require("@/img/pass5.svg"),"alt":"Login\n      image"}}):_vm._e(),(+_vm.$store.getters.community.theme === _vm.themesCodes.themeGray)?_c('img',{staticClass:"img-fluid pb-lg-5",attrs:{"src":require("@/img/pass6.svg"),"alt":"Login\n      image"}}):_vm._e()]):_c('div',[_c('img',{staticClass:"img-fluid pb-lg-5",attrs:{"src":require("@/img/pass4.svg"),"alt":"Login\n      image"}})])])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }