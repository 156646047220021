<template>
  <div
    v-if="
      $store.getters.info.user.type === userTypesText.moderator ||
        $store.getters.info.user.type === userTypesText.admin
    "
    @click="handleOutsideClick"
  >
    <div class="d-flex align-items-center flex-wrap mb-1" v-if="!loaderLabels">
      <div
        v-for="(label, labelIndex) in answerLabels"
        :key="label.id"
        class="label mr-2 mb-2 remove-btn"
        :style="{ color: label.color, background: label.background_color }"
        :title="label.description"
        v-b-tooltip.hover="{ variant: 'secondary' }"
      >
        <p>
          {{ label.name }}
        </p>

        <button
          :style="{ color: $store.getters.theme[3] }"
          class="delete hover"
          @click="removeLabel(labelIndex, label.id)"
        >
          <b-icon icon="x"></b-icon>
        </button>
      </div>
      <button
        v-if="answer.label.length > 3"
        class="show-more mb-2 hover"
        @click="showMore = !showMore"
      >
        {{
          showMore ? translation.labels.show_less : translation.labels.show_more
        }}
      </button>
      <div class="d-flex flex-wrap align-items-center">
        <button
          v-if="
            labels.length &&
              ($store.getters.info.user.type === userTypesText.moderator ||
                $store.getters.info.user.type === userTypesText.admin)
          "
          @click="(showAddLabel = !showAddLabel), (showCreateLabel = false)"
          class="btn btn-new-label hover mr-2 mb-2"
        >
          <b-icon icon="plus"></b-icon>
          {{ translation.labels.add_label }}
        </button>
        <button
          v-if="
            $store.getters.info.user.type === userTypesText.moderator ||
              $store.getters.info.user.type === userTypesText.admin
          "
          @click="(showCreateLabel = !showCreateLabel), (showAddLabel = false)"
          class="btn btn-new-label hover mb-2"
        >
          <b-icon icon="plus"></b-icon>
          {{ translation.labels.create_label }}
        </button>
      </div>
    </div>
    <div v-else class="d-flex justify-content-center">
      <loader2 :color="$store.getters.theme[2]" />
    </div>

    <div class="add-label mt-3" :class="{ active: showAddLabel }">
      <div class="d-flex">
        <div class="col">
          <h2
            class="h5 font-weight-bold mb-3"
            :style="{ color: $store.getters.theme[3] }"
          >
            {{ translation.labels.add_label }}
          </h2>
          <div
            class="w-100 d-flex flex-wrap mb-2"
            v-if="labelsFilter.length && !loaderLabels"
          >
            <div
              :class="{ active: selectedLabels.includes(label.id) }"
              class="label px-4 py-2 mr-2 mb-2 pointer"
              :style="{
                color: label.color,
                background: label.background_color
              }"
              v-for="label in labelsFilter"
              :key="label.id"
              @click="toggleLabel(label.id)"
              v-b-tooltip.hover="{ variant: 'secondary' }"
              :title="label.description"
            >
              <p>
                {{ label.name }}
              </p>
            </div>
          </div>
          <div v-else-if="loaderLabels" class="my-3">
            <loader2 color="#6b6b6b" />
          </div>
          <div v-else class="my-4 text">
            {{ translation.labels.all_available }}
          </div>
        </div>
      </div>
      <div class="d-flex">
        <div class="col">
          <button
            :class="{ hover: selectedLabels.length }"
            :disabled="!selectedLabels.length"
            class="btn btn-save"
            @click="addLabel"
          >
            <div v-if="loaderAddLabel" class="d-flex justify-content-center">
              <loader2 color="#6b6b6b" />
            </div>
            <p v-else>
              {{ translation.global.add }}
            </p>
          </button>
        </div>
      </div>
    </div>
    <div class="create-label mt-3" :class="{ active: showCreateLabel }">
      <div class="d-flex flex-wrap">
        <div class="col-md-12">
          <h2
            class="h5 font-weight-bold mb-3"
            :style="{ color: $store.getters.theme[3] }"
          >
            {{ translation.labels.create_label }}
          </h2>
          <div class="w-100 d-flex flex-wrap mb-2" v-if="!loaderLabels">
            <div
              class="label mr-2 mb-2 remove-btn"
              :style="{
                color: label.color,
                background: label.background_color
              }"
              v-for="(label, labelIndex) in labels"
              :key="label.id"
              v-b-tooltip.hover="{ variant: 'secondary' }"
              :title="label.description"
            >
              <p>
                {{ label.name }}
              </p>

              <button
                :style="{ color: $store.getters.theme[3] }"
                class="delete hover"
                @click="
                  $bvModal.show(`deleteLabel${label.id}-${answer.answer_id}`)
                "
              >
                <b-icon icon="x"></b-icon>
              </button>

              <b-modal
                @ok="deleteLabel(labelIndex, label.id)"
                :cancel-title="translation.global.cancel"
                :id="`deleteLabel${label.id}-${answer.answer_id}`"
                :title="translation.labels.modal.delete_label"
              >
                {{ translation.labels.modal.are_you_sure_delete_label }}
              </b-modal>
            </div>
          </div>
        </div>
        <div class="col-md-6">
          <div class="mb-2">
            <label for="label-name">
              {{ translation.labels.label_name }}
            </label>
            <input
              class="form-control"
              id="label-name"
              placeholder="Ex: Positivo"
              v-model="name"
            />
          </div>
          <div>
            <label for="label-description">
              {{ translation.labels.label_description }}
            </label>
            <input
              class="form-control"
              id="label-description"
              v-model="description"
            />
          </div>
        </div>
        <div class="colors col-md-6 d-flex flex-column justify-content-end">
          <div class="mb-2">
            <label for="background-color" class="form-label">
              {{ translation.labels.label_background }}
            </label>
            <input
              type="color"
              class="form-control form-control-color"
              id="background-color"
              title="Choose your color"
              v-model="background"
            />
          </div>
          <div>
            <label for="text-color" class="form-label">
              {{ translation.labels.label_color }}
            </label>
            <input
              type="color"
              class="form-control form-control-color"
              id="text-color"
              title="Choose your color"
              v-model="color"
            />
          </div>
        </div>
      </div>
      <div class="d-flex mt-3">
        <div class="col">
          <button class="btn btn-save hover" @click="createLabel">
            <div v-if="loaderCreateLabel" class="d-flex justify-content-center">
              <loader2 color="#6b6b6b" />
            </div>
            <p v-else>
              {{ translation.global.save }}
            </p>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { api } from "@/services.js";
import loader2 from "@/components/general/loader2.vue";
import { userTypesText } from "@/constants";
import { outsideClick } from "@/helpers.js";
import { mapState, mapMutations } from "vuex";

export default {
  props: {
    answerProp: {
      required: true
    }
  },
  components: {
    loader2
  },
  data() {
    return {
      showCreateLabel: false,
      showAddLabel: false,
      name: "",
      description: "",
      background: "#ceffcd",
      color: "#6b6b6b",
      loaderCreateLabel: false,
      loaderAddLabel: false,
      loaderLabels: false,
      selectedLabels: [],
      showMore: false,
      outsideClick,
      answer: null,
      userTypesText
    };
  },
  computed: {
    ...mapState(["labels"]),
    labelsFilter() {
      const labelIds = this.answer.label.map(label => label.id);
      return this.labels.filter(label => !labelIds.includes(label.id));
    },
    answerLabels() {
      if (!this.showMore)
        return this.answer.label.filter((label, index) => index <= 2);
      return this.answer.label;
    }
  },
  watch: {
    labels() {
      if (this.answer.label.length) {
        const idsAnswerLabels = this.answer.label.map(label => label.id);
        const idsLabels = this.labels.map(label => label.id);

        idsAnswerLabels.forEach((answerLabel, answerLabelIndex) => {
          if (!idsLabels.find(label => answerLabel === label))
            this.answer.label.splice(answerLabelIndex, 1);
        });
      }
    }
  },
  methods: {
    ...mapMutations(["UPDATE_LABELS"]),
    handleOutsideClick({ currentTarget }) {
      this.outsideClick(currentTarget, ["click"], () => {
        this.showCreateLabel = false;
        this.showAddLabel = false;
      });
    },
    removeLabel(index, id) {
      api
        .delete(`label/${id}/delete-from/answer/${this.answer.answer_id}`, {
          headers: {
            Authorization: `Bearer ${this.$store.getters.token}`,
            "Content-Type": "application/json"
          }
        })
        .then(r => {
          if (r.status === 200) {
            this.$toast.success(this.translation.labels.success.label_removed);
            this.answer.label.splice(index, 1);
          }
        });
    },
    addLabel() {
      if (!this.loaderAddLabel) {
        this.loaderAddLabel = true;
        api
          .put(
            `label/add-to-answer/${this.answer.answer_id}`,
            {
              labels: this.selectedLabels
            },
            {
              headers: {
                Authorization: `Bearer ${this.$store.getters.token}`,
                "Content-Type": "application/json"
              }
            }
          )
          .then(r => {
            if (r.status === 200) {
              this.$toast.success(this.translation.labels.success.added_label);

              const labels = this.labels.filter(label =>
                this.selectedLabels.includes(label.id)
              );

              this.answer.label.push(...labels);
            }

            this.loaderAddLabel = false;
          })
          .catch(() => {
            this.$toast.error(
              this.translation.errors_global.something_went_wrong
            );
            this.loaderAddLabel = false;
          });
      }
    },
    toggleLabel(id) {
      if (this.selectedLabels.includes(id))
        this.selectedLabels.splice(this.selectedLabels.indexOf(id), 1);
      else this.selectedLabels.push(id);
    },
    deleteLabel(index, id) {
      api
        .delete(`label/${id}`, {
          headers: {
            Authorization: `Bearer ${this.$store.getters.token}`,
            "Content-Type": "application/json"
          }
        })
        .then(r => {
          if (r.status === 200) {
            this.$toast.success(this.translation.labels.success.deleted_label);
            this.labels.splice(index, 1);
            this.UPDATE_LABELS(this.labels);
          }
        });
    },

    createLabel() {
      if (!this.loaderCreateLabel) {
        if (this.name.length && this.description.length) {
          this.loaderCreateLabel = true;
          api
            .post(
              `label`,
              {
                name: this.name,
                description: this.description,
                color: this.color,
                background_color: this.background,
                community_id: this.$store.getters.community.id
              },
              {
                headers: {
                  "Content-Type": "application/json",
                  Authorization: `Bearer ${this.$store.getters.token}`
                }
              }
            )
            .then(r => {
              if (r.status === 201) {
                this.name = "";
                this.description = "";
                this.background = "#ceffcd";
                this.color = "#6b6b6b";
                this.$toast.success(
                  this.translation.labels.success.label_created
                );
                this.getLabels();
              }
              this.loaderCreateLabel = false;
            })
            .catch(() => {
              this.loaderCreateLabel = false;
              this.$toast.error(
                this.translation.errors_global.something_went_wrong
              );
            });
        } else {
          this.$toast.error(this.translation.errors_global.fill_fields);
        }
      }
    },
    getLabels() {
      this.loaderLabels = true;
      api
        .get(`label/all-from-community/${this.$store.getters.community.id}`, {
          headers: {
            Authorization: `Bearer ${this.$store.getters.token}`,
            "Content-Type": "application/json"
          }
        })
        .then(r => {
          if (r.status === 200) {
            this.UPDATE_LABELS(r.data.labels);
          }
          this.loaderLabels = false;
        })
        .catch(() => {
          this.loaderLabels = false;
        });
    }
  },
  created() {
    if (
      this.$store.getters.info.user.type === this.userTypesText.moderator ||
      this.$store.getters.info.user.type === this.userTypesText.admin
    )
      this.getLabels();

    this.answer = this.answerProp;
  }
};
</script>

<style lang="scss" scoped>
.add-label {
  display: none;
  animation: show 0.3s linear;
  &.active {
    display: block;
  }
}

.create-label {
  display: none;
  animation: show 0.3s linear;
  label {
    color: #6b6b6b;
  }
  &.active {
    display: block;
  }
}

.colors {
  input {
    width: 125px;
  }
}

.btn-new-label {
  display: flex;
  align-items: center;
  background: #ececec;
  border-radius: 6px;
  color: #6b6b6b;
  font-weight: bold;
  height: 40px;
  svg {
    font-size: 1.5rem !important;
  }
}

@media (max-width: 767.98px) {
  .btn-new-label {
    font-size: 0.7rem;
    height: 35px;
    padding: 5px 10px 5px 2px;
  }
}

.btn-save {
  text-align: center;
  text-transform: uppercase;
  color: #6b6b6b;
  font-weight: bold;
  letter-spacing: 0.16em;
  border-radius: 18px;
  border: none;
  padding: 5px 20px;
  align-self: flex-end;
  border: 1px solid #c8dada;
  background: none;
  min-width: 120px;
  p {
    margin: 0;
  }
}

.label {
  display: flex;
  text-align: center;
  align-items: center;
  min-height: 35px;
  min-width: 100px;
  position: relative;
  border-radius: 6px;
  font-weight: bold;
  text-transform: uppercase;
  transition: all 0.3s ease;
  box-shadow: 0px 0px 10px 0px rgba(99, 150, 150, 0.2);
  animation: show 0.3s linear;
  p {
    padding: 5px 10px 5px 10px;
    margin: 0;
  }
  &.remove-btn {
    p {
      margin-right: 45px;
    }
  }
  &.active {
    box-shadow: 0 0 0 0.2rem rgba(163, 163, 163, 0.5);
  }
}

@media (max-width: 767.98px) {
  .label {
    font-size: 0.7rem;
    white-space: nowrap;
    min-height: 35px;
  }
}

.delete {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background: #ffffff;
  border: none;
  font-weight: bold;
  flex-shrink: 1;
  top: 5px;
  right: 5px;
  svg {
    position: relative;
    font-size: 1.1rem;
  }
  &:focus {
    outline: none;
  }
}

@media (max-width: 767.98px) {
  .delete {
    width: 20px;
    height: 20px;
  }
}

.moderator-labels {
  padding-top: 20px;
  margin-top: 20px;
}

.show-more {
  background: none;
  color: #6b6b6b;
  border: none;
  font-weight: bold;
  transition: all 0.3s ease;
  &:focus {
    outline: none;
  }
}

.text {
  color: #6b6b6b;
}
</style>
