<template>
  <div class="container">
    <imageCropper
      :img="imgProfile"
      :show="showCropper"
      :width="155"
      :height="155"
      :loader="loaderInsertImg"
      @updateImg="img => insertImg(img)"
      @trash="close"
    />
    <div class="row py-5">
      <div class="col">
        <div class="card-b mb-4 p-5">
          <h2
            class="h5 font-weight-bold mb-0"
            :style="{ color: $store.getters.theme[3] }"
          >
            {{ translation.account_settings.account_settings }}
          </h2>
          <form class="d-flex flex-wrap pt-5 pb-3">
            <div class="col-md-6">
              <div class="input">
                <label for="name">
                  {{ translation.account_settings.name_text }}
                </label>
                <b-form-input
                  type="text"
                  class="form-control"
                  id="name"
                  :state="!hasSpecialCharacters && !!name.length"
                  v-b-tooltip.hover="{ variant: 'secondary' }"
                  :title="translation.account_settings.name_validation"
                  v-model="name"
                >
                </b-form-input>
              </div>
              <div class="input">
                <label for="email">
                  {{ translation.account_settings.email_text }}
                </label>
                <input
                  disabled
                  type="email"
                  class="form-control"
                  id="email"
                  v-model="email"
                />
              </div>
            </div>
            <div class="col-md-6 d-flex flex-column align-items-center">
              <label
                for="icon-account"
                class="profile-pic"
                :style="{ 'background-color': $store.getters.theme[1] }"
              >
                <input
                  ref="inputImg"
                  @change="uploadImg('icon-account')"
                  id="icon-account"
                  class="d-none"
                  type="file"
                  accept="image/*"
                />
                <div class="img" v-if="imgProfile && imgProfile.length">
                  <img :src="imgProfile" />
                </div>
              </label>
            </div>
            <div class="col-md-6">
              <h3 class="pt-4 pb-3">
                {{ translation.account_settings.reset_pwd }}
              </h3>
              <div
                class="input"
                v-if="$store.getters.info.user.type !== userTypesText.admin"
              >
                <label for="oldPass">
                  {{ translation.account_settings.old_password_text }}
                </label>
                <input
                  type="password"
                  class="form-control"
                  id="oldPass"
                  v-model="oldPass"
                />
              </div>
              <div class="input">
                <label for="pass">
                  {{ translation.account_settings.new_password_text }}
                </label>
                <input
                  type="password"
                  class="form-control"
                  id="pass"
                  v-model="pass"
                />
              </div>
              <div class="input mb-md-5">
                <label for="confirmPass">
                  {{ translation.account_settings.confirm_new_password_text }}
                </label>
                <input
                  type="password"
                  class="form-control"
                  id="confirmPass"
                  v-model="confirmPass"
                />
              </div>
            </div>
            <div class="col d-flex align-items-end justify-content-end">
              <button
                @click.prevent="
                  $store.getters.info.user.type !== userTypesText.admin
                    ? saveAccount()
                    : saveAccountAdmin()
                "
                class="btn save-btn px-5 d-flex justify-content-center"
              >
                <loader2 v-if="loaderSave" color="#6b6b6b" />
                <p v-if="!loaderSave" class="m-0">
                  {{ translation.global.save }}
                </p>
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { v4 as uuidv4 } from "uuid";
import { api } from "@/services.js";
import imageCropper from "@/components/general/imageCropper.vue";
import loader2 from "@/components/general/loader2.vue";
import lambda from "@/lambda";
import { regexpImageName, userTypesText } from "@/constants";
import jwt_decode from "jwt-decode";

export default {
  components: { loader2, imageCropper },
  data() {
    return {
      name: "",
      email: "",
      imgProfile: "",
      imgProfileName: "",
      oldPass: "",
      pass: "",
      confirmPass: "",
      loaderSave: false,
      showCropper: false,
      loaderInsertImg: false,
      regexpImageName,
      successText: "",
      errorText: "",
      regexpNameValidation: /[()!&$@=;:’“+,?\\{\\}%`\]'">[~>#|0-9]/g,
      userTypesText
    };
  },
  computed: {
    hasSpecialCharacters() {
      return this.name.match(this.regexpNameValidation);
    }
  },
  methods: {
    async uploadImg(element) {
      const file = document.querySelector(`#${element}`).files[0];
      if (file) {
        const toBase64 = file =>
          new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => resolve(reader.result);
            reader.onerror = error => reject(error);
          });
        this.imgProfile = await toBase64(file);
        this.imgProfileName = file.name;
        this.showCropper = true;
      }
    },
    close() {
      this.showCropper = false;
      this.$refs.inputImg.value = "";
      this.imgProfile = "";
    },
    insertImg(src) {
      this.loaderInsertImg = true;

      let arr = src.split(",");
      let mime = arr[0].match(/:(.*?);/)[1];
      let bstr = atob(arr[1]);
      let n = bstr.length;
      let u8arr = new Uint8Array(n);

      while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
      }

      this.imgProfileName = `${uuidv4()}-${this.imgProfileName.replace(
        this.regexpImageName,
        ""
      )}`;

      const imgFile = new File([u8arr], this.imgProfileName, {
        type: mime
      });

      let folder;

      if (this.$store.getters.info.user.type === this.userTypesText.admin)
        folder = `Assets/CommunityAdmins/${this.$store.getters.info.user.sub}/Pictures`;
      else if (
        this.$store.getters.info.user.type === this.userTypesText.participant
      )
        folder = `Assets/Participants/${this.$store.getters.info.user.sub}/Pictures`;
      else if (
        this.$store.getters.info.user.type === this.userTypesText.moderator
      )
        folder = `Assets/Operators/Moderators/${this.$store.getters.info.user.sub}`;
      else
        folder = `Assets/Operators/Visotors/${this.$store.getters.info.user.sub}`;

      lambda
        .getSignedURL(imgFile, folder, this.imgProfileName)
        .then(url => {
          api
            .put(url, imgFile, {
              headers: {
                "Content-Type": imgFile.type
              }
            })
            .then(r => {
              this.imgProfile = r.config.url.split("?")[0];
              this.$refs.inputImg.value = "";
              this.showCropper = false;
              this.loaderInsertImg = false;
            })
            .catch(() => {
              this.$toast.error(
                this.translation.errors_global.something_went_wrong
              );
              this.loaderInsertImg = false;
            });
        })
        .catch(() => {
          this.$toast.error(
            this.translation.errors_global.something_went_wrong
          );
          this.loaderInsertImg = false;
        });
    },
    async saveAccountAdmin() {
      if (!this.loaderSave) {
        this.loaderSave = true;
        const respAdminName = await this.changeAdminName();
        const respAdminPicture = await this.changeAdminPicture();
        const respAdminPassword = await this.changeAdminPassword();

        if (
          (respAdminName && respAdminName.status === 200) ||
          (respAdminPicture && respAdminPicture.status === 200) ||
          (respAdminPassword && respAdminPassword.status === 200)
        ) {
          api
            .put(
              `token/refresh`,
              {},
              {
                headers: {
                  "Content-Type": "application/json",
                  Authorization: `Bearer ${this.$store.getters.token}`
                }
              }
            )
            .then(r => {
              if (r.status === 200) {
                const token = r.data.token;
                try {
                  const info = jwt_decode(token);
                  this.$store.commit("UPDATE_IS_LOGOUT", false);
                  this.$store.dispatch("login", { token, info });
                } catch (error) {
                  this.$store.dispatch("logOut");
                  this.$toast.error(
                    this.translation.errors_global.something_went_wrong
                  );
                }

                this.pass = "";
                this.confirmPass = "";
                this.oldPass = "";
                this.loaderSave = false;
              }
            })
            .catch(() => {
              this.loaderSave = false;
            });
        }

        if (respAdminName && respAdminName.status === 200) {
          this.successText += `• ${this.translation.account_settings.success.name_success} `;
        }

        if (respAdminPicture && respAdminPicture.status === 200) {
          this.successText += `• ${this.translation.account_settings.success.picture_success}`;
        } else {
          this.errorText += `• ${this.translation.account_settings.picture_error} `;
        }

        if (respAdminPassword && respAdminPassword.status === 200) {
          this.successText += `• ${this.translation.account_settings.success.password_success}`;
        } else if (respAdminPassword) {
          this.errorText += `• ${this.translation.account_settings.password_error} `;
        }

        if (this.successText.length) this.$toast.success(this.successText);

        if (this.errorText.length) this.$toast.error(this.errorText);

        this.successText = "";
        this.errorText = "";

        this.loaderSave = false;
      }
    },
    async changeAdminName() {
      if (!this.hasSpecialCharacters) {
        try {
          return await api.put(
            `admin/${this.$store.getters.info.user.sub}`,
            {
              name: this.name
            },
            {
              headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${this.$store.getters.token}`
              }
            }
          );
        } catch (error) {
          return error.response;
        }
      } else {
        this.errorText += `• ${this.translation.account_settings.error.name_error} `;
      }
    },
    async changeAdminPicture() {
      try {
        return await api.put(
          `admin/change-picture`,
          {
            picture: this.imgProfile
          },
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${this.$store.getters.token}`
            }
          }
        );
      } catch (error) {
        return error.response;
      }
    },
    async changeAdminPassword() {
      if (this.pass.length && this.confirmPass.length) {
        if (this.pass === this.confirmPass) {
          if (
            this.pass.match(
              /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[\W|_]).{8,}$/
            )
          ) {
            try {
              return await api.put(
                `admin/change-password`,
                {
                  password: this.pass,
                  repeat_password: this.confirmPass
                },
                {
                  headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${this.$store.getters.token}`
                  }
                }
              );
            } catch (error) {
              return error.response;
            }
          } else {
            this.errorText += `• ${this.translation.errors_global.pwd_eight_characters} `;
          }
        } else {
          this.errorText += `• ${this.translation.errors_global.invalid_combination} `;
        }
      }
    },
    saveAccount() {
      if (!this.loaderSave) {
        if (this.name.length) {
          if (!this.hasSpecialCharacters) {
            const body = {
              name: this.name,
              picture: this.imgProfile
            };

            if (
              this.oldPass.length &&
              this.pass.length &&
              this.confirmPass.length
            ) {
              if (this.pass === this.confirmPass) {
                if (
                  this.pass.match(
                    /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[\W|_]).{8,}$/
                  )
                ) {
                  body.change_password = {
                    old_password: this.oldPass,
                    new_password: this.pass,
                    new_confirm_password: this.confirmPass
                  };
                  this.sendSaveRequest(body);
                } else {
                  this.$toast.error(
                    this.translation.errors_global.pwd_eight_characters
                  );
                }
              } else {
                this.$toast.error(
                  this.translation.errors_global.invalid_combination
                );
              }
            } else {
              this.sendSaveRequest(body);
            }
          } else {
            this.errorText += `• ${this.translation.account_settings.error.name_error} `;
          }
        } else {
          this.$toast.error(this.translation.errors_global.fill_fields);
        }
      }
    },
    sendSaveRequest(body) {
      this.loaderSave = true;

      const user =
        this.$store.getters.info.user.type === this.userTypesText.participant
          ? "participant"
          : "operator";

      api
        .put(`${user}/${this.$store.getters.info.user.sub}`, body, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${this.$store.getters.token}`
          }
        })
        .then(r => {
          if (r.status === 200) {
            this.$toast.success(this.translation.success_global.changes_saved);
            api
              .put(
                `token/refresh`,
                {},
                {
                  headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${this.$store.getters.token}`
                  }
                }
              )
              .then(respRefresh => {
                if (respRefresh.status === 200) {
                  const token = respRefresh.data.token;
                  try {
                    const info = jwt_decode(token);
                    this.$store.commit("UPDATE_IS_LOGOUT", false);
                    this.$store.dispatch("login", { token, info });
                  } catch (error) {
                    this.$store.dispatch("logOut");
                    this.$toast.error(
                      this.translation.errors_global.something_went_wrong
                    );
                  }
                  this.pass = "";
                  this.confirmPass = "";
                  this.oldPass = "";
                  this.loaderSave = false;
                }
              })
              .catch(() => {
                this.loaderSave = false;
              });
          }
        })
        .catch(error => {
          if (error.response.status === 422) {
            this.$toast.error(this.translation.account_settings.password_error);
          } else {
            this.$toast.error(
              this.translation.errors_global.something_went_wrong
            );
          }
          this.loaderSave = false;
        });
    }
  },
  created() {
    this.name = this.$store.getters.info.user.name;
    this.email = this.$store.getters.info.user.email;
    if (this.$store.getters.info.user.picture)
      this.imgProfile = this.$store.getters.info.user.picture;
  }
};
</script>

<style lang="scss" scoped>
.input {
  color: #6b6b6b;
  margin-bottom: 15px;
  width: 100%;
  label {
    font-size: 0.875rem;
  }
  input {
    border: 1px solid #e6e6e6;
    font-size: 1rem;
    border-radius: 33px;
    position: relative;
  }
}

.profile-pic {
  position: relative;
  height: 155px;
  width: 155px;
  border-radius: 50%;
  margin-top: 6px;
  cursor: pointer;
  background: url(../img/image.svg) no-repeat center;
  background-position-y: 20px;
  overflow: hidden;
  input {
    display: none;
  }
  .img {
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    height: 100%;
    img {
      object-fit: cover;
      width: 155px;
      height: 155px;
    }
  }
}

.save-btn {
  text-align: center;
  text-transform: uppercase;
  color: #6b6b6b;
  font-weight: bold;
  background: #ebf2f2;
  letter-spacing: 0.16em;
  border-radius: 17px;
  border: none;
  padding: 5px 20px;
  align-self: flex-end;
  margin-top: 3rem;
  min-width: 162px;
}

.edit-wrapper {
  max-width: 200px;
  cursor: pointer;
}

.edit-btn {
  position: relative;
  display: flex;
  justify-content: flex-end;
}

.close-editor {
  margin-top: 10px;
  margin-right: 10px;
  animation: grow 400ms ease-in-out forwards;
  position: absolute;
  right: 15px;
  bottom: -35px;
  &.newactivity {
    bottom: -10px;
    right: 0px;
  }
}

.edit-theme {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  p {
    text-transform: uppercase;
    color: #777470;
    font-weight: bold;
    font-size: 0.75rem;
    margin: 0 10px 0 0;
  }
  button {
    display: flex;
    justify-content: center;
    align-items: center;
    background: #fff;
    border: none;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    color: #6b6b6b;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);

    &:focus {
      outline: none;
    }
  }
}

h3 {
  font-size: 1.2rem;
  font-weight: bold;
  color: #6b6b6b;
}
</style>
