<template>
  <div>
    <b-modal
      @ok="editTask"
      :cancel-title="translation.global.cancel"
      id="editTask"
      :title="translation.global.edit"
    >
      {{ translation.create_task.edit_confirm_message }}
    </b-modal>
    <div class="py-4" v-if="task && !loaderTask">
      <div class="title-task">
        <p
          :class="{ active: task === addTaskText }"
          class="enter-animation"
          :style="{ color: $store.getters.theme[3] }"
        >
          {{ translation.create_task.add_task }}
        </p>
        <p
          :class="{ active: task !== addTaskText }"
          class="enter-animation"
          :style="{ color: $store.getters.theme[3] }"
        >
          {{ translation.global.edit }}
        </p>
      </div>

      <div class="task-editor-box">
        <div
          class="
            d-flex
            justify-content-between
            align-items-center
            flex-wrap
            mb-3
          "
        >
          <div class="d-flex align-items-center mt-2">
            <div class="task-circle">
              <b-form-input
                v-model="codeString"
                maxlength="10"
                :state="!isCodeValid && !!codeString.length"
                v-b-tooltip.hover="{ variant: 'secondary' }"
                :title="translation.create_task.error.code_validation"
              ></b-form-input>
            </div>
            <div>
              <dropdown
                dropId="taskType"
                :options="taskTypeOptions"
                :value="selectedType"
                :width="160"
                @update="data => (this.selectedType = data)"
              />
            </div>
          </div>
          <div class="d-flex align-items-center mt-2">
            <uploader />
            <whitespace
              v-if="selectedType.value === taskEnum.whitespace.id"
              :task="task"
              :taskText="taskText"
              @blank="blank = !blank"
              @update="obj => (taskObj = obj)"
              @error="msg => (validationError = msg)"
            />
          </div>
        </div>

        <div>
          <div
            :class="{ active: attachmentsMessage }"
            class="error-box bg-danger p-2 my-3"
          >
            <b-icon class="mr-1" icon="exclamation-triangle-fill"></b-icon>
            {{ attachmentsMessage }}
          </div>
          <div class="d-flex flex-wrap mb-2 mt-3">
            <attachment-list
              :attachments="attachmentsTask"
              :deleteFile="deleteTaskFile"
            />
            <attachment-list
              :attachments="attachmentsTmp"
              :deleteFile="deleteTmpFile"
            />
          </div>
          <editor
            :placeholder="translation.create_task.write_the_task"
            :blank="blank"
            :text="taskText"
            :maxCharacters="maxCharacters"
            @charactersCount="count => (charactersCount = count)"
            @update="text => (taskText = text)"
            @imageError="msg => (imageMessage = msg)"
            @videoError="msg => (videoMessage = msg)"
          />
          <transition mode="out-in">
            <text-task
              v-if="selectedType.value === taskEnum.textTask.id"
              :task="task"
              :taskText="taskText"
              @update="obj => (taskObj = obj)"
            />
            <survey-task
              v-if="selectedType.value === taskEnum.surveyTask.id"
              :task="task"
              :taskText="taskText"
              @update="obj => (taskObj = obj)"
              @error="msg => (validationError = msg)"
            />
            <matrix
              v-if="selectedType.value === taskEnum.matrix.id"
              :task="task"
              :taskText="taskText"
              @update="obj => (taskObj = obj)"
              @error="msg => (validationError = msg)"
            />
            <slider
              v-if="selectedType.value === taskEnum.slider.id"
              :task="task"
              :taskText="taskText"
              @update="obj => (taskObj = obj)"
              @error="msg => (validationError = msg)"
            />
            <ranking
              v-if="selectedType.value === taskEnum.ranking.id"
              :task="task"
              :taskText="taskText"
              @update="obj => (taskObj = obj)"
              @error="msg => (validationError = msg)"
            />
            <drag-and-drop
              v-if="selectedType.value === taskEnum.dragAndDrop.id"
              :task="task"
              :taskText="taskText"
              @update="obj => (taskObj = obj)"
              @error="msg => (validationError = msg)"
            />
            <image-task
              v-if="selectedType.value === taskEnum.imageTask.id"
              :task="task"
              :taskText="taskText"
              @update="obj => (taskObj = obj)"
            />
            <video-task
              v-if="selectedType.value === taskEnum.videoTask.id"
              :task="task"
              :taskText="taskText"
              @update="obj => (taskObj = obj)"
            />
            <attachment
              v-if="selectedType.value === taskEnum.attachment.id"
              :task="task"
              :taskText="taskText"
              @update="obj => (taskObj = obj)"
            />
            <reactions
              v-if="selectedType.value === taskEnum.reactions.id"
              :task="task"
              :taskText="taskText"
              @update="obj => (taskObj = obj)"
              @error="msg => (validationError = msg)"
            />
            <video-evaluation
              v-if="selectedType.value === taskEnum.videoEvaluation.id"
              :task="task"
              :taskText="taskText"
              @update="obj => (taskObj = obj)"
              @error="msg => (validationError = msg)"
            />
            <text-evaluation
              v-if="selectedType.value === taskEnum.textEvaluation.id"
              :task="task"
              :taskText="taskText"
              @update="obj => (taskObj = obj)"
              @error="msg => (validationError = msg)"
            />
            <multimedia
              v-if="selectedType.value === taskEnum.multimedia.id"
              :task="task"
              :taskText="taskText"
              @update="obj => (taskObj = obj)"
            />
            <screen-recording
              v-if="selectedType.value === taskEnum.screenRecording.id"
              :task="task"
              :taskText="taskText"
              @update="obj => (taskObj = obj)"
              @error="msg => (validationError = msg)"
            />
            <audio-task
              v-if="selectedType.value === taskEnum.audio.id"
              :task="task"
              :taskText="taskText"
              @update="obj => (taskObj = obj)"
              @error="msg => (validationError = msg)"
            />
          </transition>

          <div
            class="comment-description mt-3"
            v-if="
              selectedType.value === taskEnum.surveyTask.id ||
                selectedType.value === taskEnum.dragAndDrop.id ||
                selectedType.value === taskEnum.matrix.id ||
                selectedType.value === taskEnum.ranking.id ||
                selectedType.value === taskEnum.slider.id ||
                selectedType.value === taskEnum.imageTask.id ||
                selectedType.value === taskEnum.videoTask.id ||
                selectedType.value === taskEnum.attachment.id ||
                selectedType.value === taskEnum.reactions.id ||
                selectedType.value === taskEnum.videoEvaluation.id ||
                selectedType.value === taskEnum.textEvaluation.id ||
                selectedType.value === taskEnum.multimedia.id ||
                selectedType.value === taskEnum.screenRecording.id ||
                selectedType.value === taskEnum.audio.id
            "
          >
            <p>{{ translation.create_task.comment_description }}</p>
            <b-form-input
              style="height: 35px"
              size="md"
              id="commentDescription"
              v-model="commentDescription"
              :placeholder="
                translation.create_task.comment_description_placeholder
              "
            ></b-form-input>
          </div>

          <div
            class="comment-description-setup"
            v-if="selectedType.value !== taskEnum.whitespace.id"
          >
            <div
              v-if="selectedType.value !== taskEnum.textTask.id"
              class="d-flex"
            >
              <b-form-checkbox
                v-model="obligatoryComment"
                name="check-button"
                switch
              >
                {{ translation.answer_task.obligatory_comment }}
              </b-form-checkbox>
            </div>

            <div
              class="d-flex align-items-center comment-description-setup-item "
            >
              <b-form-checkbox
                v-model="hasWordLimitation"
                name="check-button"
                switch
              >
                {{ translation.answer_task.word_validation }}
              </b-form-checkbox>

              <div v-if="hasWordLimitation" class="inputsContainer">
                <div>
                  <label for="min-number-words">Min:</label>

                  <input
                    class="input-number"
                    v-model="minWordCount"
                    size="sm"
                    id="min-number-words"
                    type="number"
                    min="0"
                    :max="maxWordCount.toString()"
                    @blur="handleMinNumberWords"
                  />
                </div>

                <div>
                  <label for="max-number-words">Max:</label>

                  <input
                    class="input-number"
                    v-model="maxWordCount"
                    id="max-number-words"
                    type="number"
                    :min="minWordCount"
                    max="5000"
                    @blur="handleMaxNumberWords"
                  />
                </div>
              </div>
            </div>

            <div
              class="d-flex align-items-center comment-description-setup-item"
            >
              <b-form-checkbox
                v-model="hasCharactersLimitation"
                name="check-button"
                switch
              >
                {{ translation.answer_task.character_validation }}
              </b-form-checkbox>

              <div v-if="hasCharactersLimitation" class="inputsContainer">
                <div>
                  <label for="min-number-words">Min:</label>

                  <input
                    class="input-number"
                    v-model="minCharactersCount"
                    size="sm"
                    id="min-number-words"
                    type="number"
                    min="0"
                    :max="maxCharactersCount"
                    @blur="handleMinCharacters"
                  />
                </div>

                <div>
                  <label for="max-number-words">Max:</label>

                  <input
                    class="input-number"
                    v-model="maxCharactersCount"
                    id="max-number-words"
                    type="number"
                    :min="minCharactersCount"
                    max="2000"
                    @blur="handleMaxCharacters"
                  />
                </div>
              </div>
            </div>
          </div>

          <logic
            v-if="+activity.has_logic"
            :task="task"
            :codeString="codeString"
            :lastTask="tasks.length - 1"
            :clearLogic="clearLogic"
            :tasksGoTo="tasksGoTo"
            :taskObj="taskObj"
            :taskType="selectedType.value"
            :totalTasks="tasks.length"
            @setLogic="logic => (logicObj = logic)"
          />

          <div class="btns d-flex justify-content-end mt-4 mb-3">
            <button
              :class="{ active: task === addTaskText }"
              class="btn save enter-animation hover"
              @click="saveTask"
            >
              <div v-if="loaderSaveTask" class="d-flex justify-content-center">
                <loader2 color="#6b6b6b" />
              </div>
              <p v-else>
                {{ translation.global.save }}
              </p>
            </button>

            <button
              :class="{ active: task !== addTaskText }"
              class="btn save enter-animation hover"
              @click="openModalEdit"
            >
              <div v-if="loaderEditTask" class="d-flex justify-content-center">
                <loader2 color="#6b6b6b" />
              </div>
              <p v-else>
                {{ translation.global.save_alterations }}
              </p>
            </button>
          </div>
        </div>
      </div>
    </div>
    <div class="d-flex justify-content-center py-5" v-else-if="task">
      <loader />
    </div>
  </div>
</template>

<script>
import { v4 as uuidv4 } from "uuid";
import { api } from "@/services.js";
import dropdown from "@/components/general/dropdown.vue";
import uploader from "@/components/general/uploader.vue";
import editor from "@/components/general/editor.vue";
import loader from "@/components/general/loader.vue";
import loader2 from "@/components/general/loader2.vue";
import logic from "@/components/createtask/logic.vue";
import textTask from "@/components/createtask/textTask.vue";
import surveyTask from "@/components/createtask/surveyTask.vue";
import whitespace from "@/components/createtask/whitespace.vue";
import matrix from "@/components/createtask/matrix.vue";
import slider from "@/components/createtask/slider.vue";
import dragAndDrop from "@/components/createtask/dragAndDrop.vue";
import imageTask from "@/components/createtask/image.vue";
import videoTask from "@/components/createtask/video.vue";
import ranking from "@/components/createtask/ranking.vue";
import attachmentList from "@/components/general/attachment.vue";
import attachment from "@/components/createtask/attachment.vue";
import reactions from "@/components/createtask/reactions.vue";
import videoEvaluation from "@/components/createtask/videoEvaluation.vue";
import textEvaluation from "@/components/createtask/textEvaluation.vue";
import multimedia from "@/components/createtask/multimedia.vue";
import screenRecording from "@/components/createtask/screenRecording.vue";
import audioTask from "@/components/createtask/audioTask.vue";
import { encodeImage, encodeBlank, encodeVideo } from "@/helpers.js";
import { mapState } from "vuex";

import {
  taskEnum,
  inputHtml,
  addTaskText,
  regexpCodeString,
  regexpClearTags
} from "@/constants";

export default {
  props: {
    tasks: {
      required: true
    },
    task: {
      required: true
    },
    loaderTask: {
      required: true
    },
    activityId: {
      required: true
    },
    activity: {
      required: true
    },
    getTasks: {
      required: true
    },
    addTask: {
      required: true
    },
    getActivity: {
      required: true
    }
  },
  components: {
    dropdown,
    editor,
    loader,
    loader2,
    textTask,
    surveyTask,
    whitespace,
    matrix,
    slider,
    dragAndDrop,
    imageTask,
    videoTask,
    ranking,
    attachment,
    reactions,
    videoEvaluation,
    textEvaluation,
    multimedia,
    screenRecording,
    audioTask,
    attachmentList,
    uploader,
    logic
  },
  data() {
    return {
      taskEnum,
      selectedType: {
        value: taskEnum.textTask.id,
        text: taskEnum.textTask.text[this.langAbbreviation]
      },
      maxCharacters: 5000,
      taskText: "",
      taskDescription: "",
      taskObj: "",
      files: [],
      charactersCount: 0,
      blank: false,
      validationError: null,
      loaderSaveTask: false,
      loaderEditTask: false,
      attachmentsTmp: [],
      attachmentsTask: [],
      deleteFiles: [],
      totalAnswers: 0,
      videoMessage: null,
      imageMessage: null,
      commentDescription: "",
      logicObj: null,
      clearLogic: false,
      tasksGoTo: [],
      isSequenced: false,
      codeString: "",
      regexpClearTags,
      encodeBlank,
      encodeVideo,
      encodeImage,
      inputHtml,
      regexpCodeString,
      addTaskText,
      obligatoryComment: false,
      hasWordLimitation: false,
      minWordCount: 0,
      maxWordCount: 1,
      hasCharactersLimitation: false,
      minCharactersCount: 0,
      maxCharactersCount: 1
    };
  },
  computed: {
    isCodeValid() {
      return (
        this.codeString.match(this.regexpCodeString) || this.codeString === "0"
      );
    },
    ...mapState([
      "attachment",
      "imageUploaderFolder",
      "videoUploaderFolder",
      "attachmentUploaderFolder"
    ]),
    taskSelectedType() {
      return this.selectedType.value;
    },
    attachmentsCount() {
      if (this.attachmentsTmp && this.attachmentsTask)
        return this.attachmentsTmp.length + this.attachmentsTask.length;
      else return 0;
    },
    attachmentsMessage() {
      let msg = null;
      if (this.attachmentsCount > 5)
        msg = this.translation.errors_global.limit_exceeded_attachments;
      return msg;
    },
    taskTypeOptions() {
      return Object.values(this.taskEnum).map(type => ({
        value: type.id,
        text: type.text[this.langAbbreviation]
      }));
    }
  },
  watch: {
    taskSelectedType() {
      if (this.taskSelectedType !== this.taskEnum.whitespace.id)
        this.taskText = this.taskText.replace(/<input.*?>/g, "");

      this.validationError = "";
    },
    task() {
      if (this.task === this.addTaskText) {
        this.taskText = "";
        this.selectedType = this.taskTypeOptions[0];
        this.initImage(this.addTaskText);
        this.initVideo(this.addTaskText);
        this.initAttachment(this.addTaskText);
        this.attachmentsTask = [];
        this.commentDescription = "";
        this.clearLogic = !this.clearLogic;
        this.tasksGoTo = [];
        this.codeString = `T${this.tasks.length + 1}`;
      } else if (this.task) {
        const type = Object.values(this.taskEnum).filter(
          type => type.id === +this.task.type_id
        )[0];

        this.selectedType = {
          value: type.id,
          text: type.text[this.langAbbreviation]
        };
        const regexp = /{0327L-S2!@#\$\)\(%(.*?)%-\[(.*?)]!@#\$\)\(}/g;

        this.taskText = this.task.raw_question.replace(regexp, this.inputHtml);
        this.totalAnswers = this.tasks[this.task.index].total_answers;
        this.commentDescription = this.task.question_object.comment_label;
        this.obligatoryComment = this.task.question_object
          ?.has_obligatory_comments
          ? true
          : false;
        this.hasWordLimitation = this.task.question_object?.has_words_limitation
          ? true
          : false;
        this.minWordCount =
          this.task.question_object?.words_count?.minimum || 0;
        this.maxWordCount =
          this.task.question_object?.words_count?.maximum || 1;

        this.hasCharactersLimitation = this.task.question_object
          ?.has_characters_limitation
          ? true
          : false;
        this.minCharactersCount =
          this.task.question_object?.characters_count?.minimum || 0;
        this.maxCharactersCount =
          this.task.question_object?.characters_count?.maximum || 1;

        this.logicObj = this.task.logic;
        this.codeString = this.task.code_string;
        this.initImage("edit");
        this.initVideo("edit");
        this.initAttachment("edit");
        this.getAttachmentsTask();

        this.tasksGoTo = this.tasks.filter(
          task => task.index > this.task.index
        );
      }
    },
    attachment() {
      this.getAttachmentsTmp();
    },

    hasWordLimitation() {
      if (!this.hasWordLimitation) {
        this.minWordCount = 0;
        this.maxWordCount = 1;
      } else {
        this.hasCharactersLimitation = false;
      }
    },

    minWordCount() {
      if (+this.minWordCount > +this.maxWordCount) {
        this.minWordCount = +this.maxWordCount;

        return;
      }

      if (!this.minWordCount.toString().match(/^(?:\d+)?$/)) {
        this.minWordCount = 0;
      }
    },

    maxWordCount() {
      if (+this.maxWordCount > 2000) {
        this.maxWordCount = 2000;
      }

      if (!this.maxWordCount.toString().match(/^(?:\d+)?$/)) {
        this.maxWordCount = +this.minWordCount;
      }
    },

    hasCharactersLimitation() {
      if (!this.hasCharactersLimitation) {
        this.minCharactersCount = 0;
        this.maxCharactersCount = 1;
      } else {
        this.hasWordLimitation = false;
      }
    },

    minCharactersCount() {
      if (+this.minCharactersCount > +this.maxCharactersCount) {
        this.minCharactersCount = +this.maxCharactersCount;

        return;
      }

      if (!this.minCharactersCount.toString().match(/^(?:\d+)?$/)) {
        this.minCharactersCount = 0;
      }
    },

    maxCharactersCount() {
      if (+this.maxCharactersCount > 5000) {
        this.maxCharactersCount = 5000;
      }

      if (!this.maxCharactersCount.toString().match(/^(?:\d+)?$/)) {
        this.maxCharactersCount = +this.minCharactersCount;
      }
    }
  },
  methods: {
    handleMinNumberWords() {
      if (!this.minWordCount || this.minWordCount.toString() === "0") {
        this.minWordCount = 0;
      }
    },

    handleMaxNumberWords() {
      if (!this.maxWordCount || this.maxWordCount.toString() === "0") {
        this.maxWordCount = +this.minWordCount;
      }

      if (+this.maxWordCount < +this.minWordCount) {
        this.maxWordCount = +this.minWordCount;
      }
    },

    handleMinCharacters() {
      if (
        !this.minCharactersCount ||
        this.minCharactersCount.toString() === "0"
      ) {
        this.minCharactersCount = 0;
      }
    },

    handleMaxCharacters() {
      if (
        !this.maxCharactersCount ||
        this.maxCharactersCount.toString() === "0"
      ) {
        this.maxCharactersCount = +this.minCharactersCount;
      }

      if (+this.maxCharactersCount < +this.minCharactersCount) {
        this.maxCharactersCount = +this.minCharactersCount;
      }
    },

    deleteTmpFile(filename) {
      api
        .post(
          "s3",
          {
            item: `attachments/${this.attachmentUploaderFolder}/${filename}`
          },
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${this.$store.getters.token}`
            }
          }
        )
        .then(r => {
          if (r.status === 200) this.getAttachmentsTmp();
        });
    },
    deleteTaskFile(filename, index) {
      this.attachmentsTask.splice(index, 1);
      this.deleteFiles.push(filename);
    },
    getAttachmentsTask() {
      this.attachmentsTask = this.task.attachments;
      this.deleteFiles = [];
    },
    getAttachmentsTmp() {
      api
        .post(
          "task/s3/attachments",
          {
            folder: `${this.attachmentUploaderFolder}`
          },
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${this.$store.getters.token}`
            }
          }
        )
        .then(r => {
          this.attachmentsTmp = r.data;
        })
        .catch(() => {
          this.attachmentsTmp = [];
        });
    },
    getErrors() {
      let errorStr = "";

      errorStr += this.videoMessage ? `• ${this.videoMessage} ` : "";
      errorStr += this.imageMessage ? `• ${this.imageMessage} ` : "";
      errorStr += this.attachmentsMessage
        ? `• ${this.attachmentsMessage} `
        : "";

      errorStr += !this.taskObj.statement.length
        ? `• ${this.translation.create_task.error.error_empty_task} `
        : "";

      errorStr += !this.codeString.length
        ? `• ${this.translation.create_task.error.error_empty_code} `
        : this.isCodeValid
        ? `• ${this.translation.create_task.error.code_validation} `
        : "";

      errorStr +=
        this.charactersCount > this.maxCharacters
          ? `• ${this.translation.errors_global.you_have_exceeded} `
          : "";

      errorStr += this.validationError ? `• ${this.validationError} ` : "";

      return errorStr.length ? errorStr : null;
    },
    hasImage(text) {
      return text.match(/(?:(<img.*?src="))(.*?)((?:".*?\/>))/g);
    },
    hasVideo(text) {
      return text.match(/(?:(<iframe.*?src="))(.*?)(?:".*?>)/g);
    },
    saveTask() {
      if (!this.loaderSaveTask) {
        if (!this.getErrors()) {
          this.loaderSaveTask = true;
          const body = {
            activity_id: this.activityId,
            type_id: this.selectedType.value,
            code_string: this.codeString
          };

          let description = this.taskObj.statement;

          // codificar as imagem do editor
          if (this.hasImage(description)) {
            description = this.encodeImage(description);
            body.has_image = 1;
            body.image_folder = `tmp/images/${this.imageUploaderFolder}`;
          } else {
            body.has_image = 0;
          }

          // codificar os vídeos do editor
          if (this.hasVideo(description)) {
            description = this.encodeVideo(description, true);
            body.has_video = 1;
            body.video_folder = `tmp/video/${this.videoUploaderFolder}`;
          } else {
            body.has_video = 0;
          }

          //pegar os attachments da task
          if (this.attachmentsTmp.length) {
            body.has_attachment = 1;
            body.attachment_folder = `tmp/attachments/${this.attachmentUploaderFolder}`;
          } else {
            body.has_attachment = 0;
          }

          //codificar imagem dos cards das tasks de ranking e drag and drop
          if (
            this.selectedType.value === this.taskEnum.dragAndDrop.id ||
            this.selectedType.value === this.taskEnum.ranking.id
          ) {
            this.taskObj.cards = this.taskObj.cards.map((card, index) => {
              if (card.has_image) body.has_image = 1;
              return {
                ...card,
                value: {
                  text: card.value.text,
                  img: card.has_image
                    ? `:)*:-!image.@#$||%{${card.value.img}}-[${index}]!@#$||%-:*:(`
                    : "",
                  image_name: card.value.image_name
                }
              };
            });
          }

          //codificar espaços brancos da task de fill in the blank
          if (this.selectedType.value === this.taskEnum.whitespace.id) {
            description = this.encodeBlank(description);
          }

          //codificar imagem das task de reaction
          if (
            this.selectedType.value === this.taskEnum.reactions.id &&
            this.taskObj.image.match(/tmp\/images/g)
          ) {
            body.has_image = 1;
            this.taskObj.image = `:)*:-!image.@#$||%{${this.taskObj.image}}-[0]!@#$||%-:*:(`;
          }

          //codificar video das task de video evaluation
          if (
            this.selectedType.value === this.taskEnum.videoEvaluation.id &&
            this.taskObj.video_url.match(/tmp\/video/g)
          ) {
            body.has_video = 1;
            this.taskObj.video_url = `:)*:-!video.@#$||%{${this.taskObj.video_url}}-[0]!@#$||%-:*:(`;
          }

          //codificar video das task de video evaluation
          if (this.selectedType.value === this.taskEnum.videoEvaluation.id) {
            body.has_video = 1;
            this.taskObj.video_url = `:)*:-!video.@#$||%{${this.taskObj.video_url}}-[0]!@#$||%-:*:(`;
          }

          //remover tags de script
          body.raw_question = description.replace(/&lt;\/?script&gt;/g, "");

          //limpar HTML tags e pegar url das imagens e vídeos
          const regexpImage = /(?:(<img.*?src="))(.*?)((?:".*?\/>))/g;
          const regexpVideo = /(?:(<iframe.*?src="))(.*?)((?:".*?>))((?:<\/iframe>))?/g;
          body.question = {
            ...this.taskObj,
            statement: description
              .replace(regexpImage, "$2")
              .replace(regexpVideo, "$2")
              .replace(this.regexpClearTags, "")
          };

          //add descrição de comentário
          if (
            (this.selectedType.value === this.taskEnum.surveyTask.id ||
              this.selectedType.value === this.taskEnum.dragAndDrop.id ||
              this.selectedType.value === this.taskEnum.matrix.id ||
              this.selectedType.value === this.taskEnum.ranking.id ||
              this.selectedType.value === this.taskEnum.slider.id ||
              this.selectedType.value === this.taskEnum.imageTask.id ||
              this.selectedType.value === this.taskEnum.videoTask.id ||
              this.selectedType.value === this.taskEnum.attachment.id ||
              this.selectedType.value === this.taskEnum.reactions.id ||
              this.selectedType.value === this.taskEnum.videoEvaluation.id ||
              this.selectedType.value === this.taskEnum.textEvaluation.id ||
              this.selectedType.value === this.taskEnum.multimedia.id ||
              this.selectedType.value === this.taskEnum.screenRecording.id ||
              this.selectedType.value === this.taskEnum.audio.id) &&
            this.commentDescription.length
          ) {
            body.question.has_custom_comment_label = 1;
            body.question.comment_label = this.commentDescription;
          } else {
            body.question.has_custom_comment_label = 0;
            body.question.comment_label = "";
          }

          body.question.has_obligatory_comments = this.obligatoryComment
            ? 1
            : 0;

          if (this.selectedType.value !== this.taskEnum.whitespace.id) {
            body.question.has_words_limitation = this.hasWordLimitation ? 1 : 0;

            body.question.words_count = {
              minimum: +this.minWordCount,
              maximum: +this.maxWordCount
            };
          } else {
            body.question.has_words_limitation = 0;

            body.question.words_count = {
              minimum: 0,
              maximum: 0
            };
          }

          if (this.selectedType.value !== this.taskEnum.whitespace.id) {
            body.question.has_characters_limitation = this
              .hasCharactersLimitation
              ? 1
              : 0;

            body.question.characters_count = {
              minimum: +this.minCharactersCount,
              maximum: +this.maxCharactersCount
            };
          } else {
            body.question.has_characters_limitation = 0;

            body.question.characters_count = {
              minimum: 0,
              maximum: 0
            };
          }

          //Verificando se existe lógica
          if (this.logicObj) {
            body.logic = this.logicObj;
          }

          api
            .post("task", body, {
              headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${this.$store.getters.token}`
              }
            })
            .then(resp => {
              if (resp.status === 201) {
                this.getActivity();
                this.getTasks();
                this.addTask();
                this.taskText = "";
                this.taskDescription = "";
                this.$toast.success(
                  this.translation.create_task.success.task_successfully_created
                );
              }
              this.loaderSaveTask = false;
            })
            .catch(error => {
              if (error.response.status === 422) {
                this.$toast.error(
                  this.translation.create_task.error.code_string_already
                );
              } else {
                this.$toast.error(
                  this.translation.errors_global.something_went_wrong
                );
              }
              this.loaderSaveTask = false;
            });
        } else {
          this.$toast.error(this.getErrors());
        }
      }
    },
    editTask() {
      if (!this.loaderEditTask) {
        if (!this.getErrors()) {
          this.loaderEditTask = true;
          const body = {
            activity_id: this.activityId,
            type_id: this.selectedType.value,
            code_string: this.codeString
          };

          let description = this.taskObj.statement;

          // codificar as imagem do editor
          if (this.hasImage(description)) {
            description = this.encodeImage(description);
            body.has_image = 1;
            body.image_folder = `tmp/images/${this.imageUploaderFolder}`;
          } else {
            body.has_image = 0;
          }

          // codificar os vídeos do editor
          if (this.hasVideo(description)) {
            description = this.encodeVideo(description, true);
            body.has_video = 1;
            body.video_folder = `tmp/video/${this.videoUploaderFolder}`;
          } else {
            body.has_video = 0;
          }

          //pegar os attachments da task
          if (this.attachmentsCount) {
            body.has_attachment = 1;
            body.attachment_folder = `tmp/attachments/${this.attachmentUploaderFolder}`;
          } else {
            body.has_attachment = 0;
          }

          //codificar imagem dos cards das tasks de ranking e drag and drop
          if (
            this.selectedType.value === this.taskEnum.dragAndDrop.id ||
            this.selectedType.value === this.taskEnum.ranking.id
          ) {
            this.taskObj.cards = this.taskObj.cards.map((card, index) => {
              if (card.has_image) body.has_image = 1;
              return {
                ...card,
                value: {
                  text: card.value.text,
                  img:
                    card.has_image && card.value.img.match(/tmp\/images/g)
                      ? `:)*:-!image.@#$||%{${card.value.img}}-[${index}]!@#$||%-:*:(`
                      : card.value.img,
                  image_name: card.value.image_name
                }
              };
            });
          }

          //codificar espaços brancos da task de fill in the blank
          if (this.selectedType.value === this.taskEnum.whitespace.id) {
            description = this.encodeBlank(description);
          }

          //codificar imagem das task de reaction
          if (
            this.selectedType.value === this.taskEnum.reactions.id &&
            this.taskObj.image.match(/tmp\/images/g)
          ) {
            body.has_image = 1;
            this.taskObj.image = `:)*:-!image.@#$||%{${this.taskObj.image}}-[0]!@#$||%-:*:(`;
          }

          //codificar video das task de video evaluation
          if (
            this.selectedType.value === this.taskEnum.videoEvaluation.id &&
            this.taskObj.video_url.match(/tmp\/video/g)
          ) {
            body.has_video = 1;
            this.taskObj.video_url = `:)*:-!video.@#$||%{${this.taskObj.video_url}}-[0]!@#$||%-:*:(`;
          }

          //remover tags de script
          body.raw_question = description.replace(/&lt;\/?script&gt;/g, "");

          //limpar HTML tags e pegar url das imagens e vídeos
          const regexpImage = /(?:(<img.*?src="))(.*?)((?:".*?\/>))/g;
          const regexpVideo = /(?:(<iframe.*?src="))(.*?)((?:".*?>))((?:<\/iframe>))?/g;
          body.question = {
            ...this.taskObj,
            statement: description
              .replace(regexpImage, "$2")
              .replace(regexpVideo, "$2")
              .replace(this.regexpClearTags, "")
          };

          if (this.deleteFiles.length) this.deleteTaskFiles();

          //add descrição de comentário
          if (
            (this.selectedType.value === this.taskEnum.surveyTask.id ||
              this.selectedType.value === this.taskEnum.dragAndDrop.id ||
              this.selectedType.value === this.taskEnum.matrix.id ||
              this.selectedType.value === this.taskEnum.ranking.id ||
              this.selectedType.value === this.taskEnum.slider.id ||
              this.selectedType.value === this.taskEnum.imageTask.id ||
              this.selectedType.value === this.taskEnum.videoTask.id ||
              this.selectedType.value === this.taskEnum.attachment.id ||
              this.selectedType.value === this.taskEnum.reactions.id ||
              this.selectedType.value === this.taskEnum.videoEvaluation.id ||
              this.selectedType.value === this.taskEnum.textEvaluation.id ||
              this.selectedType.value === this.taskEnum.multimedia.id ||
              this.selectedType.value === this.taskEnum.screenRecording.id ||
              this.selectedType.value === this.taskEnum.audio.id) &&
            this.commentDescription.length
          ) {
            body.question.has_custom_comment_label = 1;
            body.question.comment_label = this.commentDescription;
          } else {
            body.question.has_custom_comment_label = 0;
            body.question.comment_label = "";
          }

          body.question.has_obligatory_comments = this.obligatoryComment
            ? 1
            : 0;

          if (this.selectedType.value !== this.taskEnum.whitespace.id) {
            body.question.has_words_limitation = this.hasWordLimitation ? 1 : 0;

            body.question.words_count = {
              minimum: this.hasWordLimitation ? +this.minWordCount : 0,
              maximum: this.hasWordLimitation ? +this.maxWordCount : 0
            };
          } else {
            body.question.has_words_limitation = 0;

            body.question.words_count = {
              minimum: 0,
              maximum: 0
            };
          }

          if (this.selectedType.value !== this.taskEnum.whitespace.id) {
            body.question.has_characters_limitation = this
              .hasCharactersLimitation
              ? 1
              : 0;

            body.question.characters_count = {
              minimum: this.hasCharactersLimitation
                ? +this.minCharactersCount
                : 0,
              maximum: this.hasCharactersLimitation
                ? +this.maxCharactersCount
                : 0
            };
          } else {
            body.question.has_characters_limitation = 0;

            body.question.characters_count = {
              minimum: 0,
              maximum: 0
            };
          }

          //Verificando se existe lógica
          if (this.logicObj) {
            body.logic = this.logicObj;
          }

          api
            .put(`task/${this.task.id}`, body, {
              headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${this.$store.getters.token}`
              }
            })
            .then(resp => {
              if (resp.status === 200) {
                this.getActivity();
                this.getTasks();
                this.$toast.success(
                  this.translation.success_global.changes_saved
                );
              }
              this.loaderEditTask = false;
            })
            .catch(error => {
              if (error.response.status === 422) {
                this.$toast.error(
                  this.translation.create_task.error.code_string_already
                );
              } else {
                this.$toast.error(
                  this.translation.errors_global.something_went_wrong
                );
              }
              this.loaderEditTask = false;
            });
        } else {
          this.$toast.error(this.getErrors());
        }
      }
    },
    deleteTaskFiles() {
      api.post(
        `task/s3/attachments/delete/${this.task.id}`,
        {
          objects: this.deleteFiles
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${this.$store.getters.token}`
          }
        }
      );
    },
    openModalEdit() {
      if (this.totalAnswers) this.$bvModal.show("editTask");
      else this.editTask();
    },
    initAttachment(type) {
      this.attachmentsTmp = [];
      if (type === this.addTaskText)
        this.$store.commit(
          "UPDATE_ATTACHMENT_UPLOADER_FOLDER",
          `create-task-attachment-${uuidv4()}`
        );
      else
        this.$store.commit(
          "UPDATE_ATTACHMENT_UPLOADER_FOLDER",
          `edit-task-attachment-${this.task.id}-${uuidv4()}`
        );
    },
    initImage(type) {
      if (type === this.addTaskText)
        this.$store.commit(
          "UPDATE_IMAGE_UPLOADER_FOLDER",
          `create-task-image-${uuidv4()}`
        );
      else
        this.$store.commit(
          "UPDATE_IMAGE_UPLOADER_FOLDER",
          `edit-task-image-${this.task.id}-${uuidv4()}`
        );
    },
    initVideo(type) {
      if (type === this.addTaskText)
        this.$store.commit(
          "UPDATE_VIDEO_UPLOADER_FOLDER",
          `create-task-video-${uuidv4()}`
        );
      else
        this.$store.commit(
          "UPDATE_VIDEO_UPLOADER_FOLDER",
          `edit-task-video-${this.task.id}-${uuidv4()}`
        );
    }
  },
  created() {
    this.isSequenced = this.activity.is_sequenced;
  }
};
</script>

<style lang="scss" scoped>
.title-task {
  font-weight: bold;
  font-size: 1.325rem;
}

.enter-animation {
  display: none;
  animation: enter 0.3s ease-in-out forwards;
  &.active {
    display: block;
  }
}

.task-editor-box {
  border: 1px solid #efefef;
  border-radius: 27px;
  padding: 10px;
}

.task-circle {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 27px;
  font-weight: bold;
  background: #efefef;
  padding: 3px 15px;
  color: #6b6b6b;
  input {
    background: none;
    width: 50px;
    color: #6b6b6b;
    border-radius: 0;
    border-top: none;
    border-left: none;
    border-right: none;
    height: 20px;
    margin-right: 5px;
    padding-right: 5px !important;
    padding-left: 5px;
    &:focus {
      outline: none;
      box-shadow: none;
    }
  }
}

.comment-description {
  color: #6b6b6b;
  width: 250px;
  p {
    margin-bottom: 5px;
  }
}

.btns {
  button {
    display: none;
    min-width: 120px;
    height: 30px;
    border-radius: 17px;
    letter-spacing: 0.13em;
    text-transform: uppercase;
    color: #6b6b6b;
    font-size: 0.8rem;
    font-weight: bold;
    background: #ebf2f2;
    &.active {
      display: block;
    }
  }
}

.btn-preview {
  color: #6b6b6b;
  font-weight: bold;
  text-decoration: underline;
}

.comment-description-setup {
  display: flex;
  flex-direction: column;
  gap: 10px;

  margin-top: 16px;

  color: #6b6b6b;
}

.comment-description-setup-item {
  @media (max-width: 430px) {
    flex-direction: column;
    align-items: flex-start !important;
    gap: 20px;
  }
}

.inputsContainer {
  display: flex;
  align-items: center;
  gap: 20px;

  margin-left: 20px;

  color: #6b6b6b;

  div {
    display: flex;
    align-items: center;
    gap: 10px;

    label {
      margin: 0;
    }

    input {
      width: 75px;
    }
  }

  @media (max-width: 560px) {
    flex-direction: column;
  }
}

.input-number {
  padding: 2.5px 8px;

  font-size: 16px;
  color: #495057;

  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;

  &:focus {
    border-color: #80bdff;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
  }
}
</style>
