var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"row task-wrapper",attrs:{"id":"task"}},[(!_vm.loading)?_c('div',{staticClass:"col"},[_c('div',{staticClass:"task-text img-question"},[_c('p',{domProps:{"innerHTML":_vm._s(_vm.text)}})]),_c('div',{staticClass:"d-flex flex-wrap mb-3"},[_c('attachment',{attrs:{"attachments":_vm.task.attachments,"deleteFile":null}})],1),_c('h2',{staticClass:"title mb-3"},[_vm._v(_vm._s(_vm.translation.task.upload_files)+":")]),_c('div',{staticClass:"dropzone-wrapper"},[(_vm.disabled)?_c('div',{staticClass:"disabled"}):_vm._e(),_c('dropzone-multimedia-task',{on:{"files":function (f) { return (_vm.files = f); }}})],1),(_vm.files && _vm.files.length && _vm.hasDescription)?_c('div',{staticClass:"pt-4"},[_c('h2',{staticClass:"title mb-3"},[_vm._v(_vm._s(_vm.translation.task.add_captions_files)+":")]),_vm._l((_vm.files),function(media,index){return _c('div',{key:index,staticClass:"pb-4"},[_c('div',[_c('div',{staticClass:"media-wrapper"},[_c('p',[_vm._v(_vm._s(_vm.translation.task.multimedia_type)+" "+_vm._s(index + 1)+":")]),(_vm.isImage(media, 'files'))?_c('img',{attrs:{"src":media.url,"alt":""}}):_c('video',{attrs:{"controlsList":_vm.$store.getters.info.user.type !== _vm.userTypesText.admin &&
                _vm.$store.getters.info.user.type !== _vm.userTypesText.moderator
                  ? 'nodownload'
                  : '',"controls":"","src":media.url}})]),_c('b-form-input',{staticClass:"mt-3",attrs:{"size":"sm","placeholder":_vm.translation.task.caption,"disabled":_vm.task.is_answered,"maxlength":"100"},model:{value:(media.description),callback:function ($$v) {_vm.$set(media, "description", $$v)},expression:"media.description"}}),_c('character-counter',{attrs:{"count":media.description.length,"max":100}})],1)])})],2):(_vm.files && _vm.files.length && _vm.task.is_answered)?_c('div',{staticClass:"d-flex flex-wrap pt-4"},_vm._l((_vm.files),function(media,index){return _c('div',{key:index,staticClass:"multimedia-wrapper"},[(_vm.isImage(media))?_c('img',{attrs:{"src":media.url,"alt":""}}):_c('video',{attrs:{"width":"100%","height":"100%","controlsList":_vm.$store.getters.info.user.type !== _vm.userTypesText.admin &&
            _vm.$store.getters.info.user.type !== _vm.userTypesText.moderator
              ? 'nodownload'
              : '',"controls":"","src":media.url}})])}),0):_vm._e(),_c('div',{staticClass:"comments mt-4"},[(
          _vm.task.question_object.has_custom_comment_label &&
            _vm.task.question_object.comment_label &&
            _vm.task.question_object.comment_label.length
        )?_c('label',{attrs:{"for":"comments"}},[_vm._v(_vm._s(_vm.task.question_object.comment_label))]):_c('label',{attrs:{"for":"comments"}},[_vm._v(" "+_vm._s(_vm.translation.answer_task.comments)+" ")]),_c('textarea',{directives:[{name:"model",rawName:"v-model",value:(_vm.comments),expression:"comments"}],staticClass:"form-control",attrs:{"id":"textarea","maxlength":_vm.maxCharacters,"rows":"3","max-rows":"6","disabled":_vm.task.is_answered},domProps:{"value":(_vm.comments)},on:{"input":function($event){if($event.target.composing){ return; }_vm.comments=$event.target.value}}}),_c('characterAndWordCounter',{attrs:{"count":_vm.hasWordsLimitation
            ? this.comments.length
              ? this.comments.trim().split(/\s+/).length
              : 0
            : _vm.comments.length,"max":_vm.hasWordsLimitation ? _vm.maxWords : _vm.maxCharacters,"label":_vm.hasWordsLimitation
            ? _vm.translation.answer_task.words
            : _vm.translation.answer_task.characters}})],1)]):_vm._e(),(_vm.loading)?_c('div',{staticClass:"w-100 d-flex justify-content-center"},[_c('loader')],1):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }