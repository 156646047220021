<template>
  <div class="container">
    <div class="row py-5">
      <div class="col">
        <div v-if="chart && !loader" class="mx-md-5">
          <div class="chart-wrapper">
            <image-evaluation-charts
              v-if="chart.config.isHeatmap"
              :chartData="chart"
              :id="`${chart.taskId}${chart.activityId}`"
            />
            <video-evaluation-charts
              v-else-if="chart.config.isBubble"
              :chartData="chart"
              :id="`${chart.taskId}${chart.activityId}`"
            />
            <word-cloud
              v-else-if="chart.config.isWordcloud"
              :chartData="chart"
              :id="`${chart.taskId}${chart.activityId}`"
            />
            <chart
              v-else
              :chartData="chart"
              :id="`${chart.taskId}${chart.activityId}`"
            />
          </div>
        </div>
        <div v-else-if="loader" class="d-flex justify-content-center py-5">
          <loader />
        </div>
        <div v-else class="no-data">
          No data
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import videoEvaluationCharts from "@/components/charts/videoEvaluationCharts.vue";
import imageEvaluationCharts from "@/components/charts/imageEvaluationCharts.vue";
import wordCloud from "@/components/charts/wordCloud.vue";
import chart from "@/components/charts/chart.vue";
import loader from "@/components/general/loader.vue";

export default {
  props: {
    activity: {
      required: true
    },
    activeTask: {
      required: true
    }
  },
  components: {
    wordCloud,
    chart,
    videoEvaluationCharts,
    imageEvaluationCharts,
    loader
  },
  data() {
    return {
      chart: null,
      loader: false
    };
  },
  watch: {
    activeTask() {
      this.getChart();
    }
  },
  methods: {
    getChart() {
      this.loader = true;
      axios
        .get(
          `${this.exportChartHost}get-one?taskId=${this.activeTask.id}&activityId=${this.activity.id}`,
          {
            headers: {
              Authorization: `Bearer ${this.$store.getters.token}`
            }
          }
        )
        .then(r => {
          if (r.status === 200) {
            this.chart = r.data;
          }
          this.loader = false;
        })
        .catch(() => {
          this.loader = false;
        });
    }
  },
  created() {
    this.getChart();
  }
};
</script>

<style lang="scss" scoped>
.chart-wrapper {
  padding: 20px;
  border: 1px solid #c4c4c4;
  border-radius: 11px;
  margin-bottom: 30px;
}

.no-data {
  text-align: center;
  color: #6b6b6b;
}
</style>
