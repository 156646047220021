<template>
  <div>
    <div v-if="answer.answer_object.files">
      <div
        v-for="(image, index) in answer.answer_object.files"
        :key="index"
        class="pb-3"
      >
        <div>
          <img v-if="image.url" :src="image.url" />
          <p class="mt-1">
            {{ image.description }}
          </p>
        </div>
      </div>
    </div>
    <div
      v-else
      v-for="(image, index) in answer.answer_object.images"
      :key="index"
    >
      <div class="pb-3">
        <img v-if="image.URL" :src="image.URL" />
      </div>
    </div>
    <p
      v-if="
        answer.answer_object &&
          answer.answer_object.comment &&
          answer.answer_object.comment.length
      "
    >
      <span :style="{ color: $store.getters.theme[3] }" class="comments">
        {{ translation.answer_task.comments }}
      </span>
      {{ answer.answer_object.comment }}
    </p>
  </div>
</template>

<script>
export default {
  props: {
    answer: {
      required: true
    }
  }
};
</script>

<style lang="scss" scoped>
.comments {
  margin-top: 20px;
  display: block;
  text-transform: uppercase;
  font-weight: bold;
}
</style>
