<template>
  <form class="dropzone">
    <!-- Not displayed, just for Dropzone's `dictDefaultMessage` option -->
    <div id="dropzone-message" style="display: none">
      <span class="dropzone-title">
        {{ translation.dropzone.video }}
      </span>
    </div>
  </form>
</template>

<script>
import { api } from "@/services.js";
import Dropzone from "dropzone";
import "../../../node_modules/dropzone/dist/dropzone.css";
import lambda from "@/lambda";
import { regexpImageName } from "@/constants";
import { mapState } from "vuex";
import { v4 as uuidv4 } from "uuid";

Dropzone.autoDiscover = false;
export default {
  name: "dropzone",
  props: {
    clearFiles: {
      required: true
    }
  },
  data() {
    return {
      dropzone: null
    };
  },
  computed: {
    ...mapState(["videoEvaluationTaskFolder", "fileName"])
  },
  watch: {
    clearFiles() {
      this.dropzone.removeAllFiles();
    }
  },
  mounted() {
    const vm = this;
    let options = {
      // File size
      maxFilesize: 5000,
      maxFiles: 1,
      // Chunk config
      chunking: true,
      forceChunking: true,
      parallelChunkUploads: true,
      retryChunks: true,
      retryChunksLimit: 3,
      chunkSize: 150 * 1024 * 1024, // 150mb
      // Upload one file at a time since we're using the S3 pre-signed URL scenario
      parallelUploads: 1,
      uploadMultiple: false,
      // The URL will be changed for each new file being processing
      url: "/",
      // Since we're going to do a `PUT` upload to S3 directly
      method: "put",
      // Hijack the xhr.send since Dropzone always upload file by using formData
      // ref: https://github.com/danialfarid/ng-file-upload/issues/743
      sending(file, xhr) {
        let _send = xhr.send;
        xhr.send = () => {
          _send.call(xhr, file);
        };
      },
      // Content-Type should be included, otherwise you'll get a signature
      // mismatch error from S3. We're going to update this for each file.
      header: "",
      // Customize the wording
      // We're going to process each file manually (see `accept` below)
      autoProcessQueue: false,
      // Here we request a signed upload URL when a file being accepted,
      addRemoveLinks: true,
      acceptedFiles: "video/mp4,video/x-m4v,video/*",
      dictMaxFilesExceeded: this.translation.dropzone.can_not_upload_files,
      dictDefaultMessage: document.querySelector("#dropzone-message").innerHTML,
      dictFallbackMessage: this.translation.dropzone
        .your_browser_does_not_support,
      dictFallbackText: this.translation.dropzone.please_use_the_fallback,
      dictFileTooBig: this.translation.dropzone.file_is_too_big,
      dictInvalidFileType: this.translation.dropzone.you_cant_upload,
      dictResponseError: this.translation.dropzone
        .server_responded_with_status_code,
      dictCancelUpload: this.translation.dropzone.cancel_upload,
      dictCancelUploadConfirmation: this.translation.dropzone
        .are_you_sure_cancel,
      dictRemoveFile: this.translation.dropzone.remove_file,
      removedfile(file) {
        api.post(
          "s3",
          {
            item: `video/${vm.videoEvaluationTaskFolder}/${vm.fileName}`
          },
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${vm.$store.getters.token}`
            }
          }
        );

        file.previewElement.remove();
      },
      accept(file, done) {
        const preview = file.previewElement;
        const removeButton = Array.from(preview.childNodes).find(
          node => node.classList && node.classList.contains("dz-remove")
        );
        removeButton.style.display = "none";
        vm.$store.commit(
          "UPDATE_FILE_NAME",
          `${uuidv4()}-${file.name.replace(regexpImageName, "")}`
        );

        lambda
          .getSignedURL(
            file,
            `tmp/video/${vm.videoEvaluationTaskFolder}`,
            vm.fileName
          )
          .then(url => {
            file.uploadURL = url;
            done();
            // Manually process each file
            setTimeout(() => {
              vm.dropzone.processFile(file);
            });
          })
          .catch(err => {
            done("Failed to get an S3 signed upload URL", err);
          });
      },
      complete(file) {
        const preview = file.previewElement;
        const removeButton = Array.from(preview.childNodes).find(
          node => node.classList && node.classList.contains("dz-remove")
        );
        removeButton.style.display = "block";
      },
      success(file) {
        file.previewElement.children[4].style.opacity = 1;
        file.previewElement.style.marginBottom = "40px";
        const span = document.createElement("span");
        span.innerText = vm.translation.dropzone.file_uploaded_successfully;
        span.style.position = "absolute";
        span.style.textAlign = "center";
        span.style.width = "100%";
        span.style.color = "#10c398";
        span.style.fontWeight = "bold";
        span.style.fontSize = "0.8rem";

        file.previewElement.appendChild(span);
      },
      error(file, response) {
        file.previewElement.children[5].style.opacity = 1;
        file.previewElement.style.marginBottom = "60px";
        const span = document.createElement("span");
        span.innerText = response;
        span.style.position = "absolute";
        span.style.textAlign = "center";
        span.style.width = "100%";
        span.style.color = "#df2938";
        span.style.fontWeight = "bold";
        span.style.fontSize = "0.8rem";

        file.previewElement.appendChild(span);

        const errorMessage = `
        <br>Error response: ${response}
        <br>File size(Bytes): ${file.size}
        <br>Progress: ${file.upload.progress}
        <br>UploadUrl: ${file.uploadURL}
        <br>Format: ${file.type}`;
        api.post(
          "/answer/send-error-mail",
          { error: errorMessage },
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${vm.$store.getters.token}`
            }
          }
        );
      }
    };
    // Instantiate Dropzone
    this.dropzone = new Dropzone(this.$el, options);
    // Set signed upload URL for each file
    vm.dropzone.on("processing", file => {
      vm.dropzone.options.url = file.uploadURL;
    });

    vm.dropzone.on("complete", file => {
      vm.$emit("videoUrl", file.uploadURL.split("?")[0]);
    });
  }
};
</script>

<style lang="scss" scoped>
form.dropzone {
  transition: all 0.2s linear;
  border: 2px dashed #ccc;
  background-color: #fafafa;
  min-height: initial;
  &:hover {
    border-color: #3498db;
    background-color: white;
    .dz-message,
    .dropzone-title {
      color: primaryBlue;
    }
  }
  .dz-message {
    color: #666;
    span {
      line-height: 1.8;
      font-size: 13px;
      letter-spacing: 0.4px;
      span.dropzone-title {
        display: block;
        color: #888;
        font-size: 1.25em;
      }
      span.dropzone-info {
        display: block;
        color: #a8a8a8;
      }
    }
  }
}
</style>
