import { render, staticRenderFns } from "./characterCounter.vue?vue&type=template&id=0af7be25&scoped=true&"
import script from "./characterCounter.vue?vue&type=script&lang=js&"
export * from "./characterCounter.vue?vue&type=script&lang=js&"
import style0 from "./characterCounter.vue?vue&type=style&index=0&id=0af7be25&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0af7be25",
  null
  
)

export default component.exports