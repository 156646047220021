var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"id":_vm.mobile ? 'box-mobile' : 'box'}},[_c('div',{staticClass:"bell-notification ml-3 mr-4",on:{"click":_vm.handleClick}},[_c('img',{class:{ active: !_vm.show },attrs:{"src":require("@/img/bell.svg")}}),_c('img',{class:{ active: _vm.show },attrs:{"src":require("@/img/bell-blue.svg")}}),(_vm.count > 0)?_c('div',{class:{ countNotifications: _vm.count > 99 }},[_vm._v(" "+_vm._s(_vm.count)+" ")]):_vm._e()]),_c('div',{staticClass:"wrapper",class:{ active: _vm.show, mobile: _vm.mobile }},[_c('div',{staticClass:"card-b py-4"},[_c('h2',{staticClass:"h5 font-weight-bold mb-0 pb-0 px-3",style:({ color: _vm.$store.getters.theme[3] })},[_vm._v(" "+_vm._s(_vm.translation.notifications.notifications)+" ")]),(_vm.notifications.length)?_c('div',{staticClass:"box-notification mt-3"},_vm._l((_vm.notifications),function(notification,index){return _c('div',{key:index,staticClass:"item px-3 py-2",on:{"mouseover":_vm.mouseOver,"mouseleave":_vm.mouseLeave}},[_c('div',{on:{"click":function($event){return _vm.markNotification(notification)}}},[_c('div',{staticClass:"d-flex align-items-center"},[_c('div',{staticClass:"notification-pic"},[(notification.originUser.picture)?_c('img',{attrs:{"src":notification.originUser.picture,"alt":"Picture"}}):_c('b-icon',{staticClass:"icon-profile",attrs:{"icon":"person-fill","aria-hidden":"true"}})],1),_c('div',[(
                    +notification.triggeredBy.id ===
                      _vm.notificationTypes.subReplies
                  )?_c('sub-replies',{attrs:{"notification":notification}}):_vm._e(),(
                    +notification.triggeredBy.id ===
                      _vm.notificationTypes.forumNewPost
                  )?_c('forum-new-post',{attrs:{"notification":notification}}):_vm._e(),(
                    +notification.triggeredBy.id ===
                      _vm.notificationTypes.forumNewPostComment
                  )?_c('forum-new-post-comment',{attrs:{"notification":notification}}):_vm._e(),(
                    +notification.triggeredBy.id ===
                      _vm.notificationTypes.forumCommentOfComment
                  )?_c('forum-comment-of-comment',{attrs:{"notification":notification}}):_vm._e(),(
                    +notification.triggeredBy.id ===
                      _vm.notificationTypes.forumLikePost
                  )?_c('forum-like-post',{attrs:{"notification":notification}}):_vm._e(),(
                    +notification.triggeredBy.id ===
                      _vm.notificationTypes.forumLikeComment
                  )?_c('forum-like-comment',{attrs:{"notification":notification}}):_vm._e(),(
                    +notification.triggeredBy.id ===
                      _vm.notificationTypes.answerRejected
                  )?_c('answer-rejected',{attrs:{"notification":notification}}):_vm._e(),(
                    +notification.triggeredBy.id ===
                      _vm.notificationTypes.newAssignmentAvailable
                  )?_c('new-assignment-available',{attrs:{"notification":notification}}):_vm._e(),(
                    +notification.triggeredBy.id ===
                      _vm.notificationTypes.forumCommentRejected
                  )?_c('forum-comment-rejected',{attrs:{"notification":notification}}):_vm._e(),(
                    +notification.triggeredBy.id ===
                      _vm.notificationTypes.assignmentUpdated
                  )?_c('assignment-updated',{attrs:{"notification":notification}}):_vm._e(),(
                    +notification.triggeredBy.id ===
                      _vm.notificationTypes.createdCommunity
                  )?_c('created-community',{attrs:{"notification":notification}}):_vm._e(),(
                    +notification.triggeredBy.id ===
                      _vm.notificationTypes.termsAndConditions
                  )?_c('terms-and-conditions',{attrs:{"notification":notification}}):_vm._e()],1)]),_c('div',{staticClass:"blue-ball",style:({
                'background-color':
                  +notification.notificate === 1
                    ? _vm.$store.getters.theme[2]
                    : '#e6e6e6'
              })})])])}),0):_c('div',{staticClass:"d-flex justify-content-center py-5"},[_vm._v(" "+_vm._s(_vm.translation.notifications.no_notifications)+" ")])])])])}
var staticRenderFns = []

export { render, staticRenderFns }