<template>
  <div
    :class="{ active: showTaskStats === activity.id }"
    class="task-stats px-4 pb-2 pt-4"
  >
    <div class="table-wrapper" v-if="taskStats.length && !loaderTaskStats">
      <div class="table">
        <div
          :style="{ color: $store.getters.theme[3] }"
          class="table-header pb-1"
        >
          <div class="d-flex align-items-center" v-if="!showExternalId">
            ID
            <button
              :style="{ background: $store.getters.theme[2] }"
              class="btn btn-sm btn-id hover"
              v-b-tooltip.hover="{ variant: 'secondary' }"
              :title="translation.replies.dashboard.external_id"
              @click="showExternalId = 1"
            >
              <b-icon icon="arrow-left-right" aria-hidden="true"></b-icon>
            </button>
          </div>
          <div class="d-flex align-items-center" v-if="showExternalId">
            {{ translation.replies.dashboard.external_id }}
            <button
              :style="{ background: $store.getters.theme[2] }"
              class="btn btn-sm btn-id hover"
              v-b-tooltip.hover="{ variant: 'secondary' }"
              title="ID"
              @click="showExternalId = 0"
            >
              <b-icon icon="arrow-left-right" aria-hidden="true"></b-icon>
            </button>
          </div>
          <div class="lg d-flex align-items-center">
            {{ translation.replies.dashboard.name }}
          </div>
          <div class="d-flex align-items-center">
            {{ translation.replies.dashboard.general_status }}
          </div>
          <div
            class="sm d-flex align-items-center"
            v-for="task in tasksIndex"
            :key="task.index"
          >
            {{ task.code_string ? task.code_string : `T${+task.index + 1}` }}
          </div>
        </div>
        <div v-for="participant in taskStats" :key="participant.participant.id">
          <div class="table-content py-1">
            <div v-if="!showExternalId">
              {{ participant.participant.id }}
            </div>
            <div v-if="showExternalId">
              {{ participant.participant.external_id }}
            </div>
            <div
              @click="goToParticipant(participant.participant)"
              class="lg pointer"
            >
              {{ participant.participant.name }}
            </div>

            <div>
              <div
                v-if="participant.activity_status === statusDashboard.completed"
              >
                {{ translation.replies.dashboard.completed }}
              </div>
              <div
                v-if="
                  participant.activity_status === statusDashboard.incomplete
                "
              >
                {{ translation.replies.dashboard.incomplete }}
              </div>
              <div
                v-if="
                  participant.activity_status === statusDashboard.notStarted
                "
              >
                {{ translation.replies.dashboard.not_started }}
              </div>
            </div>

            <div class="sm" v-for="task in participant.tasks" :key="task.index">
              <span
                :class="{ green: +task.is_completed }"
                class="circle"
              ></span>
            </div>
          </div>
        </div>
      </div>
      <div class="d-flex justify-content-center">
        <button
          v-if="total > taskStats.length"
          :style="{ color: $store.getters.theme[3] }"
          class="btn-load-more hover"
          @click="loadMore"
        >
          {{ translation.global.load_more }}...
        </button>
      </div>
    </div>
    <div v-else-if="loaderTaskStats" class="d-flex justify-content-center py-5">
      <loader />
    </div>
    <div v-else class="d-flex justify-content-center pb-2 no-data">
      {{ translation.replies.dashboard.no_participants }}
    </div>
  </div>
</template>

<script>
import { api } from "@/services.js";
import loader from "@/components/general/loader.vue";
import { statusDashboard } from "@/constants";

export default {
  components: { loader },
  props: {
    showTaskStats: {
      required: true
    },
    activity: {
      required: true
    }
  },
  data() {
    return {
      perPage: 15,
      page: 1,
      total: 0,
      taskStats: [],
      tasksIndex: [],
      loaderTaskStats: false,
      showExternalId: 0,
      statusDashboard
    };
  },
  methods: {
    goToParticipant(user) {
      this.$router.push({
        name: "participant",
        query: { _id: user.id, _page: 1 }
      });
    },
    loadMore() {
      this.page++;

      this.getTaskStats();
    },
    getTaskStats() {
      this.loaderTaskStats = true;
      api
        .get(
          `activity/${this.activity.id}/list-task-status?offset=0&limit=${this
            .perPage * this.page}`,
          {
            headers: {
              Authorization: `Bearer ${this.$store.getters.token}`
            }
          }
        )
        .then(r => {
          if (r.status === 200) {
            this.taskStats = r.data.participants;
            this.total = r.data.participants_total;

            if (this.taskStats.length) {
              this.tasksIndex = this.taskStats[0].tasks;
            }
          }
          this.loaderTaskStats = false;
        })
        .catch(() => {
          this.loaderTaskStats = false;
        });
    }
  },
  created() {
    this.getTaskStats();
  }
};
</script>

<style lang="scss" scoped>
.task-stats {
  display: none;
  animation: show 0.3s linear;
  &.active {
    display: block;
  }
}

.table-wrapper {
  overflow-x: auto;
  padding-bottom: 20px;
  &::-webkit-scrollbar {
    height: 8px;
    overflow: hidden;
  }
  &::-webkit-scrollbar-track {
    background: rgba(0, 0, 0, 0);
    border-radius: 10px;
  }
  &::-webkit-scrollbar-thumb {
    background: #ccc;
    border-radius: 10px;
    height: 10px;
  }
  &::-webkit-scrollbar-thumb:hover {
    background: #aaa;
  }
}

.table {
  width: 100%;
}

.table-header {
  min-width: 100%;
  display: flex;
  width: fit-content;
  border-bottom: 1px solid #999999;
  div {
    min-width: 140px;
    flex: 1;
    display: flex;
    justify-content: center;
    font-weight: bold;
    font-size: 0.9rem;
    &.lg {
      min-width: 200px;
    }
    &.sm {
      min-width: 50px;
    }
  }
}

.table-content {
  min-width: 100%;
  width: fit-content;
  display: flex;
  border-bottom: 1px solid #999999;
  div {
    min-width: 140px;
    flex: 1;
    display: flex;
    justify-content: center;
    color: #6b6b6b;
    font-size: 0.9rem;
    &.lg {
      min-width: 200px;
    }
    &.sm {
      min-width: 50px;
    }
    .circle {
      width: 15px;
      height: 15px;
      background: #c4c4c4;
      border-radius: 50%;
      margin-top: 3px;
      &.green {
        background: #2ac230;
      }
    }
  }
}

.btn-load-more {
  font-weight: bold;
  border: none;
  background: none;
  &:focus {
    outline: none;
  }
}

.no-data {
  color: #6b6b6b;
}

.btn-id {
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  border-radius: 8px;
  width: 25px;
  height: 25px;
  font-size: 0.6rem;
  margin-left: 10px;
}
</style>
