<template>
  <div class="col-md-6">
    <div class="user">
      <user-profile @userType="type => (userType = type)" />
      <user-list
        :userType="userType"
        v-if="userType && !showTransmissionList"
      />
      <transmissions v-if="userType && showTransmissionList" />
    </div>
  </div>
</template>

<script>
import userProfile from "@/components/chat/userProfile.vue";
import userList from "@/components/chat/userList.vue";
import transmissions from "@/components/chat/transmissions.vue";
import { mapState } from "vuex";

export default {
  components: {
    userProfile,
    userList,
    transmissions
  },
  computed: {
    ...mapState(["showTransmissionList"])
  },
  data() {
    return {
      userType: null
    };
  }
};
</script>

<style>
.user {
  display: flex;
  width: 100%;
  position: relative;
}
</style>
