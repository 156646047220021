var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"row"},[_c('div',{staticClass:"col"},[_c('search',{staticClass:"mt-5"}),(
        !_vm.loaderParticipants && !_vm.loaderSegments && _vm.participantsPages.length
      )?_c('div',[_c('div',{staticClass:"table-wrapper",class:{ 'is-editable': _vm.isEditable }},[_c('div',{staticClass:"table mt-4"},[_c('div',{staticClass:"table-header",style:({ color: _vm.$store.getters.theme[3] })},[_c('div',[_vm._v("Voices ID")]),_c('div',{staticClass:"md"},[_vm._v(" "+_vm._s(_vm.translation.manage_participants.external_id)+" ")]),_c('div',{staticClass:"md"},[_vm._v(_vm._s(_vm.translation.manage_participants.name))]),(
                _vm.$store.getters.info.user.type === _vm.userTypesText.admin ||
                  (_vm.$store.getters.info.user.type ===
                    _vm.userTypesText.moderator &&
                    !+_vm.privacy.moderators.participants_hide_email)
              )?_c('div',{staticClass:"lg"},[_vm._v(" "+_vm._s(_vm.translation.manage_participants.email)+" ")]):_vm._e(),_vm._l((_vm.segments),function(segment){return _c('div',{key:segment.id,staticClass:"md"},[_vm._v(" "+_vm._s(segment.name)+" ")])})],2),_vm._l((_vm.participantsPages),function(participant){return _c('div',{key:participant.id,staticClass:"table-content mt-2 p-1",class:{
              disable: participant.is_active === _vm.isActiveCode.notActive,
              'is-editable': _vm.isEditable,
              first: _vm.firstParticipant && participant.id === _vm.firstParticipant
            }},[_c('div',[_vm._v(_vm._s(participant.id))]),_c('div',{staticClass:"md"},[(participant.isEditable)?_c('div',[_c('b-form-input',{attrs:{"size":"sm"},model:{value:(participant.external_id),callback:function ($$v) {_vm.$set(participant, "external_id", $$v)},expression:"participant.external_id"}})],1):_c('p',{staticClass:"m-0"},[_vm._v(_vm._s(participant.external_id))])]),_c('div',{staticClass:"md"},[(participant.isEditable)?_c('div',[_c('b-form-input',{staticClass:"mx-1",attrs:{"size":"sm"},model:{value:(participant.name),callback:function ($$v) {_vm.$set(participant, "name", $$v)},expression:"participant.name"}})],1):_c('p',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",value:({ variant: 'secondary' }),expression:"{ variant: 'secondary' }",modifiers:{"hover":true}}],staticClass:"m-0",attrs:{"title":_vm.getNameTitle(participant)}},[(
                    _vm.$store.getters.info.user.type === _vm.userTypesText.moderator
                  )?_c('span',[_vm._v(" "+_vm._s(_vm._f("maxLength")(_vm.namePrivacy( _vm.privacy.moderators.participants_identifier_type_id, participant.name, participant.id )))+" ")]):(
                    _vm.$store.getters.info.user.type === _vm.userTypesText.observer
                  )?_c('span',[_vm._v(" "+_vm._s(_vm._f("maxLength")(_vm.namePrivacy( _vm.privacy.observers.participants_identifier_type_id, participant.name, participant.id )))+" ")]):_c('span',[_vm._v(" "+_vm._s(_vm._f("maxLength")(participant.name))+" ")])])]),(
                _vm.$store.getters.info.user.type === _vm.userTypesText.admin ||
                  (_vm.$store.getters.info.user.type ===
                    _vm.userTypesText.moderator &&
                    !+_vm.privacy.moderators.participants_hide_email) ||
                  (_vm.$store.getters.info.user.type === _vm.userTypesText.observer &&
                    !+_vm.privacy.observers.participants_hide_email)
              )?_c('div',{staticClass:"lg"},[(participant.isEditable)?_c('div',[_c('b-form-input',{staticClass:"mx-1",attrs:{"size":"sm"},model:{value:(participant.email),callback:function ($$v) {_vm.$set(participant, "email", $$v)},expression:"participant.email"}})],1):_c('p',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",value:({ variant: 'secondary' }),expression:"{ variant: 'secondary' }",modifiers:{"hover":true}}],staticClass:"m-0",attrs:{"title":participant.email}},[_vm._v(" "+_vm._s(_vm._f("maxLength")(participant.email))+" ")])]):_vm._e(),_vm._l((_vm.getParticipantSegments(participant.segment)),function(segment){return _c('div',{key:segment.id,staticClass:"md"},[(participant.isEditable)?_c('div',[_c('b-form-input',{staticClass:"mx-1",attrs:{"type":"number","size":"sm","max":"1","min":"0"},model:{value:(segment.value),callback:function ($$v) {_vm.$set(segment, "value", $$v)},expression:"segment.value"}})],1):_c('p',{staticClass:"m-0"},[_vm._v(_vm._s(segment.value))])])}),_c('div',{staticClass:"right pr-3"},[(_vm.$store.getters.info.user.type === _vm.userTypesText.admin)?_c('div',{staticClass:"dropdown-actions",attrs:{"id":("dropdown-actions-participants-" + (participant.id))}},[_c('button',{staticClass:"btn-actions hover",style:({ color: _vm.$store.getters.theme[2] }),on:{"click":function($event){return _vm.handleDrop(
                      ("dropdown-actions-participants-" + (participant.id))
                    )}}},[_vm._v(" "+_vm._s(_vm.translation.global.actions)+" "),_c('b-icon',{staticClass:"m-0",attrs:{"icon":"caret-right-fill","aria-hidden":"true"}})],1),_c('div',{attrs:{"id":"dropdown"}},[_c('a',{on:{"click":function($event){return _vm.openEditParticipant(participant)}}},[_c('b-icon',{staticClass:"mr-1",attrs:{"icon":"pencil-fill"}}),_vm._v(" "+_vm._s(_vm.translation.global.edit)+" ")],1),_c('a',{on:{"click":function($event){return _vm.toggleActivateParticipant(participant)}}},[(participant.is_active === _vm.isActiveCode.active)?_c('b-icon',{staticClass:"mr-1 circle",attrs:{"icon":"x"}}):_c('b-icon',{staticClass:"mr-1 circle",attrs:{"icon":"play-fill"}}),_vm._v(" "+_vm._s(participant.is_active === _vm.isActiveCode.active ? _vm.translation.global.disable : _vm.translation.global.activate)+" ")],1)])]):_vm._e(),(participant.isEditable)?_c('div',{staticClass:"mt-1"},[_c('button',{staticClass:"btn btn-sm btn-red mx-1",on:{"click":function($event){return _vm.cancelEditParticipant(participant)}}},[_c('b-icon',{attrs:{"icon":"x"}})],1),_c('button',{staticClass:"btn btn-sm btn-green mx-1",on:{"click":function($event){return _vm.editParticipant(participant)}}},[_c('b-icon',{attrs:{"icon":"check"}})],1)]):_vm._e()])],2)})],2),(!_vm.queryValue && _vm.minPerPage < _vm.total)?_c('pagination-query',{attrs:{"perPage":_vm.perPage,"total":_vm.total,"options":[10, 20, 50, 100]},on:{"perPageUpdate":function (newPerPage) { return (_vm.perPage = newPerPage); }}}):_vm._e()],1)]):(_vm.loaderParticipants || _vm.loaderSegments)?_c('div',{staticClass:"d-flex justify-content-center py-5"},[_c('loader')],1):(_vm.queryValue)?_c('div',{staticClass:"result d-flex justify-content-center py-5"},[_vm._v(" "+_vm._s(_vm.translation.global.sorry_we_have)+" \""+_vm._s(_vm.queryValue)+"\" ")]):_c('div',{staticClass:"d-flex justify-content-center py-5"},[_vm._v(" "+_vm._s(_vm.translation.manage_participants.no_participants)+" ")])],1)])}
var staticRenderFns = []

export { render, staticRenderFns }