<template>
  <div>
    <div
      :class="{
        admin:
          $route.name === 'communities' || $route.name === 'createcommunity'
      }"
      class="header pt-2"
      @click="closeHeader"
    >
      <div class="container">
        <div class="row justify-content-between">
          <div class="col-3 logo">
            <div>
              <router-link :to="{ name: 'feed' }">
                <img
                  v-if="
                    $store.getters.community &&
                      $store.getters.community.logo &&
                      $store.getters.community.logo.length
                  "
                  class="img-fluid "
                  :src="$store.getters.community.logo"
                  alt="Logo"
                />
                <img v-else src="@/img/logo-communities.png" alt="Logo" />
              </router-link>
            </div>
          </div>
          <div
            v-if="
              $route.name !== 'communities' && $route.name !== 'createcommunity'
            "
            class="col-7 d-flex justify-content-center"
          >
            <menu-desktop
              :subActivity="subActivity"
              :subAdmin="subAdmin"
              @subActivity="sub => (subActivity = sub)"
              @subAdmin="sub => (subAdmin = sub)"
            />
          </div>
          <div class="col-2">
            <div class="d-flex align-items-center justify-content-end">
              <notification
                v-if="
                  $route.name !== 'communities' &&
                    $route.name !== 'createcommunity'
                "
              />
              <div class="user">
                <div id="dropdown-profile">
                  <div
                    class="dropdown-button"
                    @click="handleDrop('dropdown-profile')"
                  >
                    <div class="profile-img">
                      <img
                        v-if="$store.getters.info.user.picture"
                        :src="$store.getters.info.user.picture"
                        alt="Profile Picture"
                      />
                      <b-icon
                        v-else
                        class="icon-profile"
                        icon="person-fill"
                        aria-hidden="true"
                      ></b-icon>
                    </div>
                    <p class="pb-1">
                      {{ $store.getters.info.user.name.split(" ")[0] }}
                    </p>
                  </div>
                  <div id="dropdown">
                    <router-link
                      v-if="
                        $route.name !== 'communities' &&
                          $route.name !== 'createcommunity'
                      "
                      :disabled="$store.getters.is_over"
                      :class="{
                        disabled: $store.getters.is_over
                      }"
                      :to="{ name: 'account' }"
                    >
                      <b-icon
                        class="mr-1"
                        icon="gear-fill"
                        aria-hidden="true"
                      ></b-icon>
                      {{ translation.header.account_settings_text }}
                    </router-link>

                    <a @click="logout" class="pointer">
                      <b-icon
                        class="mr-2"
                        icon="box-arrow-right"
                        aria-hidden="true"
                      ></b-icon>
                      {{ translation.header.logout }}
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <sub-header-activities v-if="subActivity" />
    <sub-header-admin v-if="subAdmin" />
  </div>
</template>

<script>
import notification from "@/components/header/notification.vue";
import menuDesktop from "@/components/header/menuDesktop.vue";
import subHeaderActivities from "@/components/header/subHeaderActivities.vue";
import subHeaderAdmin from "@/components/header/subHeaderAdmin.vue";
import { outsideClick } from "@/helpers.js";

export default {
  components: {
    notification,
    menuDesktop,
    subHeaderActivities,
    subHeaderAdmin
  },
  data() {
    return {
      subActivity: false,
      subAdmin: false,
      outsideClick
    };
  },
  computed: {
    routeName() {
      return this.$route.name;
    }
  },
  watch: {
    routeName() {
      this.subActivity = false;
      this.subAdmin = false;
    }
  },
  methods: {
    logout() {
      this.$disconnect();
      this.$store.commit("UPDATE_IS_LOGOUT", true);
      this.$router.push({ name: "login" });
      this.$store.dispatch("logOut");
    },
    closeHeader({ currentTarget }) {
      this.outsideClick(currentTarget, ["click"], () => {
        this.subActivity = false;
        this.subAdmin = false;
      });
    },
    handleDrop(drop) {
      const dropItem = document.querySelector(`#${drop}`);
      dropItem.children[1].classList.toggle("active");

      this.outsideClick(dropItem, ["click"], () => {
        dropItem.children[1].classList.remove("active");
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.header {
  background: #ffffff;
  position: relative;
  z-index: 1000;
  box-shadow: 7px 7px 45px -20px rgba(99, 150, 150, 0.28);
  &.admin {
    background: none;
    box-shadow: none;
    padding-top: 20px !important;
  }
}

.logo {
  display: flex;
  align-items: center;
  img {
    max-height: 55px;
  }
}

.user {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.profile-img {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 65px;
  height: 65px;
  border-radius: 50%;
  overflow: hidden;
  margin-bottom: 5px;
  border: 3px solid #c4c4c4;
  background: #fff;
  img {
    object-fit: cover;
    height: 65px;
    width: 65px;
  }
}

.icon-profile {
  font-size: 2.5rem;
  color: #999;
}

.dropdown-profile {
  position: relative;
  width: 100px;
  display: flex;
  justify-content: center;
}

.dropdown-button {
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
  p {
    display: flex;
    align-items: center;
    color: #999;
    font-size: 0.8rem;
    font-weight: bold;
    text-transform: uppercase;
    margin-bottom: 0;
    &::after {
      content: "";
      display: block;
      width: 4px;
      height: 4px;
      border: 4px solid rgba(0, 0, 0, 0);
      border-top: 4px solid #999;
      margin-left: 5px;
    }
  }
}

#dropdown {
  display: none;
  animation: grow 400ms ease-in-out forwards;
  transform-origin: top center;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 4px;
  position: absolute;
  top: 100px;
  right: 10px;
  z-index: 5;
  background: #fff;
  width: 220px;
  &.active {
    display: block;
  }
  a {
    cursor: pointer;
    display: block;
    padding: 5px 10px;
    color: #999;
    text-decoration: none;
    font-weight: normal;
    border: none;
    width: 100%;
    text-align: left;
    background: none;
    &:hover {
      background: rgba(193, 193, 193, 0.4);
    }
    &:first-of-type {
      border-top-right-radius: 4px;
      border-top-left-radius: 4px;
    }
    &:last-of-type {
      border-bottom-right-radius: 4px;
      border-bottom-left-radius: 4px;
    }
    &.router-link-exact-active,
    &.router-link-active {
      background: rgba(193, 193, 193, 0.25);
    }
    &.disabled {
      opacity: 0.5;
      cursor: default;
      &:hover {
        background: unset;
      }
    }
    &:focus {
      outline: none;
    }
  }
}
</style>
