export const translation = {
  en: {
    login: {
      login_text: "Login",
      email_text: "E-mail",
      password_text: "Password",
      login_button: "Login",
      forgot_password_text: "Forgot my password",
      error: {
        community_ended_for_participants:
          "Community has already ended for participants",
        community_hasnt_started: "The community hasn't started yet",
        community_ended_for_moderators:
          "Community has already expired for moderators",
        community_not_active: "The community is not active",
        update_failed:
          "An error occurred while updating your account! Please contact a moderator"
      },
      success: {
        account_registered:
          "We have already found your registration, please login"
      }
    },
    email: {
      reset_password_text: "Change Password",
      type_your_email_text: "Type your E-mail",
      email_text: "E-mail",
      send_email_button: "Send E-mail",
      error: {
        email_does_not_exist: "The e-mail address does not exist"
      },
      success: {
        sent_email: "Sent e-mail"
      }
    },
    feed: {
      expired_community:
        "Your community has already expired. You have 30 days to view and export the data, after which the community will be unavailable."
    },
    pass: {
      reset_password_text: "Change Password",
      create_new_pwd: "Create your new password",
      new_password_text: "New password",
      confirm_new_password_text: "Confirm your new password",
      type_your_password_text: "Type your new password",
      save_text: "Save",
      success: {
        pwd_successfully_changed: "Your password was successfully changed"
      }
    },
    communities: {
      communities: "Communities",
      community: "Community",
      create_community: "Create community",
      edit_community: "Edit community",
      name_text: "Name",
      description_text: "Description",
      available_period_text: "Time available",
      start_time: "Begin at",
      end_time: "Deadline",
      timezone_text: "Timezone",
      language_text: "Language",
      domain_text: "Community Domain",
      see_community: "View Community",
      success: {
        community_created: "Community successfully created",
        community_copied: "Community successfully copied"
      },
      error: {
        domain_validation:
          "Your community's domain cannot contain special characters, spaces and/or capital letters",
        domain_already_being_used:
          "This domain is valid, but it is already being used"
      }
    },
    create_account: {
      create_acc: "Create an account",
      fill_info: "Insert your information and create your account",
      email_text: "E-mail",
      name_text: "Name",
      password_text: "Password",
      confirm_pwd: "Confirm password",
      profile_pic: "Profile Picture",
      save_text: "Save",
      success: {
        acc_successfully_created: "Account successfully created"
      },
      error: {
        email_already_registered:
          "E-mail already registered. To access, log in using your email and password."
      }
    },
    header: {
      feed_text: "Feed",
      assignments_text: "Assignments",
      reports_text: "Reports",
      forum_text: "Forum",
      see_assignments: "View assignments",
      add_assignment: "Create an assignment",
      admin_text: "Admin",
      moderator_text: "Moderator",
      observer_text: "Observer",
      participant_text: "Participant",
      add_participant_text: "Add Participant",
      manage_participant_text: "Manage Participants",
      community_text: "Community",
      segmentation_text: "Segmentation",
      personalize_text: "Personalize",
      moderation_text: "Moderation",
      account_settings_text: "Account Settings",
      customize_text: "Customize",
      logs: "Logs",
      terms_and_policies: "Terms and policies",
      logout: "Logout"
    },
    next_assignment: {
      next_assignment_text: "Next assignment",
      begin_assignment: "Begin assignment",
      glad_back_text:
        "We're glad you're back! Ready to contribute to something new?",
      completed_assignments: "Completed assignments"
    },
    community_guide: {
      community_guide_text: "Community Guide",
      carrousel_text_1:
        "Complete the Assignments and tasks and contribute with something new",
      carrousel_text_2: "Write comments about your answers",
      carrousel_text_3:
        "Review your answers, interact with the moderators, and have conversations about your opinions",
      add_an_image: "Add an image",
      add_an_message: "Add a message",
      ex_message_title: "Ex: Message title",
      ex_message_support_text: "Ex: Message support text"
    },
    footer: {
      privacy_policy: "Privacy Policy",
      terms_of_use: "Terms of use"
    },
    assignment: {
      assignment: "Assignment",
      edit_assignment: "Edit assignment",
      copy_assignment: "Copy assignment",
      edit_task: "Edit tasks",
      view_assignment: "View assignment",
      no_activity: "No assignment are available now",
      are_you_sure_delete_assignment:
        "Are you sure you want to delete this assignment?",
      completed: "Completed",
      success: {
        assignment_deleted: "Assignment deleted",
        assignment_copied: "Assignment copied"
      },
      error: {
        unable_to_delete:
          "Error! You can only delete assignments with no replies"
      }
    },
    create_assignment: {
      add_assignment: "Create an assignment",
      assignment_name_text: "Title",
      available_period_text: "Time available",
      start_time: "Begin at",
      end_time: "Deadline",
      who_can_see_text: "Choose who can see this assignment",
      who_will_see_1: "All",
      who_will_see_2: "Segments",
      answer_visibility_text: "Choose the reply visibility",
      answer_visibility_option_1:
        "All replies will be visible immediately to the respondent",
      answer_visibility_option_2:
        "The respondent can see all the replies after completing the assignment",
      answer_visibility_option_3:
        "The respondent cannot see any answers from other participants",
      sequenced: "Sequence Tasks",
      activated: "Activate Assignment",
      deactivate: "Deactivate Assignment",
      chained: "Chained",
      tooltip_sequenced:
        "The tasks will be shown in the order they were created",
      tooltip_activated:
        "The assignment will be visible to all selected participants",
      save_and_continue: "Save and continue",
      no_options: "No options are available now",
      add_tasks_to_activate: "Add tasks to activate the assignment",
      success: {
        assignment_created: "Assignment successfully created"
      }
    },
    task: {
      task: "Task",
      tasks: "Tasks",
      see_conversation: "View conversation",
      text_type: "Text",
      survey_type: "Survey",
      fill_blank_type: "Fill in the blank",
      matrix_type: "Matrix",
      slider_type: "Slider",
      drag_drop_type: "Drag and drop",
      image_type: "Images",
      video_type: "Video",
      attachment_type: "Attachments",
      ranking_type: "Ranking",
      reaction_type: "Image evaluation",
      video_evaluation_type: "Video evaluation",
      text_evaluation_type: "Text highlight",
      multimedia_type: "Multimedia",
      audio_type: "Audio",
      screen_recording_type: "Screen Recording",
      text_description:
        "Create questions that participants can answer with words and images.",
      survey_description:
        "Create a question with multiple-choice or single selection questions.",
      fill_blank_description:
        "Write sentences with blank spaces where participants will provide the missing word.",
      matrix_description: "Create an array of choices to be evaluated.",
      slider_description:
        "Use a slider with words or emojis to rate items or statements on a scale.",
      drag_n_drop_description:
        "Have participants drag a piece of text or image and drop it on one or more corresponding drop zones.",
      image_description: "Create a task to be answered with images.",
      video_description: "Create a task to be answered with videos.",
      attachment_description: "Create a task to be answered with attachments.",
      ranking_description: "Create a list of attributes to be ranked.",
      reaction_description: "Use markers and comments to evaluate images.",
      video_evaluation_description:
        "Use markers and comments to evaluate videos.",
      text_evaluation_description: "Highlight texts to indicate importance.",
      screen_recording_description:
        "Allow participants to record their screen as they browse a website.",
      multimedia_description:
        "Create a task to be answered with images and videos.",
      audio_description: "Create a task to be answered with audio recordings.",
      no_tasks: "No tasks available now",
      participants: "Participants",
      answers: "Answers",
      chart: "Chart",
      no_answers: "No answers are available now",
      caption: "Caption",
      add_captions_images: "Add captions to your images",
      add_captions_videos: "Add captions to your videos",
      add_captions_files: "Add captions to your videos",
      upload_images: "Upload your images",
      upload_videos: "Upload your videos",
      upload_files: "Upload your files",
      you_cant_take_screenshot: "You can't take screenshot of the task."
    },
    answer_task: {
      comments: "Comment",
      obligatory_comment_tost: "Comment is mandatory for this task",
      obligatory_comment: "Obligatory comment",
      word_validation: "Word validation",
      character_validation: "Character validation",
      min_characters: "The comment must have at least",
      characters: "characters",
      min_word: "The comment must have at least",
      words: "words",
      drag_and_drop: {
        drag_and_drop: "Drag and drop the cards in any group you want"
      },
      survey: {
        select_alternatives: "Select one or more alternatives",
        choose_alternatives: "Choose an alternative",
        other: "Other",
        none: "None of the above"
      },
      fill_blank: {
        fill_blank_spaces: "Fill in the blank spaces"
      },
      reaction: {
        select_option: "Select an option",
        minimum_number: "Minimum number of responses",
        maximum_number: "Maximum number of responses",
        are_mandatory: "options are mandatory",
        is_mandatory: "option is mandatory"
      },
      screen_recording: {
        description: "Description",
        descriptions_are_mandatory: "Descriptions are mandatory",
        opening_link: "Error opening link",
        popup_blocker:
          "Pop-up Blocker is enabled! Please add this site to your exception list.",
        task_cannot_be_answered:
          "This task cannot be answered using cell phones or tablets",
        minimum_number_recordings: "Minimum number of recordings",
        maximum_number_recordings: "Maximum number of recordings",
        start_recording: "Start screen recording",
        end_screen_recording: "End screen recording",
        recording: "Recording",
        task_instruction:
          'Press "Start Screen Recording" to start the exercise. If you are using multiple screens on your computer, remember to choose the correct one when starting. During the exercise you may be directed to another website. To finish the task, go back to the platform and click on "Finish Screen Recording".'
      },
      audio: {
        minimum_duration: "Minimum duration",
        maximum_duration: "Maximum duration"
      },
      success: {
        reply_registered: "Reply registered",
        assignment_completed: "Assignment finished"
      },
      error: {
        missing_comments: "Missing comments in reactions",
        empty_answer: "Error! Empty answer",
        reply_not_registered: "Error! The reply is not registered",
        use_all_cards: "Error! Use all cards at least once",
        empty_column: "Error! Empty column",
        empty_row: "Error! Empty row",
        fill_one: "Error! Fill at least one",
        other_unanswered: "Error! 'Other' field unanswered",
        answer_must_have: "Error! Your answer must have at least",
        fill_all_blanks: "Error! Fill in all the blanks",
        missing_captions: "Error! Images are missing captions"
      }
    },
    create_task: {
      add_task: "Create a task",
      write_the_task: "Write the task",
      edit_confirm_message:
        "This assignment already has answers. This change will result in the loss of your database. Are you sure you want to continue?",
      reorder_tasks: "Reorder tasks",
      reorder_modal:
        "When reordering the tasks of this assignment, all configured logics and responses will be erased. Are you sure you want to continue?",
      delete_modal:
        "When deleting the tasks of this assignment, all configured logics will be erased. Are you sure you want to continue?",
      delete_task_modal: "Are you sure you want to delete this task?",
      comment_description: "Comment Box Description",
      comment_description_placeholder: "Ex. Comment",
      survey: {
        add_option: "Add option",
        other_option: "Other option",
        none_option: "None option",
        single_option: "Single answer",
        tooltip_other: "Add the option 'Other' with a text box to the answer",
        tooltip_single_answer: "Enable single choice option",
        tooltip_none: "Add 'None' as an option",
        quickly_add: "Quickly add",
        name_a_option: "Name a option and use “;” to create another one.",
        quickly_add_placeholder: "Ex: Female;Male;",
        error: {
          empty_option: "Error! Empty option",
          duplicated_options: "Error! Duplicated values in options",
          duplicated_codes: "Error! Duplicated values in codes"
        }
      },
      fill_blank: {
        blank: "Blank",
        tooltip_blank:
          "Write the enunciate and click on the button to add blank space on the phrase",
        error: {
          error_no_blanks: "Error! No blanks"
        }
      },
      matrix: {
        columns: "Columns",
        rows: "Rows",
        column: "Column",
        row: "Row",
        type: "Type",
        single_selection_column: "Single-selection per column",
        single_selection_row: "Single-selection per row",
        multiple_selection: "Multiple-selection",
        error: {
          empty_field: "Error! Empty value in field",
          duplicated_fields: "Error! Duplicated values in fields",
          duplicated_codes: "Error! Duplicated codes in fields"
        }
      },
      slider: {
        points: "Points",
        point: "Point",
        error: {
          empty_field: "Error! Empty value in field",
          duplicated_fields: "Error! Duplicated values in fields",
          duplicated_codes: "Error! Duplicated codes in fields"
        }
      },
      ranking: {
        cards: "Cards",
        card: "Card",
        image: "Image",
        error: {
          empty_card: "Error! Empty value in card",
          duplicated_cards: "Error! Duplicated values in cards",
          duplicated_codes: "Error! Duplicated codes in cards"
        }
      },
      drag_and_drop: {
        cards: "Cards",
        card: "Card",
        groups: "Groups",
        group: "Group",
        image: "Image",
        finite_cards: "finite cards",
        tooltip_image: "Cards will be composed by images and descriptions",
        tooltip_finite_cards: "Each card can only be classified once",
        error: {
          empty_card: "Error! Empty value in card",
          duplicated_cards: "Error! Duplicated values in cards",
          duplicated_codes_cards: "Error! Duplicated codes in cards",
          empty_group: "Error! Empty value in group",
          duplicated_groups: "Error! Duplicated values in groups",
          duplicated_codes_groups: "Error! Duplicated codes in groups"
        }
      },
      reaction: {
        select_image: "Select an image",
        select_video: "Select an image video",
        enter_text: "Enter a text",
        mark_text: "Mark text",
        reaction_label: "Reaction Label",
        minimum_number: "Minimum number of responses",
        maximum_number: "Maximum number of responses",
        mandatory_comment: "Mandatory comment",
        custom_tagging: "Custom tagging",
        mandatory_marking: "Mandatory marking",
        non_mandatory_marking: "Non-mandatory marking",
        option: "Option",
        error: {
          empty_text: "Error! No text",
          no_marked: "Error! No marked text",
          empty_option: "Error! Empty value in option",
          duplicated_emojis: "Error! Duplicate values ​​in emojis",
          duplicated_labels: "Error! Duplicate values ​​on labels",
          no_image: "Error! No image",
          no_video: "Error! No video",
          bigger: "Error! Minimum value is bigger than maximum value",
          minimum_bigger_marks:
            "Error! Minimum number of responses is bigger than the amount of marked texts",
          minimum_bigger_mandatory:
            "Erro! Number of mandatory options is bigger than the amount of marked texts",
          no_minimum: "Error! No minimum value ",
          no_maximum: "Error! No maximum value"
        }
      },
      media: {
        add_captions: "Add captions",
        mandatory_captions: "Mandatory captions"
      },
      screen_recording: {
        mandatory_description: "Mandatory description"
      },
      audio: {
        maximum_duration: "Maximum duration in seconds",
        minimum_duration: "Minimum duration in seconds",
        error: {
          no_minimum_duration: "Error! No minimum duration value",
          no_maximum_duration: "Error! No maximum duration value",
          bigger_duration:
            "Error! Minimum duration value is bigger than maximum duration value"
        }
      },
      logics: {
        logic: "Logics and conditions",
        default: "Default",
        if_you_answer: "If you answer",
        go_to: "Go To",
        next: "Next",
        finish: "Finish",
        and: "And",
        or: "Or",
        add_condition: "Add Condition"
      },
      error: {
        the_field_cannot: 'Error! The field value cannot be just "0"',
        error_empty_task: "Empty task",
        error_empty_code: "Task code field is empty",
        error_empty_code_field: "Option with empty code field",
        code_validation:
          "The task code cannot contain special characters, spaces or be just 0.",
        number_zero: "The code cannot be the number 0 alone",
        code_string_already: "Code string already in use"
      },
      success: {
        task_successfully_created: "Task created successfully!",
        task_deleted: "Task deleted",
        task_copied: "Task successfully copied"
      }
    },
    replies: {
      replies: "Replies",
      media: "Media",
      charts: "Charts",
      exported_files: "Exported files",
      return_to_replies: "Return to replies",
      track_the_status: "Track the status of your downloaded files",
      filters: "filters",
      filters_confirm_message:
        "Only filtered items will be downloaded, do you want to proceed with the export process?",
      filters_name: "Give your export file a name",
      filters_message_exported_files:
        "By clicking on export, the file will not be ready right away, you can follow it at",
      format: "Format",
      media_only: "Media only",
      transcripts_only: "Transcripts only",
      both: "Both",
      choose_the_format: "Choose the format of the export file",
      timestamp: "Timestamp",
      download: "Download",
      export: "Export",
      assignments: "Assignments",
      name: "Name",
      labels: "Labels",
      segments: "Segments",
      type: "Type",
      export_answers_labels: "Export answers in labels",
      export_answers_code: "Export answers in code",
      activated: "Activated",
      disabled: "Disabled",
      assignment: "Assignment",
      community_status: "Community Status",
      export_status: "Export Status",
      completes: "Completes",
      ends_at: "Ends at",
      view: "View",
      solved: "Solved",
      not_solved: "Not Solved",
      all: "All",
      see_comments: "See comments",
      comment: "Comment",
      no_reports: "There are no answers matching your search.",
      no_media: "No media available",
      transcription: "Transcription",
      transcription_and_media: "Transcription and Media",
      this_video_doesnt: "This video doesn't have a transcription",
      charts_tab: {
        chart: "Chart",
        customize: "Customize",
        frequency_table: "Frequency Table",
        number_of_mentions: "Number of mentions",
        fewer: "fewer",
        more: "more",
        font_family: "Font Family",
        colors: "Colors",
        one: "One",
        multiple: "Multiple",
        number_of_words: "Number of words",
        discard_words: "Discard words",
        chart_type: "Chart type",
        data_type: "Data type",
        number: "Number",
        percentage: "Percentage",
        option: "Option",
        answers: "Answers",
        wait_word_cloud: "Wait for the word cloud to load before exporting",
        download_table: "Download table",
        download_chart: "Download chart",
        download_word_cloud: "Download word cloud",
        total_words: "Total words",
        total_responses: "Total responses",
        frequency_in_percentage: "Frequency in percentage",
        frequency_in_numbers: "Frequency in numbers",
        choose_font_family: "Choose the font family of the word cloud",
        choose_colors_wordcloud: "Choose one or more colors for the word cloud",
        choose_colors_chart: "Choose colors for the chart",
        limit_the_number_of_words:
          "Limit the number of words the cloud will contain",
        choose_your_chart_type: "Choose the chart type",
        answered: "Answered",
        download_heatmap: "Download heatmap",
        discard_words_from_the_cloud: "Discard words from the cloud",
        choose_data_format: "Choose data format",
        words: "Words",
        dot: "Dot",
        choose_heatmap: "Choose heatmap dot settings",
        max: "Max",
        radius: "Radius",
        maxOpacity: "Max Opacity",
        blur: "Blur",
        wait_changes: "Wait for the applied changes to load"
      },
      assignments_tab: {
        real_time_answers: "Real-time replies",
        number_completes: "Respondents",
        open: "Open replies",
        modal: {
          do_you_really_want_to_delete:
            "Do you really want to delete this reply?",
          delete_reply: "Delete reply",
          success: {
            reply_deleted: "Reply deleted successfully"
          }
        }
      },
      dashboard: {
        external_id: "External ID",
        name: "Name",
        progress: "Progress",
        completed: "Completed",
        not_started: "Not started",
        incomplete: "Incomplete",
        initial: "Initial",
        email: "E-mail",
        general_status: "General Status",
        send_message: "Send Message",
        no_participants: "No participants are available now"
      },
      mark_reply_viewed: "Mark response as viewed",
      reply_viewed: "Response viewed",
      moderate_viewed_answer: "The moderator viewed your answer",
      are_you_sure_delete_report:
        "Are you sure you want to delete this report?",
      report_deleted: "Report deleted"
    },
    forum: {
      search_post: "Search Post",
      export_forum: "Export Forum",
      welcome_to_forum: "Welcome to the Forum!",
      create_post_engage:
        "Create a post to engage participants on a discussion",
      join_discussion: "Join a discussion with other participants",
      no_posts: "No posts",
      delete_post: "Delete Post",
      are_you_sure_delete_post: "Are you sure you want to delete this post?",
      edited: "Edited",
      success: {
        post_deleted: "Post deleted successfully"
      },
      new_post: {
        new_post: "New Post",
        post: "Post",
        title: "Title",
        add_title_post: "Add a title to your post",
        write_new_post: "Write a new post",
        enable_reply: "Enable reply editing",
        activate_post: "Activate Post",
        enabled: "Enabled",
        not_enabled: "Not Enabled",
        desactivate: "Desactivate",
        activate: "Activate",
        segmentation: "Segmentation",
        add_segment: "Add segment",
        all: "All",
        selected_segments: "Selected segments",
        success: {
          post_created: "Post created successfully"
        },
        error: {
          empty_post: "Error! Empty post",
          empty_title: "Error! Empty title"
        }
      },
      comments: {
        comment: "Reply",
        write_new_comment: "Write a new reply",
        no_comments: "No replies",
        success: {
          comment_created: "Reply created successfully"
        },
        error: {
          empty_comment: "Error! Empty reply"
        }
      },
      subreply: {
        delete_reply: "Delete Reply",
        are_you_sure_delete_reply:
          "Are you sure you want to delete this reply?",
        success: {
          reply_deleted: "Reply deleted successfully"
        }
      }
    },
    account_settings: {
      account_settings: "Account Settings",
      name_text: "Name",
      email_text: "E-mail",
      reset_pwd: "Reset password",
      old_password_text: "Old Password",
      new_password_text: "New password",
      confirm_new_password_text: "Confirm your new password",
      name_validation: "Name cannot contain special characters or numbers",
      success: {
        name_success: "Name changed successfully ",
        picture_success: "Successfully altered picture ",
        password_success: "Password changed successfully "
      },
      error: {
        nothing_to_change: "Nothing to change",
        name_error: "Error changing name ",
        picture_error: "Error changing picture ",
        password_error: "Error changing password ",
        different_password: "Password does not match current password"
      }
    },
    terms_and_policies: {
      privacy_policy: "Privacy policy",
      terms_of_use: "Terms of use",
      edit_content: "Edit Content",
      i_accept_the_terms_os_use: "I accept the",
      i_accept_the_privacy_policy: "I accept the",
      success: {
        privacy_policy_successfully_changed:
          "Privacy policy updated successfully",
        terms_of_use_successfully_changed: "Terms of use updated successfully"
      }
    },
    customize_community: {
      customize_account:
        "Choose the color palette that most resembles your brand.",
      choose_text: "Choose a color to represent your community",
      upload_banner: "Upload the community banner.",
      upload_logo: "Upload your logo",
      banner_description:
        "Create a short description of the community that will appear next to the banner",
      description_placeholder: "Ex. Welcome to the Community...",
      add_message: "Add message",
      customize_pages: "Customize pages"
    },
    add_message: {
      add_custom_texts: "Add custom texts for your respondents.",
      message: "Message",
      title: "Title",
      supporting_text: "Supporting text",
      image: "Image",
      stop_display: "Stop display",
      save: "Save",
      messages_created_success: "Messages were created successfully",
      messages_updated_success: "Messages were updated successfully",
      validate_feed_list_message:
        'All messages that the "stop display" option is not enabled must have a title, supporting text and image filled in'
    },
    logs: {
      date: "Date",
      description: "Description",
      oldest: "Oldest",
      newest: "Newest"
    },
    notifications: {
      notifications: "Notifications",
      no_notifications: "No notifications at the moment",
      mod_commented_reply: "The moderator commented in one of your replies",
      responded_comment: "responded to your comment",
      created_new_post: "created a new post",
      commented_in_post: "commented in your post",
      commented_in_comment: "commented in your comment",
      liked_post: "liked your post",
      liked_comment: "liked your comment",
      reply_rejected: "Your reply was rejected",
      new_assignment_available: "New assignment available",
      comment_rejected: "Your comment was rejected",
      task_updated: "Task was Updated",
      forum: "Forum",
      assignment: "Assignment",
      created_community: "Admin created community",
      comunity: "Community",
      changed_terms_and_conditions: "Admin changed terms and conditions",
      terms_and_conditions: "Terms and conditions"
    },
    pagination: {
      skip: "skip",
      previous: "Previous",
      continue: "Continue",
      save_and_continue: "Save and continue",
      finish: "Finish"
    },
    dropzone: {
      attachments:
        "Drop files here or click to select. You can upload multiple files at once",
      image: "Drop image here or click to select",
      video: "Drop video here or click to select.",
      multimedia:
        "Drag and drop videos and photos here or click to select them. You can insert more than one file at a time.",
      can_not_upload_files: "You can not upload any more files",
      your_browser_does_not_support:
        "Your browser does not support drag'n'drop file uploads.",
      please_use_the_fallback:
        "Please use the fallback form below to upload your files like in the olden days.",
      file_is_too_big:
        "File is too big ({{filesize}}MiB). Max filesize: {{maxFilesize}}MiB",
      you_cant_upload: "You can't upload files of this type.",
      server_responded_with_status_code:
        "Server responded with {{statusCode}} code.",
      cancel_upload: "Cancel upload",
      are_you_sure_cancel: "Are you sure you want to cancel this upload?",
      remove_file: "Remove file",
      file_uploaded_successfully: "File uploaded successfully"
    },
    uploader: {
      add_media: "Add media",
      warn_uploader_modal:
        "Você ainda não concluiu o carregamento de seu(s) vídeo(s), deseja prosseguir mesmo assim? Os vídeos que não foram totalmente carregadas não serão adicionadas como parte da sua resposta"
    },
    conversation: {
      answer: "Reply"
    },
    labels: {
      show_more: "Show more",
      show_less: "Show less",
      add_label: "Add Label",
      create_label: "Create Label",
      label_name: "Name",
      label_description: "Description",
      label_background: "Background",
      label_color: "Color",
      all_available: "All available labels have already been added",
      modal: {
        are_you_sure_delete_label:
          "Are you sure you want to delete this label?",
        delete_label: "Delete Label"
      },
      success: {
        label_removed: "Label removed",
        added_label: "Added label",
        deleted_label: "Deleted label",
        label_created: "Label created"
      }
    },
    segmentation: {
      segmentation: "Segmentation",
      create_segmentation: "Create segmentation",
      name_a_segment: "Name a segment and use “;” to create another one.",
      segments_placeholder: "Ex: Female;Male;",
      no_segments: "No segments are available now",
      table: {
        name: "Name"
      },
      modal: {
        do_you_really_want_to_delete:
          "Do you really want to delete this reply?",
        delete_segment: "Delete segment",
        success: {
          segment_deleted: "Segment deleted successfully"
        }
      },
      success: {
        added_segment: "Added segment"
      }
    },
    add_participant: {
      add_participant: "Add Participant",
      import_lot: "Import in batches",
      registration_link: "Registration link",
      import: "Import",
      invite_participants: "Invite Participants",
      download_pattern: "Download pattern",
      import_lot_xlsx: "Import batches to XLSX file:",
      if_you_have_not: "If you have not yet created the segmentations",
      drag_and_drop_the_XLSX:
        "Drag and drop the XLSX file or select from your computer.",
      edit_as_many_fields: "Edit as many fields as you like.",
      please_check_all:
        "Please check all data carefully before sending. When pressing send the invitation will be triggered to all contacts in the list.",
      email_subject: "Email Subject",
      email_body: "Email body",
      generate_link_to_all: "Generate link to all or by group of segments",
      search_segment: "Search segment",
      number_of_people:
        "Number of people who will be able to enter the generated link",
      all: "All",
      people_will_be_able: "People will be able to enter with the link",
      generate_link: "Generate Link",
      generated_links: "Generated Links",
      no_links: "No links are available now",
      registered_using_this_link: "registered using this link",
      segments_linked_to_the_link: "Segments linked to the link",
      show_more: "Show more",
      show_less: "Show less",
      copy_link: "Copy Link",
      disable_link: "Disable Link",
      activate_link: "Activate Link",
      delete_link: "Delete Link",
      do_you_really_want_to_delete: "Do you really want to delete this link?",
      success: {
        link_copied: "Link copied",
        link_deleted: "Link deleted successfully",
        link_generated: "Link Generated"
      },
      errors: {
        empty_xlsx: "Error! Empty XLSX",
        missing_keys: "Error! Missing keys",
        duplicated_email: "Error! Duplicated E-mail",
        inactive_logging: "Inactive logging channel",
        not_within_the_pattern:
          "The file is not within the pattern, try again.",

        the_number_of_people: "The number of people must be 1 or more",
        different_segments: "The community and the file have different segments"
      },
      warn: {
        is_already: "is already in a live community",
        invalid_email: "Invalid Email"
      }
    },
    operators: {
      add_manage_operators: "Add and Manage Operators",
      add_operators: {
        add_operators: "Add Operators",
        name_operator: "Operator Name",
        placeholder_operator: "Ex. Carlos Antônio",
        placeholder_email: "Ex. carlos@gmail.com",
        type_access: "Type of access",
        admin: "Administrator",
        admin_text: {
          attention_label: "Attention: ",
          admin_text:
            "This type of access gives the moderator control over the entire community."
        },
        observer: "Observer",
        observer_text: "Descrição do que é um observador",
        moderator: "Moderator",
        moderator_text: "Descrição do que é um moderador"
      },
      manage_operators: {
        manage_operators: "Manage Operators",
        name: "Name",
        edit: "Edit",
        reset_password: "Reset password",
        resend_invitation: "Resend invitation",
        disable_access: "Disable access",
        enable_access: "Enable access",
        delete_access: "delete access",
        delete_operator: "Delete access",
        delete_operator_label: "Are you sure you want to delete this access?",
        successfully_deleted_access: "Successfully deleted access",
        error_deleting_access: "Error deleting access",
        successfully_disabled_access: "Access successfully disabled",
        error_disabled_access: "Error disabling access",
        successfully_activated_access: "Access activated successfully",
        error_activated_access: "Error activating access",
        error_reset_password: "Error resetting password",
        password_reset_successfully: "Password reset successfully",
        no_operator: "No operator is available now",
        user_has_already: "User has already logged in for the first time"
      },
      create_access: "Create access",
      operator_created: "Operator created successfully",
      email_already: "Email is already in use",
      invalid_name: "Invalid name",
      name_validation: "Name cannot contain special characters"
    },
    manage_participants: {
      registered: "Registered",
      invited: "Invited",
      profiles: "Profiles",
      name: "Name",
      email: "Email",
      external_id: "External ID",
      created_at: "Created at",
      read_at: "Read at",
      joined_at: "Joined at",
      last_login: "Last login",
      bulk_action: "For all",
      resend_invitation: "Resend Invitation",
      block_invitation: "Block Invitation",
      no_participants: "No participants are available now",
      view_participant: "View Participant",
      segments: "Segments"
    },
    chat: {
      admin: "Admin",
      participant: "Participant",
      moderator: "Moderator",
      observer: "Observer",
      enter_a_message: "Enter a message",
      no_users: "Sorry we have 0 results.",
      select_a_conversation: "Select a conversation",
      view_operators: "View operators",
      transmission_list: "Transmission list",
      my_lists: "My Lists",
      select: "Select",
      list_name: "List Name"
    },
    modal_welcome: {
      nubank_title: "Bem vindo ao Lab!",
      nubank:
        "Esse é um espaço seguro autorizado e sancionado pela Nubank onde você poderá compartilhar suas opiniões sobre diversos temas. Para seu cadastro e login, siga as instruções abaixo e crie uma senha para entrar na plataforma. Lembre-se de que essa é uma plataforma de estudos de opinião e não um acesso à sua conta bancária da Nubank, portanto não utilize  credenciais (como senhas e registros) de sua conta bancária e demais informações pessoais.",
      default_title: "Welcome to Ow Voices!",
      default:
        "This is a safe space for you to share your thoughts on different topics. For your registration and login, follow the instructions below and create a password to enter the platform. Remember that this is an opinion studies platform, so do not use your credentials (such as passwords and records) from bank accounts and other personal information."
    },
    stats_assignments: {
      assignments_statistics: "Assignments Statistics",
      assignment: "Assignment",
      start_and_end: "Start and End",
      number_of_participants: "No. of participants",
      number_of_assignments: "No. of assignments",
      complete: "Complete",
      incomplete: "Incomplete",
      not_started: "Not Started",
      no_assignment: "No assignment are available now",
      export_statistics: "Export Statistics",
      personal_progress: "Personal Progress"
    },
    privacy_and_permissions: {
      privacy_and_permissions: "Privacy and Permissions",
      privacy: "Privacy",
      permissions: "Permissions",
      participants: "Participants",
      moderators: "Moderators",
      observers: "Observers",
      identity_of_participants: "Identity of Participants",
      identity_of_admins: "Identity of Moderadores",
      how_participants_are_displayed_to_each_other:
        "How participants are displayed to each other.",
      how_admins_are_displayed: "How admins are displayed to participants.",
      admins_and_moderators_can_view_all_details:
        "Admins and moderators can view all details of participants, but the display of participant names can be customized for convenience.",
      do_not_display_participant_email:
        "Do not display participant email on any page where it is commonly displayed",
      do_not_display_participant_last_login_info:
        "Do not display participant data and last login time on any page where it is commonly displayed",
      how_participants_are_displayed_to_clients:
        "How participants are displayed to customers.",
      do_not_display_participant_segments:
        "Do not display participant segments on any page where it is commonly displayed",
      do_not_display_participant_personal_progress:
        "Do not display participant's personal progress on any page where it is commonly displayed",
      do_not_display_comments_on_replies_made_by_operators:
        "Do not display comments on replies made by admins and moderators",
      pages: "Pages",
      by_default_observers:
        "By default, Observers can view all pages below, but the restriction can be customized for convenience. Too much pages not listed below will not be visible to Observers by default.",
      do_not_display_assignments_statistics:
        "Do not display 'Assignments Statistics'",
      do_no_display_replies_in_the_reports_menu:
        "Do not display 'Replies' in the Reports menu",
      do_no_display_media_in_the_reports_menu:
        "Do not display 'Media' in the Reports menu",
      do_no_display_charts_in_the_reports_menu:
        "Do not display 'Charts' in the Reports menu",
      do_no_display_manage_participants: "Do not display 'Manage Participants'",
      by_default_moderators:
        "By default, Moderators can view all pages below, but the restriction can be customized for convenience. Too much pages not listed below will not be visible to Moderators for pattern.",
      do_not_display_customization: "Do not display 'Customization'",
      do_not_display_terms_ans_policies: "Do not display 'Terms and Policies'",
      do_not_display_create_assignments: "Do not display 'Create Assignments'",
      full_name: 'Full name ("John Doe")',
      first_name_and_last_name_initial:
        'First name and last initial ("John D")',
      name_only: 'Name only ("John")',
      name_and_unique_id: 'Name and Unique ID ("John 1234")',
      unique_id_only: 'Unique ID only ("1234")'
    },
    global: {
      return: "Return",
      next: "Next",
      edit: "Edit",
      save: "Save",
      delete: "Delete",
      copy: "Copy",
      search: "Search",
      sorry_we_have: "Sorry we have 0 search results for",
      cancel: "Cancel",
      save_alterations: "Save Alterations",
      order_by: "Order by",
      filter_by: "Filter by",
      apply: "Apply",
      send: "Send",
      load_more: "Load More",
      add: "Add",
      disable: "Disable",
      activate: "Activate",
      click_here: "Click here",
      actions: "Actions",
      clean: "Clean",
      show_more: "Show more",
      show_less: "Show less",
      yes: "Yes",
      no: "No",
      error: "Error",
      ready: "Ready",
      processing: "Processing"
    },

    errors_global: {
      pwd_eight_characters:
        "Your password must have at least eight characters long, at least one number, one special character, one uppercase letter, and one lowercase letter",
      something_went_wrong: "Something went wrong. Please try again later.",
      invalid_combination: "Invalid password combination",
      invalid_user_or_pwd: "Invalid username or password",
      fill_fields: "Fill in all the required fields",
      limit_exceeded_images: "Limit exceeded! You can upload up to 10 images",
      limit_exceeded_video: "Limit exceeded! You can upload up to 5 videos",
      limit_exceeded_attachments:
        "Limit exceeded! You can upload up to 5 attachments",
      you_have_exceeded: "You have exceeded the limit of characters",
      you_have_exceeded_word_limit: "You have exceeded the word limit",
      observers_are_not_allowed: "Observers are not allowed to access the page"
    },
    success_global: {
      changes_saved: "Changes have been saved"
    }
  },
  pt: {
    login: {
      login_text: "Login",
      email_text: "E-mail",
      password_text: "Senha",
      login_button: "Login",
      forgot_password_text: "Esqueci minha senha",
      error: {
        community_ended_for_participants:
          "A comunidade já acabou para participantes",
        community_hasnt_started: "A comunidade ainda não começou",
        community_ended_for_moderators:
          "A comunidade já expirou para moderadores",
        community_not_active: "Comunidade não começou",
        update_failed:
          "Ocorreu um error ao atualizar sua conta! Por favor entre em contato com um moderador"
      },
      success: {
        account_registered: "Já encontramos seu cadastro, por favor faça login"
      }
    },
    email: {
      reset_password_text: "Redefinir senha",
      type_your_email_text: "Digite seu e-mail",
      email_text: "E-mail",
      send_email_button: "Enviar e-mail",
      error: {
        email_does_not_exist: "Esse e-mail não existe"
      },
      success: {
        sent_email: "Email enviado"
      }
    },
    feed: {
      expired_community:
        "Sua comunidade já expirou. Você tem 30 dias para visualizar e exportar os dados, após este prazo a comunidade ficará indisponível."
    },
    pass: {
      reset_password_text: "Redefinir senha",
      create_new_pwd: "Crie sua nova senha",
      new_password_text: "Nova senha",
      confirm_new_password_text: "Confirmar a nova senha",
      type_your_password_text: "Digite sua senha",
      save_text: "Salvar",
      success: {
        pwd_successfully_changed: "Sua senha foi alterada com sucesso"
      }
    },
    communities: {
      communities: "Comunidades",
      community: "Comunidade",
      create_community: "Criar comunidade",
      edit_community: "Editar comunidade",
      name_text: "Nome",
      description_text: "Descrição",
      available_period_text: "Tempo disponível",
      start_time: "Começa em",
      end_time: "Prazo final",
      timezone_text: "Fuso horário",
      language_text: "Idioma",
      domain_text: "Domínio da Comunidade",
      see_community: "Ver comunidade",
      success: {
        community_created: "Comunidade criada com sucesso",
        community_copied: "Comunidade copiada com sucesso"
      },
      error: {
        domain_validation:
          "O domínio da sua comunidade não pode conter caracteres especiais, espaços e/ou letras maiúsculas",
        domain_already_being_used:
          "Esse domínio é válido, porém já está sendo utilizado"
      }
    },
    create_account: {
      create_acc: "Crie sua conta",
      fill_info: "Insira seus dados e crie sua conta",
      email_text: "E-mail",
      name_text: "Nome",
      password_text: "Senha",
      confirm_pwd: "Confirmar senha",
      profile_pic: "Foto de perfil",
      save_text: "Salvar",
      success: {
        acc_successfully_created: "Conta criada com sucesso"
      },
      error: {
        email_already_registered:
          "E-mail já cadastrado. Para acessar, faça login utilizando e-mail e senha"
      }
    },
    header: {
      feed_text: "Feed",
      assignments_text: "Atividades",
      reports_text: "Relatórios",
      forum_text: "Fórum",
      see_assignments: "Ver atividades",
      add_assignment: "Criar atividade",
      admin_text: "Admin",
      moderator_text: "Moderador",
      observer_text: "Observer",
      participant_text: "Participante",
      add_participant_text: "Adicionar Participante",
      manage_participant_text: "Gerenciar Participantes",
      community_text: "Comunidade",
      segmentation_text: "Segmentação",
      personalize_text: "Personalizar",
      moderation_text: "Moderação",
      account_settings_text: "Configurações da conta",
      customize_text: "Customizar",
      logs: "Histórico",
      terms_and_policies: "Termos e políticas",
      logout: "Sair"
    },
    next_assignment: {
      next_assignment_text: "Próxima atividade",
      begin_assignment: "Começar a atividade",
      glad_back_text:
        "Que bom que você voltou! Pronto(a) para contribuir com algo novo?",
      completed_assignments: "Atividades completas"
    },
    community_guide: {
      community_guide_text: "Guia da Comunidade",
      carrousel_text_1:
        "Complete as tarefas e atividades para contribuir com algo novo",
      carrousel_text_2: "Faça comentários sobre as suas respostas",
      carrousel_text_3:
        "Reveja suas respostas, interaja com os moderadores e converse sobre suas opiniões",
      add_an_image: "Adicione uma imagem",
      add_an_message: "Adicione uma mensagem",
      ex_message_title: "Ex: Título da mensagem",
      ex_message_support_text: "Ex: Texto de apoio da mensagem"
    },
    footer: {
      privacy_policy: "Política de Privacidade",
      terms_of_use: "Termos de uso"
    },
    assignment: {
      assignment: "Atividade",
      edit_assignment: "Editar atividade",
      copy_assignment: "Copiar atividade",
      edit_task: "Editar tarefas",
      view_assignment: "Ver atividade",
      no_activity: "Não há atividades disponíveis agora",
      are_you_sure_delete_assignment:
        "Tem certeza que você deseja deletar essa atividade?",
      completed: "Completa",
      success: {
        assignment_deleted: "Atividade deletada",
        assignment_copied: "Atividade copiada"
      },
      error: {
        unable_to_delete: "Erro! Você só pode excluir atividades sem respostas"
      }
    },
    create_assignment: {
      add_assignment: "Criar atividade",
      assignment_name_text: "Título",
      available_period_text: "Tempo disponível",
      start_time: "Começa em",
      end_time: "Prazo final",
      who_can_see_text: "Escolha quem pode ver esta atividade",
      who_will_see_1: "Todos",
      who_will_see_2: "Segmentos",
      answer_visibility_text: "Escolha a visibilidade das respostas",
      answer_visibility_option_1:
        "Todas as respostas ficarão visíveis imediatamente para o respondente",
      answer_visibility_option_2:
        "O respondente pode ver todas as respostas depois que completar todas as atividades",
      answer_visibility_option_3:
        "Os respondente não pode ver nenhuma resposta dos outros participantes",
      sequenced: "Sequenciar Tarefas",
      activated: "Ativar Atividade",
      deactivate: "Desativar Atividade",
      chained: "Encadeado",
      tooltip_sequenced: "As tarefas serão apresentados na ordem de criação",
      tooltip_activated:
        "A atividade ficara visível para todos os participantes selecionados",
      save_and_continue: "Salvar e continuar",
      no_options: "Nenhuma opção disponível agora",
      add_tasks_to_activate: "Adicione tarefas para ativar a atividade",
      success: {
        assignment_created: "Atividade criada com sucesso"
      }
    },
    task: {
      task: "Tarefa",
      tasks: "Tarefas",
      see_conversation: "Ver conversa",
      text_type: "Texto",
      survey_type: "Pesquisa",
      fill_blank_type: "Espaços em branco",
      matrix_type: "Matriz",
      slider_type: "Deslizar",
      drag_drop_type: "Arrastar e soltar",
      image_type: "Imagens",
      video_type: "Vídeo",
      attachment_type: "Arquivo",
      ranking_type: "Ranking",
      reaction_type: "Avaliação de imagem",
      video_evaluation_type: "Avaliação de vídeo",
      text_evaluation_type: "Marcação de texto",
      multimedia_type: "Multimídia",
      audio_type: "Áudio",
      screen_recording_type: "Gravação de tela",
      text_description:
        "Crie perguntas que os participantes possam responder com palavras e imagens.",
      survey_description:
        "Crie uma pergunta com perguntas de múltipla escolha ou de seleção única.",
      fill_blank_description:
        "Escreva frases com espaços em branco em que os participantes fornecerão a palavra que falta.",
      matrix_description: "Crie uma tabela de escolhas a serem avaliadas.",
      slider_description:
        "Crie um deslizador com palavras ou emojis para classificar itens ou declarações em uma escala.",
      drag_n_drop_description:
        "Peça aos participantes que arrastem cartões de texto ou imagem e solte-os em um ou mais grupos correspondentes.",
      image_description:
        "Crie uma tarefa que deverá ser respondida com imagens.",
      video_description:
        "Crie uma tarefa que deverá ser respondida com vídeos.",
      attachment_description:
        "Crie uma tarefa que deverá ser respondida com arquivos.",
      ranking_description:
        "Crie uma lista de atributos a serem classificados em ordem.",
      reaction_description:
        "Use marcadores e comentários para avaliar imagens.",
      video_evaluation_description:
        "Use marcadores e comentários para avaliar vídeos.",
      text_evaluation_description: "Marque textos para indicar importância.",
      screen_recording_description:
        "Permita que os participantes gravem sua tela enquanto navegam em um site.",
      multimedia_description:
        "Crie uma tarefa que deverá ser respondida com imagens e/ou vídeos.",
      no_tasks: "Nenhuma tarefa disponível agora",
      audio_description:
        "Crie uma tarefa que deverá ser respondida com gravações de audio.",
      participants: "Participantes",
      answers: "Respostas",
      chart: "Gráfico",
      no_answers: "Nenhuma resposta disponível agora",
      caption: "Legenda",
      add_captions_images: "Adicione legendas para suas imagens",
      add_captions_videos: "Adicione legendas para seus vídeos",
      add_captions_files: "Adicione legendas para seus arquivos",
      upload_images: "Carregue suas imagens",
      upload_videos: "Carregue seus vídeos",
      upload_files: "Carregue seus arquivos",
      you_cant_take_screenshot:
        "Você não pode fazer uma captura de tela da tarefa."
    },
    answer_task: {
      comments: "Comentário",
      obligatory_comment_tost: "O comentário é obrigatório para esta tarefa",
      obligatory_comment: "Comentário obrigatório",
      word_validation: "Validação de palavras",
      character_validation: "Validação de caracteres",
      min_characters: "O comentário deve possuir pelo menos",
      characters: "caracteres",
      min_word: "O comentário deve possuir pelo menos",
      words: "palavras",
      drag_and_drop: {
        drag_and_drop: "Arraste os cartões para o grupo que desejar"
      },
      survey: {
        select_alternatives: "Selecione uma ou mais alternativas",
        choose_alternatives: "Escolha uma alternativa",
        other: "Outro",
        none: "Nenhum"
      },
      fill_blank: {
        fill_blank_spaces: "Preencha os espaços em branco"
      },
      reaction: {
        select_option: "Selecione uma opção",
        minimum_number: "Número mínimo de respostas",
        maximum_number: "Número máximo de respostas",
        are_mandatory: "são obrigatórios",
        is_mandatory: "é obrigatório"
      },
      screen_recording: {
        description: "Descrição",
        descriptions_are_mandatory: "As descrições são obrigatórias",
        opening_link: "Erro ao abrir link",
        popup_blocker:
          "O bloqueador de pop-ups está ativado! Por favor, adicione este site à sua lista de exceções.",
        task_cannot_be_answered:
          "Essa tarefa não pode ser respondida utilizando celulares ou tablets”",
        minimum_number_recordings: "Número mínimo de gravações",
        maximum_number_recordings: "Número máximo de gravações",
        start_recording: "Iniciar gravação de tela",
        end_screen_recording: "Finalizar gravação de tela",
        recording: "Gravação",
        task_instruction:
          'Aperte "Iniciar Gravação de Tela" para iniciar o exercício. Caso esteja utilizando múltiplas telas no seu computador, lembre-se de escolher a tela correta ao iniciar. Durante o exercício você poderá ser direcionado para outro site. Para finalizar a tarefa, volte para a plataforma e clique em "Finalizar Gravação de Tela".'
      },
      audio: {
        minimum_duration: "Duração mínima",
        maximum_duration: "Duração máxima"
      },
      success: {
        reply_registered: "Resposta registrada",
        assignment_completed: "Atividade finalizada"
      },
      error: {
        missing_comments: "Faltando comentários nas reações",
        empty_answer: "Resposta vazia",
        reply_not_registered: "Erro! A resposta não foi registrada",
        use_all_cards: "Use todos os cartões pelo menos uma vez",
        empty_column: "Coluna vazia",
        empty_row: "Linha vazia",
        fill_one: "Preencha pelo menos um",
        other_unanswered: "Campo 'Outro' sem resposta",
        answer_must_have: "A resposta deve possuir pelo menos",
        fill_all_blanks: "Preencha todos os espaços em branco",
        missing_captions: "Faltam legendas nas imagens"
      }
    },
    create_task: {
      add_task: "Crie uma tarefa",
      write_the_task: "Escreva uma tarefa",
      edit_confirm_message:
        "Esta atividade já possui respostas. Esta alteração implicará na perda da sua base de dados. Tem certeza que deseja continuar?",
      reorder_tasks: "Reordenar tarefas",
      reorder_modal:
        "Ao se reordenar as tarefas dessa atividade, todas as lógicas configuradas e respostas serão apagadas. Tem certeza que deseja continuar?",
      delete_modal:
        "Ao se deletar as tarefas dessa atividade, todas as lógicas configuradas serão apagadas. Tem certeza que deseja continuar?",
      delete_task_modal: "Tem certeza que você deseja deletar essa task?",
      comment_description: "Descrição da caixa de comentário",
      comment_description_placeholder: "Ex. Comentário",
      survey: {
        add_option: "Adicionar uma opção",
        other_option: "Opção outro",
        none_option: "Opção nenhum",
        single_option: "Resposta única",
        tooltip_other:
          "Adicionar opção 'Outro' com caixa de texto para resposta",
        tooltip_single_answer: "Habilita apenas uma opção de resposta",
        tooltip_none: "Adicionar 'Nenhum' como opção de resposta",
        quickly_add: "Adicionar rapidamente",
        name_a_option: "Dê um nome para uma opção e use “;” para criar outra.",
        quickly_add_placeholder: "Ex: Female;Male;",
        error: {
          empty_option: "Erro! Valor vazio na opção",
          duplicated_options: "Erro! Valores duplicados nas opções",
          duplicated_codes: "Erro! Códigos duplicados nas opções"
        }
      },
      fill_blank: {
        blank: "Espaço em branco",
        tooltip_blank: "Escreva sentenças e adicione espaços em branco",
        error: {
          error_no_blanks: "Erro! Sem espaços em branco"
        }
      },
      matrix: {
        columns: "Colunas",
        rows: "Linhas",
        column: "Coluna",
        row: "Linha",
        type: "Tipo",
        single_selection_column: "Seleção única por coluna",
        single_selection_row: "Seleção única por linha",
        multiple_selection: "Múltipla escolha",
        error: {
          empty_field: "Erro! Valor vazio no campo",
          duplicated_fields: "Erro! Valores duplicados nos campos",
          duplicated_codes: "Erro! Códigos duplicados nos campos"
        }
      },
      slider: {
        points: "Pontos",
        point: "Ponto",
        error: {
          empty_field: "Erro! Valor vazio no campo",
          duplicated_fields: "Erro! Valores duplicados nos campos",
          duplicated_codes: "Erro! Códigos duplicados nos campos"
        }
      },
      ranking: {
        cards: "Cartões",
        card: "Cartão",
        image: "Imagem",
        error: {
          empty_card: "Erro! Valor vazio no cartão",
          duplicated_cards: "Erro! Valores duplicados nos cartões",
          duplicated_codes: "Erro! Códigos duplicados nos cartões"
        }
      },
      drag_and_drop: {
        cards: "Cartões",
        card: "Cartão",
        groups: "Grupos",
        group: "Grupo",
        image: "Imagem",
        finite_cards: "Cartões finitos",
        tooltip_image: "Os cartões serão compostos por imagens e descrições",
        tooltip_finite_cards:
          "Cada cartão poderá ser classificado somente uma vez",
        error: {
          empty_card: "Erro! Valor vazio no cartão",
          duplicated_cards: "Erro! Valores duplicados nos cartões",
          duplicated_codes_cards: "Erro! Códigos duplicados nos cartões",
          empty_group: "Erro! Valor vazio no grupo",
          duplicated_groups: "Erro! Valores duplicados nos grupos",
          duplicated_codes_groups: "Erro! Códigos duplicados nos grupos"
        }
      },
      reaction: {
        select_image: "Selecione uma imagem",
        select_video: "Selecione um vídeo",
        enter_text: "Digite um texto",
        mark_text: "Marcar texto",
        reaction_label: "Etiqueta da reação",
        minimum_number: "Número mínimo de respostas",
        maximum_number: "Número máximo de respostas",
        mandatory_comment: "Comentário obrigatório",
        custom_tagging: "Marcação personalizada",
        mandatory_marking: "Marcação obrigatória",
        non_mandatory_marking: "Marcação não obrigatória",
        option: "Opção",
        error: {
          empty_text: "Erro! Sem texto",
          no_marked: "Erro! Nenhum texto marcado",
          empty_option: "Erro! Valor vazio na opção",
          duplicated_emojis: "Erro! Valores duplicados nos emojis",
          duplicated_labels: "Erro! Valores duplicados nas etiquetas",
          no_image: "Erro! Sem imagem",
          no_video: "Erro! Sem video",
          bigger: "Erro! O valor mínimo é maior que o valor máximo",
          minimum_bigger_marks:
            "Erro! Número mínimo de respostas é maior que a quantidade de textos marcados",
          no_minimum: "Erro! Sem valor mínimo",
          minimum_bigger_mandatory:
            "Erro! Número de opções obrigatórias é maior que a quantidade de textos marcados",
          no_maximum: "Erro! Sem valor máximo"
        }
      },
      media: {
        add_captions: "Adicionar legendas",
        mandatory_captions: "Legendas obrigatórias"
      },
      screen_recording: {
        mandatory_description: "Descrição obrigatória"
      },
      audio: {
        maximum_duration: "Duração máxima em segundos",
        minimum_duration: "Duração mínima em segundos",
        error: {
          no_minimum_duration: "Erro! Sem valor mínimo de duração",
          no_maximum_duration: "Erro! Sem valor de duração máxima",
          bigger_duration:
            "Erro! O valor de duração máxima é maior que o valor de duração mínima"
        }
      },
      logics: {
        logic: "Lógicas e condições",
        default: "Default",
        if_you_answer: "Se você responder",
        go_to: "Vai para",
        next: "Próximo",
        finish: "Terminar",
        and: "E",
        or: "Ou",
        add_condition: "Adicionar condição"
      },
      error: {
        error_empty_task: "Erro! Tarefa vazia",
        the_field_cannot: 'Erro! O valor do campo não pode ser apenas "0"',
        error_empty_code: "Erro! Campo do código da tarefa está vazio",
        error_empty_code_field: "Erro! Opção com campo de código vazio",
        code_validation:
          "O código da tarefa não pode conter caracteres especiais, espaços ou ser apenas 0",
        number_zero: "O código não pode ser o número 0 sozinho",
        code_string_already: "Código já em uso"
      },
      success: {
        task_successfully_created: "Tarefa criada com sucesso!",
        task_deleted: "Tarefa deletada",
        task_copied: "Tarefa copiada com sucesso"
      }
    },
    replies: {
      replies: "Respostas",
      media: "Mídias",
      charts: "Gráficos",
      exported_files: "Arquivos exportados",
      return_to_replies: "Voltar para respostas",
      track_the_status: "Acompanhe os status de seus arquivos baixados",
      filters: "filtros",
      filters_confirm_message:
        "Serão baixados somente os itens filtrados, deseja seguir com o processo de exportação?",
      filters_name: "Dê um nome para seu arquivo de exportação",
      filters_message_exported_files:
        "Ao clicar em exportar o arquivo não ficará pronto no mesmo instante, você poderá acompanha-lo em",
      format: "Formato",
      media_only: "Apenas Mídias",
      transcripts_only: "Apenas Transcrições",
      both: "Ambos",
      choose_the_format: "Escolha o formato do arquivo de exportação",
      timestamp: "Marcação de tempo",
      download: "Baixar",
      export: "Exportar",
      assignments: "Atividades",
      name: "Nome",
      labels: "Etiquetas",
      segments: "Segmentos",
      type: "Tipo",
      export_answers_labels: "Exportar respostas em texto",
      export_answers_code: "Exportar respostas em código",
      activated: "Ativada",
      disabled: "Desativada",
      assignment: "Atividade",
      community_status: "Status da comunidade",
      export_status: "Exportar Status",
      completes: "Completas",
      ends_at: "Finaliza em",
      view: "Ver",
      solved: "Resolvido",
      not_solved: "Não Resolvido",
      all: "Todos",
      see_comments: "Ver comentários",
      comment: "Comentar",
      no_reports: "Não há respostas condizentes com a sua busca",
      no_media: "Não há mídias disponíveis",
      transcription: "Transcrição",
      transcription_and_media: "Transcrição e Mídias",
      this_video_doesnt: "Este vídeo não possui transcrição",
      charts_tab: {
        chart: "Gráfico",
        customize: "Customizar",
        frequency_table: "Tabela de frequência",
        number_of_mentions: "Número de menções",
        fewer: "menos",
        more: "mais",
        font_family: "Fonte",
        colors: "Cores",
        one: "Uma",
        multiple: "Mais de uma",
        number_of_words: "Número de palavras",
        discard_words: "Descartar palavras",
        chart_type: "Tipo de gráfico",
        data_type: "Tipo de dado",
        number: "Número",
        percentage: "Porcentagem",
        option: "Opção",
        answers: "Respostas",
        wait_word_cloud:
          "Aguarde o carregamento da nuvem de palavras antes de exportar",
        download_table: "Baixar tabela",
        download_chart: "Baixar gráfico",
        download_word_cloud: "Baixar nuvem de palavras",
        total_words: "Total de palavras",
        total_responses: "Total de respostas",
        frequency_in_percentage: "Frequência em porcentagem",
        frequency_in_numbers: "Frequência em números",
        choose_font_family: "Escolha a fonte da nuvem de palavras",
        choose_colors_wordcloud:
          "Escolha uma ou mais cores para a nuvem de palavras",
        choose_colors_chart: "Escolha cores para o gráfico",
        limit_the_number_of_words:
          "Delimite o número de palavras que a nuvem irá conter",
        choose_your_chart_type: "Escolha o tipo do gráfico",
        answered: "Respostas",
        download_heatmap: "Baixar mapa de calor",
        discard_words_from_the_cloud: "Descarte palavras da nuvem",
        choose_data_format: "Escolha o formato do dado",
        words: "Palavras",
        dot: "Ponto",
        choose_heatmap: "Escolha as configurações dos pontos no mapa de calor",
        max: "Máximo",
        radius: "Raio",
        maxOpacity: "Opacidade máxima",
        blur: "Desfoque",
        wait_changes: "Aguarde as mudanças aplicadas carregarem"
      },
      assignments_tab: {
        real_time_answers: "Respostas em tempo-real",
        number_completes: "Respondentes",
        open: "Abrir respostas",
        modal: {
          do_you_really_want_to_delete:
            "Tem certeza de que deseja excluir esta resposta?",
          delete_reply: "Excluir resposta",
          success: {
            reply_deleted: "Resposta deletada com sucesso"
          }
        }
      },
      dashboard: {
        external_id: "ID Externo",
        name: "Nome",
        progress: "Progresso",
        completed: "Completa",
        not_started: "Não iniciada",
        incomplete: "Incompleta",
        initial: "Inicial",
        email: "E-mail",
        general_status: "Status Geral",
        send_message: "Enviar mensagem",
        no_participants: "Não há participantes disponíveis agora"
      },
      mark_reply_viewed: "Marcar resposta como visualizada",
      reply_viewed: "Resposta visualizada",
      moderate_viewed_answer: "O moderador visualizou sua resposta",
      are_you_sure_delete_report:
        "Tem certeza que você deseja deletar esse relatório?",
      report_deleted: "Relatório deletado"
    },
    forum: {
      search_post: "Pesquisar publicação",
      export_forum: "Exportar Fórum",
      welcome_to_forum: "Bem-vindo ao nosso fórum!",
      create_post_engage:
        "Crie uma publicação para gerar discussões entre os participantes",
      join_discussion: "Junte-se a uma discussão com outros participantes",
      no_posts: "Sem publicações",
      delete_post: "Deletar Publicação",
      are_you_sure_delete_post:
        "Tem certeza que você deseja deletar essa publicação?",
      edited: "Editado",
      success: {
        post_deleted: "Publicação deletada com sucesso"
      },
      new_post: {
        new_post: "Nova publicação",
        post: "Publicação",
        title: "Titulo",
        add_title_post: "Adicione um título para a sua publicação",
        write_new_post: "Escreva um nova publicação",
        enable_reply: "Habilitar edição de respostas",
        activate_post: "Ativar publicação",
        enabled: "Habilitado",
        not_enabled: "Desabilitado",
        desactivate: "Desativado",
        activate: "Ativado",
        segmentation: "Segmentação",
        add_segment: "Adicionar segmento",
        all: "Todos",
        selected_segments: "Segmentos selecionados",
        success: {
          post_created: "Post criado com sucesso"
        },
        error: {
          empty_post: "Erro! Post vazio",
          empty_title: "Error! Titulo vazio"
        }
      },
      comments: {
        comment: "Comentar",
        write_new_comment: "Escreva um novo comentário",
        no_comments: "Sem comentários",
        success: {
          comment_created: "Comentário criado com sucesso"
        },
        error: {
          empty_comment: "Erro! Comentário vazio"
        }
      },
      subreply: {
        delete_reply: "Apagar resposta",
        are_you_sure_delete_reply:
          "Tem certeza que você deseja deletar essa resposta?",
        success: {
          reply_deleted: "Resposta deletada com sucesso"
        }
      }
    },
    account_settings: {
      account_settings: "Configurações da conta",
      name_text: "Nome",
      email_text: "E-mail",
      reset_pwd: "Redefinir senha",
      old_password_text: "Senha Antiga",
      new_password_text: "Nova senha",
      confirm_new_password_text: "Confirmar a nova senha",
      name_validation: "O nome não pode conter caracteres especiais ou números",
      success: {
        name_success: "Nome alterado com sucesso ",
        picture_success: "Foto alterada com sucesso ",
        password_success: "Senha alterada com sucesso "
      },
      error: {
        nothing_to_change: "Nada para mudar",
        name_error: "Erro ao alterar nome ",
        picture_error: "Erro ao alterar foto ",
        password_error: "Erro ao alterar senha ",
        different_password: "A senha não corresponde à senha atual"
      }
    },
    terms_and_policies: {
      privacy_policy: "Política de Privacidade",
      terms_of_use: "Termos de Uso",
      edit_content: "Editar conteúdo",
      i_accept_the_terms_os_use: "Eu aceito os",
      i_accept_the_privacy_policy: "Eu aceito a",
      success: {
        privacy_policy_successfully_changed:
          "Política de privacidade atualizada com sucesso",
        terms_of_use_successfully_changed:
          "Termos de uso atualizado com sucesso"
      }
    },
    customize_community: {
      customize_account: "Customização de Página",
      choose_text:
        "Escolha a paleta de cores que mais se parece com a sua marca.",
      upload_banner: "Faça upload do banner da comunidade.",
      upload_logo: "Faça upload do seu logotipo.",
      banner_description:
        "Crie uma breve descrição da comunidade que aparecerá junto ao banner",
      description_placeholder: "Ex. Bem vindo a Comunidade...",
      add_message: "Adicionar mensagem",
      customize_pages: "Personalizar páginas"
    },
    add_message: {
      add_custom_texts:
        "Adicione textos personalizados para seus respondentes.",
      message: "Mensagem",
      title: "Título",
      supporting_text: "Texto de apoio",
      image: "Imagem",
      stop_display: "Parar exibição",
      save: "Salvar",
      messages_created_success: "Mensagens foram criados com sucesso",
      messages_updated_success: "Mensagens foram atualizadas com sucesso",
      validate_feed_list_message:
        'Todas as mensagens que a opção "parar exibição" não estiver habilitada deverão ter título, texto de apoio e imagem preenchidos'
    },
    logs: {
      date: "Data",
      description: "Descrição",
      oldest: "Mais antigo",
      newest: "Mais recente"
    },
    notifications: {
      notifications: "Notificações",
      no_notifications: "Sem notificações no momento",
      mod_commented_reply: "O moderador comentou uma de suas respostas",
      responded_comment: "respondeu seu comentário",
      created_new_post: "Criou uma nova publicação",
      commented_in_post: "comentou seu post",
      commented_in_comment: "comentou seu comentário",
      liked_post: "gostou do seu post",
      liked_comment: "gostou do seu comentário",
      reply_rejected: "Sua resposta foi rejeitada",
      new_assignment_available: "Nova atividade disponível",
      comment_rejected: "Seu comentário foi rejeitado",
      task_updated: "Essa tarefa foi atualizada",
      forum: "Fórum",
      assignment: "Atividade",
      created_community: "Comunidade criada pelo admin",
      comunity: "Comunidade",
      changed_terms_and_conditions: "O admin alterou os termos e condições",
      terms_and_conditions: "Termos e condições"
    },
    pagination: {
      skip: "Pular",
      previous: "Anterior",
      continue: "Continuar",
      save_and_continue: "Salvar e continuar",
      finish: "Finalizar"
    },
    dropzone: {
      attachments:
        "Arraste e solte os arquivos aqui ou clique para selecioná-los. Você pode inserir mais de um arquivo por vez",
      image: "Arraste uma imagem ou clique para selecionar",
      video: "Arraste e solte o vídeo aqui ou clique para selecioná-lo",
      multimedia:
        "Arraste e solte os vídeos e fotos aqui ou clique para selecioná-los. Você pode inserir mais de um arquivo por vez.",
      can_not_upload_files: "Você não pode enviar mais arquivos",
      your_browser_does_not_support:
        "Seu navegador não suporta uploads de arquivos de arrastar e soltar.",
      please_use_the_fallback:
        "Por favor, use o formulário alternativo abaixo para enviar seus arquivos como nos velhos tempos.",
      file_is_too_big:
        "O arquivo é muito grande ({{filesize}}MiB). Tamanho máximo do arquivo: {{maxFilesize}}MiB",
      you_cant_upload: "Você não pode enviar arquivos deste tipo.",
      server_responded_with_status_code:
        "O servidor respondeu com o código {{statusCode}}.",
      cancel_upload: "Cancelar upload",
      are_you_sure_cancel: "Tem certeza de que deseja cancelar este upload?",
      remove_file: "Remover arquivo",
      file_uploaded_successfully: "Arquivo carregado com sucesso"
    },
    uploader: {
      add_media: "Adicionar mídia",
      warn_uploader_modal:
        "Você ainda não concluiu o carregamento de seu(s) vídeo(s), deseja prosseguir mesmo assim? Os vídeos que não foram totalmente carregadas não serão adicionadas como parte da sua resposta"
    },
    conversation: {
      answer: "respostas"
    },
    labels: {
      show_more: "Ver mais",
      show_less: "Ver menos",
      add_label: "Adicionar Etiqueta",
      create_label: "Criar Etiqueta",
      label_name: "Nome",
      label_description: "Descrição",
      label_background: "Cor do fundo",
      label_color: "Cor do texto",
      all_available: "Todas as etiquetas disponíveis já foram adicionadas",
      modal: {
        are_you_sure_delete_label:
          "Tem certeza que você deseja deletar essa etiqueta?",
        delete_label: "Deletar Etiqueta"
      },
      success: {
        label_removed: "Etiqueta Removida",
        added_label: "Etiqueta adicionada",
        deleted_label: "Etiqueta Deletada",
        label_created: "Eiqueta criada"
      }
    },
    segmentation: {
      segmentation: "Segmentação",
      create_segmentation: "Criar segmentação",
      name_a_segment: "Dê um nome para um segmento e use “;” para criar outro.",
      segments_placeholder: "Ex: Feminino;Masculino;",
      no_segments: "Não há segmentos disponíveis agora",
      table: {
        name: "Nome"
      },
      modal: {
        do_you_really_want_to_delete:
          "Tem certeza de que deseja excluir este segmento?",
        delete_segment: "Excluir segmento",
        success: {
          segment_deleted: "Segmento deletado com sucesso"
        }
      },
      success: {
        added_segment: "Segmento adicionado"
      }
    },
    add_participant: {
      add_participant: "Adicionar Participante",
      import_lot: "Importar Lote",
      registration_link: "Link de Registro",
      import: "Importar",
      invite_participants: "Convidar Participantes",
      download_pattern: "Baixar padrão",
      import_lot_xlsx: "Importar lote em arquivo XLSX:",
      if_you_have_not: "Caso ainda não tenha criado as segmentações",
      drag_and_drop_the_XLSX:
        "Arraste e solte o arquivo XLSX ou selecione do seu computador.",
      edit_as_many_fields: "Edite os campos que desejar.",
      please_check_all:
        "Confira todos os dados atenciosamente antes de enviar. Ao pressionar enviar o convite será disparado para todos os contatos da lista.",
      email_subject: "Assunto do E-mail",
      email_body: "Corpo do E-mail",
      generate_link_to_all: "Gerar link para todos ou por grupo de segmentos",
      search_segment: "Pesquisar segmento",
      number_of_people:
        "Número de pessoas que poderão entrar com o link gerado",
      all: "Todos",
      people_will_be_able: "Pessoas poderão entrar com o link.",
      generate_link: "Gerar Link",
      generated_links: "Links Gerados",
      no_links: "Não há atividades disponíveis agora",
      registered_using_this_link: "se registraram usando esse link",
      segments_linked_to_the_link: "Segmentos vínculados ao link",
      show_more: "Ver mais",
      show_less: "Ver menos",
      copy_link: "Copiar Link",
      disable_link: "Desativar Link",
      activate_link: "Ativar Link",
      delete_link: "Deletar Link",
      do_you_really_want_to_delete:
        "Tem certeza de que deseja excluir este link?",
      success: {
        link_copied: "Link copiado",
        link_deleted: "Link deletado com sucesso",
        link_generated: "Link Gerado"
      },
      errors: {
        empty_xlsx: "Erro! XLSX vazio",
        missing_keys: "Erro! Chave faltando",
        duplicated_email: "Erro! E-mail duplicado",
        inactive_logging: "Canal de registro inativo",
        not_within_the_pattern:
          "O arquivo não está dentro do padrão, tente novamente.",
        the_number_of_people: "O número de pessoas deve ser maior ou igual a 1",
        different_segments: "A comunidade e o arquivo têm segmentos diferentes"
      },
      warn: {
        is_already: "já está em uma comunidade ativa",
        invalid_email: "E-mail inválido"
      }
    },
    operators: {
      add_manage_operators: "Adicionar e Gerenciar Operadores",
      add_operators: {
        add_operators: "Adicionar Operadores",
        name_operator: "Nome do Operador",
        placeholder_operator: "Ex. Carlos Antônio",
        placeholder_email: "Ex. carlos@gmail.com",
        type_access: "Tipo de acesso",
        admin: "Administrador",
        admin_text: {
          attention_label: "Atenção: ",
          admin_text:
            "Esse tipo de acesso dá ao moderador controle sobre toda a comunidade."
        },
        observer: "Observador",
        observer_text: "Descrição do que é um observador",
        moderator: "Moderador",
        moderator_text: "Descrição do que é um moderador"
      },
      manage_operators: {
        manage_operators: "Gerenciar Operadores",
        name: "Nome",
        edit: "Editar",
        reset_password: "Resetar senha",
        disable_access: "Desativar acesso",
        resend_invitation: "Reenviar Convite",
        enable_access: "Ativar acesso",
        delete_access: "Deletar acesso",
        delete_operator: "Deletar acesso",
        delete_operator_label:
          "Tem certeza que você deseja deletar esse acesso?",
        successfully_deleted_access: "Acesso deletado com sucesso",
        error_deleting_access: "Erro ao deletar o acesso",
        successfully_disabled_access: "Acesso desativado com sucesso",
        error_disabled_access: "Erro ao desativar o acesso",
        successfully_activated_access: "Acesso ativado com sucesso",
        error_activated_access: "Erro ao ativar o acesso",
        error_reset_password: "Erro ao resetar a senha",
        password_reset_successfully: "Senha resetada com sucesso",
        no_operator: "Nenhum operador está disponível agora",
        user_has_already: "Usuário já realizou o primeiro acesso"
      },
      create_access: "Criar acesso",
      operator_created: "Operador criado com sucesso",
      email_already: "E-mail já está sendo utilizado",
      invalid_name: "Nome inválido",
      name_validation: "O nome não pode conter caracteres especiais ou números"
    },
    manage_participants: {
      registered: "Cadastrados",
      invited: "Convidados",
      profiles: "Perfis",
      name: "Nome",
      email: "E-mail",
      external_id: "ID Externo",
      created_at: "Criado em",
      read_at: "Visto em",
      joined_at: "Entrou em",
      last_login: "Último login",
      bulk_action: "Ação em massa",
      resend_invitation: "Reenviar Convite",
      block_invitation: "Bloquear Convite",
      no_participants: "Não há participantes disponíveis agora",
      view_participant: "Ver participante",
      segments: "Segmentos"
    },
    chat: {
      admin: "Administrador",
      participant: "Participante",
      moderator: "Moderador",
      observer: "Observador",
      enter_a_message: "Digite uma mensagem",
      no_users: "Perdão, encontramos 0 resultados.",
      select_a_conversation: "Selecione uma conversa",
      view_operators: "Ver operadores",
      transmission_list: "Lista de transmissão",
      my_lists: "Minhas listas",
      select: "Selecionar",
      list_name: "Nome da lista"
    },
    modal_welcome: {
      nubank_title: "Bem vindo ao Lab!",
      nubank:
        "Esse é um espaço seguro autorizado e sancionado pela Nubank onde você poderá compartilhar suas opiniões sobre diversos temas. Para seu cadastro e login, siga as instruções abaixo e crie uma senha para entrar na plataforma. Lembre-se de que essa é uma plataforma de estudos de opinião e não um acesso à sua conta bancária da Nubank, portanto não utilize  credenciais (como senhas e registros) de sua conta bancária e demais informações pessoais.",
      default_title: "Bem vindo à Ow Voices!",
      default:
        "Esse é um espaço seguro para você compartilhar suas opiniões sobre diversos temas. Para seu cadastro e login, siga as instruções abaixo e crie uma senha para entrar na plataforma. Lembre-se de que essa é uma plataforma de estudos de opinião, portanto não utilize suas credenciais (como senhas e registros) de contas bancárias e demais informações pessoais."
    },
    stats_assignments: {
      assignments_statistics: "Estatísticas das Atividades",
      assignment: "Atividade",
      start_and_end: "Início e Fim",
      number_of_participants: "Nº de participantes",
      number_of_assignments: "Nº de  atividades",
      complete: "Completas",
      incomplete: "Incompletas",
      not_started: "Não iniciada",
      no_assignment: "Não há atividades disponíveis agora",
      export_statistics: "Exportar estatísticas",
      personal_progress: "Progresso Pessoal"
    },
    privacy_and_permissions: {
      privacy_and_permissions: "Privacidade e Permissões",
      privacy: "Privacidade",
      permissions: "Permissões",
      participants: "Participantes",
      moderators: "Moderadores ",
      observers: "Observadores",
      identity_of_participants: "Identidade dos participantes",
      identity_of_admins: "Identidade dos administradores",
      how_participants_are_displayed_to_each_other:
        "Como os participantes são exibidos uns para os outros. ",
      how_admins_are_displayed:
        "Como os administradores são exibidos para os participantes.",
      admins_and_moderators_can_view_all_details:
        "Administradores e moderadores podem visualizar todos os detalhes dos participantes, mas a exibição dos nomes dos participantes pode ser personalizada por questões de conveniência. ",
      do_not_display_participant_email:
        "Não exibir e-mail do participante em nenhuma página em que é comumente exibido",
      do_not_display_participant_last_login_info:
        "Não exibir data e hora do último login do participante em nenhuma página em que é comumente exibido",
      how_participants_are_displayed_to_clients:
        "Como os participantes são exibidos para os clientes.",
      do_not_display_participant_segments:
        "Não exibir segmentos do participante em nenhuma página em que é comumente exibido",
      do_not_display_participant_personal_progress:
        "Não exibir progresso pessoal do participante em nenhuma página em que é comumente exibido  ",
      do_not_display_comments_on_replies_made_by_operators:
        "Não exibir comentários nas respostas feitos por administradores e moderadores",
      pages: "Páginas",
      by_default_observers:
        "Por padrão, Observadores podem visualizar todas as páginas abaixo, mas a restrição pode ser personalizada por questões de conveniência. Demais páginas não listadas abaixo não estarão visíveis para os Observadores por padrão.",
      do_not_display_assignments_statistics:
        "Não exibir ‘Estatísticas das Atividades’ ",
      do_no_display_replies_in_the_reports_menu:
        "Não exibir ‘Respostas’ no menu Relatórios",
      do_no_display_media_in_the_reports_menu:
        "Não exibir ‘Mídias’ no menu Relatórios",
      do_no_display_charts_in_the_reports_menu:
        "Não exibir ‘Gráficos’ no menu Relatórios",
      do_no_display_manage_participants:
        "Não exibir ‘Gerenciamento de Participantes’",
      by_default_moderators:
        "Por padrão, Moderadores podem visualizar todas as páginas abaixo, mas a restrição pode ser personalizada por questões de conveniência. Demais páginas não listadas abaixo não estarão visíveis para os Moderadores por padrão.",
      do_not_display_customization: "Não exibir 'Customização'",
      do_not_display_terms_ans_policies: "Não exibir 'Termos e Políticas'",
      do_not_display_create_assignments: "Não exibir 'Criar atividades'",
      full_name: 'Nome completo ("João Silva")',
      first_name_and_last_name_initial:
        'Nome e inicial do sobrenome ("João S")',
      name_only: 'Apenas nome ("João")',
      name_and_unique_id: 'Nome e ID exclusiva ("João 1234")',
      unique_id_only: 'Somente ID exclusiva ("1234")'
    },
    global: {
      return: "Voltar",
      next: "Próximo",
      edit: "Editar",
      save: "Salvar",
      delete: "Deletar",
      copy: "Copiar",
      search: "Buscar",
      sorry_we_have: "Perdão, encontramos 0 resultados para",
      cancel: "Cancelar",
      save_alterations: "Salvar Alterações",
      order_by: "Ordenar por",
      filter_by: "Filtrar por",
      apply: "Aplicar",
      send: "Enviar",
      load_more: "Carregue mais",
      add: "Adicionar",
      disable: "Desativar",
      activate: "Ativar",
      click_here: "Clique aqui",
      actions: "Ações",
      clean: "Limpar",
      show_more: "Ver mais",
      show_less: "Ver menos",
      yes: "Sim",
      no: "Não",
      error: "Erro",
      ready: "Pronto",
      processing: "Processando"
    },
    errors_global: {
      pwd_eight_characters:
        "Sua senha deve conter 8 caracteres, ao menos um número, um caractere especial, uma letra maiúscula e uma letra minúscula",
      invalid_combination: "Combinação de senhas inválida",
      something_went_wrong: "Algo deu errado. Tente novamente mais tarde.",
      invalid_user_or_pwd: "Nome de usuário ou senha inválida",
      fill_fields: "Preencha todos os campos solicitados",
      limit_exceeded_images:
        "Limite excedido! Você pode enviar no máximo 10 imagens",
      limit_exceeded_video:
        "Limite excedido! Você pode enviar no máximo 5 vídeos",
      limit_exceeded_attachments:
        "Limite excedido! Você pode enviar no máximo 5 arquivos",
      you_have_exceeded: "Você excedeu o limite de caracteres",
      you_have_exceeded_word_limit: "Você excedeu o limite de palavras",
      observers_are_not_allowed:
        "Observadores não têm permissão para acessar a página"
    },
    success_global: {
      changes_saved: "As mudanças foram salvas"
    }
  },
  es: {
    login: {
      login_text: "Acceso",
      email_text: "Correo electrónico",
      password_text: "Contraseña",
      login_button: "Acceso",
      forgot_password_text: "Olvidé mi contraseña",
      error: {
        community_ended_for_participants:
          "La comunidad ya ha terminado para los participantes",
        community_hasnt_started: "La comunidad aún no ha comenzado",
        community_ended_for_moderators:
          "La comunidad ya ha expirado para los moderadores",
        community_not_active: "La comunidad no comenzó",
        update_failed:
          "¡Se produjo un error al actualizar su cuenta! Comuníquese con un moderador"
      },
      success: {
        account_registered: "Ya hemos encontrado su registro, inicie sesión"
      }
    },
    email: {
      reset_password_text: "Restablecimiento de contraseña",
      type_your_email_text: "Escribe tu correo electrónico",
      email_text: "Correo electrónico",
      send_email_button: "Enviar",
      error: {
        email_does_not_exist: "Este email no existe"
      },
      success: {
        sent_email: "E-mail enviado"
      }
    },
    feed: {
      expired_community:
        "Tu comunidad ya ha expirado. Tiene 30 días para ver y exportar los datos, después de lo cual la comunidad no estará disponible."
    },
    pass: {
      reset_password_text: "Restablecimiento de contraseña",
      create_new_pwd: "Crea tu nueva contraseña",
      new_password_text: "Nueva contraseñ",
      confirm_new_password_text: "Confirma tu nueva contraseña",
      type_your_password_text: "Escribe tu nueva contraseña",
      save_text: "Guardar",
      success: {
        pwd_successfully_changed: "Tu contraseña ha sido exitosamente cambiada"
      }
    },
    communities: {
      communities: "Comunidades",
      community: "Comunidad",
      create_community: "Crear comunidad",
      edit_community: "Editar comunidad",
      name_text: "Nombre",
      description_text: "Descripción",
      available_period_text: "Tiempo disponible",
      start_time: "Comenzar en",
      end_time: "Plazo",
      timezone_text: "Fuso Horario",
      language_text: "Idioma",
      domain_text: "Dominio de la comunidad",
      see_community: "Ver comunidad",
      success: {
        community_created: "Comunidad creada con éxito",
        community_copied: "Comunidad copiada con éxito"
      },
      error: {
        domain_validation:
          "El dominio de su comunidad no puede contener caracteres especiales, espacios ni letras mayúsculas",
        domain_already_being_used:
          "Este dominio es válido, pero ya se está utilizando"
      }
    },
    create_account: {
      create_acc: "Cree una cuenta",
      fill_info: "Inserta tu información y crea tu cuenta",
      email_text: "Correo electrónico",
      name_text: "Nombre",
      password_text: "Contraseña",
      confirm_pwd: "Confirmar Contraseña",
      profile_pic: "Foto de perfil",
      save_text: "Guardar",
      success: {
        acc_successfully_created: "Cuenta creada con éxito"
      },
      error: {
        email_already_registered:
          "Correo electrónico ya registrado. Para acceder, inicie sesión con su correo electrónico y contraseña."
      }
    },
    header: {
      feed_text: "Feed",
      assignments_text: "Actividades",
      reports_text: "Informes",
      forum_text: "Foro",
      see_assignments: "Ver actividades",
      add_assignment: "Crear Actividad",
      admin_text: "Admin",
      moderator_text: "Moderador",
      observer_text: "Observador",
      participant_text: "Participante",
      add_participant_text: "Añadir Participante",
      manage_participant_text: "Administrar Participantes",
      community_text: "Comunidad",
      segmentation_text: "Segmentación",
      personalize_text: "Personalizar",
      moderation_text: "Moderación",
      account_settings_text: "Configuraciones de la cuenta",
      customize_text: "Personalizar",
      logs: "Historial",
      terms_and_policies: "Terminos y políticas",
      logout: "Cerrar sesión"
    },
    next_assignment: {
      next_assignment_text: "Próxima Actividad",
      begin_assignment: "Comenzar actividad",
      glad_back_text:
        "¡Nos alegra que hayas vuelto! ¿Listo para contribuir con algo nuevo?",
      completed_assignments: "Actividades completadas"
    },
    community_guide: {
      community_guide_text: "Guía de la comunidad",
      carrousel_text_1: "Completa las siguientes Actividades para contribuir",
      carrousel_text_2:
        "Únete a una discusión e interactúa con otros participantes",
      carrousel_text_3: "Revisa las respuestas y haz comentarios",
      add_an_image: "Añadir una imagen",
      add_an_message: "Añade un mensaje",
      ex_message_title: "Ej: título del mensaje",
      ex_message_support_text: "Ej: texto de soporte de mensajes"
    },
    footer: {
      privacy_policy: "Política de privacidad",
      terms_of_use: "Términos de uso"
    },
    assignment: {
      assignment: "Actividad",
      edit_assignment: "Editar actividad",
      copy_assignment: "Copiar actividad",
      edit_task: "Editar tareas",
      view_assignment: "Ver actividad",
      no_activity: "No hay actividades disponibles ahora",
      are_you_sure_delete_assignment:
        "¿Realmente quieres eliminar esta actividad?",
      completed: "Completa",
      success: {
        assignment_deleted: "Actividad eliminada",
        assignment_copied: "Actividad copiada"
      },
      error: {
        unable_to_delete:
          "¡Error! Solo puede eliminar actividades sin respuestas"
      }
    },
    create_assignment: {
      add_assignment: "Crear Actividad",
      assignment_name_text: "Título",
      available_period_text: "Tiempo disponible",
      start_time: "Comenzar en",
      end_time: "Plazo",
      who_can_see_text: "Elige quién puede ver esta Actividad",
      who_will_see_1: "Todos",
      who_will_see_2: "Segmentos",
      answer_visibility_text: "Elige la visibilidad de la respuesta",
      answer_visibility_option_1:
        "Todas las respuestas serán visibles para el encuestado",
      answer_visibility_option_2:
        "El encuestado puede ver todas las respuestas después de completar la Actividad",
      answer_visibility_option_3:
        "El participante no puede ver ninguna de las respuestas de los demás",
      sequenced: "Secuenciar Tareas",
      activated: "Activar Actividad",
      deactivate: "Desactivar Actividad",
      chained: "Encadenado",
      tooltip_sequenced: "Las tareas aparecerán en el orden de creación",
      tooltip_activated:
        "La actividad quedará visible para todos los participantes seleccionados",
      save_and_continue: "Guardar y continuar",
      no_options: "No hay opciones disponibles ahora",
      add_tasks_to_activate: "Añadir tareas para activar la actividad",
      success: {
        assignment_created: "Actividad creada correctamente"
      }
    },
    task: {
      task: "Tarea",
      tasks: "Tareas",
      see_conversation: "Ver conversación",
      text_type: "Texto",
      survey_type: "Encuesta",
      fill_blank_type: "Espacios en blanco",
      matrix_type: "Matriz",
      slider_type: "Deslizador",
      drag_drop_type: "Arrastrar y soltar",
      image_type: "Imágenes",
      video_type: "Video",
      attachment_type: "Archivo",
      ranking_type: "Clasificación",
      reaction_type: "Calificación de imágenes",
      video_evaluation_type: "Calificación de video",
      text_evaluation_type: "Marcado de texto",
      multimedia_type: "Multimedia",
      audio_type: "Audio",
      screen_recording_type: "Grabación de pantalla",
      text_description:
        "Cree preguntas que los participantes puedan responder con palabras y imágenes.",
      survey_description:
        "Cree una pregunta con preguntas de opción múltiple o de selección única.",
      fill_blank_description:
        "Escribe oraciones con espacios en blanco donde los participantes proporcionen la palabra que falta.",
      matrix_description: "Cree una tabla de opciones para evaluar.",
      slider_description:
        "Cree un deslizador con palabras o emojis para clasificar elementos o declaraciones en una escala.",
      drag_n_drop_description:
        "Pida a los participantes que arrastren tarjetas de texto o imágenes y las suelten en uno o más grupos coincidentes.",
      image_description: "Cree una tarea que será respondida con imágenes.",
      video_description: "Cree una tarea que será respondida con videos.",
      attachment_description:
        "Cree una tarea que será respondida con archivos.",
      ranking_description:
        "Cree una lista de atributos para clasificarlos en orden.",
      reaction_description:
        "Use marcadores y comentarios para calificar las imágenes.",
      video_evaluation_description:
        "Use marcadores y comentarios para calificar videos.",
      text_evaluation_description:
        " Marque textos para indicar su importancia.",
      screen_recording_description:
        "Permita que los participantes graben su pantalla mientras navegan por un sitio web.",
      multimedia_description:
        "Cree una tarea para ser respondida con imágenes y/o videos.",
      audio_description:
        "Cree una tarea para ser respondida con grabaciones de audio.",
      no_tasks: "No hay tareas disponibles ahora",
      participants: "Participantes",
      answers: "Respuestas",
      chart: "Gráfico",
      no_answers: "No hay respuestas disponibles ahora",
      caption: "Leyenda",
      add_captions_images: "Agrega leyendas a tus imágenes",
      add_captions_videos: "Agrega leyendas a tus videos",
      add_captions_files: "Agrega leyendas a tus archivos",
      upload_images: "Sube tus imágenes",
      upload_videos: "Sube tus videos",
      upload_files: "Sube tus archivos",
      you_cant_take_screenshot:
        "No puedes tomar una captura de pantalla de la tarea."
    },
    answer_task: {
      comments: "Comentar",
      obligatory_comment_tost: "El comentario es obligatorio para esta tarea",
      obligatory_comment: "Comentario obligatorio",
      word_validation: "Validación de palabras",
      character_validation: "Validación de caracteres",
      min_characters: "El comentario debe tener al menos",
      characters: "caracteres",
      min_word: "El comentario debe tener al menos",
      words: "palabras",
      drag_and_drop: {
        drag_and_drop: "Arrastre las tarjetas al grupo que desee"
      },
      survey: {
        select_alternatives: "Seleccione una o más alternativas",
        choose_alternatives: "Elige una alternativa",
        other: "Otro",
        none: "Ninguna"
      },
      fill_blank: {
        fill_blank_spaces: "Complete los espacios en blanco"
      },
      reaction: {
        select_option: "Seleccione una opción",
        minimum_number: "Número mínimo de respuestas",
        maximum_number: "Número máximo de respuestas",
        are_mandatory: "son obligatorios",
        is_mandatory: "es obligatorio"
      },
      screen_recording: {
        description: "Descrição",
        descriptions_are_mandatory: "Las descripciones son obligatorias",
        opening_link: "Error al abrir link",
        popup_blocker:
          "¡El bloqueador de pop-ups está habilitado! Agregue este site su lista de excepciones.",
        task_cannot_be_answered:
          "Esta tarea no se puede responder usando celulares o tabletas",
        minimum_number_recordings: "Número mínimo de grabaciones",
        maximum_number_recordings: "Número máximo de grabaciones",
        start_recording: "Iniciar grabación de pantalla",
        end_screen_recording: "Finalizar grabación de pantalla",
        recording: "Grabación",
        task_instruction:
          'Presione "Iniciar Grabación de Pantalla" para comenzar el ejercicio. Si está utilizando varias pantallas en su computadora, recuerde elegir la pantalla correcta al iniciar. Durante el ejercicio, es posible que se le redirija a otro sitio web. Para finalizar la tarea, vuelve a la plataforma y haz clic en "Finalizar Grabación de Pantalla".'
      },
      audio: {
        minimum_duration: "Duración mínima",
        maximum_duration: "Duración máxima"
      },
      success: {
        reply_registered: "Tarea registrada",
        assignment_completed: "Tarea finalizada"
      },
      error: {
        missing_comments: "Comentarios faltantes en las reacciones",
        empty_answer: "Respuesta vacía",
        reply_not_registered: "¡Error! La respuesta no está registrada",
        use_all_cards: "Usa todas las cartas al menos una vez",
        empty_column: "Columna vacía",
        empty_row: "Linea vacía",
        fill_one: "Llenar al menos uno",
        other_unanswered: "Campo 'Otro' sin respuesta",
        answer_must_have: "Tu respuesta debe tener al menos",
        fill_all_blanks: "Completa todos los espacios en blanco",
        missing_captions: "A las imágenes les faltan las leyendas"
      }
    },
    create_task: {
      add_task: "Crea una tarea",
      write_the_task: "Escribe la tarea",
      edit_confirm_message:
        "Esta actividad ya tiene respuestas. Este cambio resultará en la pérdida de su base de datos. Estás seguro de que quieres continuar?",
      reorder_tasks: "Reordenar tareas",
      reorder_modal:
        "Al reordenar las tareas de esta actividad, se borrarán todas las lógicas configuradas y respuestas. Estás seguro de que quieres continuar?",
      delete_modal:
        "Al eliminar las tareas de esta actividad, se borrarán todas las lógicas configuradas. Estás seguro de que quieres continuar?",
      delete_task_modal: "¿Está seguro de que desea eliminar esta tarea?",
      comment_description: "Descripción del cuadro de comentarios",
      comment_description_placeholder: "Ej. Comentario",
      survey: {
        add_option: "Agregar opción",
        other_option: "Opción 'Otro'",
        none_option: "Opción 'Ninguna'",
        single_option: "Respuesta única",
        tooltip_other:
          "Agrega la opción 'Otro' com una caja de texto para la respuesta",
        tooltip_single_answer: "Habilita apenas una opción de respuesta",
        tooltip_none: "Agrega la opción 'Ninguno' como opción de respuesta",
        quickly_add: "Agregar rápidamente",
        name_a_option: "Nombre una opción y use ';' para crear otra.",
        quickly_add_placeholder: "Ej: Mujer;Hombre;",
        error: {
          empty_option: "¡Error! Valor vacío en la opción",
          duplicated_options: "¡Error! Valores duplicados en las opciones",
          duplicated_codes: "¡Error! Códigos duplicados en las opciones"
        }
      },
      fill_blank: {
        blank: "Espacio en blanco",
        tooltip_blank:
          "Escribe el enunciado y haz clic en el botón para agregar un espacio en blanco en la frase",
        error: {
          error_no_blanks: "¡Error! Sin espacios en blanco"
        }
      },
      matrix: {
        columns: "Columnas",
        rows: "Filas",
        column: "Columna",
        row: "Fila",
        type: "Tipo",
        single_selection_column: "Selección única por columna",
        single_selection_row: "Selección única por fila",
        multiple_selection: "Selección múltiple",
        error: {
          empty_field: "¡Error! Valor vacío en lo campo",
          duplicated_fields: "¡Error! Valores duplicados en los campos",
          duplicated_codes: "¡Error! Códigos duplicados en los campos"
        }
      },
      slider: {
        points: "Puntos",
        point: "Punto",
        error: {
          empty_field: "¡Error! Valor vacío en lo campo",
          duplicated_fields: "¡Error! Valores duplicados en los campos",
          duplicated_codes: "¡Error! Códigos duplicados en los campos"
        }
      },
      ranking: {
        cards: "Tarjetas",
        card: "Tarjeta",
        image: "Imagen",
        error: {
          empty_card: "¡Error! Valor vacío en la tarjeta",
          duplicated_cards: "¡Error! Valores duplicados en las tarjetas",
          duplicated_codes: "¡Error! Códigos duplicados en las tarjetas"
        }
      },
      drag_and_drop: {
        cards: "Tarjetas",
        card: "Tarjeta",
        groups: "Grupos",
        group: "Grupo",
        image: "Imagen",
        finite_cards: "tarjetas finitas",
        tooltip_image: "Las tarjetas se componen de imágenes y descripciones.",
        tooltip_finite_cards: "Cada tarjeta solo se puede clasificar una vez.",
        error: {
          empty_card: "¡Error! Valor vacío en la tarjeta",
          duplicated_cards: "¡Error! Valores duplicados en las tarjetas",
          duplicated_codes_cards: "¡Error! Códigos duplicados en las tarjetas",
          empty_group: "¡Error! Valor vacío en lo grupo",
          duplicated_groups: "¡Error! Valores duplicados en los grupos",
          duplicated_codes_groups: "¡Error! Códigos duplicados en los grupos"
        }
      },
      reaction: {
        select_image: "Seleccionar una imagen",
        select_video: "Seleccionar un video",
        enter_text: "Ingrese un texto",
        mark_text: "Marcar texto",
        reaction_label: "Etiqueta de reacción",
        minimum_number: "Número mínimo de respuestas",
        maximum_number: "Número máximo de respuestas",
        mandatory_comment: "Comentario obligatorio",
        custom_tagging: "Etiquetado personalizado",
        mandatory_marking: "Etiqueta obligatoria",
        non_mandatory_marking: "Etiqueta no obligatoria",
        option: "Opción",
        error: {
          empty_text: "¡Error! Sin texto",
          no_marked: "¡Error! Sin texto marcado",
          empty_option: "¡Error! Valor vacío en opción",
          duplicated_emojis: "¡Error! Valores duplicados en emojis",
          duplicated_labels: "¡Error! Valores duplicados en etiquetas",
          no_image: "¡Error! Sin imagen",
          no_video: "¡Error! Sin video",
          bigger: "¡Error! El valor mínimo es mayor que el valor máximo",
          minimum_bigger_marks:
            "¡Error! El número mínimo de respuestas es mayor que la cantidad de textos marcados",
          minimum_bigger_mandatory:
            "¡Error! El número de opciones obligatorias es mayor que la cantidad de textos marcados",
          no_minimum: "¡Error! Sin valor mínimo",
          no_maximum: "¡Error! Sin valor máximo"
        }
      },
      media: {
        add_captions: "Añadir leyendas",
        mandatory_captions: "Leyendas obligatorias"
      },
      screen_recording: {
        mandatory_description: "Descripción obligatoria"
      },
      audio: {
        maximum_duration: "Duración máxima en segundos",
        minimum_duration: "Duración mínima en segundos",
        error: {
          no_minimum_duration: "¡Error! No hay valor mínimo de duración",
          no_maximum_duration: "¡Error! No hay valor de duración máxima",
          bigger_duration:
            "¡Erro! O valor de duração máxima é maior que o valor de duração mínima"
        }
      },
      logics: {
        logic: "Lógicas y condiciones",
        default: "Default",
        if_you_answer: "Si tu respondes",
        go_to: "Va para",
        next: "Próximo",
        finish: "Terminar",
        and: "Y",
        or: "O",
        add_condition: "Agregar condición"
      },
      error: {
        the_field_cannot:
          '¡Error! El valor del campo no puede ser simplemente "0"',
        error_empty_task: "Tarea vacía",
        error_empty_code: "El campo del código de tarea está vacío",
        error_empty_code_field: "Opción con campo de código vacío",
        code_validation:
          "El código de tarea no puede contener caracteres especiales, espacios o ser solo 0",
        number_zero: "El código no puede ser el número 0 solo",
        code_string_already: "Código ya en uso"
      },
      success: {
        task_successfully_created: "Tarea creada con éxito",
        task_deleted: "Tarea eliminada",
        task_copied: "Tarea copiada con éxito"
      }
    },
    replies: {
      replies: "Respuestas",
      media: "Medios",
      charts: "Gráficos",
      exported_files: "Archivos exportados",
      return_to_replies: "Volver a las respuestas",
      track_the_status: "Seguimiento del estado de sus archivos descargados",
      filters: "filtros",
      filters_confirm_message:
        "Solo se descargarán los elementos filtrados. ¿Desea continuar con el proceso de exportación?",
      filters_name: "Dale un nombre a tu archivo de exportación",
      filters_message_exported_files:
        "Al hacer clic en exportar, el archivo no estará listo de inmediato, puede seguirlo en",
      format: "Formato",
      media_only: "Apenas medias",
      transcripts_only: "Apenas transcripciones",
      both: "Ambos",
      choose_the_format: "Escolha o formato do arquivo de exportação",
      timestamp: "Marca de tiempo",
      download: "descargar",
      export: "Exportar",
      assignments: "Actividades",
      name: "Nome",
      labels: "Etiquetas",
      segments: "Segmentos",
      type: "Tipo",
      export_answers_labels: "Exportar respuestas en texto",
      export_answers_code: "Exportar respuestas en código",
      activated: "Activada",
      disabled: "Desactivada",
      assignment: "Actividad",
      community_status: "Status de la comunidad",
      export_status: "Exportar Status",
      completes: "Completas",
      ends_at: "Finaliza en",
      view: "Ver",
      solved: "Resuelto",
      not_solved: "No resuelto",
      all: "Todos",
      see_comments: "Ver comentarios",
      comment: "Comentar",
      no_reports: "No hay respuestas que coincidan con su búsqueda.",
      no_media: "No hay medios disponibles",
      transcription: "Transcripción",
      transcription_and_media: "Transcripción y Medios",
      this_video_doesnt: "Este vídeo no tiene transcripción.",
      charts_tab: {
        chart: "Gráfico",
        customize: "Personalizar",
        frequency_table: "Tabla de Frecuencia",
        number_of_mentions: "Número de menciones",
        fewer: "menos",
        more: "más",
        font_family: "Familia tipográfica",
        colors: "Colores",
        one: "Un",
        multiple: "Múltiples",
        number_of_words: "Número de palabras",
        discard_words: "Descartar palabras",
        chart_type: "Tipo de gráfico",
        data_type: "Tipo de datos",
        number: "Número",
        percentage: "Porcentaje",
        option: "Opción",
        answers: "Respuestas",
        wait_word_cloud:
          "Espere a que se cargue la nube de palabras antes de exportar",
        download_table: "Descargar tabla",
        download_chart: "Descargar gráfico",
        download_word_cloud: "Descargar nube de palabras",
        total_words: "Palabras totales",
        total_responses: "Respuestas totales",
        frequency_in_percentage: "Frecuencia en porcentaje",
        frequency_in_numbers: "Frecuencia en números",
        choose_font_family: "Elija su fuente de nube de palabras",
        choose_colors_wordcloud:
          "Elija uno o más colores para la nube de palabras",
        choose_colors_chart: "Elija colores para el gráfico",
        limit_the_number_of_words:
          "Limite el número de palabras que contendrá la nube",
        choose_your_chart_type: "Elija el tipo de gráfico",
        answered: "Respuestas",
        download_heatmap: "Descargar mapa de calor",
        discard_words_from_the_cloud: "Descartar palabras de la nube",
        choose_data_format: "Elegir formato de datos",
        words: "Palabras",
        dot: "Punto",
        choose_heatmap: "Elija la configuración de puntos del mapa de calor",
        max: "Máximo",
        radius: "Radio",
        maxOpacity: "Opacidad máxima",
        blur: "Desenfoque",
        wait_changes: "Espere a que se carguen los cambios aplicados"
      },
      assignments_tab: {
        real_time_answers: "Respuestas en tiempo real",
        number_completes: "Encuestados",
        open: "Respuestas abiertas",
        modal: {
          do_you_really_want_to_delete:
            "¿Realmente quieres eliminar esta respuesta?",
          delete_reply: "Eliminar respuesta",
          success: {
            reply_deleted: "Respuesta eliminada con éxito"
          }
        }
      },
      dashboard: {
        external_id: "ID Externo",
        name: "Nombre",
        progress: "Progreso",
        completed: "Completa",
        not_started: "No iniciada",
        incomplete: "Incompleta",
        initial: "Inicial",
        email: "Correo electrónico",
        general_status: "Estatus general",
        send_message: "Enviar mensaje",
        no_participants: "No participants are available now"
      },
      mark_reply_viewed: "Marcar la respuesta como vista",
      reply_viewed: "Respuesta vista",
      moderate_viewed_answer: "El moderador vio tu respuesta",
      are_you_sure_delete_report:
        "¿Está seguro de que desea eliminar este informe?",
      report_deleted: "Informe eliminado"
    },
    forum: {
      search_post: "Buscar publicación",
      export_forum: "Exportación del foro",
      welcome_to_forum: "¡Bienvenidos a nuestro foro!",
      create_post_engage:
        "Crea una publicación para generar discusiones entre los participantes",
      join_discussion: "Únase a una discusión con otros participantes",
      no_posts: "Sin publicaciones",
      delete_post: "Eliminar Publicación",
      are_you_sure_delete_post: "¿Realmente quieres eliminar esta publicación?",
      edited: "Editado",
      success: {
        post_deleted: "Publicación eliminada con éxito"
      },
      new_post: {
        new_post: "Nueva publicación",
        post: "Publicación",
        title: "Titulo",
        add_title_post: "Adiciona un titulo para su publicación",
        write_new_post: "Escribe una nueva publicación",
        enable_reply: "Habilitar modificación de respuestas",
        activate_post: "Activar publicación",
        enabled: "Habilitado",
        not_enabled: "Inhabilitado",
        desactivate: "Desactivado",
        activate: "Activado",
        segmentation: "Segmentación",
        add_segment: "Añadir segmento",
        all: "Todos",
        selected_segments: "Segmentos seleccionados",
        success: {
          post_created: "Publicación creada con éxito"
        },
        error: {
          empty_post: "¡Error! Publicación vacía",
          empty_title: "¡Error! Título vacío"
        }
      },
      comments: {
        comment: "Comentario",
        write_new_comment: "Escribe un nuevo comentario",
        no_comments: "Sin comentarios",
        success: {
          comment_created: "Comentario creado con éxito"
        },
        error: {
          empty_comment: "¡Error! Comentario vacío"
        }
      },
      subreply: {
        delete_reply: "Eliminar Respuesta",
        are_you_sure_delete_reply:
          "¿Realmente quieres eliminar esta respuesta?",
        success: {
          reply_deleted: "Respuesta eliminada con éxito"
        }
      }
    },
    account_settings: {
      account_settings: "Configuraciones de la cuenta",
      name_text: "Nombre",
      email_text: "Correo electrónicol",
      reset_pwd: "Restablecer la contraseña",
      old_password_text: "Contraseña anterior",
      new_password_text: "Nueva contraseña",
      confirm_new_password_text: "Confirma tu nueva contraseña",
      name_validation:
        "El nombre no puede contener caracteres especiales ni números",
      success: {
        name_success: "Nombre cambiado exitosamente ",
        picture_success: "Foto modificada con éxito ",
        password_success: "Contraseña alterada con éxito "
      },
      error: {
        nothing_to_change: "Nada que cambiar",
        name_error: "Error al cambiar el nombre ",
        picture_error: "Error al cambiar la foto ",
        password_error: "Error al cambiar la contraseña ",
        different_password: "La contraseña no coincide con la contraseña actual"
      }
    },
    terms_and_policies: {
      privacy_policy: "Política de Privacidad",
      terms_of_use: "Terminos de uso",
      edit_content: "Editar Contenido",
      i_accept_the_terms_os_use: "Acepto los",
      i_accept_the_privacy_policy: "Acepto la",
      success: {
        privacy_policy_successfully_changed:
          "Política de privacidad actualizada con éxito",
        terms_of_use_successfully_changed:
          "Terminos de uso actualizadas con éxito"
      }
    },
    customize_community: {
      customize_account: "Personalización de la Página",
      choose_text: "Elija la paleta de colores que más se parezca a su marca.",
      upload_banner: "Sube el banner de la comunidad.",
      upload_logo: "Sube tu logo",
      banner_description:
        "Crea una breve descripción de la comunidad que aparecerá junto al banner",
      description_placeholder: "Ej. Bienvenido a la comunidad...",
      add_message: "Agregar mensaje",
      customize_pages: "Personalizar páginas"
    },
    add_message: {
      add_custom_texts: "Agregue textos personalizados para sus encuestados.",
      message: "Mensaje",
      title: "Título",
      supporting_text: "Texto de apoyo",
      image: "Imagen",
      stop_display: "Detener visualización",
      save: "Ahorrar",
      messages_created_success: "Los mensajes fueron creados exitosamente",
      messages_updated_success: "Los mensajes se actualizaron correctamente",
      validate_feed_list_message:
        'Todos los mensajes en los que la opción "detener visualización" no esté habilitada deben tener un título, texto de soporte e imagen completos'
    },
    logs: {
      date: "Fecha",
      description: "Descripción",
      oldest: "Mas antiguo",
      newest: "Mas reciente"
    },
    notifications: {
      notifications: "Notificaciones",
      no_notifications: "No hay notificaciones en este momento",
      mod_commented_reply: "El moderador comentó en una de sus respuestas",
      responded_comment: "respondió a tu comentario",
      created_new_post: "creó una nueva publicación",
      commented_in_post: "comentó en tu publicación",
      commented_in_comment: "comentó en tu comentario",
      liked_post: "le gustó tu publicación",
      liked_comment: "le gustó tu comentario",
      reply_rejected: "Tu respuesta fue rechazada",
      new_assignment_available: "Nueva tarea disponible",
      comment_rejected: "Tu comentario fue rechazado",
      task_updated: "La tarea fue actualizada",
      forum: "Foro",
      assignment: "Actividade",
      created_community: "Comunidad creada por el admin",
      comunity: "Comunidad",
      changed_terms_and_conditions:
        "El admin cambió los términos y condiciones",
      terms_and_conditions: "Términos y condiciones"
    },
    pagination: {
      skip: "Saltar",
      previous: "Anterior",
      continue: "Continuar",
      save_and_continue: "Guardar y continuar",
      finish: "Finalizar"
    },
    dropzone: {
      attachments:
        "Arrastra y suelta archivos aquí o haz clic para seleccionarlos. Puedes subir multiples archivos a la vez",
      image: "Suelta la imagen aquí o haz clic para seleccionar",
      video: "Arrastra y suelta el video aquí o haz clic para seleccionarlo.",
      multimedia:
        "Arrastra y suelta videos y fotos aquí o haz clic para seleccionarlos. Puede insertar más de un archivo a la vez.",
      can_not_upload_files: "No puedes subir más archivos",
      your_browser_does_not_support:
        "Su navegador no admite la carga de archivos mediante arrastrar y soltar.",
      please_use_the_fallback:
        "Utilice el formulario alternativo a continuación para cargar sus archivos como en los viejos tiempos",
      file_is_too_big:
        "El archivo es demasiado grande ({{filesize}}MiB). Tamaño máximo: {{maxFilesize}}MiB",
      you_cant_upload: "No puedes subir archivos de este tipo.",
      server_responded_with_status_code:
        "El servidor respondió con el código {{statusCode}}.",
      cancel_upload: "Cancelar upload",
      are_you_sure_cancel: "¿Está seguro de que desea cancelar esta upload?",
      remove_file: "Eliminar archivo",
      file_uploaded_successfully: "El archivo ha subido correctamente"
    },
    uploader: {
      add_media: "Añadir medios",
      warn_uploader_modal:
        "Você ainda não concluiu o carregamento de seu(s) vídeo(s), deseja prosseguir mesmo assim? Os vídeos que não foram totalmente carregadas não serão adicionadas como parte da sua resposta"
    },
    conversation: {
      answer: "Respuesta"
    },
    labels: {
      show_more: "Ver más",
      show_less: "Ver menos",
      add_label: "Agregar etiqueta",
      create_label: "Crear etiqueta",
      label_name: "Nombre",
      label_description: "Descripción",
      label_background: "Color de fondo",
      label_color: "Color de texto",
      all_available: "Ya se han añadido todas las etiquetas disponibles",
      modal: {
        are_you_sure_delete_label:
          "¿Está seguro de que desea eliminar esta etiqueta?",
        delete_label: "Eliminar etiqueta"
      },
      success: {
        label_removed: "Etiqueta Tomada",
        added_label: "Etiqueta agregada",
        deleted_label: "Etiqueta eliminada",
        label_created: "Etiqueta creada"
      }
    },
    segmentation: {
      segmentation: "Segmentación",
      create_segmentation: "Crear segmentación",
      name_a_segment: 'Nombra un segmento y usa ";" para crear otro.',
      segments_placeholder: "Ej: Mujer;Hombre;",
      no_segments: "No hay segmentos disponibles ahora",
      table: {
        name: "Nombre"
      },
      modal: {
        do_you_really_want_to_delete:
          "¿Realmente quieres eliminar este segmento?",
        delete_segment: "Eliminar segmento",
        success: {
          segment_deleted: "Segmento eliminado con éxito"
        }
      },
      success: {
        added_segment: "Segmento agregado"
      }
    },
    add_participant: {
      add_participant: "Añadir Participante",
      import_lot: "Importar Lote",
      registration_link: "Link de Registro",
      import: "Importar",
      invite_participants: "Invitar a los participantes",
      download_pattern: "Descargar Estándar",
      import_lot_xlsx: "Importar lote en arquivo XLSX:",
      if_you_have_not: "Si aún no ha creado las segmentaciones haga",
      drag_and_drop_the_XLSX:
        "Arrastre y suelte el archivo XLSX o selecciónelo desde su computadora.",
      edit_as_many_fields: "Edite todos los campos que desee.",
      please_check_all:
        "Verifique todos los datos cuidadosamente antes de enviarlos. Al presionar enviar la invitación se activará a todos los contactos de la lista.",
      email_subject: "Asunto del correo electronico",
      email_body: "Cuerpo del correo electronico",
      generate_link_to_all: "Generar link a todos o por grupo de segmentos",
      search_segment: "Buscar segmento",
      number_of_people:
        "Número de personas que podrán ingresar al link generado",
      all: "Todos",
      people_will_be_able: "Las personas podrán ingresar con el link",
      generate_link: "Generar Link",
      generated_links: "Links Generados",
      no_links: "No hay links disponibles ahora",
      registered_using_this_link: "registrado usando este link",
      segments_linked_to_the_link: "Segmentos vinculados al link",
      show_more: "Ver más",
      show_less: "Ver menos",
      copy_link: "Copiar Link",
      disable_link: "Deshabilitar Link",
      activate_link: "Activar Link",
      delete_link: "Eliminar Link",
      do_you_really_want_to_delete: "¿Realmente quieres eliminar este link?",
      success: {
        link_copied: "Link copiado",
        link_deleted: "Link eliminado con éxito",
        link_generated: "Link Generado"
      },
      errors: {
        empty_xlsx: "¡Error! XLSX vacio",
        missing_keys: "¡Error! Llave faltante",
        duplicated_email: "¡Error! E-mail duplicado",
        inactive_logging: "Canal de registro inactivo",
        not_within_the_pattern:
          "El archivo no está dentro del patrón, intente nuevamente.",
        the_number_of_people:
          "El número de personas debe ser mayor o igual a 1",
        different_segments:
          "La comunidad y el archivo tienen diferentes segmentos"
      },
      warn: {
        is_already: "ya está en una comunidad activa",
        invalid_email: "Email inválido"
      }
    },
    operators: {
      add_manage_operators: "Agregar y administrar operadores",
      add_operators: {
        add_operators: "Agregar Operadores",
        name_operator: "Nombre del operador",
        placeholder_operator: "Ex. Carlos Antônio",
        placeholder_email: "Ex. carlos@gmail.com",
        type_access: "Tipo de acceso",
        admin: "Administrador",
        admin_text: {
          attention_label: "Atención: ",
          admin_text:
            "Este tipo de acceso le da al moderador el control sobre toda la comunidad."
        },
        observer: "Observador",
        observer_text: "Descrição do que é um observador",
        moderator: "Moderador",
        moderator_text: "Descrição do que é um moderador"
      },
      manage_operators: {
        manage_operators: "Gestionar Operadores",
        name: "Nombre",
        edit: "Editar",
        reset_password: "Restablecer la contraseña",
        resend_invitation: "Reenviar Invitación",
        disable_access: "Deshabilitar el acceso",
        enable_access: "Habilitar el acceso",
        delete_access: "Eliminar acceso",
        delete_operator: "Eliminar acceso",
        delete_operator_label:
          "¿Estás seguro de que deseas eliminar a este acceso?",
        successfully_deleted_access: "Acceso eliminado correctamente",
        error_deleting_access: "Error al eliminar el acceso",
        successfully_disabled_access: "Acceso desactivado con éxito",
        error_disabled_access: "Error al inhabilitar el acceso",
        successfully_activated_access: "Acceso activado con éxito",
        error_activated_access: "Error al activar el acceso",
        error_reset_password: "Error al restablecer la contraseña",
        password_reset_successfully: "Restablecimiento de contraseña con éxito",
        no_operator: "No hay operadores disponibles ahora",
        user_has_already:
          "El usuario ya ha realizado el primer inicio de sesión"
      },
      create_access: "Crear acceso",
      operator_created: "Operador creado con éxito",
      email_already: "Email ya está en uso",
      invalid_name: "Nombre inválido",
      name_validation: "El nombre no puede contener caracteres especiales"
    },
    manage_participants: {
      registered: "Registrados",
      invited: "Invitados",
      profiles: "Perfiles",
      name: "Nombre",
      email: "Correo electrónico",
      external_id: "ID Externo",
      created_at: "Creado en",
      read_at: "Visto en",
      joined_at: "Entró en",
      last_login: "Último login",
      bulk_action: "Acción masiva",
      resend_invitation: "Reenviar Invitación",
      block_invitation: "Block Invitación",
      no_participants: "No hay participantes disponibles ahora",
      view_participant: "Ver participante",
      segments: "Segmentos"
    },
    chat: {
      admin: "Administrador",
      participant: "Participante",
      moderator: "Moderador",
      observer: "Observador",
      enter_a_message: "Ingrese un mensaje",
      no_users: "Perdón, encontramos 0 resultados.",
      select_a_conversation: "Seleccionar una conversación",
      view_operators: "Ver operadores",
      transmission_list: "Lista de transmisión",
      my_lists: "Mis listas",
      select: "Seleccione",
      list_name: "Nombre de la lista"
    },
    modal_welcome: {
      nubank_title: "Bem vindo ao Lab!",
      nubank:
        "Esse é um espaço seguro autorizado e sancionado pela Nubank onde você poderá compartilhar suas opiniões sobre diversos temas. Para seu cadastro e login, siga as instruções abaixo e crie uma senha para entrar na plataforma. Lembre-se de que essa é uma plataforma de estudos de opinião e não um acesso à sua conta bancária da Nubank, portanto não utilize  credenciais (como senhas e registros) de sua conta bancária e demais informações pessoais.",
      default_title: "¡Bienvenido a Ow Voices!",
      default:
        "Este es un espacio seguro para que comparta sus pensamientos y opiniones sobre diferentes temas. Para su registro e inicio de sesión, siga las instrucciones a continuación y cree una contraseña para ingresar a la plataforma. Recuerde que esta es una plataforma de estudios de opinión, por lo que no utilice sus redenciales (como contraseñas y registros) de cuentas bancarias y otras informaciónes personales."
    },
    stats_assignments: {
      assignments_statistics: "Estadísticas de actividad",
      assignment: "Actividad",
      start_and_end: "Principio y Fin",
      number_of_participants: "Num. de participantes",
      number_of_assignments: "Num. de atividades",
      complete: "Completo",
      incomplete: "Incompleto",
      not_started: "No Empezado",
      no_assignment: "No hay actividades disponibles ahora",
      export_statistics: "Exportar estadísticas",
      personal_progress: "Progreso Personal"
    },
    privacy_and_permissions: {
      privacy_and_permissions: "Privacidad y permisos",
      privacy: "Privacidad",
      permissions: "Permisos",
      participants: "Participantes",
      moderators: "Moderadores",
      observers: "Observadores",
      identity_of_participants: "Identidad de los participantes",
      identity_of_admins: "Identidad de los Moderadores",
      how_participants_are_displayed_to_each_other:
        "Cómo se muestran los participantes entre sí.",
      how_admins_are_displayed:
        "Cómo se muestran los administradores a los participantes.",
      admins_and_moderators_can_view_all_details:
        "Los administradores y moderadores pueden ver todos los detalles de los participantes, pero la visualización de los nombres de los participantes se puede personalizar para mayor comodidad.",
      do_not_display_participant_email:
        "No muestre el correo electrónico del participante en ninguna página donde se muestre comúnmente",
      do_not_display_participant_last_login_info:
        "No muestre los datos de los participantes y la última hora de inicio de sesión en ninguna página donde se muestre comúnmente",
      how_participants_are_displayed_to_clients:
        "Cómo se muestran los participantes a los clientes.",
      do_not_display_participant_segments:
        "No muestre segmentos de participantes en ninguna página donde se muestre comúnmente",
      do_not_display_participant_personal_progress:
        "No muestre el progreso personal del participante en ninguna página donde se muestre comúnmente",
      do_not_display_comments_on_replies_made_by_operators:
        "No mostrar comentarios en las respuestas hechas por administradores y moderadores",
      pages: "Paginas",
      by_default_observers:
        "De forma predeterminada, los observadores pueden ver todas las páginas a continuación, pero la restricción se puede personalizar para mayor comodidad. Demasiadas páginas que no se enumeran a continuación no serán visibles para los observadores de forma predeterminada.",
      do_not_display_assignments_statistics:
        "No mostrar 'Estadísticas de Actividades'",
      do_no_display_replies_in_the_reports_menu:
        "No mostrar 'Respuestas' en el menú Informes",
      do_no_display_media_in_the_reports_menu:
        "No mostrar 'Medios' en el menú Informes",
      do_no_display_charts_in_the_reports_menu:
        "No mostrar 'Gráficos' en el menú Informes",
      do_no_display_manage_participants: "No mostrar 'Gestionar participantes'",
      by_default_moderators:
        "De forma predeterminada, los moderadores pueden ver todas las páginas a continuación, pero la restricción se puede personalizar para mayor comodidad. Demasiadas páginas que no se enumeran a continuación no serán visibles para los moderadores por patrón.",
      do_not_display_customization: "No mostrar 'Personalización'",
      do_not_display_terms_ans_policies: "No mostrar 'Términos y políticas'",
      do_not_display_create_assignments: "No mostrar 'Crear actividades'",
      full_name: 'Nombre completo ("Juan Garcia")',
      first_name_and_last_name_initial:
        'Nombre e inicial del apellido ("Juan G")',
      name_only: 'Solo nombre ("Juan")',
      name_and_unique_id: 'Nombre e ID único ("Juan 1234")',
      unique_id_only: 'ID única solamente ("1234")'
    },
    global: {
      return: "Volver",
      next: "Siguiente",
      edit: "Modificar",
      save: "Guardar",
      delete: "Eliminar",
      copy: "Copiar",
      search: "Buscar",
      sorry_we_have: "Perdón, encontramos 0 resultados para",
      cancel: "Cancelar",
      save_alterations: "Guardar Alteraciones",
      order_by: "Ordenar por",
      filter_by: "Filtrado por",
      apply: "Aplicar",
      send: "Mandar",
      load_more: "Carga más",
      add: "Agregar",
      disable: "Desactivar",
      activate: "Activar",
      click_here: "clic aquí",
      actions: "Acciones",
      clean: "Limpiar",
      show_more: "Ver más",
      show_less: "Ver menos",
      yes: "Si",
      no: "No",
      error: "Erro",
      ready: "Listo",
      processing: "Procesando"
    },
    errors_global: {
      pwd_eight_characters:
        "Su contraseña debe tener más de 8 caracteres, poseer al menos 1 número, 1 carácter especial, 1 letra mayúscula y una 1 letra minúscula",
      something_went_wrong: "Algo salió mal. Inténtalo de nuevo más tarde.",
      invalid_combination: "Contraseñas diferentes",
      invalid_user_or_pwd: "Contraseña o email inválido",
      fill_fields: "Complete todos los campos",
      limit_exceeded_images:
        "¡Limite excedido! Puedes cargar un máximo de 10 imagenes",
      limit_exceeded_video:
        "¡Limite excedido! Puedes cargar un máximo de 5 videos",
      limit_exceeded_attachments:
        "¡Limite excedido! Puedes cargar un máximo de 5 archivos",
      you_have_exceeded: "Has excedido el límite de caracteres",
      you_have_exceeded_word_limit: "Has superado el límite de palabras",
      observers_are_not_allowed:
        "Los observadores no pueden acceder a la página."
    },
    success_global: {
      changes_saved: "Cambios guardados"
    }
  }
};
