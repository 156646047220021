<template>
  <div class="container">
    <div class="row py-5">
      <div class="col">
        <div class="card-b p-5">
          <div>
            <div>
              <h2
                class="h5 font-weight-bold mb-0"
                :style="{ color: $store.getters.theme[3] }"
              >
                {{ translation.stats_assignments.assignments_statistics }}
              </h2>
              <search class="search-position" />
            </div>
            <div
              class="table-wrapper"
              v-if="filterActivities.length && !loaderActivities"
            >
              <div class="table mt-5">
                <div
                  :style="{ color: $store.getters.theme[3] }"
                  class="table-header"
                >
                  <div class="lg"></div>
                  <div class="md">
                    {{ translation.stats_assignments.start_and_end }}
                  </div>
                  <div>
                    {{ translation.stats_assignments.number_of_participants }}
                  </div>
                  <div>
                    {{ translation.stats_assignments.complete }}
                  </div>
                  <div>
                    {{ translation.stats_assignments.incomplete }}
                  </div>
                  <div>
                    {{ translation.stats_assignments.not_started }}
                  </div>
                  <div class="sm"></div>
                  <div class="sm"></div>
                </div>
                <div v-for="activity in filterActivities" :key="activity.id">
                  <div
                    @click="toggleTaskStats(activity.id)"
                    :style="{
                      background:
                        showTaskStats === activity.id
                          ? $store.getters.theme[0]
                          : 'rgba(0,0,0,0)',
                      'border-color':
                        showTaskStats === activity.id
                          ? $store.getters.theme[0]
                          : '#999999'
                    }"
                    :class="{ active: showTaskStats === activity.id }"
                    class="table-content mt-2 p-1 d-flex align-items-center"
                  >
                    <div class="lg name">
                      <span :style="{ color: $store.getters.theme[3] }">
                        {{ translation.stats_assignments.assignment }} -
                      </span>
                      <span
                        class="ml-1"
                        :style="{ color: $store.getters.theme[2] }"
                      >
                        {{ activity.name }}
                      </span>
                    </div>
                    <div class="md">
                      {{ activity.dates.start_at | formatDate }} -
                      {{ activity.dates.end_at | formatDate }}
                    </div>
                    <div>
                      {{ activity.participant_count }}
                    </div>
                    <div>
                      {{ activity.completes }}
                    </div>
                    <div>
                      {{ activity.incomplete }}
                    </div>
                    <div>
                      {{ activity.not_started }}
                    </div>
                    <div class="sm">
                      <button
                        v-if="loaderExportData !== activity.id"
                        :title="translation.stats_assignments.export_statistics"
                        v-b-tooltip.hover="{ variant: 'secondary' }"
                        @click="exportData(activity)"
                        :style="{ color: $store.getters.theme[2] }"
                        class="btn-export hover"
                      >
                        <b-icon
                          icon="file-earmark-arrow-down-fill"
                          aria-hidden="true"
                        ></b-icon>
                      </button>
                      <loader2 v-else :color="$store.getters.theme[2]" />
                    </div>
                    <div
                      class="sm"
                      v-if="
                        $store.getters.info.user.type !==
                          userTypesText.observer ||
                          !+privacy.observers
                            .participants_hide_personal_progress
                      "
                    >
                      <b-icon
                        class="caret"
                        :style="{ color: $store.getters.theme[2] }"
                        icon="caret-right-fill"
                        aria-hidden="true"
                      ></b-icon>
                    </div>
                  </div>
                  <task-stats
                    v-if="
                      showTaskStats &&
                        showTaskStats === activity.id &&
                        ($store.getters.info.user.type !==
                          userTypesText.observer ||
                          !+privacy.observers
                            .participants_hide_personal_progress)
                    "
                    :activity="activity"
                    :showTaskStats="showTaskStats"
                  />
                </div>
              </div>
              <pagination-query
                v-if="minPerPage < total"
                :perPage="perPage"
                :total="total"
                :options="[10, 20, 50, 100]"
                @perPageUpdate="newPerPage => (perPage = newPerPage)"
              />
            </div>
            <div
              v-else-if="loaderActivities"
              class="d-flex justify-content-center py-5"
            >
              <loader />
            </div>
            <div
              v-else-if="!filterActivities.length && queryValue"
              class="d-flex justify-content-center py-5"
            >
              {{ translation.global.sorry_we_have }}
              "{{ queryValue }}"
            </div>
            <div class="d-flex justify-content-center py-5" v-else>
              {{ translation.stats_assignments.no_assignment }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import paginationQuery from "@/components/general/paginationQuery.vue";
import { api } from "@/services.js";
import loader from "@/components/general/loader.vue";
import loader2 from "@/components/general/loader2.vue";
import search from "@/components/general/search.vue";
import taskStats from "@/components/statsactivities/taskStats.vue";
import { statusDashboard, userTypesText } from "@/constants";
import * as XLSX from "xlsx/xlsx.mjs";
import { mapState } from "vuex";

export default {
  components: { paginationQuery, loader, loader2, search, taskStats },

  data() {
    return {
      minPerPage: 10,
      perPage: 10,
      total: 0,
      loaderActivities: false,
      loaderExportData: false,
      showTaskStats: false,
      json_fields: {},
      json_data: [],
      activities: [],
      statusDashboard,
      patternName: "data.xls",
      userTypesText
    };
  },

  filters: {
    maxLength(value) {
      if (value.length > 22) {
        return (
          value
            .split("")
            .splice(0, 22)
            .join("") + "..."
        );
      } else return value;
    },

    formatDate(value) {
      const dateString = value.replace(/-/g, "/");
      const date = new Date(dateString);
      return date.toLocaleString().split(",")[0];
    }
  },

  computed: {
    ...mapState(["privacy"]),

    page() {
      return +this.$route.query._page;
    },

    queryValue() {
      if (this.$route.query._q === "" || this.$route.query._q)
        return this.$route.query._q;
      else return -1;
    },

    filterActivities() {
      let array = [];

      if (this.queryValue) {
        array = this.activities.filter(item => {
          return this.queryValue
            .toLowerCase()
            .split(" ")
            .every(v => item.name.toLowerCase().includes(v));
        });
      } else {
        const start = this.perPage * (this.page - 1);
        this.activities.forEach((activity, index) => {
          if (index >= start && index < start + this.perPage)
            array.push(activity);
        });
      }
      return array;
    }
  },

  methods: {
    exportData({ id, name }) {
      this.loaderExportData = id;
      this.jsonData = [];
      api
        .get(`activity/${id}/list-task-status?offset=0&limit=all`, {
          headers: {
            Authorization: `Bearer ${this.$store.getters.token}`
          }
        })
        .then(r => {
          if (r.status === 200) {
            const participants = r.data.participants;

            participants.forEach(participant => {
              const data = {
                id: participant.participant.id,
                external_id: participant.participant.external_id,
                name: participant.participant.name,
                general_status: participant.activity_status
              };

              participant.tasks.forEach(task => {
                data[
                  `${
                    task.code_string ? task.code_string : `T${+task.index + 1}`
                  }`
                ] = +task.is_completed ? "y" : "0";
              });

              this.jsonData.push(data);
            });

            const dataWS = XLSX.utils.json_to_sheet(this.jsonData);
            const wb = XLSX.utils.book_new();

            XLSX.utils.book_append_sheet(wb, dataWS, "data");
            XLSX.writeFile(wb, `${name}.xlsx`);
          }
          this.loaderExportData = false;
        })
        .catch(() => {
          this.loaderExportData = false;
        });
    },

    toggleTaskStats(id) {
      if (
        this.$store.getters.info.user.type !== this.userTypesText.observer ||
        !+this.privacy.observers.participants_hide_personal_progress
      ) {
        if (this.showTaskStats === id) this.showTaskStats = false;
        else this.showTaskStats = id;
      }
    },

    getActivities() {
      this.loaderActivities = true;
      api
        .get(
          `community/${this.$store.getters.community.id}/activities-dashboard`,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${this.$store.getters.token}`
            }
          }
        )
        .then(r => {
          if (r.status === 200) {
            this.activities = r.data.activities;
            this.total = this.activities.length;
            this.loaderActivities = false;
          }
        })
        .catch(() => {
          this.loaderActivities = false;
        });
    }
  },

  created() {
    this.getActivities();
  }
};
</script>

<style lang="scss" scoped>
.table-wrapper {
  overflow-x: auto;
  padding-bottom: 30px;
  &::-webkit-scrollbar {
    height: 8px;
    overflow: hidden;
  }
  &::-webkit-scrollbar-track {
    background: rgba(0, 0, 0, 0);
    border-radius: 10px;
  }
  &::-webkit-scrollbar-thumb {
    background: #ccc;
    border-radius: 10px;
    height: 10px;
  }
  &::-webkit-scrollbar-thumb:hover {
    background: #aaa;
  }
}

.table {
  width: 100%;
}

.table-header {
  min-width: 100%;
  display: flex;
  width: fit-content;
  > div {
    min-width: 135px;
    flex: 1;
    display: flex;
    justify-content: center;
    font-weight: bold;
    font-size: 0.85rem;
    &.lg {
      min-width: 230px;
    }
    &.md {
      min-width: 148px;
    }
    &.sm {
      min-width: 35px;
    }
  }
}

.table-content {
  min-width: 100%;
  width: fit-content;
  display: flex;
  border-radius: 50px;
  border: 1px solid #999999;
  > div {
    min-width: 135px;
    flex: 1;
    display: flex;
    justify-content: center;
    color: #6b6b6b;
    font-size: 0.85rem;
    span {
      font-weight: bold;
      word-wrap: break-word;
      word-break: break-word;
      &:nth-of-type(1) {
        white-space: nowrap;
      }
    }
    &.name {
      justify-content: flex-start;
      padding-left: 10px;
    }
    &.lg {
      min-width: 230px;
    }
    &.md {
      min-width: 148px;
    }
    &.sm {
      min-width: 35px;
    }
  }
  svg {
    transition: all 0.3s ease;
    cursor: pointer;
  }
  &.active {
    .caret {
      transform: rotate(90deg);
    }
  }
}

.download-export {
  position: absolute;
  z-index: -1;
  opacity: 0;
}

.btn-export {
  background: none;
  border: none;
  &:focus {
    outline: none;
  }
}
</style>
