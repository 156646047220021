<template>
  <div class="container">
    <div class="row py-5">
      <div class="col">
        <div class="card-b mb-4 p-md-5 p-4">
          <div class="option-btns">
            <button
              class="btn px-4 px-md-5"
              :class="{ active: option === tabOptions.privacy }"
              :style="{
                background:
                  option === tabOptions.privacy
                    ? $store.getters.theme[2]
                    : $store.getters.theme[0],
                color: option === tabOptions.privacy ? '#ffffff' : '#999999'
              }"
              @click="changeTab(tabOptions.privacy)"
            >
              {{ translation.privacy_and_permissions.privacy }}
            </button>
            <button
              class="btn px-4 px-md-5"
              :class="{ active: option === tabOptions.permissions }"
              :style="{
                background:
                  option === tabOptions.permissions
                    ? $store.getters.theme[2]
                    : $store.getters.theme[0],
                color: option === tabOptions.permissions ? '#ffffff' : '#999999'
              }"
              @click="changeTab(tabOptions.permissions)"
            >
              {{ translation.privacy_and_permissions.permissions }}
            </button>
          </div>

          <transition mode="out-in">
            <privacy v-if="option === tabOptions.privacy" />
            <permissions v-if="option === tabOptions.permissions" />
          </transition>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { tabOptions } from "@/constants";
import privacy from "@/components/privacyandpermissions/privacy.vue";
import permissions from "@/components/privacyandpermissions/permissions.vue";

export default {
  name: "AddParticipants",
  components: { privacy, permissions },
  data() {
    return {
      tabOptions,
      loader: false
    };
  },
  computed: {
    option() {
      return this.$route.query._option;
    }
  },
  methods: {
    changeTab(option) {
      const isDifferentRoute = this.option !== option;

      if (isDifferentRoute) {
        this.$router.push({ query: { _option: option } });
      }
    }
  },
  created() {}
};
</script>

<style lang="scss" scoped>
.option-btns {
  display: flex;
  justify-content: center;
  button {
    font-weight: bold;
    font-size: 1.3rem;
    transition: all 0.3s ease;
    min-width: 245px;
    &:nth-of-type(1) {
      border-top-left-radius: 35px;
      border-bottom-left-radius: 35px;
    }
    &:nth-of-type(2) {
      border-top-right-radius: 35px;
      border-bottom-right-radius: 35px;
    }
    &.active {
      z-index: 2;
    }
    &:hover {
      color: #666 !important;
    }
  }
}

@media (max-width: 767.98px) {
  .option-btns {
    button {
      min-width: 140px;
      font-size: 0.8rem;
    }
  }
}
</style>
