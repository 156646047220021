<template>
  <div class="row task-wrapper">
    <div v-if="!loading" class="col">
      <div class="task-text img-question">
        <p v-html="text"></p>
      </div>
      <div class="d-flex flex-wrap">
        <attachment :attachments="task.attachments" :deleteFile="null" />
      </div>
      <div>
        <div v-if="image">
          <div class="d-flex flex-wrap">
            <div
              class="col-sm-6 col-md-4 col-lg-3 mb-4"
              v-for="(card, index) in ranking"
              :key="index"
            >
              <div v-if="disabled" class="disabled"></div>
              <div
                @click="setCardValue(index)"
                class="card-image"
                :class="{ opacity: card.value }"
              >
                <div
                  :style="{
                    background: $store.getters.theme[0],
                    pointerEvents:
                      $store.getters.info.user.type ===
                      userTypesText.participant
                        ? 'none'
                        : 'unset'
                  }"
                >
                  <div
                    class="card-value"
                    :style="{
                      background: `${card.value ? '#fff' : 'rgba(0,0,0,0)'}`
                    }"
                  >
                    {{ card.value }}
                    <span v-if="card.value">º</span>
                  </div>
                  <img
                    v-if="card.img.code.length"
                    :src="card.img.code"
                    :alt="card.text"
                  />
                </div>
                <p>
                  {{ card.text }}
                </p>
              </div>
            </div>
          </div>
        </div>
        <div v-else>
          <div class="d-flex flex-column align-items-center">
            <div class="col-md-8" v-for="(card, index) in ranking" :key="index">
              <div v-if="disabled" class="disabled"></div>
              <div
                @click="setCardValue(index)"
                class="card-noimage p-3 mb-2"
                :style="{
                  'border-color': $store.getters.theme[1],
                  background: `${
                    card.value ? $store.getters.theme[1] : '#fff'
                  }`,
                  color: $store.getters.theme[3]
                }"
              >
                <p>
                  {{ card.text }}
                </p>
                <div
                  class="card-value"
                  :style="{
                    'border-color': $store.getters.theme[1],
                    background: `${
                      card.value ? $store.getters.theme[2] : '#fff'
                    }`
                  }"
                >
                  {{ card.value }}
                  <span v-if="card.value">º</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="comments">
          <label
            for="comments"
            v-if="
              task.question_object.has_custom_comment_label &&
                task.question_object.comment_label &&
                task.question_object.comment_label.length
            "
            >{{ task.question_object.comment_label }}</label
          >
          <label for="comments" v-else>
            {{ translation.answer_task.comments }}
          </label>
          <textarea
            class="form-control"
            id="textarea"
            v-model="comments"
            :maxlength="maxCharacters"
            rows="3"
            max-rows="6"
            :disabled="task.is_answered"
          ></textarea>
          <characterAndWordCounter
            :count="
              hasWordsLimitation
                ? this.comments.length
                  ? this.comments.trim().split(/\s+/).length
                  : 0
                : comments.length
            "
            :max="hasWordsLimitation ? maxWords : maxCharacters"
            :label="
              hasWordsLimitation
                ? translation.answer_task.words
                : translation.answer_task.characters
            "
          />
        </div>
      </div>
    </div>
    <div v-if="loading" class="w-100 d-flex justify-content-center">
      <loader />
    </div>
  </div>
</template>

<script>
import { api } from "@/services.js";
import loader from "@/components/general/loader.vue";
import attachment from "@/components/general/attachment.vue";
import characterAndWordCounter from "@/components/general/characterAndWordCounter.vue";

import { userTypesText } from "@/constants";

export default {
  display: "Clone",
  order: 2,
  components: {
    loader,
    attachment,
    characterAndWordCounter
  },
  props: {
    task: {
      required: true
    },
    save: {
      required: true
    },
    savePreview: {
      required: true
    },
    getTasks: {
      required: true
    }
  },
  data() {
    return {
      comments: "",
      question: "",
      ranking: [],
      rankingOrder: [],
      loading: false,
      disabled: false,
      userTypesText
    };
  },
  computed: {
    text() {
      const regexpVideo = /(?:(<iframe.*?src="))(.*?)((?:".*?>))((?:<\/iframe>))?/g;

      return this.$store.getters.info.user.type === userTypesText.participant
        ? this.task.raw_question.replace(
            regexpVideo,
            "<video src='$2' width='350' height='197' frameborder='0' allowfullscreen='allowfullscreen' controls controlsList='nodownload'></iframe>"
          )
        : this.task.raw_question;
    },
    image() {
      return this.task.question_object.cards.filter(card => card.has_image)
        .length;
    },
    cards() {
      return this.task.question_object.cards.map(card => ({
        img: {
          code: card.value.img,
          name: card.value.image_name
        },
        text: card.value.text
      }));
    },
    page() {
      return this.$store.getters.activeTaskIndex;
    },
    obligatoryComment() {
      return this.task.question_object?.has_obligatory_comments;
    },

    hasCharactersLimitation() {
      return this.task.question_object?.has_characters_limitation;
    },

    maxCharacters() {
      return this.hasCharactersLimitation
        ? this.task.question_object?.characters_count?.maximum
        : 5000;
    },

    minCharacters() {
      return this.hasCharactersLimitation
        ? this.task.question_object?.characters_count?.minimum
        : 0;
    },

    hasWordsLimitation() {
      return this.task.question_object?.has_words_limitation;
    },

    maxWords() {
      return this.hasWordsLimitation
        ? this.task.question_object?.words_count?.maximum
        : 2000;
    },

    minWords() {
      return this.hasWordsLimitation
        ? this.task.question_object?.words_count?.minimum
        : 0;
    }
  },
  watch: {
    save() {
      this.answer();
    },
    savePreview() {
      this.answerPreview();
    },
    page() {
      this.init();
    },
    comments() {
      if (this.comments.length >= this.maxCharacters) {
        this.comments = this.comments
          .split("")
          .splice(0, this.maxCharacters)
          .join("");
      }

      const words = this.comments.split(/\s+/);

      const wordsCount = words.length;

      if (wordsCount > this.maxWords) {
        this.comments = words.splice(0, this.maxWords).join(" ");
      }
    }
  },

  methods: {
    fixRanking() {
      this.ranking.forEach((card, index) => {
        if (this.rankingOrder.includes(card.text)) {
          this.ranking[index].value = this.rankingOrder.indexOf(card.text) + 1;
        } else {
          this.ranking[index].value = null;
        }
      });
    },
    setCardValue(index) {
      if (this.rankingOrder.includes(this.ranking[index].text)) {
        this.ranking[index].value = null;
        this.rankingOrder.splice(
          this.rankingOrder.indexOf(this.ranking[index].text),
          1
        );
        this.fixRanking();
      } else {
        this.ranking[index].value = this.rankingOrder.length + 1;
        this.rankingOrder.push(this.ranking[index].text);
      }
    },
    answerPreview() {
      if (!this.loading) {
        if (this.rankingOrder.length === this.ranking.length) {
          if (this.obligatoryComment && !this.comments.length) {
            this.$toast.error(
              this.translation.answer_task.obligatory_comment_tost
            );

            return;
          }

          if (
            this.comments.length &&
            this.hasCharactersLimitation &&
            this.comments.length < this.minCharacters
          ) {
            this.$toast.error(
              `${this.translation.answer_task.min_characters} ${this.minCharacters} ${this.translation.answer_task.characters}`
            );

            return;
          }

          if (this.comments.length && this.hasWordsLimitation) {
            const wordsCount = this.comments.split(/\s+/).length;

            if (wordsCount < this.minWords) {
              this.$toast.error(
                `${this.translation.answer_task.min_word} ${this.minWords} ${this.translation.answer_task.words}`
              );

              return;
            }
          }

          this.loading = true;

          const answer = {
            comment: this.comments
          };

          answer.options = this.ranking.map((card, index) => ({
            index: index,
            option: {
              text: card.text,
              img: card.img.code,
              image_name: card.img.name
            },
            rank: card.value
          }));

          const body = {
            task_id: this.task.id,
            has_attachment: 0,
            has_video: 0,
            has_image: 0,
            raw_answer: JSON.stringify(answer),
            answer
          };

          api
            .post("/answer/send/preview", body, {
              headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${this.$store.getters.token}`
              }
            })
            .then(r => {
              if (r.status === 200) {
                this.$toast.success(
                  this.translation.answer_task.success.reply_registered
                );
                this.getTasks("save");
                this.loading = false;
              }
            })
            .catch(() => {
              this.$toast.error(
                this.translation.answer_task.error.reply_not_registered
              );
              this.loading = false;
            });
        } else {
          this.$toast.error(this.translation.answer_task.error.use_all_cards);
        }
      }
    },
    answer() {
      if (!this.loading) {
        if (this.rankingOrder.length === this.ranking.length) {
          if (this.obligatoryComment && !this.comments.length) {
            this.$toast.error(
              this.translation.answer_task.obligatory_comment_tost
            );

            return;
          }

          if (
            this.comments.length &&
            this.hasCharactersLimitation &&
            this.comments.length < this.minCharacters
          ) {
            this.$toast.error(
              `${this.translation.answer_task.min_characters} ${this.minCharacters} ${this.translation.answer_task.characters}`
            );

            return;
          }

          if (this.comments.length && this.hasWordsLimitation) {
            const wordsCount = this.comments.split(/\s+/).length;

            if (wordsCount < this.minWords) {
              this.$toast.error(
                `${this.translation.answer_task.min_word} ${this.minWords} ${this.translation.answer_task.words}`
              );

              return;
            }
          }

          this.loading = true;

          const answer = {
            comment: this.comments
          };

          answer.options = this.ranking.map((card, index) => ({
            index: index,
            option: {
              text: card.text,
              img: card.img.code,
              image_name: card.img.name
            },
            rank: card.value
          }));

          const body = {
            task_id: this.task.id,
            participant_id: this.$store.getters.info.user.sub,
            has_attachment: 0,
            has_video: 0,
            has_image: 0,
            raw_answer: JSON.stringify(answer),
            answer
          };

          api
            .post("/answer/send", body, {
              headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${this.$store.getters.token}`
              }
            })
            .then(r => {
              if (r.status === 200) {
                this.$toast.success(
                  this.translation.answer_task.success.reply_registered
                );
                this.getTasks("save");
                this.loading = false;
              }
            })
            .catch(() => {
              this.$toast.error(
                this.translation.answer_task.error.reply_not_registered
              );
              this.loading = false;
            });
        } else {
          this.$toast.error(this.translation.answer_task.error.use_all_cards);
        }
      }
    },
    init() {
      this.ranking = [];
      this.rankingOrder = [];
      if (this.task.is_answered) {
        this.comments = this.task.answer.answer_object.comment;
        this.ranking = this.task.answer.answer_object.options.map(card => ({
          img: {
            code: card.option.img,
            name: card.option.image_name
          },
          text: card.option.text,
          value: card.rank
        }));

        this.disabled = true;
      } else if (this.task.answer && this.$route.query._preview) {
        this.comments = this.task.answer.answer_object.comment;
        this.ranking = this.task.answer.answer_object.options.map(card => ({
          img: {
            code: card.option.img,
            name: card.option.image_name
          },
          text: card.option.text,
          value: card.rank
        }));
      } else {
        this.comments = "";
        this.disabled = false;
        this.cards.forEach(card => {
          this.ranking.push({
            img: card.img,
            text: card.text,
            value: null
          });
        });
      }
    }
  },
  created() {
    this.init();
  },
  mounted() {
    if (this.$store.getters.info.user.type === userTypesText.participant) {
      const taskWrapper = document.querySelector(".task-wrapper");

      taskWrapper.addEventListener("contextmenu", e => {
        e.preventDefault();
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.task-text {
  display: flex;
  flex-direction: column;
  color: #6b6b6b;
  overflow-wrap: break-word;
}

.select-box {
  display: inline-block;
  padding: 20px;
  border-radius: 20px;
  color: #6b6b6b;
  font-weight: bold;
  font-size: 1.2rem;
}

.comments {
  label {
    color: #6b6b6b;
  }
  textarea {
    width: 100%;
    display: block;
    border: 1px solid #b3b3b3;
    border-radius: 10px;
  }
}

.custom-control-label {
  color: red;
}

.card-noimage {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 14px;
  border: 3px solid #ebf2f2;
  color: #6b6b6b;
  font-weight: bold;
  transition: all 0.3s ease;
  cursor: pointer;
  .card-value {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 30px;
    height: 30px;
    border-radius: 5px;
    border: 2px solid #ebf2f2;
    cursor: pointer;
    color: #fff;
  }
  p {
    width: calc(100% - 50px);
    word-wrap: break-word;
    word-break: break-word;
    margin-bottom: 0;
  }
}

.card-image {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: #6b6b6b;
  font-weight: bold;
  cursor: pointer;
  p {
    max-width: 100%;
    word-wrap: break-word;
    word-break: break-word;
  }

  &.opacity {
    opacity: 0.6;
  }
  > div {
    position: relative;
    overflow: hidden;
    img {
      object-fit: cover;
      width: 230px;
      height: 160px;
    }
    .card-value {
      position: absolute;
      top: 10px;
      right: 10px;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 30px;
      height: 30px;
      border-radius: 5px;
      border: 2px solid #fff;
      cursor: pointer;
      color: #6b6b6b;
    }

    background-color: red;
    display: block;
    width: 100%;
    height: 150px;
    margin-bottom: 15px;
    border-radius: 15px;
    box-shadow: 0px 0px 37px -16px #c4c4c4;
  }
}

@media (max-width: 567.98px) {
  .card-image {
    > div {
      width: 230px;
      height: 160px;
    }
  }
}

.disabled {
  position: absolute;
  width: 100%;
  height: 100%;
  background: #fff;
  opacity: 0.3;
  z-index: 12;
}
</style>
