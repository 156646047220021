var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"navigation"},[_c('nav',[_c('a',{staticClass:"link",on:{"click":_vm.goToFeed}},[_c('img',{attrs:{"src":require("@/img/feed.svg"),"alt":"Feed"}}),_vm._v(" "+_vm._s(_vm.translation.header.feed_text)+" ")]),_c('a',{staticClass:"link",on:{"click":_vm.openActivities}},[_c('img',{attrs:{"src":require("@/img/activities.svg"),"alt":"Activities"}}),_vm._v(" "+_vm._s(_vm.translation.header.assignments_text)+" ")]),_c('a',{staticClass:"link",on:{"click":_vm.goToForum}},[_c('img',{attrs:{"src":require("@/img/forum.svg"),"alt":"Forum"}}),_vm._v(" "+_vm._s(_vm.translation.header.forum_text)+" ")]),(
        _vm.$store.getters.info.user.type === _vm.userTypesText.admin ||
          _vm.$store.getters.info.user.type === _vm.userTypesText.moderator ||
          (_vm.$store.getters.info.user.type === _vm.userTypesText.observer &&
            (!+_vm.permissions.observers.hide_report_replies ||
              !+_vm.permissions.observers.hide_report_media ||
              !+_vm.permissions.observers.hide_report_charts))
      )?_c('a',{staticClass:"link",on:{"click":_vm.goToAnswers}},[_c('img',{attrs:{"src":require("@/img/answers.svg"),"alt":"Answers"}}),_vm._v(" "+_vm._s(_vm.translation.header.reports_text)+" ")]):_vm._e(),(
        _vm.$store.getters.info.user.type === _vm.userTypesText.moderator ||
          _vm.$store.getters.info.user.type === _vm.userTypesText.admin ||
          _vm.$store.getters.info.user.type === _vm.userTypesText.observer
      )?_c('a',{staticClass:"link",on:{"click":_vm.openAdmin}},[_c('img',{attrs:{"src":require("@/img/admin.svg"),"alt":"Activities"}}),_vm._v(" "+_vm._s(_vm.$store.getters.info.user.type === _vm.userTypesText.admin ? _vm.translation.header.admin_text : _vm.$store.getters.info.user.type === _vm.userTypesText.moderator ? _vm.translation.header.moderator_text : _vm.translation.header.observer_text)+" ")]):_vm._e()]),_c('div',{staticClass:"nav-line"},[_c('div',{staticClass:"nav-thumb",style:({ background: _vm.$store.getters.theme[2] })})])])}
var staticRenderFns = []

export { render, staticRenderFns }