import { render, staticRenderFns } from "./PrivacyAndPermissions.vue?vue&type=template&id=4f8ca495&scoped=true&"
import script from "./PrivacyAndPermissions.vue?vue&type=script&lang=js&"
export * from "./PrivacyAndPermissions.vue?vue&type=script&lang=js&"
import style0 from "./PrivacyAndPermissions.vue?vue&type=style&index=0&id=4f8ca495&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4f8ca495",
  null
  
)

export default component.exports