<template>
  <div class="notification">
    <h3 class="mr-2">
      {{ translation.notifications.task_updated }}
    </h3>
    <p>
      {{
        notification.source.code_string
          ? notification.source.code_string
          : `T${+notification.source.index + 1}`
      }}
      -
      {{ notification.source.activity.name }}
    </p>
  </div>
</template>

<script>
import { userTypes } from "@/constants";

export default {
  props: {
    notification: {
      required: true
    }
  },
  data() {
    return {
      userTypes
    };
  }
};
</script>

<style lang="scss" scoped>
.notification {
  h3 {
    color: #6b6b6b;
    font-size: 0.9rem;
    font-weight: bold;
    max-width: 170px;
    margin: 0;
  }
  p {
    color: #999999;
    font-weight: 0.875rem;
    max-width: 170px;
    margin: 0;
  }
}

@media (max-width: 576.98px) {
  .notification {
    h3 {
      color: #6b6b6b;
      font-size: 0.82rem !important;
      font-weight: bold;
      max-width: 170px;
      margin: 0;
    }
    p {
      color: #999999;
      font-size: 0.8rem !important;
      font-weight: 0.7rem !important;
      max-width: 170px;
      margin: 0;
    }
  }
}
</style>
