var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('h2',{staticClass:"title"},[_vm._v(" "+_vm._s(_vm.translation.replies.charts_tab.choose_your_chart_type)+" ")]),_c('div',{staticClass:"type-wrapper"},[_c('button',{staticClass:"btn hover",style:({
        background:
          _vm.type === _vm.chartTypes.line ? _vm.$store.getters.theme[3] : 'none'
      }),on:{"click":function($event){return _vm.setType(_vm.chartTypes.line)}}},[(_vm.type === _vm.chartTypes.line)?_c('img',{attrs:{"src":require("@/img/line-icon-white.svg"),"alt":"Pie Chart"}}):_c('img',{attrs:{"src":require("@/img/line-icon.svg"),"alt":"Pie Chart"}})]),_c('button',{staticClass:"btn hover",style:({
        background: _vm.type === _vm.chartTypes.bar ? _vm.$store.getters.theme[3] : 'none'
      }),on:{"click":function($event){return _vm.setType(_vm.chartTypes.bar)}}},[(_vm.type === _vm.chartTypes.bar)?_c('img',{attrs:{"src":require("@/img/bar-icon-white.svg"),"alt":"Bar Chart"}}):_c('img',{attrs:{"src":require("@/img/bar-icon.svg"),"alt":"Bar Chart"}})]),_c('button',{staticClass:"btn hover",style:({
        background:
          _vm.type === _vm.chartTypes.barHorizontal ? _vm.$store.getters.theme[3] : 'none'
      }),on:{"click":function($event){return _vm.setType(_vm.chartTypes.barHorizontal)}}},[(_vm.type === _vm.chartTypes.barHorizontal)?_c('img',{attrs:{"src":require("@/img/bar-horizontal-icon-white.svg"),"alt":"Horizontal Bar Chart"}}):_c('img',{attrs:{"src":require("@/img/bar-horizontal-icon.svg"),"alt":"Horizontal Bar Chart"}})]),_c('button',{staticClass:"btn hover",style:({
        background:
          _vm.type === _vm.chartTypes.stackedBar ? _vm.$store.getters.theme[3] : 'none'
      }),on:{"click":function($event){return _vm.setType(_vm.chartTypes.stackedBar)}}},[(_vm.type === _vm.chartTypes.stackedBar)?_c('img',{attrs:{"src":require("@/img/stacked-bar-icon-white.svg"),"alt":"Stacked Bar Chart"}}):_c('img',{attrs:{"src":require("@/img/stacked-bar-icon.svg"),"alt":"Stacked Bar Chart"}})]),_c('button',{staticClass:"btn hover",style:({
        background: _vm.type === _vm.chartTypes.pie ? _vm.$store.getters.theme[3] : 'none'
      }),on:{"click":function($event){return _vm.setType(_vm.chartTypes.pie)}}},[(_vm.type === _vm.chartTypes.pie)?_c('img',{attrs:{"src":require("@/img/pie-icon-white.svg"),"alt":"Pie Chart"}}):_c('img',{attrs:{"src":require("@/img/pie-icon.svg"),"alt":"Pie Chart"}})])])])}
var staticRenderFns = []

export { render, staticRenderFns }