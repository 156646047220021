<template>
  <div>
    <h2 class="title mb-4" :style="{ color: $store.getters.theme[3] }">
      {{ translation.add_participant.generated_links }}
    </h2>

    <div v-if="links.length && !loaderLinks" class="links-table">
      <div class="link" v-for="link in linksPages" :key="link.id">
        <link-url :link="link" :segments="segments" @getLinks="getLinks" />
      </div>
      <pagination-query
        v-if="minPerPage < links.length"
        :perPage="perPage"
        :total="links.length"
        :options="[5, 10, 20, 50, 100]"
        @perPageUpdate="newPerPage => (perPage = newPerPage)"
      />
    </div>
    <div v-else-if="loaderLinks" class="d-flex justify-content-center py-5">
      <loader />
    </div>
    <div v-else class="d-flex justify-content-center py-5 no-link">
      {{ translation.add_participant.no_links }}
    </div>
  </div>
</template>

<script>
import { api } from "@/services.js";
import loader from "@/components/general/loader.vue";
import paginationQuery from "@/components/general/paginationQuery.vue";
import linkUrl from "@/components/addparticipant/linkUrl.vue";

export default {
  props: {
    triggerGetLinks: {
      required: true
    },
    segments: {
      required: true
    }
  },
  components: {
    loader,
    paginationQuery,
    linkUrl
  },
  data() {
    return {
      links: [],
      linksPages: [],
      loaderLinks: false,
      perPage: 5,
      minPerPage: 5
    };
  },
  computed: {
    page() {
      return this.$route.query._page;
    }
  },
  watch: {
    triggerGetLinks() {
      this.getLinks();
    },
    page() {
      this.getLinksPages();
    },
    perPage() {
      this.getLinksPages();
    }
  },
  methods: {
    getLinksPages() {
      let array = [];

      const start = this.perPage * (this.page - 1);
      this.links.forEach((link, index) => {
        if (index >= start && index < start + this.perPage) array.push(link);
      });

      this.linksPages = array;
    },
    getLinks() {
      this.loaderLinks = true;
      api
        .get(
          `admin/manage/community/${this.$store.getters.community.id}/register-urls`,
          {
            headers: {
              Authorization: `Bearer ${this.$store.getters.token}`
            }
          }
        )
        .then(r => {
          if (r.status === 200) {
            this.links = r.data.inviteGroups.reverse();
            this.getLinksPages();
          }
          this.loaderLinks = false;
        })
        .catch(() => {
          this.loaderLinks = false;
        });
    }
  },
  created() {
    this.getLinks();
  }
};
</script>

<style lang="scss" scoped>
.title {
  font-weight: bold;
  font-size: 1.3rem;
}

.no-link {
  color: #6b6b6b;
}
</style>
