var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',[_c('div',{staticClass:"drops-matrix pt-4 px-2"},[_c('div',{staticClass:"d-flex pr-3"},[_c('span',[_vm._v(" "+_vm._s(_vm.translation.create_task.matrix.columns)+":")]),_c('dropdown',{attrs:{"width":60,"dropId":"columns","options":_vm.options,"value":_vm.dataCol},on:{"update":function (data) { return (_vm.dataCol = data); }}})],1),_c('div',{staticClass:"d-flex pr-3"},[_c('span',[_vm._v(" "+_vm._s(_vm.translation.create_task.matrix.rows)+":")]),_c('dropdown',{attrs:{"width":60,"dropId":"rows","options":_vm.options,"value":_vm.dataRow},on:{"update":function (data) { return (_vm.dataRow = data); }}})],1),_c('div',{staticClass:"d-flex"},[_c('span',[_vm._v(_vm._s(_vm.translation.create_task.matrix.type)+":")]),_c('dropdown',{attrs:{"width":220,"dropId":"matrixType","options":_vm.matrixTypeOptions,"value":_vm.dataMatrixType},on:{"update":function (data) { return (_vm.dataMatrixType = data); }}})],1),_c('div',{staticClass:"error-box bg-danger p-2 ml-3",class:{ active: _vm.matrixError }},[_c('b-icon',{staticClass:"mr-1",attrs:{"icon":"exclamation-triangle-fill"}}),_vm._v(" "+_vm._s(_vm.matrixError)+" ")],1)]),_c('div',{staticClass:"matrix d-flex justify-content-center py-4"},[_c('div',{staticClass:"w-100"},[_c('div',{staticClass:"cols"},[_c('p',[_vm._v(" "+_vm._s(_vm.translation.create_task.matrix.columns)+" ")]),_vm._l((_vm.matrix.cols),function(col,index){
var _obj;
return _c('div',{key:index},[_c('b-form-input',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",value:({ variant: 'secondary' }),expression:"{ variant: 'secondary' }",modifiers:{"hover":true}}],staticClass:"code",attrs:{"maxlength":"10","state":!_vm.isCodeValid(col.codeString) &&
                  !_vm.hasDuplicate(_vm.matrix.cols, 'codeString') &&
                  !!col.codeString.length &&
                  col.codeString !== '0',"title":_vm.translation.create_task.error.code_validation},model:{value:(col.codeString),callback:function ($$v) {_vm.$set(col, "codeString", $$v)},expression:"col.codeString"}}),_c('input',{directives:[{name:"model",rawName:"v-model",value:(col.value),expression:"col.value"}],class:( _obj = {}, _obj['text-danger'] = _vm.redText, _obj ),attrs:{"type":"text","maxlength":"254","placeholder":_vm.translation.create_task.matrix.column},domProps:{"value":(col.value)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(col, "value", $event.target.value)}}})],1)})],2),_c('div',{staticClass:"rows"},[_c('p',[_vm._v(" "+_vm._s(_vm.translation.create_task.matrix.rows)+" ")]),_vm._l((_vm.matrix.rows),function(row,index){
              var _obj;
return _c('div',{key:index},[_c('b-form-input',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.right.v-secondary",value:(
                _vm.translation.create_task.error.code_validation
              ),expression:"\n                translation.create_task.error.code_validation\n              ",modifiers:{"hover":true,"right":true,"v-secondary":true}}],staticClass:"code",attrs:{"maxlength":"10","state":!_vm.isCodeValid(row.codeString) &&
                  !_vm.hasDuplicate(_vm.matrix.rows, 'codeString') &&
                  !!row.codeString.length &&
                  row.codeString !== '0'},model:{value:(row.codeString),callback:function ($$v) {_vm.$set(row, "codeString", $$v)},expression:"row.codeString"}}),_c('input',{directives:[{name:"model",rawName:"v-model",value:(row.value),expression:"row.value"}],class:( _obj = {}, _obj['text-danger'] = _vm.redText, _obj ),attrs:{"type":"text","maxlength":"254","placeholder":_vm.translation.create_task.matrix.row},domProps:{"value":(row.value)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(row, "value", $event.target.value)}}})],1)})],2)])])])])}
var staticRenderFns = []

export { render, staticRenderFns }