<template>
  <div class="chart">
    <div v-if="data.length">
      <config-word-cloud
        :id="id"
        :activityId="chartData.activityId"
        :taskId="chartData.taskId"
        :wordsCount="data.length"
        :max="max"
      />
      <p class="answered">
        {{ translation.replies.charts_tab.answered }}:
        {{ chartData.answersCount }}
      </p>

      <div class="message" v-if="messageTimeOut">
        {{ translation.replies.charts_tab.wait_word_cloud }}
        <b-icon
          icon="clock"
          animation="fade"
          font-scale="1"
          shift-v="8"
        ></b-icon>
      </div>
      <div class="message" v-else-if="messageChanges">
        {{ translation.replies.charts_tab.wait_changes }}
        <b-icon
          icon="clock"
          animation="fade"
          font-scale="1"
          shift-v="8"
        ></b-icon>
      </div>
      <div
        v-if="config"
        class="image-word-cloud"
        :id="`word-cloud-image-${id}`"
      >
        <div class="word-cloud-wrapper">
          <vue-word-cloud
            :id="`wordcloud-${id}`"
            style="height: 480px; width: 100%"
            :font-family="config.font.value"
            :words="words"
            :color="([, weight]) => setColor(weight)"
          >
          </vue-word-cloud>
        </div>
        <mentions-chart
          :wordsCount="words.length"
          :min="min"
          :max="max"
          :theme="config.theme"
        />
      </div>
      <div v-else class="d-flex justify-content-center py-5">
        <loader />
      </div>
      <div class="d-flex justify-content-end">
        <button
          :class="{ hover: !pptLoader }"
          class="btn-download"
          :disabled="pptLoader"
          @click="exportPPT"
        >
          <div
            v-if="pptLoader"
            class="d-flex justify-content-center align-items-center"
          >
            <loader2 color="#6b6b6b" class="mr-1" />
            {{ translation.replies.charts_tab.download_word_cloud }}
          </div>
          <div v-else>
            <b-icon icon="download" aria-hidden="true"></b-icon>
            {{ translation.replies.charts_tab.download_word_cloud }}
          </div>
        </button>
      </div>

      <div>
        <div class="table-wrapper">
          <div class="header">
            <div>
              {{ translation.replies.charts_tab.words }}
            </div>
            <div>
              {{ translation.replies.charts_tab.frequency_in_percentage }}
            </div>
            <div>
              {{ translation.replies.charts_tab.frequency_in_numbers }}
            </div>
          </div>
          <div class="table-scroll">
            <div v-for="(value, index) in tableWords" :key="index" class="item">
              <div>
                <p :style="{ color: $store.getters.theme[2] }">
                  {{ value.word }}
                </p>
              </div>
              <div class="bar-wrapper">
                <div class="bar">
                  <div
                    :style="{
                      width: `${getPercent(value)}%`,
                      background: $store.getters.theme[1]
                    }"
                  ></div>
                </div>
                <p>{{ getPercent(value) | filterPercent }}%</p>
              </div>
              <div>
                <p>
                  {{ value.weight }}
                </p>
              </div>
            </div>
          </div>
          <div class="footer">
            <div>
              {{ translation.replies.charts_tab.total_words }}:
              {{ tableWords.length }}
            </div>
          </div>
        </div>
        <div class="d-flex justify-content-end">
          <button
            :class="{ hover: !xlsxLoader }"
            class="btn-download"
            @click="exportXLSX"
          >
            <div
              v-if="xlsxLoader"
              class="d-flex justify-content-center align-items-center"
            >
              <loader2 color="#6b6b6b" class="mr-1" />
              {{ translation.replies.charts_tab.download_table }}
            </div>
            <div v-else>
              <b-icon icon="download" aria-hidden="true"></b-icon>
              {{ translation.replies.charts_tab.download_table }}
            </div>
          </button>
        </div>
      </div>
    </div>
    <div v-else>
      No Chart
    </div>
  </div>
</template>

<script>
import configWordCloud from "@/components/charts/configWordCloud.vue";
import mentionsChart from "@/components/charts/mentionsChart.vue";
import loader from "@/components/general/loader.vue";
import loader2 from "@/components/general/loader2.vue";
import VueWordCloud from "vuewordcloud";
import pptxgen from "pptxgenjs";
import * as htmlToImage from "html-to-image";
import * as XLSX from "xlsx/xlsx.mjs";
import { mapState } from "vuex";

export default {
  components: {
    [VueWordCloud.name]: VueWordCloud,
    configWordCloud,
    mentionsChart,
    loader2,
    loader
  },
  props: {
    chartData: {
      required: true
    },
    id: {
      required: true
    }
  },
  filters: {
    filterPercent(value) {
      return value.toFixed(2);
    }
  },
  data() {
    return {
      data: [],
      pptLoader: false,
      xlsxLoader: false,
      messageTimeOut: true,
      messageChanges: true
    };
  },
  computed: {
    ...mapState(["configChart"]),
    config() {
      if (!this.configChart) return false;
      return this.configChart[this.id];
    },
    tableWords() {
      if (!this.config) return [];
      return this.data
        .filter(data => !this.config.discardedWords.includes(data.word))
        .filter((data, index) => index + 1 <= this.config.words)
        .sort((a, b) => a.word.localeCompare(b.word));
    },
    words() {
      if (!this.config) return [];
      return this.data
        .filter(data => !this.config.discardedWords.includes(data.word))
        .filter((data, index) => index + 1 <= this.config.words)
        .map(({ word, weight }) => [word, weight]);
    },
    total() {
      return this.tableWords.reduce((acc, curr) => (acc += curr.weight), 0);
    },
    min() {
      const first = this.tableWords[0].weight;
      return this.tableWords.reduce(
        (acc, curr) => (acc = curr.weight < acc ? curr.weight : acc),
        first
      );
    },
    max() {
      if (!this.tableWords.length) return 0;
      const first = this.tableWords[0].weight;
      return this.tableWords.reduce(
        (acc, curr) => (acc = curr.weight > acc ? curr.weight : acc),
        first
      );
    },
    gap() {
      return this.words.length > 5
        ? this.max / 5
        : this.max / this.words.length;
    }
  },
  watch: {
    config() {
      this.messageChanges = true;
      setTimeout(() => {
        this.messageChanges = false;
      }, 10000);
    }
  },
  methods: {
    async exportXLSX() {
      this.xlsxLoader = true;
      const json = this.data.map(value => ({
        word: value.word,
        percentage: `${this.getPercent(value).toFixed(2)}%`,
        count: value.weight
      }));

      const dataWS = XLSX.utils.json_to_sheet(json);
      const wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, dataWS, "worksheet");
      XLSX.writeFile(wb, `data.xlsx`);
      this.xlsxLoader = false;
    },
    async exportPPT() {
      this.pptLoader = true;

      let slide;
      let pres = new pptxgen();

      const image = await this.getWordCloudImage();

      slide = pres.addSlide();

      slide.addText(this.chartData.taskObject.question_object.statement, {
        x: 0.2,
        y: 0.2,
        color: "6b6b6b",
        fontSize: 10
      });

      slide.addImage({
        data: image,
        x: 0.5,
        y: 0.5,
        w: "80%",
        h: "80%"
      });

      pres.writeFile({ fileName: "chart" });

      this.pptLoader = false;
    },
    async getWordCloudImage() {
      let node = document.querySelector(`#word-cloud-image-${this.id}`);
      return await htmlToImage.toPng(node);
    },
    getPercent({ weight }) {
      const total = (weight * 100) / this.total;
      return total;
    },
    setColor(weight) {
      const colors = this.config.theme.colors;
      if (this.config.theme.hasOneColor) return colors[0];

      if (this.words.length >= 5 && this.max >= 5)
        return weight >= this.gap * 5
          ? colors[4]
          : weight > this.gap * 4
          ? colors[3]
          : weight > this.gap * 3
          ? colors[2]
          : weight > this.gap * 2
          ? colors[1]
          : colors[0];
      else if (this.words.length === 4 || this.max === 4)
        return weight >= this.gap * 4
          ? colors[3]
          : weight > this.gap * 3
          ? colors[2]
          : weight > this.gap * 2
          ? colors[1]
          : colors[0];
      else if (this.words.length === 3 || this.max === 3)
        return weight >= this.gap * 3
          ? colors[2]
          : weight > this.gap * 2
          ? colors[1]
          : colors[0];
      else if (this.words.length === 2 || this.max === 2)
        return weight >= this.gap * 2 ? colors[1] : colors[0];
      else return colors[0];
    }
  },
  created() {
    this.data = this.chartData.answersValues
      .sort((a, b) => {
        if (a.value < b.value) return 1;
        if (a.value > b.value) return -1;
        return 0;
      })
      .map(word => ({
        weight: word.value,
        word: word.label
      }));

    setTimeout(() => {
      this.messageTimeOut = false;
    }, 15000);
  }
};
</script>

<style lang="scss" scoped>
@import url("https://fonts.googleapis.com/css2?family=Abril+Fatface&family=Annie+Use+Your+Telescope&family=Anton&family=Bahiana&family=Barrio&family=Finger+Paint&family=Fredericka+the+Great&family=Gloria+Hallelujah&family=Indie+Flower&family=Life+Savers&family=Londrina+Sketch&family=Love+Ya+Like+A+Sister&family=Merienda&family=Nothing+You+Could+Do&family=Pacifico&family=Roboto&family=Montserrat&family=Quicksand:wght@300&family=Righteous&family=Sacramento&family=Shadows+Into+Light&display=swap");

.answered {
  color: #6b6b6b;
}

.image-word-cloud {
  padding: 10px;
}

.word-cloud-wrapper {
  display: flex;
  justify-content: center;
  position: relative;
  background: rgba(0, 0, 0, 0.05);
  padding: 20px;
}

.btn-download {
  display: flex;
  align-items: center;
  background: none;
  border: none;
  font-size: 1.2rem;
  font-weight: bold;
  color: #6b6b6b;

  &:focus {
    outline: none;
  }
  svg {
    margin-right: 5px;
  }
}

.table-wrapper {
  margin-top: 40px;
  padding: 10px;
  margin-bottom: 20px;
}

.header {
  display: flex;
  margin-bottom: 10px;
  > div {
    background: #e6e6e6;
    font-weight: bold;
    color: #6b6b6b;
    padding: 5px 10px;

    &:nth-of-type(1) {
      margin-right: 10px;
      width: calc(33% - 10px);
    }
    &:nth-of-type(2) {
      margin-right: 10px;
      width: calc(33%);
    }
    &:nth-of-type(3) {
      width: calc(33%);
    }
  }
}

.footer {
  background: #e6e6e6;
  padding: 5px 10px;
  font-weight: bold;
  color: #6b6b6b;
  margin-top: 10px;
}

@media (max-width: 767.98px) {
  .header {
    > div {
      font-size: 0.7rem;
      word-wrap: break-word;
      word-break: break-word;
      &:nth-of-type(1) {
        margin-right: 5px;
        width: calc(23% - 5px);
      }
      &:nth-of-type(2) {
        margin-right: 5px;
        width: calc(38.5%);
      }
      &:nth-of-type(3) {
        width: calc(38.5%);
      }
    }
  }
}

.table-scroll {
  height: 400px;
  overflow-x: auto;
  overflow-y: scroll;
  padding: 10px;

  &::-webkit-scrollbar {
    width: 8px;
    height: 10px;
  }
  &::-webkit-scrollbar-track {
    background: rgba(0, 0, 0, 0.05);
    border-radius: 10px;
  }
  &::-webkit-scrollbar-thumb {
    background: #e6e6e6;
    border-radius: 10px;
    height: 10px;
  }
  &::-webkit-scrollbar-thumb:hover {
    background: #ffffff;
  }
}

.item {
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #e6e6e6;
  padding: 5px;
  &:last-of-type {
    border-bottom: none;
  }
  > div {
    width: calc(33%);
    p {
      margin-bottom: 0;
      color: #6b6b6b;
      word-wrap: break-word;
      word-break: break-word;
    }
    &:nth-of-type(1) {
      width: calc(33% - 10px);
    }
    &:last-of-type {
      display: flex;
      justify-content: center;
    }
    &.bar-wrapper {
      display: flex;
      align-items: center;
      p {
        min-width: 60px;
        margin-left: 10px;
      }
      .bar {
        background: rgba(0, 0, 0, 0.05);
        height: 20px;
        width: 100%;
        div {
          height: 20px;
        }
      }
    }
  }
}

@media (max-width: 767.98px) {
  .item {
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid #e6e6e6;
    padding: 5px;
    &:last-of-type {
      border-bottom: none;
    }
    > div {
      width: 100%;
      min-width: 200px;
      &:nth-of-type(1) {
        width: 100%;
      }
    }
  }
}

.message {
  animation: show 0.3s linear;
  text-align: center;
  color: #6b6b6b;
}
</style>
