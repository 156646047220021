<template>
  <div class="navigation">
    <nav>
      <a @click="goToFeed" class="link">
        <img src="@/img/feed.svg" alt="Feed" />
        {{ translation.header.feed_text }}
      </a>

      <a @click="openActivities" class="link">
        <img src="@/img/activities.svg" alt="Activities" />
        {{ translation.header.assignments_text }}
      </a>

      <a @click="goToForum" class="link">
        <img src="@/img/forum.svg" alt="Forum" />
        {{ translation.header.forum_text }}
      </a>

      <a
        @click="goToAnswers"
        v-if="
          $store.getters.info.user.type === userTypesText.admin ||
            $store.getters.info.user.type === userTypesText.moderator ||
            ($store.getters.info.user.type === userTypesText.observer &&
              (!+permissions.observers.hide_report_replies ||
                !+permissions.observers.hide_report_media ||
                !+permissions.observers.hide_report_charts))
        "
        class="link"
      >
        <img src="@/img/answers.svg" alt="Answers" />
        {{ translation.header.reports_text }}
      </a>

      <a
        v-if="
          $store.getters.info.user.type === userTypesText.moderator ||
            $store.getters.info.user.type === userTypesText.admin ||
            $store.getters.info.user.type === userTypesText.observer
        "
        @click="openAdmin"
        class="link"
      >
        <img src="@/img/admin.svg" alt="Activities" />
        {{
          $store.getters.info.user.type === userTypesText.admin
            ? translation.header.admin_text
            : $store.getters.info.user.type === userTypesText.moderator
            ? translation.header.moderator_text
            : translation.header.observer_text
        }}
      </a>
    </nav>
    <div class="nav-line">
      <div
        :style="{ background: $store.getters.theme[2] }"
        class="nav-thumb"
      ></div>
    </div>
  </div>
</template>

<script>
import { tabOptions, userTypesText } from "@/constants";
import { mapState } from "vuex";

export default {
  props: {
    subActivity: {
      required: true
    },
    subAdmin: {
      required: true
    }
  },

  data() {
    return {
      tabOptions,
      userTypesText
    };
  },

  computed: {
    ...mapState(["permissions"]),

    routeName() {
      return this.$route.name;
    }
  },

  watch: {
    routeName() {
      this.setThumb();
    }
  },

  methods: {
    goToFeed() {
      if (this.routeName !== "feed") this.$router.push({ name: "feed" });
      this.getLink(0);
    },

    openActivities() {
      this.getLink(1);
      if (
        this.$store.getters.info.user.type === this.userTypesText.admin ||
        (this.$store.getters.info.user.type === this.userTypesText.moderator &&
          !+this.permissions.moderators.hide_create_activity)
      )
        this.$emit("subActivity", !this.subActivity);
      else if (this.routeName !== "activities")
        this.$router.push({
          name: "activities",
          query: { _page: 1, _q: "" }
        });
    },

    goToForum() {
      if (this.routeName !== "forum") this.$router.push({ name: "forum" });
      this.getLink(2);
    },

    goToAnswers() {
      if (this.routeName !== "answers") {
        if (
          this.$store.getters.info.user.type !== userTypesText.observer ||
          (+this.permissions.observers.hide_report_replies &&
            +this.permissions.observers.hide_report_media &&
            +this.permissions.observers.hide_report_charts) ||
          !+this.permissions.observers.hide_report_replies
        ) {
          this.$router.push({
            name: "answers",
            query: { _option: this.tabOptions.reports, _page: 1, _q: "" }
          });
        } else if (!+this.permissions.observers.hide_report_media) {
          this.$router.push({
            name: "answers",
            query: { _option: this.tabOptions.media, _page: 1, _q: "" }
          });
        } else {
          this.$router.push({
            name: "answers",
            query: { _option: this.tabOptions.charts, _page: 1, _q: "" }
          });
        }
      }

      this.getLink(3);
    },

    openAdmin() {
      if (
        this.$store.getters.info.user.type === userTypesText.observer &&
        +this.permissions.observers.hide_report_replies
      )
        this.getLink(3);
      else this.getLink(4);
      this.$emit("subAdmin", !this.subAdmin);
    },

    getLink(linkIndex) {
      const thumb = document.querySelector(".nav-thumb");
      if (linkIndex || linkIndex === 0) {
        this.$emit("subActivity", false);
        this.$emit("subAdmin", false);
        thumb.style.opacity = 1;
        thumb.style.marginLeft = `${120 * linkIndex}px`;
      } else {
        thumb.style.opacity = 0;
      }
    },

    setThumb() {
      if (this.routeName === "feed") this.getLink(0);
      else if (
        this.routeName === "activities" ||
        this.routeName === "createactivity" ||
        this.routeName === "tasks" ||
        this.routeName === "createtask"
      )
        this.getLink(1);
      else if (
        this.routeName === "forum" ||
        this.routeName === "newpost" ||
        this.routeName === "post"
      )
        this.getLink(2);
      else if (this.routeName === "answers") this.getLink(3);
      else if (
        this.routeName === "account" ||
        this.routeName === "termsofuse" ||
        this.routeName === "privacypolicy"
      )
        this.getLink();
      else if (
        this.$store.getters.info.user.type === userTypesText.observer &&
        +this.permissions.observers.hide_report_replies
      )
        this.getLink(3);
      else this.getLink(4);
    }
  },

  mounted() {
    this.setThumb();
  }
};
</script>

<style lang="scss" scoped>
.navigation {
  display: flex;
  justify-content: flex-end;
  position: relative;
  nav {
    display: flex;
    align-items: flex-end;
  }
}

.nav-line {
  position: absolute;
  display: block;
  width: 100%;
  height: 5px;
  bottom: 0;
}

.nav-thumb {
  width: 120px;
  height: 5px;
  transition: all 0.3s ease;
}

.link {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 120px;
  color: #999;
  text-transform: uppercase;
  font-weight: bold;
  text-decoration: none;
  position: relative;
  transition: all 0.5s ease-in-out;
  padding-bottom: 20px;
  cursor: pointer;
  img {
    height: 25px;
    // margin-bottom: 5px;
  }
}
</style>
