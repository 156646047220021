<template>
  <div class="container">
    <div class="row py-5">
      <div
        :class="{
          'col-lg-8':
            $store.getters.info.user.type === userTypesText.participant,
          col: $store.getters.info.user.type !== userTypesText.participant
        }"
      >
        <activities
          :activities="activities"
          :openNewActivity="filterActivities"
          :feed="false"
          :loader="loader"
          :getActivities="getActivities"
          @edit="id => handleEdit(id)"
          @task="id => handleTask(id)"
        />
      </div>
      <div
        class="col-lg-4"
        v-if="$store.getters.info.user.type === userTypesText.participant"
      >
        <next-activity
          :loader2="loader"
          :activity="nextActivity"
          :activities="activities"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { api } from "@/services.js";
import activities from "@/components/general/activities.vue";
import nextActivity from "@/components/general/nextActivity.vue";
import { userTypesText } from "@/constants";

export default {
  components: { activities, nextActivity },
  data() {
    return {
      openEditor: false,
      openNewActivity: false,
      activities: [],
      newActivityId: "none",
      tab: 1,
      reload: false,
      scroll: false,
      loader: false,
      userTypesText
    };
  },
  computed: {
    nextActivity() {
      if (this.activities.length) return this.activities[0];
      else return {};
    },
    filterActivities() {
      if (this.activities && this.activities.length >= 3)
        return this.activities.slice(0, 3);
      else return this.activities;
    }
  },
  watch: {
    reload() {
      this.getActivities();
    }
  },
  methods: {
    handleNewActivity() {
      if (!this.$store.getters.is_over) {
        this.openNewActivity = !this.openNewActivity;
        this.tab = 1;
        this.newActivityId = "none";
      }
    },
    getActivities() {
      this.loader = true;

      let url = `community/${this.$store.getters.community.id}/activities`;
      if (this.$store.getters.info.user.type === this.userTypesText.participant)
        url = `community/${this.$store.getters.community.id}/participant/${this.$store.getters.info.user.sub}/activities`;

      api
        .get(url, {
          headers: {
            Authorization: `Bearer ${this.$store.getters.token}`
          }
        })
        .then(r => {
          if (r.status === 200) {
            this.activities = r.data.community.activities;
          }
          this.loader = false;
        })
        .catch(() => {
          this.loader = false;
        });
    },
    handleTask(id) {
      this.tab = 2;
      this.openNewActivity = true;
      this.newActivityId = id;
      this.scroll = !this.scroll;
    },
    handleEdit(id) {
      this.tab = 1;
      this.openNewActivity = true;
      this.newActivityId = id;
      this.scroll = !this.scroll;
    }
  },
  created() {
    this.getActivities();
  }
};
</script>

<style lang="scss" scoped>
.edit-wrapper {
  max-width: 300px;
  cursor: pointer;
}

.edit-btn {
  position: relative;
  height: 70px;
}

.close-editor {
  margin-top: 10px;
  margin-right: 10px;
  animation: grow 400ms ease-in-out forwards;
  position: absolute;
  right: 15px;
  bottom: -10px;
  &.newactivity {
    bottom: 12px;
    right: 0px;
  }
}

.edit-theme {
  display: flex;
  align-items: center;
  button {
    display: flex;
    justify-content: center;
    align-items: center;
    background: #fff;
    border: none;
    border-radius: 17px;
    color: #6b6b6b;
    text-transform: uppercase;
    color: #777470;
    font-weight: bold;
    font-size: 0.81rem;
    letter-spacing: 0.13em;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    padding: 5px 30px 5px 10px;
    &:focus {
      outline: none;
    }
  }
}
</style>
