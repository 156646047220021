<template>
  <div>
    <div class="pt-4 switch">
      <div class="d-flex mb-3">
        <b-form-checkbox v-model="description" name="check-button" switch>
          {{ translation.create_task.media.add_captions }}
        </b-form-checkbox>
      </div>

      <div class="d-flex mb-3" v-if="description">
        <b-form-checkbox
          v-model="mandatoryDescription"
          name="check-button"
          switch
        >
          {{ translation.create_task.media.mandatory_captions }}
        </b-form-checkbox>
      </div>
    </div>
  </div>
</template>

<script>
import { taskEnum, addTaskText } from "@/constants";

export default {
  props: {
    taskText: {
      required: true
    },
    task: {
      required: true
    }
  },
  data() {
    return {
      description: false,
      mandatoryDescription: false,
      addTaskText,
      taskEnum
    };
  },
  computed: {
    taskObj() {
      return {
        statement: this.taskText,
        has_description: this.description,
        mandatory_description: this.mandatoryDescription
      };
    }
  },
  watch: {
    taskObj() {
      this.$emit("update", this.taskObj);
    }
  },
  methods: {
    getDescription() {
      if (
        this.task !== this.addTaskText &&
        +this.task.type_id === this.taskEnum.imageTask.id
      ) {
        this.description = this.task.question_object.has_description;
        this.mandatoryDescription = this.task.question_object.mandatory_description;
      } else {
        this.setTask();
      }
      this.$emit("update", this.taskObj);
    },
    setTask() {
      this.description = false;
      this.mandatoryDescription = false;
    }
  },
  created() {
    this.setTask();
    this.getDescription();
  }
};
</script>

<style lang="scss" scoped>
.switch {
  color: #6b6b6b;
}
</style>
