var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('h2',{staticClass:"title"},[_vm._v(" "+_vm._s(_vm.translation.replies.charts_tab.choose_data_format)+" ")]),_c('div',{staticClass:"is-percent"},[_c('button',{staticClass:"btn hover",style:({
        background: _vm.isPercent
          ? _vm.$store.getters.theme[2]
          : _vm.$store.getters.theme[3]
      }),on:{"click":function($event){_vm.isPercent = false}}},[_vm._v(" "+_vm._s(_vm.translation.replies.charts_tab.number)+" ")]),_c('button',{staticClass:"btn hover",style:({
        background: !_vm.isPercent
          ? _vm.$store.getters.theme[2]
          : _vm.$store.getters.theme[3]
      }),on:{"click":function($event){_vm.isPercent = true}}},[_vm._v(" "+_vm._s(_vm.translation.replies.charts_tab.percentage)+" ")])])])}
var staticRenderFns = []

export { render, staticRenderFns }