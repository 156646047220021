<template>
  <div class="container">
    <div class="row py-5">
      <div class="col">
        <router-link
          v-if="firstParticipant"
          :to="{
            name: 'participant',
            query: { _id: firstParticipant, _page: 1 }
          }"
          class="back"
          ><b-icon class="mr-1" icon="arrow-left-circle"></b-icon>
          {{ translation.global.return }}
        </router-link>
        <div class="card-b mb-4 px-2 py-4 p-md-5">
          <div class="option-btns">
            <button
              class="btn px-2 px-md-5"
              :class="{ active: option === tabOptions.registeredOption }"
              :style="{
                background:
                  option === tabOptions.registeredOption
                    ? $store.getters.theme[2]
                    : $store.getters.theme[0],
                color:
                  option === tabOptions.registeredOption ? '#ffffff' : '#999999'
              }"
              @click="changeTab(tabOptions.registeredOption)"
            >
              {{ translation.manage_participants.registered }}
            </button>
            <button
              class="btn px-2 px-md-5"
              :class="{ active: option === tabOptions.invitedOption }"
              :style="{
                background:
                  option === tabOptions.invitedOption
                    ? $store.getters.theme[2]
                    : $store.getters.theme[0],
                color:
                  option === tabOptions.invitedOption ? '#ffffff' : '#999999',
                'border-color': $store.getters.theme[2]
              }"
              @click="changeTab(tabOptions.invitedOption)"
            >
              {{ translation.manage_participants.invited }}
            </button>
            <button
              class="btn px-2 px-md-5"
              :class="{ active: option === tabOptions.profileOption }"
              :style="{
                background:
                  option === tabOptions.profileOption
                    ? $store.getters.theme[2]
                    : $store.getters.theme[0],
                color:
                  option === tabOptions.profileOption ? '#ffffff' : '#999999'
              }"
              @click="changeTab(tabOptions.profileOption)"
            >
              {{ translation.manage_participants.profiles }}
            </button>
          </div>
          <transition mode="out-in">
            <registered-tab v-if="option === tabOptions.registeredOption" />
            <invited-tab v-if="option === tabOptions.invitedOption" />
            <profile-tab v-if="option === tabOptions.profileOption" />
          </transition>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { tabOptions } from "@/constants";
import registeredTab from "@/components/manageparticipants/registeredTab.vue";
import invitedTab from "@/components/manageparticipants/invitedTab.vue";
import profileTab from "@/components/manageparticipants/profileTab.vue";

export default {
  name: "ManageParticipants",
  components: { registeredTab, invitedTab, profileTab },
  data() {
    return {
      tabOptions,
      loader: false
    };
  },
  computed: {
    option() {
      return this.$route.query._option;
    },
    firstParticipant() {
      return this.$route.query._first;
    }
  },
  methods: {
    changeTab(option) {
      const isDifferentRoute = this.option !== option;

      if (isDifferentRoute) {
        this.$router.push({ query: { _option: option, _page: 1 } });
      }
    }
  },
  created() {}
};
</script>

<style lang="scss" scoped>
.option-btns {
  display: flex;
  justify-content: center;
  button {
    font-weight: bold;
    font-size: 1.3rem;
    transition: all 0.3s ease;
    min-width: 245px;
    border: none;
    &:nth-of-type(1) {
      border-top-left-radius: 35px;
      border-bottom-left-radius: 35px;
    }
    &:nth-of-type(2) {
      border-right: 1px solid;
      border-left: 1px solid;
      border-radius: 0;
    }
    &:nth-of-type(3) {
      border-top-right-radius: 35px;
      border-bottom-right-radius: 35px;
    }
    &.active {
      z-index: 2;
    }
    &:hover {
      color: #666 !important;
    }
  }
}

@media (max-width: 991.98px) {
  .option-btns {
    button {
      min-width: 200px;
      font-size: 0.8rem;
    }
  }
}

@media (max-width: 767.98px) {
  .option-btns {
    button {
      min-width: 100px;
      font-size: 0.8rem;
    }
  }
}

.back {
  color: #6b6b6b;
  cursor: pointer;
}
</style>
