<template>
  <div>
    <div class="d-flex align-items-center justify-content-end mt-3">
      <div
        :class="{ active: screenRecordingError }"
        class="error-box bg-danger p-2 mr-3"
      >
        <b-icon class="mr-1" icon="exclamation-triangle-fill"></b-icon>
        {{ screenRecordingError }}
      </div>
    </div>

    <div class="link">
      <label for="link">
        Link:
      </label>
      <b-form-input
        placeholder="Ex: https://owvoices.com/"
        v-model="link"
        name="link"
        size="sm"
      ></b-form-input>
    </div>

    <div class="switch d-flex mt-4">
      <span class="pr-2">
        {{ translation.create_task.screen_recording.mandatory_description }}:
      </span>
      <b-form-checkbox
        v-model="mandatoryDescription"
        name="check-button"
        switch
      >
      </b-form-checkbox>
    </div>

    <div class="min-max mt-4 mb-3">
      <div class="mb-3">
        <label for="min">
          {{ translation.create_task.reaction.minimum_number }}:
        </label>
        <b-form-input
          v-model="min"
          name="min"
          type="number"
          min="1"
          :max="max"
          size="sm"
        ></b-form-input>
      </div>
      <div>
        <label for="max">
          {{ translation.create_task.reaction.maximum_number }}:
        </label>
        <b-form-input
          v-model="max"
          name="max"
          type="number"
          min="1"
          size="sm"
        ></b-form-input>
      </div>
    </div>
  </div>
</template>

<script>
import { taskEnum, addTaskText } from "@/constants";

export default {
  components: {},
  props: {
    taskText: {
      required: true
    },
    task: {
      required: true
    }
  },
  data() {
    return {
      link: "",
      min: 1,
      max: 10,
      mandatoryDescription: false,
      taskEnum,
      addTaskText
    };
  },
  computed: {
    taskObj() {
      return {
        statement: this.taskText,
        min_answers: +this.min,
        max_answers: +this.max,
        mandatory_description: this.mandatoryDescription ? 1 : 0,
        link: this.link
      };
    },
    screenRecordingError() {
      let message = null;

      if (+this.min > this.max)
        message = this.translation.create_task.reaction.error.bigger;
      else if (this.min === "")
        message = this.translation.create_task.reaction.error.no_minimum;
      else if (this.max === "")
        message = this.translation.create_task.reaction.error.no_maximum;

      return message;
    }
  },
  watch: {
    taskObj() {
      this.$emit("update", this.taskObj);
    },
    screenRecordingError() {
      this.$emit("error", this.screenRecordingError);
    }
  },
  methods: {
    getDescription() {
      if (
        this.task !== this.addTaskText &&
        +this.task.type_id === this.taskEnum.screenRecording.id
      ) {
        this.min = this.task.question_object.min_answers
          ? this.task.question_object.min_answers
          : 1;

        this.max = this.task.question_object.max_answers
          ? this.task.question_object.max_answers
          : 10;

        this.link = this.task.question_object.link;

        this.mandatoryDescription = +this.task.question_object
          .mandatory_description
          ? true
          : false;
      } else {
        this.setTask();
      }
      this.$emit("update", this.taskObj);
    },
    setTask() {
      this.min = 1;
      this.max = 10;
      this.link = "";
      this.mandatoryDescription = false;
    },
    init() {
      if (this.task === this.addTaskText) {
        this.setTask();
      }
    }
  },
  mounted() {
    this.getDescription();
    this.$emit("error", this.screenRecordingError);
  }
};
</script>

<style lang="scss" scoped>
.link,
.switch {
  color: #6b6b6b;
}

.min-max {
  color: #6b6b6b;
  > div {
    display: flex;
    align-items: center;
    label {
      margin-bottom: 0;
    }
    input {
      max-width: 100px;
      margin-left: 10px;
    }
  }
}
</style>
