<template>
  <div id="createtask" class="container">
    <div class="row pt-5">
      <div class="col">
        <div class="d-flex justify-content-end">
          <a class="mb-2 back" @click="$router.go(-1)">
            <b-icon class="mr-1" icon="arrow-left-circle"></b-icon>
            {{ translation.global.return }}
          </a>
        </div>
      </div>
    </div>
    <div v-if="post && !loaderPost" class="row pb-5">
      <welcome class="d-none d-md-block" />
      <div class="col-md-9">
        <div class="p-relative">
          <div class="card-b p-3 border-radius-right post">
            <post :post="post" :getPost="getPost" @updateComment="getPost" />
          </div>
          <div
            class="detail"
            :style="{ 'background-color': $store.getters.theme[2] }"
          ></div>
        </div>
        <div v-if="post.comments.length" class="ml-md-5 mt-3 card-b p-3">
          <div v-for="(comment, index) in post.comments" :key="index">
            <sub-reply
              :getPost="getPost"
              :hasComment="true"
              :comment="comment"
              :type="1"
              :index="index"
              @updateComment="getPost"
              @updateLike="getPost"
              :isEditable="+post.can_comments_be_edited"
            />
            <div v-if="comment.comments.length" class="sub-reply p-relative">
              <div
                :class="{ first: cIndex === 0 }"
                class="w-100 d-flex p-relative pl-md-5"
                v-for="(commentReply, cIndex) in comment.comments"
                :key="cIndex"
              >
                <sub-reply
                  class="pl-md-5 pl-5"
                  :getPost="getPost"
                  :hasComment="false"
                  :type="2"
                  :index="cIndex"
                  :comment="commentReply"
                  @updateComment="getPost"
                  @updateLike="getPost"
                  :isEditable="+post.can_comments_be_edited"
                />
              </div>
            </div>
          </div>
        </div>
        <div v-else class="d-flex card-b justify-content-center mt-3 p-3">
          No comments
        </div>
      </div>
    </div>
    <div class="d-flex justify-content-center py-5" v-else-if="loaderPost">
      <loader />
    </div>
  </div>
</template>

<script>
import { api } from "@/services.js";
import post from "@/components/forum/post.vue";
import subReply from "@/components/forum/subReply.vue";
import welcome from "@/components/forum/welcome.vue";
import loader from "@/components/general/loader.vue";

export default {
  components: { post, subReply, welcome, loader },
  data() {
    return {
      post: null,
      loaderPost: false
    };
  },
  computed: {
    postId() {
      return this.$route.query.pId;
    }
  },
  watch: {},
  methods: {
    getPost(type) {
      if (this.postId) {
        if (type && type === "init") this.loaderPost = true;
        api
          .get(`forum/${this.postId}`, {
            headers: {
              Authorization: `Bearer ${this.$store.getters.token}`
            }
          })
          .then(r => {
            if (r.status === 200) {
              this.post = r.data;
            }
            this.loaderPost = false;
          })
          .catch(() => {
            this.loaderPost = false;
            this.$router.push({ name: "forum" });
          });
      } else {
        this.$router.push({ name: "forum" });
      }
    },
    updateComment() {
      this.post.commentsAmount = this.post.commentsAmount + 1;
    },
    init() {
      this.getPost("init");
    }
  },
  created() {
    this.init();
  }
};
</script>

<style lang="scss" scoped>
.border-radius-right {
  border-radius: 0;
  border-top-right-radius: 20px;
  border-bottom-right-radius: 20px;
}

.border-radius-left {
  border-radius: 0;
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;
}

@media (max-width: 767.98px) {
  .border-radius-right {
    border-radius: 20px;
  }

  .border-radius-left {
    border-radius: 20px;
  }
}

.profile-img {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100px;
  height: 100px;
  border-radius: 50%;
  overflow: hidden;
  margin-bottom: 5px;

  img {
    object-fit: cover;
    height: 100px;
    width: 100px;
  }
}

@media (max-width: 767.98px) {
  .profile-img {
    width: 60px;
    height: 60px;

    img {
      height: 60px;
      width: 60px;
    }
  }
}

.name {
  margin: 0 auto;
  max-width: 100px;
  font-weight: bold;
  color: #6b6b6b;
}

.text {
  font-size: 1rem;
  color: #6b6b6b;
  h3 {
    font-size: 1rem;
    font-weight: bold;
  }
}

.search {
  display: flex;
  align-items: center;
  border: 1px solid #676767;
  color: #6b6b6b;
  border-radius: 27px;
  overflow: hidden;
  flex: 1;
  input {
    width: 100%;
    border: none;
    background-color: rgba(0, 0, 0, 0);
    &:focus {
      outline: none;
      color: #6b6b6b;
    }
  }
}

.btn-newpost {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 30px;
  border-radius: 17px;
  text-transform: uppercase;
  font-weight: bold;
  color: #fff;
  font-size: 0.65rem;
  white-space: nowrap;
  svg {
    font-size: 1.2rem !important ;
  }
}

.detail {
  width: 3px;
  height: 30px;
  position: absolute;
  bottom: -30px;
  left: 80px;
  &::after {
    content: "";
    position: absolute;
    bottom: 0;
    left: -3.5px;
    display: block;
    width: 10px;
    height: 10px;
    background-color: inherit;
    border-radius: 50%;
  }
}

.sub-reply {
  position: relative;
}

.sub-reply-detail {
  margin-left: 45px;
  width: 60px;
  height: 4px;
  margin-top: 60px;
  margin-right: 10px;

  &::before {
    content: "";
    position: absolute;
    display: block;
    width: 4px;
    height: 100%;
    background-color: inherit;
    bottom: 0;
  }
  &.first {
    &::before {
      top: -40px;
      height: 100px;
    }
  }
  &::after {
    content: "";
    width: 10px;
    height: 10px;
    border-radius: 50%;
    position: absolute;
    display: block;
    background-color: inherit;
    right: 2px;
    top: 57px;
  }
}

.back {
  color: #6b6b6b;
  cursor: pointer;
}
</style>
