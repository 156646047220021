<template>
  <div>
    <Bar
      ref="chart"
      v-if="chartData && chartOptions"
      :chart-options="chartOptions"
      :chart-data="chartData"
      :chart-id="chartId"
      :dataset-id-key="datasetIdKey"
      :plugins="plugins"
      :css-classes="cssClasses"
      :styles="styles"
      :width="width"
      :height="height"
    />
    <div class="d-flex justify-content-end">
      <button
        :class="{ hover: !pptLoader }"
        class="btn-download"
        :disabled="pptLoader"
        @click="exportPPT"
      >
        <div
          v-if="pptLoader"
          class="d-flex justify-content-center align-items-center"
        >
          <loader2 color="#6b6b6b" class="mr-1" />
          {{ translation.replies.charts_tab.download_chart }}
        </div>
        <div v-else>
          <b-icon icon="download" aria-hidden="true"></b-icon>
          {{ translation.replies.charts_tab.download_chart }}
        </div>
      </button>
    </div>
  </div>
</template>

<script>
import { Bar } from "vue-chartjs/legacy";
import pptxgen from "pptxgenjs";
import loader2 from "@/components/general/loader2.vue";

import {
  Chart as ChartJS,
  Title,
  Tooltip,
  Legend,
  BarElement,
  CategoryScale,
  LinearScale
} from "chart.js";

ChartJS.register(
  Title,
  Tooltip,
  Legend,
  BarElement,
  CategoryScale,
  LinearScale
);

export default {
  name: "BarChart",
  components: {
    Bar,
    loader2
  },
  props: {
    chartId: {
      type: String,
      default: "bar-chart"
    },
    datasetIdKey: {
      type: String,
      default: "label"
    },
    width: {
      type: Number,
      default: 400
    },
    height: {
      type: Number,
      default: 400
    },
    cssClasses: {
      default: "",
      type: String
    },
    styles: {
      type: Object,
      default: () => {}
    },
    plugins: {
      type: Array,
      default: () => []
    },
    colors: {
      required: true
    },
    hasOneColor: {
      required: true
    },
    data: {
      required: true
    },
    getPercent: {
      required: true
    },
    isPercent: {
      required: true
    },
    statement: {
      required: true
    }
  },
  data() {
    return {
      chartData: null,
      chartOptions: null,
      pptLoader: false
    };
  },
  methods: {
    async exportPPT() {
      const backgroundColor = this.hasOneColor ? [this.colors[0]] : this.colors;
      this.pptLoader = true;
      let slide;
      let pres = new pptxgen();
      slide = pres.addSlide();

      slide.addText(this.statement, {
        x: 0.2,
        y: 0.2,
        color: "6b6b6b",
        fontSize: 10
      });

      let options = {
        x: 0.8,
        y: 0.8,
        w: 6.0,
        h: 3.0,
        barDir: "bar",
        barGrouping: "stacked",
        chartColors: backgroundColor,
        showLegend: true,
        valAxisMinVal: 0,
        valAxisMaxVal: this.isPercent ? 100 : this.total
      };

      const labelDatasets = this.data.map(dataset => dataset.question);
      const labels = this.data[0].data.map(data => data.label);
      const datasetsSize = this.data[0].data.length;
      const datasets = [];

      for (let index = 0; index < datasetsSize; index++) {
        datasets.push({
          name: labels[index],
          labels: labelDatasets,
          values: this.data.map(dataset =>
            this.isPercent
              ? this.getPercent(dataset.data[index], dataset.data)
              : dataset.data[index].value
          )
        });
      }

      slide.addChart(pres.charts.BAR, datasets, options);
      pres.writeFile({ fileName: "chart" });
      this.pptLoader = false;
    },
    setData() {
      const labelDatasets = this.data.map(dataset => dataset.question);
      const labels = this.data[0].data.map(data => data.label);
      const datasetsSize = this.data[0].data.length;
      const datasets = [];

      for (let index = 0; index < datasetsSize; index++) {
        datasets.push({
          label: labels[index],
          data: this.data.map(dataset =>
            this.isPercent
              ? this.getPercent(dataset.data[index], dataset.data)
              : dataset.data[index].value
          ),
          backgroundColor: this.hasOneColor
            ? this.colors[0]
            : this.colors[index]
        });
      }

      this.chartData = {
        labels: labelDatasets,
        datasets: datasets
      };
    },
    setOptions() {
      this.chartOptions = {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
          legend: {
            align: "start",
            labels: {
              boxWidth: 10,
              boxHeight: 10
            }
          }
        },
        indexAxis: "y",
        scales: {
          x: {
            stacked: true,
            min: 0,
            max: this.data[0].data.reduce((acc, curr) => (acc += curr.value), 0)
          },
          y: {
            stacked: true
          }
        }
      };

      if (this.isPercent) {
        this.chartOptions.scales.x = {
          stacked: true,
          min: 0,
          max: 100,
          ticks: {
            callback: value => `${value}%`
          },
          scaleLabel: {
            display: true
          }
        };

        this.chartOptions.plugins.tooltip = {
          callbacks: {
            label: tooltipItem =>
              `${tooltipItem.dataset.label}: ${tooltipItem.raw.toFixed(2)}%`
          }
        };
      }
    }
  },
  mounted() {
    this.setOptions();
    this.setData();
  }
};
</script>

<style lang="scss" scoped>
.btn-download {
  display: flex;
  align-items: center;
  background: none;
  border: none;
  font-size: 1.2rem;
  font-weight: bold;
  color: #6b6b6b;
  margin-top: 30px;
  &:focus {
    outline: none;
  }
  svg {
    margin-right: 5px;
  }
}

.question {
  color: #6b6b6b;
  text-align: center;
  margin-bottom: 30px;
}
</style>
