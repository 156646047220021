<template>
  <div>
    <div class="d-flex align-items-center justify-content-end mt-3">
      <div
        :class="{ active: surveyError }"
        class="error-box bg-danger p-2 mr-3"
      >
        <b-icon class="mr-1" icon="exclamation-triangle-fill"></b-icon>
        {{ surveyError }}
      </div>
      <button
        @click="$bvModal.show('quickly-add')"
        :style="{ background: $store.getters.theme[2] }"
        class="btn btn-quickly"
      >
        {{ translation.create_task.survey.quickly_add }}
      </button>
      <b-modal
        @ok="quicklyAdd"
        :cancel-title="translation.global.cancel"
        id="quickly-add"
        :title="translation.create_task.survey.quickly_add"
      >
        <p>{{ translation.create_task.survey.name_a_option }}</p>
        <b-form-textarea
          id="textarea"
          :placeholder="translation.create_task.survey.quickly_add_placeholder"
          v-model="quicklyAddText"
        ></b-form-textarea>
      </b-modal>
    </div>
    <div class="survey-options" v-if="surveyOptions.length">
      <div
        v-for="(option, index) in surveyOptions"
        :key="index"
        :class="{ disabled: !option.active }"
        class="survey-option"
      >
        <div>
          <b-form-input
            v-model="option.codeString"
            maxlength="10"
            :state="
              !isCodeValid(option.codeString) &&
                !hasDuplicate(activeOptions, 'codeString') &&
                !!option.codeString.length &&
                option.codeString !== '0'
            "
            v-b-tooltip.hover="{ variant: 'secondary' }"
            :title="translation.create_task.error.code_validation"
          ></b-form-input>
        </div>
        <input
          :class="{
            ['text-danger']: redText && index !== surveyOptions.length - 1
          }"
          :placeholder="translation.create_task.survey.add_option"
          v-model="option.text"
          @click="addSurveyOption(index)"
          @keyup="addSurveyOption(index)"
          type="text"
          maxlength="255"
        />
        <div
          v-if="option.active && surveyOptions.length > 3"
          @click="removeSurveyOption(index)"
        >
          <b-icon icon="x"></b-icon>
        </div>
      </div>
    </div>

    <div class="pt-4 switch">
      <div class="d-flex mb-3">
        <b-form-checkbox v-model="other" name="check-button" switch>
          {{ translation.create_task.survey.other_option }}
        </b-form-checkbox>
        <tooltip
          class="ml-2"
          :left="true"
          :text="translation.create_task.survey.tooltip_other"
        />
      </div>

      <div class="d-flex mb-3">
        <b-form-checkbox v-model="onlyChoice" name="check-button" switch>
          {{ translation.create_task.survey.single_option }}
        </b-form-checkbox>
        <tooltip
          class="ml-2"
          :left="true"
          :text="translation.create_task.survey.tooltip_single_answer"
        />
      </div>
      <div class="d-flex mb-3">
        <b-form-checkbox v-model="none" name="check-button" switch>
          {{ translation.create_task.survey.none_option }}
        </b-form-checkbox>
        <tooltip
          class="ml-2"
          :left="true"
          :text="translation.create_task.survey.tooltip_none"
        />
      </div>
    </div>
  </div>
</template>

<script>
import tooltip from "@/components/general/tooltip.vue";
import { taskEnum, addTaskText, regexpCodeString } from "@/constants";

export default {
  props: {
    taskText: {
      required: true
    },
    task: {
      required: true
    }
  },
  components: {
    tooltip
  },
  data() {
    return {
      surveyOptions: [],
      onlyChoice: false,
      none: false,
      other: false,
      quicklyAddText: "",
      regexpCodeString,
      taskEnum,
      addTaskText
    };
  },
  computed: {
    activeOptions() {
      return this.surveyOptions.filter(option => option.active);
    },
    taskObj() {
      return {
        statement: this.taskText,
        options: this.surveyOptions
          .filter(option => option.active)
          .map((option, index) => ({
            option: option.text,
            index: index,
            code_string: option.codeString
          })),
        is_single_choice: this.onlyChoice ? 1 : 0,
        has_none: this.none ? 1 : 0,
        has_other: this.other ? 1 : 0
      };
    },
    redText() {
      let resp = false;

      if (
        this.hasDuplicate(this.activeOptions, "text") ||
        this.hasDuplicate(this.activeOptions, "codeString") ||
        this.hasEmpty(this.activeOptions) ||
        this.hasZeros(this.activeOptions)
      )
        resp = true;
      return resp;
    },
    surveyError() {
      let message = null;

      if (this.hasEmpty(this.activeOptions, "text"))
        message = this.translation.create_task.survey.error.empty_option;
      else if (this.hasDuplicate(this.activeOptions, "text"))
        message = this.translation.create_task.survey.error.duplicated_options;
      else if (this.hasDuplicate(this.activeOptions, "codeString"))
        message = this.translation.create_task.survey.error.duplicated_codes;
      else if (this.hasZeros(this.activeOptions))
        message = this.translation.create_task.error.the_field_cannot;
      else if (this.hasEmpty(this.activeOptions, "codeString"))
        message = this.translation.create_task.error.error_empty_code_field;
      else if (
        this.activeOptions.filter(option => option.codeString === "0").length
      )
        message = this.translation.create_task.error.number_zero;
      else if (
        this.activeOptions.filter(
          (option, index) =>
            this.isCodeValid(option.codeString) &&
            index !== this.surveyOptions.length - 1
        ).length
      )
        message = this.translation.create_task.error.code_validation;

      return message;
    }
  },
  watch: {
    taskObj() {
      this.$emit("update", this.taskObj);
    },
    surveyError() {
      this.$emit("error", this.surveyError);
    }
  },
  methods: {
    isCodeValid(codeString) {
      return codeString.match(this.regexpCodeString);
    },
    quicklyAdd() {
      if (this.quicklyAddText.length) {
        const lastIndex = this.surveyOptions.length - 1;
        const options = this.quicklyAddText.split(";");

        options.forEach((option, index) => {
          const hasOption = option.length;
          if (hasOption) {
            if (index === 0) {
              this.surveyOptions[lastIndex].text = option;
              this.surveyOptions[lastIndex].active = true;
            } else {
              this.surveyOptions.push({
                text: option,
                codeString: `${this.surveyOptions.length + 1}`,
                active: true
              });
            }
          }
        });

        this.surveyOptions.push({
          text: "",
          codeString: `${this.surveyOptions.length + 1}`,
          active: false
        });

        this.quicklyAddText = "";
      }
    },
    hasZeros(array) {
      return array.some(option => option.text === "0");
    },
    hasEmpty(array) {
      return array.some(option => !option.text.length && option.active);
    },
    hasDuplicate(array, key) {
      const valuesSoFar = [];
      for (let i = 0; i < array.length; ++i) {
        const value = array[i][key].trim();
        if (valuesSoFar.indexOf(value) !== -1) {
          return true;
        }
        valuesSoFar.push(value);
      }
      return false;
    },
    addSurveyOption(index) {
      if (!this.surveyOptions[index].active) {
        this.surveyOptions[index].active = true;

        this.surveyOptions.push({
          text: "",
          codeString: `${this.surveyOptions.length + 1}`,
          active: false
        });
      }
    },
    removeSurveyOption(index) {
      if (this.surveyOptions.length > 3) this.surveyOptions.splice(index, 1);
    },
    getDescription() {
      if (
        (this.task !== this.addTaskText) &
        (+this.task.type_id === this.taskEnum.surveyTask.id)
      ) {
        this.surveyOptions = this.task.question_object.options.map(
          (option, index) => ({
            text: option.option,
            codeString: option.code_string ? option.code_string : `${index}`,
            active: true
          })
        );

        this.surveyOptions.push({
          text: "",
          codeString: `${this.surveyOptions.length}`,
          active: false
        });

        if (this.task.question_object.is_single_choice) this.onlyChoice = true;
        if (this.task.question_object.has_none) this.none = true;
        if (this.task.question_object.has_other) this.other = true;
      } else {
        this.setTask();
      }
      this.$emit("update", this.taskObj);
    },
    setTask() {
      this.surveyOptions = [
        {
          text: `${this.translation.create_task.reaction.option} 1`,
          codeString: "1",
          active: true
        },
        {
          text: `${this.translation.create_task.reaction.option} 2`,
          codeString: "2",
          active: true
        },
        {
          text: "",
          codeString: "3",
          active: false
        }
      ];
    },
    init() {
      if (this.task === this.addTaskText) {
        this.setTask();
      }
    }
  },
  created() {
    this.init();
    this.getDescription();
    this.$emit("error", this.surveyError);
  }
};
</script>

<style lang="scss" scoped>
.survey-options {
  display: flex;
  flex-wrap: wrap;
}

.survey-option {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 20px;
  border-radius: 36px;
  border: 1px solid #efefef;
  padding: 5px;
  width: calc(50% - 10px);
  animation: enter 0.3s ease-in-out forwards;
  &:nth-of-type(n) {
    margin-right: 10px;
  }
  div {
    &:nth-of-type(1) {
      display: flex;
      justify-content: center;
      align-items: center;
      border: 2px solid #dbecec;
      border-radius: 13px;
      color: #6b6b6b;
      font-weight: bold;
      margin-right: 5px;
      margin-left: 10px;
      input {
        border-radius: 13px;
        width: 80px;
      }
    }
    &:nth-of-type(2) {
      color: #6b6b6b;
      margin-left: 5px;
      font-size: 1.2rem;
      cursor: pointer;
    }
  }
  input {
    color: #6b6b6b;
    font-size: 0.75rem;
    border: none;
    width: 100%;
    &:focus {
      outline: none;
    }
  }
  &.disabled {
    input {
      color: #b3b3b3;
    }
    div {
      &:nth-of-type(1) {
        color: #b3b3b3;
        border: 2px solid #efefef;
      }
    }
  }
}

@media (max-width: 767.98px) {
  .survey-option {
    width: 100%;
  }
}

.switch {
  color: #6b6b6b;
}

::placeholder {
  color: #b3b3b3;
  opacity: 1;
}

:-ms-input-placeholder {
  color: #b3b3b3;
}

::-ms-input-placeholder {
  color: #b3b3b3;
}

.btn-quickly {
  border-radius: 17px;
  text-transform: uppercase;
  font-weight: bold;
  color: #fff;
  font-size: 0.9rem;
  white-space: nowrap;
  letter-spacing: 0.1rem;
}
</style>
