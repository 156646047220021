<template>
  <div class="chart-wrapper">
    <div>
      <h3>
        {{ translation.replies.charts_tab.number_of_mentions }}
      </h3>
      <ul class="chart">
        <li>
          {{ translation.replies.charts_tab.fewer }}
        </li>
        <li>
          <div :style="{ background: getColor(0) }" class="square"></div>
          <span>
            {{ min }}
          </span>
        </li>
        <li v-if="wordsCount >= 2 && max >= 2">
          <div :style="{ background: getColor(1) }" class="square"></div>
          <span v-if="wordsCount === 2 || max === 2">
            {{ max }}
          </span>
        </li>
        <li v-if="wordsCount >= 3 && max >= 3">
          <div :style="{ background: getColor(2) }" class="square"></div>
          <span v-if="wordsCount === 3 || max === 3">
            {{ max }}
          </span>
        </li>
        <li v-if="wordsCount >= 4 && max >= 4">
          <div :style="{ background: getColor(3) }" class="square"></div>
          <span v-if="wordsCount === 4 || max === 4">
            {{ max }}
          </span>
        </li>
        <li v-if="wordsCount >= 5 && max >= 5">
          <div :style="{ background: getColor(4) }" class="square"></div>
          <span>
            {{ max }}
          </span>
        </li>
        <li>
          {{ translation.replies.charts_tab.more }}
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    min: {
      required: true
    },
    max: {
      required: true
    },
    theme: {
      required: true
    },
    wordsCount: {
      required: true
    }
  },
  methods: {
    getColor(value) {
      return this.theme.hasOneColor
        ? this.theme.colors[0]
        : this.theme.colors[value];
    }
  }
};
</script>

<style lang="scss" scoped>
.chart-wrapper {
  display: flex;
  justify-content: center;
  margin: 60px 10px 30px;
  > div {
    border: 1px solid rgba(0, 0, 0, 0.2);
    padding: 10px;
    h3 {
      font-size: 1rem;
      color: #6b6b6b;
      text-align: center;
      font-weight: bold;
    }
  }
}

.chart {
  display: flex;
  align-items: center;
  list-style: none;
  padding: 10px 20px;
  li {
    display: flex;
    align-items: center;
    flex-direction: column;
    margin-right: 4px;
    position: relative;
    color: #6b6b6b;
    span {
      display: flex;
      flex-direction: column;
      align-items: center;
      color: #6b6b6b;
      font-size: 0.8rem;
      margin-top: 5px;
      position: absolute;
      bottom: -25px;
      &::before {
        content: "";
        display: block;
        width: 1px;
        height: 5px;
        background: rgba(0, 0, 0, 0.25);
        position: relative;
        top: 1px;
      }
    }
    .square {
      border-radius: 2px;
    }

    &:nth-of-type(1) {
      margin-right: 15px;
      font-weight: bold;
    }

    &:nth-of-type(2) {
      .square {
        width: 20px;
        height: 20px;
      }
      span {
        bottom: -30px;
        &::before {
          height: 10px;
        }
      }
    }

    &:nth-of-type(3) {
      .square {
        width: 22.5px;
        height: 22.5px;
      }
    }

    &:nth-of-type(4) {
      .square {
        width: 25px;
        height: 25px;
      }
    }

    &:nth-of-type(5) {
      .square {
        width: 27.5px;
        height: 27.5px;
      }
    }

    &:nth-of-type(6) {
      margin-right: 0;
      .square {
        width: 30px;
        height: 30px;
      }
    }

    &:nth-of-type(7) {
      margin-right: 0;
      margin-left: 15px;
      font-weight: bold;
    }
  }
}
</style>
