<template>
  <div class="task-wrapper d-flex">
    <div class="task">
      <text-statement
        v-if="+task.type_id === taskEnum.textTask.id"
        :task="task"
        :getFormattedTask="getFormattedTask"
      />

      <survey-statement
        v-else-if="+task.type_id === taskEnum.surveyTask.id"
        :task="task"
        :getFormattedTask="getFormattedTask"
      />

      <whitespace-statement
        v-else-if="+task.type_id === taskEnum.whitespace.id"
        :task="task"
        :getFormattedTask="getFormattedTask"
      />

      <matrix-statement
        v-else-if="+task.type_id === taskEnum.matrix.id"
        :task="task"
        :getFormattedTask="getFormattedTask"
      />

      <slider-statement
        v-else-if="+task.type_id === taskEnum.slider.id"
        :task="task"
        :getFormattedTask="getFormattedTask"
      />

      <ranking-statement
        v-else-if="+task.type_id === taskEnum.ranking.id"
        :task="task"
        :getFormattedTask="getFormattedTask"
      />

      <drag-and-drop-statement
        v-else-if="+task.type_id === taskEnum.dragAndDrop.id"
        :task="task"
        :getFormattedTask="getFormattedTask"
      />

      <image-statement
        v-else-if="+task.type_id === taskEnum.imageTask.id"
        :task="task"
        :getFormattedTask="getFormattedTask"
      />

      <video-statement
        v-else-if="+task.type_id === taskEnum.videoTask.id"
        :task="task"
        :getFormattedTask="getFormattedTask"
      />

      <attachment-statement
        v-else-if="+task.type_id === taskEnum.attachment.id"
        :task="task"
        :getFormattedTask="getFormattedTask"
      />

      <reactions-statement
        v-else-if="+task.type_id === taskEnum.reactions.id"
        :task="task"
        :getFormattedTask="getFormattedTask"
      />

      <video-evaluation-statement
        v-else-if="+task.type_id === taskEnum.videoEvaluation.id"
        :task="task"
        :getFormattedTask="getFormattedTask"
      />

      <text-evaluation-statement
        v-else-if="+task.type_id === taskEnum.textEvaluation.id"
        :task="task"
        :getFormattedTask="getFormattedTask"
      />

      <multimedia-statement
        v-else-if="+task.type_id === taskEnum.multimedia.id"
        :task="task"
        :getFormattedTask="getFormattedTask"
      />

      <screen-recording-statement
        v-else-if="+task.type_id === taskEnum.screenRecording.id"
        :task="task"
        :getFormattedTask="getFormattedTask"
      />

      <audio-statement
        v-else-if="+task.type_id === taskEnum.audio.id"
        :task="task"
        :getFormattedTask="getFormattedTask"
      />
    </div>
  </div>
</template>

<script>
import {
  taskEnum,
  imageRegexpGlobal,
  blankRegexpGlobal,
  videoRegexpGlobal,
  regexpClearTags
} from "@/constants";

import textStatement from "@/components/taskdata/textStatement.vue";
import surveyStatement from "@/components/taskdata/surveyStatement.vue";
import whitespaceStatement from "@/components/taskdata/whitespaceStatement.vue";
import matrixStatement from "@/components/taskdata/matrixStatement.vue";
import sliderStatement from "@/components/taskdata/sliderStatement.vue";
import rankingStatement from "@/components/taskdata/rankingStatement.vue";
import dragAndDropStatement from "@/components/taskdata/dragAndDropStatement.vue";
import imageStatement from "@/components/taskdata/imageStatement.vue";
import videoStatement from "@/components/taskdata/videoStatement.vue";
import attachmentStatement from "@/components/taskdata/attachmentStatement.vue";
import reactionsStatement from "@/components/taskdata/reactionsStatement.vue";
import videoEvaluationStatement from "@/components/taskdata/videoEvaluationStatement.vue";
import textEvaluationStatement from "@/components/taskdata/textEvaluationStatement.vue";
import multimediaStatement from "@/components/taskdata/multimediaStatement.vue";
import screenRecordingStatement from "@/components/taskdata/screenRecordingStatement.vue";
import audioStatement from "@/components/taskdata/audioStatement.vue";

export default {
  components: {
    textStatement,
    surveyStatement,
    whitespaceStatement,
    matrixStatement,
    sliderStatement,
    rankingStatement,
    dragAndDropStatement,
    imageStatement,
    videoStatement,
    attachmentStatement,
    reactionsStatement,
    videoEvaluationStatement,
    textEvaluationStatement,
    multimediaStatement,
    screenRecordingStatement,
    audioStatement
  },
  props: {
    task: {
      required: true
    }
  },
  data() {
    return {
      showMore: false,
      regexpClearTags,
      taskEnum,
      blankRegexpGlobal,
      imageRegexpGlobal,
      videoRegexpGlobal
    };
  },
  computed: {
    getFormattedTask() {
      const brTag = "&spantag";
      const imgTag = "&imgtag";
      const closeImgTag = "&/imgtag";
      const iframeTag = "&iframetag";
      const closeIframeTag = "&/iframetag";

      let text = this.task.raw_question
        .replace(this.blankRegexpGlobal, `_____________`)
        .replace(this.imageRegexpGlobal, ` ${imgTag} src="$2" ${closeImgTag} `)
        .replace(
          this.videoRegexpGlobal,
          ` ${iframeTag} src="$2"> ${closeIframeTag} `
        )
        .replace(/<\/p>/g, brTag)
        .replace(this.regexpClearTags, "")
        .replace(/&imgtag/g, "<img ")
        .replace(/&\/imgtag/g, " />")
        .replace(/&iframetag/g, "<iframe")
        .replace(/&\/iframetag/g, " </iframe>")
        .replace(/&spantag/g, "<span></span>");

      return text;
    }
  },
  methods: {}
};
</script>

<style lang="scss" scoped>
.task {
  width: 100%;
  font-size: 0.9rem;
  color: #6b6b6b;
}
</style>
