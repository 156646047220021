<template>
  <div class="container">
    <div class="row py-5">
      <div class="col">
        <a v-if="!$route.query._chat" class="mb-2 back" @click="$router.go(-1)"
          ><b-icon class="mr-1" icon="arrow-left-circle"></b-icon>
          {{ translation.global.return }}
        </a>
        <router-link
          v-else
          class="mb-2 back"
          :to="{ name: 'chat', query: { _participant: participant.id } }"
          ><b-icon class="mr-1" icon="arrow-left-circle"></b-icon>
          {{ translation.global.return }}
        </router-link>
        <div class="card-b">
          <div
            :style="{ background: $store.getters.theme[3] }"
            class="participant-info"
          >
            <div class="px-md-5 p-4" v-if="participant">
              <div
                class="mr-5"
                :style="{
                  'background-color': $store.getters.theme[0],
                  color: $store.getters.theme[3]
                }"
              >
                <img
                  v-if="participant.picture && participant.picture.length"
                  :src="participant.picture"
                  alt="Profile Picture"
                />
                <b-icon
                  v-else
                  class="icon-profile"
                  icon="person-fill"
                  aria-hidden="true"
                ></b-icon>
              </div>
              <div class="mr-md-5">
                <div class="mr-md-5">
                  <h3 class="mb-1">
                    <span
                      v-if="
                        $store.getters.info.user.type ===
                          userTypesText.moderator
                      "
                    >
                      {{
                        namePrivacy(
                          privacy.moderators.participants_identifier_type_id,
                          participant.name,
                          participant.id
                        )
                      }}
                    </span>
                    <span
                      v-else-if="
                        $store.getters.info.user.type === userTypesText.observer
                      "
                    >
                      {{
                        namePrivacy(
                          privacy.observers.participants_identifier_type_id,
                          participant.name,
                          participant.id
                        )
                      }}
                    </span>
                    <span v-else>
                      {{ participant.name }}
                    </span>
                  </h3>
                  <p
                    class="mb-1"
                    v-if="
                      $store.getters.info.user.type === userTypesText.admin ||
                        ($store.getters.info.user.type ===
                          userTypesText.moderator &&
                          !+privacy.moderators.participants_hide_email) ||
                        ($store.getters.info.user.type ===
                          userTypesText.observer &&
                          !+privacy.observers.participants_hide_email)
                    "
                  >
                    <span>{{ translation.manage_participants.email }}: </span
                    >{{ participant.email }}
                  </p>
                  <p><span>ID: </span>{{ participant.id }}</p>
                </div>
                <div class="mr-md-5">
                  <p class="mb-1">
                    <span>
                      {{ translation.manage_participants.created_at }}:
                    </span>
                    {{ participant.created_at }}
                  </p>
                  <p
                    v-if="
                      $store.getters.info.user.type === userTypesText.admin ||
                        ($store.getters.info.user.type ===
                          userTypesText.moderator &&
                          !+privacy.moderators.participants_hide_login_info) ||
                        ($store.getters.info.user.type ===
                          userTypesText.observer &&
                          !+privacy.observers.participants_hide_login_info)
                    "
                  >
                    <span>
                      {{ translation.manage_participants.last_login }}:
                    </span>
                    {{ participant.last_login }}
                  </p>
                </div>
                <router-link
                  v-if="$store.getters.info.user.type === userTypesText.admin"
                  class="edit-participant mt-2"
                  :to="{
                    name: 'manageparticipants',
                    query: {
                      _option: tabOptions.registeredOption,
                      _page: 1,
                      _first: participant.id
                    }
                  }"
                >
                  <b-icon icon="pencil-fill" aria-hidden="true"></b-icon>
                  {{ translation.global.edit }}
                </router-link>
              </div>
            </div>
          </div>
          <div v-if="participant" class="px-md-5 p-3">
            <div
              v-if="
                $store.getters.info.user.type !== userTypesText.observer ||
                  !+privacy.observers.participants_hide_segments
              "
            >
              <h2 class="title" :style="{ color: $store.getters.theme[3] }">
                {{ translation.manage_participants.segments }}:
              </h2>
              <div class="d-flex flex-wrap">
                <div
                  class="segment"
                  v-for="segment in participant.segments"
                  :key="segment.id"
                >
                  {{ segment.name }}
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col">
                <nav
                  :style="{ 'border-color': $store.getters.theme[2] }"
                  class="tabs my-5"
                >
                  <button
                    :style="{
                      background:
                        tab === tabOptions.answers
                          ? $store.getters.theme[2]
                          : $store.getters.theme[1],
                      color:
                        tab === tabOptions.answers
                          ? '#ffffff'
                          : $store.getters.theme[3]
                    }"
                    :class="{ active: tab === tabOptions.answers }"
                    class="btn"
                    @click="tab = tabOptions.answers"
                  >
                    {{ translation.task.answers }}
                  </button>

                  <button
                    v-if="
                      $store.getters.info.user.type !==
                        userTypesText.observer ||
                        !+privacy.observers.participants_hide_personal_progress
                    "
                    :style="{
                      background:
                        tab === tabOptions.personalStats
                          ? $store.getters.theme[2]
                          : $store.getters.theme[1],
                      color:
                        tab === tabOptions.personalStats
                          ? '#ffffff'
                          : $store.getters.theme[3]
                    }"
                    :class="{ active: tab === tabOptions.personalStats }"
                    class="btn"
                    @click="tab = tabOptions.personalStats"
                  >
                    {{ translation.stats_assignments.assignments_statistics }}
                  </button>
                </nav>
              </div>
            </div>
            <transition mode="out-in">
              <div v-if="tab === tabOptions.personalStats">
                <h2 class="title" :style="{ color: $store.getters.theme[3] }">
                  {{ translation.stats_assignments.personal_progress }}
                </h2>
                <personal-stats :participant="participant" />
              </div>

              <div v-if="tab === tabOptions.answers">
                <h2 class="title" :style="{ color: $store.getters.theme[3] }">
                  {{ translation.replies.replies }}:
                </h2>
                <div
                  class="d-flex justify-content-center py-5"
                  v-if="loaderAnswers"
                >
                  <loader />
                </div>
                <div v-else>
                  <answers
                    class="mt-4"
                    :getAnswers="getAnswers"
                    :participants="participants"
                  />
                </div>
                <pagination-query
                  v-if="minPerPage < total"
                  :perPage="perPage"
                  :total="total"
                  :options="[5, 10, 20, 50, 100]"
                  @perPageUpdate="newPerPage => (perPage = newPerPage)"
                />
              </div>
            </transition>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { api } from "@/services.js";
import answers from "@/components/general/answers.vue";
import paginationQuery from "@/components/general/paginationQuery.vue";
import loader from "@/components/general/loader.vue";
import { tabOptions, userTypesText } from "@/constants";
import personalStats from "@/components/participant/personalStats.vue";
import { namePrivacy } from "@/helpers.js";
import { mapState } from "vuex";

export default {
  components: { answers, paginationQuery, loader, personalStats },

  data() {
    return {
      participants: [],
      total: 0,
      minPerPage: 5,
      perPage: 5,
      loaderParticipant: false,
      loaderAnswers: false,
      participant: null,
      tab: tabOptions.answers,
      tabOptions,
      userTypesText
    };
  },

  computed: {
    ...mapState(["privacy"]),

    page() {
      return this.$route.query._page;
    }
  },

  watch: {
    page() {
      this.getAnswers();
    },

    perPage() {
      this.getAnswers();
    }
  },

  methods: {
    namePrivacy,

    getParticipant() {
      this.loaderParticipant = true;

      api
        .get(`participant/${this.$route.query._id}`, {
          headers: {
            Authorization: `Bearer ${this.$store.getters.token}`
          }
        })
        .then(r => {
          if (r.status === 200) {
            this.participant = r.data;
            this.participant.segments = this.participant.segments.filter(
              segment => +segment.is_active
            );
          }
          this.loaderParticipant = false;
        })
        .catch(() => {
          this.loaderParticipant = false;
        });
    },

    getAnswers() {
      this.loaderAnswers = true;
      api
        .get(
          `report/community/${
            this.$store.getters.community.id
          }?participants[]=${this.$route.query._id}&offset=${this.perPage *
            (this.page - 1)}&limit=${this.perPage}&all=true`,
          {
            headers: {
              Authorization: `Bearer ${this.$store.getters.token}`
            }
          }
        )
        .then(r => {
          if (r.status === 200) {
            this.participants = Object.values(r.data.report);
            this.total = r.data.total_answers;
          }
          this.loaderAnswers = false;
        })
        .catch(() => {
          this.loaderAnswers = false;
        });
    }
  },

  created() {
    this.getParticipant();
    this.getAnswers();
  }
};
</script>

<style lang="scss" scoped>
.back {
  color: #6b6b6b;
  cursor: pointer;
}

.title {
  font-weight: bold;
  font-size: 1.2rem;
  margin: 10px;
}

.participant-info {
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  margin-bottom: 60px;
  > div {
    min-height: 200px;
    display: flex;
    align-items: flex-end;
    > div {
      &:nth-of-type(1) {
        position: relative;
        top: 80px;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 160px;
        height: 160px;
        border-radius: 50%;
        overflow: hidden;
        border: 2px solid #c4c4c4;
        img {
          object-fit: cover;
          height: 160px;
          width: 160px;
        }
      }
      &:nth-of-type(2) {
        color: #fff;
        display: flex;
        flex-wrap: wrap;
        align-items: flex-end;
        div {
          margin-right: 20px;
        }
        h3 {
          font-weight: bold;
          font-size: 1.1rem;
        }
        p {
          margin-bottom: 0;
        }
        span {
          font-weight: bold;
        }
      }
    }
  }
}

@media (max-width: 991.98px) {
  .participant-info {
    margin-bottom: 0px;
    > div {
      flex-wrap: wrap;
      > div {
        &:nth-of-type(1) {
          width: 80px;
          height: 80px;
          top: unset;
          margin-bottom: 20px;
          img {
            width: 80px;
            height: 80px;
          }
        }
        &:nth-of-type(2) {
          word-wrap: break-word;
          word-break: break-word;
          font-weight: 0.9rem;
        }
      }
    }
  }
}

.segment {
  position: relative;
  background: #f3f3f3;
  border-radius: 5px;
  color: #999999;
  padding: 5px 10px;
  margin: 10px;
  min-width: 80px;
  text-align: center;
}

.edit-participant {
  font-weight: bold;
  color: #fff;
}

.tabs {
  border-bottom: 2px solid;
  button {
    margin-right: 10px;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;
    background: #f5f5f5;
    font-weight: bold;
    transition: all 0.3s ease;
    min-width: 120px;
    &:hover {
      transform: scale(1.01);
    }
  }
}

@media (max-width: 567.98px) {
  .tabs {
    display: flex;
    border-bottom: 2px solid;
    margin-top: 30px;
    button {
      min-width: 100px;
      font-size: 0.8rem;
    }
  }
}
</style>
