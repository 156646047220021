<template>
  <header v-if="!isPreview">
    <header-desktop class="d-lg-block d-none" />
    <header-mobile class="d-lg-none d-block" />
  </header>
</template>

<script>
import headerDesktop from "@/components/header/headerDesktop.vue";
import headerMobile from "@/components/header/headerMobile.vue";

export default {
  components: {
    headerDesktop,
    headerMobile
  },
  computed: {
    isPreview() {
      return this.$route.query._preview;
    }
  }
};
</script>

<style lang="scss" scoped></style>
