<template>
  <div>
    <div>
      <div class="drops-matrix pt-4 px-2">
        <div class="d-flex pr-3">
          <span> {{ translation.create_task.matrix.columns }}:</span>
          <dropdown
            :width="60"
            dropId="columns"
            :options="options"
            :value="dataCol"
            @update="data => (dataCol = data)"
          />
        </div>
        <div class="d-flex pr-3">
          <span> {{ translation.create_task.matrix.rows }}:</span>
          <dropdown
            :width="60"
            dropId="rows"
            :options="options"
            :value="dataRow"
            @update="data => (dataRow = data)"
          />
        </div>
        <div class="d-flex">
          <span>{{ translation.create_task.matrix.type }}:</span>
          <dropdown
            :width="220"
            dropId="matrixType"
            :options="matrixTypeOptions"
            :value="dataMatrixType"
            @update="data => (dataMatrixType = data)"
          />
        </div>
        <div
          :class="{ active: matrixError }"
          class="error-box bg-danger p-2 ml-3"
        >
          <b-icon class="mr-1" icon="exclamation-triangle-fill"></b-icon>
          {{ matrixError }}
        </div>
      </div>
      <div class="matrix d-flex justify-content-center py-4">
        <div class="w-100">
          <div class="cols">
            <p>
              {{ translation.create_task.matrix.columns }}
            </p>
            <div v-for="(col, index) in matrix.cols" :key="index">
              <b-form-input
                class="code"
                v-model="col.codeString"
                maxlength="10"
                :state="
                  !isCodeValid(col.codeString) &&
                    !hasDuplicate(matrix.cols, 'codeString') &&
                    !!col.codeString.length &&
                    col.codeString !== '0'
                "
                v-b-tooltip.hover="{ variant: 'secondary' }"
                :title="translation.create_task.error.code_validation"
              ></b-form-input>
              <input
                :class="{
                  ['text-danger']: redText
                }"
                type="text"
                v-model="col.value"
                maxlength="254"
                :placeholder="translation.create_task.matrix.column"
              />
            </div>
          </div>
          <div class="rows">
            <p>
              {{ translation.create_task.matrix.rows }}
            </p>
            <div v-for="(row, index) in matrix.rows" :key="index">
              <b-form-input
                class="code"
                v-model="row.codeString"
                maxlength="10"
                :state="
                  !isCodeValid(row.codeString) &&
                    !hasDuplicate(matrix.rows, 'codeString') &&
                    !!row.codeString.length &&
                    row.codeString !== '0'
                "
                v-b-tooltip.hover.right.v-secondary="
                  translation.create_task.error.code_validation
                "
              ></b-form-input>
              <input
                :class="{
                  ['text-danger']: redText
                }"
                type="text"
                v-model="row.value"
                maxlength="254"
                :placeholder="translation.create_task.matrix.row"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import dropdown from "@/components/general/dropdown.vue";
import { taskEnum, addTaskText, regexpCodeString } from "@/constants";

export default {
  props: {
    taskText: {
      required: true
    },
    task: {
      required: true
    }
  },
  components: {
    dropdown
  },
  data() {
    return {
      dataCol: {
        value: 2,
        text: 2
      },
      dataRow: {
        value: 2,
        text: 2
      },
      dataMatrixType: {
        value: "",
        text: ""
      },
      matrix: {
        cols: [],
        rows: []
      },
      taskEnum,
      addTaskText,
      regexpCodeString
    };
  },
  computed: {
    taskObj() {
      return {
        statement: this.taskText,
        columns: this.matrix.cols.map((col, index) => ({
          index: index,
          value: col.value,
          code_string: col.codeString
        })),
        rows: this.matrix.rows.map((row, index) => ({
          index: index,
          value: row.value,
          code_string: row.codeString
        })),
        matrix_type: this.dataMatrixType.value
      };
    },
    redText() {
      let resp = false;
      const values = this.matrix.cols.concat(this.matrix.rows);
      if (
        this.hasDuplicate(values, "value") ||
        this.hasDuplicate(this.matrix.cols, "codeString") ||
        this.hasDuplicate(this.matrix.rows, "codeString") ||
        this.hasEmpty(this.matrix.cols) ||
        this.hasEmpty(this.matrix.rows) ||
        this.hasZeros(this.matrix.cols) ||
        this.hasZeros(this.matrix.rows)
      )
        resp = true;
      return resp;
    },
    matrixError() {
      let message = null;
      const values = this.matrix.cols.concat(this.matrix.rows);

      if (
        this.hasEmpty(this.matrix.cols, "value") ||
        this.hasEmpty(this.matrix.rows, "value")
      )
        message = this.translation.create_task.matrix.error.empty_field;
      else if (this.hasDuplicate(values, "value"))
        message = this.translation.create_task.matrix.error.duplicated_fields;
      else if (
        this.hasDuplicate(this.matrix.rows, "codeString") ||
        this.hasDuplicate(this.matrix.cols, "codeString")
      )
        message = this.translation.create_task.matrix.error.duplicated_codes;
      else if (
        this.hasZeros(this.matrix.cols) ||
        this.hasZeros(this.matrix.rows)
      )
        message = this.translation.create_task.error.the_field_cannot;
      else if (
        this.hasEmpty(this.matrix.cols, "codeString") ||
        this.hasEmpty(this.matrix.rows, "codeString")
      )
        message = this.translation.create_task.error.error_empty_code_field;
      else if (
        this.matrix.cols.filter(col => col.codeString === "0").length ||
        this.matrix.rows.filter(row => row.codeString === "0").length
      )
        message = this.translation.create_task.error.number_zero;
      else if (
        this.matrix.cols.filter(col => this.isCodeValid(col.codeString))
          .length ||
        this.matrix.rows.filter(row => this.isCodeValid(row.codeString)).length
      )
        message = this.translation.create_task.error.code_validation;

      return message;
    },
    options() {
      const array = [];
      for (let i = 2; i <= 20; i++) {
        array.push({
          value: i,
          text: i
        });
      }
      return array;
    },
    matrixTypeOptions() {
      return [
        {
          value: "UR",
          text: this.translation.create_task.matrix.single_selection_row
        },
        {
          value: "UC",
          text: this.translation.create_task.matrix.single_selection_column
        },
        {
          value: "MC",
          text: this.translation.create_task.matrix.multiple_selection
        }
      ];
    }
  },
  watch: {
    taskObj() {
      this.$emit("update", this.taskObj);
    },
    matrixError() {
      this.$emit("error", this.matrixError);
    },
    dataCol() {
      let range;
      if (this.dataCol.value > this.matrix.cols.length) {
        range = this.dataCol.value - this.matrix.cols.length;
        const count = this.matrix.cols.length;
        for (let i = 0; i < range; i++) {
          this.matrix.cols.push({
            value: "",
            codeString: `${count + i + 1}`
          });
        }
      } else {
        range = this.matrix.cols.length - this.dataCol.value;
        this.matrix.cols.splice(
          this.dataCol.value,
          this.matrix.cols.length - 1
        );
      }
    },
    dataRow() {
      let range;
      if (this.dataRow.value > this.matrix.rows.length) {
        range = this.dataRow.value - this.matrix.rows.length;
        const count = this.matrix.rows.length;
        for (let i = 0; i < range; i++) {
          this.matrix.rows.push({
            value: "",
            codeString: `${count + i + 1}`
          });
        }
      } else {
        range = this.matrix.rows.length - this.dataRow.value;
        this.matrix.rows.splice(
          this.dataRow.value,
          this.matrix.rows.length - 1
        );
      }
    }
  },
  methods: {
    hasZeros(array) {
      return array.some(option => option.value === "0");
    },
    hasEmpty(array) {
      return array.some(option => !option.value.length);
    },
    isCodeValid(codeString) {
      return codeString.match(this.regexpCodeString);
    },
    hasDuplicate(array, key) {
      const valuesSoFar = [];
      for (let i = 0; i < array.length; ++i) {
        const value = array[i][key].trim();
        if (valuesSoFar.indexOf(value) !== -1) {
          return true;
        }
        valuesSoFar.push(value);
      }
      return false;
    },
    getDescription() {
      if (
        this.task !== this.addTaskText &&
        +this.task.type_id === this.taskEnum.matrix.id
      ) {
        this.matrix.cols = this.task.question_object.columns.map(
          (col, index) => ({
            value: col.value,
            codeString: col.code_string ? col.code_string : `${index + 1}`
          })
        );
        this.matrix.rows = this.task.question_object.rows.map((row, index) => ({
          value: row.value,
          codeString: row.code_string ? row.code_string : `${index + 1}`
        }));

        this.dataCol = {
          value: this.matrix.cols.length,
          text: this.matrix.cols.length
        };
        this.dataRow = {
          value: this.matrix.rows.length,
          text: this.matrix.rows.length
        };

        this.dataMatrixType = this.matrixTypeOptions.filter(
          option => option.value === this.task.question_object.matrix_type
        )[0];
      } else {
        this.setTask();
      }
      this.$emit("update", this.taskObj);
    },
    setTask() {
      this.dataCol = {
        value: 2,
        text: 2
      };
      this.dataRow = {
        value: 2,
        text: 2
      };
      this.dataMatrixType = {
        value: "UR",
        text: this.translation.create_task.matrix.single_selection_row
      };
      this.matrix = {
        cols: [
          {
            value: `${this.translation.create_task.matrix.column} 1`,
            codeString: "1"
          },
          {
            value: `${this.translation.create_task.matrix.column} 2`,
            codeString: "2"
          }
        ],
        rows: [
          {
            value: `${this.translation.create_task.matrix.row} 1`,
            codeString: "1"
          },
          {
            value: `${this.translation.create_task.matrix.row} 2`,
            codeString: "2"
          }
        ]
      };
    },
    init() {
      if (this.task === this.addTaskText) {
        this.setTask();
      }
    }
  },

  created() {
    this.init();
    this.getDescription();
  }
};
</script>

<style lang="scss" scoped>
.drops-matrix {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  height: 61px;
  span {
    color: #6b6b6b;
  }
}
.matrix {
  overflow: auto;
  &::-webkit-scrollbar {
    height: 8px;
    overflow: hidden;
  }
  &::-webkit-scrollbar-track {
    background: rgba(0, 0, 0, 0);
    border-radius: 10px;
  }
  &::-webkit-scrollbar-thumb {
    background: #ccc;
    border-radius: 10px;
    height: 10px;
  }
  &::-webkit-scrollbar-thumb:hover {
    background: #aaa;
  }
  .cols,
  .rows {
    p {
      display: none;
    }
    input:nth-of-type(2) {
      border: 1px solid #c4c4c4;
      border-radius: 2px;
      text-align: center;
      color: #999999;
      font-size: 0.8rem;
      width: 150px;
      transition: all 0.3s ease;
      animation: show 0.3s linear;
      margin-right: 5px;
      &:focus,
      &:hover {
        outline: none;
        border: 1px solid #6b6b6b;
        box-shadow: none;
      }

      &::placeholder {
        color: #999999;
        opacity: 0.6;
      }

      &:-ms-input-placeholder {
        color: #999999;
        opacity: 0.6;
      }

      &::-ms-input-placeholder {
        color: #999999;
        opacity: 0.6;
      }
    }
    > div {
      display: flex;
      margin-right: 2px;
      margin-bottom: 2px;
    }
  }
  .cols {
    display: flex;
    padding-left: 250px;
  }
}

@media (max-width: 991.98px) {
  .matrix {
    .cols,
    .rows {
      flex-direction: column;
      padding-left: 0;
      p {
        display: block;
        color: #6b6b6b;
        margin-bottom: 10px;
      }
      input:nth-of-type(2) {
        width: 100%;
        margin: 10px 0;
        padding: 5px;
      }

      div {
        &:nth-of-type(1) {
          flex-direction: column;
          padding-left: 0;
        }
        &:nth-of-type(2) {
          flex-direction: column;
        }
      }
    }
  }
}

.code {
  width: 95px;
  box-shadow: 0px 0px 37px -16px #c4c4c4;
  border-radius: 14px;
  border: 3px solid #ebf2f2;
  color: #6b6b6b;
  font-weight: bold;
  margin-right: 5px;
  &:focus {
    outline: none;
    box-shadow: 0px 0px 37px -16px #c4c4c4;
    border: 3px solid #ebf2f2;
  }
}
</style>
