<template>
  <div>
    <div v-if="!loader && feedList.length && selectedFeed" class="wrapper">
      <imageCropper
        :img="imgMessage"
        :show="showCropper"
        :width="300"
        :height="200"
        @updateImg="img => handleUpdateImg(img)"
        @trash="trash"
      />

      <h2 class="m-0">
        {{ translation.add_message.add_custom_texts }}
      </h2>

      <div class="content">
        <div class="create-message">
          <div class="message-list">
            <button
              v-for="feed in feedList"
              :key="feed.id"
              class="btn"
              @click="selectMessage(feed.id)"
              :style="{
                background:
                  selectedMessage === feed.id
                    ? $store.getters.theme[2]
                    : '#f6f8f9',
                color: selectedMessage === feed.id ? '#ffffff' : '#999999'
              }"
            >
              {{ `${translation.add_message.message} ${feed.index + 1}` }}
            </button>
          </div>

          <div class="inputs-container">
            <label class="label" for="title">
              {{ translation.add_message.title }}:

              <div>
                <b-form-input
                  type="text"
                  id="title"
                  v-model="selectedFeed.title"
                  :maxlength="40"
                />

                <div
                  class="counter-container"
                  :class="{ limit: selectedFeed.title.length === 40 }"
                >
                  {{ selectedFeed.title.length }}/40
                </div>
              </div>
            </label>

            <label class="label" for="supporting-text">
              {{ translation.add_message.supporting_text }}:

              <div>
                <textarea
                  class="form-control"
                  id="supporting-text"
                  rows="3"
                  v-model="selectedFeed.text"
                  :maxlength="100"
                ></textarea>

                <div
                  class="counter-container"
                  :class="{ limit: selectedFeed.text.length === 100 }"
                >
                  {{ selectedFeed.text.length }}/100
                </div>
              </div>
            </label>

            <div class="upload-container">
              <span>{{ translation.add_message.image }}:</span>

              <label
                for="img-message"
                class="
                    img-feed-upload
                    d-flex
                    m-0
                    align-items-center
                    justify-content-center
                  "
              >
                <input
                  ref="inputImgMessage"
                  id="img-message"
                  class="d-none"
                  type="file"
                  accept="image/*"
                  @change="uploadLogo"
                />

                <p>
                  {{ uploadText }}
                </p>
              </label>
            </div>
          </div>

          <b-form-checkbox
            class="stop-display"
            v-model="selectedFeed.show"
            @change="handleStopDisplay"
          >
            {{ translation.add_message.stop_display }}
          </b-form-checkbox>
        </div>

        <div class="preview">
          <guide
            :feedPreviewData.sync="feedList"
            :isPreview="true"
            :selectedMessage="selectedMessage"
            :loadingImg="loadingImg"
          />
        </div>
      </div>

      <button
        class="save-btn btn d-flex justify-content-center"
        :style="{ background: $store.getters.theme[2] }"
        @click="handleSave"
      >
        <loader2 v-if="uploadOrCreateLoader" />

        <span v-else>{{ translation.add_message.save }}</span>
      </button>
    </div>

    <div v-else class="d-flex align-items-center justify-content-center mt-5">
      <loader />
    </div>
  </div>
</template>

<script>
import { api } from "@/services.js";
import imageCropper from "@/components/general/imageCropper.vue";
import { regexpImageName } from "@/constants";
import loader from "@/components/general/loader.vue";
import loader2 from "@/components/general/loader2.vue";
import { v4 as uuidv4 } from "uuid";
import guide from "@/components/feed/guide.vue";
import lambda from "@/lambda";
import jwt_decode from "jwt-decode";

export default {
  name: "AddMessage",
  components: { imageCropper, loader, loader2, guide },

  data() {
    return {
      selectedMessage: "",
      uploadText: "Upload",
      fileImgMessage: {},
      imgMessage: "",
      imgMessageName: "",
      showCropper: false,
      imgMessagePreCropped: "",
      imgMessageCropped: "",
      loadingImg: false,
      regexpImageName,
      stopDisplay: false,
      title: "",
      supportingText: "",
      loader: false,
      feedList: [],
      selectedFeed: null,
      uploadOrCreateLoader: false,
      communityId: this.$store.getters.community.id,
      thereIsFeedCreated: false
    };
  },

  computed: {
    defaultFeed() {
      let defaultFeed = [];

      for (let i = 0; i <= 4; i++) {
        defaultFeed.push({
          id: uuidv4(),
          index: i,
          title: "",
          text: "",
          img: "",
          show: false
        });
      }

      return defaultFeed;
    }
  },

  watch: {
    selectedMessage() {
      if (!this.feedList?.length) return;

      this.selectedFeed = this.feedList.find(
        feed => feed.id === this.selectedMessage
      );

      this.resetImageUpload();
    }
  },

  methods: {
    handleStopDisplay() {
      this.feedList = [...this.feedList];
    },

    selectMessage(value) {
      this.selectedMessage = value;
    },

    async uploadLogo() {
      const file = document.querySelector(`#img-message`).files[0];
      this.fileImgMessage = file;

      if (file) {
        const toBase64 = file =>
          new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => resolve(reader.result);
            reader.onerror = error => reject(error);
          });

        this.imgMessage = await toBase64(file);
        this.imgMessageName = file.name;
        this.showCropper = true;
        this.uploadText = file.name;
      }
    },

    handleUpdateImg(img) {
      this.showCropper = false;
      this.imgMessage = img;
      this.imgMessagePreCropped = img;
      this.$refs.inputImgMessage.value = "";

      this.applyImgMessage();
    },

    applyImgMessage() {
      if (!this.loadingImg && this.imgMessageName !== "") {
        this.loadingImg = true;

        this.selectedFeed.img = "";

        let arr = this.imgMessagePreCropped.split(",");
        let mime = arr[0].match(/:(.*?);/)[1];
        let bstr = atob(arr[1]);
        let n = bstr.length;
        let u8arr = new Uint8Array(n);

        while (n--) {
          u8arr[n] = bstr.charCodeAt(n);
        }

        const imgName = `${uuidv4()}-${this.fileImgMessage.name.replace(
          this.regexpImageName,
          ""
        )}`;

        this.imgMessageCropped = new File([u8arr], imgName, {
          type: mime
        });

        const propName = uuidv4();

        lambda
          .getSignedURL(
            this.fileImgMessage,
            `Assets/Community/${this.$store.getters.community.client.id}`,
            imgName
          )
          .then(url => {
            api
              .put(url, this.imgMessageCropped, {
                headers: {
                  "Content-Type": this.fileImgMessage.type
                }
              })
              .then(r => {
                api
                  .put(
                    `/community/${this.$store.getters.community.id}`,
                    {
                      [propName]: r.config.url.split("?")[0]
                    },
                    {
                      headers: {
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${this.$store.getters.token}`
                      }
                    }
                  )
                  .then(() => {
                    api
                      .put(
                        `token/refresh`,
                        {},
                        {
                          headers: {
                            "Content-Type": "application/json",
                            Authorization: `Bearer ${this.$store.getters.token}`
                          }
                        }
                      )
                      .then(respRefresh => {
                        if (respRefresh.status === 200) {
                          const token = respRefresh.data.token;

                          try {
                            const info = jwt_decode(token);
                            this.$store.dispatch("login", { token, info });
                          } catch (error) {
                            this.$store.dispatch("logOut");
                            this.$toast.error(
                              this.translation.errors_global
                                .something_went_wrong
                            );
                          }

                          this.selectedFeed.img = r.config.url.split("?")[0];
                        }
                        this.loadingImg = false;
                      })
                      .catch(() => {
                        this.loadingImg = false;
                      });
                  })
                  .catch(() => {
                    this.$toast.error(
                      this.$toast.error(
                        this.translation.errors_global.something_went_wrong
                      )
                    );
                    this.loadingImg = false;
                  });
              })
              .catch(() => {
                this.loadingImg = false;
              });
          })
          .catch(() => {
            this.$toast.error(
              this.translation.errors_global.something_went_wrong
            );
            this.loadingImg = false;
          });
      }
    },

    async fetchFeed() {
      try {
        this.loader = true;

        const response = await api.get(`/community/${this.communityId}/feed`, {
          headers: {
            Authorization: `Bearer ${this.$store.getters.token}`
          }
        });

        const { data, status } = response;

        if (status === 200) {
          if (data?.length) {
            this.feedList = response.data.map(item => ({
              ...item,
              id: uuidv4(),
              show: item.show ? false : true
            }));

            this.thereIsFeedCreated = true;
          } else {
            this.feedList = this.defaultFeed;
          }

          this.selectedFeed = this.feedList[0];
        }

        this.selectMessage(this.feedList[0].id);
      } catch (error) {
        const { status } = error.response;

        if (status === 404) {
          this.feedList = this.defaultFeed;

          this.selectedFeed = this.feedList[0];

          this.selectMessage(this.feedList[0].id);

          return;
        }

        this.$toast.error(this.translation.errors_global.something_went_wrong);
      } finally {
        this.loader = false;
      }
    },

    handleSave() {
      if (this.thereIsFeedCreated) {
        this.updateFeed();
      } else {
        this.createFeed();
      }
    },

    async createFeed() {
      if (this.validateFeedList()) {
        this.$toast.error(
          this.translation.add_message.validate_feed_list_message
        );

        return;
      }

      try {
        this.uploadOrCreateLoader = true;

        const response = await api.post(
          "/community/feed",
          {
            communityId: this.communityId,
            pages: this.feedList.map(feed => ({
              ...feed,
              show: feed.show ? 0 : 1
            }))
          },
          {
            headers: {
              Authorization: `Bearer ${this.$store.getters.token}`
            }
          }
        );

        const { status } = response;

        if (status === 200) {
          this.$toast.success(
            this.translation.add_message.messages_created_success
          );
        }
      } catch (error) {
        this.$toast.error(this.translation.errors_global.something_went_wrong);
      } finally {
        this.uploadOrCreateLoader = false;
      }
    },

    validateFeedList() {
      const validation = this.feedList.find(feed => {
        if (feed.show) {
          return undefined;
        }

        return !feed.title.length || !feed.text.length || !feed.img.length;
      });

      return validation;
    },

    async updateFeed() {
      if (this.validateFeedList()) {
        this.$toast.error(
          this.translation.add_message.validate_feed_list_message
        );

        return;
      }

      try {
        this.uploadOrCreateLoader = true;

        const response = await api.put(
          `/community/${this.communityId}/feed`,
          {
            pages: this.feedList.map(feed => ({
              ...feed,
              show: feed.show ? 0 : 1
            }))
          },
          {
            headers: {
              Authorization: `Bearer ${this.$store.getters.token}`
            }
          }
        );

        const { status } = response;

        if (status === 200) {
          this.$toast.success(
            this.translation.add_message.messages_updated_success
          );
        }
      } catch (error) {
        this.$toast.error(this.translation.errors_global.something_went_wrong);
      } finally {
        this.uploadOrCreateLoader = false;
      }
    },

    trash() {
      this.resetImageUpload();
    },

    resetImageUpload() {
      this.showCropper = false;
      this.uploadText = "Upload";
      this.imgMessageName = "";

      if (this.$refs?.inputImgMessage?.value) {
        this.$refs.inputImgMessage.value = "";
      }
    }
  },

  created() {
    this.fetchFeed();
  }
};
</script>

<style lang="scss" scoped>
.wrapper {
  display: flex;
  flex-direction: column;
  gap: 20px;

  margin-top: 35px;

  h2 {
    font-size: 1rem;
    color: #424242;
    font-weight: 700;
  }
}

.content {
  display: grid;
  grid-template-columns: 1fr 280px;
  gap: 30px;

  @media (max-width: 1200px) {
    grid-template-columns: 1fr;
    gap: 50px;

    .preview {
      width: 290px;

      margin: 0 auto;
    }
  }

  @media (max-width: 425px) {
    .preview {
      width: 280px;
    }
  }
}

.create-message {
  display: flex;
  flex-direction: column;
  gap: 40px;
}

.message-list {
  display: flex;
  align-content: center;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 13px;

  button {
    display: flex;
    align-items: center;
    justify-content: center;

    background: #f6f8f9;

    min-width: 127.3px;
    max-width: 127.3px;

    height: 30px;

    padding: 5px 20px;

    color: #999999;
    font-weight: bold;
    text-align: center;
    line-height: 0;

    border: none;
    border-radius: 50px;

    transition: background-color 0.2s;
  }

  @media (max-width: 991px) {
    justify-content: center;
  }

  @media (max-width: 415px) {
    flex-direction: column;
  }
}

.inputs-container {
  display: flex;
  flex-direction: column;
  gap: 17px;
}

.label {
  display: flex;
  align-items: center;
  gap: 15px;

  color: #424242;
  font-size: 16px;
  font-weight: 700;

  box-sizing: border-box;

  div {
    display: flex;
    flex-direction: column;

    position: relative;

    max-width: 311px;
    width: 100%;

    input {
      height: 34px;
      width: 100%;

      border: 1px solid #dcdcdc;
      border-radius: 5px;

      padding: 5px 10px;
    }

    textarea {
      width: 100%;

      resize: none;

      &::-webkit-scrollbar {
        width: 8px;
      }
      &::-webkit-scrollbar-track {
        background: #ebf2f2;
        border-radius: 10px;
      }
      &::-webkit-scrollbar-thumb {
        background: #c8dada;
        border-radius: 10px;
        height: 40px;
      }
      &::-webkit-scrollbar-thumb:hover {
        background: #b9c9c9;
      }
    }

    @media (max-width: 390px) {
      max-width: 220px;
    }
  }

  @media (max-width: 420px) {
    flex-direction: column;
    align-items: flex-start;
    gap: 5px;
  }
}

.counter-container {
  position: absolute !important;
  bottom: -17px;
  right: 5px;

  text-align: right;
  font-size: 0.6rem;
  margin-top: 2px;
  font-weight: bold;
  color: #999999;
  &.limit {
    color: #666666;
  }
}

.upload-container {
  display: flex;
  align-items: center;
  gap: 15px;

  span {
    color: #424242;
    font-size: 16px;
    font-weight: 700;
  }

  @media (max-width: 420px) {
    flex-direction: column;
    align-items: flex-start;
    gap: 5px;
  }
}

.img-feed-upload {
  gap: 0px !important;

  padding: 4px 9px;

  width: 107px;
  height: 30px;

  cursor: pointer;

  border: 1px solid #828181;
  border-radius: 5px;

  background: none;

  p {
    margin: 0;

    color: #7e7e7e;
    font-size: 16px;
    /* line-height: 20px; */
    font-weight: bold;
    text-align: center;

    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    line-clamp: 1;
    -webkit-box-orient: vertical;
    word-break: break-all;
  }
}

.stop-display {
  display: flex;
  align-items: center;

  color: #999999;
  font-size: 14px;
}

.save-btn {
  width: 165px;

  margin-top: 15px;

  text-align: center;
  text-transform: uppercase;
  color: #fff;
  font-weight: bold;
  letter-spacing: 0.16em;
  border-radius: 17px;
  border: none;
  padding: 5px 20px;
  align-self: flex-end;
}
</style>
