import { displayNameIds } from "./constants";

export function outsideClick(element, events, callback) {
  const html = document.documentElement;
  const outside = "data-outside";
  if (!element.hasAttribute(outside)) {
    events.forEach(userEvent => {
      setTimeout(() => {
        html.addEventListener(userEvent, () => {
          if (!element.contains(event.target)) {
            element.removeAttribute(outside);
            events.forEach(userEvent => {
              html.removeEventListener(userEvent, this.handleOutsideClick);
            });
            callback();
          }
        });
      });
    });
    element.setAttribute(outside, "");
  }
}

export function encodeImage(text) {
  const regexp = /(?:(<img.*?src="))(.*?)((?:".*?\/>))/g;
  const regexpIndex = /(:\)\*:-!image\.@#\$\|\|%{(.*?)}-\[)(index)(]!@#\$\|\|%-:\*:\()/;

  let newText = text.replace(
    regexp,
    `$1:)*:-!image.@#$||%{$2}-[index]!@#$||%-:*:($3`
  );
  let count = 0;

  while (regexpIndex.exec(newText)) {
    if (regexpIndex.exec(newText)[0].match(/tmp\/images/)) {
      newText = newText.replace(regexpIndex, `$1${count}$4`);
      count++;
    } else newText = newText.replace(regexpIndex, `$2`);
  }

  return newText;
}

export function encodeVideo(text, keepIframeClosingTag = false) {
  const regexp = /(?:(<iframe.*?src="))(.*?)((?:".*?>))((?:<\/iframe>))?/g;
  const regexpIndex = /(:\)\*:-!video\.@#\$\|\|%{(.*?)}-\[)(index)(]!@#\$\|\|%-:\*:\()/;

  let newText = text.replace(
    regexp,
    keepIframeClosingTag
      ? `$1:)*:-!video.@#$||%{$2}-[index]!@#$||%-:*:($3$4`
      : `$1:)*:-!video.@#$||%{$2}-[index]!@#$||%-:*:($3`
  );

  let count = 0;

  while (regexpIndex.exec(newText)) {
    if (regexpIndex.exec(newText)[0].match(/tmp\/video/)) {
      newText = newText.replace(regexpIndex, `$1${count}$4`);
      count++;
    } else newText = newText.replace(regexpIndex, `$2`);
  }

  return newText;
}

export function encodeBlank(text) {
  const regexp = /<input.*?>/;

  let newText = text;
  let count = 0;

  while (regexp.exec(newText)) {
    newText = newText.replace(
      regexp,
      `{0327L-S2!@#$)(%blank%-[${count}]!@#$)(}`
    );
    count++;
  }
  return newText;
}

export function formatBytes(bytes, decimals = 2) {
  if (bytes === 0) return "0 Bytes";

  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
}

export function downloadFile(url) {
  const a = document.createElement("a");
  a.href = url;
  a.download = url.split("/").pop();
  a.setAttribute("target", "_blank");
  document.body.appendChild(a);
  a.click();
  document.body.removeChild(a);
}

export function namePrivacy(id, name, uniqueId) {
  if (displayNameIds.fullName === id) {
    return name;
  }

  if (displayNameIds.firstNameAndLastNameInitial === id) {
    const split = name.split(" ");
    return split.length > 1 ? `${split[0]} ${split[1][0]}` : name;
  }

  if (displayNameIds.firstName === id) {
    const split = name.split(" ");
    return split.length > 1 ? split[0] : name;
  }

  if (displayNameIds.nameAndId === id) {
    const split = name.split(" ");
    return split.length > 1 ? `${split[0]} ${uniqueId}` : `${name} ${uniqueId}`;
  }

  if (displayNameIds.idOnly === id) {
    return uniqueId;
  }
}
