<template>
  <div class="pt-5">
    <h2 class="title my-1 my-md-0" :style="{ color: $store.getters.theme[3] }">
      {{ translation.add_participant.invite_participants }}
    </h2>
    <label class="pt-4 pb-2">
      {{ translation.add_participant.generate_link_to_all }}:
    </label>
    <div class="d-flex flex-wrap">
      <div class="col-md-6 pr-md-2 px-0 my-2">
        <div v-if="!loaderSegments" class="segments-box-warpper">
          <div class="search">
            <b-form-input
              class="mb-2"
              size="sm"
              :placeholder="translation.add_participant.search_segment"
              v-model="searchSegments"
            ></b-form-input>
            <b-icon class="mx-2" icon="search" aria-hidden="true"></b-icon>
          </div>
          <div class="segments-box">
            <div class="scroll p-1">
              <b-form-group v-if="options.length" v-slot="{ ariaDescribedby }">
                <b-form-checkbox v-model="allSegments">
                  {{ translation.add_participant.all }}
                </b-form-checkbox>
                <b-form-checkbox-group
                  class="checkbox-input d-flex flex-column"
                  v-model="selectedSegments"
                  :options="optionsTemp"
                  value-field="value"
                  text-field="text"
                  disabled-field="notEnabled"
                  :aria-describedby="ariaDescribedby"
                ></b-form-checkbox-group>
              </b-form-group>
            </div>
          </div>
        </div>
        <div v-else class="d-flex justify-content-center py-5">
          <loader />
        </div>
      </div>
      <div class="col-md-6 d-flex align-items-end pl-md-2 px-0 my-2">
        <div class="selected-segments">
          <div class="scroll p-1">
            <div class="segments-wrapper">
              <div
                class="segment"
                v-for="(segment, index) in selectedSegments"
                :key="segment.id"
              >
                <button
                  class="btn btn-remove hover"
                  @click="selectedSegments.splice(index, 1)"
                >
                  <b-icon icon="x" aria-hidden="true"></b-icon>
                </button>
                {{ segment.name }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div>
      <label class="pt-4 pb-2">
        {{ translation.add_participant.number_of_people }}:
      </label>
      <div class="participants-amount">
        <b-form-input
          id="participantsAmount"
          v-model="participantsAmount"
          type="number"
          min="1"
        ></b-form-input>
        <label for="participantsAmount">
          {{ translation.add_participant.people_will_be_able }}
        </label>
      </div>
    </div>
    <div class="d-flex justify-content-end py-5">
      <button class="btn btn-generate-link hover" @click="generateLink">
        <loader2 v-if="loaderGenerateLink" color="#6b6b6b" />
        <span v-else>
          {{ translation.add_participant.generate_link }}
        </span>
      </button>
    </div>
    <links-table
      v-if="!loaderSegments"
      :triggerGetLinks="getLinks"
      :segments="segments"
    />
  </div>
</template>

<script>
import { api } from "@/services.js";
import loader from "@/components/general/loader.vue";
import loader2 from "@/components/general/loader2.vue";
import linksTable from "@/components/addparticipant/linksTable.vue";

export default {
  components: {
    loader,
    loader2,
    linksTable
  },
  data() {
    return {
      segments: [],
      loaderSegments: false,
      selectedSegments: [],
      allSegments: false,
      searchSegments: "",
      options: [],
      optionsTemp: [],
      participantsAmount: 1,
      loaderGenerateLink: false,
      getLinks: false
    };
  },
  watch: {
    selectedSegments() {
      const isAllChecked = this.selectedSegments.length === this.options.length;
      if (!isAllChecked) {
        this.allSegments = false;
      }
    },
    allSegments() {
      const isAllChecked = this.selectedSegments.length === this.options.length;
      if (this.allSegments) {
        this.selectedSegments = this.options.map(option => option.value);
      } else if (isAllChecked) {
        this.selectedSegments = [];
      }
    },
    searchSegments() {
      if (this.searchSegments.length) {
        this.optionsTemp = this.options.filter(item => {
          return this.searchSegments
            .toLowerCase()
            .split(" ")
            .every(v => item.text.toLowerCase().includes(v));
        });
      } else {
        this.optionsTemp = this.options;
      }
    }
  },
  methods: {
    generateLink() {
      if (!this.loaderSegments) {
        if (this.participantsAmount && +this.participantsAmount > 0) {
          this.loaderGenerateLink = true;
          api
            .post(
              `admin/manage/participant/create/register-link`,
              {
                segments: this.selectedSegments.map(segment => +segment.id),
                community_id: this.$store.getters.community.id,
                register_limit: +this.participantsAmount
              },
              {
                headers: {
                  "Content-Type": "application/json",
                  Authorization: `Bearer ${this.$store.getters.token}`
                }
              }
            )
            .then(r => {
              if (r.status === 201) {
                this.$toast.success(
                  this.translation.add_participant.success.link_generated
                );
                this.getLinks = !this.getLinks;
                this.participantsAmount = 1;
              }
              this.loaderGenerateLink = false;
            })
            .catch(() => {
              this.$toast.error(
                this.translation.errors_global.something_went_wrong
              );
              this.loaderGenerateLink = false;
            });
        } else {
          this.$toast.error(
            this.translation.add_participant.errors.the_number_of_people
          );
        }
      }
    },
    getSegments() {
      this.loaderSegments = true;
      api
        .get(`community/${this.$store.getters.community.id}/segments`, {
          headers: {
            Authorization: `Bearer ${this.$store.getters.token}`
          }
        })
        .then(r => {
          if (r.status === 200) {
            this.segments = r.data.filter(segment => +segment.is_active);
            this.options = this.segments.map(segment => ({
              value: {
                id: segment.id,
                name: segment.name
              },
              text: segment.name,
              disabled: false
            }));
            this.optionsTemp = [...this.options];
          }
          this.loaderSegments = false;
        })
        .catch(() => {
          this.loaderSegments = false;
        });
    }
  },
  created() {
    this.getSegments();
  }
};
</script>

<style lang="scss" scoped>
.title {
  font-weight: bold;
  font-size: 1.3rem;
}

.segments-box-warpper {
  input {
    border-radius: 27px;
    border: 1px solid #e6e6e6;
  }
  .search {
    position: relative;
    color: #6b6b6b;
    input {
      padding-right: 40px;
    }
    svg {
      position: absolute;
      right: 8px;
      top: 8px;
      color: #6b6b6b;
    }
  }
}

.segments-box,
.selected-segments {
  width: 100%;
  border: 1px solid #e6e6e6;
  border-radius: 10px;
  padding: 10px;
  font-size: 0.8rem;
}

.scroll {
  height: 200px;
  overflow-y: scroll;
  color: #424151;
  &::-webkit-scrollbar {
    width: 8px;
  }
  &::-webkit-scrollbar-track {
    background: #ebf2f2;
    border-radius: 10px;
  }
  &::-webkit-scrollbar-thumb {
    background: #c8dada;
    border-radius: 10px;
    height: 40px;
  }
  &::-webkit-scrollbar-thumb:hover {
    background: #b9c9c9;
  }
}

.segments-wrapper {
  display: flex;
  flex-wrap: wrap;
}

.segment {
  position: relative;
  background: #f3f3f3;
  border-radius: 5px;
  color: #999999;
  padding: 5px 10px;
  margin: 10px;
  min-width: 80px;
  text-align: center;
  .btn-remove {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    width: 14px;
    height: 14px;
    top: -6px;
    right: -6px;
    background: #999999;
    color: #ffffff;
    border-radius: 50%;
    padding: 0;
    font-size: 0.8rem;
  }
}

label {
  font-weight: bold;
  color: #6b6b6b;
}

.participants-amount {
  display: flex;
  align-items: center;
  input {
    width: 60px;
    margin-right: 15px;
  }
  label {
    margin: 0;
    font-weight: normal;
  }
}

.btn-generate-link {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 200px;
  background: #ebf2f2;
  border-radius: 25px;
  text-transform: uppercase;
  color: #6b6b6b;
  font-weight: bold;
}
</style>
