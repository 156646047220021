<template>
  <div class="container">
    <div class="row py-5">
      <div class="col">
        <div class="card-b p-5">
          <h2 class="title mb-4" :style="{ color: $store.getters.theme[3] }">
            {{ translation.terms_and_policies.privacy_policy }}
          </h2>
          <div v-if="+$store.getters.community.fk_privacy_policy_id !== noText">
            <div class="text" v-if="!loaderText" v-html="text"></div>
            <div v-if="loaderText" class="d-flex justify-content-center">
              <loader />
            </div>
          </div>
          <div v-else>
            <p v-html="termsAndPoliciesDefault"></p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { api } from "@/services.js";
import loader from "@/components/general/loader.vue";

export default {
  components: { loader },
  data() {
    return {
      loaderText: false,
      text: "",
      noText: 1
    };
  },

  methods: {
    getText() {
      this.loaderText = true;
      api
        .get(
          `community/${this.$store.getters.community.id}/privacy-policy-terms`,
          {
            headers: {
              Authorization: `Bearer ${this.$store.getters.token}`
            }
          }
        )
        .then(r => {
          if (r.status === 200) {
            this.text = r.data.privacy_policy;
          } else {
            this.text = "";
          }
          this.loaderText = false;
        })
        .catch(() => {
          this.loaderText = false;
        });
    },
    init() {
      this.getText();
    }
  },
  created() {
    this.init();
  }
};
</script>

<style lang="scss" scoped>
.title {
  font-weight: bold;
  font-size: 1.3rem;
}

.text {
  color: #6b6b6b;
  overflow-wrap: break-word;
}
</style>
