<template>
  <div class="chart">
    <div v-if="data.length">
      <config
        :id="id"
        :activityId="chartData.activityId"
        :taskId="chartData.taskId"
        :optionsCount="getOptionsCount()"
      />
      <p class="answered">
        {{ translation.replies.charts_tab.answered }}:
        {{ chartData.answersCount }}
      </p>
      <div v-if="config && !loaderConfig">
        <div v-if="config.type === chartTypes.stackedBar">
          <stacked-bar-chart
            :colors="config.theme.colors"
            :hasOneColor="config.theme.hasOneColor"
            :data="data"
            :getPercent="getPercent"
            :isPercent="config.isPercent"
            :statement="chartData.taskObject.question_object.statement"
          />
          <div class="table-wordcloud">
            <div class="table-wordclou-scroll">
              <div class="table-wordcloud-header">
                <div>
                  {{ translation.replies.charts_tab.option }}
                </div>
                <div v-for="(data, dIndex) in data[0].data" :key="dIndex">
                  {{ data.label }}
                </div>
              </div>
              <div class="table-wordcloud-body">
                <div v-for="(dataset, dsIndex) in data" :key="dsIndex">
                  <div>
                    {{ dataset.question.length ? dataset.question : "N/A" }}
                  </div>
                  <div v-for="(data, dIndex) in dataset.data" :key="dIndex">
                    <p v-if="!config.isPercent">
                      {{ data.value }}
                    </p>
                    <p v-else>
                      {{ getPercent(data, dataset.data) | filterPercent }}%
                    </p>
                  </div>
                </div>
              </div>
              <div class="footer-wordcloud">
                <div>
                  {{ translation.replies.charts_tab.total_responses }}:
                  {{
                    data[0].data.reduce((acc, curr) => (acc += curr.value), 0)
                  }}
                </div>
                <div
                  v-for="(data, dIndex) in data[0].data.length - 1"
                  :key="dIndex"
                ></div>
              </div>
            </div>
          </div>
          <div class="d-flex justify-content-end mt-4">
            <button
              :class="{ hover: !xlsxLoader }"
              class="btn-download"
              @click="exportStackedBarXLSX()"
            >
              <div
                v-if="xlsxLoader"
                class="d-flex justify-content-center align-items-center"
              >
                <loader2 color="#6b6b6b" class="mr-1" />
                {{ translation.replies.charts_tab.download_table }}
              </div>
              <div v-else>
                <b-icon icon="download" aria-hidden="true"></b-icon>
                {{ translation.replies.charts_tab.download_table }}
              </div>
            </button>
          </div>
        </div>
        <div v-else>
          <div v-for="(chart, index) in data" :key="index">
            <div class="charts-types">
              <line-chart
                v-if="config.type === chartTypes.line"
                :colors="config.theme.colors"
                :hasOneColor="config.theme.hasOneColor"
                :data="chart.data"
                :getPercent="getPercent"
                :isPercent="config.isPercent"
                :question="chart.question"
                :statement="chartData.taskObject.question_object.statement"
              />
              <bar-chart
                v-if="config.type === chartTypes.bar"
                :colors="config.theme.colors"
                :hasOneColor="config.theme.hasOneColor"
                :data="chart.data"
                :getPercent="getPercent"
                :isPercent="config.isPercent"
                :question="chart.question"
                :statement="chartData.taskObject.question_object.statement"
              />
              <bar-chart-horizontal
                v-if="config.type === chartTypes.barHorizontal"
                :colors="config.theme.colors"
                :hasOneColor="config.theme.hasOneColor"
                :data="chart.data"
                :getPercent="getPercent"
                :isPercent="config.isPercent"
                :question="chart.question"
                :statement="chartData.taskObject.question_object.statement"
              />

              <pie-chart
                v-if="config.type === chartTypes.pie"
                :colors="config.theme.colors"
                :hasOneColor="config.theme.hasOneColor"
                :data="chart.data"
                :getPercent="getPercent"
                :isPercent="config.isPercent"
                :question="chart.question"
                :statement="chartData.taskObject.question_object.statement"
              />
            </div>
            <div>
              <div class="table-wrapper">
                <div class="header">
                  <div>
                    {{ translation.replies.charts_tab.option }}
                  </div>
                  <div>
                    {{ translation.replies.charts_tab.answers }}
                  </div>
                </div>
                <div class="table-scroll">
                  <div
                    v-for="(item, iIndex) in chart.data"
                    :key="iIndex"
                    class="item"
                  >
                    <div>{{ item.label }}</div>
                    <div>
                      <p>{{ getPercent(item, chart.data) | filterPercent }}%</p>
                      <p>{{ item.value }}</p>
                    </div>
                  </div>
                </div>
              </div>
              <div class="footer">
                <div>
                  {{ translation.replies.charts_tab.total_responses }}:
                  {{ getTotal(chart.data) }}
                </div>
              </div>
            </div>
            <div class="d-flex justify-content-end mt-4">
              <button
                :class="{ hover: !xlsxLoader }"
                class="btn-download"
                @click="exportXLSX(chart)"
              >
                <div
                  v-if="xlsxLoader"
                  class="d-flex justify-content-center align-items-center"
                >
                  <loader2 color="#6b6b6b" class="mr-1" />
                  {{ translation.replies.charts_tab.download_table }}
                </div>
                <div v-else>
                  <b-icon icon="download" aria-hidden="true"></b-icon>
                  {{ translation.replies.charts_tab.download_table }}
                </div>
              </button>
            </div>
          </div>
        </div>
      </div>
      <div v-else class="d-flex justify-content-center py-5">
        <loader />
      </div>
    </div>
    <div v-else>
      No Chart
    </div>
  </div>
</template>

<script>
import lineChart from "@/components/charts/lineChart.vue";
import barChart from "@/components/charts/barChart.vue";
import barChartHorizontal from "@/components/charts/barChartHorizontal.vue";
import stackedBarChart from "@/components/charts/stackedBarChart.vue";
import pieChart from "@/components/charts/pieChart.vue";
import config from "@/components/charts/config.vue";
import loader from "@/components/general/loader.vue";
import loader2 from "@/components/general/loader2.vue";
import { mapState } from "vuex";
import { chartTypes } from "@/chartsConfig";
import * as XLSX from "xlsx/xlsx.mjs";

export default {
  props: {
    chartData: {
      required: true
    },
    id: {
      required: true
    }
  },
  components: {
    lineChart,
    barChart,
    stackedBarChart,
    pieChart,
    barChartHorizontal,
    config,
    loader,
    loader2
  },
  filters: {
    filterPercent(value) {
      return value.toFixed(2);
    }
  },
  data() {
    return {
      chartTypes,
      data: [],
      xlsxLoader: false,
      loaderConfig: false
    };
  },
  computed: {
    ...mapState(["configChart"]),
    config() {
      if (!this.configChart) return false;
      return this.configChart[this.id];
    }
  },
  watch: {
    config() {
      this.loaderConfig = true;
      setTimeout(() => {
        this.loaderConfig = false;
      }, "1");
    }
  },
  methods: {
    getOptionsCount() {
      const marks = this.chartData.taskObject.question_object.marks;
      const options = this.chartData.taskObject.question_object.options;
      const columns = this.chartData.taskObject.question_object.columns;
      const points = this.chartData.taskObject.question_object.points;
      const groups = this.chartData.taskObject.question_object.groups;
      const cards = this.chartData.taskObject.question_object.cards;

      if (marks) return marks.length;
      else if (options) return options.length;
      else if (columns) return columns.length;
      else if (points) return points.length;
      else if (groups) return groups.length;
      else if (cards) return cards.length;
      else {
        return 5;
      }
    },
    getTotal(data) {
      return data.reduce((acc, curr) => (acc += curr.value), 0);
    },
    getPercent({ value }, data) {
      const total = (value * 100) / this.getTotal(data);
      return total;
    },
    async exportStackedBarXLSX() {
      this.xlsxLoader = true;
      const json = this.data.map(dataset => {
        const exportJson = {
          option: dataset.question
        };

        const firstDataset = this.data[0];
        const labelsCount = firstDataset.data.length;

        for (let index = 0; index < labelsCount; index++) {
          exportJson[dataset.data[index].label] = this.config.isPercent
            ? `${this.getPercent(dataset.data[index], dataset.data).toFixed(
                2
              )}%`
            : dataset.data[index].value;
        }

        return exportJson;
      });

      const dataWS = XLSX.utils.json_to_sheet(json);
      const wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, dataWS, "worksheet");
      XLSX.writeFile(wb, `data.xlsx`);
      this.xlsxLoader = false;
    },
    async exportXLSX(chart) {
      this.xlsxLoader = true;
      const json = chart.data.map(item => ({
        option: item.label,
        percentage: `${this.getPercent(item, chart.data).toFixed(2)}%`,
        answers: item.value
      }));

      const dataWS = XLSX.utils.json_to_sheet(json);
      const wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, dataWS, "worksheet");
      XLSX.writeFile(wb, `data.xlsx`);
      this.xlsxLoader = false;
    }
  },
  created() {
    const isMultiple =
      this.chartData.answersValues.length &&
      this.chartData.answersValues[0].question;

    if (isMultiple) {
      this.data = this.chartData.answersValues;
    } else {
      this.data = [
        {
          question: "",
          data: this.chartData.answersValues
        }
      ];
    }
  }
};
</script>

<style lang="scss" scoped>
.answered {
  color: #6b6b6b;
}

.charts-types {
  margin-top: 30px;
}

.table-wrapper {
  margin-top: 60px;
  margin-bottom: 20px;
}

.header {
  display: flex;
  margin-bottom: 10px;
  > div {
    width: 20%;
    background: #e6e6e6;
    padding: 5px 10px;
    font-weight: bold;
    color: #6b6b6b;
    &:nth-of-type(1) {
      width: 80%;
      margin-right: 5px;
    }
  }
}

@media (max-width: 767.98px) {
  .header {
    > div {
      width: 40%;

      &:nth-of-type(1) {
        width: 60%;
      }
    }
  }
}

.footer {
  background: #e6e6e6;
  padding: 5px 10px;
  font-weight: bold;
  color: #6b6b6b;
  margin-top: 10px;
}

.table-scroll {
  max-height: 400px;
  overflow-x: auto;
  overflow-y: scroll;

  &::-webkit-scrollbar {
    width: 8px;
    height: 8px;
  }
  &::-webkit-scrollbar-track {
    background: rgba(0, 0, 0, 0.05);
    border-radius: 10px;
  }
  &::-webkit-scrollbar-thumb {
    background: rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    height: 10px;
  }
  &::-webkit-scrollbar-thumb:hover {
    background: rgba(0, 0, 0, 0.1);
  }
}

.item {
  display: flex;
  &:last-of-type {
    > div {
      border-bottom: none;
    }
  }
  > div {
    width: calc(20% - 13px);
    padding: 5px 10px;
    color: #6b6b6b;
    border-bottom: 1px solid #e6e6e6;

    &:nth-of-type(1) {
      width: calc(80% + 8px);
    }
    &:nth-of-type(2) {
      display: flex;
      justify-content: space-between;
      p {
        margin-bottom: 0;
        padding: 0 5px;
      }
    }
  }
}

@media (max-width: 767.98px) {
  .item {
    > div {
      width: calc(40% - 13px);
      &:nth-of-type(1) {
        width: calc(60% + 8px);
      }
    }
  }
}

.btn-download {
  display: flex;
  align-items: center;
  background: none;
  border: none;
  font-size: 1.2rem;
  font-weight: bold;
  color: #6b6b6b;

  &:focus {
    outline: none;
  }
  svg {
    margin-right: 5px;
  }
}

.table-wordcloud {
  margin-top: 30px;
  margin-bottom: 20px;
}

.table-wordclou-scroll {
  overflow: auto;
  max-height: 400px;
  padding-bottom: 10px;
  position: relative;

  &::-webkit-scrollbar {
    width: 8px;
    height: 8px;
  }
  &::-webkit-scrollbar-track {
    background: rgba(0, 0, 0, 0.05);
    border-radius: 10px;
  }
  &::-webkit-scrollbar-thumb {
    background: rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    height: 10px;
  }
  &::-webkit-scrollbar-thumb:hover {
    background: rgba(0, 0, 0, 0.1);
  }
}

.table-wordcloud-header {
  display: flex;
  div {
    flex: 1;
    min-width: 130px;
    background: #e6e6e6;
    padding: 5px 10px;
    font-weight: bold;
    color: #6b6b6b;
    margin-right: 10px;
  }
}

.table-wordcloud-body {
  > div {
    display: flex;
    border-bottom: 1px solid #e6e6e6;
    &:last-of-type {
      border-bottom: none;
    }
    > div {
      display: flex;
      flex: 1;
      min-width: 140px;
      padding: 5px 10px;
      color: #6b6b6b;
      p {
        margin-bottom: 0;
      }
    }
  }
}

.footer-wordcloud {
  width: calc(100% + 10px);
  display: flex;
  margin-top: 10px;
  background: #e6e6e6;
  div {
    flex: 1;
    min-width: 130px;
    padding: 5px 10px;
    font-weight: bold;
    color: #6b6b6b;
    margin-right: 10px;
    background: #e6e6e6;
    &:nth-of-type(1) {
      min-width: 270px;
    }
  }
}
</style>
