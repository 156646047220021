<template>
  <div class="permissions">
    <div class="observers">
      <h2 :style="{ color: $store.getters.theme[3] }">
        {{ translation.privacy_and_permissions.observers }}
      </h2>

      <h3>
        {{ translation.privacy_and_permissions.pages }}
      </h3>

      <p>
        {{ translation.privacy_and_permissions.by_default_observers }}
      </p>

      <div class="checkbox-wrapper">
        <b-form-checkbox v-model="observersHideStatsActivities">
          {{
            translation.privacy_and_permissions
              .do_not_display_assignments_statistics
          }}
        </b-form-checkbox>
      </div>

      <div class="checkbox-wrapper">
        <b-form-checkbox v-model="observersHideReportReplies">
          {{
            translation.privacy_and_permissions
              .do_no_display_replies_in_the_reports_menu
          }}
        </b-form-checkbox>
      </div>

      <div class="checkbox-wrapper">
        <b-form-checkbox v-model="observersHideReportMedia">
          {{
            translation.privacy_and_permissions
              .do_no_display_media_in_the_reports_menu
          }}
        </b-form-checkbox>
      </div>

      <div class="checkbox-wrapper">
        <b-form-checkbox v-model="observersHideReportCharts">
          {{
            translation.privacy_and_permissions
              .do_no_display_charts_in_the_reports_menu
          }}
        </b-form-checkbox>
      </div>

      <div class="checkbox-wrapper">
        <b-form-checkbox v-model="observersHideManageParticipants">
          {{
            translation.privacy_and_permissions
              .do_no_display_manage_participants
          }}
        </b-form-checkbox>
      </div>
    </div>

    <div class="moderadots">
      <h2 :style="{ color: $store.getters.theme[3] }">
        {{ translation.privacy_and_permissions.moderators }}
      </h2>

      <h3>
        {{ translation.privacy_and_permissions.pages }}
      </h3>

      <p>
        {{ translation.privacy_and_permissions.by_default_moderators }}
      </p>

      <div class="checkbox-wrapper">
        <b-form-checkbox v-model="moderatorsHideCustomization">
          {{ translation.privacy_and_permissions.do_not_display_customization }}
        </b-form-checkbox>
      </div>

      <div class="checkbox-wrapper">
        <b-form-checkbox v-model="moderatorsHideTermsAndPolicies">
          {{
            translation.privacy_and_permissions
              .do_not_display_terms_ans_policies
          }}
        </b-form-checkbox>
      </div>

      <div class="checkbox-wrapper">
        <b-form-checkbox v-model="moderatorsHideCreateActivity">
          {{
            translation.privacy_and_permissions
              .do_not_display_create_assignments
          }}
        </b-form-checkbox>
      </div>
    </div>

    <div class="d-flex align-items-end justify-content-end">
      <button
        @click="save"
        class="btn save-btn px-5 d-flex justify-content-center hover"
        :style="{ background: $store.getters.theme[2] }"
      >
        <loader2 v-if="loaderSave" color="#ffffff" />
        <p v-if="!loaderSave" class="m-0">
          {{ translation.global.save }}
        </p>
      </button>
    </div>
  </div>
</template>

<script>
import loader2 from "@/components/general/loader2.vue";
import { api } from "@/services.js";
import { mapMutations, mapState } from "vuex";

export default {
  components: {
    loader2
  },

  data() {
    return {
      loaderSave: false,
      observersHideStatsActivities: false,
      observersHideReportReplies: false,
      observersHideReportMedia: false,
      observersHideReportCharts: false,
      observersHideManageParticipants: false,
      moderatorsHideCustomization: false,
      moderatorsHideTermsAndPolicies: false,
      moderatorsHideCreateActivity: false
    };
  },

  computed: {
    ...mapState(["permissions"])
  },

  watch: {
    permissions() {
      this.setFields();
    }
  },

  methods: {
    ...mapMutations(["UPDATE_PERMISSIONS"]),

    save() {
      this.loaderSave = true;

      const permissions = {
        moderators: {
          hide_customization: this.moderatorsHideCustomization ? "1" : "0",
          hide_terms_and_policies: this.moderatorsHideTermsAndPolicies
            ? "1"
            : "0",
          hide_create_activity: this.moderatorsHideCreateActivity ? "1" : "0"
        },
        observers: {
          hide_activities_stats: this.observersHideStatsActivities ? "1" : "0",
          hide_report_replies: this.observersHideReportReplies ? "1" : "0",
          hide_report_media: this.observersHideReportMedia ? "1" : "0",
          hide_report_charts: this.observersHideReportCharts ? "1" : "0",
          hide_manage_participants: this.observersHideManageParticipants
            ? "1"
            : "0"
        }
      };

      api
        .put(
          `community/${this.$store.getters.community.id}/permission`,
          permissions,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${this.$store.getters.token}`
            }
          }
        )
        .then(resp => {
          if (resp.status === 200) {
            this.$toast.success(this.translation.success_global.changes_saved);

            this.UPDATE_PERMISSIONS(permissions);
          }
          this.loaderSave = false;
        })
        .catch(() => {
          this.loaderSave = false;
          this.$toast.error(
            this.translation.errors_global.something_went_wrong
          );
        });
    },

    setFields() {
      this.moderatorsHideCustomization = !!+this.permissions.moderators
        .hide_customization;
      this.moderatorsHideTermsAndPolicies = !!+this.permissions.moderators
        .hide_terms_and_policies;
      this.moderatorsHideCreateActivity = !!+this.permissions.moderators
        .hide_create_activity;
      this.observersHideStatsActivities = !!+this.permissions.observers
        .hide_activities_stats;
      this.observersHideReportReplies = !!+this.permissions.observers
        .hide_report_replies;
      this.observersHideReportMedia = !!+this.permissions.observers
        .hide_report_media;
      this.observersHideReportCharts = !!+this.permissions.observers
        .hide_report_charts;
      this.observersHideManageParticipants = !!+this.permissions.observers
        .hide_manage_participants;
    }
  },

  mounted() {
    this.setFields();
  }
};
</script>

<style lang="scss" scoped>
.permissions {
  margin-top: 60px;
  h2 {
    font-weight: bold;
    font-size: 1.3rem;
    margin-bottom: 30px;
  }

  h3 {
    font-weight: bold;
    color: #6b6b6b;
    font-size: 1.1rem;
    margin-bottom: 10px;
  }

  p {
    color: #6b6b6b;
  }
}

.observers,
.moderators {
  margin-bottom: 30px;
}

.checkbox-wrapper {
  color: #6b6b6b;
  margin-bottom: 10px;
}

.save-btn {
  text-align: center;
  text-transform: uppercase;

  font-weight: bold;
  letter-spacing: 0.16em;
  border-radius: 17px;
  border: none;
  padding: 5px 20px;
  align-self: flex-end;
  margin-top: 3rem;
  min-width: 162px;
  p {
    color: #fff;
  }
}
</style>
