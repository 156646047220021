<template>
  <div class="dropdown-wrapper">
    <div class="outside" v-if="opened"></div>
    <div class="dropdown" :id="dropId">
      <a
        :style="{
          width: `${width}px`,
          background: background,
          color: color
        }"
        class="btn drop-btn px-2 py-0 ml-2"
        @click="handleDrop"
      >
        <p>
          {{ data.text }}
        </p>
        <b-icon
          class="m-0 pl-2"
          icon="caret-down-fill"
          aria-hidden="true"
        ></b-icon
      ></a>
      <div class="drop-item-wrapper">
        <div class="drop-item">
          <a
            v-for="(option, index) in options"
            :key="index"
            :class="{ active: data === option }"
            :style="{
              width: `${width}px`
            }"
            @click="mark(option)"
            >{{ option.text }}</a
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { outsideClick } from "@/helpers.js";

export default {
  props: {
    options: {
      required: true
    },
    dropId: {
      required: true
    },
    value: {
      required: true
    },
    width: {
      required: true
    },
    background: {
      default: "#efefef"
    },
    color: {
      default: "##6b6b6b"
    }
  },
  data() {
    return {
      data: {},
      opened: false,
      outsideClick
    };
  },
  watch: {
    data() {
      this.$emit("update", this.data);
    },
    value() {
      this.data = this.value;
    }
  },
  methods: {
    mark(option) {
      this.data = option;
      const dropItem = document.querySelector(`#${this.dropId}`);
      dropItem.children[1].classList.remove("active");
      this.opened = false;
    },
    handleDrop() {
      const dropItem = document.querySelector(`#${this.dropId}`);
      dropItem.children[1].classList.toggle("active");
      this.opened = !this.opened;
      this.outsideClick(dropItem, ["click"], () => {
        dropItem.children[1].classList.remove("active");
        this.opened = false;
      });
    }
  },
  created() {
    this.data = this.value;
  }
};
</script>

<style lang="scss" scoped>
.outside {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  z-index: 2;
}

.dropdown {
  &:hover {
    .drop-item-wrapper {
      display: block;
    }
  }
}

.drop-btn {
  display: flex;
  align-items: center;
  justify-content: space-between;
  text-transform: uppercase;
  font-size: 0.7rem;
  border-radius: 17px;
  cursor: pointer;
  height: 100%;
  z-index: 3;
  position: relative;
  p {
    margin-bottom: 0;
  }
  svg {
    font-size: 1.8rem !important ;
  }
}

.drop-item-wrapper {
  position: absolute;
  display: none;
  padding-top: 10px;
  z-index: 9000;
  top: 30px;
  right: 0;

  &.active {
    display: block;
  }
}

.drop-item {
  animation: grow 400ms ease-in-out forwards;
  transform-origin: top center;
  background: #fff;
  border-radius: 4px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
  max-height: 150px;
  overflow: auto;
  overflow-x: hidden;
  &::-webkit-scrollbar {
    width: 8px;
  }
  &::-webkit-scrollbar-track {
    background: rgba(0, 0, 0, 0);
    border-radius: 10px;
  }
  &::-webkit-scrollbar-thumb {
    background: #e6e6e6;
    border-radius: 10px;
    height: 40px;
  }
  &::-webkit-scrollbar-thumb:hover {
    background: #ffffff;
  }
  a {
    background: #fff;
    display: block;
    color: #495057;
    padding: 2px 10px;
    text-decoration: none;
    cursor: pointer;
    border-bottom: 1px solid rgba(0, 0, 0, 0.075);
    &:hover,
    &.active {
      color: #666;
      background: rgba(0, 0, 0, 0.1);
    }
  }
}
</style>
