<template>
  <div class="privacy">
    <div class="participants">
      <h2 :style="{ color: $store.getters.theme[3] }">
        {{ translation.privacy_and_permissions.participants }}
      </h2>

      <div class="item">
        <h3>
          {{ translation.privacy_and_permissions.identity_of_participants }}
        </h3>
        <p>
          {{
            translation.privacy_and_permissions
              .how_participants_are_displayed_to_each_other
          }}
        </p>
        <div class="dropdown-wrapper" v-if="participantsParticipantId">
          <dropdown
            :width="270"
            dropId="participants-participant-id"
            :options="idOptions"
            :value="participantsParticipantId"
            @update="data => (participantsParticipantId = data)"
          />
        </div>
      </div>

      <div class="item">
        <h3>
          {{ translation.privacy_and_permissions.identity_of_admins }}
        </h3>
        <p>
          {{ translation.privacy_and_permissions.how_admins_are_displayed }}
        </p>
        <div class="dropdown-wrapper" v-if="participantsAdminId">
          <dropdown
            :width="270"
            dropId="participants-admin-id"
            :options="idOptions"
            :value="participantsAdminId"
            @update="data => (participantsAdminId = data)"
          />
        </div>
      </div>
    </div>

    <div class="moderators">
      <h2 :style="{ color: $store.getters.theme[3] }">
        {{ translation.privacy_and_permissions.moderators }}
      </h2>

      <div class="item">
        <h3>
          {{ translation.privacy_and_permissions.identity_of_participants }}
        </h3>
        <p>
          {{
            translation.privacy_and_permissions
              .admins_and_moderators_can_view_all_details
          }}
        </p>
        <div class="dropdown-wrapper" v-if="moderatorsParticipantId">
          <dropdown
            :width="270"
            dropId="moderators-participant-id"
            :options="idOptions"
            :value="moderatorsParticipantId"
            @update="data => (moderatorsParticipantId = data)"
          />
        </div>
      </div>

      <div class="checkbox-wrapper">
        <b-form-checkbox v-model="moderatorsHideEmail">
          {{
            translation.privacy_and_permissions.do_not_display_participant_email
          }}
        </b-form-checkbox>
      </div>

      <div class="checkbox-wrapper">
        <b-form-checkbox v-model="moderatorsLastLoginInfo">
          {{
            translation.privacy_and_permissions
              .do_not_display_participant_last_login_info
          }}
        </b-form-checkbox>
      </div>
    </div>

    <div class="observers">
      <h2 :style="{ color: $store.getters.theme[3] }">
        {{ translation.privacy_and_permissions.observers }}
      </h2>

      <div class="item">
        <h3>
          {{ translation.privacy_and_permissions.identity_of_participants }}
        </h3>
        <p>
          {{
            translation.privacy_and_permissions
              .how_participants_are_displayed_to_clients
          }}
        </p>
        <div class="dropdown-wrapper" v-if="observersParticipantId">
          <dropdown
            :width="270"
            dropId="observers-participant-id"
            :options="idOptions"
            :value="observersParticipantId"
            @update="data => (observersParticipantId = data)"
          />
        </div>
      </div>

      <div class="checkbox-wrapper">
        <b-form-checkbox v-model="observersHideEmail">
          {{
            translation.privacy_and_permissions.do_not_display_participant_email
          }}
        </b-form-checkbox>
      </div>

      <div class="checkbox-wrapper">
        <b-form-checkbox v-model="observersLastLoginInfo">
          {{
            translation.privacy_and_permissions
              .do_not_display_participant_last_login_info
          }}
        </b-form-checkbox>
      </div>

      <div class="checkbox-wrapper">
        <b-form-checkbox v-model="observersHideSegments">
          {{
            translation.privacy_and_permissions
              .do_not_display_participant_segments
          }}
        </b-form-checkbox>
      </div>

      <div class="checkbox-wrapper">
        <b-form-checkbox v-model="observersHidePersonalProgress">
          {{
            translation.privacy_and_permissions
              .do_not_display_participant_personal_progress
          }}
        </b-form-checkbox>
      </div>

      <div class="checkbox-wrapper">
        <b-form-checkbox v-model="observersHideOperatorsComments">
          {{
            translation.privacy_and_permissions
              .do_not_display_comments_on_replies_made_by_operators
          }}
        </b-form-checkbox>
      </div>
    </div>

    <div class="d-flex align-items-end justify-content-end">
      <button
        @click="save"
        class="btn save-btn px-5 d-flex justify-content-center hover"
        :style="{ background: $store.getters.theme[2] }"
      >
        <loader2 v-if="loaderSave" color="#ffffff" />
        <p v-if="!loaderSave" class="m-0">
          {{ translation.global.save }}
        </p>
      </button>
    </div>
  </div>
</template>

<script>
import dropdown from "@/components/general/dropdown.vue";
import loader2 from "@/components/general/loader2.vue";
import { api } from "@/services.js";
import { mapMutations, mapState } from "vuex";
import { displayNameIds } from "@/constants";

export default {
  components: {
    dropdown,
    loader2
  },

  data() {
    return {
      loaderSave: false,
      participantsParticipantId: null,
      participantsAdminId: null,
      moderatorsParticipantId: null,
      moderatorsHideEmail: false,
      moderatorsLastLoginInfo: false,
      observersParticipantId: null,
      observersHideEmail: false,
      observersLastLoginInfo: false,
      observersHideSegments: false,
      observersHidePersonalProgress: false,
      observersHideOperatorsComments: false,
      idOptions: null,
      displayNameIds
    };
  },

  computed: {
    ...mapState(["privacy"])
  },

  watch: {
    privacy() {
      this.setFields();
    }
  },

  methods: {
    ...mapMutations(["UPDATE_PRIVACY"]),

    save() {
      this.loaderSave = true;

      const privacy = {
        participants: {
          participants_identifier_type_id: this.participantsParticipantId.value,
          admins_identifier_type_id: this.participantsAdminId.value
        },
        moderators: {
          participants_identifier_type_id: this.moderatorsParticipantId.value,
          participants_hide_email: this.moderatorsHideEmail ? "1" : "0",
          participants_hide_login_info: this.moderatorsLastLoginInfo ? "1" : "0"
        },
        observers: {
          participants_identifier_type_id: this.observersParticipantId.value,
          participants_hide_email: this.observersHideEmail ? "1" : "0",
          participants_hide_login_info: this.observersLastLoginInfo ? "1" : "0",
          participants_hide_segments: this.observersHideSegments ? "1" : "0",
          participants_hide_personal_progress: this
            .observersHidePersonalProgress
            ? "1"
            : "0",
          participants_hide_operators_comment: this
            .observersHideOperatorsComments
            ? "1"
            : "0"
        }
      };

      api
        .put(`community/${this.$store.getters.community.id}/privacy`, privacy, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${this.$store.getters.token}`
          }
        })
        .then(resp => {
          if (resp.status === 200) {
            this.$toast.success(this.translation.success_global.changes_saved);

            this.UPDATE_PRIVACY(privacy);
          }
          this.loaderSave = false;
        })
        .catch(() => {
          this.loaderSave = false;
          this.$toast.error(
            this.translation.errors_global.something_went_wrong
          );
        });
    },

    getOptionIndex(id) {
      const option = this.idOptions.find(option => option.value === id);

      return this.idOptions.indexOf(option);
    },

    setFields() {
      this.participantsParticipantId = this.idOptions[
        this.getOptionIndex(
          this.privacy.participants.participants_identifier_type_id
        )
      ];

      this.participantsAdminId = this.idOptions[
        this.getOptionIndex(this.privacy.participants.admins_identifier_type_id)
      ];

      this.moderatorsParticipantId = this.idOptions[
        this.getOptionIndex(
          this.privacy.moderators.participants_identifier_type_id
        )
      ];

      this.moderatorsHideEmail = !!+this.privacy.moderators
        .participants_hide_email;

      this.moderatorsLastLoginInfo = !!+this.privacy.moderators
        .participants_hide_login_info;

      this.observersParticipantId = this.idOptions[
        this.getOptionIndex(
          this.privacy.observers.participants_identifier_type_id
        )
      ];

      this.observersHideEmail = !!+this.privacy.observers
        .participants_hide_email;
      this.observersLastLoginInfo = !!+this.privacy.observers
        .participants_hide_login_info;
      this.observersHideSegments = !!+this.privacy.observers
        .participants_hide_segments;
      this.observersHidePersonalProgress = !!+this.privacy.observers
        .participants_hide_personal_progress;
      this.observersHideOperatorsComments = !!+this.privacy.observers
        .participants_hide_operators_comment;
    }
  },

  created() {
    this.idOptions = [
      {
        value: this.displayNameIds.fullName,
        text: this.translation.privacy_and_permissions.full_name
      },
      {
        value: this.displayNameIds.firstNameAndLastNameInitial,
        text: this.translation.privacy_and_permissions
          .first_name_and_last_name_initial
      },
      {
        value: this.displayNameIds.firstName,
        text: this.translation.privacy_and_permissions.name_only
      },
      {
        value: this.displayNameIds.nameAndId,
        text: this.translation.privacy_and_permissions.name_and_unique_id
      },
      {
        value: this.displayNameIds.idOnly,
        text: this.translation.privacy_and_permissions.unique_id_only
      }
    ];
  },

  mounted() {
    this.setFields();
  }
};
</script>

<style lang="scss" scoped>
.privacy {
  margin-top: 60px;
  h2 {
    font-weight: bold;
    font-size: 1.3rem;
    margin-bottom: 30px;
  }
}

.participants,
.moderators {
  margin-bottom: 30px;
}

.item {
  margin-top: 30px;

  h3 {
    font-weight: bold;
    color: #6b6b6b;
    font-size: 1.1rem;
    margin-bottom: 10px;
  }

  p {
    color: #6b6b6b;
  }
}

.dropdown-wrapper {
  max-width: 280px;
  margin-bottom: 20px;
}

.checkbox-wrapper {
  color: #6b6b6b;
  margin-bottom: 10px;
}

.save-btn {
  text-align: center;
  text-transform: uppercase;
  color: #fff;
  font-weight: bold;
  letter-spacing: 0.16em;
  border-radius: 17px;
  border: none;
  padding: 5px 20px;
  align-self: flex-end;
  margin-top: 3rem;
  min-width: 162px;
}
</style>
