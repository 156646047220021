<template>
  <div class="row">
    <div class="col">
      <div class="d-flex justify-content-between align-items-center mt-5">
        <div>
          <div
            class="dropdown-actions"
            id="dropdown-bulk-action-participants"
            v-if="$store.getters.info.user.type === userTypesText.admin"
          >
            <button
              :style="{ color: $store.getters.theme[2] }"
              class="btn-actions hover"
              @click="handleDrop('dropdown-bulk-action-participants')"
            >
              {{ translation.manage_participants.bulk_action }}
              <b-icon
                class="m-0"
                icon="caret-right-fill"
                aria-hidden="true"
              ></b-icon>
            </button>
            <b-modal
              modal-class="modalComment"
              no-enforce-focus
              @ok.prevent="reinviteParticipant"
              :cancel-title="translation.global.cancel"
              id="reinvite-participant"
              :title="translation.manage_participants.resend_invitation"
            >
              <form v-if="!loaderSend" class="mb-3">
                <label class="mb-3" for="subject">
                  {{ translation.add_participant.email_subject }}:</label
                >
                <b-form-input id="subject" v-model="subject"></b-form-input>

                <label class="mt-4 mb-3">
                  {{ translation.add_participant.email_body }}:
                </label>
                <editor
                  placeholder=""
                  :text="body"
                  :maxCharacters="maxCharacters"
                  @charactersCount="count => (charactersCount = count)"
                  @update="txt => (body = txt)"
                />
              </form>
              <div
                class="d-flex justify-content-center py-5"
                v-else-if="loaderSend"
              >
                <loader />
              </div>
            </b-modal>
            <div id="dropdown" class="bulk">
              <a @click="$bvModal.show('reinvite-participant')">
                <b-icon class="mr-2" icon="envelope"></b-icon>
                {{ translation.manage_participants.resend_invitation }}
              </a>
              <a @click="blockInvites">
                <b-icon class="mr-2" icon="x-circle"></b-icon>
                {{ translation.manage_participants.block_invitation }}
              </a>
            </div>
          </div>
        </div>
        <search />
      </div>
      <div v-if="!loaderParticipants && participantsPages.length">
        <div class="table-wrapper">
          <div class="table mt-4">
            <div
              :style="{ color: $store.getters.theme[3] }"
              class="table-header"
            >
              <div v-if="$store.getters.info.user.type === userTypesText.admin">
                <b-form-checkbox v-model="all"> </b-form-checkbox>
              </div>
              <div
                v-if="
                  $store.getters.info.user.type === userTypesText.admin ||
                    ($store.getters.info.user.type ===
                      userTypesText.moderator &&
                      !+privacy.moderators.participants_hide_email) ||
                    ($store.getters.info.user.type === userTypesText.observer &&
                      !+privacy.observers.participants_hide_email)
                "
                class="lg"
              >
                {{ translation.manage_participants.email }}
              </div>
              <div class="lg">
                {{ translation.manage_participants.created_at }}
              </div>
              <div class="lg">
                {{ translation.manage_participants.read_at }}
              </div>
              <div class="lg">
                {{ translation.manage_participants.joined_at }}
              </div>
            </div>
            <div
              v-for="participant in participantsPages"
              :key="participant.id"
              class="table-content mt-2 p-1"
            >
              <div v-if="$store.getters.info.user.type === userTypesText.admin">
                <b-form-checkbox
                  :value="participant.id"
                  v-model="selectedParticipants"
                ></b-form-checkbox>
              </div>
              <div
                class="lg"
                :title="participant.destination_email"
                v-b-tooltip.hover="{ variant: 'secondary' }"
                v-if="
                  $store.getters.info.user.type === userTypesText.admin ||
                    ($store.getters.info.user.type ===
                      userTypesText.moderator &&
                      !+privacy.moderators.participants_hide_email) ||
                    ($store.getters.info.user.type === userTypesText.observer &&
                      !+privacy.observers.participants_hide_email)
                "
              >
                {{ participant.destination_email | maxLength }}
              </div>
              <div class="lg">{{ participant.created_at | formatDate }}</div>
              <div class="lg">{{ participant.read_at_utc }}</div>
              <div class="lg">{{ participant.joined_at }}</div>

              <div class="right pr-3">
                <div
                  class="dropdown-actions"
                  :id="`dropdown-actions-participants-${participant.id}`"
                  v-if="$store.getters.info.user.type === userTypesText.admin"
                >
                  <button
                    :style="{ color: $store.getters.theme[2] }"
                    class="btn-actions hover"
                    @click="
                      handleDrop(
                        `dropdown-actions-participants-${participant.id}`
                      )
                    "
                  >
                    {{ translation.global.actions }}
                    <b-icon
                      class="m-0"
                      icon="caret-right-fill"
                      aria-hidden="true"
                    ></b-icon>
                  </button>
                  <div id="dropdown">
                    <a @click="openReinviteParticipantModal(participant.id)">
                      <b-icon class="mr-2" icon="envelope"></b-icon>
                      {{ translation.manage_participants.resend_invitation }}
                    </a>

                    <a @click="blockOneParticipant(participant.id)">
                      <b-icon class="mr-2" icon="x-circle"></b-icon>
                      {{ translation.manage_participants.block_invitation }}
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <pagination-query
            v-if="!queryValue && minPerPage < total"
            :perPage="perPage"
            :total="total"
            :options="[10, 20, 50, 100]"
            @perPageUpdate="newPerPage => (perPage = newPerPage)"
          />
        </div>
      </div>
      <div
        class="d-flex justify-content-center py-5"
        v-else-if="loaderParticipants"
      >
        <loader />
      </div>
      <div
        v-else-if="queryValue"
        class="result d-flex justify-content-center py-5"
      >
        {{ translation.global.sorry_we_have }}
        "{{ queryValue }}"
      </div>
      <div class="d-flex justify-content-center py-5" v-else>
        {{ translation.manage_participants.no_participants }}
      </div>
    </div>
  </div>
</template>

<script>
import { api } from "@/services.js";
import paginationQuery from "@/components/general/paginationQuery.vue";
import loader from "@/components/general/loader.vue";
import search from "@/components/general/search.vue";
import editor from "@/components/general/editor.vue";
import { userTypesText } from "@/constants";
import { outsideClick } from "@/helpers.js";
import { mapState } from "vuex";

export default {
  components: { paginationQuery, search, loader, editor },
  data() {
    return {
      loaderParticipants: false,
      participantsPages: [],
      participants: [],
      perPage: 10,
      minPerPage: 10,
      total: 0,
      selectedParticipants: [],
      all: false,
      participantsIds: [],
      subject: "",
      body: "<p></p>",
      maxCharacters: 10000,
      charactersCount: 0,
      loaderSend: false,
      loaderBlock: false,
      outsideClick,
      userTypesText
    };
  },

  filters: {
    maxLength(value) {
      if (value.length > 25) {
        return (
          value
            .split("")
            .splice(0, 25)
            .join("") + "..."
        );
      } else return value;
    },

    formatDate(value) {
      const date = new Date(value);
      return date.toLocaleString();
    }
  },

  computed: {
    ...mapState(["privacy"]),

    page() {
      return this.$route.query._page;
    },

    queryValue() {
      if (this.$route.query._q && this.$route.query._q.length)
        return this.$route.query._q;
      else return 0;
    }
  },

  watch: {
    queryValue() {
      this.getParticipantsPages();
    },

    page() {
      this.getParticipantsPages();
    },

    perPage() {
      this.getParticipantsPages();
    },

    participants() {
      this.getParticipantsPages();
    },

    selectedParticipants() {
      const isAllChecked = this.selectedParticipants.length === this.total;
      if (!isAllChecked) {
        this.all = false;
      }
    },

    all() {
      const isAllChecked = this.selectedParticipants.length === this.total;
      if (this.all) {
        this.selectedParticipants = this.participantsIds;
      } else if (isAllChecked) {
        this.selectedParticipants = [];
      }
    }
  },

  methods: {
    blockOneParticipant(id) {
      this.selectedParticipants = [id];
      this.blockInvites();
    },

    blockInvites() {
      if (!this.loaderBlock) {
        this.loaderBlock = true;

        api
          .put(
            `invites/manage/change-active-status?is_active=0`,
            {
              invites: this.selectedParticipants
            },
            {
              headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${this.$store.getters.token}`
              }
            }
          )
          .then(r => {
            if (r.status === 200) {
              this.selectedParticipants = [];
              this.getParticipants();
              this.$toast.success(
                this.translation.success_global.changes_saved
              );
            }
            this.loaderBlock = false;
          })
          .catch(() => {
            this.$toast.error(
              this.translation.errors_global.something_went_wrong
            );
            this.loaderBlock = false;
          });
      }
    },

    openReinviteParticipantModal(id) {
      this.selectedParticipants = [id];
      this.$bvModal.show("reinvite-participant");
    },

    reinviteParticipant() {
      if (!this.loaderSend) {
        this.loaderSend = true;

        api
          .post(
            `invites/re-invite`,
            {
              invitations: this.selectedParticipants,
              email_body: this.body,
              email_subject: this.subject
            },
            {
              headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${this.$store.getters.token}`
              }
            }
          )
          .then(r => {
            if (r.status === 200) {
              this.selectedParticipants = [];
              this.$bvModal.hide("reinvite-participant");
              this.$toast.success(this.translation.email.success.sent_email);
            }
            this.loaderSend = false;
          })
          .catch(() => {
            this.$toast.error(
              this.translation.errors_global.something_went_wrong
            );
            this.loaderSend = false;
          });
      }
    },

    getParticipantsPages() {
      let array = [];

      if (this.queryValue) {
        array = this.participants.filter(item => {
          return this.queryValue
            .toLowerCase()
            .split(" ")
            .every(v => item.destination_email.toLowerCase().includes(v));
        });
      } else {
        const start = this.perPage * (this.page - 1);
        this.participants.forEach((participant, index) => {
          if (index >= start && index < start + this.perPage)
            array.push(participant);
        });
      }

      this.participantsPages = array;
    },

    getParticipants() {
      this.loaderParticipants = true;
      api
        .get(`invites/manage/community/${this.$store.getters.community.id}`, {
          headers: {
            Authorization: `Bearer ${this.$store.getters.token}`
          }
        })
        .then(r => {
          if (r.status === 200) {
            this.participants = r.data;
            this.participantsIds = r.data.map(participant => participant.id);
            this.getParticipantsPages();
            this.total = this.participants.length;
          }
          this.loaderParticipants = false;
        })
        .catch(() => {
          this.loaderParticipants = false;
        });
    },

    handleDrop(drop) {
      const dropItem = document.querySelector(`#${drop}`);
      dropItem.children[1].classList.toggle("active");

      this.outsideClick(dropItem, ["click"], () => {
        dropItem.children[1].classList.remove("active");
      });
    }
  },

  created() {
    this.getParticipants();
  }
};
</script>

<style lang="scss" scoped>
.table-wrapper {
  overflow-x: auto;
  margin-right: 100px;
  &::-webkit-scrollbar {
    height: 8px;
    overflow: hidden;
  }
  &::-webkit-scrollbar-track {
    background: rgba(0, 0, 0, 0);
    border-radius: 10px;
  }
  &::-webkit-scrollbar-thumb {
    background: #ccc;
    border-radius: 10px;
    height: 10px;
  }
  &::-webkit-scrollbar-thumb:hover {
    background: #aaa;
  }
}

.table {
  width: 100%;
}

.table-header {
  min-width: 100%;
  display: flex;
  width: fit-content;
  div {
    min-width: 80px;
    flex: 1;
    display: flex;
    justify-content: center;
    font-weight: bold;
    font-size: 0.9rem;
    &.md {
      min-width: 180px;
    }
    &.lg {
      min-width: 200px;
    }
  }
}

.table-content {
  min-width: 100%;
  width: fit-content;
  display: flex;
  align-items: center;
  border-top-left-radius: 50px;
  border-bottom-left-radius: 50px;
  border: 1px solid #999999;
  border-right: 0;
  height: 40px;
  > div {
    min-width: 80px;
    flex: 1;
    display: flex;
    justify-content: center;
    color: #6b6b6b;
    animation: show 0.3s linear;
    font-size: 0.9rem;
    &.md {
      min-width: 180px;
    }
    &.lg {
      min-width: 200px;
    }
    &.right {
      flex-direction: column;
      position: absolute;
      right: 45px;
      background: #fff;
      min-width: 80px;
      border: 1px solid #999999;
      border-top-right-radius: 50px;
      border-bottom-right-radius: 50px;
      border-left: 0;
      height: 40px;
    }
  }
}

@media (max-width: 767.98px) {
  .table-content {
    > div {
      &.right {
        right: 20px;
        min-width: 95px;
      }
    }
  }
}

.btn-actions {
  display: flex;
  align-items: center;
  font-weight: bold;
  background: none;
  border: none;
  &:focus {
    outline: none;
  }
}

.dropdown-actions {
  position: relative;
  display: flex;
  justify-content: center;
}

#dropdown {
  display: none;
  animation: grow 400ms ease-in-out forwards;
  transform-origin: top center;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 4px;
  position: absolute;
  top: 40px;
  right: 0px;
  z-index: 5;
  background: #fff;
  width: 155px;
  &.bulk {
    right: unset;
    left: 0;
  }
  &.active {
    display: block;
  }
  a {
    display: flex;
    align-items: center;
    cursor: pointer;
    padding: 5px 10px;
    color: #999;
    text-decoration: none;
    font-weight: normal;
    white-space: nowrap;
    font-size: 0.9rem;
    &:hover {
      background: rgba(193, 193, 193, 0.4);
    }
    &:first-of-type {
      border-top-right-radius: 4px;
      border-top-left-radius: 4px;
    }
    &:last-of-type {
      border-bottom-right-radius: 4px;
      border-bottom-left-radius: 4px;
    }
    &.active {
      background: rgba(193, 193, 193, 0.25);
    }
  }
}

.btn-red {
  background: #df2938;
  color: #fff;
}

.btn-green {
  background: #10c398;
  color: #fff;
}

form {
  label {
    display: block;
    color: #6b6b6b;
    font-weight: bold;
    font-size: 1.1rem;
  }
  input {
    border: 1px solid #efefef;
    border-radius: 3px;
    color: #6b6b6b;
  }
}
</style>
