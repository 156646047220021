<template>
  <div class="container pt-5">
    <div
      v-if="
        $store.getters.is_over &&
          ($store.getters.info.user.type === userTypesText.moderator ||
            $store.getters.info.user.type === userTypesText.admin)
      "
      class="row mb-4"
    >
      <div class="col">
        <div class="card-b p-3 text-danger">
          {{ translation.feed.expired_community }}
        </div>
      </div>
    </div>
    <a
      v-if="$store.getters.info.user.type === userTypesText.admin"
      class="back"
      @click="goToCommunities"
    >
      <b-icon class="mr-1" icon="arrow-left-circle"></b-icon>
      {{ translation.communities.communities }}
    </a>
    <div class="row pb-5 pt-2">
      <div
        :class="{
          'col-lg-8':
            $store.getters.info.user.type === userTypesText.participant,
          col: $store.getters.info.user.type !== userTypesText.participant
        }"
        class="p-0"
      >
        <div
          :class="{
            participant:
              $store.getters.info.user.type === userTypesText.participant
          }"
          class="card-b img-feed mb-4"
        >
          <img
            v-if="
              $store.getters.community.banner &&
                $store.getters.community.banner.length
            "
            :src="$store.getters.community.banner"
            alt="Banner"
          />
          <div class="desc">
            <div
              class="box"
              :style="{
                'background-color': $store.getters.theme[2],
                opacity: 0.78
              }"
            ></div>
            <div class="text">
              <p>{{ $store.getters.community.description }}</p>
            </div>
          </div>
        </div>

        <activities
          :activities="filterActivities"
          :openNewActivity="openNewActivity"
          :feed="true"
          :getActivities="getActivities"
          :loader="loader"
          @edit="id => handleEdit(id)"
          @task="id => handleTask(id)"
        />
      </div>
      <div
        class="col-lg-4"
        v-if="$store.getters.info.user.type === userTypesText.participant"
      >
        <next-activity
          :loader2="loader"
          :activity="nextActivity"
          :activities="activities"
        />
        <guide />
      </div>
    </div>
  </div>
</template>

<script>
import { api } from "@/services.js";
import activities from "@/components/general/activities.vue";
import nextActivity from "@/components/general/nextActivity.vue";
import guide from "@/components/feed/guide.vue";
import { themes, userTypesText, isActiveCode } from "@/constants";

export default {
  name: "Feed",
  components: { activities, guide, nextActivity },
  data() {
    return {
      openEditor: false,
      openNewActivity: false,
      activities: [],
      newActivityId: "none",
      tab: 1,
      reload: false,
      scroll: false,
      loader: false,
      themes,
      userTypesText,
      isActiveCode
    };
  },
  computed: {
    filterActivities() {
      let activitiesActive = this.activities.filter(
        activity => activity.is_active === this.isActiveCode.active
      );

      let perPage = 4;

      if (this.$store.getters.info.user.type === this.userTypesText.participant)
        perPage = 3;
      if (
        activitiesActive.length >= perPage &&
        this.$store.getters.info.user.type !== this.userTypesText.moderator &&
        this.$store.getters.info.user.type !== this.userTypesText.admin
      )
        return activitiesActive.slice(0, perPage);
      else if (
        this.activities.length >= perPage &&
        (this.$store.getters.info.user.type === this.userTypesText.moderator ||
          this.$store.getters.info.user.type === this.userTypesText.admin)
      ) {
        return this.activities.slice(0, perPage);
      } else if (
        this.$store.getters.info.user.type === this.userTypesText.moderator ||
        this.$store.getters.info.user.type === this.userTypesText.admin
      ) {
        return this.activities;
      } else {
        return activitiesActive;
      }
    },
    nextActivity() {
      if (this.activities.length) return this.activities[0];
      else return {};
    }
  },
  watch: {
    reload() {
      this.getActivities();
    }
  },
  methods: {
    goToCommunities() {
      this.$store.commit("UPDATE_COMMUNITY", null);
      this.$store.commit("UPDATE_THEME", JSON.stringify(this.themes[4]));
      const lang = (navigator.language || navigator.userLanguage).split("-")[0];

      if (lang === "es" || lang === "pt" || lang === "en") {
        this.$store.commit("UPDATE_LANGUAGE", lang);
      } else {
        this.$store.commit("UPDATE_LANGUAGE", "en");
      }

      this.$router.push({ name: "communities" });
    },
    handleNewActivity() {
      if (!this.$store.getters.is_over) {
        this.openNewActivity = !this.openNewActivity;
        this.tab = 1;
        this.newActivityId = "none";
      }
    },
    handleTheme() {
      if (!this.$store.getters.is_over) {
        this.openEditor = !this.openEditor;
      }
    },
    getActivities() {
      this.loader = true;

      let url = `community/${this.$store.getters.community.id}/activities`;
      if (this.$store.getters.info.user.type === this.userTypesText.participant)
        url = `community/${this.$store.getters.community.id}/participant/${this.$store.getters.info.user.sub}/activities`;

      api
        .get(url, {
          headers: {
            Authorization: `Bearer ${this.$store.getters.token}`
          }
        })
        .then(r => {
          if (r.status === 200) {
            this.activities = r.data.community.activities;
          }
          this.loader = false;
        })
        .catch(() => {
          this.loader = false;
        });
    },
    handleTask(id) {
      this.scroll = !this.scroll;
      this.tab = 2;
      this.openNewActivity = true;
      this.newActivityId = id;
    },
    handleEdit(id) {
      this.scroll = !this.scroll;
      this.tab = 1;
      this.openNewActivity = true;
      this.newActivityId = id;
    }
  },
  created() {
    this.getActivities();
  }
};
</script>

<style lang="scss" scoped>
.img-feed {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  height: 355px;
  margin: 0 15px;
  img {
    object-fit: cover;
    width: 1140px;
    height: 355px;
  }
  &.participant {
    height: 300px;
    img {
      object-fit: cover;
      width: 1110px;
      height: 300px;
    }
  }
}

@media (max-width: 1199.98px) {
  .img-feed {
    height: 300px;
    img {
      height: 300px;
      width: 930px;
    }
  }
}

@media (max-width: 976.98px) {
  .img-feed {
    height: 250px;
    img {
      height: 250px;
      width: 690px;
    }
  }
}

@media (max-width: 767.98px) {
  .img-feed {
    height: 200px;
    img {
      height: 200px;
      width: 510px;
    }
  }
}

@media (max-width: 567.98px) {
  .img-feed {
    height: 300px;
    img {
      height: 300px;
      width: 600px;
    }
  }
}

.desc {
  width: 100%;
  height: 60px;
  position: absolute;
  bottom: 0;
  .box {
    width: 100%;
    height: 100%;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
  }
  .text {
    display: flex;
    align-items: center;
    position: absolute;
    bottom: 0;
    height: 60px;
    p {
      text-transform: uppercase;
      font-weight: bold;
      color: #fff;
      margin-bottom: 0px;
      margin-left: 20px;
    }
  }
}

@media (max-width: 567.98px) {
  .desc {
    height: 90px;
    .text {
      height: 90px;
    }
  }
}

.edit-wrapper {
  max-width: 210px;
  cursor: pointer;
}

.edit-btn {
  position: relative;
  display: flex;
  justify-content: flex-end;
}

.close-editor {
  margin-top: 10px;
  margin-right: 10px;
  animation: grow 400ms ease-in-out forwards;
  position: absolute;
  right: 15px;
  bottom: -35px;
  &.newactivity {
    bottom: -10px;
    right: 0px;
  }
}

.edit-theme {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  p {
    text-transform: uppercase;
    color: #777470;
    font-weight: bold;
    font-size: 0.75rem;
    margin: 0 10px 0 0;
  }
  button {
    display: flex;
    justify-content: center;
    align-items: center;
    background: #fff;
    border: none;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    color: #6b6b6b;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);

    &:focus {
      outline: none;
    }
  }
}

.back {
  color: #6b6b6b;
  cursor: pointer;
}
</style>
