<template>
  <div>
    <div class="mb-3">
      <div
        class="d-flex align-items-center my-4"
        v-for="(option, index) in answer.answer_object.options"
        :key="index"
      >
        <div
          :style="{
            backgroundImage: bgUrl,
            backgroundSize: `${imageWidth}px ${imageHeight}px`,
            backgroundPosition: `${(option.clickMetadata.x - 65) *
              -1}px ${(option.clickMetadata.y - 65) * -1}px`
          }"
          class="crop-image"
          :data-icon="option.icon"
        />
        <div>
          <p>
            {{ option.label }}
          </p>
          <p>
            <span class="font-weight-bold">
              {{ translation.answer_task.comments }}:
            </span>
            {{ option.comment }}
          </p>
        </div>
      </div>
      <p v-if="answer.answer_object && answer.answer_object.comment.length">
        <span :style="{ color: $store.getters.theme[3] }" class="comments">
          {{ translation.answer_task.comments }}
        </span>
        {{ answer.answer_object.comment }}
      </p>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    answer: {
      required: true
    }
  },
  computed: {
    bgUrl() {
      return `url(${this.answer.answer_object.image})`;
    },
    imageWidth() {
      return this.answer.answer_object.image_width;
    },
    imageHeight() {
      return this.answer.answer_object.image_height;
    }
  }
};
</script>

<style lang="scss" scoped>
.crop-image {
  background-color: #f9f9f9;
  background-repeat: no-repeat;
  border: 1px solid #ccc;
  float: left;
  height: 128px;
  margin-right: 20px;
  position: relative;
  transition: background-position 0.3s ease-in-out;
  width: 128px;
  display: flex;
  justify-content: center;
  align-items: center;
  &::before {
    content: attr(data-icon);
    display: block;
    font-size: 28px;
  }
}

.comments {
  margin-top: 20px;
  display: block;
  text-transform: uppercase;
  font-weight: bold;
}
</style>
