<template>
  <div class="text-task-data" v-html="answer.raw_answer"></div>
</template>

<script>
export default {
  props: {
    answer: {
      required: true
    }
  }
};
</script>

<style lang="scss">
.text-task-data {
  * {
    font-size: 1rem !important;
  }
}
</style>
