<template>
  <div>
    <div class="d-flex flex-wrap pt-4">
      <div v-if="answer.answer_object.files">
        <div
          v-for="(video, index) in answer.answer_object.files"
          :key="index"
          class="pb-3"
        >
          <div>
            <video
              :controlsList="
                $store.getters.info.user.type !== userTypesText.admin &&
                $store.getters.info.user.type !== userTypesText.moderator
                  ? 'nodownload'
                  : ''
              "
              :src="video.url"
              controls
            ></video>
            <p class="mt-1">
              {{ video.description }}
            </p>
          </div>
        </div>
      </div>
      <div
        v-else
        v-for="(video, index) in answer.answer_object.videos"
        :key="index"
      >
        <div class="pb-3 mr-3">
          <video
            :controlsList="
              $store.getters.info.user.type !== userTypesText.admin &&
              $store.getters.info.user.type !== userTypesText.moderator
                ? 'nodownload'
                : ''
            "
            :src="video.URL"
            controls
          ></video>
        </div>
      </div>
    </div>
    <p
      v-if="
        answer.answer_object &&
          answer.answer_object.comment &&
          answer.answer_object.comment.length
      "
    >
      <span :style="{ color: $store.getters.theme[3] }" class="comments">
        {{ translation.answer_task.comments }}
      </span>
      {{ answer.answer_object.comment }}
    </p>
  </div>
</template>

<script>
import { userTypesText } from "@/constants";

export default {
  props: {
    answer: {
      required: true
    }
  },
  data() {
    return {
      userTypesText
    };
  }
};
</script>

<style lang="scss" scoped>
.comments {
  margin-top: 20px;
  display: block;
  text-transform: uppercase;
  font-weight: bold;
}
</style>
