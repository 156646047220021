<template>
  <div>
    <div class="task-statement-formatted">
      <p :class="{ active: showMore }" v-html="getFormattedTask"></p>
      <div v-if="showMore">
        <div class="matrix d-flex justify-content-center py-4">
          <div class="w-100">
            <div class="cols">
              <p>
                {{ translation.create_task.matrix.columns }}
              </p>
              <div
                v-for="(col, index) in task.question_object.columns"
                :key="index"
              >
                <span>
                  {{ col.value }}
                </span>
              </div>
            </div>
            <div class="rows">
              <p>
                {{ translation.create_task.matrix.rows }}
              </p>
              <div
                v-for="(row, index) in task.question_object.rows"
                :key="index"
              >
                <span>
                  {{ row.value }}
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="task-statement-separator">
        <button
          :style="{ color: $store.getters.theme[2] }"
          class="task-statement-show-more hover"
          @click="showMore = !showMore"
        >
          {{
            showMore
              ? translation.global.show_less
              : translation.global.show_more
          }}
        </button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    task: {
      required: true
    },
    getFormattedTask: {
      required: true
    }
  },
  data() {
    return {
      showMore: false
    };
  }
};
</script>

<style lang="scss" scoped>
.matrix {
  overflow: auto;
  &::-webkit-scrollbar {
    height: 8px;
    overflow: hidden;
  }
  &::-webkit-scrollbar-track {
    background: rgba(0, 0, 0, 0);
    border-radius: 10px;
  }
  &::-webkit-scrollbar-thumb {
    background: #ccc;
    border-radius: 10px;
    height: 10px;
  }
  &::-webkit-scrollbar-thumb:hover {
    background: #aaa;
  }
  .cols,
  .rows {
    p {
      display: none;
    }
    span {
      display: block;
      border: 1px solid #c4c4c4;
      border-radius: 2px;
      text-align: center;
      color: #999999;
      font-size: 0.8rem;
      width: 150px;
      transition: all 0.3s ease;
      animation: show 0.3s linear;
      margin-right: 1px;
      padding: 5px;

      &::placeholder {
        color: #999999;
        opacity: 0.6;
      }

      &:-ms-input-placeholder {
        color: #999999;
        opacity: 0.6;
      }

      &::-ms-input-placeholder {
        color: #999999;
        opacity: 0.6;
      }
    }
    > div {
      display: flex;
      margin-right: 2px;
      margin-bottom: 2px;
    }
  }
  .cols {
    display: flex;
    padding-left: 150px;
  }
}

@media (max-width: 991.98px) {
  .matrix {
    .cols,
    .rows {
      flex-direction: column;
      padding-left: 0;
      p {
        display: block;
        color: #6b6b6b;
        margin-bottom: 10px;
      }
      span {
        width: 100%;
        margin: 10px 0;
        padding: 5px;
      }

      div {
        &:nth-of-type(1) {
          flex-direction: column;
          padding-left: 0;
        }
        &:nth-of-type(2) {
          flex-direction: column;
        }
      }
    }
  }
}
</style>
