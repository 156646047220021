<template>
  <div id="createtask" class="container">
    <div v-if="!loaderPost" class="row py-5">
      <welcome class="d-none d-md-block" />

      <div class="col-md-9">
        <div class="d-md-flex mb-3">
          <div class="search mr-3">
            <input
              v-model="search"
              class="pl-2"
              :placeholder="translation.forum.search_post"
              type="text"
            />
            <b-icon class="mx-2" icon="search" aria-hidden="true"></b-icon>
          </div>
          <div class="d-flex my-3 my-md-0">
            <router-link
              v-if="
                $store.getters.info.user.type === userTypesText.moderator ||
                  $store.getters.info.user.type === userTypesText.admin
              "
              :to="{ name: 'newpost' }"
              :style="{ 'background-color': $store.getters.theme[2] }"
              class="btn btn-newpost"
            >
              <b-icon class="mr-1" icon="plus" aria-hidden="true"></b-icon>
              {{ translation.forum.new_post.new_post }}
            </router-link>
            <button
              href=""
              :style="{ 'background-color': $store.getters.theme[2] }"
              class="ml-2 btn btn-export-forum"
              @click="exportForum"
            >
              <span v-if="loaderExport">
                <loader2 color="#ffffff" />
              </span>
              <span v-else>
                {{ translation.forum.export_forum }}
              </span>
            </button>
          </div>
        </div>

        <div v-if="searchArray.length" class="p-relative">
          <div
            v-for="(post, index) in searchArray"
            :key="index"
            :class="{ opacity: !+post.is_active }"
            class="card-b p-3 border-radius-right post mb-3"
          >
            <post
              :getPost="getPosts"
              :post="post"
              @updateLike="like => updateLike(like, index)"
            />
          </div>
        </div>
        <div
          v-else-if="queryValue"
          class="result d-flex justify-content-center py-5"
        >
          {{ translation.global.sorry_we_have }}
          "{{ queryValue }}"
        </div>
        <div v-else class="result d-flex justify-content-center py-5">
          {{ translation.forum.no_posts }}
        </div>
      </div>
    </div>
    <div class="d-flex justify-content-center py-5" v-else-if="loaderPost">
      <loader />
    </div>
  </div>
</template>

<script>
import { api } from "@/services.js";
import post from "@/components/forum/post.vue";
import welcome from "@/components/forum/welcome.vue";
import loader from "@/components/general/loader.vue";
import loader2 from "@/components/general/loader2.vue";
import { userTypesText } from "@/constants";

export default {
  components: { post, welcome, loader, loader2 },
  data() {
    return {
      posts: [],
      searchArray: [],
      loaderPost: false,
      search: "",
      userTypesText,
      loaderExport: false
    };
  },
  computed: {
    queryValue() {
      if (this.$route.query._q && this.$route.query._q.length)
        return this.$route.query._q;
      else return 0;
    }
  },
  watch: {
    search() {
      this.$router.push({ query: { _q: this.search } });
    },
    queryValue() {
      if (this.queryValue) {
        this.searchArray = this.posts.filter(item => {
          return this.queryValue
            .toLowerCase()
            .split(" ")
            .every(v => item.title.toLowerCase().includes(v));
        });
      } else {
        this.searchArray = this.posts;
      }
    }
  },
  methods: {
    exportForum() {
      if (!this.loaderExport) {
        this.loaderExport = true;
        api
          .get(`forum/community/${this.$store.getters.community.id}/export`, {
            responseType: "arraybuffer",
            headers: {
              Authorization: `Bearer ${this.$store.getters.token}`,
              "Content-Type": "application/json"
            }
          })
          .then(r => {
            const blob = new Blob([r.data], {
              type: "application/octet-stream"
            });
            const filename = `forum.xlsx`;
            const a = document.createElement("a");
            document.body.appendChild(a);
            a.style = "display: none";
            const url = window.URL.createObjectURL(blob);
            a.href = url;
            a.download = filename;
            a.click();
            window.URL.revokeObjectURL(url);
            this.loaderExport = false;
          })
          .catch(() => {
            this.loaderExport = false;
            this.$toast.error(
              this.translation.errors_global.something_went_wrong
            );
          });
      }
    },
    getPosts(type) {
      if (type === "init") this.loaderPost = true;

      if (
        this.$store.getters.info.user.type === this.userTypesText.participant
      ) {
        api
          .get(
            `forum/posts/participant/${this.$store.getters.info.user.sub}/community/${this.$store.getters.community.id}`,
            {
              headers: {
                Authorization: `Bearer ${this.$store.getters.token}`
              }
            }
          )
          .then(r => {
            if (r.status === 200) {
              this.posts = r.data;
              this.searchArray = r.data;
            }
            this.loaderPost = false;
          })
          .catch(() => {
            this.loaderPost = false;
          });
      } else {
        api
          .get(`forum/community/${this.$store.getters.community.id}`, {
            headers: {
              Authorization: `Bearer ${this.$store.getters.token}`
            }
          })
          .then(r => {
            if (r.status === 200) {
              this.posts = r.data;
              this.searchArray = r.data;
            }
            this.loaderPost = false;
          })
          .catch(() => {
            this.loaderPost = false;
          });
      }
    },
    updateLike(like, index) {
      if (like) {
        this.posts[index].likes = this.posts[index].likes - 1;
        this.posts[index].liked = false;
      } else {
        this.posts[index].likes = this.posts[index].likes + 1;
        this.posts[index].liked = true;
      }
    },
    init() {
      this.getPosts("init");
    }
  },
  created() {
    this.init();
  }
};
</script>

<style lang="scss" scoped>
.border-radius-right {
  border-radius: 0;
  border-top-right-radius: 20px;
  border-bottom-right-radius: 20px;
}

.border-radius-left {
  border-radius: 0;
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;
}

@media (max-width: 767.98px) {
  .border-radius-right {
    border-radius: 20px;
  }

  .border-radius-left {
    border-radius: 20px;
  }
}

.profile-img {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100px;
  height: 100px;
  border-radius: 50%;
  overflow: hidden;
  margin-bottom: 5px;

  img {
    object-fit: cover;
    height: 100px;
    width: 100px;
  }
}

.name {
  margin: 0 auto;
  max-width: 100px;
  font-weight: bold;
  color: #6b6b6b;
}

.text {
  font-size: 1rem;
  color: #6b6b6b;
  h3 {
    font-size: 1rem;
    font-weight: bold;
  }
}

.search {
  display: flex;
  align-items: center;
  border: 1px solid #676767;
  color: #6b6b6b;
  border-radius: 27px;
  overflow: hidden;
  flex: 1;
  input {
    color: #6b6b6b;
    width: 100%;
    border: none;
    background-color: rgba(0, 0, 0, 0);
    &:focus {
      outline: none;
      color: #6b6b6b;
    }
  }
}

.result {
  color: #6b6b6b;
}

.btn-newpost,
.btn-export-forum {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 30px;
  border-radius: 17px;
  text-transform: uppercase;
  font-weight: bold;
  color: #fff;
  font-size: 0.65rem;
  white-space: nowrap;
  svg {
    font-size: 1.2rem !important ;
  }
}

.detail {
  width: 3px;
  height: 30px;
  position: absolute;
  bottom: -30px;
  left: 80px;
  &::after {
    content: "";
    position: absolute;
    bottom: 0;
    left: -3.5px;
    display: block;
    width: 10px;
    height: 10px;
    background-color: inherit;
    border-radius: 50%;
  }
}

.sub-reply {
  position: relative;
}

.sub-reply-detail {
  margin-left: 45px;
  width: 60px;
  height: 4px;
  position: relative;
  margin-right: 10px;
  &::before {
    content: "";
    position: absolute;
    display: block;
    width: 4px;
    height: 150px;
    background-color: inherit;
    bottom: 0;
  }
  &::after {
    content: "";
    width: 10px;
    height: 10px;
    border-radius: 50%;
    position: absolute;
    display: block;
    background-color: inherit;
    right: 0;
    bottom: -2.5px;
  }
}

.opacity {
  opacity: 0.5;
}
</style>
