var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"container"},[(_vm.isConnected)?_c('div',{staticClass:"row py-5"},[_c('user',{staticClass:"d-md-block",class:{
        'd-none':
          _vm.activeTransmissionListId ||
          _vm.activeConversation ||
          _vm.showCreateTransmission
      }}),(_vm.activeConversation && !_vm.showTransmissionList)?_c('chat-box',{attrs:{"activeConversation":_vm.activeConversation,"userSource":_vm.userSource,"userTarget":_vm.userTarget,"oldMessages":_vm.oldMessages,"newMessages":_vm.newMessages,"loaderConversation":_vm.loaderConversation},on:{"sendMessage":function (message) { return _vm.sendMessage(message); },"deleteMessage":function (body) { return _vm.deleteMessage(body); },"close":function($event){_vm.activeConversation = null}}}):(_vm.showTransmissionList && !_vm.activeTransmissionListId)?_c('create-transmission',{staticClass:"d-md-block",class:{
        'd-none': !_vm.showCreateTransmission
      },attrs:{"userSource":_vm.userSource},on:{"sendMessage":function (body) { return _vm.sendMessageTransmissionList(body); }}}):(_vm.showTransmissionList && _vm.activeTransmissionListId)?_c('transmission-list-chat',{attrs:{"activeTransmissionListId":_vm.activeTransmissionListId,"userSource":_vm.userSource,"getTransmissionMessages":_vm.getTransmissionMessages},on:{"sendMessage":function (body) { return _vm.sendMessageTransmissionList(body); },"close":function($event){return _vm.$store.commit('UPDATE_ACTIVE_TRANSMISSION_LIST_ID', null)}}}):_vm._e()],1):_c('div',{staticClass:"d-flex justify-content-center py-5"},[_c('loader')],1)])}
var staticRenderFns = []

export { render, staticRenderFns }