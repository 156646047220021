<template>
  <div :id="mobile ? 'box-mobile' : 'box'">
    <div @click="handleClick" class="bell-notification ml-3 mr-4">
      <img :class="{ active: !show }" src="@/img/bell.svg" />
      <img :class="{ active: show }" src="@/img/bell-blue.svg" />
      <div v-if="count > 0" :class="{ countNotifications: count > 99 }">
        {{ count }}
      </div>
    </div>
    <div :class="{ active: show, mobile: mobile }" class="wrapper">
      <div class="card-b py-4">
        <h2
          class="h5 font-weight-bold mb-0 pb-0 px-3"
          :style="{ color: $store.getters.theme[3] }"
        >
          {{ translation.notifications.notifications }}
        </h2>
        <div class="box-notification mt-3" v-if="notifications.length">
          <div
            @mouseover="mouseOver"
            @mouseleave="mouseLeave"
            class="item px-3 py-2"
            v-for="(notification, index) in notifications"
            :key="index"
          >
            <div @click="markNotification(notification)">
              <div class="d-flex align-items-center">
                <div class="notification-pic">
                  <img
                    v-if="notification.originUser.picture"
                    :src="notification.originUser.picture"
                    alt="Picture"
                  />
                  <b-icon
                    v-else
                    class="icon-profile"
                    icon="person-fill"
                    aria-hidden="true"
                  ></b-icon>
                </div>
                <div>
                  <sub-replies
                    v-if="
                      +notification.triggeredBy.id ===
                        notificationTypes.subReplies
                    "
                    :notification="notification"
                  />
                  <forum-new-post
                    v-if="
                      +notification.triggeredBy.id ===
                        notificationTypes.forumNewPost
                    "
                    :notification="notification"
                  />
                  <forum-new-post-comment
                    v-if="
                      +notification.triggeredBy.id ===
                        notificationTypes.forumNewPostComment
                    "
                    :notification="notification"
                  />
                  <forum-comment-of-comment
                    v-if="
                      +notification.triggeredBy.id ===
                        notificationTypes.forumCommentOfComment
                    "
                    :notification="notification"
                  />
                  <forum-like-post
                    v-if="
                      +notification.triggeredBy.id ===
                        notificationTypes.forumLikePost
                    "
                    :notification="notification"
                  />
                  <forum-like-comment
                    v-if="
                      +notification.triggeredBy.id ===
                        notificationTypes.forumLikeComment
                    "
                    :notification="notification"
                  />
                  <answer-rejected
                    v-if="
                      +notification.triggeredBy.id ===
                        notificationTypes.answerRejected
                    "
                    :notification="notification"
                  />
                  <new-assignment-available
                    v-if="
                      +notification.triggeredBy.id ===
                        notificationTypes.newAssignmentAvailable
                    "
                    :notification="notification"
                  />
                  <forum-comment-rejected
                    v-if="
                      +notification.triggeredBy.id ===
                        notificationTypes.forumCommentRejected
                    "
                    :notification="notification"
                  />
                  <assignment-updated
                    v-if="
                      +notification.triggeredBy.id ===
                        notificationTypes.assignmentUpdated
                    "
                    :notification="notification"
                  />
                  <created-community
                    v-if="
                      +notification.triggeredBy.id ===
                        notificationTypes.createdCommunity
                    "
                    :notification="notification"
                  />
                  <terms-and-conditions
                    v-if="
                      +notification.triggeredBy.id ===
                        notificationTypes.termsAndConditions
                    "
                    :notification="notification"
                  />
                </div>
              </div>
              <div
                :style="{
                  'background-color':
                    +notification.notificate === 1
                      ? $store.getters.theme[2]
                      : '#e6e6e6'
                }"
                class="blue-ball"
              ></div>
            </div>
          </div>
        </div>
        <div class="d-flex justify-content-center py-5" v-else>
          {{ translation.notifications.no_notifications }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { api } from "@/services.js";
import subReplies from "@/components/notification/subReplies.vue";
import forumNewPost from "@/components/notification/forumNewPost.vue";
import forumNewPostComment from "@/components/notification/forumNewPostComment.vue";
import forumCommentOfComment from "@/components/notification/forumCommentOfComment.vue";
import forumLikePost from "@/components/notification/forumLikePost.vue";
import forumLikeComment from "@/components/notification/forumLikeComment.vue";
import answerRejected from "@/components/notification/answerRejected.vue";
import newAssignmentAvailable from "@/components/notification/newAssignmentAvailable.vue";
import forumCommentRejected from "@/components/notification/forumCommentRejected.vue";
import assignmentUpdated from "@/components/notification/assignmentUpdated.vue";
import createdCommunity from "@/components/notification/createdCommunity.vue";
import termsAndConditions from "@/components/notification/termsAndConditions.vue";
import { notificationTypes, userTypes, themes } from "@/constants";
import { outsideClick } from "@/helpers.js";
import { mapState } from "vuex";

export default {
  props: {
    mobile: {
      default: false
    }
  },
  components: {
    subReplies,
    forumNewPost,
    forumNewPostComment,
    forumCommentOfComment,
    forumLikePost,
    forumLikeComment,
    answerRejected,
    newAssignmentAvailable,
    forumCommentRejected,
    assignmentUpdated,
    createdCommunity,
    termsAndConditions
  },
  data() {
    return {
      count: 0,
      show: false,
      notifications: [],
      interval: null,
      outsideClick,
      notificationTypes,
      userTypes,
      themes
    };
  },
  computed: {
    ...mapState(["updateNotification"])
  },
  watch: {
    updateNotification() {
      this.getNotifications();
    }
  },
  methods: {
    markNotification(notification) {
      const isNotificate = +notification.notificate;

      if (isNotificate) {
        api
          .put(
            `notification/${notification.id}`,
            {},
            {
              headers: {
                Authorization: `Bearer ${this.$store.getters.token}`
              }
            }
          )
          .then(r => {
            if (r.status === 200) {
              this.getNotifications();
              this.openNotification(notification);
              this.show = false;
            }
          });
      } else {
        this.openNotification(notification);
      }
    },
    openNotification(notification) {
      const notificationType = +notification.triggeredBy.id;

      if (
        notificationType === this.notificationTypes.subReplies ||
        notificationType === this.notificationTypes.answerRejected ||
        notificationType === this.notificationTypes.assignmentUpdated
      ) {
        const scroll =
          notificationType === this.notificationTypes.subReplies ? true : false;

        const firstP =
          this.userTypes.participant === notification.user_origin_type
            ? notification.user_origin_id
            : notification.user_target_id;

        this.goToAssignment(
          +notification.source.index,
          notification.source.activity.id,
          scroll,
          firstP
        );
      } else if (
        notificationType === this.notificationTypes.forumNewPost ||
        notificationType === this.notificationTypes.forumNewPostComment ||
        notificationType === this.notificationTypes.forumCommentOfComment ||
        notificationType === this.notificationTypes.forumLikePost ||
        notificationType === this.notificationTypes.forumLikeComment ||
        notificationType === this.notificationTypes.forumCommentRejected
      ) {
        this.goToForum(notification.source.post_source_id);
      } else if (
        notificationType === this.notificationTypes.newAssignmentAvailable
      ) {
        this.goToAssignment(0, notification.source.id);
      } else if (notificationType === this.notificationTypes.createdCommunity) {
        this.goToCommunity();
      } else if (
        notificationType === this.notificationTypes.termsAndConditions
      ) {
        this.goToTermsConditions();
      }
    },
    mouseOver(e) {
      e.currentTarget.style.background = this.$store.getters.theme[0];
    },
    mouseLeave(e) {
      e.currentTarget.style.background = "white";
    },
    getNotifications() {
      api
        .get(`notification/community/${this.$store.getters.community.id}`, {
          headers: {
            Authorization: `Bearer ${this.$store.getters.token}`
          }
        })
        .then(r => {
          if (r.status === 200) {
            this.notifications = r.data;
            this.count = this.notifications.filter(
              notification => +notification.notificate === 1
            ).length;
          }
        });
    },
    handleClick() {
      const element = this.mobile ? "box-mobile" : "box";
      this.show = !this.show;

      const item = document.querySelector(`#${element}`);
      this.outsideClick(item, ["click"], () => {
        this.show = false;
      });
    },
    goToAssignment(page, id, scroll, user) {
      if (id !== this.$route.query._activity) {
        this.$router.push({
          name: "tasks",
          query: {
            _activity: id,
            _pageParticipants: 1,
            _pageReplies: 1,
            _page: page,
            _scroll: scroll,
            _firstP: user
          }
        });
      } else {
        this.$router.push({
          query: {
            ...this.$route.query,
            _page: page,
            _scroll: scroll,
            _firstP: user
          }
        });
      }
    },
    goToForum(postId) {
      if (postId !== this.$route.query.pId) {
        this.$router.push({
          name: "post",
          query: {
            pId: postId
          }
        });
      }
    },
    goToCommunity() {
      this.$router.push({ name: "feed" });
    },
    goToTermsConditions() {
      this.$router.push({
        name: "terms-and-policy"
      });
    },
    init() {
      this.getNotifications();
    }
  },
  created() {
    this.init();
  },
  mounted() {
    this.interval = setInterval(this.init, 60000);
  },
  beforeDestroy() {
    clearInterval(this.interval);
  }
};
</script>

<style lang="scss" scoped>
.bell-notification {
  position: relative;
  cursor: pointer;
  height: 31.65px;
  width: 30px;
  img {
    position: absolute;
    top: 0;
    right: 0;
    opacity: 0;
    height: 100%;
    width: 100%;
    transition: all 0.5s ease;
    &.active {
      opacity: 1;
      transform: scale(1.01);
    }
  }
  div {
    position: absolute;
    top: -1px;
    right: 1.5px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #e80909;
    color: #ffffff;
    width: 13px;
    height: 13px;
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
    font-size: 0.625rem;
    font-weight: bold;
    border-radius: 50%;
    &.countNotifications {
      width: 18px;
      height: 18px;
    }
  }
}

.wrapper {
  position: absolute;
  z-index: 100;
  display: none;
  height: 406px;
  width: 300px;
  margin-right: 10px;
  animation: expand 0.5s;
  right: 0;
  margin-top: 50px;

  &.active {
    display: block;
  }
  &.mobile {
    width: 280px;
  }
  .card-b {
    height: 100%;
    padding-right: 3px;
    overflow: hidden;
  }
}

.box-notification {
  width: 100%;
  height: 90%;
  overflow-y: auto;

  &::-webkit-scrollbar {
    width: 8px;
  }
  &::-webkit-scrollbar-track {
    background: rgba(0, 0, 0, 0);
    border-radius: 10px;
  }
  &::-webkit-scrollbar-thumb {
    background: #e6e6e6;
    border-radius: 10px;
    height: 40px;
  }
  &::-webkit-scrollbar-thumb:hover {
    background: #ffffff;
  }
}

.item {
  > div {
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
    transition: all 0.5s ease;

    .blue-ball {
      width: 10px;
      height: 10px;
      border-radius: 50%;
    }
  }
}

.notification-pic {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  overflow: hidden;
  background: #e6e6e6;
  margin-right: 10px;
  flex-shrink: 0;
  img {
    height: 100%;
  }
}

@media (max-width: 576.98px) {
  .notification-pic {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 45px;
    height: 45px;
    border-radius: 50%;
    overflow: hidden;
    background: #e6e6e6;
    margin-right: 10px;
    img {
      height: 100%;
    }
  }
}
</style>
