var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"user-list ml-md-4 ml-2"},[_c('div',{staticClass:"input"},[_c('b-form-input',{attrs:{"size":"sm","placeholder":_vm.translation.global.search},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c('b-icon',{staticClass:"mx-2",attrs:{"icon":"search","aria-hidden":"true"}})],1),(!_vm.loaderUsers && _vm.usersSearch.length)?_c('div',{staticClass:"users"},[_c('div',{staticClass:"users-scroll"},_vm._l((_vm.usersSearch),function(user){return _c('button',{key:user.id,on:{"click":function($event){_vm.chatSelectedUser = user}}},[_c('div',{staticClass:"profile-img-wrapper"},[(_vm.getNotification(user.id))?_c('div',{staticClass:"notification"},[_vm._v(" "+_vm._s(_vm.getNotification(user.id))+" ")]):_vm._e(),_c('div',{staticClass:"profile-img",style:({
              color: _vm.$store.getters.theme[3]
            })},[(user.picture && user.picture.length)?_c('img',{attrs:{"src":user.picture,"alt":"Profile Picture"}}):_c('b-icon',{staticClass:"icon-profile",attrs:{"icon":"person-fill","aria-hidden":"true"}})],1)]),_c('div',[_c('p',[(
                _vm.$store.getters.info.user.type === _vm.userTypesText.moderator &&
                  !user.operator_type_id
              )?_c('span',[_vm._v(" "+_vm._s(_vm.namePrivacy( _vm.privacy.moderators.participants_identifier_type_id, user.name, user.id ))+" ")]):(
                _vm.$store.getters.info.user.type === _vm.userTypesText.observer &&
                  !user.operator_type_id
              )?_c('span',[_vm._v(" "+_vm._s(_vm.namePrivacy( _vm.privacy.observers.participants_identifier_type_id, user.name, user.id ))+" ")]):_c('span',[_vm._v(" "+_vm._s(user.name)+" ")])]),_c('p',[_vm._v("ID "+_vm._s(user.id))])])])}),0)]):(_vm.loaderUsers)?_c('div',{staticClass:"py-5 d-flex justify-content-center"},[_c('loader2',{attrs:{"color":_vm.$store.getters.theme[2]}})],1):_c('div',{staticClass:"text py-5 d-flex justify-content-center"},[_vm._v(" "+_vm._s(_vm.translation.chat.no_users)+" ")])])}
var staticRenderFns = []

export { render, staticRenderFns }