var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:("" + (_vm.$store.getters.info &&
      _vm.$store.getters.info.user.type === _vm.userTypesText.participant
        ? 'block-ps'
        : '')),style:({
    background:
      _vm.hideComponent && _vm.$store.getters.theme
        ? _vm.$store.getters.theme[0]
        : 'white'
  }),attrs:{"id":"app"}},[(
      _vm.hideComponent &&
        _vm.$store.getters.info &&
        +_vm.$store.getters.info.user.change_password &&
        _vm.$store.getters.info.user.type !== _vm.userTypesText.participant
    )?_c('first-login'):_vm._e(),(_vm.hideComponent && _vm.$store.getters.theme)?_c('cHeader'):_vm._e(),(_vm.$store.getters.theme)?_c('main',{attrs:{"id":"main"}},[_c('transition',{attrs:{"mode":"out-in"}},[_c('router-view')],1)],1):_vm._e(),(
      _vm.hideComponent &&
        _vm.$store.getters.info &&
        _vm.$store.getters.info.user.type === _vm.userTypesText.participant
    )?_c('chat-participant'):_vm._e(),(
      _vm.hideComponent && _vm.$route.name !== 'communities' && _vm.$store.getters.theme
    )?_c('cFooter'):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }