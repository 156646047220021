<template>
  <div>
    <imageCropper
      :img="img.code"
      :show="showCropper"
      :loader="loaderInsertImg"
      @updateImg="img => insertImg(img)"
      @trash="close"
    />
    <p class="label mt-4">
      {{ translation.create_task.reaction.select_image }}:
    </p>

    <div class="image-reaction mb-5">
      <div
        :style="{
          width: `${imageWidth + 40}px`,
          height: `${imageHeight + 40}px`
        }"
        class="scroll"
      >
        <label
          for="reaction-img"
          class="input"
          :style="{
            width: `${imageWidth}px`,
            height: `${imageHeight}px`,
            'background-color': $store.getters.theme[0]
          }"
        >
          <div class="img" v-if="img.code.length">
            <img
              :style="{ width: `${imageWidth}px`, height: `${imageHeight}px` }"
              ref="img"
              :src="img.code"
            />
          </div>
          <input
            ref="reactionImg"
            :style="{ width: `${imageWidth}px`, height: `${imageHeight}px` }"
            @change="uploadImg"
            id="reaction-img"
            class="d-none"
            type="file"
            accept="image/*"
          />
        </label>
      </div>
    </div>
    <div class="d-flex align-items-center justify-content-end mt-3">
      <div
        :class="{ active: reactionError }"
        class="error-box bg-danger p-2 mr-3"
      >
        <b-icon class="mr-1" icon="exclamation-triangle-fill"></b-icon>
        {{ reactionError }}
      </div>
    </div>
    <div class="reaction-options" v-if="reactionOptions.length">
      <div
        v-for="(option, index) in reactionOptions"
        :key="index"
        :class="{ disabled: !option.active }"
        class="reaction-option py-2 px-2 px-md-3"
      >
        <div>
          <div>
            {{ index + 1 }}
          </div>
          <div>
            <label :for="`emoji-${index}`">Emoji:</label>
            <input
              :style="{
                background: option.active ? $store.getters.theme[0] : '#efefef'
              }"
              type="text"
              autocomplete="off"
              v-model="option.icon"
              @keypress.prevent
              @paste.prevent
              :id="`emoji-${index}`"
              @click="showEmojiPicker(option, index)"
              @keyup="addReactionOption(index)"
            />
          </div>
          <div @click="addReactionOption(index)">
            <label :for="`label-${index}`">
              {{ translation.create_task.reaction.reaction_label }}:
            </label>
            <input
              :style="{
                background: option.active ? $store.getters.theme[0] : '#efefef'
              }"
              :class="{
                ['text-danger']: redText && index !== reactionOptions.length - 1
              }"
              class="px-2"
              autocomplete="off"
              v-model="option.label"
              :id="`label-${index}`"
              type="text"
              maxlength="255"
              @keyup="addReactionOption(index)"
              @click="addReactionOption(index)"
            />
          </div>
          <div
            class="p-2"
            v-if="option.active && reactionOptions.length > 3"
            @click="removeReactionOption(index)"
          >
            <b-icon icon="x"></b-icon>
          </div>
        </div>
        <div class="emoji-select mt-4">
          <VEmojiPicker
            v-if="option.showEmoji"
            @select="emoji => selectEmoji(emoji, option)"
          />
          <div v-if="option.showEmoji" @click="option.showEmoji = false">
            <b-icon icon="x"></b-icon>
          </div>
        </div>
      </div>
    </div>
    <div class="min-max mt-4 mb-3">
      <div class="mb-3">
        <label for="min">
          {{ translation.create_task.reaction.minimum_number }}:
        </label>
        <b-form-input
          v-model="min"
          name="min"
          type="number"
          min="1"
          :max="max"
          size="sm"
        ></b-form-input>
      </div>
      <div>
        <label for="max">
          {{ translation.create_task.reaction.maximum_number }}:
        </label>
        <b-form-input
          v-model="max"
          name="max"
          type="number"
          min="1"
          size="sm"
        ></b-form-input>
      </div>
    </div>
    <div class="switch d-flex">
      <span class="pr-2">
        {{ translation.create_task.reaction.mandatory_comment }}:</span
      >
      <b-form-checkbox v-model="mandatoryComment" name="check-button" switch>
      </b-form-checkbox>
    </div>
  </div>
</template>

<script>
import { v4 as uuidv4 } from "uuid";
import { api } from "@/services.js";
import lambda from "@/lambda";
import imageCropper from "@/components/general/imageCropper.vue";
import { taskEnum, regexpImageName, addTaskText } from "@/constants";
import { VEmojiPicker } from "v-emoji-picker";
import { mapState } from "vuex";

export default {
  props: {
    taskText: {
      required: true
    },
    task: {
      required: true
    }
  },
  components: { imageCropper, VEmojiPicker },
  data() {
    return {
      img: {
        code: "",
        name: ""
      },
      showCropper: false,
      loaderInsertImg: false,
      reactionOptions: [],
      imageWidth: 800,
      imageHeight: 400,
      min: 1,
      max: 10,
      mandatoryComment: false,
      taskEnum,
      regexpImageName,
      addTaskText
    };
  },
  computed: {
    ...mapState(["imageUploaderFolder"]),
    taskObj() {
      return {
        statement: this.taskText,
        options: this.reactionOptions
          .filter(option => option.active)
          .map((option, index) => ({
            label: option.label,
            icon: option.icon,
            index: index
          })),
        image: this.img.code,
        image_name: this.img.name,
        image_width: this.imageWidth,
        image_height: this.imageHeight,
        min_answers: +this.min,
        max_answers: +this.max,
        mandatory_comment: this.mandatoryComment
      };
    },
    redText() {
      let resp = false;
      if (
        this.hasDuplicate(this.reactionOptions, "label") ||
        this.hasEmpty(this.reactionOptions) ||
        this.hasZeros(this.reactionOptions)
      )
        resp = true;
      return resp;
    },
    reactionError() {
      let message = null;
      if (this.hasEmpty(this.reactionOptions))
        message = this.translation.create_task.reaction.error.empty_option;
      else if (this.hasZeros(this.reactionOptions))
        message = this.translation.create_task.error.the_field_cannot;
      else if (this.hasDuplicate(this.reactionOptions, "label"))
        message = this.translation.create_task.reaction.error.duplicated_labels;
      else if (this.hasDuplicate(this.reactionOptions, "icon"))
        message = this.translation.create_task.reaction.error.duplicated_emojis;
      else if (!this.img.code.length)
        message = this.translation.create_task.reaction.error.no_image;
      else if (+this.min > this.max)
        message = this.translation.create_task.reaction.error.bigger;
      else if (this.min === "")
        message = this.translation.create_task.reaction.error.no_minimum;
      else if (this.max === "")
        message = this.translation.create_task.reaction.error.no_maximum;

      return message;
    }
  },
  watch: {
    taskObj() {
      this.$emit("update", this.taskObj);
    },
    reactionError() {
      this.$emit("error", this.reactionError);
    }
  },
  methods: {
    hasZeros(array) {
      return array.some(option => option.label === "0");
    },
    hasEmpty(array) {
      return array.some(option => !option.label.length && option.active);
    },
    hasDuplicate(array, key) {
      const valuesSoFar = [];
      for (let i = 0; i < array.length; ++i) {
        const value = array[i][key].trim();
        if (valuesSoFar.indexOf(value) !== -1) {
          return true;
        }
        valuesSoFar.push(value);
      }
      return false;
    },
    close() {
      this.showCropper = false;
      this.img.code = "";
      this.img.name = "";
      this.$refs.reactionImg.value = "";
    },
    async uploadImg() {
      const file = this.$refs.reactionImg.files[0];
      if (file) {
        const toBase64 = file =>
          new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => resolve(reader.result);
            reader.onerror = error => reject(error);
          });
        this.img.code = await toBase64(file);
        this.img.name = file.name;
        this.showCropper = true;
      }
    },
    insertImg(src) {
      this.loaderInsertImg = true;

      let arr = src.split(",");
      let mime = arr[0].match(/:(.*?);/)[1];
      let bstr = atob(arr[1]);
      let n = bstr.length;
      let u8arr = new Uint8Array(n);

      while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
      }

      const imgName = `${uuidv4()}-${this.img.name.replace(
        this.regexpImageName,
        ""
      )}`;

      const imgFile = new File([u8arr], imgName, {
        type: mime
      });

      lambda
        .getSignedURL(
          imgFile,
          `tmp/images/${this.imageUploaderFolder}`,
          imgName
        )
        .then(url => {
          api
            .put(url, imgFile, {
              headers: {
                "Content-Type": imgFile.type
              }
            })
            .then(r => {
              const src = r.config.url.split("?")[0];
              this.img.code = src;
              this.img.name = imgName;
              this.showCropper = false;
              this.$refs.reactionImg.value = "";

              const url = src;
              const img = new Image();
              img.onload = () => {
                const ratioWidth = img.width / img.height;
                const ratioHeight = img.height / img.width;

                if (img.width > 800) {
                  this.imageWidth = 800;
                  this.imageHeight = 800 / ratioWidth;
                  if (img.height > 600) {
                    this.imageWidth = 600 / ratioHeight;
                    this.imageHeight = 600;
                  }
                }

                if (img.height > 600) {
                  this.imageWidth = 600 / ratioHeight;
                  this.imageHeight = 600;
                  if (img.width > 800) {
                    this.imageWidth = 800;
                    this.imageHeight = 800 / ratioWidth;
                  }
                }

                if (img.height <= 600 && img.width <= 800) {
                  this.imageWidth = img.width;
                  this.imageHeight = img.height;
                }
              };
              img.src = url;

              this.loaderInsertImg = false;
            })
            .catch(() => {
              this.$toast.error(
                this.translation.errors_global.something_went_wrong
              );
              this.loaderInsertImg = false;
            });
        })
        .catch(() => {
          this.$toast.error(
            this.translation.errors_global.something_went_wrong
          );
          this.loaderInsertImg = false;
        });
    },
    addReactionOption(index) {
      if (!this.reactionOptions[index].active) {
        this.reactionOptions[index].active = true;

        this.reactionOptions.push({
          label: "",
          icon: "",
          showEmoji: false,
          active: false
        });
      }
    },
    removeReactionOption(index) {
      if (this.reactionOptions.length > 3)
        this.reactionOptions.splice(index, 1);
    },
    showEmojiPicker(option, index) {
      option.showEmoji = !option.showEmoji;
      this.addReactionOption(index);
    },
    selectEmoji(emoji, option) {
      option.icon = emoji.data;
      option.showEmoji = false;
    },
    getDescription() {
      if (
        this.task !== this.addTaskText &&
        +this.task.type_id === this.taskEnum.reactions.id
      ) {
        this.img.code = this.task.question_object.image;
        this.img.name = this.task.question_object.image_name;
        this.imageWidth = this.task.question_object.image_width;
        this.imageHeight = this.task.question_object.image_height;
        this.min = this.task.question_object.min_answers
          ? this.task.question_object.min_answers
          : 1;
        this.max = this.task.question_object.max_answers
          ? this.task.question_object.max_answers
          : 10;
        this.mandatoryComment = this.task.question_object.mandatory_comment;
        this.reactionOptions = this.task.question_object.options.map(
          option => ({
            label: option.label,
            icon: option.icon,
            showEmoji: false,
            active: true
          })
        );

        this.reactionOptions.push({
          label: "",
          icon: "",
          showEmoji: false,
          active: false
        });
      } else {
        this.setTask();
      }
      this.$emit("update", this.taskObj);
    },
    setTask() {
      this.reactionOptions = [
        {
          label: `${this.translation.create_task.reaction.option} 1`,
          icon: "🙂",
          showEmoji: false,
          active: true
        },
        {
          label: `${this.translation.create_task.reaction.option} 2`,
          icon: "🙁",
          showEmoji: false,
          active: true
        },
        {
          label: "",
          icon: "",
          showEmoji: false,
          active: false
        }
      ];

      this.min = 1;
      this.max = 10;
    },
    init() {
      if (this.task === this.addTaskText) {
        this.setTask();
      }
    }
  },
  created() {
    this.init();
    this.getDescription();
    this.$emit("error", this.reactionError);
  }
};
</script>

<style lang="scss" scoped>
.label {
  font-weight: bold;
  color: #6b6b6b;
}

.reaction-options {
  display: flex;
  flex-wrap: wrap;
}

.reaction-option {
  width: calc(50% - 10px);
  animation: enter 0.3s ease-in-out forwards;
  margin-top: 20px;
  border-radius: 20px;
  border: 1px solid #efefef;
  padding: 5px;
  position: relative;
  &:nth-of-type(n) {
    margin-right: 10px;
  }
  > div:nth-of-type(1) {
    display: flex;
    justify-content: space-between;
    align-items: center;
    div {
      &:nth-of-type(1) {
        flex-shrink: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 30px;
        height: 30px;
        border: 2px solid #dbecec;
        border-radius: 13px;
        color: #6b6b6b;
        font-weight: bold;
        margin-right: 5px;
        margin-right: 20px;
      }
      &:nth-of-type(2) {
        margin-right: 10px;
        input {
          text-align: center;
          width: 80px;
          font-size: 2rem;
        }
      }
      &:nth-of-type(3) {
        width: 100%;
      }
      &:nth-of-type(4) {
        color: #6b6b6b;
        margin-left: 5px;
        font-size: 1.2rem;
        cursor: pointer;
        position: absolute;
        top: 0;
        right: 0;
      }
    }
    label {
      display: inline-block;
      font-weight: bold;
      color: #6b6b6b;
      white-space: nowrap;
    }
    input {
      display: block;
      color: #6b6b6b;
      font-size: 0.75rem;
      border: none;
      width: 100%;
      border-radius: 10px;
      height: 50px;
      &:focus {
        outline: none;
      }
    }
  }
  &.disabled {
    opacity: 0.5;
    > div:nth-of-type(1) {
      div {
        &:nth-of-type(1) {
          color: #ddd;
        }
      }
      label {
        color: #ddd;
      }
      input {
        color: #ddd;
      }
    }
  }
}

@media (max-width: 991.98px) {
  .reaction-option {
    width: 100%;
    > div:nth-of-type(1) {
      flex-direction: column;
      div {
        &:nth-of-type(2) {
          margin-top: 10px;
          order: 2;
        }
        &:nth-of-type(3) {
          margin-top: 10px;
        }
      }
    }
  }
}

.image-reaction {
  display: flex;
  justify-content: center;

  .input {
    display: block;
    background: url(../../img/image.svg) no-repeat center;
    background-size: contain;
    cursor: pointer;
    padding: 0;
    border-radius: 20px;
    input {
      display: none;
    }
    .img {
      display: flex;
      align-items: center;
      justify-content: center;
      img {
        object-fit: cover;
        background-color: white;
        border-radius: 20px;
      }
    }
  }
}

.scroll {
  overflow: auto;
  display: flex;
  justify-content: center;
  &::-webkit-scrollbar {
    height: 8px;
    width: 8px;
    overflow: hidden;
  }
  &::-webkit-scrollbar-track {
    background: rgba(0, 0, 0, 0);
    border-radius: 10px;
  }
  &::-webkit-scrollbar-thumb {
    background: #ccc;
    border-radius: 10px;
    height: 10px;
  }
  &::-webkit-scrollbar-thumb:hover {
    background: #aaa;
  }
}

.emoji-select {
  display: flex;
  margin-left: 30px;
  div {
    color: #6b6b6b;
    font-size: 1.2rem;
    cursor: pointer;
    margin-left: 20px;
  }
}

@media (max-width: 991.98px) {
  .emoji-select {
    justify-content: center;
    margin-left: 0px;
    div {
      margin-left: 5px;
    }
  }
}

.min-max {
  color: #6b6b6b;
  > div {
    display: flex;
    align-items: center;
    label {
      margin-bottom: 0;
    }
    input {
      max-width: 100px;
      margin-left: 10px;
    }
  }
}

.switch {
  color: #6b6b6b;
}
</style>
