<template>
  <div class="notification">
    <h3
      v-if="userTypes.participant === notification.user_origin_type"
      class="mr-2"
    >
      <span v-if="$store.getters.info.user.type === userTypesText.moderator">
        {{
          namePrivacy(
            privacy.moderators.participants_identifier_type_id,
            notification.originUser.name,
            notification.originUser.id
          )
        }}
      </span>
      <span
        v-else-if="$store.getters.info.user.type === userTypesText.observer"
      >
        {{
          namePrivacy(
            privacy.observers.participants_identifier_type_id,
            notification.originUser.name,
            notification.originUser.id
          )
        }}
      </span>
      <span v-else>
        {{ notification.originUser.name }}
      </span>
      {{ translation.notifications.liked_post }}
    </h3>
    <h3 v-else class="mr-2">
      <span v-if="$store.getters.info.user.type === userTypesText.participant">
        {{
          namePrivacy(
            privacy.participants.admins_identifier_type_id,
            notification.originUser.name,
            notification.originUser.id
          )
        }}
      </span>
      <span v-else>
        {{ notification.originUser.name }}
      </span>
      {{ translation.notifications.liked_post }}
    </h3>
    <p>
      {{ translation.notifications.forum }}
      - {{ notification.source.title }}
    </p>
  </div>
</template>

<script>
import { userTypes, userTypesText } from "@/constants";
import { namePrivacy } from "@/helpers";
import { mapState } from "vuex";

export default {
  props: {
    notification: {
      required: true
    }
  },

  data() {
    return {
      userTypes,
      userTypesText
    };
  },

  computed: {
    ...mapState(["privacy"])
  },

  methods: {
    namePrivacy
  }
};
</script>

<style lang="scss" scoped>
.notification {
  h3 {
    color: #6b6b6b;
    font-size: 0.9rem;
    font-weight: bold;
    max-width: 170px;
    margin: 0;
  }
  p {
    color: #999999;
    font-weight: 0.875rem;
    max-width: 170px;
    margin: 0;
  }
}

@media (max-width: 576.98px) {
  .notification {
    h3 {
      color: #6b6b6b;
      font-size: 0.82rem !important;
      font-weight: bold;
      max-width: 170px;
      margin: 0;
    }
    p {
      color: #999999;
      font-size: 0.8rem !important;
      font-weight: 0.7rem !important;
      max-width: 170px;
      margin: 0;
    }
  }
}
</style>
