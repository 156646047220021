<template>
  <div class="row task-wrapper" id="task">
    <div v-if="!loading" class="col">
      <div class="task-text img-question">
        <p v-html="question"></p>
      </div>
      <div class="d-flex flex-wrap">
        <attachment :attachments="task.attachments" :deleteFile="null" />
      </div>
      <div class="editor pt-3">
        <div v-if="disabled" class="disabled"></div>
        <div class="d-flex justify-content-end mb-2">
          <uploader :image="true" />
        </div>
        <editor
          placeholder=""
          :text="html"
          :hasCharactersLimitation="hasCharactersLimitation"
          :maxCharacters="maxCharacters"
          :minCharacters="minCharacters"
          :hasWordsLimitation="hasWordsLimitation"
          :maxWords="maxWords"
          :minWords="minWords"
          :isTextTask="true"
          @charactersCount="count => (charactersCount = count)"
          @update="text => (html = text)"
          @imageError="msg => (imageMessage = msg)"
        />
      </div>
    </div>
    <div v-if="loading" class="w-100 d-flex justify-content-center">
      <loader />
    </div>
  </div>
</template>

<script>
import { v4 as uuidv4 } from "uuid";
import { api } from "@/services.js";
import editor from "@/components/general/editor.vue";
import loader from "@/components/general/loader.vue";
import uploader from "@/components/general/uploader.vue";
import attachment from "@/components/general/attachment.vue";
import { encodeImage } from "@/helpers.js";
import { regexpClearTags } from "@/constants";
import { mapState } from "vuex";

import { userTypesText } from "@/constants";

export default {
  components: {
    loader,
    editor,
    uploader,
    attachment
  },
  props: {
    task: {
      required: true
    },
    save: {
      required: true
    },
    savePreview: {
      required: true
    },
    getTasks: {
      required: true
    }
  },
  data() {
    return {
      html: "",
      charactersCount: 0,
      question: "",
      disabled: false,
      imageMessage: null,
      loading: false,
      regexpClearTags,
      encodeImage
    };
  },
  computed: {
    ...mapState(["imageUploaderFolder"]),
    page() {
      return this.$store.getters.activeTaskIndex;
    },

    hasCharactersLimitation() {
      return this.task.question_object?.has_characters_limitation;
    },

    maxCharacters() {
      return this.hasCharactersLimitation
        ? this.task.question_object?.characters_count?.maximum
        : 5000;
    },

    minCharacters() {
      return this.hasCharactersLimitation
        ? this.task.question_object?.characters_count?.minimum
        : 10;
    },

    hasWordsLimitation() {
      return this.task.question_object?.has_words_limitation;
    },

    maxWords() {
      return this.hasWordsLimitation
        ? this.task.question_object?.words_count?.maximum
        : 2000;
    },

    minWords() {
      return this.hasWordsLimitation
        ? this.task.question_object?.words_count?.minimum
        : 2;
    }
  },
  watch: {
    save() {
      this.answer();
    },
    savePreview() {
      this.answerPreview();
    },
    page() {
      this.init();
    }
  },
  methods: {
    hasImage(text) {
      return text.match(/(?:(<img.*?src="))(.*?)((?:".*?\/>))/g);
    },
    answerPreview() {
      if (!this.loading) {
        if (!this.imageMessage) {
          if (
            (!this.hasWordsLimitation || this.hasCharactersLimitation) &&
            this.html.replace(this.regexpClearTags, "").length <
              this.minCharacters
          ) {
            this.$toast.error(
              `${this.translation.answer_task.error.answer_must_have} ${this.minCharacters} ${this.translation.answer_task.characters}`
            );

            return;
          }

          if (this.charactersCount > this.maxCharacters) {
            this.$toast.error(this.translation.errors_global.you_have_exceeded);

            return;
          }

          if (this.hasWordsLimitation) {
            const wordsCount = this.html
              .replace(this.regexpClearTags, "")
              .split(/\s+/).length;

            if (wordsCount < this.minWords) {
              this.$toast.error(
                `${this.translation.answer_task.min_word} ${this.minWords} ${this.translation.answer_task.words}`
              );

              return;
            }

            if (wordsCount > this.maxWords) {
              this.$toast.error(
                this.translation.errors_global.you_have_exceeded_word_limit
              );

              return;
            }
          }

          this.loading = true;

          let body = {
            task_id: this.task.id,
            has_video: 0,
            has_attachment: 0
          };

          let answer = this.html;

          if (this.hasImage(answer)) {
            answer = this.encodeImage(answer);
            body.has_image = 1;
            body.image_folder = `tmp/images/${this.imageUploaderFolder}`;
          } else {
            body.has_image = 0;
          }

          body.raw_answer = answer;

          const regexpImage = /(?:(<img.*?src="))(.*?)((?:".*?\/>))/g;
          const regexpVideo = /(?:(<iframe.*?src="))(.*?)((?:".*?>))((?:<\/iframe>))?/g;

          body.answer = {
            statement: answer
              .replace(regexpImage, "$2")
              .replace(regexpVideo, "$2")
              .replace(this.regexpClearTags, "")
          };

          api
            .post("/answer/send/preview", body, {
              headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${this.$store.getters.token}`
              }
            })
            .then(r => {
              if (r.status === 200) {
                this.$toast.success(
                  this.translation.answer_task.success.reply_registered
                );
                this.getTasks("save");
                this.loading = false;
              }
            })
            .catch(() => {
              this.$toast.error(
                this.translation.answer_task.error.reply_not_registered
              );
              this.loading = false;
            });
        } else {
          this.$toast.error(this.imageMessage);
        }
      }
    },
    answer() {
      if (!this.loading) {
        if (!this.imageMessage) {
          if (
            (!this.hasWordsLimitation || this.hasCharactersLimitation) &&
            this.html.replace(this.regexpClearTags, "").length <
              this.minCharacters
          ) {
            this.$toast.error(
              `${this.translation.answer_task.error.answer_must_have} ${this.minCharacters} ${this.translation.answer_task.characters}`
            );

            return;
          }

          if (this.charactersCount > this.maxCharacters) {
            this.$toast.error(this.translation.errors_global.you_have_exceeded);

            return;
          }

          if (this.hasWordsLimitation) {
            const wordsCount = this.html
              .replace(this.regexpClearTags, "")
              .split(/\s+/).length;

            if (wordsCount < this.minWords) {
              this.$toast.error(
                `${this.translation.answer_task.min_word} ${this.minWords} ${this.translation.answer_task.words}`
              );

              return;
            }

            if (wordsCount > this.maxWords) {
              this.$toast.error(
                this.translation.errors_global.you_have_exceeded_word_limit
              );

              return;
            }
          }

          this.loading = true;

          let body = {
            task_id: this.task.id,
            participant_id: this.$store.getters.info.user.sub,
            has_video: 0,
            has_attachment: 0
          };

          let answer = this.html;

          if (this.hasImage(answer)) {
            answer = this.encodeImage(answer);
            body.has_image = 1;
            body.image_folder = `tmp/images/${this.imageUploaderFolder}`;
          } else {
            body.has_image = 0;
          }

          body.raw_answer = answer;

          const regexpImage = /(?:(<img.*?src="))(.*?)((?:".*?\/>))/g;
          const regexpVideo = /(?:(<iframe.*?src="))(.*?)((?:".*?>))((?:<\/iframe>))?/g;

          body.answer = {
            statement: answer
              .replace(regexpImage, "$2")
              .replace(regexpVideo, "$2")
              .replace(this.regexpClearTags, "")
          };

          api
            .post("/answer/send", body, {
              headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${this.$store.getters.token}`
              }
            })
            .then(r => {
              if (r.status === 200) {
                this.$toast.success(
                  this.translation.answer_task.success.reply_registered
                );
                this.getTasks("save");
                this.loading = false;
              }
            })
            .catch(() => {
              this.$toast.error(
                this.translation.answer_task.error.reply_not_registered
              );
              this.loading = false;
            });
        } else {
          this.$toast.error(this.imageMessage);
        }
      }
    },
    initImage() {
      this.$store.commit(
        "UPDATE_IMAGE_UPLOADER_FOLDER",
        `send-answer-image-${uuidv4()}`
      );
    },
    init() {
      const regexpVideo = /(?:(<iframe.*?src="))(.*?)((?:".*?>))((?:<\/iframe>))?/g;

      if (this.$store.getters.info.user.type === userTypesText.participant) {
        this.question = this.task.raw_question.replace(
          regexpVideo,
          "<video src='$2' width='350' height='197' frameborder='0' allowfullscreen='allowfullscreen' controls controlsList='nodownload'></iframe>"
        );
      } else {
        this.question = this.task.raw_question;
      }

      if (this.task.is_answered) {
        this.html = this.task.answer.raw_answer;
        this.disabled = true;
      } else if (this.task.answer && this.$route.query._preview) {
        this.html = this.task.answer.raw_answer;
      } else {
        this.html = "";
        this.disabled = false;
        this.initImage();
      }
    }
  },

  mounted() {
    this.init();

    if (this.$store.getters.info.user.type === userTypesText.participant) {
      const taskWrapper = document.querySelector(".task-wrapper");

      taskWrapper.addEventListener("contextmenu", e => {
        e.preventDefault();
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.task-text {
  display: flex;
  flex-direction: column;
  color: #6b6b6b;
  overflow-wrap: break-word;

  p {
    .img-wrapper {
      display: inline-block;
      position: relative;

      .overlay-img {
        position: absolute;
        top: 0;
        right: 0;

        widows: 100%;
        height: 100%;
        background: red;
      }
    }
  }
}

.editor {
  position: relative;
}

.disabled {
  position: absolute;
  width: 100%;
  height: 100%;
  background: #fff;
  opacity: 0.3;
  z-index: 2;
}
</style>
