<template>
  <div v-if="isConnected" class="chat-wrapper">
    <imageCropper
      :img="img"
      :show="showCropper"
      :loader="loaderInsertImg"
      @updateImg="img => insertImg(img)"
      @trash="close"
    />
    <div v-if="!showChat" class="chat-button">
      <span v-if="notifications.length">{{ notifications.length }}</span>
      <button
        @click="showChat = true"
        :style="{ background: $store.getters.theme[2] }"
        class="btn"
      >
        <img src="@/img/chat-button.svg" alt="" />
      </button>
    </div>
    <div class="chat-box" :class="{ active: showChat }">
      <button
        @click="showChat = false"
        :style="{ background: $store.getters.theme[2] }"
        class="btn"
      >
        <b-icon icon="x"></b-icon>
      </button>
      <div class="chat-header">
        <span
          v-if="!activeConversation"
          :style="{ color: $store.getters.theme[3] }"
          >Chat</span
        >
        <a
          :style="{ color: $store.getters.theme[3] }"
          v-else
          @click="activeConversation = null"
        >
          <b-icon icon="arrow-left" class="mr-2"></b-icon>
          {{ translation.chat.view_operators }}
        </a>
      </div>
      <div v-if="!activeConversation" class="conversations">
        <div v-if="!loaderConversations">
          <button
            class="btn"
            v-for="conversation in conversations"
            :key="conversation.id"
            @click="getConversation(conversation)"
          >
            <user-info
              @user="user => (conversation.userSource = user)"
              :sourceId="conversation.userSourceId"
              :sourceType="conversation.userSourceType"
              :notifications="notifications"
            />
          </button>
        </div>
        <div
          v-else-if="loaderConversations"
          class="py-5 d-flex justify-content-center"
        >
          <loader2 :color="$store.getters.theme[2]" />
        </div>
        <div v-else class="py-5 d-flex justify-content-center">
          {{ translation.chat.no_users }}
        </div>
      </div>
      <div v-if="activeConversation && !loaderConversation" class="chat p-4">
        <div class="chat-user">
          <div
            class="profile-img"
            :style="{
              color: $store.getters.theme[3]
            }"
          >
            <img
              v-if="targetUser.picture && targetUser.picture.length"
              :src="targetUser.picture"
              alt="Profile Picture"
            />
            <b-icon
              v-else
              class="icon-profile"
              icon="person-fill"
              aria-hidden="true"
            ></b-icon>
          </div>
          <div>
            <p>
              {{
                namePrivacy(
                  privacy.participants.admins_identifier_type_id,
                  targetUser.name,
                  targetUser.id
                )
              }}
            </p>
            <p>ID {{ targetUser.id }}</p>
          </div>
        </div>

        <ul class="chat-scroll" ref="chatScroll">
          <li
            :class="{ right: messageObj.message.sourceID === activeUser.id }"
            v-for="messageObj in oldMessages"
            :key="messageObj.id"
          >
            <div
              v-if="messageObj.message.sourceID === activeUser.id"
              class="delete-message"
            >
              <button class="hover" @click="deleteMessage(messageObj.id)">
                <b-icon icon="trash" aria-hidden="true"></b-icon>
              </button>
            </div>
            {{ messageObj.message.text }}
            <img
              v-if="messageObj.message.image"
              :src="messageObj.message.image"
              alt=""
            />
          </li>
          <li
            :class="{ right: messageObj.message.sourceID === activeUser.id }"
            v-for="messageObj in newMessages"
            :key="messageObj.id"
          >
            <div
              v-if="messageObj.message.sourceID === activeUser.id"
              class="delete-message"
            >
              <button class="hover" @click="deleteMessage(messageObj.id)">
                <b-icon icon="trash" aria-hidden="true"></b-icon>
              </button>
            </div>
            {{ messageObj.message.text }}

            <img
              v-if="messageObj.message.image"
              :src="messageObj.message.image"
              alt=""
            />
          </li>
        </ul>

        <form class="send-message">
          <button
            v-if="img"
            @click="close"
            :style="{ background: $store.getters.theme[2] }"
            class="btn delete-img"
          >
            <b-icon icon="x"></b-icon>
          </button>
          <label for="image-chat">
            <div v-if="!img" class="add-img">
              <b-icon icon="card-image" aria-hidden="true"></b-icon>
            </div>
            <input
              ref="inputImg"
              @change="uploadImg('image-chat')"
              id="image-chat"
              class="d-none"
              type="file"
              accept="image/*"
            />
          </label>
          <div :style="{ padding: img.length ? '10px' : 0 }" class="textarea">
            <b-form-textarea
              ref="textarea"
              @keypress="submit"
              @keyup="fitToContent"
              class="px-3"
              size="sm"
              :placeholder="translation.chat.enter_a_message"
              v-model="message"
            ></b-form-textarea>
            <div class="img" v-if="img.length">
              <img :src="img" />
            </div>
          </div>
          <button
            ref="sendButton"
            @click.prevent="sendMessage"
            class="btn send"
            :style="{
              'background-color': $store.getters.theme[2]
            }"
          >
            <img src="@/img/send-message.svg" />
          </button>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import { v4 as uuidv4 } from "uuid";
import { api } from "@/services.js";
import axios from "axios";
import { userTypes } from "@/constants";
import loader2 from "@/components/general/loader2.vue";
import userInfo from "@/components/chat/userInfo.vue";
import imageCropper from "@/components/general/imageCropper.vue";
import { regexpImageName } from "@/constants";
import lambda from "@/lambda";
import { mapState } from "vuex";
import { namePrivacy } from "@/helpers";

export default {
  components: {
    loader2,
    userInfo,
    imageCropper
  },
  data() {
    return {
      showChat: false,
      userTypes,
      message: "",
      conversations: [],
      loaderConversations: false,
      activeConversation: null,
      loaderConversation: false,
      targetUser: null,
      oldMessages: [],
      newMessages: [],
      img: "",
      imgName: "",
      showCropper: false,
      loaderInsertImg: false,
      regexpImageName,
      notifications: []
    };
  },
  computed: {
    ...mapState(["isLogOut", "privacy"]),

    isConnected() {
      return this.$store.getters.chatIsConnected;
    },

    activeUser() {
      const userType = this.$store.getters.info.user.type.toLowerCase();
      return {
        id: this.$store.getters.info.user.sub,
        type: this.userTypes[userType]
      };
    }
  },

  watch: {
    activeConversation() {
      this.message = "";
      if (this.$refs.textarea) this.$refs.textarea.$el.style.height = "30px";
      this.img = "";
    }
  },

  methods: {
    namePrivacy,

    fitToContent({ target }) {
      if (this.message.length < 60) {
        target.style.height = "30px";
      } else if (target.scrollHeight <= 150) {
        target.style.height = "auto";
        target.style.height = target.scrollHeight + "px";
        target.style.overflowY = "hidden";
      } else target.style.overflowY = "scroll";
    },

    getNotifications() {
      axios(
        `${this.chatHost}get-not-read-messages?communityId=${this.$store.getters.community.id}&userId=${this.activeUser.id}&userType=${this.activeUser.type}`
      ).then(r => {
        if (r.status === 200) {
          this.notifications = r.data.reduce(
            (acc, conversation) => acc.concat(conversation),
            []
          );
        }
      });
    },

    close() {
      this.showCropper = false;
      this.$refs.inputImg.value = "";
      this.img = "";
    },

    insertImg(src) {
      this.loaderInsertImg = true;

      let arr = src.split(",");
      let mime = arr[0].match(/:(.*?);/)[1];
      let bstr = atob(arr[1]);
      let n = bstr.length;
      let u8arr = new Uint8Array(n);

      while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
      }

      const imgName = `${uuidv4()}-${this.imgName.replace(
        this.regexpImageName,
        ""
      )}`;

      const imgFile = new File([u8arr], imgName, {
        type: mime
      });

      const folder = `communities/${this.$store.getters.community.id}/Chat/${this.activeConversation.id}/Assets/${this.activeUser.type}/${this.activeUser.id}`;

      lambda
        .getSignedURL(imgFile, folder, imgName)
        .then(url => {
          api
            .put(url, imgFile, {
              headers: {
                "Content-Type": imgFile.type
              }
            })
            .then(r => {
              this.img = r.config.url.split("?")[0];
              this.$refs.inputImg.value = "";
              this.showCropper = false;
              this.loaderInsertImg = false;
            })
            .catch(() => {
              this.$toast.error(
                this.translation.errors_global.something_went_wrong
              );
              this.loaderInsertImg = false;
            });
        })
        .catch(() => {
          this.$toast.error(
            this.translation.errors_global.something_went_wrong
          );
          this.loaderInsertImg = false;
        });
    },

    async uploadImg(element) {
      const file = document.querySelector(`#${element}`).files[0];
      if (file) {
        const toBase64 = file =>
          new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => resolve(reader.result);
            reader.onerror = error => reject(error);
          });
        this.img = await toBase64(file);
        this.imgName = file.name;
        this.showCropper = true;
      }
    },

    getConversations() {
      this.loaderConversations = true;
      axios(
        `${this.chatHost}conversations?communityId=${this.$store.getters.community.id}&userId=${this.activeUser.id}&userType=${this.activeUser.type}`
      )
        .then(r => {
          if (r.status === 200) {
            this.conversations = r.data;
          }
          this.loaderConversations = false;
        })
        .catch(() => {
          this.loaderConversations = false;
        });
    },

    markMessages() {
      axios(
        `${this.chatHost}mark-messages?conversationId=${this.activeConversation.id}`
      );
    },

    getConversation(conversation) {
      this.notifications = this.notifications.filter(
        notification => notification.conversationId !== conversation.id
      );
      this.activeConversation = conversation;
      this.targetUser = conversation.userSource;
      this.targetUser.type = conversation.userSourceType.toString();
      this.newMessages = [];
      this.oldMessages = [];
      this.message = "";
      this.loaderConversation = true;
      axios(
        `${this.chatHost}conversation-messages?conversationId=${this.activeConversation.id}`
      )
        .then(r => {
          if (r.status === 200) {
            if (r.data.Items) this.oldMessages = r.data.Items;
            this.markMessages();
            if (this.$refs.chatScroll)
              this.$refs.chatScroll.scrollTop =
                this.$refs.chatScroll.scrollHeight + 10;
          }
          this.loaderConversation = false;
        })
        .catch(() => (this.loaderConversation = false));
    },

    submit(e) {
      if (e.which === 13 && !e.shiftKey) {
        e.preventDefault();

        this.$refs.sendButton.click();
      }
    },

    deleteMessage(id) {
      const body = {
        action: "DELETE-MESSAGE",
        messageId: id,
        userSourceId: this.activeUser.id,
        userSourceType: this.activeUser.type
      };

      this.$socket.send(JSON.stringify(body));
    },

    sendMessage() {
      if (this.message.length || this.img) {
        const body = {
          message: {
            text: this.message,
            image: this.img,
            sourceID: this.activeUser.id
          },
          userTargetId: this.targetUser.id,
          userTargetType: this.targetUser.type,
          userSourceId: this.activeUser.id,
          userSourceType: this.activeUser.type,
          communityId: this.$store.getters.community.id,
          conversationId: this.activeConversation.id
        };

        this.$socket.send(JSON.stringify(body));
        this.$refs.textarea.$el.style.height = "30px";
        this.message = "";
        this.img = "";

        if (this.$refs.chatScroll)
          this.$refs.chatScroll.scrollTop =
            this.$refs.chatScroll.scrollHeight + 10;
      }
    },

    updateConversations(message) {
      const conversation = this.conversations.filter(
        conversation => conversation.id === message.conversationId
      );

      if (!conversation.length) {
        this.getConversations();
      }
    },

    init() {
      this.$disconnect();
      this.$store.commit("UPDATE_CHAT_IS_CONNECTED", false);
      const userType = this.$store.getters.info.user.type.toLowerCase();

      this.$connect(
        `${this.chatConnect}?communityId=${this.$store.getters.community.id}&userId=${this.$store.getters.info.user.sub}&userType=${this.userTypes[userType]}`
      );
      this.$socket.onopen = () => {
        this.$store.commit("UPDATE_CHAT_IS_CONNECTED", true);
      };

      this.$socket.onclose = () => {
        if (this.$store.getters.info && !this.isLogOut) {
          this.$connect(
            `${this.chatConnect}?communityId=${this.$store.getters.community.id}&userId=${this.$store.getters.info.user.sub}&userType=${this.userTypes[userType]}`
          );
        }
      };

      this.$options.sockets.onmessage = e => {
        const message = JSON.parse(e.data);
        if (message.action === "DELETE-MESSAGE") {
          const msgOldMessages = this.oldMessages.find(
            msg => msg.id === message.messageId
          );

          const msgNewMessages = this.newMessages.find(
            msg => msg.id === message.messageId
          );

          if (msgOldMessages) {
            const index = this.oldMessages.indexOf(msgOldMessages);
            this.oldMessages.splice(index, 1);
          } else if (msgNewMessages) {
            const index = this.newMessages.indexOf(msgNewMessages);
            this.newMessages.splice(index, 1);
          }
        } else {
          if (message.conversationId === this.activeConversation.id)
            this.newMessages.push(message);

          if (
            !this.activeConversation ||
            message.conversationId !== this.activeConversation.id
          )
            this.notifications.push(message);
          this.updateConversations(message);
        }
      };
      this.getConversations();
      this.getNotifications();
    }
  },

  mounted() {
    this.init();
  },

  updated() {
    if (this.$refs.chatScroll)
      this.$refs.chatScroll.scrollTop = this.$refs.chatScroll.scrollHeight + 10;
  }
};
</script>

<style lang="scss" scoped>
.chat-wrapper {
  position: fixed;
  right: 15px;
  bottom: 15px;
  z-index: 1000;
}

.chat-button {
  position: relative;
  span {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: -5px;
    left: 0;
    background: #df2938;
    color: #ffffff;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    font-weight: bold;
  }
  button {
    width: 60px;
    height: 60px;
    border-radius: 34px 8px 34px 34px;
    img {
      object-fit: contain;
      width: 35px;
      height: 35px;
    }
  }
}

.chat-box {
  position: relative;
  > button {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    color: #ffffff;
    position: absolute;
    top: -25px;
    right: 0px;
    padding: 0;
  }
  display: none;
  height: 80vh;
  width: 300px;
  margin-right: 10px;
  animation: expand-chat 0.5s;
  background: #ffffff;
  border-radius: 34px 34px 8px 34px;
  box-shadow: 0px 0px 45px rgba(0, 0, 0, 0.1);
  &.active {
    display: block;
  }
}
.chat-header {
  display: flex;
  align-items: center;
  height: 60px;
  padding: 0 20px;
  font-weight: bold;
  a {
    text-decoration: underline;
    cursor: pointer;
  }
}

.conversations {
  border-radius: 0px 0px 8px 34px;
  height: 71vh;
  background: linear-gradient(180deg, #eff8fa 0%, #ffffff 100%);
  padding: 10px;
  animation: show 0.3s linear;
  > div {
    padding: 10px 10px 10px 5px;
    overflow-y: scroll;
    height: 100%;
    width: 100%;
    &::-webkit-scrollbar {
      width: 8px;
    }
    &::-webkit-scrollbar-track {
      background: rgba(0, 0, 0, 0.05);
      border-radius: 10px;
    }
    &::-webkit-scrollbar-thumb {
      background: #e6e6e6;
      border-radius: 10px;
      height: 40px;
    }
    &::-webkit-scrollbar-thumb:hover {
      background: rgba(0, 0, 0, 0.1);
    }
  }

  button {
    min-height: 74px;
    width: 100%;
    background: #ffffff;
    border-radius: 10px;
    margin-bottom: 10px;
  }
}

.chat {
  display: flex;
  flex-direction: column;
  border-radius: 0px 0px 8px 34px;
  height: 71vh;
  background: linear-gradient(180deg, #eff8fa 0%, #ffffff 100%);
  padding: 10px;
  animation: show 0.3s linear;
  .chat-user {
    display: flex;
    align-items: center;
    border-bottom: 1px solid #c4c4c4;
    padding-bottom: 10px;
    p {
      margin: 0;
      color: #6b6b6b;
      font-size: 0.9rem;
      &:nth-of-type(1) {
        font-weight: bold;
        font-size: 1rem;
      }
    }
    .profile-img {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 50px;
      height: 50px;
      border-radius: 50%;
      overflow: hidden;
      flex-shrink: 0;
      background: #f4f5f7;
      margin-right: 20px;
      img {
        object-fit: cover;
        height: 50px;
        width: 50px;
      }
    }
  }

  .chat-scroll {
    display: flex;
    flex-direction: column;
    flex: 1;
    overflow-y: scroll;
    padding: 20px 0 55px;
    margin-bottom: 5px;
    &::-webkit-scrollbar {
      width: 8px;
    }
    &::-webkit-scrollbar-track {
      background: rgba(0, 0, 0, 0);
      border-radius: 10px;
    }
    &::-webkit-scrollbar-thumb {
      background: rgba(0, 0, 0, 0.1);
      border-radius: 10px;
      height: 40px;
    }
    &::-webkit-scrollbar-thumb:hover {
      background: rgba(0, 0, 0, 0.2);
    }
    li {
      max-width: 180px;
      background: #f4f5f7;
      border-radius: 15px;
      border-top-left-radius: 0;
      padding: 10px;
      margin-bottom: 10px;
      list-style: none;
      word-wrap: break-word;
      word-break: break-word;
      align-self: flex-start;
      &.right {
        align-self: flex-end;
        border-top-right-radius: 0;
        border-top-left-radius: 15px;
        margin-right: 20px;
      }
      img {
        display: block;
        object-fit: contain;
        max-width: 160px;
        border-radius: 20px;
      }
      &:hover {
        .delete-message {
          display: flex;
        }
        &.right {
          .delete-message {
            justify-content: flex-end;
          }
        }
      }
    }
  }

  .delete-message {
    display: none;
    button {
      border: none;
      background: none;
      color: #6b6b6b;
      &:focus {
        outline: none;
      }
    }
  }

  .send-message {
    display: flex;
    align-items: flex-end;
    position: relative;
    .delete-img {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 30px;
      height: 30px;
      border-radius: 50%;
      color: #ffffff;
      margin-right: 10px;
    }
    label {
      margin-bottom: 0;
      cursor: pointer;
      position: relative;

      .add-img {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 30px;
        height: 30px;
        color: #6b6b6b;
        background: #f4f5f6;
        border-radius: 50%;
        margin-right: 10px;
      }
    }
    .textarea {
      width: 100%;
      background: #f4f5f6;
      border-radius: 40px;
      margin-right: 10px;
      textarea {
        height: 30px;
        background: #f4f5f6;
        border-radius: 40px;
        border: none;
        overflow-y: hidden;
        resize: none;

        &::-webkit-scrollbar {
          width: 6px;
        }
        &::-webkit-scrollbar-track {
          background: rgba(0, 0, 0, 0);
          border-radius: 10px;
        }
        &::-webkit-scrollbar-thumb {
          background: rgba(0, 0, 0, 0);
          border-radius: 10px;
          height: 40px;
        }
        &::-webkit-scrollbar-thumb:hover {
          background: rgba(0, 0, 0, 0.1);
        }
        &:hover {
          &::-webkit-scrollbar-thumb {
            background: rgba(0, 0, 0, 0.1);
            border-radius: 10px;
            height: 40px;
          }
        }
        &:focus {
          box-shadow: none;
        }
      }
      .img {
        display: flex;
        flex-direction: column;
        img {
          object-fit: contain;
          width: 150px;
          border-radius: 20px;
        }
      }
    }
    .send {
      width: 30px;
      height: 30px;
      border-radius: 50%;
      flex-shrink: 0;
      padding: 0;
      position: relative;
      img {
        position: absolute;
        object-fit: contain;
        width: 15px;
        height: 15px;
        right: 6px;
        top: 7px;
      }
    }
  }
}
</style>
