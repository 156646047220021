<template>
  <div class="container">
    <div class="row py-5">
      <div class="col">
        <div class="card-b mb-4 p-5">
          <div class="option-btns">
            <button
              class="btn px-4 px-md-5"
              :class="{ active: option === tabOptions.addOperator }"
              :style="{
                background:
                  option === tabOptions.addOperator
                    ? $store.getters.theme[2]
                    : $store.getters.theme[0],
                color: option === tabOptions.addOperator ? '#ffffff' : '#999999'
              }"
              @click="changeTab(tabOptions.addOperator)"
            >
              {{ translation.operators.add_operators.add_operators }}
            </button>
            <button
              class="btn px-4 px-md-5"
              :class="{ active: option === tabOptions.manageOperator }"
              :style="{
                background:
                  option === tabOptions.manageOperator
                    ? $store.getters.theme[2]
                    : $store.getters.theme[0],
                color:
                  option === tabOptions.manageOperator ? '#ffffff' : '#999999'
              }"
              @click="changeTab(tabOptions.manageOperator)"
            >
              {{ translation.operators.manage_operators.manage_operators }}
            </button>
          </div>
          <transition mode="out-in">
            <add-operators v-if="option === tabOptions.addOperator" />
            <manage-operators v-if="option === tabOptions.manageOperator" />
          </transition>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { tabOptions } from "@/constants";
import addOperators from "@/components/addmanageoperators/addOperators.vue";
import manageOperators from "@/components/addmanageoperators/manageOperators.vue";

export default {
  name: "Operation",
  components: { addOperators, manageOperators },
  data() {
    return {
      tabOptions,
      loader: false
    };
  },
  computed: {
    option() {
      return this.$route.query._option;
    }
  },
  methods: {
    changeTab(option) {
      const isDifferentRoute = this.option !== option;
      const isAddOperatorsRoute = option === tabOptions.addOperator;

      if (isDifferentRoute) {
        if (isAddOperatorsRoute)
          this.$router.push({ query: { _option: option } });
        else
          this.$router.push({ query: { _option: option, _page: 1, _q: "" } });
      }
    }
  },
  created() {}
};
</script>

<style lang="scss" scoped>
.option-btns {
  display: flex;
  justify-content: center;
  button {
    font-weight: bold;
    font-size: 1.3rem;
    transition: all 0.3s ease;
    min-width: 245px;
    &:nth-of-type(1) {
      border-top-left-radius: 35px;
      border-bottom-left-radius: 35px;
    }
    &:nth-of-type(2) {
      border-top-right-radius: 35px;
      border-bottom-right-radius: 35px;
    }
    &.active {
      z-index: 2;
    }
    &:hover {
      color: #666 !important;
    }
  }
}
@media (max-width: 977.98px) {
  .option-btns {
    button {
      font-size: 1rem;
    }
  }
}

@media (max-width: 767.98px) {
  .option-btns {
    button {
      min-width: 140px;
      font-size: 0.8rem;
    }
  }
}
</style>
