<template>
  <div>
    <div class="pt-4">
      <div v-if="answer.answer_object.files">
        <div
          class="mb-4"
          v-for="(media, index) in answer.answer_object.files"
          :key="index"
        >
          <img v-if="isImage(media)" :src="media.url" alt="" />
          <video
            :controlsList="
              $store.getters.info.user.type !== userTypesText.admin &&
              $store.getters.info.user.type !== userTypesText.moderator
                ? 'nodownload'
                : ''
            "
            controls
            v-else
            :src="media.url"
          ></video>
          <p>
            {{ media.description }}
          </p>
        </div>
      </div>
      <div
        v-else
        v-for="(media, index) in answer.answer_object.attachments"
        :key="index"
      >
        <img v-if="isImage(media)" :src="media.URL" alt="" />
        <video
          :controlsList="
            $store.getters.info.user.type !== userTypesText.admin &&
            $store.getters.info.user.type !== userTypesText.moderator
              ? 'nodownload'
              : ''
          "
          controls
          v-else
          :src="media.URL"
        ></video>
      </div>
    </div>
    <p
      v-if="
        answer.answer_object &&
          answer.answer_object.comment &&
          answer.answer_object.comment.length
      "
    >
      <span :style="{ color: $store.getters.theme[3] }" class="comments">
        {{ translation.answer_task.comments }}
      </span>
      {{ answer.answer_object.comment }}
    </p>
  </div>
</template>

<script>
import { userTypesText } from "@/constants";
import { imgTypes } from "@/constants";

export default {
  props: {
    answer: {
      required: true
    }
  },
  data() {
    return {
      userTypesText
    };
  },
  methods: {
    isImage(media) {
      if (!media.url) return false;
      const extension = media.url.split(".").pop();
      return imgTypes.includes(extension);
    }
  }
};
</script>

<style lang="scss" scoped>
.comments {
  margin-top: 20px;
  display: block;
  text-transform: uppercase;
  font-weight: bold;
}
</style>
