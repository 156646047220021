<template>
  <div class="d-flex flex-wrap">
    <div
      class="attachment mr-3 mb-2"
      v-for="(attachment, index) in attachments"
      :key="`${index}task`"
    >
      <div class="d-flex py-2 px-3" @click="download(attachment.URL)">
        <b-icon icon="paperclip" aria-hidden="true"></b-icon>
        <div class="ml-2">
          <a class="mb-0">{{ attachment.FileName }}</a>
          <p class="mb-0">{{ formatBytes(attachment.Size) }}</p>
        </div>
      </div>
      <b-icon
        v-if="deleteFile"
        class="icon"
        @click="deleteFile(attachment.FileName, index)"
        icon="x"
      ></b-icon>
    </div>
  </div>
</template>

<script>
import { formatBytes } from "@/helpers";

export default {
  props: {
    attachments: {
      required: true
    },
    deleteFile: {
      required: true
    }
  },
  methods: {
    formatBytes,
    download(url) {
      window.open(url, "_blank");
      window.focus();
    }
  }
};
</script>

<style lang="scss" scoped>
.attachment {
  display: flex;
  align-items: center;
  color: #6b6b6b;
  text-transform: uppercase;
  font-size: 0.8rem;
  border-radius: 20px;
  border: 1px solid #efefef;
  background-color: #fff;
  position: relative;
  padding-right: 20px;
  > div {
    cursor: pointer;
    width: 100%;
    height: 100%;
  }
  svg {
    &:nth-of-type(1) {
      font-size: 1.5rem;
    }
  }
  .icon {
    position: absolute;
    right: 5px;
    cursor: pointer;
    font-size: 1.2rem;
  }
  a {
    color: #6b6b6b;
    font-weight: bold;
    font-size: 0.8rem;
  }
}
</style>
