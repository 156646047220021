<template>
  <div>
    {{ audioError }}
    <div class="d-flex align-items-center justify-content-end mt-3">
      <div :class="{ active: audioError }" class="error-box bg-danger p-2 mr-3">
        <b-icon class="mr-1" icon="exclamation-triangle-fill"></b-icon>
        {{ audioError }}
      </div>
    </div>

    <div class="min-max mt-4 mb-3">
      <div class="mb-3">
        <label for="min">
          {{ translation.create_task.reaction.minimum_number }}:
        </label>
        <b-form-input
          v-model="min"
          name="min"
          type="number"
          min="1"
          :max="max"
          size="sm"
        ></b-form-input>
      </div>

      <div class="mb-3">
        <label for="max">
          {{ translation.create_task.reaction.maximum_number }}:
        </label>
        <b-form-input
          v-model="max"
          name="max"
          type="number"
          min="1"
          size="sm"
        ></b-form-input>
      </div>

      <div class="mb-3">
        <label for="min-duration">
          {{ translation.create_task.audio.minimum_duration }}:
        </label>
        <b-form-input
          v-model="minDuration"
          name="min-duration"
          type="number"
          min="1"
          :max="maxDuration"
          size="sm"
        ></b-form-input>
      </div>

      <div>
        <label for="max-duration">
          {{ translation.create_task.audio.maximum_duration }}:
        </label>
        <b-form-input
          v-model="maxDuration"
          name="max-duration"
          type="number"
          min="1"
          size="sm"
        ></b-form-input>
      </div>
    </div>
  </div>
</template>

<script>
import { taskEnum, addTaskText } from "@/constants";

export default {
  components: {},
  props: {
    taskText: {
      required: true
    },
    task: {
      required: true
    }
  },
  data() {
    return {
      min: 1,
      max: 10,
      minDuration: 1,
      maxDuration: 30,
      taskEnum,
      addTaskText
    };
  },
  computed: {
    taskObj() {
      return {
        statement: this.taskText,
        min_answers: +this.min,
        max_answers: +this.max,
        min_duration: +this.minDuration,
        max_duration: +this.maxDuration
      };
    },
    audioError() {
      let message = null;

      if (+this.min > this.max)
        message = this.translation.create_task.reaction.error.bigger;
      else if (this.min === "")
        message = this.translation.create_task.reaction.error.no_minimum;
      else if (this.max === "")
        message = this.translation.create_task.reaction.error.no_maximum;
      else if (+this.minDuration > this.maxDuration)
        message = this.translation.create_task.audio.error.bigger_duration;
      else if (this.minDuration === "")
        message = this.translation.create_task.audio.error.no_minimum_duration;
      else if (this.maxDuration === "")
        message = this.translation.create_task.audio.error.no_maximum_duration;

      return message;
    }
  },
  watch: {
    taskObj() {
      this.$emit("update", this.taskObj);
    },
    screenRecordingError() {
      this.$emit("error", this.screenRecordingError);
    }
  },
  methods: {
    getDescription() {
      if (
        this.task !== this.addTaskText &&
        +this.task.type_id === this.taskEnum.audio.id
      ) {
        this.min = this.task.question_object.min_answers
          ? this.task.question_object.min_answers
          : 1;

        this.max = this.task.question_object.max_answers
          ? this.task.question_object.max_answers
          : 10;

        this.minDuration = this.task.question_object.min_duration
          ? this.task.question_object.min_duration
          : 1;

        this.maxDuration = this.task.question_object.max_duration
          ? this.task.question_object.max_duration
          : 30;
      } else {
        this.setTask();
      }
      this.$emit("update", this.taskObj);
    },
    setTask() {
      this.min = 1;
      this.max = 10;
      this.minDuration = 1;
      this.maxDuration = 30;
    },
    init() {
      if (this.task === this.addTaskText) {
        this.setTask();
      }
    }
  },
  mounted() {
    this.getDescription();
    this.$emit("error", this.audioError);
  }
};
</script>

<style lang="scss" scoped>
.min-max {
  color: #6b6b6b;
  > div {
    display: flex;
    align-items: center;
    label {
      margin-bottom: 0;
    }
    input {
      max-width: 100px;
      margin-left: 10px;
    }
  }
}
</style>
