var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"pb-5"},[(_vm.participants.length)?_c('div',_vm._l((_vm.participants),function(participant,index){return _c('div',{key:index,staticClass:"d-flex flex-wrap"},[_c('div',{staticClass:"col-md-2 py-1 px-md-2 px-0"},[_c('router-link',{staticClass:"participant-info",attrs:{"to":{
            name: 'participant',
            query: { _id: participant[0].participant.id, _page: 1 }
          }}},[_c('div',{style:({
              'background-color': _vm.$store.getters.theme[0],
              color: _vm.$store.getters.theme[3]
            })},[(participant[0].participant.picture.length)?_c('img',{attrs:{"src":participant[0].participant.picture,"alt":"Profile Picture"}}):_c('b-icon',{staticClass:"icon-profile",attrs:{"icon":"person-fill","aria-hidden":"true"}})],1),_c('p',{style:({
              color: _vm.$store.getters.theme[3]
            })},[(_vm.$store.getters.info.user.type === _vm.userTypesText.moderator)?_c('span',[_vm._v(" "+_vm._s(_vm.namePrivacy( _vm.privacy.moderators.participants_identifier_type_id, participant[0].participant.name, participant[0].participant.id ))+" ")]):(
                _vm.$store.getters.info.user.type === _vm.userTypesText.observer
              )?_c('span',[_vm._v(" "+_vm._s(_vm.namePrivacy( _vm.privacy.observers.participants_identifier_type_id, participant[0].participant.name, participant[0].participant.id ))+" ")]):_c('span',[_vm._v(" "+_vm._s(participant[0].participant.name)+" ")])])])],1),_c('div',{staticClass:"col-md-10 py-1 px-md-2 px-0"},[_c('div',{staticClass:"participant-wrapper mb-4"},_vm._l((participant),function(answer,aIndex){return _c('div',{key:answer.answer_id,staticClass:"answer-wrapper"},[_c('div',{staticClass:"activity px-md-3 px-2 py-2 mt-3"},[_c('img',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",value:({ variant: 'secondary' }),expression:"{ variant: 'secondary' }",modifiers:{"hover":true}}],staticClass:"task-icon",style:({ background: _vm.$store.getters.theme[1] }),attrs:{"title":_vm.taskText(answer.task),"src":_vm.taskImage(answer.task)}}),_c('div',{style:({ background: _vm.$store.getters.theme[1] })},[_c('span',{style:({ color: _vm.$store.getters.theme[3] })},[_vm._v(_vm._s(answer.activity.name))]),_vm._v(" - "),_c('span',{style:({ color: _vm.$store.getters.theme[2] })},[_vm._v(" "+_vm._s(answer.task.code_string ? answer.task.code_string : ("T" + (+answer.task.index + 1)))+" ")])])]),_c('div',{staticClass:"answer p-md-3 m-3"},[_c('get-task-data',{attrs:{"task":answer.task}}),_c('div',{staticClass:"answer-box"},[_c('p',{style:({ color: _vm.$store.getters.theme[3] })},[_vm._v(" "+_vm._s(_vm.translation.replies.replies)+" ")]),_c('get-answer-data',{attrs:{"answer":answer}})],1),_c('div',{staticClass:"buttons-comment d-flex justify-content-between align-items-center mt-3"},[_c('div',{staticClass:"d-flex align-items-center"},[(
                      _vm.$store.getters.info.user.type ===
                        _vm.userTypesText.moderator ||
                        _vm.$store.getters.info.user.type ===
                          _vm.userTypesText.admin ||
                        answer.participant.id === _vm.$store.getters.info.user.sub
                    )?_c('div',[_c('button',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",value:({ variant: 'secondary' }),expression:"{ variant: 'secondary' }",modifiers:{"hover":true}}],staticClass:"viewed-button blue",class:{
                        active: +answer.viewed,
                        participant: !(
                          _vm.$store.getters.info.user.type ===
                            _vm.userTypesText.moderator ||
                          _vm.$store.getters.info.user.type ===
                            _vm.userTypesText.admin
                        )
                      },attrs:{"title":_vm.translation.replies.reply_viewed},on:{"click":function($event){return _vm.toggleValues(answer, aIndex, 'viewed')}}},[_c('b-icon',{staticClass:"mr-1",attrs:{"icon":"eye"}})],1),_c('button',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",value:({ variant: 'secondary' }),expression:"{ variant: 'secondary' }",modifiers:{"hover":true}}],staticClass:"viewed-button",class:{
                        active: !+answer.viewed,
                        participant: !(
                          _vm.$store.getters.info.user.type ===
                            _vm.userTypesText.moderator ||
                          _vm.$store.getters.info.user.type ===
                            _vm.userTypesText.admin
                        )
                      },attrs:{"title":_vm.translation.replies.mark_reply_viewed},on:{"click":function($event){return _vm.toggleValues(answer, aIndex, 'viewed')}}},[_c('img',{attrs:{"src":require("@/img/eye-closed.svg"),"alt":"Not viewed"}})])]):_vm._e(),_c('reject-answer',{attrs:{"activeTask":answer.task,"anwserIndex":answer.answer_id,"answer":answer,"getAnswers":_vm.getAnswers}}),(
                      _vm.$store.getters.info.user.type !==
                        _vm.userTypesText.participant ||
                        answer.participant.id ===
                          _vm.$store.getters.info.user.sub ||
                        +answer.has_public_replies
                    )?_c('button',{staticClass:"btn-chat hover",style:({ color: _vm.$store.getters.theme[2] }),on:{"click":function($event){return _vm.toggleSubReplies(answer.answer_id)}}},[_vm._v(" "+_vm._s(+answer.has_sub_replies ? _vm.translation.replies.see_comments : _vm.translation.replies.comment)+" ")]):_vm._e()],1),(
                    _vm.$store.getters.info.user.type ===
                      _vm.userTypesText.moderator ||
                      _vm.$store.getters.info.user.type === _vm.userTypesText.admin
                  )?_c('div',[_c('button',{staticClass:"check-button blue",class:{
                      active: +answer.checked,
                      participant: !(
                        _vm.$store.getters.info.user.type ===
                          _vm.userTypesText.moderator ||
                        _vm.$store.getters.info.user.type === _vm.userTypesText.admin
                      )
                    },on:{"click":function($event){return _vm.toggleValues(answer, index, 'check')}}},[_c('b-icon',{staticClass:"mr-1",attrs:{"icon":"check-circle"}}),_vm._v(" "+_vm._s(_vm.translation.replies.solved)+" ")],1),_c('button',{staticClass:"check-button",class:{
                      active: !+answer.checked,
                      participant: !(
                        _vm.$store.getters.info.user.type ===
                          _vm.userTypesText.moderator ||
                        _vm.$store.getters.info.user.type === _vm.userTypesText.admin
                      )
                    },on:{"click":function($event){return _vm.toggleValues(answer, index, 'check')}}},[_c('b-icon',{staticClass:"mr-1",attrs:{"icon":"circle"}}),_vm._v(" "+_vm._s(_vm.translation.replies.solved)+" ")],1)]):_vm._e()]),_c('sub-replies',{attrs:{"showSubReplies":_vm.showSubReplies,"task":answer.task,"answer":answer},on:{"close":function($event){_vm.showSubReplies = false},"updateHasSubReplies":function($event){answer.has_sub_replies = 1}}})],1),_c('div',{staticClass:"m-3"},[_c('labels',{attrs:{"answerProp":answer}})],1)])}),0)])])}),0):_c('div',{staticClass:"d-flex justify-content-center no-reports"},[_vm._v(" "+_vm._s(_vm.translation.replies.no_reports)+" ")])])}
var staticRenderFns = []

export { render, staticRenderFns }