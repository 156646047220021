var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"row task-wrapper"},[(!_vm.loading)?_c('div',{staticClass:"col"},[_c('div',{staticClass:"task-text img-question"},[_c('p',{domProps:{"innerHTML":_vm._s(_vm.text)}})]),_c('div',{staticClass:"d-flex flex-wrap"},[_c('attachment',{attrs:{"attachments":_vm.task.attachments,"deleteFile":null}})],1),_c('div',{staticClass:"d-flex justify-content-center py-4"},[_c('div',{staticClass:"select-box",style:({ background: _vm.$store.getters.theme[1] })},[_c('p',{staticClass:"mb-1"},[_vm._v(" "+_vm._s(_vm.translation.answer_task.reaction.minimum_number)+": "+_vm._s(_vm.min)+" ")]),_c('p',{staticClass:"mb-0"},[_vm._v(" "+_vm._s(_vm.translation.answer_task.reaction.maximum_number)+": "+_vm._s(_vm.max)+" ")])])]),_c('div',[_c('div',{staticClass:"image-wrapper py-4"},[(_vm.disabled)?_c('div',{staticClass:"disabled"}):_vm._e(),_c('div',{ref:"image",staticClass:"image",style:({ width: (_vm.imageWidth + "px"), height: (_vm.imageHeight + "px") }),on:{"mouseleave":_vm.handleMouseLeave}},[(_vm.src)?_c('img',{style:({ width: (_vm.imageWidth + "px"), height: (_vm.imageHeight + "px") }),attrs:{"src":_vm.src},on:{"click":_vm.setReactionPosition}}):_vm._e(),_vm._l((_vm.reactions),function(option,index){return _c('div',{key:index,staticClass:"reaction-icon",style:({
              position: 'absolute',
              top: ((option.clickMetadata.y - 15) + "px"),
              left: ((option.clickMetadata.x - 15) + "px")
            }),on:{"click":function($event){return _vm.editReaction(option, index)}}},[_vm._v(" "+_vm._s(option.icon)+" ")])}),_c('div',{staticClass:"dropdown-reaction",class:{ active: _vm.showDrop },style:({
              position: 'absolute',
              top: _vm.modalTopPosition,
              left: _vm.modalLeftPosition
            })},[(!_vm.editingReaction)?_c('span',{staticClass:"dot"}):_vm._e(),_c('div',{staticClass:"box",class:{
                right: _vm.imageWidth - _vm.modalLeft < 200,
                top: _vm.imageHeight - _vm.modalTop < 200 && _vm.modalTop > 120
              }},[_c('transition',{attrs:{"mode":"out-in"}},[(_vm.dropStep === _vm.tabOptions.selectReaction)?_c('div',{staticClass:"select-reaction"},_vm._l((_vm.options),function(option,index){return _c('button',{key:index,staticClass:"btn",on:{"click":function($event){return _vm.selectOption(option)}}},[_c('span',[_vm._v(" "+_vm._s(option.icon)+" ")]),_c('span',[_vm._v(" "+_vm._s(option.label)+" ")])])}),0):_vm._e(),(_vm.dropStep === _vm.tabOptions.fillComment)?_c('div',{staticClass:"fill-comment"},[_c('b-form-textarea',{staticStyle:{"overflow":"hidden"},attrs:{"id":"comment-temp","placeholder":_vm.translation.answer_task.comments},model:{value:(_vm.commentTemp),callback:function ($$v) {_vm.commentTemp=$$v},expression:"commentTemp"}}),_c('div',{staticClass:"pt-2"},[_c('button',{staticClass:"btn mr-2",on:{"click":_vm.deleteReaction}},[_c('b-icon',{attrs:{"icon":"x"}})],1),_c('button',{staticClass:"btn",on:{"click":_vm.addReaction}},[_c('b-icon',{attrs:{"icon":"check"}})],1)])],1):_vm._e()])],1)])],2)])]),_vm._l((_vm.reactions),function(option,index){return _c('div',{key:index,staticClass:"d-flex align-items-center my-4"},[_c('div',{staticClass:"crop-image",style:({
          backgroundImage: _vm.bgUrl,
          backgroundSize: (_vm.imageWidth + "px " + _vm.imageHeight + "px"),
          backgroundPosition: (((option.clickMetadata.x - 65) *
            -1) + "px " + ((option.clickMetadata.y - 65) * -1) + "px")
        }),attrs:{"data-icon":option.icon}}),_c('div',{staticClass:"break-label"},[_c('p',{staticClass:"title mb-1"},[_vm._v(" "+_vm._s(_vm.translation.task.reaction_type)+" "+_vm._s(index + 1)+" ")]),_c('p',[_vm._v(" "+_vm._s(option.label)+" ")]),_c('b-form-textarea',{attrs:{"disabled":_vm.task.is_answered,"id":"comment","placeholder":_vm.translation.answer_task.comments},model:{value:(option.comment),callback:function ($$v) {_vm.$set(option, "comment", $$v)},expression:"option.comment"}})],1),_c('button',{staticClass:"btn btn-remove",attrs:{"disabled":_vm.task.is_answered},on:{"click":function($event){return _vm.removeOption(index)}}},[_c('b-icon',{attrs:{"icon":"x"}})],1)])}),_c('div',{staticClass:"comments mt-4"},[(
          _vm.task.question_object.has_custom_comment_label &&
            _vm.task.question_object.comment_label &&
            _vm.task.question_object.comment_label.length
        )?_c('label',{attrs:{"for":"comments"}},[_vm._v(_vm._s(_vm.task.question_object.comment_label))]):_c('label',{attrs:{"for":"comments"}},[_vm._v(" "+_vm._s(_vm.translation.answer_task.comments)+" ")]),_c('textarea',{directives:[{name:"model",rawName:"v-model",value:(_vm.comments),expression:"comments"}],staticClass:"form-control",attrs:{"id":"textarea","maxlength":_vm.maxCharacters,"rows":"3","max-rows":"6","disabled":_vm.task.is_answered},domProps:{"value":(_vm.comments)},on:{"input":function($event){if($event.target.composing){ return; }_vm.comments=$event.target.value}}}),_c('characterAndWordCounter',{attrs:{"count":_vm.hasWordsLimitation
            ? this.comments.length
              ? this.comments.trim().split(/\s+/).length
              : 0
            : _vm.comments.length,"max":_vm.hasWordsLimitation ? _vm.maxWords : _vm.maxCharacters,"label":_vm.hasWordsLimitation
            ? _vm.translation.answer_task.words
            : _vm.translation.answer_task.characters}})],1)],2):_vm._e(),(_vm.loading)?_c('div',{staticClass:"w-100 d-flex justify-content-center"},[_c('loader')],1):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }