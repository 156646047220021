export const fonts = [
  { value: "Arial", text: "Arial" },
  { value: "Roboto", text: "Roboto" },
  { value: "Montserrat", text: "Montserrat" },
  { value: "Times New Roman", text: "Times New Roman" },
  { value: "Helvetica", text: "Helvetica" },
  { value: "Courier New", text: "Courier New" },
  { value: "Abril Fatface", text: "Abril Fatface" },
  { value: "Annie Use Your Telescope", text: "Annie Use Your Telescope" },
  { value: "Anton", text: "Anton" },
  { value: "Bahiana", text: "Bahiana" },
  { value: "Barrio", text: "Barrio" },
  { value: "Finger Paint", text: "Finger Paint" },
  { value: "Fredericka the Great", text: "Fredericka the Great" },
  { value: "Gloria Hallelujah", text: "Gloria Hallelujah" },
  { value: "Indie Flower", text: "Indie Flower" },
  { value: "Life Savers", text: "Life Savers" },
  { value: "Londrina Sketch", text: "Londrina Sketch" },
  { value: "Love Ya Like A Sister", text: "Love Ya Like A Sister" },
  { value: "Merienda", text: "Merienda" },
  { value: "Nothing You Could Do", text: "Nothing You Could Do" },
  { value: "Pacifico", text: "Pacifico" },
  { value: "Quicksand", text: "Quicksand" },
  { value: "Righteous", text: "Righteous" },
  { value: "Sacramento", text: "Sacramento" },
  { value: "Shadows Into Light", text: "Shadows Into Light" }
];

export const chartTypes = {
  line: "line",
  bar: "bar",
  barHorizontal: "bar-horizontal",
  stackedBar: "stacked-bar",
  pie: "pie",
  doughnut: "doughnut"
};
