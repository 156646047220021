<template>
  <div class="row justify-content-center py-4">
    <div class="col-md-10 d-flex flex-wrap">
      <div
        class="
          item
          col-md-4
          d-flex
          justify-content-center
          align-items-center
          pb-3
        "
      >
        <div class="icon-task">
          <img src="@/img/text.svg" />
        </div>
        <p class="desc-task">
          <span>{{ translation.task.text_type }}:</span>
          {{ translation.task.text_description }}
        </p>
      </div>

      <div
        class="
          item
          col-md-4
          d-flex
          justify-content-center
          align-items-center
          pb-3
        "
      >
        <div class="icon-task">
          <img src="@/img/white-space.svg" />
        </div>
        <p class="desc-task">
          <span> {{ translation.task.fill_blank_type }}:</span>
          {{ translation.task.fill_blank_description }}
        </p>
      </div>

      <div
        class="
          item
          col-md-4
          d-flex
          justify-content-center
          align-items-center
          pb-3
        "
      >
        <div class="icon-task">
          <img src="@/img/survey.svg" />
        </div>
        <p class="desc-task">
          <span>{{ translation.task.survey_type }}:</span>
          {{ translation.task.survey_description }}
        </p>
      </div>

      <div
        class="
          item
          col-md-4
          d-flex
          justify-content-center
          align-items-center
          pb-3
        "
      >
        <div class="icon-task">
          <img src="@/img/matrix.svg" />
        </div>
        <p class="desc-task">
          <span>{{ translation.task.matrix_type }}:</span>
          {{ translation.task.matrix_description }}
        </p>
      </div>

      <div
        class="
          item
          col-md-4
          d-flex
          justify-content-center
          align-items-center
          pb-3
        "
      >
        <div class="icon-task">
          <img src="@/img/slider.svg" />
        </div>
        <p class="desc-task">
          <span>{{ translation.task.slider_type }}:</span>
          {{ translation.task.slider_description }}
        </p>
      </div>

      <div
        class="
          item
          col-md-4
          d-flex
          justify-content-center
          align-items-center
          pb-3
        "
      >
        <div class="icon-task">
          <img src="@/img/drag-and-drop.svg" />
        </div>
        <p class="desc-task">
          <span>{{ translation.task.drag_drop_type }}:</span>
          {{ translation.task.drag_n_drop_description }}
        </p>
      </div>

      <div
        class="
          item
          col-md-4
          d-flex
          justify-content-center
          align-items-center
          pb-3
        "
      >
        <div class="icon-task">
          <img src="@/img/picture.svg" />
        </div>
        <p class="desc-task">
          <span>{{ translation.task.image_type }}:</span>
          {{ translation.task.image_description }}
        </p>
      </div>

      <div
        class="
          item
          col-md-4
          d-flex
          justify-content-center
          align-items-center
          pb-3
        "
      >
        <div class="icon-task">
          <img src="@/img/video.svg" />
        </div>
        <p class="desc-task">
          <span>{{ translation.task.video_type }}:</span>
          {{ translation.task.video_description }}
        </p>
      </div>

      <div
        class="
          item
          col-md-4
          d-flex
          justify-content-center
          align-items-center
          pb-3
        "
      >
        <div class="icon-task">
          <img src="@/img/attachment.svg" />
        </div>
        <p class="desc-task">
          <span>{{ translation.task.attachment_type }}:</span>
          {{ translation.task.attachment_description }}
        </p>
      </div>

      <div
        class="
          item
          col-md-4
          d-flex
          justify-content-center
          align-items-center
          pb-3
        "
      >
        <div class="icon-task">
          <img src="@/img/ranking.svg" />
        </div>
        <p class="desc-task">
          <span>{{ translation.task.ranking_type }}:</span>
          {{ translation.task.ranking_description }}
        </p>
      </div>

      <div
        class="
          item
          col-md-4
          d-flex
          justify-content-center
          align-items-center
          pb-3
        "
      >
        <div class="icon-task">
          <img src="@/img/reaction.svg" />
        </div>
        <p class="desc-task">
          <span>{{ translation.task.reaction_type }}:</span>
          {{ translation.task.reaction_description }}
        </p>
      </div>

      <div
        class="
          item
          col-md-4
          d-flex
          justify-content-center
          align-items-center
          pb-3
        "
      >
        <div class="icon-task">
          <img src="@/img/video-evaluation.svg" />
        </div>
        <p class="desc-task">
          <span>{{ translation.task.video_evaluation_type }}:</span>
          {{ translation.task.video_evaluation_description }}
        </p>
      </div>

      <div
        class="
          item
          col-md-4
          d-flex
          justify-content-center
          align-items-center
          pb-3
        "
      >
        <div class="icon-task">
          <img src="@/img/text-evaluation.svg" />
        </div>
        <p class="desc-task">
          <span>{{ translation.task.text_evaluation_type }}:</span>
          {{ translation.task.text_evaluation_description }}
        </p>
      </div>

      <div
        class="
          item
          col-md-4
          d-flex
          justify-content-center
          align-items-center
          pb-3
        "
      >
        <div class="icon-task">
          <img src="@/img/screen-recording.svg" />
        </div>
        <p class="desc-task">
          <span>{{ translation.task.screen_recording_type }}:</span>
          {{ translation.task.screen_recording_description }}
        </p>
      </div>

      <div
        class="
          item
          col-md-4
          d-flex
          justify-content-center
          align-items-center
          pb-3
        "
      >
        <div class="icon-task">
          <img src="@/img/multimedia.svg" />
        </div>
        <p class="desc-task">
          <span>{{ translation.task.multimedia_type }}:</span>
          {{ translation.task.multimedia_description }}
        </p>
      </div>

      <div
        class="
          item
          col-md-4
          d-flex
          justify-content-center
          align-items-center
          pb-3
        "
      >
        <div class="icon-task">
          <img src="@/img/audio.svg" />
        </div>
        <p class="desc-task">
          <span>{{ translation.task.audio_type }}:</span>
          {{ translation.task.audio_description }}
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
.icon-task {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  background: #efefef;
  margin-right: 10px;
  border-radius: 50%;
}

.desc-task {
  font-size: 0.65rem;
  color: #6b6b6b;
  max-width: 160px;
  margin-bottom: 0;
  span {
    font-weight: bold;
  }
}

.item {
  p {
    display: flex;
    flex-direction: column;
    justify-content: center;
    min-width: 160px;
    min-height: 65px;
  }
}

@media (max-width: 991.98px) {
  .item {
    p {
      min-width: unset;
    }
  }
}
</style>
