<template>
  <div id="createtask" class="container">
    <div class="row py-5">
      <div class="col">
        <div v-if="!loaderActivity" class="card-b pb-5">
          <label
            class="new-activity-header"
            :style="{ 'background-color': $store.getters.theme[1] }"
          >
            <div class="img">
              <img v-if="activity.banner" :src="activity.banner" />
            </div>
          </label>
          <div class="style">
            <img src="@/img/white-curve.png" />
          </div>
          <div class="pb-0 pt-2 px-md-5 px-3">
            <h4
              class="pb-2 font-weight-bold"
              :style="{ color: $store.getters.theme[3] }"
            >
              {{ translation.assignment.assignment }}
              -
              <span :style="{ color: $store.getters.theme[2] }">
                {{ activity.name }}
              </span>
            </h4>
          </div>
          <explanation />
          <taskList
            :getActivity="getActivity"
            :activityId="activityId"
            :activity="activity"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { api } from "@/services.js";
import explanation from "@/components/createtask/explanation.vue";
import taskList from "@/components/createtask/taskList.vue";

export default {
  components: { taskList, explanation },
  data() {
    return {
      loaderActivity: false,
      activity: []
    };
  },
  computed: {
    activityId() {
      return this.$route.query.a;
    }
  },
  watch: {},
  methods: {
    getActivity() {
      this.loaderActivity = true;
      api
        .get(`activity/${this.activityId}`, {
          headers: {
            Authorization: `Bearer ${this.$store.getters.token}`
          }
        })
        .then(r => {
          if (r.status === 200) {
            this.activity = r.data;
          }
          this.loaderActivity = false;
        })
        .catch(() => {
          this.loaderActivity = false;
        });
    },
    init() {
      this.getActivity();
    }
  },
  created() {
    this.init();
  }
};
</script>

<style lang="scss" scoped>
.card-b {
  position: relative;
}

.new-activity-header {
  position: relative;
  height: 200px;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  width: 100%;
  background: url(../img/image.svg) no-repeat center;
  background-size: contain;
  overflow: hidden;
  input {
    display: none;
  }
  .img {
    display: flex;
    justify-content: center;
    align-items: center;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    height: 200px;
    overflow: hidden;
    position: relative;
    img {
      object-fit: cover;
      width: 1110px;
      height: 200px;
    }
  }
}
.style {
  display: flex;
  position: absolute;
  top: 172px;
  height: 30px;
  width: 100%;
  img {
    width: 100%;
  }
}

@media (max-width: 991.98px) {
  .style {
    top: 172px;
    height: 30px;
  }
}
</style>
