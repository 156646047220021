<template>
  <div class="pt-5">
    <h2 class="title my-1 my-md-0" :style="{ color: $store.getters.theme[3] }">
      {{ translation.add_participant.invite_participants }}
    </h2>
    <div class="warn p-4 my-4">
      <b-icon class="mr-3" icon="exclamation-triangle"></b-icon>
      <p>
        {{ translation.add_participant.please_check_all }}
      </p>
    </div>
    <form class="mb-5">
      <label class="mb-3" for="subject">
        {{ translation.add_participant.email_subject }}:</label
      >
      <b-form-input id="subject" v-model="subject"></b-form-input>

      <label class="mt-4 mb-3">
        {{ translation.add_participant.email_body }}:
      </label>
      <editor
        placeholder=""
        :text="body"
        :maxCharacters="maxCharacters"
        @charactersCount="count => (charactersCount = count)"
        @update="txt => (body = txt)"
      />
    </form>
    <div v-if="notInvitedParticipants.length" class="p-4 mb-5 warning-emails">
      <div
        class="d-flex align-items-center justify-content-center pb-2"
        v-for="(participant, index) in notInvitedParticipants"
        :key="index"
      >
        <b-icon class="mr-3" icon="exclamation-triangle"></b-icon>

        <p class="m-0">
          {{ participant.participant.email }}
          {{ translation.add_participant.warn.is_already }}
        </p>
      </div>
    </div>
    <div v-if="invalidEmails.length" class="p-4 mb-5 warning-emails red">
      <div
        class="d-flex align-items-center justify-content-center pb-2"
        v-for="(participant, index) in invalidEmails"
        :key="index"
      >
        <b-icon class="mr-3" icon="exclamation-triangle"></b-icon>

        <p class="m-0">
          {{ translation.add_participant.warn.invalid_email }}:
          {{ participant.email }}
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import { api } from "@/services.js";
import editor from "@/components/general/editor.vue";

export default {
  props: {
    next: {
      required: true
    }
  },
  components: { editor },
  watch: {
    next() {
      this.sendEmail();
    }
  },
  data() {
    return {
      subject: "",
      body: "<p></p>",
      maxCharacters: 10000,
      charactersCount: 0,
      loaderSend: false,
      notInvitedParticipants: [],
      invalidEmails: []
    };
  },
  methods: {
    jsonFormatted() {
      this.jsonFile = this.$store.getters.importLotJson;
      return this.jsonFile.map(line => {
        const obj = {
          email: line.email,
          external_id: line.external_id
        };

        const keys = Object.keys(line);

        obj.segments = keys
          .filter(key => key !== "email" && key !== "external_id")
          .filter(key => +line[key])
          .map(key => {
            const id = key.match(/\[(\d+)]/)[1];
            return id;
          });

        return obj;
      });
    },
    sendEmail() {
      if (!this.loaderSend) {
        this.invalidEmails = [];
        this.notInvitedParticipants = [];
        this.loaderSend = true;
        const jsonFormatted = this.jsonFormatted();
        this.$emit("loaderSend", this.loaderSend);
        api
          .post(
            `admin/manage/participant/invite`,
            {
              email: {
                subject: this.subject,
                body: this.body
              },
              participants: jsonFormatted,
              community_id: this.$store.getters.community.id
            },
            {
              headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${this.$store.getters.token}`
              }
            }
          )
          .then(r => {
            if (r.status === 200) {
              if (r.data.invitedParticipants.length)
                this.$toast.success(this.translation.email.success.sent_email);
              this.subject = "";
              this.body = "<p></p>";

              this.notInvitedParticipants = r.data.notInvitedParticipants;
            }
            this.loaderSend = false;
            this.$emit("loaderSend", this.loaderSend);
          })
          .catch(error => {
            if (error.response.status === 400) {
              this.invalidEmails = error.response.data["Invalid Emails"];
              this.$toast.error(
                this.translation.add_participant.warn.invalid_email
              );
            } else {
              this.$toast.error(
                this.translation.errors_global.something_went_wrong
              );
            }

            this.loaderSend = false;
            this.$emit("loaderSend", this.loaderSend);
          });
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.title {
  font-weight: bold;
  font-size: 1.3rem;
}

.warn {
  display: flex;
  justify-content: center;
  align-items: center;
  background: #f7fafa;
  border-radius: 10px;
  font-weight: bold;
  color: #6b6b6b;
  p {
    margin: 0;
  }
  svg {
    font-size: 2.5rem;
  }
}

form {
  label {
    display: block;
    color: #6b6b6b;
    font-weight: bold;
    font-size: 1.1rem;
  }
  input {
    border: 1px solid #efefef;
    border-radius: 3px;
    color: #6b6b6b;
  }
}

.warning-emails {
  border-radius: 20px;
  background: #ffea9f;
  color: #6b6b6b;
  font-weight: bold;
  animation: show 0.3s linear;
  &.red {
    background: #df2938;
    color: #ffffff;
  }
}
</style>
