<template>
  <div class="logic mt-4">
    <h2>{{ translation.create_task.logics.logic }}</h2>

    <div class="my-3">
      <label>{{ translation.create_task.logics.default }}:</label>
      <b-form-select
        class="select-logic ml-2"
        v-model="logic.default"
        size="sm"
      >
        <b-form-select-option
          :value="task.index"
          v-for="task in tasksGoTo"
          :key="task.id"
        >
          {{ task.code_string ? task.code_string : `T${+task.index + 1}` }}
        </b-form-select-option>
        <b-form-select-option
          v-if="!isAdd && totalTasks > 1 && lastTask !== task.index"
          :value="logicOptions.next"
        >
          {{ translation.create_task.logics.next }}
        </b-form-select-option>
        <b-form-select-option :value="logicOptions.finish">
          {{ translation.create_task.logics.finish }}
        </b-form-select-option>
      </b-form-select>
    </div>

    <div
      class="conditions mb-4"
      v-for="(condition, conditionIndex) in logic.conditions"
      :key="conditionIndex"
    >
      <label class="mr-2 mb-2">
        {{ translation.create_task.logics.if_you_answer }}:
      </label>
      <div>
        <div
          class="condition-lines mb-2"
          v-for="(conditionLine, conditionLineIndex) in condition.condition"
          :key="conditionLineIndex"
        >
          <b-form-select
            v-if="conditionLineIndex !== 0"
            class="select-logic mr-2"
            v-model="conditionLine.operator"
            size="sm"
            :disabled="conditionLineIndex < 1"
          >
            <b-form-select-option :value="logicOptions.and">
              {{ translation.create_task.logics.and }}
            </b-form-select-option>
            <b-form-select-option :value="logicOptions.or">
              {{ translation.create_task.logics.or }}
            </b-form-select-option>
          </b-form-select>

          <b-form-select
            class="select-logic "
            v-model="conditionLine.task"
            size="sm"
            @change="handleTaskSelect(conditionLine)"
          >
            <b-form-select-option
              :value="task.index"
              v-for="task in tasksOptions"
              :key="task.index"
            >
              {{ task.code_string ? task.code_string : `T${+task.index + 1}` }}
            </b-form-select-option>
          </b-form-select>

          <b-form-select
            class="select-logic ml-2"
            v-model="conditionLine.subquestion"
            size="sm"
            :disabled="!getSubQuestions(conditionLine.task).length"
          >
            <b-form-select-option
              :value="subquestion.index"
              v-for="subquestion in getSubQuestions(conditionLine.task)"
              :key="subquestion.index"
              :title="subquestion.option"
            >
              {{ subquestion.option | maxLength }}
            </b-form-select-option>
          </b-form-select>

          <b-form-select
            class="select-logic ml-2"
            v-model="conditionLine.answer"
            size="sm"
            :disabled="!getAnswers(conditionLine.task).length"
          >
            <b-form-select-option
              :value="answer.index"
              v-for="answer in getAnswers(conditionLine.task)"
              :key="answer.index"
              :title="answer.option"
            >
              {{ answer.option | maxLength }}
            </b-form-select-option>
          </b-form-select>

          <button
            class="btn-x hover mr-2"
            @click="
              removeConditionLine(condition.condition, conditionLineIndex)
            "
          >
            <b-icon icon="x"></b-icon>
          </button>
        </div>
      </div>

      <div class="logic-button mb-2">
        <button class="btn-plus hover " @click="addLine(condition)">
          <span :style="{ background: $store.getters.theme[2] }">
            <b-icon icon="plus"></b-icon>
          </span>
        </button>

        <div class="go-to">
          <label class="ml-2 mr-2">
            {{ translation.create_task.logics.go_to }}:
          </label>
          <b-form-select
            class="select-logic "
            v-model="condition.goTo"
            size="sm"
          >
            <b-form-select-option
              :value="task.index"
              v-for="task in tasksGoTo"
              :key="task.id"
            >
              {{ task.code_string ? task.code_string : `T${+task.index + 1}` }}
            </b-form-select-option>
            <b-form-select-option
              v-if="!isAdd && totalTasks > 1 && lastTask !== task.index"
              :value="logicOptions.next"
            >
              {{ translation.create_task.logics.next }}
            </b-form-select-option>
            <b-form-select-option :value="logicOptions.finish">
              {{ translation.create_task.logics.finish }}
            </b-form-select-option>
          </b-form-select>
          <button class="btn-x hover" @click="removeCondition(conditionIndex)">
            <b-icon icon="x"></b-icon>
          </button>
        </div>
      </div>
    </div>

    <button
      class="btn-plus hover mt-3"
      v-if="tasksOptions.length"
      @click="addCondition"
    >
      {{ translation.create_task.logics.add_condition }}
      <span :style="{ background: $store.getters.theme[2] }">
        <b-icon icon="plus"></b-icon>
      </span>
    </button>
  </div>
</template>

<script>
import { api } from "@/services.js";
import { logicOptions, addTaskText, taskEnum } from "@/constants";

export default {
  props: {
    task: {
      required: true
    },
    codeString: {
      required: true
    },
    lastTask: {
      required: true
    },
    clearLogic: {
      required: true
    },
    tasksGoTo: {
      required: true
    },
    taskObj: {
      required: true
    },
    taskType: {
      required: true
    },
    totalTasks: {
      required: true
    }
  },
  data() {
    return {
      loaderTasks: false,
      tasks: [],
      logic: {
        default: logicOptions.next,
        conditions: []
      },
      logicOptions,
      addTaskText,
      taskEnum
    };
  },
  filters: {
    maxLength(value) {
      if (value.length > 8) {
        return (
          value
            .split("")
            .splice(0, 8)
            .join("") + "..."
        );
      } else return value;
    }
  },
  computed: {
    hasLogic() {
      return (
        this.taskType === this.taskEnum.surveyTask.id ||
        this.taskType === this.taskEnum.matrix.id ||
        this.taskType === this.taskEnum.dragAndDrop.id ||
        this.taskType === this.taskEnum.slider.id
      );
    },
    isAdd() {
      return this.task === this.addTaskText;
    },
    tasksOptions() {
      if (this.hasLogic && this.taskObj) {
        const isSurveyType =
          this.taskType === this.taskEnum.surveyTask.id && this.taskObj.options;

        const isSliderType =
          this.taskType === this.taskEnum.slider.id && this.taskObj.points;

        const isMatrixType =
          this.taskType === this.taskEnum.matrix.id &&
          this.taskObj.rows &&
          this.taskObj.columns;

        const isDragAndDropType =
          this.taskType === this.taskEnum.dragAndDrop.id &&
          this.taskObj.cards &&
          this.taskObj.groups;

        let answers = [];
        let subquestions = [];

        if (isSurveyType) {
          answers = this.taskObj.options;
          subquestions = [];
        } else if (isSliderType) {
          answers = this.taskObj.points.map(point => ({
            index: point.index,
            option: point.value
          }));
          subquestions = [];
        } else if (isMatrixType) {
          answers = this.taskObj.rows.map(task => ({
            index: task.index,
            option: task.value
          }));
          subquestions = this.taskObj.columns.map(task => ({
            index: task.index,
            option: task.value
          }));
        } else if (isDragAndDropType) {
          answers = this.taskObj.cards.map(task => ({
            index: task.index,
            option: task.value.text
          }));
          subquestions = this.taskObj.groups.map(task => ({
            index: task.index,
            option: task.value
          }));
        }

        const index = this.isAdd ? this.totalTasks : this.task.index;

        return [
          ...this.tasks.filter(task => +task.index !== +index),
          {
            code_string: this.codeString,
            index: index,
            answers: answers,
            sub_questions: subquestions
          }
        ];
      } else {
        return [...this.tasks];
      }
    }
  },
  watch: {
    task() {
      this.init();
    },
    logic() {
      this.$emit("setLogic", this.logic);
    },
    clearLogic() {
      this.setTask();
    },
    taskObj() {
      if (this.isAdd && this.hasLogic) {
        this.logic = {
          default: this.logicOptions.finish,
          conditions: []
        };
      }
    },
    taskType() {
      if (this.hasLogic) {
        if (this.isAdd || this.totalTasks === 1) {
          this.logic = {
            default: this.logicOptions.finish,
            conditions: []
          };
        } else {
          this.logic = {
            default: this.logicOptions.next,
            conditions: []
          };
        }
      }
    }
  },
  methods: {
    removeCondition(index) {
      this.logic.conditions.splice(index, 1);
    },
    removeConditionLine(condition, index) {
      condition.splice(index, 1);
      if (condition.length === 1) condition[0].operator = "";
    },
    addLine(condition) {
      const task = this.hasLogic
        ? this.isAdd
          ? this.totalTasks
          : this.task.index
        : this.tasksOptions[0].index;

      condition.condition.push({
        task: task,
        answer: this.tasksOptions[0].answers[0].index,
        subquestion: this.tasksOptions[0].sub_questions.length
          ? this.tasksOptions[0].sub_questions[0].index
          : 0,
        operator: condition.condition.length ? this.logicOptions.and : ""
      });
    },
    addCondition() {
      const task = this.hasLogic
        ? this.isAdd
          ? this.totalTasks
          : this.task.index
        : this.tasksOptions[0].index;

      this.logic.conditions.push({
        goTo: this.isAdd ? logicOptions.finish : logicOptions.next,
        condition: [
          {
            task: task,
            answer: this.tasksOptions[0].answers[0].index,
            subquestion: this.tasksOptions[0].sub_questions.length
              ? this.tasksOptions[0].sub_questions[0].index
              : 0,
            operator: ""
          }
        ]
      });
    },
    handleTaskSelect(conditionLine) {
      const task = this.tasksOptions.find(
        task => task.index === conditionLine.task
      );

      conditionLine.answer = task.answers[0].index;
      conditionLine.subquestion = task.sub_questions.length
        ? task.sub_questions[0].index
        : 0;
    },
    getAnswers(index) {
      const answers = this.tasksOptions.find(task => +task.index === +index);
      return answers ? answers.answers : [];
    },
    getSubQuestions(index) {
      const subquestions = this.tasksOptions.find(
        task => +task.index === +index
      );
      return subquestions ? subquestions.sub_questions : [];
    },
    getTasks() {
      this.loaderTasks = true;
      api
        .get(
          `activity/${this.$route.query.a}/tasks/options`,

          {
            headers: {
              Authorization: `Bearer ${this.$store.getters.token}`
            }
          }
        )
        .then(r => {
          if (r.status === 200) {
            this.loaderTasks = false;
            this.tasks = r.data.ActivityTaskOptions.filter(
              task => !this.isAdd && task.index <= +this.task.index
            );
          }
          this.$emit("setLogic", this.logic);
        })
        .catch(error => {
          if (error.response.status === 404) {
            this.$emit("setLogic", this.logic);
          }
          this.loaderTasks = false;
        });
    },
    setTask() {
      if (this.isAdd) {
        this.logic = {
          default: this.logicOptions.finish,
          conditions: []
        };
      } else {
        this.logic =
          this.task.logic && this.task.logic.logic_object
            ? this.task.logic.logic_object
            : {
                default: this.logicOptions.next,
                conditions: []
              };
      }
    },
    init() {
      this.getTasks();
      this.setTask();
    }
  },
  created() {
    this.init();
  }
};
</script>

<style lang="scss" scoped>
.logic {
  h2 {
    font-weight: bold;
    font-size: 1.3rem;
    color: #6b6b6b;
  }

  label {
    white-space: nowrap;
    color: #6b6b6b;
    margin: 0;
  }
}

.select-logic {
  width: 100px;
}

.conditions {
  display: flex;
  flex-wrap: wrap;
}

.condition-lines {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex-wrap: wrap;
}

.go-to {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.btn-plus {
  display: flex;
  align-items: center;
  color: #6b6b6b;
  font-weight: bold;
  padding: 0;
  background: none;
  border: none;
  flex-wrap: wrap;
  &:focus {
    outline: none;
  }
  span {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    width: 20px;
    height: 20px;
    color: #ffffff;
    margin-left: 5px;
  }
}

.logic-button {
  display: flex;
  align-self: flex-end;
  flex-wrap: wrap;
}

.btn-x {
  width: 25px;
  background: none;
  border: none;
  color: #ccc;
  font-size: 1.5rem;
  &:focus {
    outline: none;
  }
}
</style>
