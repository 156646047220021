<template>
  <div>
    <div
      :class="{
        'card-b': $store.getters.info.user.type !== userTypesText.participant,
        'py-4': $store.getters.info.user.type !== userTypesText.participant
      }"
      class="top-activities mx-3 mb-4 px-2"
      v-if="!feed"
    >
      <div>
        <router-link
          v-if="
            $store.getters.info.user.type === userTypesText.admin ||
              $store.getters.info.user.type === userTypesText.moderator ||
              ($store.getters.info.user.type === userTypesText.observer &&
                !+permissions.observers.hide_activities_stats)
          "
          :style="{ background: $store.getters.theme[1] }"
          class="btn btn-stats ml-md-3 my-1 hover"
          :to="{ name: 'statsactivities', query: { _q: '', _page: 1 } }"
        >
          {{ translation.stats_assignments.assignments_statistics }}
        </router-link>
      </div>
      <search class="my-1 ml-3" />
    </div>
    <div v-if="filterActivities.length && !loader" class="d-flex flex-wrap">
      <div
        :class="{
          'col-lg-4':
            $store.getters.info.user.type === userTypesText.participant,
          'col-lg-3':
            $store.getters.info.user.type !== userTypesText.participant
        }"
        class="col-md-4 col-sm-6 mb-md-4 mb-3"
        v-for="activity in filterActivities"
        :key="activity.id"
      >
        <div :id="`gear-drop-${activity.id}`">
          <div
            @click="
              openMenu({
                _activity: activity.id,
                _pageParticipant: 1,
                _pageReplies: 1,
                _q: ''
              })
            "
            :class="{ disabled: activity.is_active === '0' }"
            class="card-b p-0 pointer"
          >
            <div>
              <div class="card-header p-0">
                <div class="overflow-hidden">
                  <img v-if="activity.banner" :src="activity.banner" />
                </div>
                <div
                  class="check-icon"
                  :style="{
                    color: $store.getters.theme[3]
                  }"
                >
                  <b-icon
                    v-if="
                      ($store.getters.info.user.type ===
                        userTypesText.moderator ||
                        $store.getters.info.user.type ===
                          userTypesText.admin) &&
                        (!loaderCopy || activity.id !== copyActivityId)
                    "
                    class="h2 m-0"
                    icon="gear-fill"
                    aria-hidden="true"
                  ></b-icon>
                  <loader2
                    v-else-if="loaderCopy && activity.id === copyActivityId"
                    :color="$store.getters.theme[2]"
                  />
                  <b-icon
                    v-else-if="
                      !activity.is_completed &&
                        $store.getters.info.user.type !== userTypesText.observer
                    "
                    class="h2 m-0"
                    icon="caret-right-fill"
                    aria-hidden="true"
                  ></b-icon>
                  <b-icon
                    v-else
                    class="h2 m-0"
                    icon="eye-fill"
                    aria-hidden="true"
                  ></b-icon>
                  <div
                    class="dropdown-gear"
                    :id="`dropdown-gear-${activity.id}`"
                  >
                    <a
                      :style="{
                        color: $store.getters.theme[3]
                      }"
                      @click="
                        openTasks({
                          _activity: activity.id,
                          _pageParticipant: 1,
                          _pageReplies: 1,
                          _q: ''
                        })
                      "
                    >
                      <b-icon icon="eye-fill" aria-hidden="true"></b-icon
                      >{{ translation.assignment.view_assignment }}</a
                    >
                    <a
                      :style="{
                        color: $store.getters.theme[3]
                      }"
                      @click="editActivity(activity.id)"
                    >
                      <b-icon icon="pencil-fill" aria-hidden="true"></b-icon
                      >{{ translation.assignment.edit_assignment }}</a
                    >
                    <a
                      :style="{
                        color: $store.getters.theme[3]
                      }"
                      @click="editTask(activity.id)"
                    >
                      <b-icon
                        icon="plus-circle-fill"
                        aria-hidden="true"
                      ></b-icon
                      >{{ translation.assignment.edit_task }}</a
                    >
                    <a
                      :style="{
                        color: $store.getters.theme[3]
                      }"
                      @click="copyActivity(activity.id)"
                    >
                      <p class="d-flex align-items-center mb-0">
                        <b-icon
                          icon="stickies-fill"
                          aria-hidden="true"
                        ></b-icon>

                        <span>
                          {{ translation.assignment.copy_assignment }}
                        </span>
                      </p>
                    </a>
                    <a
                      :style="{
                        color: $store.getters.theme[3]
                      }"
                      @click="$bvModal.show(`delete-activity-${activity.id}`)"
                    >
                      <b-icon icon="trash-fill" aria-hidden="true"></b-icon
                      >{{ translation.global.delete }}</a
                    >
                    <b-modal
                      @ok="deleteActivity(activity.id)"
                      :cancel-title="translation.global.cancel"
                      :id="`delete-activity-${activity.id}`"
                      :title="translation.global.delete"
                    >
                      {{
                        translation.assignment.are_you_sure_delete_assignment
                      }}
                    </b-modal>
                  </div>
                </div>
                <div class="style">
                  <img class="img-fluid" src="@/img/white-curve-small.png" />
                </div>
              </div>
              <div class="px-3 py-4">
                <div>
                  <p
                    class="h6 font-weight-bold activity-name"
                    :style="{
                      color: $store.getters.theme[3]
                    }"
                  >
                    {{ translation.assignment.assignment }}
                    -
                    <span
                      :style="{ color: $store.getters.theme[2] }"
                      class="font-weight-bold d-block"
                      >{{ activity.name }}</span
                    >
                  </p>
                </div>
                <div
                  class="
                    d-flex
                    align-items-center
                    justify-content-end
                    mt-4
                    activity-bottom
                  "
                >
                  <p v-if="activity.is_completed">
                    {{ translation.assignment.completed }}
                    <b-icon icon="check-circle"></b-icon>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      v-else-if="queryValue && !loader"
      class="result d-flex justify-content-center py-5"
    >
      {{ translation.global.sorry_we_have }}
      "{{ queryValue }}"
    </div>
    <div class="d-flex justify-content-center py-5" v-else-if="loader">
      <loader />
    </div>
    <div class="d-flex justify-content-center py-5" v-else>
      {{ translation.assignment.no_activity }}
    </div>

    <pagination-query
      v-if="!queryValue && !feed && minPerPage < total"
      :perPage="perPage"
      :total="total"
      :options="[12, 24, 48, 96]"
      @perPageUpdate="newPerPage => (perPage = newPerPage)"
    />
  </div>
</template>

<script>
import { api } from "@/services.js";
import search from "@/components/general/search.vue";
import paginationQuery from "@/components/general/paginationQuery.vue";
import loader2 from "@/components/general/loader2.vue";
import loader from "@/components/general/loader.vue";
import { userTypesText, isActiveCode } from "@/constants";
import { outsideClick } from "@/helpers.js";
import { mapState } from "vuex";

export default {
  name: "activities-component",

  props: {
    getActivities: {
      required: true
    },
    activities: {
      required: true
    },
    feed: {
      default: false
    },
    loader: {
      required: true
    }
  },

  components: {
    search,
    paginationQuery,
    loader2,
    loader
  },

  data() {
    return {
      minPerPage: 12,
      perPage: 12,
      loaderDelete: false,
      loaderCopy: false,
      outsideClick,
      userTypesText,
      isActiveCode
    };
  },

  computed: {
    ...mapState(["permissions"]),

    page() {
      return +this.$route.query._page;
    },

    queryValue() {
      if (this.$route.query._q && this.$route.query._q.length)
        return this.$route.query._q;
      else return 0;
    },

    total() {
      return this.activities.filter(
        activity =>
          activity.is_active === this.isActiveCode.active ||
          this.$store.getters.info.user.type !== this.userTypesText.participant
      ).length;
    },

    filterActivities() {
      if (this.feed) {
        return this.activities.filter(
          activity =>
            activity.is_active === this.isActiveCode.active ||
            this.$store.getters.info.user.type !==
              this.userTypesText.participant
        );
      } else {
        let activities = this.activities.filter(
          activity =>
            activity.is_active === this.isActiveCode.active ||
            this.$store.getters.info.user.type !==
              this.userTypesText.participant
        );

        let array = [];

        if (this.queryValue) {
          array = activities.filter(item => {
            return this.queryValue
              .toLowerCase()
              .split(" ")
              .every(v => item.name.toLowerCase().includes(v));
          });
        } else {
          const start = this.perPage * (this.page - 1);
          activities.forEach((activity, index) => {
            if (index >= start && index < start + this.perPage)
              array.push(activity);
          });
        }

        return array;
      }
    }
  },

  methods: {
    editActivity(id) {
      this.$router.push({
        name: "createactivity",
        query: {
          a: id
        }
      });
    },

    editTask(id) {
      this.$router.push({
        name: "createtask",
        query: {
          a: id
        }
      });
    },

    copyActivity(id) {
      if (!this.loaderCopy) {
        this.loaderCopy = true;
        this.copyActivityId = id;
        api
          .get(`activity/${id}/copy`, {
            headers: {
              Authorization: `Bearer ${this.$store.getters.token}`
            }
          })
          .then(r => {
            if (r.status === 200) {
              this.$toast.success(
                this.translation.assignment.success.assignment_copied
              );
              this.getActivities();
            }
            this.loaderCopy = false;
          })
          .catch(() => {
            this.$toast.error(
              this.translation.errors_global.something_went_wrong
            );

            this.loaderCopy = false;
          });
      }
    },

    async deleteActivity(id) {
      if (!this.loaderDelete) {
        this.loaderDelete = true;
        api
          .delete(`activity/${id}`, {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${this.$store.getters.token}`
            }
          })
          .then(r => {
            if (r.status === 200) {
              this.$toast.success(
                this.translation.assignment.success.assignment_deleted
              );
              this.getActivities();
              this.loaderDelete = false;
            }
          })
          .catch(error => {
            if (error.response.status === 403) {
              this.$toast.error(
                this.translation.assignment.error.unable_to_delete
              );
            } else {
              this.$toast.error(
                this.translation.errors_global.something_went_wrong
              );
            }

            this.loaderDelete = false;
          });
      }
    },

    openTasks(query) {
      this.$store.commit("UPDATE_GO_TO_TASK", true);
      this.$router.push({ name: "tasks", query });
    },

    openMenu(query) {
      if (
        this.$store.getters.info.user.type === this.userTypesText.moderator ||
        this.$store.getters.info.user.type === this.userTypesText.admin
      ) {
        this.handleDrop(query._activity);
      } else {
        this.openTasks(query);
      }
    },

    handleDrop(id) {
      const dropItem = document.querySelector(`#gear-drop-${id}`);
      const dropdown = document.querySelector(`#dropdown-gear-${id}`);

      dropdown.classList.toggle("active");

      this.outsideClick(dropItem, ["click"], () => {
        dropdown.classList.remove("active");
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.top-activities {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

@media (max-width: 767.98px) {
  .top-activities {
    flex-direction: column;
  }
}

.btn-stats {
  border-radius: 7px;
  color: #6b6b6b;
  text-transform: uppercase;
  font-weight: bold;
}

.card-b {
  &.disabled {
    position: relative;
    opacity: 0.5;
    z-index: 1;
  }
}

.col-md-4 {
  a {
    text-decoration: none;
  }
}

.card-header {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 130px;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  > div {
    &:nth-of-type(1) {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 100%;
      width: 100%;
      border-top-left-radius: 20px;
      border-top-right-radius: 20px;
      overflow: hidden;
      img {
        object-fit: cover;
        width: 270px;
        height: 130px;
      }
    }
  }
}

@media (max-width: 767.98px) {
  .card-header {
    > div {
      &:nth-of-type(1) {
        img {
          width: 540px;
          height: 130px;
        }
      }
    }
  }
}

.style {
  position: absolute;
  bottom: -9px;
  height: 25px;
  overflow: hidden;
  width: 100%;
  img {
    width: 110%;
    height: 100%;
  }
}

@media (max-width: 1199.98px) {
  .card-header {
    height: 100px;
    > div {
      &:nth-of-type(1) {
        height: 100px;
      }
    }
  }
}

@media (max-width: 767.98px) {
  .card-header {
    height: 130px;
    > div {
      &:nth-of-type(1) {
        height: 130px;
      }
    }
  }
}
.activity-name {
  font-size: 1rem;
  min-height: 60px;
  word-wrap: break-word;
  word-break: break-word;
  span {
    font-size: 0.95rem;
  }
}

@media (max-width: 1199.98px) {
  .activity-name {
    font-size: 1rem;
  }
}

@media (max-width: 767.98px) {
  .activity-name {
    font-size: 1.2rem;
  }
}

.check-icon {
  position: absolute;
  bottom: -25px;
  right: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  box-shadow: 0px -4px 15px rgba(100, 100, 100, 0.1);
  background: #fff;
  z-index: 2;
}

.activity-bottom {
  height: 30px;
  p {
    color: #10c398;
    position: relative;
    top: 15px;
  }
}

.dropdown-gear {
  display: none;
  animation: grow 400ms ease-in-out forwards;
  transform-origin: top center;
  box-shadow: 0px 0px 5px 2px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  position: absolute;
  top: 55px;
  right: -10px;
  z-index: 5;
  background: #fff;
  width: 155px;
  &.active {
    display: block;
  }
  a {
    cursor: pointer;
    display: block;
    padding: 5px 10px;
    text-decoration: none;
    font-weight: normal;
    font-size: 0.95rem;
    svg {
      margin-right: 5px;
    }
    &:hover {
      background: rgba(193, 193, 193, 0.4);
    }
    &:first-of-type {
      border-top-right-radius: 4px;
      border-top-left-radius: 4px;
    }
    &:last-of-type {
      border-bottom-right-radius: 4px;
      border-bottom-left-radius: 4px;
    }
  }
}
</style>
