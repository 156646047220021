<template>
  <div>
    <transition mode="out-in">
      <import-file
        v-if="step === steps.file"
        :next="next"
        :segments="segments"
        @goFowards="goFowards"
      />
      <import-table
        v-if="step === steps.table"
        :next="next"
        :segments="segments"
        @goFowards="goFowards"
        @goBack="goBack"
      />
      <import-email
        v-if="step === steps.email"
        :next="next"
        @loaderSend="loader => (loaderSend = loader)"
      />
    </transition>

    <div class="d-flex flex-wrap justify-content-end">
      <button
        class="btn btn-back hover mr-2 my-1"
        v-if="step > 1"
        @click="goBack"
      >
        {{ translation.global.return }}
      </button>
      <div v-if="!loaderSegments">
        <button class="btn btn-fowards hover my-1" @click="nextStep">
          <span v-if="loaderSend">
            <loader2 :color="$store.getters.theme[2]"
          /></span>
          <span v-else
            >{{
              step === steps.file
                ? translation.add_participant.import
                : step === steps.table
                ? translation.global.next
                : translation.global.send
            }}
          </span>
        </button>
      </div>
      <div class="mr-4" v-else>
        <loader2 color="#6b6b6b" />
      </div>
    </div>
  </div>
</template>

<script>
import { api } from "@/services.js";
import importFile from "@/components/addparticipant/importFile.vue";
import importTable from "@/components/addparticipant/importTable.vue";
import importEmail from "@/components/addparticipant/importEmail.vue";
import loader2 from "@/components/general/loader2.vue";

export default {
  components: { importFile, importTable, importEmail, loader2 },
  data() {
    return {
      steps: {
        file: 1,
        table: 2,
        email: 3
      },
      step: 1,
      next: false,
      segments: [],
      loaderSegments: false,
      loaderSend: false
    };
  },
  computed: {
    stepQuery() {
      return +this.$route.query._step;
    }
  },
  methods: {
    goBack() {
      this.step--;
      this.$router.push({ query: { ...this.$route.query, _step: this.step } });
    },
    goFowards() {
      this.step++;
      this.$router.push({ query: { ...this.$route.query, _step: this.step } });
    },
    nextStep() {
      this.next = !this.next;
    },
    getSegments() {
      this.loaderSegments = true;
      api
        .get(`community/${this.$store.getters.community.id}/segments`, {
          headers: {
            Authorization: `Bearer ${this.$store.getters.token}`
          }
        })
        .then(r => {
          if (r.status === 200) {
            this.segments = r.data.filter(segment => +segment.is_active);
          }
          this.loaderSegments = false;
        })
        .catch(() => {
          this.loaderSegments = false;
        });
    }
  },
  created() {
    if (this.stepQuery) this.step = this.stepQuery;
    this.getSegments();
  }
};
</script>

<style lang="scss" scoped>
.btn-back {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 200px;
  border-radius: 25px;
  text-transform: uppercase;
  color: #6b6b6b;
  font-weight: bold;
  border: 1.5px solid #c8dada;
}
.btn-fowards {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 200px;
  background: #ebf2f2;
  border-radius: 25px;
  text-transform: uppercase;
  color: #6b6b6b;
  font-weight: bold;
}
</style>
