<template>
  <div class="card-b p-3 mb-3">
    <div class="p-2 card-b-header">
      <h2
        class="h5 font-weight-bold mb-0"
        :style="{ color: $store.getters.theme[3] }"
      >
        {{ translation.next_assignment.next_assignment_text }}
      </h2>
    </div>
    <div class="links pt-1 mb-2">
      <div
        @click="
          openTasks({
            _activity: activity.id,
            _pageParticipant: 1,
            _pageReplies: 1,
            _q: ''
          })
        "
      >
        <div
          class="item d-flex align-items-center p-2 rounded pointer"
          @mouseover="mouseOver"
          @mouseleave="mouseLeave"
        >
          <div class="size-img">
            <div
              class="check-icon "
              :style="{
                color: $store.getters.theme[3]
              }"
            >
              <b-icon
                class="h2 m-0"
                icon="caret-right-fill"
                aria-hidden="true"
              ></b-icon>
            </div>
          </div>
          <div>
            <p class="m-0">{{ activity.name }}</p>
            <p
              class="m-0"
              :style="{
                color: $store.getters.theme[2]
              }"
            >
              {{ translation.next_assignment.begin_assignment }}
            </p>
          </div>
        </div>
      </div>
      <div class="d-flex align-items-center p-2 rounded">
        <div class="size-img">
          <img class="img-fluid" src="@/img/hand.svg" alt="" />
        </div>
        <p class="m-0 ml-3 text-grey">
          {{ translation.next_assignment.glad_back_text }}
        </p>
      </div>
    </div>
    <div class="complete-activities pt-2 pr-3">
      <radial-progress-bar
        :diameter="70"
        :strokeWidth="5"
        :innerStrokeWidth="5"
        innerStrokeColor="#ffffff"
        :startColor="$store.getters.theme[2]"
        :stopColor="$store.getters.theme[2]"
        :completed-steps="completed"
        :total-steps="activities.length"
      >
        <div class="circle">
          <img src="@/img/complete-activities.svg" />
        </div>
      </radial-progress-bar>
      <p v-if="!loader2">
        {{ completed }}/{{ activities.length }}
        {{ translation.next_assignment.completed_assignments }}
      </p>
      <div v-if="loader2" class="w-100 pl-2">
        <loader2 :color="$store.getters.theme[2]" />
      </div>
    </div>
  </div>
</template>

<script>
import RadialProgressBar from "vue-radial-progress";
import loader2 from "@/components/general/loader2.vue";

export default {
  name: "NextTask",
  props: ["activity", "activities", "loader2"],
  components: {
    RadialProgressBar,
    loader2
  },
  data() {
    return {};
  },
  computed: {
    completed() {
      const array = this.activities.filter(activity => activity.is_completed);
      return array.length;
    }
  },
  methods: {
    mouseOver(e) {
      if (this.$store.getters.theme)
        e.currentTarget.style.background = this.$store.getters.theme[0];
    },

    mouseLeave(e) {
      e.currentTarget.style.background = "white";
    },

    openTasks(query) {
      this.$store.commit("UPDATE_GO_TO_TASK", true);
      this.$router.push({ name: "tasks", query });
    }
  }
};
</script>

<style lang="scss" scoped>
.check-icon {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  color: rgba(10, 45, 68, 0.4) !important;
  background: #e2e2e2;
}

.size-img {
  display: inline-table;
  width: 50px;
}

.links {
  > div {
    transition: all 0.5s ease;
    &:nth-of-type(1) {
      p {
        &:nth-of-type(1) {
          color: #b3b3b3;
          font-weight: bold;
          font-size: 1.07rem;
        }
        &:nth-of-type(2) {
          text-transform: uppercase;
          font-weight: bold;
          font-size: 0.75rem;
        }
      }
    }
  }
}

.complete-activities {
  display: flex;
  justify-content: center;
  align-items: center;
  border-top: 1px solid #e6e6e6;
  p {
    margin-bottom: 0;
    margin-left: 20px;
    color: #b3b3b3;
    font-weight: bold;
  }
}

.circle {
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #cccccc;
  border-radius: 50%;
  width: 56px;
  height: 56px;
}

.item {
  transition: all 0.5s ease;
}
</style>
