<template>
  <div>
    <h2 class="title">
      {{ translation.replies.charts_tab.choose_font_family }}
    </h2>
    <div v-if="font" class="font-wrapper">
      <div class="scroll">
        <a
          v-for="(option, index) in fonts"
          :id="option.value"
          :key="index"
          :class="{ active: font.value === option.value }"
          :style="{
            background:
              font.value === option.value ? $store.getters.theme[2] : 'none',
            fontFamily: option.value
          }"
          @click="setFont(option)"
          >{{ option.text }}</a
        >
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    fonts: {
      required: true
    },
    config: {
      required: true
    }
  },
  data() {
    return {
      font: null
    };
  },
  methods: {
    setFont(data) {
      this.font = data;
      this.$emit("update", this.font);
    }
  },
  created() {
    this.font = this.config.font;
  }
};
</script>

<style lang="scss" scoped>
.title {
  font-size: 1rem;
  color: #6b6b6b;
  font-weight: bold;
  margin-bottom: 10px;
}

.font-wrapper {
  max-width: 300px;
  margin: 20px 0;
  background: #ffffff;
  border-radius: 3px;
  padding: 5px;
  a {
    display: block;
    padding: 5px 10px;
    color: #6b6b6b;
    cursor: pointer;
    border-radius: 3px;
    &.active {
      font-weight: bold;
      color: #ffffff;
      &:hover {
        text-decoration: none;
      }
    }
  }
}

.scroll {
  height: 150px;
  overflow-y: scroll;
  padding-right: 5px;
  &::-webkit-scrollbar {
    width: 8px;
    overflow: hidden;
  }
  &::-webkit-scrollbar-track {
    background: rgba(0, 0, 0, 0.1);
    border-radius: 10px;
  }
  &::-webkit-scrollbar-thumb {
    background: #ccc;
    border-radius: 10px;
    height: 10px;
  }
  &::-webkit-scrollbar-thumb:hover {
    background: #aaa;
  }
}
</style>
