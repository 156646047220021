<template>
  <div class="task-statement-formatted">
    <p
      ref="formattedTask"
      :class="{ active: showMore }"
      v-html="getFormattedTask"
    ></p>
    <div class="task-statement-separator">
      <button
        v-if="hasShowMore"
        :style="{ color: $store.getters.theme[2] }"
        class="task-statement-show-more hover"
        @click="showMore = !showMore"
      >
        {{
          showMore ? translation.global.show_less : translation.global.show_more
        }}
      </button>
    </div>
  </div>
</template>

<script>
import { imageRegexpGlobal, videoRegexpGlobal } from "@/constants";

export default {
  props: {
    task: {
      required: true
    },
    getFormattedTask: {
      required: true
    }
  },
  data() {
    return {
      showMore: false,
      hasShowMore: false,
      imageRegexpGlobal,
      videoRegexpGlobal
    };
  },
  mounted() {
    this.hasShowMore =
      this.$refs.formattedTask.scrollHeight > 100 ||
      this.getFormattedTask.match(imageRegexpGlobal) ||
      this.getFormattedTask.match(videoRegexpGlobal);
  }
};
</script>

<style lang="scss" scoped></style>
