<template>
  <div>
    <div class="btn-customize">
      <button
        v-if="!toggleCustomize"
        class="hover"
        @click="toggleCustomize = !toggleCustomize"
      >
        <span>
          {{ translation.replies.charts_tab.customize }}
        </span>
      </button>
    </div>
    <div
      v-if="!loader"
      :class="{ active: toggleCustomize }"
      class="config-wrapper"
    >
      <nav class="config-nav">
        <a
          :class="{ active: option === configOptions.font }"
          :style="{ color: linkColor(configOptions.font) }"
          @click="option = configOptions.font"
        >
          {{ translation.replies.charts_tab.font_family }}
        </a>
        <a
          :class="{ active: option === configOptions.colors }"
          :style="{ color: linkColor(configOptions.colors) }"
          @click="option = configOptions.colors"
        >
          {{ translation.replies.charts_tab.colors }}
        </a>
        <a
          :class="{ active: option === configOptions.numberOfWords }"
          :style="{ color: linkColor(configOptions.numberOfWords) }"
          @click="option = configOptions.numberOfWords"
        >
          {{ translation.replies.charts_tab.number_of_words }}
        </a>
        <a
          :class="{ active: option === configOptions.discardedWords }"
          :style="{ color: linkColor(configOptions.discardedWords) }"
          @click="option = configOptions.discardedWords"
        >
          {{ translation.replies.charts_tab.discard_words }}
        </a>
      </nav>
      <div class="config-box">
        <transition mode="out-in">
          <config-font
            v-if="option === configOptions.font && config.font"
            :config="config"
            :fonts="fonts"
            @update="newfont => setFont(newfont)"
          />
          <config-colors
            v-if="option === configOptions.colors"
            :config="config"
            :isWordcloud="true"
            :optionsCount="+config.words >= 5 ? 5 : +config.words"
            :max="max"
            @updateColors="newColors => setColors(newColors)"
            @updateHasOneColor="
              newHasOneColor => setHasOneColor(newHasOneColor)
            "
            @updateThemes="newThemes => setThemes(newThemes)"
            @currentNewTheme="currentNewTheme => (newTheme = currentNewTheme)"
          />
          <config-number-of-words
            v-if="option === configOptions.numberOfWords && config.words"
            :config="config"
            :wordsCount="wordsCount"
            @update="newWords => setWords(newWords)"
          />
          <config-discarded-words
            v-if="option === configOptions.discardedWords"
            :config="config"
            @update="newDiscardedWords => setDiscardedWords(newDiscardedWords)"
          />
        </transition>
        <div class="btns-footer">
          <button @click="cancelConfig" class="btn hover">
            {{ translation.global.cancel }}
          </button>
          <button
            :style="{
              background: $store.getters.theme[2]
            }"
            class="btn hover"
            @click="applyConfig"
            :disabled="loader"
          >
            {{ translation.global.save }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import configFont from "@/components/charts/configItems/configFont.vue";
import configColors from "@/components/charts/configItems/configColors.vue";
import configNumberOfWords from "@/components/charts/configItems/configNumberOfWords.vue";
import configDiscardedWords from "@/components/charts/configItems/configDiscardedWords.vue";
import { configOptions } from "@/constants.js";
import { fonts } from "@/chartsConfig";
import { mapState, mapMutations } from "vuex";

export default {
  components: {
    configFont,
    configColors,
    configNumberOfWords,
    configDiscardedWords
  },
  props: {
    id: {
      required: true
    },
    taskId: {
      required: true
    },
    activityId: {
      required: true
    },
    wordsCount: {
      required: true
    },
    max: {
      required: true
    }
  },
  data() {
    return {
      newTheme: null,
      toggleCustomize: false,
      option: null,
      config: {
        discardedWords: null,
        font: null,
        theme: {
          hasOneColor: false,
          colors: null,
          themes: null
        },
        words: null
      },
      loader: false,
      defaultWords: 50,
      configOptions,
      fonts
    };
  },
  computed: {
    ...mapState(["configChart"])
  },
  methods: {
    ...mapMutations(["UPDATE_CONFIG_CHART"]),
    updateConfig() {
      const config = JSON.parse(JSON.stringify(this.config));
      this.UPDATE_CONFIG_CHART({
        ...this.configChart,
        [this.id]: config
      });
    },
    setFont(newfont) {
      this.config.font = newfont;
      this.updateConfig();
    },
    setColors(newColors) {
      this.config.theme.colors = newColors;
      this.updateConfig();
    },
    setHasOneColor(newHasOneColor) {
      this.config.theme.hasOneColor = newHasOneColor;
      this.updateConfig();
    },
    setThemes(newThemes) {
      this.config.theme.themes = newThemes;
      this.updateConfig();
    },
    setWords(newWords) {
      this.config.words = newWords;
      this.updateConfig();
    },
    setDiscardedWords(newDiscardedWords) {
      this.config.discardedWords = newDiscardedWords;
      this.updateConfig();
    },
    linkColor(currentOption) {
      if (this.option === currentOption)
        return `${this.$store.getters.theme[2]}`;
      else return "#6b6b6b";
    },
    getConfig() {
      this.loader = true;

      let url = `${this.exportChartHost}get/config?taskId=${this.taskId}&activityId=${this.activityId}`;

      axios
        .get(url, {
          headers: {
            Authorization: `Bearer ${this.$store.getters.token}`
          }
        })
        .then(r => {
          if (r.status === 200) {
            const configInitial = r.data.config;

            if (!configInitial.font) this.config.font = this.fonts[0];
            else this.config.font = configInitial.font;

            this.config.theme.hasOneColor = configInitial.theme.hasOneColor;
            this.config.theme.themes = configInitial.theme.themes;

            if (!configInitial.theme.colors) {
              this.config.theme.colors = [
                "#AC92EB",
                "#4FC1E8",
                "#A0D568",
                "#FFCE54",
                "#ED5564"
              ];
              this.config.theme.themes.push(this.config.theme.colors);
            } else this.config.theme.colors = configInitial.theme.colors;

            if (!configInitial.words)
              this.config.words =
                this.wordsCount >= this.defaultWords
                  ? this.defaultWords
                  : this.wordsCount;
            else this.config.words = configInitial.words;

            this.config.discardedWords = configInitial.discardedWords;

            this.updateConfig();
          }
          this.loader = false;
        })
        .catch(() => {
          this.loader = false;
        });
    },
    cancelConfig() {
      this.getConfig();
      this.toggleCustomize = !this.toggleCustomize;
    },
    applyConfig() {
      this.toggleCustomize = !this.toggleCustomize;
      this.loader = true;

      if (this.newTheme) this.setColors(this.newTheme);

      let url = `${this.exportChartHost}update`;

      axios
        .put(
          url,
          {
            taskId: this.taskId,
            activityId: this.activityId,
            config: { ...this.config, isWordcloud: true }
          },
          {
            headers: {
              Authorization: `Bearer ${this.$store.getters.token}`
            }
          }
        )
        .then(r => {
          if (r.status === 200) {
            this.$toast.success(this.translation.success_global.changes_saved);
          }

          this.loader = false;
        })
        .catch(() => {
          this.loader = false;
        });
    }
  },
  created() {
    this.option = this.configOptions.font;
    this.getConfig();
  }
};
</script>

<style lang="scss" scoped>
.btn-customize {
  display: flex;
  justify-content: flex-end;
  button {
    background: none;
    border: none;
    color: #6b6b6b;
    font-weight: bold;
    font-size: 1.2rem;
    padding: 10px;
    &:hover {
      color: #6b6b6b;
    }
    &:focus {
      outline: none;
    }
    svg {
      font-size: 2rem;
      font-weight: bold;
      text-align: center;
    }
  }
}

.config-wrapper {
  transition: all 0.5s ease-in-out;
  height: 0;
  overflow: hidden;
  &.active {
    display: block;
    height: 330px;
  }
}

@media (max-width: 767.98px) {
  .config-wrapper {
    &.active {
      height: 480px;
    }
  }
}

.config-nav {
  display: flex;
  flex-wrap: wrap;
  a {
    display: block;
    color: #6b6b6b;
    margin-right: 10px;
    padding: 5px 10px;
    font-weight: bold;
    font-size: 0.9rem;
    cursor: pointer;
    &:hover {
      color: #424151;
    }
    &.active {
      text-decoration: underline;
    }
  }
}

.config-box {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border-radius: 10px;
  background: #efefef;
  padding: 20px;
  height: calc(100% - 40px);
}

@media (max-width: 767.98px) {
  .config-box {
    height: calc(100% - 65px);
  }
}

.btns-footer {
  display: flex;
  justify-content: flex-end;
  button {
    color: #ffffff;
    font-weight: bold;
    padding: 5px 20px;
    background: #ccc;
    &:nth-of-type(1) {
      color: #6b6b6b;
      margin-right: 10px;
    }
  }
}
</style>
