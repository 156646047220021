<template>
  <div>
    <div class="d-flex justify-content-end">
      <div class="col-lg-11 col-md-10 px-md-2 p-0">
        <div v-if="!loader">
          <div class="d-flex justify-content-center flex-wrap">
            <dropdown-multiple
              class="mr-2 mb-2"
              v-if="activitiesOptions.length"
              :dropId="`${option}-activitiesFilter-${communityId}`"
              :placeholder="translation.replies.assignments"
              :width="140"
              :options="activitiesOptions"
              :value="[activitiesOptions[0].value]"
              :reset="reset"
              @update="data => (activitiesData = data)"
            />
            <dropdown-multiple
              class="mr-2 mb-2"
              v-if="participantsOptions.length && option !== tabOptions.charts"
              :dropId="`${option}-participantsFilter-${communityId}`"
              :placeholder="translation.replies.name"
              :width="140"
              :options="participantsOptions"
              :clear="clear"
              @update="data => (participantsData = data)"
            />
            <dropdown-multiple
              class="mr-2 mb-2"
              v-if="
                checkedOptions.length &&
                  option !== tabOptions.media &&
                  option !== tabOptions.charts
              "
              :dropId="`${option}-checkedFilter-${communityId}`"
              placeholder="Status"
              :width="140"
              :options="checkedOptions"
              :clear="clear"
              @update="data => (checkedData = data)"
            />
            <dropdown-multiple
              class="mr-2 mb-2"
              v-if="labelsOptions.length && option !== tabOptions.charts"
              :dropId="`${option}-labelsFilter-${communityId}`"
              :placeholder="translation.replies.labels"
              :width="140"
              :options="labelsOptions"
              :clear="clear"
              @update="data => (labelsData = data)"
            />
            <dropdown-multiple
              class="mr-2 mb-2"
              v-if="segmentsOptions.length"
              :dropId="`${option}-segmentsFilter-${communityId}`"
              :placeholder="translation.replies.segments"
              :width="140"
              :options="segmentsOptions"
              :clear="clear"
              @update="data => (segmentsData = data)"
            />
            <dropdown-multiple
              class="mr-2 mb-2"
              v-if="typeOptions.length && option !== tabOptions.media"
              :dropId="`${option}-typeFilter-${communityId}`"
              :placeholder="translation.replies.type"
              :width="140"
              :options="typeOptions"
              :clear="clear"
              @update="data => (typeData = data)"
            />
          </div>

          <div class="btns mt-2">
            <div>
              <button
                @click="applyOptions"
                :style="{ color: $store.getters.theme[2] }"
                class="btn hover mr-1"
              >
                {{ translation.global.apply }} {{ translation.replies.filters }}
              </button>
              <button
                @click="clearOptions"
                :style="{
                  color: $store.getters.theme[2]
                }"
                class="btn hover"
              >
                {{ translation.global.clean }} {{ translation.replies.filters }}
              </button>
            </div>
            <button
              v-if="option !== tabOptions.charts"
              :disabled="isExportDisable"
              @click="$emit('nextStep')"
              :class="{ hover: !isExportDisable }"
              class="btn mr-1 download"
            >
              <b-icon icon="download" aria-hidden="true"></b-icon>
              <span>{{ translation.replies.export }}</span>
            </button>
          </div>
        </div>
        <div class="d-flex justify-content-center py-5" v-else>
          <loader />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { api } from "@/services.js";
import dropdownMultiple from "@/components/general/dropdownMultiple.vue";
import loader from "@/components/general/loader.vue";
import {
  taskEnum,
  tabOptions,
  displayNameIds,
  userTypesText
} from "@/constants";
import { namePrivacy } from "@/helpers.js";
import { mapState } from "vuex";

export default {
  components: { dropdownMultiple, loader },

  props: {
    option: {
      required: true
    },
    participants: {
      required: true
    },
    medias: {
      required: true
    }
  },

  data() {
    return {
      loader: false,
      clear: false,
      reset: false,
      taskEnum,
      displayNameIds,
      userTypesText,

      activitiesData: [],
      activitiesOptions: [],

      participantsData: [],
      participantsOptions: [],

      checkedData: [],
      checkedOptions: [],

      labelsData: [],
      labelsOptions: [],

      segmentsData: [],
      segmentsOptions: [],

      typeData: [],
      typeOptions: [],

      tabOptions
    };
  },

  computed: {
    ...mapState(["privacy"]),

    isExportDisable() {
      return this.option === tabOptions.reports
        ? !this.participants.length
        : !this.medias.length;
    },

    communityId() {
      return this.$store.getters.community.id;
    },

    queryParams() {
      let queryParams = "";

      if (this.activitiesData.length) queryParams += "activities[]=";
      this.activitiesData.forEach(
        (id, index) =>
          (queryParams +=
            index === this.activitiesData.length - 1 ? `${id}&` : `${id},`)
      );

      if (this.participantsData.length) queryParams += "participants[]=";
      this.participantsData.forEach(
        (id, index) =>
          (queryParams +=
            index === this.participantsData.length - 1 ? `${id}&` : `${id},`)
      );

      if (this.labelsData.length) queryParams += "labels[]=";
      this.labelsData.forEach(
        (id, index) =>
          (queryParams +=
            index === this.labelsData.length - 1 ? `${id}&` : `${id},`)
      );

      if (this.segmentsData.length) queryParams += "segments[]=";
      this.segmentsData.forEach(
        (id, index) =>
          (queryParams +=
            index === this.segmentsData.length - 1 ? `${id}&` : `${id},`)
      );

      if (this.typeData.length) queryParams += "types[]=";
      this.typeData.forEach(
        (id, index) =>
          (queryParams +=
            index === this.typeData.length - 1 ? `${id}&` : `${id},`)
      );

      queryParams +=
        this.checkedData.length === 1 ? `checked=${this.checkedData[0]}&` : "";

      return queryParams;
    }
  },

  watch: {
    option() {
      this.getFilters();
    }
  },

  methods: {
    namePrivacy,

    applyOptions() {
      localStorage.setItem(
        `${this.option}-activitiesFilter-${this.communityId}`,
        JSON.stringify(this.activitiesData)
      );

      localStorage.setItem(
        `${this.option}-participantsFilter-${this.communityId}`,
        JSON.stringify(this.participantsData)
      );

      localStorage.setItem(
        `${this.option}-checkedFilter-${this.communityId}`,
        JSON.stringify(this.checkedData)
      );

      localStorage.setItem(
        `${this.option}-labelsFilter-${this.communityId}`,
        JSON.stringify(this.labelsData)
      );

      localStorage.setItem(
        `${this.option}-segmentsFilter-${this.communityId}`,
        JSON.stringify(this.segmentsData)
      );

      localStorage.setItem(
        `${this.option}-typeFilter-${this.communityId}`,
        JSON.stringify(this.typeData)
      );

      localStorage.setItem(
        `${this.option}-queryParams-${this.communityId}`,
        this.queryParams
      );

      this.$emit("queryParams");
    },

    clearOptions() {
      this.clear = !this.clear;
      this.reset = !this.reset;

      localStorage.setItem(
        `${this.option}-activitiesFilter-${this.communityId}`,
        JSON.stringify([this.activitiesOptions[0].value])
      );

      localStorage.setItem(
        `${this.option}-participantsFilter-${this.communityId}`,
        "[]"
      );

      localStorage.setItem(
        `${this.option}-checkedFilter-${this.communityId}`,
        "[]"
      );

      localStorage.setItem(
        `${this.option}-labelsFilter-${this.communityId}`,
        "[]"
      );

      localStorage.setItem(
        `${this.option}-segmentsFilter-${this.communityId}`,
        "[]"
      );

      localStorage.setItem(
        `${this.option}-typeFilter-${this.communityId}`,
        "[]"
      );

      localStorage.setItem(
        `${this.option}-queryParams-${this.communityId}`,
        `activities[]=${this.activitiesOptions[0].value}&`
      );

      this.$emit("queryParams");
    },

    getFilters() {
      this.loader = true;

      api
        .get(`report/community/${this.$store.getters.community.id}/filters`, {
          headers: {
            Authorization: `Bearer ${this.$store.getters.token}`
          }
        })
        .then(r => {
          if (r.status === 200) {
            this.activitiesOptions = r.data.activities.map(item => ({
              value: item.id,
              text: item.name
            }));

            const namePrivacyId =
              this.$store.getters.info.user.type ===
              this.userTypesText.moderator
                ? this.privacy.moderators.participants_identifier_type_id
                : this.$store.getters.info.user.type ===
                  this.userTypesText.observer
                ? this.privacy.observers.participants_identifier_type_id
                : this.displayNameIds.fullName;

            this.participantsOptions = r.data.participants.map(item => ({
              value: item.id,
              text: this.namePrivacy(namePrivacyId, item.name, item.id)
            }));

            this.labelsOptions = r.data.labels.map(item => ({
              value: item.id,
              text: item.name
            }));

            this.segmentsOptions = r.data.segments.map(item => ({
              value: item.id,
              text: item.name
            }));

            this.typeOptions = Object.values(this.taskEnum).map(item => ({
              value: item.id,
              text: item.text[this.langAbbreviation]
            }));

            if (this.option === this.tabOptions.charts) {
              this.typeOptions = this.typeOptions.filter(
                type =>
                  type.value !== this.taskEnum.imageTask.id &&
                  type.value !== this.taskEnum.videoTask.id &&
                  type.value !== this.taskEnum.multimedia.id &&
                  type.value !== 14
              );
            }

            this.checkedOptions = [
              {
                value: 0,
                text: this.translation.replies.not_solved
              },
              {
                value: 1,
                text: this.translation.replies.solved
              }
            ];

            const activites = JSON.parse(
              localStorage.getItem(
                `${this.option}-activitiesFilter-${this.communityId}`
              )
            );

            if (!activites || !activites.length) {
              this.activitiesData = [];
              this.activitiesData.push(this.activitiesOptions[0].value);
              this.applyOptions();
            }
          }
          this.loader = false;
        })
        .catch(() => {
          this.loader = false;
        });
    }
  },
  created() {
    this.getFilters();
  }
};
</script>

<style lang="scss" scoped>
.btns {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  button {
    font-weight: bold;
    border-radius: 36px;
    text-decoration: underline;
    padding: 5px;
    color: #6b6b6b;
    &.download {
      text-decoration: none;
      span {
        text-decoration: underline;
        margin-left: 8px;
      }
    }
    &:focus {
      box-shadow: none;
    }
  }
}
</style>
