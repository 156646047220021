<template>
  <div class="header py-3">
    <div class="container">
      <div class="row">
        <div class="col" ref="menuOutside">
          <slide>
            <div class="d-flex align-items-center justify-content-between">
              <div class="user">
                <div class="profile-img">
                  <img
                    v-if="$store.getters.info.user.picture"
                    :src="$store.getters.info.user.picture"
                    alt="Profile Picture"
                  />
                  <b-icon
                    v-else
                    class="icon-profile"
                    icon="person-fill"
                    aria-hidden="true"
                  ></b-icon>
                </div>
                <p class="pb-1">
                  {{ $store.getters.info.user.name.split(" ")[0] }}
                </p>
              </div>
              <notification
                :mobile="true"
                v-if="
                  $route.name !== 'communities' &&
                    $route.name !== 'createcommunity'
                "
              />
            </div>
            <nav>
              <div
                v-if="
                  $route.name !== 'communities' &&
                    $route.name !== 'createcommunity'
                "
              >
                <router-link :to="{ name: 'feed' }">
                  <img src="@/img/feed.svg" alt="Feed" />
                  {{ translation.header.feed_text }}
                </router-link>

                <a @click="openActivities">
                  <img src="@/img/activities.svg" alt="Activities" />
                  {{ translation.header.assignments_text }}
                </a>
                <sub-header-activities-mobile
                  :showActivities="showActivities"
                />

                <router-link :to="{ name: 'forum' }">
                  <img src="@/img/forum.svg" alt="Forum" />
                  {{ translation.header.forum_text }}
                </router-link>

                <a
                  @click="goToAnswers"
                  v-if="
                    $store.getters.info.user.type === userTypesText.admin ||
                      $store.getters.info.user.type ===
                        userTypesText.moderator ||
                      ($store.getters.info.user.type ===
                        userTypesText.observer &&
                        (!+permissions.observers.hide_report_replies ||
                          !+permissions.observers.hide_report_media ||
                          !+permissions.observers.hide_report_charts))
                  "
                >
                  <img src="@/img/answers.svg" alt="Answers" />
                  {{ translation.header.reports_text }}
                </a>

                <a
                  v-if="
                    $store.getters.info.user.type === userTypesText.moderator ||
                      $store.getters.info.user.type === userTypesText.admin ||
                      $store.getters.info.user.type === userTypesText.observer
                  "
                  @click="openAdmin"
                >
                  <img src="@/img/admin.svg" alt="Activities" />
                  {{
                    $store.getters.info.user.type === userTypesText.admin
                      ? translation.header.admin_text
                      : $store.getters.info.user.type ===
                        userTypesText.moderator
                      ? translation.header.moderator_text
                      : translation.header.observer_text
                  }}
                </a>
                <sub-header-admin-mobile :showAdmin="showAdmin" />

                <router-link
                  v-if="
                    $route.name !== 'communities' &&
                      $route.name !== 'createcommunity'
                  "
                  :disabled="$store.getters.is_over"
                  :class="{
                    disabled: $store.getters.is_over
                  }"
                  :to="{ name: 'account' }"
                >
                  <img src="@/img/account.svg" alt="Account" />
                  {{ translation.header.account_settings_text }}
                </router-link>
              </div>

              <a @click="logout" class="pointer">
                <b-icon
                  :style="{ color: $store.getters.theme[2] }"
                  class="mr-2"
                  icon="box-arrow-right"
                  aria-hidden="true"
                ></b-icon>
                <span :style="{ color: $store.getters.theme[2] }">
                  {{ translation.header.logout }}
                </span>
              </a>
            </nav>
          </slide>
        </div>
        <div class="col">
          <div class="logo">
            <router-link :to="{ name: 'feed' }">
              <img
                v-if="
                  $store.getters.community &&
                    $store.getters.community.logo &&
                    $store.getters.community.logo.length
                "
                class="img-fluid"
                :src="$store.getters.community.logo"
                alt="Logo"
              />
              <img v-else src="@/img/logo-communities.png" alt="Logo" />
            </router-link>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Slide } from "vue-burger-menu";
import notification from "@/components/header/notification.vue";
import subHeaderActivitiesMobile from "@/components/header/subHeaderActivitiesMobile.vue";
import subHeaderAdminMobile from "@/components/header/subHeaderAdminMobile.vue";
import { tabOptions, userTypesText } from "@/constants";
import { mapState } from "vuex";

export default {
  components: {
    Slide,
    notification,
    subHeaderActivitiesMobile,
    subHeaderAdminMobile
  },
  data() {
    return {
      tabOptions,
      userTypesText,
      showActivities: false,
      showAdmin: false
    };
  },
  computed: {
    ...mapState(["permissions"]),

    routeName() {
      return this.$route.name;
    }
  },

  watch: {
    routeName() {
      if (this.$refs.menuOutside) this.$refs.menuOutside.click();
    }
  },

  methods: {
    logout() {
      this.$disconnect();
      this.$store.commit("UPDATE_CHAT_IS_CONNECTED", false);
      this.$store.commit("UPDATE_IS_LOGOUT", true);
      this.$router.push({ name: "login" });
      this.$store.dispatch("logOut");
    },

    goToAnswers() {
      if (this.routeName !== "answers") {
        if (
          this.$store.getters.info.user.type !== userTypesText.observer ||
          (+this.permissions.observers.hide_report_replies &&
            +this.permissions.observers.hide_report_media &&
            +this.permissions.observers.hide_report_charts) ||
          !+this.permissions.observers.hide_report_replies
        ) {
          this.$router.push({
            name: "answers",
            query: { _option: this.tabOptions.reports, _page: 1, _q: "" }
          });
        } else if (!+this.permissions.observers.hide_report_media) {
          this.$router.push({
            name: "answers",
            query: { _option: this.tabOptions.media, _page: 1, _q: "" }
          });
        } else {
          this.$router.push({
            name: "answers",
            query: { _option: this.tabOptions.charts, _page: 1, _q: "" }
          });
        }
      }
    },

    openAdmin() {
      this.showAdmin = !this.showAdmin;
    },

    openActivities() {
      if (
        this.$store.getters.info.user.type === this.userTypesText.admin ||
        (this.$store.getters.info.user.type === this.userTypesText.moderator &&
          !+this.permissions.moderators.hide_create_activity)
      )
        this.showActivities = !this.showActivities;
      else if (this.$route.name !== "activities") {
        this.$router.push({
          name: "activities",
          query: { _page: 1, _q: "" }
        });
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.header {
  background: #ffffff;
}

.logo {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  img {
    max-height: 50px;
  }
}

.user {
  display: flex;
  align-items: center;
  p {
    color: #999;
    font-size: 0.8rem;
    font-weight: bold;
    text-transform: uppercase;
    margin-bottom: 0;
    margin-left: 10px;
  }
}

.profile-img {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 60px;
  height: 60px;
  border-radius: 50%;
  overflow: hidden;
  margin-bottom: 5px;
  border: 3px solid #c4c4c4;
  background: #fff;
  img {
    object-fit: cover;
    height: 60px;
    width: 60px;
  }
}

nav {
  display: flex;
  flex-direction: column;
  a {
    display: flex;
    align-items: center;
    font-weight: bold;
    text-transform: uppercase;
    text-decoration: none;
    color: #999 !important;
    border-bottom: 1px solid #e5e5e5;
    padding: 10px;
    font-size: 1rem;
    cursor: pointer;
    height: 60px;
    img {
      width: 35px;
      height: 30px;
      margin-right: 10px;
      object-fit: contain;
    }
    &:nth-of-type(1),
    &:nth-of-type(2) {
      img {
        height: 35px;
      }
    }
    &:nth-of-type(5) {
      img {
        height: 25px;
      }
    }
    svg {
      width: 30px;
      font-size: 1.5rem;
    }
  }
}
</style>
