<template>
  <div class="row task-wrapper">
    <div class="col">
      <div class="d-flex flex-wrap mt-4">
        <attachment :attachments="task.attachments" :deleteFile="null" />
      </div>
      <div v-if="loading" class="w-100 d-flex justify-content-center">
        <loader />
      </div>

      <div class="task-text img-question" v-html="question"></div>
    </div>
  </div>
</template>

<script>
import { api } from "@/services.js";
import loader from "@/components/general/loader.vue";
import attachment from "@/components/general/attachment.vue";

import { userTypesText } from "@/constants";

export default {
  components: { loader, attachment },
  props: {
    task: {
      required: true
    },
    save: {
      required: true
    },
    savePreview: {
      required: true
    },
    getTasks: {
      required: true
    }
  },
  data() {
    return {
      loading: false,
      question: "",
      blankRegexpGlobal: /({0327L-S2!@#\$\)\(%)(.*?)(%-\[(.*?)]!@#\$\)\(})/g,
      blankRegexp: /({0327L-S2!@#\$\)\(%)(blank)(%-\[(.*?)]!@#\$\)\(})/
    };
  },
  computed: {
    page() {
      return this.$store.getters.activeTaskIndex;
    }
  },
  watch: {
    save() {
      this.answer();
    },
    savePreview() {
      this.answerPreview();
    },
    page() {
      this.getQuestion();
    }
  },
  methods: {
    answerPreview() {
      if (!this.loading) {
        const whitespaceArray = Array.from(
          document.querySelectorAll(".whitespace-input")
        );

        const whitespaceValues = whitespaceArray.map(input => input.value);
        const filter = whitespaceValues.filter(
          whitespace => !whitespace.length
        );
        const maxLength = whitespaceValues.filter(
          whitespace => whitespace.length > 255
        );

        const hasZeros = whitespaceValues.some(input => input === "0");

        if (!filter.length) {
          if (!hasZeros) {
            if (!maxLength.length) {
              this.loading = true;
              let rawAnswer = this.task.raw_question;
              let answer = this.task.question_object.statement;
              let count = 0;
              const blankHash = "8e15625d6c158ec48f374efb77bd2714";

              while (this.blankRegexp.exec(rawAnswer)) {
                if (whitespaceValues[count] === "blank")
                  whitespaceValues[count] = blankHash;

                rawAnswer = rawAnswer.replace(
                  this.blankRegexp,
                  `$1${whitespaceValues[count]}$3`
                );
                answer = answer.replace(
                  this.blankRegexp,
                  `$1${whitespaceValues[count]}$3`
                );

                count++;
              }

              rawAnswer = rawAnswer.replaceAll(blankHash, "blank");
              answer = answer.replaceAll(blankHash, "blank");

              const body = {
                task_id: this.task.id,
                has_attachment: 0,
                has_video: 0,
                has_image: 0,
                raw_answer: rawAnswer,
                answer: {
                  statement: answer,
                  options: whitespaceValues.map((value, index) => ({
                    text: value === blankHash ? "blank" : value,
                    index
                  }))
                }
              };

              api
                .post("/answer/send/preview", body, {
                  headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${this.$store.getters.token}`
                  }
                })
                .then(r => {
                  if (r.status === 200) {
                    this.$toast.success(
                      this.translation.answer_task.success.reply_registered
                    );
                    this.getTasks("save");
                    this.loading = false;
                  }
                })
                .catch(() => {
                  this.$toast.error(
                    this.translation.answer_task.error.reply_not_registered
                  );
                  this.loading = false;
                });
            } else {
              this.$toast.error(
                this.translation.errors_global.you_have_exceeded
              );
            }
          } else {
            this.$toast.error(
              this.translation.create_task.error.the_field_cannot
            );
          }
        } else {
          this.$toast.error(this.translation.answer_task.error.fill_all_blanks);
        }
      }
    },
    answer() {
      if (!this.loading) {
        const whitespaceArray = Array.from(
          document.querySelectorAll(".whitespace-input")
        );

        const whitespaceValues = whitespaceArray.map(input => input.value);
        const filter = whitespaceValues.filter(
          whitespace => !whitespace.length
        );
        const maxLength = whitespaceValues.filter(
          whitespace => whitespace.length > 255
        );

        const hasZeros = whitespaceValues.some(input => input === "0");

        if (!filter.length) {
          if (!hasZeros) {
            if (!maxLength.length) {
              this.loading = true;
              let rawAnswer = this.task.raw_question;
              let answer = this.task.question_object.statement;
              let count = 0;

              const blankHash = "8e15625d6c158ec48f374efb77bd2714";

              while (this.blankRegexp.exec(rawAnswer)) {
                if (whitespaceValues[count] === "blank")
                  whitespaceValues[count] = blankHash;

                rawAnswer = rawAnswer.replace(
                  this.blankRegexp,
                  `$1${whitespaceValues[count]}$3`
                );
                answer = answer.replace(
                  this.blankRegexp,
                  `$1${whitespaceValues[count]}$3`
                );

                count++;
              }

              rawAnswer = rawAnswer.replaceAll(blankHash, "blank");
              answer = answer.replaceAll(blankHash, "blank");

              const body = {
                task_id: this.task.id,
                participant_id: this.$store.getters.info.user.sub,
                has_attachment: 0,
                has_video: 0,
                has_image: 0,
                raw_answer: rawAnswer,
                answer: {
                  statement: answer,
                  options: whitespaceValues.map((value, index) => ({
                    text: value === blankHash ? "blank" : value,
                    index
                  }))
                }
              };

              api
                .post("/answer/send", body, {
                  headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${this.$store.getters.token}`
                  }
                })
                .then(r => {
                  if (r.status === 200) {
                    this.$toast.success(
                      this.translation.answer_task.success.reply_registered
                    );
                    this.getTasks("save");
                    this.loading = false;
                  }
                })
                .catch(() => {
                  this.$toast.error(
                    this.translation.answer_task.error.reply_not_registered
                  );
                  this.loading = false;
                });
            } else {
              this.$toast.error(
                this.translation.errors_global.you_have_exceeded
              );
            }
          } else {
            this.$toast.error(
              this.translation.create_task.error.the_field_cannot
            );
          }
        } else {
          this.$toast.error(this.translation.answer_task.error.fill_all_blanks);
        }
      }
    },
    getQuestion() {
      const regexpVideo = /(?:(<iframe.*?src="))(.*?)((?:".*?>))((?:<\/iframe>))?/g;

      this.question = "";

      if (this.$store.getters.info.user.type === userTypesText.participant) {
        this.question = this.task.raw_question
          .replace(
            this.blankRegexpGlobal,
            `<input type="text" class="whitespace-input" >`
          )
          .replace(
            regexpVideo,
            "<video src='$2' width='350' height='197' frameborder='0' allowfullscreen='allowfullscreen' controls controlsList='nodownload'></iframe>"
          );
      } else {
        this.question = this.task.raw_question.replace(
          this.blankRegexpGlobal,
          `<input type="text" class="whitespace-input" >`
        );
      }

      this.init();
    },
    init() {
      if (this.task.is_answered) {
        this.question = this.task.answer.raw_answer.replace(
          this.blankRegexpGlobal,
          `<input type="text" value="$2" class="whitespace-input" disabled >`
        );
      } else if (this.task.answer && this.$route.query._preview) {
        this.question = this.task.answer.raw_answer.replace(
          this.blankRegexpGlobal,
          `<input type="text" value="$2" class="whitespace-input" >`
        );
      }
    }
  },

  mounted() {
    this.getQuestion();

    if (this.$store.getters.info.user.type === userTypesText.participant) {
      const taskWrapper = document.querySelector(".task-wrapper");

      taskWrapper.addEventListener("contextmenu", e => {
        e.preventDefault();
      });
    }
  },
  updated() {
    const whitespaceArray = Array.from(
      document.querySelectorAll(".whitespace-input")
    );

    whitespaceArray.forEach(input => {
      input.addEventListener("input", ({ target }) => {
        if (target.value.length > 255) target.style.color = "#df2938";
        else target.style.color = "#6b6b6b";
      });
    });
  }
};
</script>

<style lang="scss" scoped>
.task-text {
  display: flex;
  flex-direction: column;
  color: #6b6b6b;
  overflow-wrap: break-word;
}

.select-box {
  display: inline-block;
  padding: 20px;
  border-radius: 20px;
  color: #6b6b6b;
  font-weight: bold;
  font-size: 1.2rem;
}
</style>
