<template>
  <div>
    <h2 class="title">
      {{ translation.replies.charts_tab.choose_data_format }}
    </h2>
    <div class="is-percent">
      <button
        :style="{
          background: isPercent
            ? $store.getters.theme[2]
            : $store.getters.theme[3]
        }"
        class="btn hover"
        @click="isPercent = false"
      >
        {{ translation.replies.charts_tab.number }}
      </button>
      <button
        :style="{
          background: !isPercent
            ? $store.getters.theme[2]
            : $store.getters.theme[3]
        }"
        class="btn hover"
        @click="isPercent = true"
      >
        {{ translation.replies.charts_tab.percentage }}
      </button>
    </div>
  </div>
</template>

<script>
import { chartTypes } from "@/chartsConfig";

export default {
  props: {
    config: {
      required: true
    }
  },
  data() {
    return {
      isPercent: false,
      chartTypes
    };
  },
  watch: {
    isPercent() {
      this.setIsPercent(this.isPercent);
    }
  },
  methods: {
    setIsPercent(data) {
      this.$emit("update", data);
    }
  },
  created() {
    this.isPercent = this.config.isPercent;
  }
};
</script>

<style lang="scss" scoped>
.title {
  font-size: 1rem;
  color: #6b6b6b;
  font-weight: bold;
  margin-bottom: 20px;
}

.is-percent {
  button {
    color: #ffffff;
    font-weight: bold;
    &:nth-of-type(1) {
      margin-right: 20px;
    }
  }
}
</style>
