<template>
  <div>
    <div class="top-text mt-4">
      <p>{{ translation.create_task.reaction.enter_text }}:</p>
      <button
        :style="{ 'background-color': $store.getters.theme[2] }"
        class="btn hover"
        @click="markSelection"
      >
        {{ translation.create_task.reaction.mark_text }}
      </button>
    </div>

    <div class="text-task">
      <p
        ref="textTask"
        class="editable-p form-control"
        @keypress="onKeydown"
        @paste="onPaste"
        @focus="onFocus"
        @click="onClick"
        @input="onInput"
        contentEditable="true"
      ></p>
      <character-counter
        :max="maxCharacters"
        :min="minCharacters"
        :count="characterCount"
      />
    </div>

    <div class="d-flex align-items-center justify-content-end mt-3">
      <div
        :class="{ active: reactionError }"
        class="error-box bg-danger p-2 mr-3"
      >
        <b-icon class="mr-1" icon="exclamation-triangle-fill"></b-icon>
        {{ reactionError }}
      </div>
    </div>

    <div class="switch d-flex mt-4">
      <span class="pr-2">
        {{ translation.create_task.reaction.custom_tagging }}:</span
      >
      <b-form-checkbox v-model="customTagging" name="check-button" switch>
      </b-form-checkbox>
    </div>

    <div
      class="reaction-options"
      v-if="customTagging && reactionOptions.length"
    >
      <div
        v-for="(option, index) in reactionOptions"
        :key="index"
        :class="{ disabled: !option.active }"
        class="reaction-option py-2 px-2 px-md-3"
      >
        <div>
          <div>
            {{ index + 1 }}
          </div>
          <div>
            <b-form-input v-model="option.color" type="color"></b-form-input>
          </div>
          <div @click="addReactionOption(index)">
            <label :for="`label-${index}`">
              {{ translation.create_task.reaction.reaction_label }}:
            </label>
            <input
              :style="{
                background: option.active ? $store.getters.theme[0] : '#efefef'
              }"
              :class="{
                ['text-danger']: redText && index !== reactionOptions.length - 1
              }"
              class="px-2 input-text"
              autocomplete="off"
              v-model="option.label"
              :id="`label-${index}`"
              type="text"
              maxlength="255"
              @keyup="addReactionOption(index)"
              @click="addReactionOption(index)"
            />
            <b-form-checkbox
              v-if="option.active"
              v-model="option.mandatory"
              name="check-button"
              class="mandatory-option"
              switch
            >
              {{
                option.mandatory
                  ? translation.create_task.reaction.mandatory_marking
                  : translation.create_task.reaction.non_mandatory_marking
              }}
            </b-form-checkbox>
          </div>
          <div
            class="p-2"
            v-if="option.active && reactionOptions.length > 3"
            @click="removeReactionOption(index)"
          >
            <b-icon icon="x"></b-icon>
          </div>
        </div>
      </div>
    </div>
    <div class="min-max mt-4 mb-3">
      <div class="mb-3">
        <label for="min">
          {{ translation.create_task.reaction.minimum_number }}:
        </label>
        <b-form-input
          v-model="min"
          name="min"
          type="number"
          min="1"
          :max="max"
          size="sm"
        ></b-form-input>
      </div>
      <div>
        <label for="max">
          {{ translation.create_task.reaction.maximum_number }}:
        </label>
        <b-form-input
          v-model="max"
          name="max"
          type="number"
          min="1"
          size="sm"
        ></b-form-input>
      </div>
    </div>
  </div>
</template>

<script>
import { taskEnum, addTaskText } from "@/constants";
import characterCounter from "@/components/general/characterCounter.vue";

export default {
  components: {
    characterCounter
  },
  props: {
    taskText: {
      required: true
    },
    task: {
      required: true
    }
  },
  data() {
    return {
      reactionOptions: [],
      min: 1,
      max: 10,
      textTask: "",
      textTaskRaw: "",
      marks: [],
      customTagging: false,
      taskEnum,
      addTaskText,
      hasMark: 0,
      maxCharacters: 5000,
      minCharacters: 0
    };
  },
  computed: {
    reactionMandatoryOptions() {
      return this.reactionOptions.filter(option => option.mandatory);
    },
    characterCount() {
      return this.textTask.length;
    },
    colorDefault() {
      return this.$store.getters.theme[2];
    },
    taskObj() {
      return {
        statement: this.taskText,
        text: this.textTask,
        raw_text: this.textTaskRaw,
        marks: this.marks.map((mark, index) => ({ index: index, mark })),
        min_answers: +this.min,
        max_answers: +this.max,
        custom_tagging: this.customTagging ? 1 : 0,
        options: this.reactionOptions
          .filter(option => option.active)
          .map((option, index) => ({
            label: option.label,
            color: option.color,
            mandatory: option.mandatory,
            index: index
          }))
      };
    },
    redText() {
      let resp = false;
      if (
        this.hasDuplicate(this.reactionOptions, "label") ||
        this.hasEmpty(this.reactionOptions) ||
        this.hasZeros(this.reactionOptions)
      )
        resp = true;
      return resp;
    },
    reactionError() {
      let message = null;

      if (!this.textTask.length)
        message = this.translation.create_task.reaction.error.empty_text;
      else if (!this.hasMark)
        message = this.translation.create_task.reaction.error.no_marked;
      else if (this.hasMark < +this.min)
        message = this.translation.create_task.reaction.error
          .minimum_bigger_marks;
      else if (this.hasMark < this.reactionMandatoryOptions.length)
        message = this.translation.create_task.reaction.error
          .minimum_bigger_mandatory;
      else if (this.hasEmpty(this.reactionOptions))
        message = this.translation.create_task.reaction.error.empty_option;
      else if (this.hasZeros(this.reactionOptions))
        message = this.translation.create_task.error.the_field_cannot;
      else if (this.hasDuplicate(this.reactionOptions, "label"))
        message = this.translation.create_task.reaction.error.duplicated_labels;
      else if (+this.min > this.max)
        message = this.translation.create_task.reaction.error.bigger;
      else if (this.min === "")
        message = this.translation.create_task.reaction.error.no_minimum;
      else if (this.max === "")
        message = this.translation.create_task.reaction.error.no_maximum;
      else if (this.characterCount > this.maxCharacters)
        message = this.translation.errors_global.you_have_exceeded;

      return message;
    }
  },
  watch: {
    taskObj() {
      this.$emit("update", this.taskObj);
    },
    reactionError() {
      this.$emit("error", this.reactionError);
    }
  },
  methods: {
    handleEnterButtonPress(event) {
      const { target } = event;

      let innerHTML = target.innerHTML;

      const thereSpaceAtTheEnd = innerHTML.slice(-6);

      const spaceTag = "&nbsp;";

      if (thereSpaceAtTheEnd === spaceTag) {
        innerHTML = innerHTML.slice(0, innerHTML.length - 6);
      }

      const thereTagBRAtTheEnd = innerHTML.slice(-4);

      const includesTagBR = thereTagBRAtTheEnd.includes("<br>");

      const selection = window.getSelection();
      const range = selection.getRangeAt(0);
      const br = document.createElement("br");

      const preRange = range.cloneRange();

      preRange.setStart(target, 0);

      const selectedCursorPosition = preRange.toString().replace(/\n/g, "")
        .length;

      const textSizeWithoutLineBreaks = target.innerText.replace(
        /(\r\n|\n|\r)/gm,
        ""
      ).length;

      if (
        includesTagBR ||
        selectedCursorPosition !== textSizeWithoutLineBreaks
      ) {
        event.preventDefault();
      }

      range.insertNode(br);
      range.setStartAfter(br);
      range.setEndAfter(br);
      selection.removeAllRanges();
      selection.addRange(range);
    },

    onKeydown(event) {
      const { target, key } = event;
      const { innerText, innerHTML } = target;

      //remove all div tags
      const divTags = target.querySelectorAll("div");

      if (divTags?.length) {
        divTags.forEach(divTag => {
          while (divTag.firstChild) {
            target.insertBefore(divTag.firstChild, divTag);
          }

          target.removeChild(divTag);
        });
      }

      if (key === "Enter") {
        this.handleEnterButtonPress(event);
      }

      this.textTask = innerText;
      this.textTaskRaw = innerHTML;
    },

    onInput({ target }) {
      const { innerText, innerHTML } = target;

      this.textTask = innerText;
      this.textTaskRaw = innerHTML;

      this.getMarks();
    },

    onPaste(e) {
      e.preventDefault();
      const text = e.clipboardData.getData("text/plain");

      const range = document.getSelection().getRangeAt(0);
      range.deleteContents();

      const textNode = document.createTextNode(text);
      range.insertNode(textNode);
      range.selectNodeContents(textNode);
      range.collapse(false);

      const selection = window.getSelection();
      selection.removeAllRanges();
      selection.addRange(range);

      e.target.innerText = selection.anchorNode.wholeText;
      this.textTask = e.target.innerText;
    },
    onFocus({ target }) {
      if (target.childNodes && target.childNodes.length) {
        const lastNodeIndex = target.childNodes.length - 1;

        const hasChar = target?.childNodes[lastNodeIndex]?.wholeText?.length;
        if (!hasChar) {
          target.innerHTML = `${target.innerHTML}&nbsp;`;
        }
      }
    },
    onClick({ target, currentTarget }) {
      if (target.nodeName === "MARK") {
        currentTarget.innerHTML = currentTarget.innerHTML.replace(
          target.outerHTML,
          target.innerHTML
        );
        this.textTaskRaw = currentTarget.innerHTML;
        this.getMarks();
      }
    },
    markSelection() {
      const selObj = document.getSelection();
      const selRange = selObj.getRangeAt(0);
      const selectionText = selRange.toString();

      const parent = selRange.startContainer.parentElement;

      if (
        parent.classList.contains("editable-p") ||
        parent.nodeName === "MARK"
      ) {
        if (selectionText.trim().length) {
          const mark = document.createElement("mark");

          const cloneContents = selRange.cloneContents();

          mark.appendChild(cloneContents);
          mark.style.background = this.$store.getters.theme[2];
          mark.style.color = "white";
          mark.style.paddingLeft = "5px";
          mark.style.paddingRight = "5px";
          mark.style.borderRadius = "5px";
          mark.style.cursor = "pointer";
          mark.classList.add("mark");

          selRange.deleteContents();
          selRange.insertNode(mark);

          const regex = /<mark[^>]*>(.*?)<\/mark>/g;
          mark.innerHTML = mark.innerHTML.replace(regex, "$1");

          this.getMarks();
          this.textTaskRaw = parent.innerHTML;

          if (parent.nodeName === "MARK") {
            parent.parentElement.removeChild(parent);
          }
        }
      }
    },
    getMarks() {
      this.marks = Array.from(this.$refs.textTask.childNodes)
        .filter(node => node.nodeName === "MARK")
        .map(mark => mark.innerText);
      this.hasMark = this.marks.length;
    },
    hasZeros(array) {
      return array.some(option => option.label === "0");
    },
    hasEmpty(array) {
      return array.some(option => !option.label.length && option.active);
    },
    hasDuplicate(array, key) {
      const valuesSoFar = [];
      for (let i = 0; i < array.length; ++i) {
        const value = array[i][key].trim();
        if (valuesSoFar.indexOf(value) !== -1) {
          return true;
        }
        valuesSoFar.push(value);
      }
      return false;
    },
    addReactionOption(index) {
      if (!this.reactionOptions[index].active) {
        this.reactionOptions[index].active = true;

        this.reactionOptions.push({
          label: "",
          color: this.colorDefault,
          mandatory: false,
          active: false
        });
      }
    },
    removeReactionOption(index) {
      if (this.reactionOptions.length > 3)
        this.reactionOptions.splice(index, 1);
    },
    getDescription() {
      if (
        this.task !== this.addTaskText &&
        +this.task.type_id === this.taskEnum.textEvaluation.id
      ) {
        this.$refs.textTask.innerHTML = this.task.question_object.raw_text;
        this.textTask = this.$refs.textTask.innerText;
        this.textTaskRaw = this.task.question_object.raw_text;
        this.getMarks();
        this.min = this.task.question_object.min_answers
          ? this.task.question_object.min_answers
          : 1;

        this.max = this.task.question_object.max_answers
          ? this.task.question_object.max_answers
          : 10;

        this.customTagging = !!this.task.question_object.custom_tagging;

        this.reactionOptions = this.task.question_object.options.map(
          option => ({
            label: option.label,
            color: option.color,
            mandatory: option.mandatory,
            active: true
          })
        );

        this.reactionOptions.push({
          label: "",
          color: this.colorDefault,
          mandatory: false,
          active: false
        });
      } else {
        this.setTask();
      }
      this.$emit("update", this.taskObj);
    },
    setTask() {
      this.reactionOptions = [
        {
          label: `${this.translation.create_task.reaction.option} 1`,
          color: this.colorDefault,
          mandatory: false,
          active: true
        },
        {
          label: `${this.translation.create_task.reaction.option} 2`,
          color: this.colorDefault,
          mandatory: false,
          active: true
        },
        {
          label: "",
          color: this.colorDefault,
          mandatory: false,
          active: false
        }
      ];

      this.textTask = "";

      this.min = 1;
      this.max = 10;

      this.customTagging = false;
    },
    init() {
      if (this.task === this.addTaskText) {
        this.setTask();
      }
    }
  },
  mounted() {
    this.getDescription();
    this.$emit("error", this.reactionError);
  }
};
</script>

<style lang="scss" scoped>
.top-text {
  display: flex;
  align-items: center;
  justify-content: space-between;

  p {
    font-weight: bold;
    color: #6b6b6b;
  }
  button {
    margin-bottom: 10px;
    border-radius: 17px;
    text-transform: uppercase;
    font-weight: bold;
    color: #fff;
    font-size: 0.8rem;
  }
}

.reaction-options {
  display: flex;
  flex-wrap: wrap;
}

.text-task {
  p {
    min-height: 200px;
    overflow: auto;
    line-height: 1.8rem;
  }
}

.reaction-option {
  width: calc(50% - 10px);
  animation: enter 0.3s ease-in-out forwards;
  margin-top: 20px;
  border-radius: 20px;
  border: 1px solid #efefef;
  padding: 5px;
  position: relative;
  &:nth-of-type(n) {
    margin-right: 10px;
  }
  > div:nth-of-type(1) {
    display: flex;
    justify-content: space-between;
    align-items: center;
    > div {
      &:nth-of-type(1) {
        flex-shrink: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 30px;
        height: 30px;
        border: 2px solid #dbecec;
        border-radius: 13px;
        color: #6b6b6b;
        font-weight: bold;
        margin-right: 5px;
        margin-right: 20px;
      }
      &:nth-of-type(2) {
        margin-right: 15px;
        input {
          width: 50px;
          height: 50px;
          border: 1px solid #efefef;
        }
      }
      &:nth-of-type(3) {
        width: 100%;
      }
      &:nth-of-type(4) {
        color: #6b6b6b;
        margin-left: 5px;
        font-size: 1.2rem;
        cursor: pointer;
        position: absolute;
        top: 0;
        right: 0;
      }
    }
    label {
      display: inline-block;
      font-weight: bold;
      color: #6b6b6b;
      white-space: nowrap;
    }
    .input-text {
      display: block;
      color: #6b6b6b;
      font-size: 0.75rem;
      border: none;
      width: 100%;
      border-radius: 10px;
      height: 50px;
      &:focus {
        outline: none;
      }
    }
  }
  &.disabled {
    opacity: 0.5;
    > div:nth-of-type(1) {
      > div {
        &:nth-of-type(1) {
          color: #ddd;
        }
      }
      label {
        color: #ddd;
      }
      input {
        color: #ddd;
      }
    }
  }
}

@media (max-width: 991.98px) {
  .reaction-option {
    width: 100%;
    > div:nth-of-type(1) {
      flex-direction: column;
      div {
        &:nth-of-type(2) {
          margin-top: 10px;
          order: 2;
        }
        &:nth-of-type(3) {
          margin-top: 10px;
        }
      }
    }
  }
}

.scroll {
  overflow: auto;
  display: flex;
  justify-content: center;
  &::-webkit-scrollbar {
    height: 8px;
    width: 8px;
    overflow: hidden;
  }
  &::-webkit-scrollbar-track {
    background: rgba(0, 0, 0, 0);
    border-radius: 10px;
  }
  &::-webkit-scrollbar-thumb {
    background: #ccc;
    border-radius: 10px;
    height: 10px;
  }
  &::-webkit-scrollbar-thumb:hover {
    background: #aaa;
  }
}

.min-max {
  color: #6b6b6b;
  > div {
    display: flex;
    align-items: center;
    label {
      margin-bottom: 0;
    }
    input {
      max-width: 100px;
      margin-left: 10px;
    }
  }
}

.switch {
  color: #6b6b6b;
}

.mandatory-option {
  color: #6b6b6b;
  font-size: 0.8rem;
}
</style>
