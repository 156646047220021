<template>
  <div :id="idDropdown" class="mt-4">
    <button
      @click="openDropdown"
      :class="{ active: open }"
      class="btn btn-dropdown mb-3"
    >
      {{ text }}
      <b-icon icon="caret-down-fill" aria-hidden="true"></b-icon>
    </button>
    <div :class="{ active: open }" class="dropdown-box py-1">
      <div v-if="options.length">
        <a
          :class="{
            active: options.length === selectedOptions.length
          }"
          @click="toggleAll()"
        >
          {{ translation.create_assignment.who_will_see_1 }}
        </a>
        <a
          class="d-block"
          v-for="(option, index) in options"
          @click="toggleOption(option)"
          :key="index"
          :class="{ active: hasOption(option.id) }"
          >{{ option.name }}</a
        >
      </div>
      <div class="p-3 no-options" v-else>
        {{ translation.create_assignment.no_options }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    idDropdown: {
      required: true
    },
    text: {
      required: true
    },
    options: {
      required: true
    },
    currentOptions: {
      required: true
    }
  },
  data() {
    return {
      open: false,
      selectedOptions: []
    };
  },
  watch: {
    selectedOptions() {
      this.$emit("update", this.selectedOptions);
    },
    currentOptions() {
      this.setOptions();
    }
  },
  methods: {
    openDropdown() {
      this.open = !this.open;
    },
    toggleAll() {
      if (this.selectedOptions.length === this.options.length) {
        this.selectedOptions = [];
      } else {
        this.selectedOptions = this.options.map(option => ({ ...option }));
      }
    },
    toggleOption(option) {
      const filter = this.selectedOptions.filter(opt => opt.id === option.id);
      if (filter.length) {
        this.selectedOptions.splice(this.selectedOptions.indexOf(filter[0]), 1);
      } else {
        this.selectedOptions.push(option);
      }
    },
    hasOption(id) {
      return this.selectedOptions.find(option => option.id === id);
    },
    setOptions() {
      const idsCurrentOptions = this.currentOptions.map(option => option.id);
      this.selectedOptions = this.options.filter(option =>
        idsCurrentOptions.includes(option.id)
      );
    }
  },
  created() {
    this.setOptions();

    if (this.idDropdown === "segments") this.open = true;
  }
};
</script>

<style lang="scss" scoped>
.btn-dropdown {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 30px;
  border-radius: 17px;
  text-transform: uppercase;
  font-weight: bold;
  color: #fff;
  font-size: 0.9rem;
  white-space: nowrap;
  background: #b3b3b3;
  svg {
    font-size: 1.2rem !important ;
    margin-left: 5px;
    transition: all 0.3s ease;
  }
  &.active {
    svg {
      transform: rotate(-90deg);
    }
  }
}

.dropdown-box {
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
  background-color: #fff;
  border-radius: 7px;
  padding-right: 5px;
  display: none;
  animation: grow 400ms ease-in-out forwards;
  &.active {
    display: block;
  }
  div {
    overflow-y: auto;
    max-height: 200px;
    &::-webkit-scrollbar {
      width: 8px;
    }
    &::-webkit-scrollbar-track {
      background: rgba(0, 0, 0, 0);
      border-radius: 10px;
    }
    &::-webkit-scrollbar-thumb {
      background: #e6e6e6;
      border-radius: 10px;
      height: 40px;
    }
    &::-webkit-scrollbar-thumb:hover {
      background: #ffffff;
    }
  }
  a {
    display: flex;
    align-items: center;
    padding: 2px 10px;
    margin: 2px 5px 2px 0;
    text-transform: uppercase;
    color: #6b6b6b;
    font-size: 0.7rem;
    cursor: pointer;
    text-decoration: none;
    &::before {
      content: "";
      display: inline-block;
      width: 10px;
      height: 10px;
      border: 2px solid #fff;
      border-radius: 50%;
      margin-right: 6px;
      box-shadow: 0px 0px 1px 0px #6b6b6b;
      transition: all 0.3s ease;
    }
    &:hover {
      background: rgba(0, 0, 0, 0.05);
    }
    &.active {
      background: rgba(0, 0, 0, 0.05);
      &::before {
        background: #6b6b6b;
      }
    }
  }
}

.no-options {
  color: #6b6b6b;
}
</style>
