<template>
  <half-circle-spinner :animation-duration="1000" :size="20" :color="color" />
</template>

<script>
import { HalfCircleSpinner } from "epic-spinners";
export default {
  name: "loder",
  props: ["color"],
  components: {
    HalfCircleSpinner
  }
};
</script>

<style></style>
