<template>
  <div class="col-md-3 p-md-0 pb-4">
    <div
      class="
        card-b
        p-3
        border-radius-left
        d-flex
        flex-column
        align-items-center
      "
    >
      <div class="d-flex flex-column align-items-center">
        <div
          class="profile-img mt-4"
          :style="{
            'background-color': $store.getters.theme[0],
            color: $store.getters.theme[3]
          }"
        >
          <img
            v-if="$store.getters.info.user.picture"
            :src="$store.getters.info.user.picture"
            alt="Profile Picture"
          />
          <b-icon
            v-else
            class="icon-profile"
            icon="person-fill"
            aria-hidden="true"
          ></b-icon>
        </div>
        <p class="name">{{ $store.getters.info.user.name }}</p>
      </div>

      <div class="info d-flex flex-column align-items-center text-center mt-5">
        <img src="@/img/forum-img.svg" alt="" />
        <h3 class="mt-4 mb-1" :style="{ color: $store.getters.theme[2] }">
          {{ translation.forum.welcome_to_forum }}
        </h3>
        <p v-if="$store.getters.info.user.type === userTypesText.participant">
          {{ translation.forum.join_discussion }}
        </p>
        <p v-else>
          {{ translation.forum.create_post_engage }}
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import { userTypesText } from "@/constants";
export default {
  data() {
    return {
      userTypesText
    };
  }
};
</script>

<style lang="scss" scoped>
.border-radius-right {
  border-radius: 0;
  border-top-right-radius: 20px;
  border-bottom-right-radius: 20px;
}

.border-radius-left {
  border-radius: 0;
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;
}

.profile-img {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100px;
  height: 100px;
  border-radius: 50%;
  overflow: hidden;
  margin-bottom: 5px;

  img {
    object-fit: cover;
    height: 100px;
    width: 100px;
  }
}
.name {
  margin: 0 auto;
  max-width: 100px;
  font-weight: bold;
  color: #6b6b6b;
}

.info {
  font-size: 1rem;
  color: #6b6b6b;
  h3 {
    font-size: 1rem;
    font-weight: bold;
  }
}
</style>
