<template>
  <div class="wrapper">
    <div class="pass-modal p-5">
      <h2
        class="h5 font-weight-bold mb-0"
        :style="{ color: $store.getters.theme[3] }"
      >
        {{ translation.pass.reset_password_text }}
      </h2>
      <div class="inputs">
        <div class="mb-4 mt-4 input">
          <label for="password-first-login">{{
            translation.pass.new_password_text
          }}</label>
          <input
            :type="eyePass ? 'text' : 'password'"
            class="form-control p-4 pr-5"
            id="password-first-login"
            :placeholder="translation.pass.type_your_password_text"
            v-model="newPass"
          />
          <div class="eye-pass" @click="eyePass = !eyePass">
            <b-icon v-if="eyePass" icon="eye" aria-hidden="true"></b-icon>
            <b-icon v-else icon="eye-slash" aria-hidden="true"></b-icon>
          </div>
        </div>
        <div class="mb-4 mt-4 input">
          <label for="confirm-password-first-login">{{
            translation.pass.confirm_new_password_text
          }}</label>
          <input
            :type="eyePassConfirm ? 'text' : 'password'"
            class="form-control p-4 pr-5"
            id="confirm-password-first-login"
            :placeholder="translation.pass.type_your_password_text"
            v-model="confirmPass"
          />
          <div class="eye-pass" @click="eyePassConfirm = !eyePassConfirm">
            <b-icon
              v-if="eyePassConfirm"
              icon="eye"
              aria-hidden="true"
            ></b-icon>
            <b-icon v-else icon="eye-slash" aria-hidden="true"></b-icon>
          </div>
        </div>
      </div>
      <div class="btns pt-3">
        <button class="btn hover mr-2" @click="logout">
          <p>
            {{ translation.header.logout }}
          </p>
        </button>
        <button class="btn hover" @click="changePassword">
          <div
            v-if="loaderChangePassword"
            class="d-flex justify-content-center"
          >
            <loader2 color="#6b6b6b" />
          </div>
          <p v-else>
            {{ translation.global.save }}
          </p>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { api } from "@/services.js";
import loader2 from "@/components/general/loader2.vue";
import jwt_decode from "jwt-decode";
import { userTypesText } from "@/constants";

export default {
  components: {
    loader2
  },
  data() {
    return {
      newPass: "",
      confirmPass: "",
      loaderChangePassword: false,
      eyePass: false,
      eyePassConfirm: false,
      userTypesText
    };
  },
  methods: {
    logout() {
      this.$disconnect();
      this.$store.commit("UPDATE_CHAT_IS_CONNECTED", false);
      this.$store.commit("UPDATE_IS_LOGOUT", true);
      this.$router.push({ name: "login" });
      this.$store.dispatch("logOut");
    },
    changePassword() {
      if (!this.loaderChangePassword) {
        if (this.newPass.length && this.newPass === this.confirmPass) {
          if (
            this.newPass.match(
              /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[\W|_]).{8,}$/
            )
          ) {
            let user = "operator/first-login";
            if (this.$store.getters.info.user.type === this.userTypesText.admin)
              user = "admin";

            this.loaderChangePassword = true;
            api
              .put(
                `${user}/change-password`,
                {
                  password: this.newPass,
                  repeat_password: this.confirmPass
                },
                {
                  headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${this.$store.getters.token}`
                  }
                }
              )
              .then(r => {
                if (r.status === 200) {
                  api
                    .put(
                      `token/refresh`,
                      {},
                      {
                        headers: {
                          "Content-Type": "application/json",
                          Authorization: `Bearer ${this.$store.getters.token}`
                        }
                      }
                    )
                    .then(respRefresh => {
                      if (respRefresh.status === 200) {
                        const token = respRefresh.data.token;
                        try {
                          const info = jwt_decode(token);
                          this.$store.commit("UPDATE_IS_LOGOUT", false);
                          this.$store.dispatch("login", { token, info });
                        } catch (error) {
                          this.$store.dispatch("logOut");
                          this.$toast.error(
                            this.translation.errors_global.something_went_wrong
                          );
                        }
                      }

                      this.loaderChangePassword = false;
                    })
                    .catch(() => {
                      this.loaderChangePassword = false;
                      this.$store.commit("UPDATE_IS_LOGOUT", true);
                      this.$router.push({ name: "login" });
                      this.$store.dispatch("logOut");
                    });

                  this.$toast.success(
                    this.translation.success_global.changes_saved
                  );
                }
              })
              .catch(() => {
                this.$toast.error(
                  this.translation.errors_global.something_went_wrong
                );
                this.loaderChangePassword = false;
              });
          } else {
            this.$toast.error(
              this.translation.errors_global.pwd_eight_characters
            );
          }
        } else {
          this.$toast.error(this.translation.errors_global.invalid_combination);
        }
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  width: 100vw;
  height: 100vh;
  background: rgba(255, 255, 255, 0.62);
  z-index: 1000;
  top: 0;
}

.pass-modal {
  background: #ffffff;
  box-shadow: 0px 0px 45px -20px #639696;
  border-radius: 20px;
}

.inputs {
  display: flex;
  flex-wrap: wrap;
  label {
    color: #6b6b6b;
  }
  .input {
    position: relative;
    &:nth-of-type(1) {
      margin-right: 20px;
    }
  }
}

.btns {
  display: flex;
  justify-content: flex-end;
  button {
    text-align: center;
    text-transform: uppercase;
    color: #6b6b6b;
    font-weight: bold;
    letter-spacing: 0.16em;
    border-radius: 17px;
    border: none;
    padding: 5px 20px;
    align-self: flex-end;
    border: 1px solid #c8dada;
    background: none;
    min-width: 120px;
    &:nth-of-type(2) {
      background: #c8dada;
    }
    p {
      margin: 0;
    }
  }
}

.eye-pass {
  position: absolute;
  top: 40px;
  right: 20px;
  font-size: 24px;
  color: #bbb;
  cursor: pointer;
}
</style>
