var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(
      _vm.$store.getters.info.user.type === _vm.userTypesText.moderator ||
        _vm.$store.getters.info.user.type === _vm.userTypesText.admin
    )?_c('div',{staticClass:"filters mx-md-3 0 pt-4"},[_c('label',{staticClass:"pr-md-3 pr-2 m-0",attrs:{"for":"filter-radios"}},[_vm._v(_vm._s(_vm.translation.global.filter_by)+":")]),_c('b-form-radio',{staticClass:"pr-md-4 pr-2",attrs:{"name":"filter-radios","value":"checked"},model:{value:(_vm.filterCheck),callback:function ($$v) {_vm.filterCheck=$$v},expression:"filterCheck"}},[_vm._v(_vm._s(_vm.translation.replies.solved))]),_c('b-form-radio',{staticClass:"pr-md-4 pr-2",attrs:{"name":"filter-radios","value":"unchecked"},model:{value:(_vm.filterCheck),callback:function ($$v) {_vm.filterCheck=$$v},expression:"filterCheck"}},[_vm._v(_vm._s(_vm.translation.replies.not_solved))]),_c('b-form-radio',{attrs:{"name":"filter-radios","value":"all"},model:{value:(_vm.filterCheck),callback:function ($$v) {_vm.filterCheck=$$v},expression:"filterCheck"}},[_vm._v(_vm._s(_vm.translation.replies.all))])],1):_vm._e(),_c('div',{ref:"scroll"},[(
        (_vm.$store.getters.info.user.type !== _vm.userTypesText.participant ||
          _vm.totalAnswers(_vm.activeTask.id)) &&
          _vm.answers.length
      )?_c('div',{staticClass:"container pt-4"},_vm._l((_vm.answers),function(answer,index){return _c('div',{key:answer.answer_id},[(
            _vm.activity.visibility.id === _vm.visibilityCodes.all_visible ||
              (_vm.activity.visibility.id === _vm.visibilityCodes.after_complete &&
                _vm.is_completed) ||
              answer.participant.id === _vm.$store.getters.info.user.sub ||
              _vm.$store.getters.info.user.type !== _vm.userTypesText.participant
          )?_c('div',{staticClass:"row justify-content-center pb-3 px-md-5 px-2"},[_c('div',{staticClass:"col-md-2 participant p-2",class:{
              pointer:
                _vm.$store.getters.info.user.type === _vm.userTypesText.moderator ||
                _vm.$store.getters.info.user.type === _vm.userTypesText.admin
            },on:{"click":function($event){return _vm.goToParticipant(answer)}}},[_c('div',{style:({
                'background-color': _vm.$store.getters.theme[0],
                color: _vm.$store.getters.theme[3]
              })},[(answer.participant.picture)?_c('img',{attrs:{"src":answer.participant.picture,"alt":"Picture"}}):_c('b-icon',{staticClass:"icon-profile",attrs:{"icon":"person-fill","aria-hidden":"true"}})],1),_c('p',{style:({
                color: _vm.$store.getters.theme[3]
              })},[(
                  _vm.$store.getters.info.user.type === _vm.userTypesText.moderator
                )?_c('span',[_vm._v(" "+_vm._s(_vm.namePrivacy( _vm.privacy.moderators.participants_identifier_type_id, answer.participant.name, answer.participant.id ))+" ")]):(
                  _vm.$store.getters.info.user.type === _vm.userTypesText.participant
                )?_c('span',[_vm._v(" "+_vm._s(_vm.namePrivacy( _vm.privacy.participants.participants_identifier_type_id, answer.participant.name, answer.participant.id ))+" ")]):(
                  _vm.$store.getters.info.user.type === _vm.userTypesText.observer
                )?_c('span',[_vm._v(" "+_vm._s(_vm.namePrivacy( _vm.privacy.observers.participants_identifier_type_id, answer.participant.name, answer.participant.id ))+" ")]):_c('span',[_vm._v(" "+_vm._s(answer.participant.name)+" ")])])]),_c('div',{staticClass:"col-md-10 data px-3",style:({
              'background-color': _vm.$store.getters.theme[0]
            })},[_c('get-answer-data',{attrs:{"answer":answer}}),_c('div',{staticClass:"buttons-comment d-flex justify-content-between align-items-center mt-3"},[_c('div',{staticClass:"d-flex align-items-center"},[(
                    _vm.$store.getters.info.user.type ===
                      _vm.userTypesText.moderator ||
                      _vm.$store.getters.info.user.type === _vm.userTypesText.admin ||
                      _vm.$store.getters.info.user.type ===
                        _vm.userTypesText.observer ||
                      answer.participant.id === _vm.$store.getters.info.user.sub
                  )?_c('div',[_c('button',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",value:({ variant: 'secondary' }),expression:"{ variant: 'secondary' }",modifiers:{"hover":true}}],staticClass:"viewed-button blue",class:{
                      active: +answer.viewed,
                      participant: !(
                        _vm.$store.getters.info.user.type ===
                          _vm.userTypesText.moderator ||
                        _vm.$store.getters.info.user.type === _vm.userTypesText.admin
                      )
                    },attrs:{"title":_vm.$store.getters.info.user.type ===
                        _vm.userTypesText.moderator ||
                      _vm.$store.getters.info.user.type === _vm.userTypesText.admin
                        ? _vm.translation.replies.reply_viewed
                        : _vm.$store.getters.info.user.type ===
                          _vm.userTypesText.participant
                        ? _vm.translation.replies.moderate_viewed_answer
                        : ''},on:{"click":function($event){return _vm.toggleValues(answer, index, 'viewed')}}},[_c('b-icon',{staticClass:"mr-1",attrs:{"icon":"eye"}})],1),_c('button',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",value:({ variant: 'secondary' }),expression:"{ variant: 'secondary' }",modifiers:{"hover":true}}],staticClass:"viewed-button",class:{
                      active: !+answer.viewed,
                      participant: !(
                        _vm.$store.getters.info.user.type ===
                          _vm.userTypesText.moderator ||
                        _vm.$store.getters.info.user.type === _vm.userTypesText.admin
                      )
                    },attrs:{"title":_vm.$store.getters.info.user.type ===
                        _vm.userTypesText.moderator ||
                      _vm.$store.getters.info.user.type === _vm.userTypesText.admin
                        ? _vm.translation.replies.mark_reply_viewed
                        : ''},on:{"click":function($event){return _vm.toggleValues(answer, index, 'viewed')}}},[_c('img',{attrs:{"src":require("@/img/eye-closed.svg"),"alt":"Not viewed"}})])]):_vm._e(),_c('reject-answer',{attrs:{"activeTask":_vm.activeTask,"anwserIndex":index,"answer":answer,"getAnswers":_vm.getAnswers}}),(
                    _vm.$store.getters.info.user.type !==
                      _vm.userTypesText.participant ||
                      (answer.participant.id ===
                        _vm.$store.getters.info.user.sub &&
                        +answer.total_sub_replies)
                  )?_c('button',{staticClass:"btn-chat hover",style:({ color: _vm.$store.getters.theme[2] }),on:{"click":function($event){return _vm.toggleSubReplies(answer.answer_id)}}},[(
                      _vm.$store.getters.info.user.type !== _vm.userTypesText.observer
                    )?_c('span',[_vm._v(" "+_vm._s(answer.has_sub_replies ? _vm.translation.replies.see_comments : _vm.translation.replies.comment)+" ")]):_c('span',[_vm._v(" "+_vm._s(_vm.translation.replies.see_comments)+" ")])]):_vm._e()],1),(
                  _vm.$store.getters.info.user.type === _vm.userTypesText.moderator ||
                    _vm.$store.getters.info.user.type === _vm.userTypesText.admin
                )?_c('div',[_c('button',{staticClass:"check-button blue",class:{
                    active: +answer.checked,
                    participant: !(
                      _vm.$store.getters.info.user.type ===
                        _vm.userTypesText.moderator ||
                      _vm.$store.getters.info.user.type === _vm.userTypesText.admin
                    )
                  },on:{"click":function($event){return _vm.toggleValues(answer, index, 'check')}}},[_c('b-icon',{staticClass:"mr-1",attrs:{"icon":"check-circle"}}),_vm._v(" "+_vm._s(_vm.translation.replies.solved)+" ")],1),_c('button',{staticClass:"check-button",class:{
                    active: !+answer.checked,
                    participant: !(
                      _vm.$store.getters.info.user.type ===
                        _vm.userTypesText.moderator ||
                      _vm.$store.getters.info.user.type === _vm.userTypesText.admin
                    )
                  },on:{"click":function($event){return _vm.toggleValues(answer, index, 'check')}}},[_c('b-icon',{staticClass:"mr-1",attrs:{"icon":"circle"}}),_vm._v(" "+_vm._s(_vm.translation.replies.solved)+" ")],1)]):_vm._e()]),_c('sub-replies',{attrs:{"showSubReplies":_vm.showSubReplies,"task":_vm.activeTask,"answer":answer},on:{"close":function($event){_vm.showSubReplies = false}}})],1),_c('div',{staticClass:"col-md-2 col-0"}),_c('div',{staticClass:"col-md-10 col-12 my-2"},[_c('labels',{attrs:{"answerProp":answer}})],1)]):_vm._e()])}),0):(
        _vm.$store.getters.info.user.type !== _vm.userTypesText.participant &&
          !_vm.loaderAnswer
      )?_c('div',{staticClass:"d-flex justify-content-center py-5"},[_vm._v(" "+_vm._s(_vm.translation.task.no_answers)+" ")]):_vm._e(),(_vm.loaderAnswer)?_c('div',{staticClass:"d-flex justify-content-center py-5"},[_c('loader')],1):_vm._e(),(_vm.answers.length)?_c('div',{staticClass:"px-5 pb-5"},[(
          _vm.minPerPage < _vm.total &&
            (_vm.activity.visibility.id === _vm.visibilityCodes.all_visible ||
              (_vm.activity.visibility.id === _vm.visibilityCodes.after_complete &&
                _vm.is_completed) ||
              _vm.$store.getters.info.user.type !== _vm.userTypesText.participant)
        )?_c('pagination-query',{attrs:{"perPage":_vm.perPage,"total":_vm.total,"queryName":"_pageReplies","options":[5, 10, 20, 50, 100]},on:{"perPageUpdate":function (newPerPage) { return (_vm.perPage = newPerPage); }}}):_vm._e()],1):_vm._e()])])}
var staticRenderFns = []

export { render, staticRenderFns }