<template>
  <div ref="blank" class="d-flex align-items-center">
    <button class="btn btn-blank mr-2" ref="btn" @click="addBlank">
      {{ translation.create_task.fill_blank.blank }}
    </button>

    <tooltip :text="translation.create_task.fill_blank.tooltip_blank" />
  </div>
</template>

<script>
import tooltip from "@/components/general/tooltip.vue";

export default {
  components: {
    tooltip
  },
  props: {
    taskText: {
      required: true
    },
    task: {
      required: true
    }
  },
  data() {
    return {};
  },
  computed: {
    taskObj() {
      return {
        statement: this.taskText
      };
    },
    whitespaceError() {
      let message = null;

      if (!this.taskObj.statement.match(/<input.*?>/g))
        message = this.translation.create_task.fill_blank.error.error_no_blanks;
      return message;
    }
  },
  watch: {
    taskObj() {
      this.$emit("update", this.taskObj);
    },
    whitespaceError() {
      this.$emit("error", this.whitespaceError);
    }
  },
  methods: {
    addBlank() {
      this.$emit("blank");
      this.$refs.btn.blur();
    },
    getDescription() {
      this.$emit("update", this.taskObj);
    }
  },
  created() {
    this.getDescription();
    this.$emit("error", this.whitespaceError);
  }
};
</script>

<style lang="scss" scoped>
.btn-blank {
  width: 120px;
  height: 30px;
  border-radius: 17px;
  text-transform: uppercase;
  font-weight: bold;
  border: 1px solid #b3b3b3;
  color: #6b6b6b;
  font-size: 0.65rem;
  white-space: nowrap;
  margin-left: 10px;
}
</style>
