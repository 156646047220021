<template>
  <div>
    <div class="mb-3">
      <div
        class="d-flex align-items-center my-4"
        v-for="(option, index) in answer.answer_object.options"
        :key="index"
      >
        <div class="time">{{ option.timestamp | formatTime }}</div>
        <div>
          <p>{{ option.icon }} {{ option.label }}</p>
          <p>
            <span class="font-weight-bold">
              {{ translation.answer_task.comments }}:
            </span>
            {{ option.comment }}
          </p>
        </div>
      </div>
      <p v-if="answer.answer_object && answer.answer_object.comment.length">
        <span :style="{ color: $store.getters.theme[3] }" class="comments">
          {{ translation.answer_task.comments }}
        </span>
        {{ answer.answer_object.comment }}
      </p>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    answer: {
      required: true
    }
  },
  filters: {
    formatTime(value) {
      const date = new Date(0);
      date.setSeconds(value);

      return date.toISOString().substr(11, 8);
    }
  }
};
</script>

<style lang="scss" scoped>
.time {
  display: flex;
  align-items: center;
  font-size: 1.2rem;
  font-weight: bold;
  color: #6b6b6b;
  background: #efefef;
  padding: 10px;
  border-radius: 10px;
  margin-right: 10px;
}

.comments {
  margin-top: 20px;
  display: block;
  text-transform: uppercase;
  font-weight: bold;
}
</style>
