var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(!_vm.feed)?_c('div',{staticClass:"top-activities mx-3 mb-4 px-2",class:{
      'card-b': _vm.$store.getters.info.user.type !== _vm.userTypesText.participant,
      'py-4': _vm.$store.getters.info.user.type !== _vm.userTypesText.participant
    }},[_c('div',[(
          _vm.$store.getters.info.user.type === _vm.userTypesText.admin ||
            _vm.$store.getters.info.user.type === _vm.userTypesText.moderator ||
            (_vm.$store.getters.info.user.type === _vm.userTypesText.observer &&
              !+_vm.permissions.observers.hide_activities_stats)
        )?_c('router-link',{staticClass:"btn btn-stats ml-md-3 my-1 hover",style:({ background: _vm.$store.getters.theme[1] }),attrs:{"to":{ name: 'statsactivities', query: { _q: '', _page: 1 } }}},[_vm._v(" "+_vm._s(_vm.translation.stats_assignments.assignments_statistics)+" ")]):_vm._e()],1),_c('search',{staticClass:"my-1 ml-3"})],1):_vm._e(),(_vm.filterActivities.length && !_vm.loader)?_c('div',{staticClass:"d-flex flex-wrap"},_vm._l((_vm.filterActivities),function(activity){return _c('div',{key:activity.id,staticClass:"col-md-4 col-sm-6 mb-md-4 mb-3",class:{
        'col-lg-4':
          _vm.$store.getters.info.user.type === _vm.userTypesText.participant,
        'col-lg-3':
          _vm.$store.getters.info.user.type !== _vm.userTypesText.participant
      }},[_c('div',{attrs:{"id":("gear-drop-" + (activity.id))}},[_c('div',{staticClass:"card-b p-0 pointer",class:{ disabled: activity.is_active === '0' },on:{"click":function($event){return _vm.openMenu({
              _activity: activity.id,
              _pageParticipant: 1,
              _pageReplies: 1,
              _q: ''
            })}}},[_c('div',[_c('div',{staticClass:"card-header p-0"},[_c('div',{staticClass:"overflow-hidden"},[(activity.banner)?_c('img',{attrs:{"src":activity.banner}}):_vm._e()]),_c('div',{staticClass:"check-icon",style:({
                  color: _vm.$store.getters.theme[3]
                })},[(
                    (_vm.$store.getters.info.user.type ===
                      _vm.userTypesText.moderator ||
                      _vm.$store.getters.info.user.type ===
                        _vm.userTypesText.admin) &&
                      (!_vm.loaderCopy || activity.id !== _vm.copyActivityId)
                  )?_c('b-icon',{staticClass:"h2 m-0",attrs:{"icon":"gear-fill","aria-hidden":"true"}}):(_vm.loaderCopy && activity.id === _vm.copyActivityId)?_c('loader2',{attrs:{"color":_vm.$store.getters.theme[2]}}):(
                    !activity.is_completed &&
                      _vm.$store.getters.info.user.type !== _vm.userTypesText.observer
                  )?_c('b-icon',{staticClass:"h2 m-0",attrs:{"icon":"caret-right-fill","aria-hidden":"true"}}):_c('b-icon',{staticClass:"h2 m-0",attrs:{"icon":"eye-fill","aria-hidden":"true"}}),_c('div',{staticClass:"dropdown-gear",attrs:{"id":("dropdown-gear-" + (activity.id))}},[_c('a',{style:({
                      color: _vm.$store.getters.theme[3]
                    }),on:{"click":function($event){return _vm.openTasks({
                        _activity: activity.id,
                        _pageParticipant: 1,
                        _pageReplies: 1,
                        _q: ''
                      })}}},[_c('b-icon',{attrs:{"icon":"eye-fill","aria-hidden":"true"}}),_vm._v(_vm._s(_vm.translation.assignment.view_assignment))],1),_c('a',{style:({
                      color: _vm.$store.getters.theme[3]
                    }),on:{"click":function($event){return _vm.editActivity(activity.id)}}},[_c('b-icon',{attrs:{"icon":"pencil-fill","aria-hidden":"true"}}),_vm._v(_vm._s(_vm.translation.assignment.edit_assignment))],1),_c('a',{style:({
                      color: _vm.$store.getters.theme[3]
                    }),on:{"click":function($event){return _vm.editTask(activity.id)}}},[_c('b-icon',{attrs:{"icon":"plus-circle-fill","aria-hidden":"true"}}),_vm._v(_vm._s(_vm.translation.assignment.edit_task))],1),_c('a',{style:({
                      color: _vm.$store.getters.theme[3]
                    }),on:{"click":function($event){return _vm.copyActivity(activity.id)}}},[_c('p',{staticClass:"d-flex align-items-center mb-0"},[_c('b-icon',{attrs:{"icon":"stickies-fill","aria-hidden":"true"}}),_c('span',[_vm._v(" "+_vm._s(_vm.translation.assignment.copy_assignment)+" ")])],1)]),_c('a',{style:({
                      color: _vm.$store.getters.theme[3]
                    }),on:{"click":function($event){return _vm.$bvModal.show(("delete-activity-" + (activity.id)))}}},[_c('b-icon',{attrs:{"icon":"trash-fill","aria-hidden":"true"}}),_vm._v(_vm._s(_vm.translation.global.delete))],1),_c('b-modal',{attrs:{"cancel-title":_vm.translation.global.cancel,"id":("delete-activity-" + (activity.id)),"title":_vm.translation.global.delete},on:{"ok":function($event){return _vm.deleteActivity(activity.id)}}},[_vm._v(" "+_vm._s(_vm.translation.assignment.are_you_sure_delete_assignment)+" ")])],1)],1),_vm._m(0,true)]),_c('div',{staticClass:"px-3 py-4"},[_c('div',[_c('p',{staticClass:"h6 font-weight-bold activity-name",style:({
                    color: _vm.$store.getters.theme[3]
                  })},[_vm._v(" "+_vm._s(_vm.translation.assignment.assignment)+" - "),_c('span',{staticClass:"font-weight-bold d-block",style:({ color: _vm.$store.getters.theme[2] })},[_vm._v(_vm._s(activity.name))])])]),_c('div',{staticClass:"\n                  d-flex\n                  align-items-center\n                  justify-content-end\n                  mt-4\n                  activity-bottom\n                "},[(activity.is_completed)?_c('p',[_vm._v(" "+_vm._s(_vm.translation.assignment.completed)+" "),_c('b-icon',{attrs:{"icon":"check-circle"}})],1):_vm._e()])])])])])])}),0):(_vm.queryValue && !_vm.loader)?_c('div',{staticClass:"result d-flex justify-content-center py-5"},[_vm._v(" "+_vm._s(_vm.translation.global.sorry_we_have)+" \""+_vm._s(_vm.queryValue)+"\" ")]):(_vm.loader)?_c('div',{staticClass:"d-flex justify-content-center py-5"},[_c('loader')],1):_c('div',{staticClass:"d-flex justify-content-center py-5"},[_vm._v(" "+_vm._s(_vm.translation.assignment.no_activity)+" ")]),(!_vm.queryValue && !_vm.feed && _vm.minPerPage < _vm.total)?_c('pagination-query',{attrs:{"perPage":_vm.perPage,"total":_vm.total,"options":[12, 24, 48, 96]},on:{"perPageUpdate":function (newPerPage) { return (_vm.perPage = newPerPage); }}}):_vm._e()],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"style"},[_c('img',{staticClass:"img-fluid",attrs:{"src":require("@/img/white-curve-small.png")}})])}]

export { render, staticRenderFns }