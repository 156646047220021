<template>
  <div class="row task-wrapper">
    <div v-if="!loading" class="col">
      <div class="task-text img-question">
        <p v-html="text"></p>
      </div>
      <div class="d-flex flex-wrap">
        <attachment :attachments="task.attachments" :deleteFile="null" />
      </div>
      <div class="d-flex justify-content-center py-4">
        <div
          class="select-box"
          :style="{ background: $store.getters.theme[1] }"
        >
          <p class="mb-1">
            {{ translation.answer_task.reaction.minimum_number }}: {{ min }}
          </p>
          <p class="mb-3">
            {{ translation.answer_task.reaction.maximum_number }}: {{ max }}
          </p>
          <p class="mb-1">
            {{ translation.answer_task.audio.minimum_duration }}:
            {{ formatDuration(minDuration) }}
          </p>
          <p class="mb-0">
            {{ translation.answer_task.audio.maximum_duration }}:
            {{ formatDuration(maxDuration) }}
          </p>
        </div>
      </div>

      <div>
        <div class="recording">
          <button
            :class="{ ['is-recording']: recording, hover: !task.is_answered }"
            class="mic-recording-button mb-4"
            @click="toggleRecording"
            :style="{
              background: recording ? '#df2938' : $store.getters.theme[2]
            }"
            :disabled="task.is_answered"
          >
            <b-icon
              class="play-icon"
              v-if="paused || !started"
              icon="play-fill"
            ></b-icon>
            <b-icon v-else icon="pause"></b-icon>
          </button>

          <p class="bytes mb-4">
            {{ formatDuration(currentTime / 100) }}
          </p>

          <button
            :class="{ hover: started }"
            class="finish-button"
            @click="stop"
            :disabled="task.is_answered || !started || loaderUploadAudio"
            :style="{
              background: $store.getters.theme[2]
            }"
          >
            OK
          </button>
        </div>

        <div v-if="audios.length">
          <div class="audio" v-for="(audio, index) in audios" :key="audio.url">
            <p class="mb-0">
              {{ translation.answer_task.screen_recording.recording }}
              {{ index + 1 }}
            </p>

            <div>
              <audio controls>
                <source :src="audio.url" type="audio/mpeg" />
                Your browser does not support the audio tag.
              </audio>

              <div class="delete-button">
                <button
                  :class="{ hover: !task.is_answered }"
                  class="btn"
                  :disabled="task.is_answered"
                  @click="deleteAudio(index)"
                >
                  <b-icon icon="x"></b-icon>
                </button>
              </div>
            </div>

            <p class="bytes mb-0">
              {{ formatBytes(audio.size) }}
            </p>

            <p class="bytes mb-0">
              {{ formatDuration(audio.duration) }}
            </p>
          </div>
        </div>
      </div>

      <div class="d-flex justify-content-center my-5" v-if="loaderUploadAudio">
        <loader />
      </div>

      <div class="comments mt-4">
        <label
          for="comments"
          v-if="
            task.question_object.has_custom_comment_label &&
              task.question_object.comment_label &&
              task.question_object.comment_label.length
          "
          >{{ task.question_object.comment_label }}</label
        >
        <label for="comments" v-else>
          {{ translation.answer_task.comments }}
        </label>
        <textarea
          class="form-control"
          id="textarea"
          v-model="comments"
          :maxlength="maxCharacters"
          rows="3"
          max-rows="6"
          :disabled="task.is_answered"
        ></textarea>
        <characterAndWordCounter
          :count="
            hasWordsLimitation
              ? this.comments.length
                ? this.comments.trim().split(/\s+/).length
                : 0
              : comments.length
          "
          :max="hasWordsLimitation ? maxWords : maxCharacters"
          :label="
            hasWordsLimitation
              ? translation.answer_task.words
              : translation.answer_task.characters
          "
        />
      </div>
    </div>
    <div v-if="loading" class="w-100 d-flex justify-content-center">
      <loader />
    </div>
  </div>
</template>

<script>
import { api } from "@/services.js";
import loader from "@/components/general/loader.vue";
import attachment from "@/components/general/attachment.vue";
import characterAndWordCounter from "@/components/general/characterAndWordCounter.vue";
import { tabOptions } from "@/constants";
import { formatBytes } from "@/helpers.js";
import lambda from "@/lambda";
import { v4 as uuidv4 } from "uuid";
import { mapState } from "vuex";
import getBlobDuration from "get-blob-duration";

import { userTypesText } from "@/constants";

export default {
  components: { loader, attachment, characterAndWordCounter },
  props: {
    task: {
      required: true
    },
    save: {
      required: true
    },
    savePreview: {
      required: true
    },
    getTasks: {
      required: true
    }
  },
  data() {
    return {
      tabOptions,
      loading: false,
      comments: "",
      audios: [],
      disabled: false,
      recorder: null,
      started: false,
      recording: false,
      paused: false,
      loaderUploadAudio: false,
      size: 0,
      duration: 0,
      interval: null,
      currentTime: 0
    };
  },

  computed: {
    ...mapState(["audioRecordingTaskFolder"]),

    text() {
      const regexpVideo = /(?:(<iframe.*?src="))(.*?)((?:".*?>))((?:<\/iframe>))?/g;

      return this.$store.getters.info.user.type === userTypesText.participant
        ? this.task.raw_question.replace(
            regexpVideo,
            "<video src='$2' width='350' height='197' frameborder='0' allowfullscreen='allowfullscreen' controls controlsList='nodownload'></iframe>"
          )
        : this.task.raw_question;
    },

    page() {
      return this.$store.getters.activeTaskIndex;
    },

    min() {
      return this.task.question_object.min_answers
        ? this.task.question_object.min_answers
        : 1;
    },

    max() {
      return this.task.question_object.max_answers
        ? this.task.question_object.max_answers
        : 10;
    },

    minDuration() {
      return this.task.question_object.min_duration
        ? this.task.question_object.min_duration
        : 0;
    },

    maxDuration() {
      return this.task.question_object.max_duration
        ? this.task.question_object.max_duration
        : 30;
    },

    mandatoryComment() {
      return this.task.question_object.mandatory_comment;
    },

    obligatoryComment() {
      return this.task.question_object?.has_obligatory_comments;
    },

    hasCharactersLimitation() {
      return this.task.question_object?.has_characters_limitation;
    },

    maxCharacters() {
      return this.hasCharactersLimitation
        ? this.task.question_object?.characters_count?.maximum
        : 5000;
    },

    minCharacters() {
      return this.hasCharactersLimitation
        ? this.task.question_object?.characters_count?.minimum
        : 0;
    },

    hasWordsLimitation() {
      return this.task.question_object?.has_words_limitation;
    },

    maxWords() {
      return this.hasWordsLimitation
        ? this.task.question_object?.words_count?.maximum
        : 2000;
    },

    minWords() {
      return this.hasWordsLimitation
        ? this.task.question_object?.words_count?.minimum
        : 0;
    }
  },

  watch: {
    save() {
      this.answer();
    },

    savePreview() {
      this.answerPreview();
    },

    currentTime() {
      if (this.currentTime / 100 === this.maxDuration) this.stop();
    },

    page() {
      this.init();
    },

    comments() {
      if (this.comments.length >= this.maxCharacters) {
        this.comments = this.comments
          .split("")
          .splice(0, this.maxCharacters)
          .join("");
      }

      const words = this.comments.split(/\s+/);

      const wordsCount = words.length;

      if (wordsCount > this.maxWords) {
        this.comments = words.splice(0, this.maxWords).join(" ");
      }
    }
  },

  methods: {
    formatBytes,

    formatDuration(duration) {
      return new Date(duration * 1000).toISOString().substr(11, 8);
    },

    addAudio(url) {
      this.audios.push({
        url,
        size: this.size,
        duration: this.duration
      });

      this.size = 0;
      this.duration = 0;
    },

    deleteAudio(index) {
      this.audios.splice(index, 1);
    },

    async uploadAudio(blob) {
      this.loaderUploadAudio = true;
      lambda
        .getSignedURL(
          blob,
          `tmp/audio/${this.audioRecordingTaskFolder}`,
          `${uuidv4()}.mp3`
        )
        .then(url => {
          api
            .put(url, blob, {
              headers: {
                "Content-Type": blob.type
              }
            })
            .then(r => {
              this.loaderUploadAudio = false;
              this.addAudio(r.config.url.split("?")[0]);
            })
            .catch(() => {
              this.$toast.error(
                this.translation.errors_global.something_went_wrong
              );
              this.loaderUploadAudio = false;
            });
        })
        .catch(() => {
          this.$toast.error(
            this.translation.errors_global.something_went_wrong
          );
          this.loaderUploadAudio = false;
        });
    },

    async recordAudio() {
      const stream = await navigator.mediaDevices.getUserMedia({ audio: true });

      this.recorder = new MediaRecorder(stream);
      this.recorder.start();
      this.started = true;
      this.recording = true;
      this.size = 0;
      this.duration = 0;

      const audioChunks = [];
      this.recorder.addEventListener("dataavailable", event => {
        audioChunks.push(event.data);
      });

      this.recorder.addEventListener("start", async () => {
        this.interval = setInterval(() => {
          this.currentTime++;
        }, 10);
      });

      this.recorder.addEventListener("stop", async () => {
        clearInterval(this.interval);
        this.currentTime = 0;
        this.recorder = null;
        this.started = false;
        this.recording = false;
        this.paused = false;

        const audioBlob = new Blob(audioChunks, {
          type: "audio/mp3"
        });

        const duration = await getBlobDuration(audioBlob);

        this.size = audioBlob.size;
        this.duration = duration;

        if (this.duration >= this.minDuration) {
          this.uploadAudio(audioBlob);
        } else {
          this.$toast.error(
            `${
              this.translation.answer_task.audio.minimum_duration
            }: ${this.formatDuration(this.minDuration)}`
          );
        }
      });

      this.recorder.addEventListener("pause", () => {
        clearInterval(this.interval);
        this.recording = false;
        this.paused = true;
      });

      this.recorder.addEventListener("resume", () => {
        this.interval = setInterval(() => {
          this.currentTime++;
        }, 10);
        this.recording = true;
        this.paused = false;
      });
    },

    pause() {
      this.recorder.pause();
    },

    resume() {
      this.recorder.resume();
    },

    stop() {
      this.recorder.stop();
    },

    toggleRecording() {
      if (!this.started) this.recordAudio();
      else if (!this.paused) this.pause();
      else this.resume();
    },

    answerPreview() {
      if (!this.loading) {
        if (this.audios.length) {
          if (this.audios.length >= this.min) {
            if (this.audios.length <= this.max) {
              if (this.obligatoryComment && !this.comments.length) {
                this.$toast.error(
                  this.translation.answer_task.obligatory_comment_tost
                );

                return;
              }

              if (
                this.comments.length &&
                this.hasCharactersLimitation &&
                this.comments.length < this.minCharacters
              ) {
                this.$toast.error(
                  `${this.translation.answer_task.min_characters} ${this.minCharacters} ${this.translation.answer_task.characters}`
                );

                return;
              }

              if (this.comments.length && this.hasWordsLimitation) {
                const wordsCount = this.comments.split(/\s+/).length;

                if (wordsCount < this.minWords) {
                  this.$toast.error(
                    `${this.translation.answer_task.min_word} ${this.minWords} ${this.translation.answer_task.words}`
                  );

                  return;
                }
              }

              this.loading = true;

              const answer = {
                comment: this.comments,
                audios: this.audios.map((audio, index) => ({
                  index,
                  ...audio
                })),
                audio_folder: `tmp/audio/${this.audioRecordingTaskFolder}`
              };

              const body = {
                task_id: this.task.id,
                has_attachment: 0,
                has_video: 0,
                has_image: 0,
                raw_answer: JSON.stringify(answer),
                answer
              };

              api
                .post("/answer/send/preview", body, {
                  headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${this.$store.getters.token}`
                  }
                })
                .then(r => {
                  if (r.status === 200) {
                    this.$toast.success(
                      this.translation.answer_task.success.reply_registered
                    );
                    this.getTasks("save");
                    this.loading = false;
                  }
                })
                .catch(() => {
                  this.$toast.error(
                    this.translation.answer_task.error.reply_not_registered
                  );
                  this.loading = false;
                });
            } else {
              this.$toast.error(
                `${this.translation.answer_task.reaction.maximum_number}: ${this.max}`
              );
            }
          } else {
            this.$toast.error(
              `${this.translation.answer_task.reaction.minimum_number}: ${this.min}`
            );
          }
        } else {
          this.$toast.error(this.translation.answer_task.error.empty_answer);
        }
      }
    },
    answer() {
      if (!this.loading) {
        if (this.audios.length) {
          if (this.audios.length >= this.min) {
            if (this.audios.length <= this.max) {
              if (this.obligatoryComment && !this.comments.length) {
                this.$toast.error(
                  this.translation.answer_task.obligatory_comment_tost
                );

                return;
              }

              if (
                this.comments.length &&
                this.hasCharactersLimitation &&
                this.comments.length < this.minCharacters
              ) {
                this.$toast.error(
                  `${this.translation.answer_task.min_characters} ${this.minCharacters} ${this.translation.answer_task.characters}`
                );

                return;
              }

              if (this.comments.length && this.hasWordsLimitation) {
                const wordsCount = this.comments.split(/\s+/).length;

                if (wordsCount < this.minWords) {
                  this.$toast.error(
                    `${this.translation.answer_task.min_word} ${this.minWords} ${this.translation.answer_task.words}`
                  );

                  return;
                }
              }

              this.loading = true;

              const answer = {
                comment: this.comments,
                audios: this.audios.map((audio, index) => ({
                  index,
                  ...audio
                })),
                audio_folder: `tmp/audio/${this.audioRecordingTaskFolder}`
              };

              const body = {
                task_id: this.task.id,
                participant_id: this.$store.getters.info.user.sub,
                has_attachment: 0,
                has_video: 0,
                has_image: 0,
                raw_answer: JSON.stringify(answer),
                answer
              };

              api
                .post("/answer/send", body, {
                  headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${this.$store.getters.token}`
                  }
                })
                .then(r => {
                  if (r.status === 200) {
                    this.$toast.success(
                      this.translation.answer_task.success.reply_registered
                    );
                    this.getTasks("save");
                    this.loading = false;
                  }
                })
                .catch(() => {
                  this.$toast.error(
                    this.translation.answer_task.error.reply_not_registered
                  );
                  this.loading = false;
                });
            } else {
              this.$toast.error(
                `${this.translation.answer_task.reaction.maximum_number}: ${this.max}`
              );
            }
          } else {
            this.$toast.error(
              `${this.translation.answer_task.reaction.minimum_number}: ${this.min}`
            );
          }
        } else {
          this.$toast.error(this.translation.answer_task.error.empty_answer);
        }
      }
    },
    init() {
      if (
        this.task.is_answered ||
        (this.task.answer && this.$route.query._preview)
      ) {
        this.comments = this.task.answer.answer_object.comment;
        this.audios = this.task.answer.answer_object.audios;
      } else {
        this.$store.commit(
          "UPDATE_AUDIO_RECORDING_TASK_FOLDER",
          `answer-audio-recording-${uuidv4()}`
        );

        this.comments = "";
        this.audios = [];
      }
    }
  },
  created() {
    this.init();
  },
  mounted() {
    if (this.$store.getters.info.user.type === userTypesText.participant) {
      const taskWrapper = document.querySelector(".task-wrapper");

      taskWrapper.addEventListener("contextmenu", e => {
        e.preventDefault();
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.task-text {
  display: flex;
  flex-direction: column;
  color: #6b6b6b;
  overflow-wrap: break-word;
}

.comments {
  label {
    color: #6b6b6b;
  }
  textarea {
    width: 100%;
    display: block;
    border: 1px solid #b3b3b3;
    border-radius: 10px;
  }
}

.select-box {
  display: inline-block;
  padding: 20px;
  border-radius: 20px;
  color: #6b6b6b;
  font-weight: bold;
  font-size: 1.2rem;
}

.recording {
  margin-top: 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.mic-recording-button {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100px;
  height: 100px;
  border-radius: 50%;
  transition: all 0.3s ease;
  font-size: 3rem;
  border: none;
  box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.1);
  color: #ffffff;
  background: #0091ea;

  &:focus {
    outline: none;
    box-shadow: 0 0 0 0.2rem rgba(123, 123, 123, 0.25);
  }

  &.is-recording {
    background: #f74567;
    animation-name: recording;
    animation-duration: 1s;
    animation-iteration-count: infinite;
  }
}

.play-icon {
  position: relative;
  left: 2px;
}

.audio {
  margin-top: 60px;
  display: flex;
  gap: 1rem;
  flex-wrap: wrap;
  align-items: center;

  > div {
    display: flex;
    gap: 5px;
    align-items: center;
  }

  audio {
    width: 400px;
    @media (max-width: 767.98px) {
      max-width: 280px;
    }
  }
}

.delete-button {
  button {
    padding: 5px;
    border-radius: 50%;
    color: #f74567;

    &:hover {
      background: rgba(0, 0, 0, 0.1);
    }
  }
}

.bytes {
  color: #6b6b6b;
}

.finish-button {
  display: flex;
  align-items: center;
  background: none;
  border: none;
  font-size: 1.2rem;
  font-weight: bold;
  color: #ffffff;
  font-weight: bold;
  padding: 5px 40px;
  border-radius: 10px;

  &:focus {
    box-shadow: 0 0 0 0.2rem rgba(123, 123, 123, 0.25);
    outline: none;
  }
}

@keyframes recording {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.05);
  }
  100% {
    transform: scale(1);
  }
}
</style>
