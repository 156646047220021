<template>
  <div>
    <div class="task-statement-formatted">
      <p :class="{ active: showMore }" v-html="getFormattedTask"></p>
      <div class="m-0" v-if="showMore">
        <div class="mt-4">
          <div v-if="image">
            <div class="d-flex flex-wrap">
              <div
                class="col-sm-6 col-md-4 col-lg-3 mb-4"
                v-for="(card, index) in task.question_object.cards"
                :key="index"
              >
                <div class="card-image">
                  <div
                    :style="{
                      background: $store.getters.theme[0]
                    }"
                  >
                    <div class="card-value"></div>
                    <img
                      v-if="card.value.img.length"
                      :src="card.value.img"
                      :alt="card.value.text"
                    />
                  </div>
                  {{ card.value.text }}
                </div>
              </div>
            </div>
          </div>
          <div v-else>
            <div class="d-flex flex-column align-items-center">
              <div
                class="col-md-8"
                v-for="(card, index) in task.question_object.cards"
                :key="index"
              >
                <div class="card-noimage p-3 mb-2">
                  {{ card.value.text }}
                  <div class="card-value"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="task-statement-separator">
        <button
          :style="{ color: $store.getters.theme[2] }"
          class="task-statement-show-more hover"
          @click="showMore = !showMore"
        >
          {{
            showMore
              ? translation.global.show_less
              : translation.global.show_more
          }}
        </button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    task: {
      required: true
    },
    getFormattedTask: {
      required: true
    }
  },
  computed: {
    image() {
      return this.task.question_object.cards.filter(card => card.has_image)
        .length;
    }
  },
  data() {
    return {
      showMore: false
    };
  }
};
</script>

<style lang="scss" scoped>
.card-noimage {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 14px;
  border: 3px solid #ebf2f2;
  color: #6b6b6b;
  font-weight: bold;
  transition: all 0.3s ease;
}

.card-image {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: #6b6b6b;
  font-weight: bold;

  > div {
    position: relative;
    overflow: hidden;
    img {
      object-fit: cover;
      width: 200px;
      height: 140px;
    }
    background-color: red;
    display: block;
    width: 100%;
    height: 140px;
    margin-bottom: 15px;
    border-radius: 15px;
    box-shadow: 0px 0px 37px -16px #c4c4c4;
  }
}

@media (max-width: 567.98px) {
  .card-image {
    > div {
      img {
        width: 180px;
        height: 140px;
      }
      width: 180px;
      height: 140px;
    }
  }
}
</style>
