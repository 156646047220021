import Vue from "vue";
import { BootstrapVue, IconsPlugin } from "bootstrap-vue";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-vue/dist/bootstrap-vue.css";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import VueToast from "vue-toast-notification";
//import 'vue-toast-notification/dist/theme-default.css';
import "vue-toast-notification/dist/theme-sugar.css";
import "vue-advanced-cropper/dist/style.css";
import VueNativeSock from "vue-native-websocket";
import { translation } from "./translation.js";
import { terms_and_policies } from "./termsAndPoliciesDefault.js";

Vue.use(VueNativeSock, process.env.VUE_APP_CHAT_CONNECT, {
  connectManually: true,
  reconnection: true,
});

Vue.config.productionTip = false;

Vue.use(VueToast, {
  position: "bottom",
  duration: 4000,
});

Vue.use(IconsPlugin);

Vue.use(BootstrapVue);

Vue.mixin({
  data() {
    return {
      reporttool: process.env.VUE_APP_REPORTTOOL,
      reporttoolLogin: process.env.VUE_APP_REPORTTOOL_LOGIN,
      logs: process.env.VUE_APP_LOGS,
      chatConnect: process.env.VUE_APP_CHAT_CONNECT,
      chatHost: process.env.VUE_APP_CHAT_HOST,
      exportMediaHost: process.env.VUE_APP_EXPORT_MEDIA_HOST,
      exportChartHost: process.env.VUE_APP_EXPORT_CHART_HOST,
      exportAnswersHost: process.env.VUE_APP_EXPORT_ANSWERS_HOST,
      tinySelfHostedUrl: process.env.VUE_APP_TINY_SELF_HOSTED_URL,
    };
  },
  computed: {
    langAbbreviation() {
      if (this.$store.getters.language) {
        return this.$store.getters.language;
      } else {
        return "en";
      }
    },
    translation() {
      if (this.$store.getters.language) {
        return translation[this.$store.getters.language];
      } else {
        return translation.en;
      }
    },
    termsAndPoliciesDefault() {
      if (this.$store.getters.language) {
        return terms_and_policies[this.$store.getters.language];
      } else {
        return terms_and_policies.en;
      }
    },
  },
  methods: {},
});

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
