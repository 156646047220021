<template>
  <div>
    <router-link :to="{ name: 'login' }" class="logo">
      <img
        v-if="
          $store.getters.community &&
            $store.getters.community.logo &&
            $store.getters.community.logo.length
        "
        class="img-fluid "
        :src="$store.getters.community.logo"
        alt="Logo"
      />
      <img v-else src="@/img/logo-communities.png" alt="Logo" />
    </router-link>
    <div class="wrapper d-flex align-items-center">
      <div class="col-lg-6 px-lg-5">
        <div class="px-lg-5 w-100">
          <div
            :style="{
              background: `${
                $store.getters.theme ? $store.getters.theme[2] : '#108FC3'
              }`
            }"
            class="style"
          ></div>
          <h2>
            {{ translation.pass.reset_password_text }}
          </h2>
          <p class="pt-3 text">
            {{ translation.pass.create_new_pwd }}
          </p>
          <form>
            <div class="mb-4 mt-4 input">
              <input
                type="password"
                class="form-control p-4"
                id="password"
                :placeholder="translation.pass.new_password_text"
                v-model="pass"
              />
              <img src="@/img/padlock.svg" alt="Password Icon" />
            </div>
            <div class="mb-3 input">
              <input
                type="password"
                class="form-control p-4"
                id="confirmpass"
                :placeholder="translation.pass.confirm_new_password_text"
                v-model="confirmpass"
              />
              <img src="@/img/padlock.svg" alt="Password Icon" />
            </div>
            <button
              :style="{
                background: `${
                  $store.getters.theme ? $store.getters.theme[0] : '#F7FAFA'
                }`
              }"
              @click.prevent="change"
              class="btn mt-4 hover d-flex justify-content-center align-items-center"
            >
              <loader2 v-if="loader2" color="#6b6b6b" />
              <p class="m-0" v-if="!loader2">
                {{ translation.global.save }}
              </p>
            </button>
          </form>
        </div>
      </div>
      <div
        class="col-lg-6 d-flex justify-content-center align-self-end px-lg-5"
      >
        <div v-if="$store.getters.community">
          <img
            v-if="+$store.getters.community.theme === themesCodes.themeOrange"
            class="img-fluid pb-lg-5"
            src="@/img/pass1.svg"
            alt="Login
        image"
          />
          <img
            v-if="+$store.getters.community.theme === themesCodes.themeGreen"
            class="img-fluid pb-lg-5"
            src="@/img/pass2.svg"
            alt="Login
        image"
          />
          <img
            v-if="+$store.getters.community.theme === themesCodes.themeYellow"
            class="img-fluid pb-lg-5"
            src="@/img/pass3.svg"
            alt="Login
        image"
          />
          <img
            v-if="+$store.getters.community.theme === themesCodes.themeBlue"
            class="img-fluid pb-lg-5"
            src="@/img/pass4.svg"
            alt="Login
        image"
          />
          <img
            v-if="+$store.getters.community.theme === themesCodes.themePink"
            class="img-fluid pb-lg-5"
            src="@/img/pass5.svg"
            alt="Login
        image"
          />
          <img
            v-if="+$store.getters.community.theme === themesCodes.themeGray"
            class="img-fluid pb-lg-5"
            src="@/img/pass6.svg"
            alt="Login
        image"
          />
        </div>
        <div v-else>
          <img
            class="img-fluid pb-lg-5"
            src="@/img/pass4.svg"
            alt="Login
        image"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { api } from "@/services.js";
import loader2 from "@/components/general/loader2.vue";
import { themesCodes } from "@/constants";

export default {
  name: "Pass",
  components: {
    loader2
  },
  data() {
    return {
      pass: "",
      confirmpass: "",
      loader2: false,
      themesCodes
    };
  },
  methods: {
    change() {
      if (this.pass !== "" && this.confirmpass !== "") {
        if (!this.loader2) {
          if (this.pass === this.confirmpass) {
            if (
              this.pass.match(
                /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[\W|_]).{8,}$/
              )
            ) {
              this.loader2 = true;
              api
                .post(
                  "recovery/password",
                  {
                    password: this.pass
                  },
                  {
                    headers: {
                      "Content-Type": "application/json",
                      Authorization: `Bearer ${this.$route.query.token}`
                    }
                  }
                )
                .then(r => {
                  if (r.status === 200) {
                    this.$router.push({ name: "login" });
                    this.$toast.success(
                      this.translation.pass.success.pwd_successfully_changed
                    );
                  }
                  this.loader2 = false;
                })
                .catch(() => {
                  this.$toast.error(
                    this.translation.errors_global.something_went_wrong
                  );
                  this.loader2 = false;
                });
            } else {
              this.$toast.error(
                this.translation.errors_global.pwd_eight_characters
              );
            }
          } else {
            this.$toast.error(
              this.translation.errors_global.invalid_combination
            );
          }
        }
      } else {
        this.$toast.error(this.translation.errors_global.fill_fields);
      }
    }
  },
  created() {
    if (!this.$route.query.token) this.$router.push({ name: "login" });
  }
};
</script>
<style lang="scss" scoped>
.logo {
  margin-top: 30px;
  margin-left: 30px;
  display: flex;
  img {
    max-height: 55px;
  }
}

@media (max-width: 991.98px) {
  .logo {
    margin-top: 20px;
    margin-left: 20px;
  }
}

.wrapper {
  height: calc(100vh - 80px);
}

.col-lg-6 {
  display: flex;
  position: relative;
  &:nth-of-type(2) {
    display: flex;
    align-items: center;
    img {
      height: 500px;
    }
  }
}

@media (max-width: 991.98px) {
  .col-lg-6 {
    &:nth-of-type(1) {
      margin-top: -30px;
    }
    &:nth-of-type(2) {
      display: none !important;
    }
  }
}

.btn {
  height: 50px;
}

.style {
  display: block;
  width: 31px;
  height: 4px;
  border-radius: 4px;
  background: #639696;
  margin-bottom: 8px;
}

h2 {
  font-size: 1.3rem;
  color: #6b6b6b;
  font-weight: bold;
}

.text {
  color: #6b6b6b;
  font-size: 0.875rem;
}

.input {
  display: flex;
  align-items: center;
  border: 1px solid #e6e6e6;
  color: #6b6b6b;
  font-size: 1rem;
  border-radius: 33px;
  position: relative;
  img {
    position: absolute;
    right: 20px;
  }
}

input {
  width: 100%;
  border: none;
  border-radius: 33px;
}

button {
  width: 100%;
  background: #ebf2f2;
  border-radius: 33px;
  letter-spacing: 0.46em;
  text-transform: uppercase;
  font-weight: bold;
  color: #6b6b6b;
}

a {
  display: block;
  text-align: center;
  color: #6b6b6b;
  text-decoration: underline;
}
</style>
