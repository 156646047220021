<template>
  <div>
    <div class="btn-customize">
      <button
        v-if="!toggleCustomize"
        class="hover"
        @click="toggleCustomize = !toggleCustomize"
      >
        <span>
          {{ translation.replies.charts_tab.customize }}
        </span>
      </button>
    </div>
    <div
      v-if="!loader"
      :class="{ active: toggleCustomize }"
      class="config-wrapper"
    >
      <nav class="config-nav">
        <a
          v-if="!isBubble"
          :class="{ active: option === configOptions.type }"
          :style="{ color: linkColor(configOptions.type) }"
          @click="option = configOptions.type"
        >
          {{ translation.replies.charts_tab.chart_type }}
        </a>
        <a
          :class="{ active: option === configOptions.colors }"
          :style="{ color: linkColor(configOptions.colors) }"
          @click="option = configOptions.colors"
        >
          {{ translation.replies.charts_tab.colors }}
        </a>
        <a
          v-if="!isBubble"
          :class="{ active: option === configOptions.isPercent }"
          :style="{ color: linkColor(configOptions.isPercent) }"
          @click="option = configOptions.isPercent"
        >
          {{ translation.replies.charts_tab.data_type }}
        </a>
      </nav>
      <div class="config-box">
        <transition mode="out-in">
          <config-type
            v-if="option === configOptions.type && config.type && !isBubble"
            :config="config"
            @update="newType => setType(newType)"
          />
          <config-colors
            v-if="option === configOptions.colors && config.theme.colors"
            :config="config"
            :optionsCount="optionsCount"
            :isWordcloud="false"
            @updateColors="newColors => setColors(newColors)"
            @updateHasOneColor="
              newHasOneColor => setHasOneColor(newHasOneColor)
            "
            @updateThemes="newThemes => setThemes(newThemes)"
            @currentNewTheme="currentNewTheme => (newTheme = currentNewTheme)"
          />
          <config-percent
            v-if="option === configOptions.isPercent && !isBubble"
            :config="config"
            @update="newIsPercent => setIsPercent(newIsPercent)"
          />
        </transition>
        <div class="btns-footer">
          <button @click="cancelConfig" class="btn hover">
            {{ translation.global.cancel }}
          </button>
          <button
            :style="{
              background: $store.getters.theme[2]
            }"
            class="btn hover"
            @click="applyConfig"
            :disabled="loader"
          >
            {{ translation.global.save }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import configType from "@/components/charts/configItems/configType.vue";
import configColors from "@/components/charts/configItems/configColors.vue";
import configPercent from "@/components/charts/configItems/configPercent.vue";
import { configOptions } from "@/constants.js";
import { mapState, mapMutations } from "vuex";

export default {
  components: {
    configColors,
    configType,
    configPercent
  },
  props: {
    id: {
      required: true
    },
    taskId: {
      required: true
    },
    activityId: {
      required: true
    },
    optionsCount: {
      required: true
    },
    isBubble: {
      default: false
    }
  },
  data() {
    return {
      newTheme: null,
      toggleCustomize: false,
      option: null,
      config: {
        theme: {
          hasOneColor: false,
          colors: null,
          themes: null
        },
        type: null,
        isPercent: false
      },
      loader: false,
      configOptions
    };
  },
  computed: {
    ...mapState(["configChart"])
  },
  methods: {
    ...mapMutations(["UPDATE_CONFIG_CHART"]),
    updateConfig() {
      const config = JSON.parse(JSON.stringify(this.config));
      this.UPDATE_CONFIG_CHART({
        ...this.configChart,
        [this.id]: config
      });
    },
    setType(newType) {
      this.config.type = newType;
      this.updateConfig();
    },
    setColors(newColors) {
      this.config.theme.colors = newColors;
      this.updateConfig();
    },
    setHasOneColor(newHasOneColor) {
      this.config.theme.hasOneColor = newHasOneColor;
      this.updateConfig();
    },
    setThemes(newThemes) {
      this.config.theme.themes = newThemes;
      this.updateConfig();
    },
    setIsPercent(newIsPercent) {
      this.config.isPercent = newIsPercent;
      this.updateConfig();
    },
    linkColor(currentOption) {
      if (this.option === currentOption)
        return `${this.$store.getters.theme[2]}`;
      else return "#6b6b6b";
    },
    getColorsArray() {
      const array = ["#AC92EB", "#4FC1E8", "#A0D568", "#FFCE54", "#ED5564"];

      if (this.optionsCount > 5) {
        for (let index = 0; index < this.optionsCount - 5; index++) {
          const randomColor = Math.floor(Math.random() * 16777215).toString(16);
          array.push(`#${randomColor}`);
        }
      }

      return array;
    },
    getConfig() {
      this.loader = true;

      let url = `${this.exportChartHost}get/config?taskId=${this.taskId}&activityId=${this.activityId}`;

      axios
        .get(url, {
          headers: {
            Authorization: `Bearer ${this.$store.getters.token}`
          }
        })
        .then(r => {
          if (r.status === 200) {
            const configInitial = r.data.config;

            this.config.theme.hasOneColor = configInitial.theme.hasOneColor;
            this.config.theme.themes = configInitial.theme.themes;

            if (!configInitial.theme.colors) {
              const randomColors = this.getColorsArray();
              this.config.theme.colors = randomColors;
              this.config.theme.themes.push(randomColors);
            } else this.config.theme.colors = configInitial.theme.colors;

            this.config.type = configInitial.type;
            this.config.isPercent = configInitial.isPercent;

            this.updateConfig();
          }
          this.loader = false;
        })
        .catch(() => {
          this.loader = false;
        });
    },
    cancelConfig() {
      this.getConfig();
      this.toggleCustomize = !this.toggleCustomize;
    },
    applyConfig() {
      this.loader = true;
      this.toggleCustomize = !this.toggleCustomize;

      if (this.newTheme) this.setColors(this.newTheme);

      let url = `${this.exportChartHost}update`;

      axios
        .put(
          url,
          {
            taskId: this.taskId,
            activityId: this.activityId,
            config: { ...this.config, isBubble: this.isBubble }
          },
          {
            headers: {
              Authorization: `Bearer ${this.$store.getters.token}`
            }
          }
        )
        .then(r => {
          if (r.status === 200) {
            this.$toast.success(this.translation.success_global.changes_saved);
          }
          this.loader = false;
        })
        .catch(() => {
          this.loader = false;
        });
    }
  },
  created() {
    this.option = this.isBubble
      ? this.configOptions.colors
      : this.configOptions.type;
    this.getConfig();
  }
};
</script>

<style lang="scss" scoped>
.btn-customize {
  display: flex;
  justify-content: flex-end;
  button {
    background: none;
    border: none;
    color: #6b6b6b;
    font-weight: bold;
    font-size: 1.2rem;
    padding: 10px;
    &:hover {
      color: #6b6b6b;
    }
    &:focus {
      outline: none;
    }
    svg {
      font-size: 2rem;
      font-weight: bold;
      text-align: center;
    }
  }
}

.config-wrapper {
  transition: all 0.5s ease-in-out;
  height: 0;
  overflow: hidden;
  &.active {
    display: block;
    height: 330px;
  }
}

@media (max-width: 767.98px) {
  .config-wrapper {
    &.active {
      height: 430px;
    }
  }
}

.config-nav {
  display: flex;
  flex-wrap: wrap;
  a {
    display: block;
    color: #6b6b6b;
    margin-right: 10px;
    padding: 5px 10px;
    font-weight: bold;
    font-size: 0.9rem;
    cursor: pointer;
    &:hover {
      color: #424151;
    }
    &.active {
      text-decoration: underline;
    }
  }
}

.config-box {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border-radius: 10px;
  background: #efefef;
  padding: 20px;
  height: calc(100% - 40px);
}

@media (max-width: 767.98px) {
  .config-box {
    height: calc(100% - 65px);
  }
}

.btns-footer {
  display: flex;
  justify-content: flex-end;
  button {
    color: #ffffff;
    font-weight: bold;
    padding: 5px 20px;
    background: #ccc;
    &:nth-of-type(1) {
      color: #6b6b6b;
      margin-right: 10px;
    }
  }
}
</style>
