<template>
  <div
    :class="{ active: showSubReplies && showSubReplies === answer.answer_id }"
    class="sub-replies px-4 pb-2 pt-4"
  >
    <div
      v-if="
        subReplies.length ||
          $store.getters.info.user.type === userTypesText.moderator ||
          $store.getters.info.user.type === userTypesText.admin
      "
    >
      <div v-for="subReply in subReplies" :key="subReply.id">
        <div class="d-flex align-items-center mb-3 subReply">
          <div class="participant">
            <div
              :style="{
                'background-color': $store.getters.theme[0],
                color: $store.getters.theme[3]
              }"
            >
              <img
                v-if="userOrigin(subReply).picture"
                :src="userOrigin(subReply).picture"
                alt="Picture"
              />
              <b-icon
                v-else
                class="icon-profile"
                icon="person-fill"
                aria-hidden="true"
              ></b-icon>
            </div>
            <p
              :style="{
                color: $store.getters.theme[3]
              }"
            >
              <span v-if="subReply.fk_origin_type_id === userTypes.participant">
                <span
                  v-if="
                    $store.getters.info.user.type === userTypesText.moderator
                  "
                >
                  {{
                    namePrivacy(
                      privacy.moderators.participants_identifier_type_id,
                      userOrigin(subReply).name,
                      userOrigin(subReply).id
                    )
                  }}
                </span>
                <span
                  v-else-if="
                    $store.getters.info.user.type === userTypesText.participant
                  "
                >
                  {{
                    namePrivacy(
                      privacy.participants.participants_identifier_type_id,
                      userOrigin(subReply).name,
                      userOrigin(subReply).id
                    )
                  }}
                </span>
                <span
                  v-else-if="
                    $store.getters.info.user.type === userTypesText.observer
                  "
                >
                  {{
                    namePrivacy(
                      privacy.observers.participants_identifier_type_id,
                      userOrigin(subReply).name,
                      userOrigin(subReply).id
                    )
                  }}
                </span>
                <span v-else>
                  {{ userOrigin(subReply).name }}
                </span>
              </span>
              <span v-else>
                <span
                  v-if="
                    $store.getters.info.user.type === userTypesText.participant
                  "
                >
                  {{
                    namePrivacy(
                      privacy.participants.admins_identifier_type_id,
                      userOrigin(subReply).name,
                      userOrigin(subReply).id
                    )
                  }}
                </span>
                <span v-else>
                  {{ userOrigin(subReply).name }}
                </span>
              </span>
            </p>
          </div>
          <div class="ml-4 w-100">
            <div
              class="
                pl-3
                reply
                d-flex
                align-items-center
                justify-content-between
              "
            >
              {{ subReply.reply }}
              <button
                v-if="
                  +userOrigin(subReply).id === +$store.getters.info.user.sub
                "
                @click="deleteSubReply(subReply.id)"
                class="btn-trash hover"
              >
                <loader2
                  v-if="loaderDelete && loaderDelete === subReply.id"
                  color="#6b6b6b"
                />
                <p v-if="!loaderDelete" class="m-0">
                  <b-icon icon="trash-fill"></b-icon>
                </p>
              </button>
            </div>
            <div class="date-reply my-1">{{ subReply.date | formatDate }}</div>
          </div>
        </div>
      </div>
      <div class="d-flex justify-content-center py-5" v-if="loaderSubReplies">
        <loader />
      </div>
      <b-form-textarea
        v-if="$store.getters.info.user.type !== userTypesText.observer"
        id="textarea"
        size="sm"
        :placeholder="translation.answer_task.comments"
        v-model="text"
        class="mt-4"
      ></b-form-textarea>
      <div
        class="btns d-flex align-items-center justify-content-end mt-3 mb-4"
        v-if="$store.getters.info.user.type !== userTypesText.observer"
      >
        <button class="btn btn-sm mr-2 hover" @click="$emit('close')">
          {{ translation.global.cancel }}
        </button>
        <button class="btn btn-sm hover" @click="send">
          <loader2 v-if="loaderSend" color="#6b6b6b" />
          <p v-if="!loaderSend" class="m-0">
            {{ translation.global.send }}
          </p>
        </button>
      </div>
      <div
        v-if="total > subReplies.length"
        class="d-flex justify-content-center mb-4"
      >
        <button
          :style="{
            'background-color': $store.getters.theme[2]
          }"
          class="btn btn-sm btn-load-more hover"
          @click="loadMore"
        >
          {{ translation.global.load_more }}
        </button>
      </div>
    </div>
    <div v-else>
      {{ translation.forum.comments.no_comments }}
    </div>
  </div>
</template>

<script>
import { api } from "@/services.js";
import loader from "@/components/general/loader.vue";
import loader2 from "@/components/general/loader2.vue";
import { userTypesText, userTypes } from "@/constants";
import { mapState } from "vuex";
import { namePrivacy } from "@/helpers";

export default {
  components: {
    loader2,
    loader
  },

  props: {
    showSubReplies: {
      required: true
    },
    task: {
      required: true
    },
    answer: {
      required: true
    }
  },

  data() {
    return {
      text: "",
      loaderSend: false,
      loaderSubReplies: false,
      loaderDelete: false,
      subReplies: [],
      interval: null,
      perPage: 5,
      page: 1,
      total: 0,
      userTypesText,
      userTypes
    };
  },

  filters: {
    formatDate(value) {
      const date = new Date(value);
      return date.toLocaleString();
    }
  },

  computed: {
    ...mapState(["privacy"])
  },

  methods: {
    namePrivacy,

    deleteSubReply(id) {
      this.loaderDelete = id;
      api
        .delete(
          `task/sub/reply/${id}
          `,
          {
            headers: {
              Authorization: `Bearer ${this.$store.getters.token}`
            }
          }
        )
        .then(r => {
          if (r.status === 200) {
            this.$toast.success(
              this.translation.replies.assignments_tab.modal.success
                .reply_deleted
            );
            this.getSubReplies();
          }
          this.loaderDelete = false;
        })
        .catch(() => {
          this.$toast.error(
            this.translation.errors_global.something_went_wrong
          );

          this.loaderDelete = false;
        });
    },

    loadMore() {
      this.page++;

      this.getSubReplies();
    },

    userOrigin(subReply) {
      const userType = Object.entries(subReply.origin)[0][0];
      return subReply.origin[userType];
    },

    getSubReplies() {
      this.loaderSubReplies = true;
      api
        .get(
          `task/${this.task.id}/sub/reply/participant/${
            this.answer.participant.id
          }?offset=0&limit=${this.perPage * this.page}`,
          {
            headers: {
              Authorization: `Bearer ${this.$store.getters.token}`
            }
          }
        )
        .then(r => {
          if (r.status === 200) {
            const subReplies = r.data.subReplies.reverse();

            if (
              this.$store.getters.info.user.type === userTypesText.observer &&
              +this.privacy.observers.participants_hide_personal_progress
            ) {
              this.subReplies = subReplies.filter(
                subreply => subreply.fk_origin_type_id === userTypes.participant
              );
              this.total =
                r.data.totalSubReplies -
                subReplies.length -
                this.subReplies.length;
            } else {
              this.subReplies = subReplies;
              this.total = r.data.totalSubReplies;
            }
          }
          this.loaderSubReplies = false;
        })
        .catch(() => {
          this.loaderSubReplies = false;
        });
    },

    send() {
      if (!this.loaderSend) {
        if (this.text.length) {
          this.loaderSend = true;

          let body = {
            participant_id: this.answer.participant.id,
            task_id: this.task.id,
            content: this.text,
            has_image: 0
          };

          api
            .post("task/sub/reply", body, {
              headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${this.$store.getters.token}`
              }
            })
            .then(r => {
              if (r.status === 201) {
                this.message = "";
                this.getSubReplies();
                this.text = "";
                this.$toast.success(
                  this.translation.answer_task.success.reply_registered
                );
                if (!+this.answer.has_sub_replies)
                  this.$emit("updateHasSubReplies");
              }
              this.loaderSend = false;
            })
            .catch(() => {
              this.$toast.error(
                this.translation.answer_task.error.reply_not_registered
              );
              this.loaderSend = false;
            });
        } else {
          this.$toast.error(this.translation.answer_task.error.empty_answer);
        }
      }
    }
  },
  created() {
    this.getSubReplies();
  },
  mounted() {
    this.interval = setInterval(this.getSubReplies, 60000);
  },
  beforeDestroy() {
    clearInterval(this.interval);
  }
};
</script>

<style lang="scss" scoped>
.sub-replies {
  display: none;
  animation: show 0.3s linear;
  &.active {
    display: block;
  }
}

.btns {
  button {
    display: flex;
    justify-content: center;
    letter-spacing: 0.13em;
    color: #6b6b6b;
    font-weight: bold;
    min-width: 100px;
    &:nth-of-type(2) {
      background: #c8dada;
      border-radius: 30px;
    }
    &:nth-of-type(1) {
      border: 1.5px solid #c8dada;
      border-radius: 30px;
      background: #ffffff;
    }
  }
}

.participant {
  display: flex;
  flex-direction: column;
  align-items: center;
  div {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 60px;
    height: 60px;
    border-radius: 50%;
    overflow: hidden;
    border: 2px solid #c4c4c4;
    img {
      object-fit: cover;
      height: 60px;
      width: 60px;
    }
  }
  p {
    font-weight: bold;
    font-size: 0.81rem;
    margin-top: 5px;
    width: 60px;
    text-align: center;
    margin-bottom: 0;
  }
}

.data {
  background: #f5f5f5;
  border-radius: 20px;
  color: #6b6b6b;
  font-size: 0.81rem;
  padding: 10px;
  p {
    margin: 0;
  }
  span {
    font-weight: bold;
    margin-right: 10px;
  }
}

.date-reply {
  padding: 0 16px;
  font-size: 12px;
  text-align: end;
}

.reply {
  width: 100%;
  background: #fff;
  padding: 10px;
  border-radius: 10px;
  min-height: 50px;
}

.btn-load-more {
  color: #ffffff;
  font-weight: bold;
}

.btn-trash {
  padding: 5px 10px;
  border: none;
  background: none;
  color: #6b6b6b;
  font-size: 1rem;
  &:focus {
    outline: none;
  }
}
</style>
