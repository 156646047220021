<template>
  <div>
    <div class="pt-4" v-if="answer.answer_object.audios">
      <div
        class="audio mb-3"
        v-for="(audio, index) in answer.answer_object.audios"
        :key="index"
      >
        <audio
          controls
          :controlsList="
            $store.getters.info.user.type !== userTypesText.admin &&
            $store.getters.info.user.type !== userTypesText.moderator
              ? 'nodownload'
              : ''
          "
        >
          <source :src="audio.url" type="audio/mpeg" />
          Your browser does not support the audio tag.
        </audio>
        <p>
          {{ formatBytes(audio.size) }}
        </p>
      </div>
    </div>
    <p
      v-if="
        answer.answer_object &&
          answer.answer_object.comment &&
          answer.answer_object.comment.length
      "
    >
      <span :style="{ color: $store.getters.theme[3] }" class="comments">
        {{ translation.answer_task.comments }}
      </span>
      {{ answer.answer_object.comment }}
    </p>
  </div>
</template>

<script>
import { userTypesText } from "@/constants";
import { formatBytes } from "@/helpers.js";

export default {
  props: {
    answer: {
      required: true
    }
  },

  data() {
    return {
      userTypesText
    };
  },

  methods: {
    formatBytes
  }
};
</script>

<style lang="scss" scoped>
.comments {
  margin-top: 20px;
  display: block;
  text-transform: uppercase;
  font-weight: bold;
}

.audio {
  display: flex;
  align-items: center;
  p {
    margin-bottom: 0;
    margin-left: 10px;
  }
}
</style>
