<template>
  <div class="row task-wrapper" id="task">
    <div v-if="!loading" class="col">
      <div class="task-text  img-question">
        <p v-html="text"></p>
      </div>
      <div class="d-flex flex-wrap mb-3">
        <attachment :attachments="task.attachments" :deleteFile="null" />
      </div>

      <h2 class="title mb-3">{{ translation.task.upload_videos }}:</h2>
      <div class="dropzone-wrapper">
        <div v-if="disabled" class="disabled"></div>
        <dropzone-video-task @files="f => (files = f)"></dropzone-video-task>
      </div>

      <div v-if="files && files.length && hasDescription" class="pt-4">
        <h2 class="title mb-3">{{ translation.task.add_captions_videos }}:</h2>
        <div v-for="(media, index) in files" :key="index" class="pb-4">
          <div>
            <div class="media-wrapper">
              <p>{{ translation.task.video_type }} {{ index + 1 }}:</p>
              <video
                :src="media.url"
                :controlsList="
                  $store.getters.info.user.type !== userTypesText.admin &&
                  $store.getters.info.user.type !== userTypesText.moderator
                    ? 'nodownload'
                    : ''
                "
                controls
              ></video>
            </div>
            <b-form-input
              class="mt-3"
              size="sm"
              v-model="media.description"
              :placeholder="translation.task.caption"
              :disabled="task.is_answered"
              maxlength="100"
            ></b-form-input>
            <character-counter :count="media.description.length" :max="100" />
          </div>
        </div>
      </div>
      <div
        v-else-if="videos && videos.length && task.is_answered"
        class="d-flex flex-wrap pt-4"
      >
        <div
          v-for="(video, index) in videos"
          :key="index"
          class="col-md-4 pb-4 "
        >
          <div>
            <video
              width="100%"
              height="100%"
              :controlsList="
                $store.getters.info.user.type !== userTypesText.admin &&
                $store.getters.info.user.type !== userTypesText.moderator
                  ? 'nodownload'
                  : ''
              "
              :src="video.URL"
              controls
            ></video>
          </div>
        </div>
      </div>

      <div class="comments mt-4">
        <label
          for="comments"
          v-if="
            task.question_object.has_custom_comment_label &&
              task.question_object.comment_label &&
              task.question_object.comment_label.length
          "
          >{{ task.question_object.comment_label }}</label
        >
        <label for="comments" v-else>
          {{ translation.answer_task.comments }}
        </label>

        <textarea
          class="form-control"
          id="textarea"
          v-model="comments"
          :maxlength="maxCharacters"
          rows="3"
          max-rows="6"
          :disabled="task.is_answered"
        ></textarea>
        <characterAndWordCounter
          :count="
            hasWordsLimitation
              ? this.comments.length
                ? this.comments.trim().split(/\s+/).length
                : 0
              : comments.length
          "
          :max="hasWordsLimitation ? maxWords : maxCharacters"
          :label="
            hasWordsLimitation
              ? translation.answer_task.words
              : translation.answer_task.characters
          "
        />
      </div>
    </div>
    <div v-if="loading" class="w-100 d-flex justify-content-center">
      <loader />
    </div>
  </div>
</template>

<script>
import { v4 as uuidv4 } from "uuid";
import { api } from "@/services.js";
import loader from "@/components/general/loader.vue";
import dropzoneVideoTask from "@/components/general/dropzoneVideoTask.vue";
import attachment from "@/components/general/attachment.vue";
import characterAndWordCounter from "@/components/general/characterAndWordCounter.vue";
import { userTypesText } from "@/constants";
import { mapState } from "vuex";

export default {
  components: {
    loader,
    dropzoneVideoTask,
    attachment,
    characterAndWordCounter
  },
  props: {
    task: {
      required: true
    },
    save: {
      required: true
    },
    savePreview: {
      required: true
    },
    getTasks: {
      required: true
    }
  },
  data() {
    return {
      loading: false,
      comments: "",
      charactersCount: 0,
      question: "",
      disabled: false,
      videos: [],
      files: [],
      userTypesText
    };
  },
  computed: {
    ...mapState(["videoTaskFolder"]),
    text() {
      const regexpVideo = /(?:(<iframe.*?src="))(.*?)((?:".*?>))((?:<\/iframe>))?/g;

      return this.$store.getters.info.user.type === userTypesText.participant
        ? this.task.raw_question.replace(
            regexpVideo,
            "<video src='$2' width='350' height='197' frameborder='0' allowfullscreen='allowfullscreen' controls controlsList='nodownload'></iframe>"
          )
        : this.task.raw_question;
    },
    page() {
      return this.$store.getters.activeTaskIndex;
    },
    hasDescription() {
      return this.task.question_object.has_description;
    },
    mandatoryDescription() {
      return this.task.question_object.mandatory_description;
    },
    obligatoryComment() {
      return this.task.question_object?.has_obligatory_comments;
    },

    hasCharactersLimitation() {
      return this.task.question_object?.has_characters_limitation;
    },

    maxCharacters() {
      return this.hasCharactersLimitation
        ? this.task.question_object?.characters_count?.maximum
        : 5000;
    },

    minCharacters() {
      return this.hasCharactersLimitation
        ? this.task.question_object?.characters_count?.minimum
        : 0;
    },

    hasWordsLimitation() {
      return this.task.question_object?.has_words_limitation;
    },

    maxWords() {
      return this.hasWordsLimitation
        ? this.task.question_object?.words_count?.maximum
        : 2000;
    },

    minWords() {
      return this.hasWordsLimitation
        ? this.task.question_object?.words_count?.minimum
        : 0;
    }
  },
  watch: {
    save() {
      this.answer();
    },
    savePreview() {
      this.answerPreview();
    },
    page() {
      this.init();
    },
    comments() {
      if (this.comments.length >= this.maxCharacters) {
        this.comments = this.comments
          .split("")
          .splice(0, this.maxCharacters)
          .join("");
      }

      const words = this.comments.split(/\s+/);

      const wordsCount = words.length;

      if (wordsCount > this.maxWords) {
        this.comments = words.splice(0, this.maxWords).join(" ");
      }
    }
  },
  methods: {
    answerPreview() {
      const missingDescriptions = this.files
        .map((file, index) => ({
          ...file,
          index
        }))
        .filter(file => !file.description.length);

      if (!this.loading) {
        if (this.files.length) {
          if (
            !this.hasDescription ||
            !missingDescriptions.length ||
            !this.mandatoryDescription
          ) {
            if (this.obligatoryComment && !this.comments.length) {
              this.$toast.error(
                this.translation.answer_task.obligatory_comment_tost
              );

              return;
            }

            if (
              this.comments.length &&
              this.hasCharactersLimitation &&
              this.comments.length < this.minCharacters
            ) {
              this.$toast.error(
                `${this.translation.answer_task.min_characters} ${this.minCharacters} ${this.translation.answer_task.characters}`
              );

              return;
            }

            if (this.comments.length && this.hasWordsLimitation) {
              const wordsCount = this.comments.split(/\s+/).length;

              if (wordsCount < this.minWords) {
                this.$toast.error(
                  `${this.translation.answer_task.min_word} ${this.minWords} ${this.translation.answer_task.words}`
                );

                return;
              }
            }

            this.loading = true;

            const answer = {
              comment: this.comments,
              video_folder: `tmp/video/${this.videoTaskFolder}`,
              files: this.files
            };

            const body = {
              task_id: this.task.id,
              answer: answer,
              raw_answer: JSON.stringify(answer),
              has_attachment: 0,
              has_image: 0,
              has_video: 1
            };

            api
              .post("/answer/send/preview", body, {
                headers: {
                  "Content-Type": "application/json",
                  Authorization: `Bearer ${this.$store.getters.token}`
                }
              })
              .then(r => {
                if (r.status === 200) {
                  this.$toast.success(
                    this.translation.answer_task.success.reply_registered
                  );
                  this.getTasks("save");
                  this.loading = false;
                }
              })
              .catch(error => {
                if (error.response.status === 400) {
                  this.$toast.error(
                    this.translation.answer_task.error.empty_answer
                  );
                } else {
                  this.$toast.error(
                    this.translation.answer_task.error.reply_not_registered
                  );
                }
                this.loading = false;
              });
          } else {
            let descriptions = "";
            missingDescriptions.forEach(
              (description, index) =>
                (descriptions +=
                  index === missingDescriptions.length - 1
                    ? ` ${
                        this.translation.task.video_type
                      } ${description.index + 1}.`
                    : ` ${
                        this.translation.task.video_type
                      } ${description.index + 1},`)
            );
            this.$toast.error(
              `${this.translation.answer_task.error.missing_captions}: ${descriptions}`
            );
          }
        } else {
          this.$toast.error(this.translation.answer_task.error.empty_answer);
        }
      }
    },
    answer() {
      const missingDescriptions = this.files
        .map((file, index) => ({
          ...file,
          index
        }))
        .filter(file => !file.description.length);

      if (!this.loading) {
        if (this.files.length) {
          if (
            !this.hasDescription ||
            !missingDescriptions.length ||
            !this.mandatoryDescription
          ) {
            if (this.obligatoryComment && !this.comments.length) {
              this.$toast.error(
                this.translation.answer_task.obligatory_comment_tost
              );

              return;
            }

            if (
              this.comments.length &&
              this.hasCharactersLimitation &&
              this.comments.length < this.minCharacters
            ) {
              this.$toast.error(
                `${this.translation.answer_task.min_characters} ${this.minCharacters} ${this.translation.answer_task.characters}`
              );

              return;
            }

            if (this.comments.length && this.hasWordsLimitation) {
              const wordsCount = this.comments.split(/\s+/).length;

              if (wordsCount < this.minWords) {
                this.$toast.error(
                  `${this.translation.answer_task.min_word} ${this.minWords} ${this.translation.answer_task.words}`
                );

                return;
              }
            }

            this.loading = true;

            const answer = {
              comment: this.comments,
              video_folder: `tmp/video/${this.videoTaskFolder}`,
              files: this.files
            };

            const body = {
              task_id: this.task.id,
              participant_id: this.$store.getters.info.user.sub,
              answer: answer,
              raw_answer: JSON.stringify(answer),
              has_attachment: 0,
              has_image: 0,
              has_video: 1
            };

            api
              .post("/answer/send", body, {
                headers: {
                  "Content-Type": "application/json",
                  Authorization: `Bearer ${this.$store.getters.token}`
                }
              })
              .then(r => {
                if (r.status === 200) {
                  this.$toast.success(
                    this.translation.answer_task.success.reply_registered
                  );
                  this.getTasks("save");
                  this.loading = false;
                }
              })
              .catch(error => {
                if (error.response.status === 400) {
                  this.$toast.error(
                    this.translation.answer_task.error.empty_answer
                  );
                } else {
                  this.$toast.error(
                    this.translation.answer_task.error.reply_not_registered
                  );
                }
                this.loading = false;
              });
          } else {
            let descriptions = "";
            missingDescriptions.forEach(
              (description, index) =>
                (descriptions +=
                  index === missingDescriptions.length - 1
                    ? ` ${
                        this.translation.task.video_type
                      } ${description.index + 1}.`
                    : ` ${
                        this.translation.task.video_type
                      } ${description.index + 1},`)
            );
            this.$toast.error(
              `${this.translation.answer_task.error.missing_captions}: ${descriptions}`
            );
          }
        } else {
          this.$toast.error(this.translation.answer_task.error.empty_answer);
        }
      }
    },
    init() {
      if (this.task.is_answered) {
        this.comments = this.task.answer.answer_object.comment;
        this.files = this.task.answer.answer_object.files;
        this.videos = this.task.answer.answer_object.videos;
        this.disabled = true;
      } else {
        const notParticipantAndNotPreview =
          this.$store.getters.info.user.type !==
            this.userTypesText.participant && !this.$route.query._preview;

        if (notParticipantAndNotPreview) this.disabled = true;
        else this.disabled = false;

        this.files = [];

        this.$store.commit(
          "UPDATE_VIDEO_TASK_FOLDER",
          `answer-video-${uuidv4()}`
        );

        this.comments = "";
      }
    }
  },
  created() {
    this.init();
  },
  mounted() {
    if (this.$store.getters.info.user.type === userTypesText.participant) {
      const taskWrapper = document.querySelector(".task-wrapper");

      taskWrapper.addEventListener("contextmenu", e => {
        e.preventDefault();
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.task-text {
  display: flex;
  flex-direction: column;
  color: #6b6b6b;
  overflow-wrap: break-word;
}

video {
  width: 100%;
  max-width: 300px;
  max-height: 200px;
}

.comments {
  label {
    color: #6b6b6b;
  }
  textarea {
    width: 100%;
    display: block;
    border: 1px solid #b3b3b3;
    border-radius: 10px;
  }
}

.dropzone-wrapper {
  position: relative;
}

.disabled {
  position: absolute;
  width: 100%;
  height: 100%;
  background: #fff;
  opacity: 0.3;
  z-index: 2;
}

.media-wrapper {
  p {
    font-weight: bold;
    color: #6b6b6b;
    margin-bottom: 10px;
  }
}

.title {
  font-weight: bold;
  font-size: 1.1rem;
  color: #6b6b6b;
}
</style>
