<template>
  <div class="export-box">
    <div>
      <label for="export-name"> {{ translation.replies.filters_name }}: </label>
      <b-form-input id="export-name" v-model="name"></b-form-input>
      <div
        v-if="option === tabOptions.reports"
        class="d-flex flex-wrap align-items-center mt-4"
      >
        <label class="m-0 mr-4"> {{ translation.replies.type }}:</label>
        <b-form-radio-group v-slot="{ ariaDescribedby }">
          <b-form-radio
            v-model="baseType"
            :aria-describedby="ariaDescribedby"
            :value="baseTypes.label"
          >
            {{ translation.replies.export_answers_labels }}
          </b-form-radio>
          <b-form-radio
            v-model="baseType"
            :aria-describedby="ariaDescribedby"
            :value="baseTypes.code"
          >
            {{ translation.replies.export_answers_code }}
          </b-form-radio>
        </b-form-radio-group>
      </div>
      <div
        v-if="option === tabOptions.media"
        class="d-flex flex-wrap align-items-center mt-4"
      >
        <label class="m-0 mr-4">{{ translation.replies.download }}:</label>
        <b-form-radio-group v-slot="{ ariaDescribedby }">
          <b-form-radio
            v-model="download"
            :aria-describedby="ariaDescribedby"
            :value="exportMediaOptions.media"
          >
            {{ translation.replies.media_only }}
          </b-form-radio>
          <b-form-radio
            v-model="download"
            :aria-describedby="ariaDescribedby"
            :value="exportMediaOptions.transcript"
          >
            {{ translation.replies.transcripts_only }}
          </b-form-radio>
          <b-form-radio
            v-model="download"
            :aria-describedby="ariaDescribedby"
            :value="exportMediaOptions.both"
          >
            {{ translation.replies.both }}
          </b-form-radio>
        </b-form-radio-group>
      </div>
      <div
        :class="{ 'd-flex': download !== exportMediaOptions.media }"
        class="d-none flex-wrap align-items-center mt-4"
      >
        <label class="m-0 mr-4">
          {{ translation.replies.choose_the_format }}:
        </label>
        <b-form-radio-group v-slot="{ ariaDescribedby }">
          <b-form-radio
            v-model="format"
            :aria-describedby="ariaDescribedby"
            :value="exportMediaFormats.docx"
          >
            DOCX
          </b-form-radio>
          <b-form-radio
            v-model="format"
            :aria-describedby="ariaDescribedby"
            :value="exportMediaFormats.xlsx"
          >
            XLSX
          </b-form-radio>
          <b-form-radio
            v-model="format"
            :aria-describedby="ariaDescribedby"
            :value="exportMediaFormats.both"
          >
            {{ translation.replies.both }}
          </b-form-radio>
        </b-form-radio-group>
      </div>

      <div
        v-if="download !== exportMediaOptions.media"
        class="d-flex flex-wrap align-items-center mt-4"
      >
        <label class="m-0 mr-4"> {{ translation.replies.timestamp }}:</label>
        <b-form-checkbox v-model="timestamp" switch>
          {{ timestamp ? translation.global.yes : translation.global.no }}
        </b-form-checkbox>
      </div>
    </div>
    <div class="buttons mt-5">
      <p class="mt-2">
        {{ translation.replies.filters_message_exported_files }}
        <a
          :style="{ color: $store.getters.theme[2] }"
          class="hover"
          @click="$emit('openExportedFiles')"
        >
          {{ translation.replies.exported_files }}
        </a>
      </p>
      <button @click="$emit('initialStep')" class="btn hover mt-2">
        {{ translation.global.cancel }}
      </button>
      <button
        :style="{ background: $store.getters.theme[2] }"
        :disabled="loader"
        :class="{ hover: name.length }"
        class="btn mt-2"
        @click="createBody"
      >
        <loader2 v-if="loader" color="#ffffff" />
        <p v-if="!loader" class="m-0">
          {{ translation.replies.export }}
        </p>
      </button>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import {
  tabOptions,
  exportMediaOptions,
  exportMediaFormats,
  exportMediaTimestamp,
  baseTypes
} from "@/constants";
import loader2 from "@/components/general/loader2.vue";

export default {
  props: {
    option: {
      required: true
    }
  },
  components: {
    loader2
  },
  data() {
    return {
      name: "",
      download: "",
      format: "",
      baseType: "",
      timestamp: false,
      loader: false,
      exportMediaOptions,
      exportMediaFormats,
      exportMediaTimestamp,
      baseTypes,
      tabOptions
    };
  },
  methods: {
    createBody() {
      if (this.name.length) {
        const communityId = this.$store.getters.community.id;

        const body = {
          name: this.name,
          communityId: communityId
        };

        if (this.option === this.tabOptions.media) {
          body.option = this.download;
          body.format = this.format;
          body.timestamp = this.timestamp
            ? this.exportMediaTimestamp.yes
            : this.exportMediaTimestamp.no;
        } else {
          body.type = this.baseType;
        }

        const activities = JSON.parse(
          localStorage.getItem(`${this.option}-activitiesFilter-${communityId}`)
        );
        if (activities && activities.length) body.activities = activities;

        const participants = JSON.parse(
          localStorage.getItem(
            `${this.option}-participantsFilter-${communityId}`
          )
        );
        if (participants && participants.length)
          body.participants = participants;

        const checked = JSON.parse(
          localStorage.getItem(`${this.option}-checkedFilter-${communityId}`)
        );
        if (checked && checked.length === 1) body.checked = checked[0];

        const labels = JSON.parse(
          localStorage.getItem(`${this.option}-labelsFilter-${communityId}`)
        );
        if (labels && labels.length) body.labels = labels;

        const segments = JSON.parse(
          localStorage.getItem(`${this.option}-segmentsFilter-${communityId}`)
        );
        if (segments && segments.length) body.segments = segments;
        const types = JSON.parse(
          localStorage.getItem(`${this.option}-typeFilter-${communityId}`)
        );
        if (types && types.length) body.types = types;

        this.option === this.tabOptions.media
          ? this.exportMedia(body)
          : this.exportReport(body);
      } else {
        this.$toast.error(this.translation.errors_global.fill_fields);
      }
    },
    exportMedia(body) {
      if (!this.loader) {
        this.loader = true;
        axios
          .post(`${this.exportMediaHost}media/launch`, body, {
            headers: {
              Authorization: `Bearer ${this.$store.getters.token}`,
              "Content-Type": "application/json"
            }
          })
          .then(r => {
            if (r.status === 200) {
              this.name = "";
              this.download = this.exportMediaOptions.media;
              this.format = this.exportMediaFormats.docx;
              this.timestamp = false;
              this.$emit("openExportedFiles");
              this.$toast.success(
                this.translation.success_global.changes_saved
              );
              this.loader = false;
            }
          })
          .catch(() => {
            this.$toast.error(
              this.translation.errors_global.something_went_wrong
            );
            this.loader = false;
          });
      }
    },
    exportReport(body) {
      if (!this.loader) {
        this.loader = true;
        axios
          .post(`${this.exportAnswersHost}create-xlsx`, body, {
            headers: {
              Authorization: `Bearer ${this.$store.getters.token}`,
              "Content-Type": "application/json"
            }
          })
          .then(r => {
            if (r.status === 200) {
              this.name = "";
              this.baseType = this.baseTypes.label;
              this.$emit("openExportedFiles");
              this.$toast.success(
                this.translation.success_global.changes_saved
              );
              this.loader = false;
            }
          })
          .catch(() => {
            this.$toast.error(
              this.translation.errors_global.something_went_wrong
            );
            this.loader = false;
          });
      }
    }
  },
  mounted() {
    this.download = this.exportMediaOptions.media;
    this.format = this.exportMediaFormats.docx;
    this.baseType = this.baseTypes.label;
  }
};
</script>

<style lang="scss" scoped>
.export-box {
  background: #fbfafa;
  border-radius: 5px;
  padding: 20px;
  color: #6b6b6b;

  label {
    font-weight: bold;
    color: #6b6b6b;
  }

  input {
    background: none;
    color: #6b6b6b;
  }
}

.buttons {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  flex-wrap: wrap;
  > p {
    max-width: 420px;
    text-align: right;
    margin-bottom: 0;
    margin-right: 10px;
    a {
      display: inline-block;
      cursor: pointer;
      text-decoration: underline;
      text-transform: lowercase;
    }
  }
  button {
    display: flex;
    justify-content: center;
    background: #e7e7e7;
    font-weight: bold;
    border-radius: 25px;
    width: 100%;
    max-width: 200px;
    margin: 5px 10px;
    color: #6b6b6b;
    &:nth-of-type(2) {
      color: #ffffff;
    }
  }
}
</style>
