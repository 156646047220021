<template>
  <div>
    <b-modal
      modal-class="modalComment"
      no-enforce-focus
      @ok.prevent="commentPost"
      @cancel="isEdit = false"
      @close="isEdit = false"
      @hide="isEdit = false"
      :cancel-title="translation.global.cancel"
      :id="id"
      :title="translation.forum.comments.comment"
    >
      <div>
        <div class="d-flex justify-content-end">
          <uploader class="mb-2" />
        </div>
        <editor
          :placeholder="translation.forum.comments.write_new_comment"
          :text="textPost"
          :maxCharacters="maxCharacters"
          @charactersCount="count => (charactersCount = count)"
          @update="text => (textPost = text)"
          @imageError="msg => (imageMessage = msg)"
          @videoError="msg => (videoMessage = msg)"
        />
      </div>
      <div
        :class="{ active: attachmentsMessage }"
        class="error-box bg-danger p-2 my-3"
      >
        <b-icon class="mr-1" icon="exclamation-triangle-fill"></b-icon>
        {{ attachmentsMessage }}
      </div>
      <div class="d-flex flex-wrap mb-2 mt-3">
        <attachment
          :attachments="attachmentsPost"
          :deleteFile="deletePostFile"
        />
        <attachment :attachments="attachmentsTmp" :deleteFile="deleteTmpFile" />
      </div>
    </b-modal>
  </div>
</template>

<script>
import { api } from "@/services.js";
import editor from "@/components/general/editor.vue";
import uploader from "@/components/general/uploader.vue";
import attachment from "@/components/general/attachment.vue";
import { encodeImage, encodeVideo } from "@/helpers.js";
import { mapState } from "vuex";

export default {
  components: { editor, uploader, attachment },
  props: {
    open: {
      required: true
    },
    edit: {
      required: true
    },
    post: {
      required: true
    },
    id: {
      required: true
    },
    type: {
      required: true
    }
  },
  data() {
    return {
      textPost: "",
      maxCharacters: 1000,
      charactersCount: 0,
      deleteFiles: [],
      attachmentsTmp: [],
      attachmentsPost: [],
      isEdit: false,
      videoMessage: null,
      imageMessage: null,
      loaderComment: false,
      encodeImage,
      encodeVideo
    };
  },
  computed: {
    ...mapState([
      "attachment",
      "imageUploaderFolder",
      "videoUploaderFolder",
      "attachmentUploaderFolder"
    ]),
    attachmentsCount() {
      const attTemp = this.attachmentsTmp ? this.attachmentsTmp.length : 0;
      const attPost = this.attachmentsPost ? this.attachmentsPost.length : 0;
      return attTemp + attPost;
    },
    attachmentsMessage() {
      let msg = null;
      if (this.attachmentsCount > 5)
        msg = this.translation.errors_global.limit_exceeded_attachments;
      return msg;
    }
  },
  watch: {
    attachment() {
      this.getAttachmentsTmp();
    },
    open() {
      this.$bvModal.show(this.id);
      this.textPost = "";
      this.isEdit = false;
      this.attachmentsPost = [];
      this.attachmentsTmp = [];
    },
    edit() {
      this.$bvModal.show(this.id);
      this.isEdit = true;
      this.textPost = this.post.comment;
      this.attachmentsPost = this.post.attachments;
      this.attachmentsTmp = [];
    }
  },
  methods: {
    hasImage(text) {
      return text.match(/(?:(<img.*?src="))(.*?)((?:".*?\/>))/g);
    },
    hasVideo(text) {
      return text.match(/(?:(<iframe.*?src="))(.*?)(?:".*?>)/g);
    },
    getErrors() {
      let errorStr = "";

      errorStr += this.videoMessage ? `• ${this.videoMessage} ` : "";
      errorStr += this.imageMessage ? `• ${this.imageMessage} ` : "";
      errorStr += this.attachmentsMessage
        ? `• ${this.attachmentsMessage} `
        : "";

      errorStr +=
        this.charactersCount > this.maxCharacters
          ? `• ${this.translation.errors_global.you_have_exceeded} `
          : "";

      errorStr += !this.textPost.length
        ? `• ${this.translation.forum.comments.error.empty_comment} `
        : "";

      return errorStr.length ? errorStr : null;
    },
    commentPost() {
      if (!this.loaderComment) {
        if (!this.getErrors()) {
          this.loaderComment = true;
          let comment = this.textPost;

          let body = {};
          // codificar as imagem do editor
          if (this.hasImage(comment)) {
            comment = this.encodeImage(comment);
            body.has_image = 1;
            body.image_folder = `tmp/images/${this.imageUploaderFolder}`;
          } else {
            body.has_image = 0;
          }

          // codificar os vídeos do editor
          if (this.hasVideo(comment)) {
            comment = this.encodeVideo(comment);
            body.has_video = 1;
            body.video_folder = `tmp/video/${this.videoUploaderFolder}`;
          } else {
            body.has_video = 0;
          }

          //pegar os attachments da task
          if (this.attachmentsTmp.length) {
            body.has_attachment = 1;
            body.attachment_folder = `tmp/attachments/${this.attachmentUploaderFolder}`;
          } else {
            body.has_attachment = 0;
          }

          body.comment = comment;
          body.post_source_id = this.post.post_source_id;
          body.target_user = this.post.user.id;
          body.submit_target_id = this.post.id;
          body.source_type = this.type;
          body.community_id = this.$store.getters.community.id;

          if (!this.isEdit) {
            api
              .post("forum/comment", body, {
                headers: {
                  "Content-Type": "application/json",
                  Authorization: `Bearer ${this.$store.getters.token}`
                }
              })
              .then(resp => {
                if (resp.status === 201) {
                  this.$bvModal.hide(this.id);
                  this.$toast.success(
                    this.translation.forum.comments.success.comment_created
                  );
                  this.$emit("reply");
                }
                this.loaderComment = false;
              })
              .catch(() => {
                this.loaderComment = false;
                this.$toast.error(
                  this.translation.errors_global.something_went_wrong
                );
              });
          } else {
            api
              .put(`forum/comment/${this.post.id}`, body, {
                headers: {
                  "Content-Type": "application/json",
                  Authorization: `Bearer ${this.$store.getters.token}`
                }
              })
              .then(resp => {
                if (resp.status === 200) {
                  this.$bvModal.hide(this.id);
                  this.$toast.success(
                    this.translation.success_global.changes_saved
                  );
                  this.$emit("reply");
                  this.isEdit = false;
                }
                this.loaderComment = false;
              })
              .catch(() => {
                this.loaderComment = false;
                this.$toast.error(
                  this.translation.errors_global.something_went_wrong
                );
              });
          }
        } else {
          this.$toast.error(this.getErrors());
        }
      }
    },
    deletePostFile(filename, index) {
      this.attachmentsPost.splice(index, 1);
      this.deleteFiles.push(filename);
    },
    deleteTmpFile(filename) {
      api
        .post(
          "s3",
          {
            item: `attachments/${this.attachmentUploaderFolder}/${filename}`
          },
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${this.$store.getters.token}`
            }
          }
        )
        .then(r => {
          if (r.status === 200) this.getAttachmentsTmp();
        });
    },
    getAttachmentsTmp() {
      api
        .post(
          "task/s3/attachments",
          {
            folder: `${this.attachmentUploaderFolder}`
          },
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${this.$store.getters.token}`
            }
          }
        )
        .then(r => {
          this.attachmentsTmp = r.data;
        });
    }
  },
  created() {}
};
</script>

<style lang="scss" scoped></style>
