<template>
  <div class="container">
    <div class="row py-5">
      <div class="col">
        <div class="notfound card-b p-5 d-flex flex-column align-items-center">
          <p>
            {{ translation.assignment.completed }}
          </p>
          <p>
            <b-icon icon="check-circle"></b-icon>
          </p>
          <router-link
            :to="{
              name: 'tasks',
              query: {
                _activity: this.$route.query._activity,
                _pageParticipant: 1,
                _pageReplies: 1,
                _preview: true,
                _previous: this.$route.query._p
              }
            }"
            class="btn btn-return"
          >
            {{ translation.pagination.previous }}
          </router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
.notfound {
  p {
    margin: 0;
    color: #10c398;
    font-weight: bold;
    font-size: 1.5rem;
    &:nth-of-type(2) {
      font-size: 5rem;
    }
  }
}

.btn-return {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 25px;
  padding: 8px 25px;
  text-transform: uppercase;
  color: #fff;
  font-weight: bold;
  white-space: nowrap;
  margin-top: 20px;
  background: #10c398;
}
</style>
