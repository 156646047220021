<template>
  <div>
    <div v-if="charts.length">
      <div class="mb-5" v-for="(chart, index) in charts" :key="index">
        <div
          class="activity px-3 py-2"
          :style="{ background: $store.getters.theme[0] }"
        >
          <span :style="{ color: $store.getters.theme[3] }">
            {{ chart.activityName }}
          </span>
          -
          <span :style="{ color: $store.getters.theme[2] }">
            {{ chart.taskObject.code_string }}
          </span>
        </div>
        <div class="chart-wrapper">
          <get-task-data :task="chart.taskObject" />
          <image-evaluation-charts
            v-if="chart.config.isHeatmap"
            :chartData="chart"
            :id="`${chart.taskId}${chart.activityId}`"
          />
          <video-evaluation-charts
            v-else-if="chart.config.isBubble"
            :chartData="chart"
            :id="`${chart.taskId}${chart.activityId}`"
          />
          <word-cloud
            v-else-if="chart.config.isWordcloud"
            :chartData="chart"
            :id="`${chart.taskId}${chart.activityId}`"
          />
          <chart
            v-else
            :chartData="chart"
            :id="`${chart.taskId}${chart.activityId}`"
          />
        </div>
      </div>
    </div>
    <div v-else class="no-data">
      No Chart
    </div>
  </div>
</template>

<script>
import videoEvaluationCharts from "@/components/charts/videoEvaluationCharts.vue";
import imageEvaluationCharts from "@/components/charts/imageEvaluationCharts.vue";
import wordCloud from "@/components/charts/wordCloud.vue";
import chart from "@/components/charts/chart.vue";
import getTaskData from "@/components/task/getTaskData.vue";

export default {
  props: {
    charts: {
      required: true
    }
  },
  components: {
    wordCloud,
    chart,
    getTaskData,
    videoEvaluationCharts,
    imageEvaluationCharts
  },
  data() {
    return {};
  }
};
</script>

<style lang="scss" scoped>
.activity {
  display: inline-block;
  font-weight: bold;
  border-top-right-radius: 20px;
  border-bottom-right-radius: 20px;
  margin-bottom: 10px;
}

.chart-wrapper {
  padding: 20px;
  border: 1px solid #c4c4c4;
  border-radius: 11px;
  margin-bottom: 30px;
}

.no-data {
  text-align: center;
  color: #6b6b6b;
}
</style>
