<template>
  <div>
    <div class="btn-customize">
      <button
        v-if="!toggleCustomize"
        class="hover"
        @click="toggleCustomize = !toggleCustomize"
      >
        <span>
          {{ translation.replies.charts_tab.customize }}
        </span>
      </button>
    </div>
    <div
      v-if="!loader"
      :class="{ active: toggleCustomize }"
      class="config-wrapper"
    >
      <nav class="config-nav">
        <a
          :class="{ active: option === configOptions.dot }"
          :style="{ color: linkColor(configOptions.dot) }"
          @click="option = configOptions.dot"
        >
          {{ translation.replies.charts_tab.dot }}
        </a>
      </nav>
      <div class="config-box">
        <transition mode="out-in">
          <config-dot
            v-if="option === configOptions.dot"
            :config="config"
            @updateMax="newMax => setMax(newMax)"
            @updateRadius="newRadius => setRadius(newRadius)"
            @updateMaxOpacity="newMaxOpacity => setMaxOpacity(newMaxOpacity)"
            @updateBlur="newBlur => setBlur(newBlur)"
          />
        </transition>
        <div class="btns-footer">
          <button @click="cancelConfig" class="btn hover">
            {{ translation.global.cancel }}
          </button>
          <button
            :style="{
              background: $store.getters.theme[2]
            }"
            class="btn hover"
            @click="applyConfig"
            :disabled="loader"
          >
            {{ translation.global.save }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import configDot from "@/components/charts/configItems/configDot.vue";
import { configOptions } from "@/constants.js";
import { mapState, mapMutations } from "vuex";

export default {
  components: {
    configDot
  },
  props: {
    id: {
      required: true
    },
    taskId: {
      required: true
    },
    activityId: {
      required: true
    }
  },
  data() {
    return {
      toggleCustomize: false,
      option: null,
      config: {
        dot: {
          max: null,
          radius: null,
          maxOpacity: null,
          blur: null
        }
      },
      loader: false,
      configOptions
    };
  },
  computed: {
    ...mapState(["configChart"])
  },
  methods: {
    ...mapMutations(["UPDATE_CONFIG_CHART"]),
    updateConfig() {
      const config = JSON.parse(JSON.stringify(this.config));
      this.UPDATE_CONFIG_CHART({
        ...this.configChart,
        [this.id]: config
      });
    },
    setMax(newMax) {
      this.config.dot.max = newMax;
      this.updateConfig();
    },
    setRadius(newRadius) {
      this.config.dot.radius = newRadius;
      this.updateConfig();
    },
    setMaxOpacity(newMaxOpacity) {
      this.config.dot.maxOpacity = newMaxOpacity;
      this.updateConfig();
    },
    setBlur(newBlur) {
      this.config.dot.blur = newBlur;
      this.updateConfig();
    },
    linkColor(currentOption) {
      if (this.option === currentOption)
        return `${this.$store.getters.theme[2]}`;
      else return "#6b6b6b";
    },
    getConfig() {
      this.loader = true;

      let url = `${this.exportChartHost}get/config?taskId=${this.taskId}&activityId=${this.activityId}`;

      axios
        .get(url, {
          headers: {
            Authorization: `Bearer ${this.$store.getters.token}`
          }
        })
        .then(r => {
          if (r.status === 200) {
            const configInitial = r.data.config;

            if (!configInitial.dot || !configInitial.dot.max)
              this.config.dot.max = 2;
            else this.config.dot.max = configInitial.dot.max;

            if (!configInitial.dot || !configInitial.dot.radius)
              this.config.dot.radius = 20;
            else this.config.dot.radius = configInitial.dot.radius;

            if (!configInitial.dot || !configInitial.dot.maxOpacity)
              this.config.dot.maxOpacity = 0.5;
            else this.config.dot.maxOpacity = configInitial.dot.maxOpacity;

            if (!configInitial.dot || !configInitial.dot.blur)
              this.config.dot.blur = 0.5;
            else this.config.dot.blur = configInitial.dot.blur;

            this.updateConfig();
          }
          this.loader = false;
        })
        .catch(() => {
          this.loader = false;
        });
    },
    cancelConfig() {
      this.getConfig();
      this.toggleCustomize = !this.toggleCustomize;
    },
    applyConfig() {
      this.toggleCustomize = !this.toggleCustomize;
      this.loader = true;

      let url = `${this.exportChartHost}update`;

      axios
        .put(
          url,
          {
            taskId: this.taskId,
            activityId: this.activityId,
            config: { ...this.config, isHeatmap: true }
          },
          {
            headers: {
              Authorization: `Bearer ${this.$store.getters.token}`
            }
          }
        )
        .then(r => {
          if (r.status === 200) {
            this.$toast.success(this.translation.success_global.changes_saved);
          }
          this.loader = false;
        })
        .catch(() => {
          this.loader = false;
        });
    }
  },
  created() {
    this.option = this.configOptions.dot;
    this.getConfig();
  }
};
</script>

<style lang="scss" scoped>
.btn-customize {
  display: flex;
  justify-content: flex-end;
  button {
    background: none;
    border: none;
    color: #6b6b6b;
    font-weight: bold;
    font-size: 1.2rem;
    padding: 10px;
    &:hover {
      color: #6b6b6b;
    }
    &:focus {
      outline: none;
    }
    svg {
      font-size: 2rem;
      font-weight: bold;
      text-align: center;
    }
  }
}

.config-wrapper {
  transition: all 0.5s ease-in-out;
  height: 0;
  overflow: hidden;
  &.active {
    display: block;
    height: 300px;
  }
}

@media (max-width: 767.98px) {
  .config-wrapper {
    &.active {
      height: 460px;
    }
  }
}

.config-nav {
  display: flex;
  flex-wrap: wrap;
  a {
    display: block;
    color: #6b6b6b;
    margin-right: 10px;
    padding: 5px 10px;
    font-weight: bold;
    font-size: 0.9rem;
    cursor: pointer;
    &:hover {
      color: #424151;
    }
    &.active {
      text-decoration: underline;
    }
  }
}

.config-box {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border-radius: 10px;
  background: #efefef;
  padding: 20px;
  height: calc(100% - 40px);
}

@media (max-width: 767.98px) {
  .config-box {
    height: calc(100% - 65px);
  }
}

.btns-footer {
  display: flex;
  justify-content: flex-end;
  button {
    color: #ffffff;
    font-weight: bold;
    padding: 5px 20px;
    background: #ccc;
    &:nth-of-type(1) {
      color: #6b6b6b;
      margin-right: 10px;
    }
  }
}
</style>
