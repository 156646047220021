<template>
  <div class="answer-data">
    <text-answer
      v-if="+answer.task.type_id === taskEnum.textTask.id"
      :answer="answer"
    />
    <survey-answer
      v-else-if="+answer.task.type_id === taskEnum.surveyTask.id"
      :answer="answer"
    />
    <whitespace-answer
      v-else-if="+answer.task.type_id === taskEnum.whitespace.id"
      :answer="answer"
    />
    <matrix-answer
      v-else-if="+answer.task.type_id === taskEnum.matrix.id"
      :answer="answer"
    />
    <slider-answer
      v-else-if="+answer.task.type_id === taskEnum.slider.id"
      :answer="answer"
    />
    <ranking-answer
      v-else-if="+answer.task.type_id === taskEnum.ranking.id"
      :answer="answer"
    />
    <drag-and-drop-answer
      v-else-if="+answer.task.type_id === taskEnum.dragAndDrop.id"
      :answer="answer"
    />
    <image-answer
      v-else-if="+answer.task.type_id === taskEnum.imageTask.id"
      :answer="answer"
    />
    <video-answer
      v-else-if="+answer.task.type_id === taskEnum.videoTask.id"
      :answer="answer"
    />
    <attachment-answer
      v-else-if="+answer.task.type_id === taskEnum.attachment.id"
      :answer="answer"
    />
    <reactions-answer
      v-else-if="+answer.task.type_id === taskEnum.reactions.id"
      :answer="answer"
    />
    <video-evaluation-answer
      v-else-if="+answer.task.type_id === taskEnum.videoEvaluation.id"
      :answer="answer"
    />
    <text-evaluation-answer
      v-else-if="+answer.task.type_id === taskEnum.textEvaluation.id"
      :answer="answer"
    />
    <multimedia-answer
      v-else-if="+answer.task.type_id === taskEnum.multimedia.id"
      :answer="answer"
    />
    <screen-recording-answer
      v-else-if="+answer.task.type_id === taskEnum.screenRecording.id"
      :answer="answer"
    />
    <audio-answer
      v-else-if="+answer.task.type_id === taskEnum.audio.id"
      :answer="answer"
    />
  </div>
</template>

<script>
import textAnswer from "@/components/answerdata/textAnswer.vue";
import surveyAnswer from "@/components/answerdata/surveyAnswer.vue";
import whitespaceAnswer from "@/components/answerdata/whitespaceAnswer.vue";
import matrixAnswer from "@/components/answerdata/matrixAnswer.vue";
import sliderAnswer from "@/components/answerdata/sliderAnswer.vue";
import rankingAnswer from "@/components/answerdata/rankingAnswer.vue";
import dragAndDropAnswer from "@/components/answerdata/dragAndDropAnswer.vue";
import imageAnswer from "@/components/answerdata/imageAnswer.vue";
import videoAnswer from "@/components/answerdata/videoAnswer.vue";
import attachmentAnswer from "@/components/answerdata/attachmentAnswer.vue";
import reactionsAnswer from "@/components/answerdata/reactionsAnswer.vue";
import videoEvaluationAnswer from "@/components/answerdata/videoEvaluationAnswer.vue";
import textEvaluationAnswer from "@/components/answerdata/textEvaluationAnswer.vue";
import screenRecordingAnswer from "@/components/answerdata/screenRecordingAnswer.vue";
import multimediaAnswer from "@/components/answerdata/multimediaAnswer.vue";
import audioAnswer from "@/components/answerdata/audioAnswer.vue";
import { taskEnum } from "@/constants";

export default {
  props: {
    answer: {
      required: true
    }
  },
  components: {
    textAnswer,
    surveyAnswer,
    whitespaceAnswer,
    matrixAnswer,
    sliderAnswer,
    rankingAnswer,
    dragAndDropAnswer,
    imageAnswer,
    videoAnswer,
    attachmentAnswer,
    reactionsAnswer,
    videoEvaluationAnswer,
    textEvaluationAnswer,
    screenRecordingAnswer,
    multimediaAnswer,
    audioAnswer
  },
  data() {
    return {
      taskEnum
    };
  },
  filters: {},
  computed: {},
  watch: {},
  methods: {}
};
</script>

<style lang="scss" scoped></style>
