<template>
  <half-circle-spinner
    :animation-duration="1000"
    :size="40"
    :color="`${$store.getters.theme ? $store.getters.theme[2] : '#6b6b6b'}`"
  />
</template>

<script>
import { HalfCircleSpinner } from "epic-spinners";
export default {
  name: "loder",
  components: {
    HalfCircleSpinner
  }
};
</script>

<style></style>
