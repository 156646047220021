var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"sub-header",class:{ active: _vm.showAdmin }},[_c('div',[(_vm.$store.getters.info.user.type === _vm.userTypesText.admin)?_c('div',[_c('button',{style:({ color: _vm.$store.getters.theme[2] }),on:{"click":function($event){_vm.showPrivacy = !_vm.showPrivacy}}},[_vm._v(" "+_vm._s(_vm.translation.privacy_and_permissions.privacy)+" ")]),_c('div',{staticClass:"links",class:{ active: _vm.showPrivacy }},[_c('router-link',{attrs:{"to":{
            name: 'privacyandpermissions',
            query: { _option: _vm.tabOptions.privacy }
          }}},[_vm._v(" "+_vm._s(_vm.translation.privacy_and_permissions.privacy_and_permissions)+" ")])],1)]):_vm._e(),(_vm.$store.getters.info.user.type === _vm.userTypesText.admin)?_c('div',[_c('button',{style:({ color: _vm.$store.getters.theme[2] }),on:{"click":function($event){_vm.showCommunity = !_vm.showCommunity}}},[_vm._v(" "+_vm._s(_vm.translation.header.community_text)+" ")]),_c('div',{staticClass:"links",class:{ active: _vm.showCommunity }},[_c('router-link',{attrs:{"to":{ name: 'segmentation', query: { _page: 1 } }}},[_vm._v(" "+_vm._s(_vm.translation.header.segmentation_text)+" ")]),(_vm.$store.getters.info.user.type !== _vm.userTypesText.participant)?_c('router-link',{attrs:{"to":{ name: 'logs', query: { _page: 1 } }}},[_vm._v(" "+_vm._s(_vm.translation.header.logs)+" ")]):_vm._e()],1)]):_vm._e()]),_c('div',[(_vm.$store.getters.info.user.type === _vm.userTypesText.admin)?_c('div',[_c('button',{style:({ color: _vm.$store.getters.theme[2] }),on:{"click":function($event){_vm.showOperations = !_vm.showOperations}}},[_vm._v(" "+_vm._s(_vm.translation.header.moderation_text)+" ")]),_c('div',{staticClass:"links",class:{ active: _vm.showOperations }},[_c('router-link',{attrs:{"to":{
            name: 'operators',
            query: { _option: _vm.tabOptions.addOperator }
          }}},[_vm._v(" "+_vm._s(_vm.translation.operators.add_manage_operators)+" ")])],1)]):_vm._e()]),_c('div',[(
        _vm.$store.getters.info.user.type === _vm.userTypesText.admin ||
          (_vm.$store.getters.info.user.type === _vm.userTypesText.moderator &&
            (!+_vm.permissions.moderators.hide_customization ||
              !+_vm.permissions.moderators.hide_terms_and_policies))
      )?_c('div',[_c('button',{style:({ color: _vm.$store.getters.theme[2] }),on:{"click":function($event){_vm.showPersonalize = !_vm.showPersonalize}}},[_vm._v(" "+_vm._s(_vm.translation.header.personalize_text)+" ")]),_c('div',{staticClass:"links",class:{ active: _vm.showPersonalize }},[(
            _vm.$store.getters.info.user.type === _vm.userTypesText.admin ||
              (_vm.$store.getters.info.user.type === _vm.userTypesText.moderator &&
                !+_vm.permissions.moderators.hide_customization)
          )?_c('router-link',{attrs:{"to":{
            name: 'customization',
            query: { _option: _vm.tabOptions.customizePages }
          },"disabled":_vm.$store.getters.is_over}},[_vm._v(" "+_vm._s(_vm.translation.header.customize_text)+" ")]):_vm._e(),(
            _vm.$store.getters.info.user.type === _vm.userTypesText.admin ||
              (_vm.$store.getters.info.user.type === _vm.userTypesText.moderator &&
                !+_vm.permissions.moderators.hide_terms_and_policies)
          )?_c('router-link',{attrs:{"to":{ name: 'terms-and-policy' },"disabled":_vm.$store.getters.is_over}},[_vm._v(" "+_vm._s(_vm.translation.header.terms_and_policies)+" ")]):_vm._e()],1)]):_vm._e()]),(
      _vm.$store.getters.info.user.type === _vm.userTypesText.admin ||
        _vm.$store.getters.info.user.type === _vm.userTypesText.moderator ||
        (_vm.$store.getters.info.user.type === _vm.userTypesText.observer &&
          !+_vm.permissions.observers.hide_activities_stats)
    )?_c('div',[_c('div',[_c('button',{style:({ color: _vm.$store.getters.theme[2] }),on:{"click":function($event){_vm.showParticipant = !_vm.showParticipant}}},[_vm._v(" "+_vm._s(_vm.translation.header.participant_text)+" ")]),_c('div',{staticClass:"links",class:{ active: _vm.showParticipant }},[(_vm.$store.getters.info.user.type === _vm.userTypesText.admin)?_c('router-link',{attrs:{"to":{
            name: 'addparticipant',
            query: { _option: _vm.tabOptions.importOption, _step: 1 }
          }}},[_vm._v(" "+_vm._s(_vm.translation.header.add_participant_text)+" ")]):_vm._e(),_c('router-link',{attrs:{"to":{
            name: 'manageparticipants',
            query: { _option: _vm.tabOptions.registeredOption, _page: 1 }
          }}},[_vm._v(" "+_vm._s(_vm.translation.header.manage_participant_text)+" ")])],1)])]):_vm._e(),_c('div',[_c('button',{style:({ color: _vm.$store.getters.theme[2] }),on:{"click":function($event){_vm.showChat = !_vm.showChat}}},[_vm._v(" Chat ")]),_c('div',{staticClass:"links",class:{ active: _vm.showChat }},[_c('router-link',{attrs:{"to":{ name: 'chat' },"disabled":_vm.$store.getters.is_over}},[_vm._v(" Chat ")])],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }