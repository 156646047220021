import { translation } from "./translation.js";

export const taskEnum = {
  textTask: {
    id: 1,
    text: {
      en: translation.en.task.text_type,
      pt: translation.pt.task.text_type,
      es: translation.es.task.text_type
    },
    icon: "text"
  },
  surveyTask: {
    id: 2,
    text: {
      en: translation.en.task.survey_type,
      pt: translation.pt.task.survey_type,
      es: translation.es.task.survey_type
    },
    icon: "survey"
  },
  whitespace: {
    id: 3,
    text: {
      en: translation.en.task.fill_blank_type,
      pt: translation.pt.task.fill_blank_type,
      es: translation.es.task.fill_blank_type
    },
    icon: "white-space"
  },
  matrix: {
    id: 4,
    text: {
      en: translation.en.task.matrix_type,
      pt: translation.pt.task.matrix_type,
      es: translation.es.task.matrix_type
    },
    icon: "matrix"
  },
  slider: {
    id: 5,
    text: {
      en: translation.en.task.slider_type,
      pt: translation.pt.task.slider_type,
      es: translation.es.task.slider_type
    },
    icon: "slider"
  },
  ranking: {
    id: 6,
    text: {
      en: translation.en.task.ranking_type,
      pt: translation.pt.task.ranking_type,
      es: translation.es.task.ranking_type
    },
    icon: "ranking"
  },
  dragAndDrop: {
    id: 7,
    text: {
      en: translation.en.task.drag_drop_type,
      pt: translation.pt.task.drag_drop_type,
      es: translation.es.task.drag_drop_type
    },
    icon: "drag-and-drop"
  },
  imageTask: {
    id: 8,
    text: {
      en: translation.en.task.image_type,
      pt: translation.pt.task.image_type,
      es: translation.es.task.image_type
    },
    icon: "picture"
  },
  videoTask: {
    id: 9,
    text: {
      en: translation.en.task.video_type,
      pt: translation.pt.task.video_type,
      es: translation.es.task.video_type
    },
    icon: "video"
  },
  attachment: {
    id: 10,
    text: {
      en: translation.en.task.attachment_type,
      pt: translation.pt.task.attachment_type,
      es: translation.es.task.attachment_type
    },
    icon: "attachment"
  },
  reactions: {
    id: 11,
    text: {
      en: translation.en.task.reaction_type,
      pt: translation.pt.task.reaction_type,
      es: translation.es.task.reaction_type
    },
    icon: "reaction"
  },
  videoEvaluation: {
    id: 12,
    text: {
      en: translation.en.task.video_evaluation_type,
      pt: translation.pt.task.video_evaluation_type,
      es: translation.es.task.video_evaluation_type
    },
    icon: "video-evaluation"
  },
  textEvaluation: {
    id: 13,
    text: {
      en: translation.en.task.text_evaluation_type,
      pt: translation.pt.task.text_evaluation_type,
      es: translation.es.task.text_evaluation_type
    },
    icon: "text-evaluation"
  },
  screenRecording: {
    id: 14,
    text: {
      en: translation.en.task.screen_recording_type,
      pt: translation.pt.task.screen_recording_type,
      es: translation.es.task.screen_recording_type
    },
    icon: "screen-recording"
  },
  multimedia: {
    id: 15,
    text: {
      en: translation.en.task.multimedia_type,
      pt: translation.pt.task.multimedia_type,
      es: translation.es.task.multimedia_type
    },
    icon: "multimedia"
  },
  audio: {
    id: 16,
    text: {
      en: translation.en.task.audio_type,
      pt: translation.pt.task.audio_type,
      es: translation.es.task.audio_type
    },
    icon: "audio"
  }
};

export const tabOptions = {
  linkOption: "link",
  importOption: "import",
  registeredOption: "registered",
  invitedOption: "invited",
  profileOption: "profile",
  selectReaction: "selectReaction",
  fillComment: "fillComment",
  addOperator: "addOperator",
  manageOperator: "manageOperator",
  participants: "participants",
  answers: "answers",
  reports: "reports",
  media: "media",
  charts: "charts",
  personalStats: "personalStats",
  privacy: "privacy",
  permissions: "permissions",
  customizePages: "customizePages",
  addMessage: "addMessage"
};

export const userTypes = {
  moderator: "1",
  participant: "2",
  admin: "3",
  observer: "4"
};

export const userTypesText = {
  moderator: "Moderator",
  admin: "Admin",
  participant: "Participant",
  observer: "Observer"
};

export const notificationTypes = {
  subReplies: 1,
  forumNewPost: 2,
  forumNewPostComment: 3,
  forumCommentOfComment: 4,
  forumLikePost: 5,
  forumLikeComment: 6,
  answerRejected: 7,
  newAssignmentAvailable: 8,
  forumCommentRejected: 9,
  assignmentUpdated: 10,
  createdCommunity: 11,
  termsAndConditions: 12
};

export const statusDashboard = {
  completed: "Completed",
  incomplete: "Incomplete",
  notStarted: "Not Started"
};

export const imgTypes = [
  "svg",
  "png",
  "jpg",
  "jpeg",
  "jfif",
  "pjpeg",
  "pjp",
  "gif",
  "avif",
  "apng",
  "webp",
  "bmp",
  "cur",
  "ico",
  "tif",
  "tiff"
];

export const themes = {
  1: ["#FBFBFB", "#F1F1F1", "#E45D3F", "#4E3833"],
  2: ["#F8FAF9 ", "#F1F1F1 ", "#419C64", "#0A2D44"],
  3: ["#FAFAFA", "#F1F1F1", "#FEC821 ", "#3A3831"],
  4: ["#F6F8F9", "#F1F1F1", "#108FC3", "#163F59"],
  5: ["#FDFBFC", "#F1F1F1 ", "#E05297", "#410723"],
  6: ["#F8F8F8", "#EAEAEA ", "#6C7F9A", "#3E4C61"],
  7: ["#F8F8F8", "#F1F1F1", "#820AD1", "#25013A"]
};

export const logicOptions = {
  next: "NEXT",
  finish: "FINISH",
  and: "AND",
  or: "OR"
};

export const addTaskText = "add";

export const inputHtml =
  '<input type="text" style="background: white; border: 1px solid #6b6b6b; width: 50px; text-align: center; border-radius: 5px; font-weight:bold; padding: 3px 10px" value="BLANK" disabled>';

export const blankRegexpGlobal = /({0327L-S2!@#\$\)\(%)(.*?)(%-\[(.*?)]!@#\$\)\(})/g;

export const imageRegexpGlobal = /(?:(<img.*?src="))(.*?)((?:".*?\/>))/g;

export const videoRegexpGlobal = /(?:(<iframe.*?src="))(.*?)((?:".*?>))((?:<\/iframe>))?/g;

export const regexpImageName = /[!()^&$@=;:’+, ?\\{\\}^%`\]'">[~>#|áàâãéèêíïóôõöúçñ]/g;

export const regexpClearTags = /<[^>]*>/g;

export const exportMediaOptions = {
  both: "BOTH",
  media: "MEDIA",
  transcript: "TRANSCRIPT"
};

export const exportMediaFormats = {
  both: "BOTH",
  docx: "DOCX",
  xlsx: "XLSX"
};

export const exportMediaTimestamp = {
  yes: "YES",
  no: "NO"
};

export const configOptions = {
  font: 1,
  colors: 2,
  numberOfWords: 3,
  discardedWords: 4,
  type: 5,
  isPercent: 6,
  dot: 7
};

export const baseTypes = {
  label: "label",
  code: "code"
};

export const isRoot = "1";

export const isActiveCode = {
  active: "1",
  notActive: "0"
};

export const visibilityCodes = {
  all_visible: "1",
  after_complete: "2",
  never: "3"
};

export const themesCodes = {
  themeOrange: 1,
  themeGreen: 2,
  themeYellow: 3,
  themeBlue: 4,
  themePink: 5,
  themeGray: 6,
  themePurple: 7
};

export const regexpCodeString = /[()!^&$@=;:’+, ?\\{\\}^%`\]'">[~>#|]/g;

export const displayNameIds = {
  fullName: "1",
  firstNameAndLastNameInitial: "2",
  firstName: "3",
  nameAndId: "4",
  idOnly: "5"
};
