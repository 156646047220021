<template>
  <div class="row">
    <div class="col">
      <search class="mt-5 mb-4" />
      <div
        class="d-flex flex-wrap"
        v-if="!loaderParticipants && participantsPages.length"
      >
        <div
          class="col-lg-4 col-md-6"
          v-for="participant in participantsPages"
          :key="participant.id"
        >
          <router-link
            :to="{
              name: 'participant',
              query: { _id: participant.id, _page: 1 }
            }"
            class="participant p-3 mb-4 hover"
          >
            <div
              :style="{
                'background-color': $store.getters.theme[0],
                color: $store.getters.theme[3]
              }"
            >
              <img
                v-if="participant.picture && participant.picture.length"
                :src="participant.picture"
                alt="Profile Picture"
              />
              <b-icon
                v-else
                class="icon-profile"
                icon="person-fill"
                aria-hidden="true"
              ></b-icon>
            </div>
            <div>
              <h3 :style="{ color: $store.getters.theme[2] }">
                <span
                  v-if="
                    $store.getters.info.user.type === userTypesText.moderator
                  "
                >
                  {{
                    namePrivacy(
                      privacy.moderators.participants_identifier_type_id,
                      participant.name,
                      participant.id
                    )
                  }}
                </span>
                <span
                  v-else-if="
                    $store.getters.info.user.type === userTypesText.observer
                  "
                >
                  {{
                    namePrivacy(
                      privacy.observers.participants_identifier_type_id,
                      participant.name,
                      participant.id
                    )
                  }}
                </span>
                <span v-else>
                  {{ participant.name }}
                </span>
              </h3>
              <p
                v-b-tooltip.hover="{ variant: 'secondary' }"
                :title="participant.email"
                v-if="
                  $store.getters.info.user.type === userTypesText.admin ||
                    ($store.getters.info.user.type ===
                      userTypesText.moderator &&
                      !+privacy.moderators.participants_hide_email) ||
                    ($store.getters.info.user.type === userTypesText.observer &&
                      !+privacy.observers.participants_hide_email)
                "
              >
                {{ participant.email }}
              </p>
              <p>ID {{ participant.id }}</p>
              <a class="hover" :style="{ color: $store.getters.theme[2] }">{{
                translation.manage_participants.view_participant
              }}</a>
            </div>
          </router-link>
        </div>
        <pagination-query
          v-if="!queryValue && minPerPage < total"
          :perPage="perPage"
          :total="total"
          :options="[12, 24, 48, 96]"
          @perPageUpdate="newPerPage => (perPage = newPerPage)"
        />
      </div>
      <div
        class="d-flex justify-content-center py-5"
        v-else-if="loaderParticipants"
      >
        <loader />
      </div>
      <div
        v-else-if="queryValue"
        class="result d-flex justify-content-center py-5"
      >
        {{ translation.global.sorry_we_have }}
        "{{ queryValue }}"
      </div>
      <div class="d-flex justify-content-center py-5" v-else>
        {{ translation.manage_participants.no_participants }}
      </div>
    </div>
  </div>
</template>

<script>
import { api } from "@/services.js";
import paginationQuery from "@/components/general/paginationQuery.vue";
import loader from "@/components/general/loader.vue";
import search from "@/components/general/search.vue";
import { userTypesText } from "@/constants";
import { mapState } from "vuex";
import { namePrivacy } from "@/helpers.js";

export default {
  components: { paginationQuery, search, loader },
  data() {
    return {
      loaderParticipants: false,
      participantsPages: [],
      participants: [],
      perPage: 12,
      minPerPage: 12,
      total: 0,
      userTypesText
    };
  },

  computed: {
    ...mapState(["privacy"]),

    page() {
      return this.$route.query._page;
    },

    queryValue() {
      if (this.$route.query._q && this.$route.query._q.length)
        return this.$route.query._q;
      else return 0;
    }
  },

  watch: {
    queryValue() {
      this.getParticipantsPages();
    },

    page() {
      this.getParticipantsPages();
    },

    perPage() {
      this.getParticipantsPages();
    },

    participants() {
      this.getParticipantsPages();
    }
  },

  methods: {
    namePrivacy,

    getParticipantsPages() {
      let array = [];

      if (this.queryValue) {
        array = this.participants.filter(item => {
          return this.queryValue
            .toLowerCase()
            .split(" ")
            .every(v => item.name.toLowerCase().includes(v));
        });
      } else {
        const start = this.perPage * (this.page - 1);
        this.participants.forEach((participant, index) => {
          if (index >= start && index < start + this.perPage)
            array.push(participant);
        });
      }

      this.participantsPages = array;
    },

    getParticipants() {
      this.loaderParticipants = true;
      api
        .get(`community/${this.$store.getters.community.id}/participants`, {
          headers: {
            Authorization: `Bearer ${this.$store.getters.token}`
          }
        })
        .then(r => {
          if (r.status === 200) {
            this.participants = r.data.sort((a, b) =>
              a.name.localeCompare(b.name)
            );
            this.total = this.participants.length;

            this.getParticipantsPages();
          }
          this.loaderParticipants = false;
        })
        .catch(() => {
          this.loaderParticipants = false;
        });
    }
  },

  created() {
    this.getParticipants();
  }
};
</script>

<style lang="scss" scoped>
.participant {
  display: flex;
  align-items: center;
  background: #ffffff;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);
  border-radius: 20px;
  cursor: pointer;
  text-decoration: none;
  > div {
    &:nth-of-type(1) {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 80px;
      height: 80px;
      border-radius: 50%;
      overflow: hidden;
      border: 2px solid #c4c4c4;
      margin-right: 20px;
      flex-shrink: 0;
      img {
        object-fit: cover;
        height: 80px;
        width: 80px;
      }
    }
    &:nth-of-type(2) {
      max-width: 171px;
      h3 {
        font-weight: bold;
        font-size: 1.1rem;
        margin-bottom: 2px;
        word-wrap: break-word;
        word-break: break-word;
      }
      p {
        font-size: 0.9rem;
        margin-bottom: 0;
        color: #6b6b6b;
        text-overflow: ellipsis;
        white-space: nowrap;
        max-width: 160px;
        overflow: hidden;
      }
      a {
        display: block;
        margin-top: 10px;
        color: #0087bf;
        font-weight: bold;
        text-decoration: underline;
      }
    }
  }
}
</style>
