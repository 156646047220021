<template>
  <div class="col-md-6">
    <imageCropper
      :img="img"
      :show="showCropper"
      :loader="loaderInsertImg"
      @updateImg="img => insertImg(img)"
      @trash="close"
    />
    <a v-if="!loaderConversation" class="close hover" @click="$emit('close')"
      ><b-icon class="mr-1" icon="x-circle"></b-icon>
    </a>
    <div v-if="!loaderConversation" class="chat p-4 mt-2 mt-lg-0">
      <div class="chat-user">
        <div
          class="profile-img"
          :style="{
            color: $store.getters.theme[3]
          }"
        >
          <img
            v-if="userTarget.picture && userTarget.picture.length"
            :src="userTarget.picture"
            alt="Profile Picture"
          />
          <b-icon
            v-else
            class="icon-profile"
            icon="person-fill"
            aria-hidden="true"
          ></b-icon>
        </div>
        <div>
          <p
            :class="{ pointer: userTarget.type === userTypes.participant }"
            @click="goToParticipant(userTarget)"
          >
            <span
              v-if="
                $store.getters.info.user.type === userTypesText.moderator &&
                  !userTarget.operator_type_id
              "
            >
              {{
                namePrivacy(
                  privacy.moderators.participants_identifier_type_id,
                  userTarget.name,
                  userTarget.id
                )
              }}
            </span>
            <span
              v-else-if="
                $store.getters.info.user.type === userTypesText.observer &&
                  !userTarget.operator_type_id
              "
            >
              {{
                namePrivacy(
                  privacy.observers.participants_identifier_type_id,
                  userTarget.name,
                  userTarget.id
                )
              }}
            </span>
            <span v-else>
              {{ userTarget.name }}
            </span>
          </p>
          <p>ID {{ userTarget.id }}</p>
        </div>
      </div>

      <ul class="chat-scroll" ref="chatScroll">
        <li
          :class="{ right: messageObj.message.sourceID === userSource.id }"
          v-for="messageObj in oldMessages"
          :key="messageObj.id"
        >
          <div
            v-if="messageObj.message.sourceID === userSource.id"
            class="delete-message"
          >
            <button class="hover" @click="deleteMessage(messageObj.id)">
              <b-icon icon="trash" aria-hidden="true"></b-icon>
            </button>
          </div>
          {{ messageObj.message.text }}
          <img
            v-if="messageObj.message.image"
            :src="messageObj.message.image"
            alt=""
          />
        </li>
        <li
          :class="{ right: messageObj.message.sourceID === userSource.id }"
          v-for="messageObj in newMessages"
          :key="messageObj.id"
        >
          <div
            v-if="messageObj.message.sourceID === userSource.id"
            class="delete-message"
          >
            <button class="hover" @click="deleteMessage(messageObj.id)">
              <b-icon icon="trash" aria-hidden="true"></b-icon>
            </button>
          </div>
          {{ messageObj.message.text }}

          <img
            v-if="messageObj.message.image"
            :src="messageObj.message.image"
            alt=""
          />
        </li>
      </ul>

      <form class="send-message">
        <button
          v-if="img"
          @click="close"
          :style="{ background: $store.getters.theme[2] }"
          class="btn delete-img"
        >
          <b-icon icon="x"></b-icon>
        </button>
        <label for="image-chat">
          <div v-if="!img" class="add-img">
            <b-icon icon="card-image" aria-hidden="true"></b-icon>
          </div>
          <input
            ref="inputImg"
            @change="uploadImg('image-chat')"
            id="image-chat"
            class="d-none"
            type="file"
            accept="image/*"
          />
        </label>
        <div :style="{ padding: img.length ? '10px' : 0 }" class="textarea">
          <b-form-textarea
            ref="textarea"
            @keypress="submit"
            @keyup="fitToContent"
            class="px-3"
            size="sm"
            :placeholder="translation.chat.enter_a_message"
            v-model="message"
          ></b-form-textarea>
          <div class="img" v-if="img.length">
            <img :src="img" />
          </div>
        </div>
        <button
          ref="sendButton"
          @click.prevent="sendMessage"
          class="btn send"
          :style="{
            'background-color': $store.getters.theme[2]
          }"
        >
          <img src="@/img/send-message.svg" />
        </button>
      </form>
    </div>
    <div v-if="loaderConversation" class="py-5 d-flex justify-content-center">
      <loader2 :color="$store.getters.theme[2]" />
    </div>
  </div>
</template>

<script>
import { v4 as uuidv4 } from "uuid";
import { api } from "@/services.js";
import imageCropper from "@/components/general/imageCropper.vue";
import { regexpImageName, userTypes, userTypesText } from "@/constants";
import lambda from "@/lambda";
import loader2 from "@/components/general/loader2.vue";
import { mapState } from "vuex";
import { namePrivacy } from "@/helpers.js";

export default {
  props: {
    activeConversation: {
      required: true
    },
    userSource: {
      required: true
    },
    userTarget: {
      required: true
    },
    loaderConversation: {
      required: true
    },
    oldMessages: {
      required: true
    },
    newMessages: {
      required: true
    }
  },

  components: {
    imageCropper,
    loader2
  },

  data() {
    return {
      message: "",
      img: "",
      imgName: "",
      showCropper: false,
      loaderInsertImg: false,
      loaderDeleteMessage: false,
      regexpImageName,
      userTypes,
      userTypesText
    };
  },

  computed: {
    ...mapState(["privacy"])
  },

  watch: {
    activeConversation() {
      this.message = "";
      this.$refs.textarea.$el.style.height = "30px";
      this.img = "";
    }
  },

  methods: {
    namePrivacy,

    goToParticipant(user) {
      if (user.type === this.userTypes.participant) {
        this.$router.push({
          name: "participant",
          query: { _id: user.id, _page: 1, _chat: true }
        });
      }
    },

    fitToContent({ target }) {
      if (this.message.length < 60) {
        target.style.height = "30px";
      } else if (target.scrollHeight <= 150) {
        target.style.height = "auto";
        target.style.height = target.scrollHeight + "px";
        target.style.overflowY = "hidden";
      } else target.style.overflowY = "scroll";
    },

    submit(e) {
      if (e.which === 13 && !e.shiftKey) {
        e.preventDefault();

        this.$refs.sendButton.click();
      }
    },

    deleteMessage(id) {
      const body = {
        action: "DELETE-MESSAGE",
        messageId: id,
        userSourceId: this.userSource.id,
        userSourceType: this.userSource.type
      };

      this.$emit("deleteMessage", body);
    },

    sendMessage() {
      if (this.message.length || this.img) {
        const message = {
          text: this.message,
          image: this.img,
          sourceID: this.userSource.id
        };
        this.$emit("sendMessage", message);

        if (this.$refs.chatScroll)
          this.$refs.chatScroll.scrollTop =
            this.$refs.chatScroll.scrollHeight + 10;

        this.message = "";
        this.$refs.textarea.$el.style.height = "30px";
        this.img = "";
      }
    },

    close() {
      this.showCropper = false;
      this.$refs.inputImg.value = "";
      this.img = "";
    },

    insertImg(src) {
      this.loaderInsertImg = true;

      let arr = src.split(",");
      let mime = arr[0].match(/:(.*?);/)[1];
      let bstr = atob(arr[1]);
      let n = bstr.length;
      let u8arr = new Uint8Array(n);

      while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
      }

      const imgName = `${uuidv4()}-${this.imgName.replace(
        this.regexpImageName,
        ""
      )}`;

      const imgFile = new File([u8arr], imgName, {
        type: mime
      });

      const folder = `communities/${this.$store.getters.community.id}/Chat/${this.activeConversation.id}/Assets/${this.userSource.type}/${this.userSource.id}`;

      lambda
        .getSignedURL(imgFile, folder, imgName)
        .then(url => {
          api
            .put(url, imgFile, {
              headers: {
                "Content-Type": imgFile.type
              }
            })
            .then(r => {
              this.img = r.config.url.split("?")[0];
              this.$refs.inputImg.value = "";
              this.showCropper = false;
              this.loaderInsertImg = false;
            })
            .catch(() => {
              this.$toast.error(
                this.translation.errors_global.something_went_wrong
              );
              this.loaderInsertImg = false;
            });
        })
        .catch(() => {
          this.$toast.error(
            this.translation.errors_global.something_went_wrong
          );
          this.loaderInsertImg = false;
        });
    },

    async uploadImg(element) {
      const file = document.querySelector(`#${element}`).files[0];
      if (file) {
        const toBase64 = file =>
          new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => resolve(reader.result);
            reader.onerror = error => reject(error);
          });
        this.img = await toBase64(file);
        this.imgName = file.name;
        this.showCropper = true;
      }
    }
  },

  updated() {
    if (this.$refs.chatScroll)
      this.$refs.chatScroll.scrollTop = this.$refs.chatScroll.scrollHeight + 10;
  }
};
</script>

<style lang="scss" scoped>
.chat {
  width: 100%;
  display: flex;
  flex-direction: column;
  height: calc(100vh - 150px);
  min-height: 550px;
  background: #ffffff;
  box-shadow: 0px 0px 45px rgba(0, 0, 0, 0.1);
  border-top-right-radius: 40px;
  border-bottom-right-radius: 40px;

  .chat-user {
    display: flex;
    align-items: center;
    border-bottom: 1px solid #c4c4c4;
    padding-bottom: 10px;
    p {
      margin: 0;
      color: #6b6b6b;
      font-size: 0.9rem;
      &:nth-of-type(1) {
        font-weight: bold;
        font-size: 1rem;
      }
    }

    .profile-img {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 50px;
      height: 50px;
      border-radius: 50%;
      overflow: hidden;
      flex-shrink: 0;
      background: #eee;
      margin-right: 20px;
      img {
        object-fit: cover;
        height: 50px;
        width: 50px;
      }
    }
  }

  @media (max-width: 991.98px) {
    border-top-left-radius: 40px;
    border-bottom-left-radius: 40px;
  }

  .chat-scroll {
    display: flex;
    flex-direction: column;
    flex: 1;
    overflow-y: scroll;
    padding: 20px 0 55px;
    margin-bottom: 5px;
    &::-webkit-scrollbar {
      width: 8px;
    }
    &::-webkit-scrollbar-track {
      background: rgba(0, 0, 0, 0);
      border-radius: 10px;
    }
    &::-webkit-scrollbar-thumb {
      background: rgba(0, 0, 0, 0.1);
      border-radius: 10px;
      height: 40px;
    }
    &::-webkit-scrollbar-thumb:hover {
      background: rgba(0, 0, 0, 0.2);
    }
    li {
      max-width: 250px;
      background: #f4f5f7;
      border-radius: 15px;
      border-top-left-radius: 0;
      padding: 10px;
      margin-bottom: 10px;
      list-style: none;
      word-wrap: break-word;
      word-break: break-word;
      transition: all 0.3s ease;
      align-self: flex-start;
      &.right {
        align-self: flex-end;
        border-top-right-radius: 0;
        border-top-left-radius: 15px;
        margin-right: 20px;
      }
      img {
        display: block;
        object-fit: contain;
        max-width: 230px;
        border-radius: 20px;
      }
      &:hover {
        .delete-message {
          display: flex;
        }
        &.right {
          .delete-message {
            justify-content: flex-end;
          }
        }
      }
    }
  }

  .delete-message {
    display: none;
    button {
      border: none;
      background: none;
      color: #6b6b6b;
      &:focus {
        outline: none;
      }
    }
  }

  .send-message {
    display: flex;
    align-items: flex-end;
    position: relative;
    .delete-img {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 30px;
      height: 30px;
      border-radius: 50%;
      color: #ffffff;
      margin-right: 10px;
    }
    label {
      margin-bottom: 0;
      cursor: pointer;
      position: relative;
      .add-img {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 30px;
        height: 30px;
        color: #6b6b6b;
        background: #f4f5f6;
        border-radius: 50%;
        margin-right: 10px;
      }
    }
    .textarea {
      width: 100%;
      background: #f4f5f6;
      border-radius: 40px;
      margin-right: 10px;
      textarea {
        height: 30px;
        background: #f4f5f6;
        border: none;
        border-radius: 40px;
        overflow-y: hidden;
        resize: none;

        &::-webkit-scrollbar {
          width: 6px;
        }
        &::-webkit-scrollbar-track {
          background: rgba(0, 0, 0, 0);
          border-radius: 10px;
        }
        &::-webkit-scrollbar-thumb {
          background: rgba(0, 0, 0, 0);
          border-radius: 10px;
          height: 40px;
        }
        &::-webkit-scrollbar-thumb:hover {
          background: rgba(0, 0, 0, 0.1);
        }
        &:hover {
          &::-webkit-scrollbar-thumb {
            background: rgba(0, 0, 0, 0.1);
            border-radius: 10px;
            height: 40px;
          }
        }
        &:focus {
          box-shadow: none;
        }
      }
      .img {
        display: flex;
        flex-direction: column;
        img {
          object-fit: contain;
          width: 200px;
          border-radius: 20px;
        }
      }
    }
    .send {
      width: 30px;
      height: 30px;
      border-radius: 50%;
      flex-shrink: 0;
      padding: 0;
      position: relative;
      img {
        position: absolute;
        object-fit: contain;
        width: 15px;
        height: 15px;
        right: 6px;
        top: 7px;
      }
    }
  }
}

.close {
  color: #df2938;
  cursor: pointer;
  position: absolute;
  top: 20px;
  right: 30px;
  font-size: 1.3rem;
}
</style>
