<template>
  <div>
    <div class="task-statement-formatted">
      <p :class="{ active: showMore }" v-html="getFormattedTask"></p>
      <div class="m-0" v-if="showMore">
        <div class="slider mb-5 mt-5 mt-lg-4">
          <div
            class="
              d-flex
              flex-column flex-lg-row
              align-items-center
              justify-content-center
            "
          >
            <div
              :style="{
                'border-color': '#ccc'
              }"
              class="item px-3 py-2"
              v-for="(dot, index) in task.question_object.points"
              :key="index"
            >
              <p class="text">{{ dot.value }}</p>

              <div class="dot"></div>
            </div>
          </div>
        </div>
      </div>
      <div class="task-statement-separator">
        <button
          :style="{ color: $store.getters.theme[2] }"
          class="task-statement-show-more hover"
          @click="showMore = !showMore"
        >
          {{
            showMore
              ? translation.global.show_less
              : translation.global.show_more
          }}
        </button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    task: {
      required: true
    },
    getFormattedTask: {
      required: true
    }
  },
  data() {
    return {
      showMore: false
    };
  }
};
</script>

<style lang="scss" scoped>
.slider {
  color: #6b6b6b;
  > div {
    height: 130px;
    overflow: auto;
    &::-webkit-scrollbar {
      height: 8px;
      width: 8px;
    }
    &::-webkit-scrollbar-track {
      background: rgba(0, 0, 0, 0);
      border-radius: 10px;
    }
    &::-webkit-scrollbar-thumb {
      background: #ccc;
      border-radius: 10px;
      height: 40px;
    }
    &::-webkit-scrollbar-thumb:hover {
      background: #aaa;
    }
    .item {
      flex: 1;
      text-align: center;
      position: relative;
      border-bottom: 2px solid;
      .text {
        white-space: nowrap;
        font-size: 1.125rem;
        height: 27px;
      }
      .dot {
        transition: all 0.3s ease-in-out;
        position: absolute;
        display: block;
        height: 15px;
        width: 15px;
        border-radius: 50%;
        left: calc(50% - 7.5px);
        bottom: -7.5px;
        background: #ccc;
      }
    }
  }
}

@media (max-width: 991.98px) {
  .slider {
    > div {
      height: unset;
      .item {
        border-bottom: none;
        border-left: 2px solid;
        .text {
          padding-left: 20px;
          white-space: unset;
          width: 175px;
        }
        .dot {
          left: -7.5px;
          bottom: calc(50% - 2.5px);
        }
      }
    }
  }
}
</style>
