<template>
  <div
    class="tooltip-wrapper"
    @mouseenter.prevent="tooltip = true"
    @mouseleave.prevent="tooltip = false"
  >
    <div class="tooltip-btn">
      <b-icon icon="question" aria-hidden="true"></b-icon>
    </div>
    <div :class="{ active: tooltip, left: left }" class="tooltip-text">
      {{ text }}
    </div>
  </div>
</template>

<script>
export default {
  props: {
    text: {
      required: true
    },
    left: {}
  },
  data() {
    return {
      tooltip: false
    };
  }
};
</script>

<style lang="scss" scoped>
.tooltip-wrapper {
  width: 20px;
  position: relative;
  z-index: 998;
  cursor: pointer;
}

.tooltip-btn {
  position: relative;

  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  width: 15px;
  height: 15px;
  background: #c4c4c4;
  color: #fff;
  cursor: pointer;
  position: relative;
  svg {
    cursor: pointer;
  }
}

.tooltip-text {
  display: none;
  position: absolute;
  z-index: 9000;
  background: #e4e4e4;
  width: 200px;
  padding: 10px;
  font-size: 0.8rem;
  color: #7a7a7a;
  border-radius: 15px;
  animation: show 0.3s linear;
  bottom: 30px;
  right: -3px;
  &.active {
    display: block;
  }
  &::after {
    content: "";
    position: absolute;
    bottom: -19px;
    right: 10px;
    border: 10px solid rgba(0, 0, 0, 0);
    border-top: 15px solid #e4e4e4;
    transform: rotate(-10deg);
  }

  &.left {
    left: -3px;
    &::after {
      right: unset;
      left: 5px;
      transform: rotate(10deg);
    }
  }
}
</style>
