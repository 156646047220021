<template>
  <div>
    <h2 class="title">
      {{ translation.replies.charts_tab.discard_words_from_the_cloud }}
    </h2>
    <div class="discarded-words">
      <form @submit.prevent="addWord">
        <b-form-input
          size="sm"
          required
          type="text"
          v-model="word"
        ></b-form-input>
        <button
          :style="{ background: $store.getters.theme[2] }"
          class="btn btn-sm hover px-4"
        >
          {{ translation.global.add }}
        </button>
      </form>
      <div class="discarded-words-box">
        <div class="scroll">
          <p v-for="(word, index) in discardedWords" :key="index">
            {{ word }}
            <button
              :style="{
                background: $store.getters.theme[2]
              }"
              class="btn hover"
              @click="removeWord(index)"
            >
              <b-icon icon="x"></b-icon>
            </button>
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    config: {
      required: true
    }
  },
  data() {
    return {
      word: "",
      discardedWords: []
    };
  },
  methods: {
    addWord() {
      const wordWithoutSpaces = this.word.replace(/ /g, "");
      const hasWord = this.discardedWords.some(
        word => word === wordWithoutSpaces
      );
      if (!hasWord) this.discardedWords.push(wordWithoutSpaces);
      this.word = "";

      this.$emit("update", this.discardedWords);
    },
    removeWord(index) {
      this.discardedWords.splice(index, 1);

      this.$emit("update", this.discardedWords);
    }
  },
  created() {
    this.discardedWords = this.config.discardedWords;
  }
};
</script>

<style lang="scss" scoped>
.title {
  font-size: 1rem;
  color: #6b6b6b;
  font-weight: bold;
  margin-bottom: 20px;
}

.discarded-words {
  form {
    margin-bottom: 10px;
    display: flex;
    align-items: center;
    button {
      margin-left: 5px;
      color: #fff;
      font-weight: bold;
    }
  }
}

.discarded-words-box {
  background: #ffffff;
  padding: 10px;
  border-radius: 5px;
  p {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    color: #6b6b6b;
    button {
      margin-left: 10px;
      font-size: 1rem;
      color: #ffffff;
      padding: 0px 2px;
    }
  }
}

.scroll {
  height: 100px;
  overflow-y: scroll;
  padding: 10px;

  &::-webkit-scrollbar {
    width: 8px;
  }
  &::-webkit-scrollbar-track {
    background: rgba(0, 0, 0, 0);
    border-radius: 10px;
  }
  &::-webkit-scrollbar-thumb {
    background: #e6e6e6;
    border-radius: 10px;
    height: 40px;
  }
  &::-webkit-scrollbar-thumb:hover {
    background: #ffffff;
  }
}
</style>
