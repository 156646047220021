<template>
  <div>
    <div class="task-statement-formatted">
      <p :class="{ active: showMore }" v-html="getFormattedTask"></p>
      <div v-if="showMore">
        <div class="cards d-flex justify-content-center flex-wrap mt-4">
          <div
            class="card-wrapper"
            v-for="(card, index) in task.question_object.cards"
            :key="index"
          >
            <div :class="{ hasImage: image }" class="card-item">
              <div class="img" v-if="image && card.value.img.length">
                <img :src="card.value.img" />
              </div>
              <div class="text">
                <div
                  :style="{
                    'background-color': $store.getters.theme[2]
                  }"
                ></div>
                <p>{{ card.value.text }}</p>
              </div>
            </div>
          </div>
        </div>
        <div class="d-flex justify-content-center flex-wrap mt-4">
          <div
            class="col-md-3 mb-4"
            v-for="(group, index) in task.question_object.groups"
            :key="index"
          >
            <div class="group py-3 px-2">
              <h3>{{ group.value }}</h3>
            </div>
          </div>
        </div>
      </div>
      <div class="task-statement-separator">
        <button
          :style="{ color: $store.getters.theme[2] }"
          class="task-statement-show-more hover"
          @click="showMore = !showMore"
        >
          {{
            showMore
              ? translation.global.show_less
              : translation.global.show_more
          }}
        </button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    task: {
      required: true
    },
    getFormattedTask: {
      required: true
    }
  },
  computed: {
    image() {
      return this.task.question_object.cards.filter(card => card.has_image)
        .length;
    }
  },
  data() {
    return {
      showMore: false
    };
  }
};
</script>

<style lang="scss" scoped>
.card-wrapper {
  display: flex;
  justify-content: center;
  position: relative;
}

.card-item {
  box-shadow: 0px 0px 37px -16px #c4c4c4;
  border-radius: 10px;
  margin: 10px 6px 6px;
  position: relative;
  background-color: white;
  width: 150px;
  height: 40px;
  &.hasImage {
    width: 150px;
    height: 120px;
    .text {
      div {
        border-top-left-radius: 0px;
        border-top-right-radius: 0px;
      }
    }
  }
  .img {
    display: flex;
    align-items: center;
    justify-content: center;
    img {
      border-radius: 10px;
      object-fit: cover;
      width: 150px;
      height: 120px;
    }
  }
  .text {
    position: absolute;
    bottom: 0;
    z-index: 5;
    height: 50px;
    width: 100%;
    div {
      border-radius: 10px;
      position: absolute;
      top: 0;
      height: 100%;
      width: 100%;
      opacity: 0.8;
      border-radius: 10px;
    }
    p {
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 0;
      font-size: 1rem;
      position: relative;
      z-index: 5;
      font-weight: bold;
      text-align: center;
      color: #fff;
      padding: 5px 10px 5px;
      background: none;
      border: none;
      width: 100%;
      height: 50px;
      line-height: 1rem;
      font-size: 1rem;
      word-wrap: break-word;
      word-break: break-word;
      &:focus {
        outline: none;
      }
    }
  }
}

.group {
  text-align: center;
  width: 100%;
  border-radius: 22px;
  min-height: 100px;
  padding: 20px;
  position: relative;
  margin-right: 20px;
  background-color: #f3f4f6;
  h3 {
    color: #6b6b6b;
    font-weight: bold;
    font-size: 1rem;
    word-wrap: break-word;
    word-break: break-word;
  }
}
</style>
