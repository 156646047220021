<template>
  <div class="row task-wrapper">
    <div v-if="!loading" class="col">
      <div class="task-text img-question">
        <p v-html="text"></p>
      </div>

      <div class="d-flex flex-wrap">
        <attachment :attachments="task.attachments" :deleteFile="null" />
      </div>
      <div class="d-flex justify-content-center py-4">
        <div
          class="select-box"
          :style="{ background: $store.getters.theme[1] }"
        >
          <p class="mb-1">
            {{ translation.answer_task.reaction.minimum_number }}: {{ min }}
          </p>
          <p class="mb-0">
            {{ translation.answer_task.reaction.maximum_number }}: {{ max }}
          </p>
        </div>
      </div>

      <div
        v-if="customTagging"
        class="d-flex flex-wrap justify-content-center mb-3"
      >
        <button
          :class="{ active: colorTask === option.color }"
          class="btn btn-color mr-3 hover"
          v-for="option in options"
          :key="option.index"
          :style="{ background: option.color }"
          @click="(colorTask = option.color), (optionLabel = option.label)"
        >
          {{ option.label }}
        </button>
      </div>

      <div>
        <p
          :class="{ disabled: task.is_answered }"
          class="text-wrapper box-text-highlight"
          ref="taskText"
          @click="markSelection"
          v-html="taggedText"
        ></p>
      </div>

      <div class="comments mt-4">
        <label
          for="comments"
          v-if="
            task.question_object.has_custom_comment_label &&
              task.question_object.comment_label &&
              task.question_object.comment_label.length
          "
          >{{ task.question_object.comment_label }}</label
        >
        <label for="comments" v-else>
          {{ translation.answer_task.comments }}
        </label>
        <textarea
          class="form-control"
          id="textarea"
          v-model="comments"
          :maxlength="maxCharacters"
          rows="3"
          max-rows="6"
          :disabled="task.is_answered"
        ></textarea>
        <characterAndWordCounter
          :count="
            hasWordsLimitation
              ? this.comments.length
                ? this.comments.trim().split(/\s+/).length
                : 0
              : comments.length
          "
          :max="hasWordsLimitation ? maxWords : maxCharacters"
          :label="
            hasWordsLimitation
              ? translation.answer_task.words
              : translation.answer_task.characters
          "
        />
      </div>
    </div>
    <div v-if="loading" class="w-100 d-flex justify-content-center">
      <loader />
    </div>
  </div>
</template>

<script>
import { api } from "@/services.js";
import loader from "@/components/general/loader.vue";
import attachment from "@/components/general/attachment.vue";
import characterAndWordCounter from "@/components/general/characterAndWordCounter.vue";
import { tabOptions } from "@/constants";
import { outsideClick } from "@/helpers.js";

import { userTypesText } from "@/constants";

export default {
  components: { loader, attachment, characterAndWordCounter },
  props: {
    task: {
      required: true
    },
    save: {
      required: true
    },
    savePreview: {
      required: true
    },
    getTasks: {
      required: true
    }
  },
  filters: {
    formatTime(value) {
      const date = new Date(0);
      date.setSeconds(value);

      return date.toISOString().substr(11, 8);
    }
  },
  data() {
    return {
      tabOptions,
      outsideClick,
      loading: false,
      question: "",
      reactions: [],
      taggedText: "",
      comments: "",
      colorTask: ""
    };
  },
  computed: {
    text() {
      const regexpVideo = /(?:(<iframe.*?src="))(.*?)((?:".*?>))((?:<\/iframe>))?/g;

      return this.$store.getters.info.user.type === userTypesText.participant
        ? this.task.raw_question.replace(
            regexpVideo,
            "<video src='$2' width='350' height='197' frameborder='0' allowfullscreen='allowfullscreen' controls controlsList='nodownload'></iframe>"
          )
        : this.task.raw_question;
    },
    page() {
      return this.$store.getters.activeTaskIndex;
    },
    options() {
      return this.task.question_object.options;
    },
    min() {
      return this.task.question_object.min_answers
        ? this.task.question_object.min_answers
        : 1;
    },
    max() {
      return this.task.question_object.max_answers
        ? this.task.question_object.max_answers
        : 10;
    },
    marks() {
      return this.task.question_object.marks;
    },
    textTask() {
      return this.task.question_object.raw_text;
    },
    customTagging() {
      return this.task.question_object.custom_tagging;
    },
    obligatoryComment() {
      return this.task.question_object?.has_obligatory_comments;
    },

    hasCharactersLimitation() {
      return this.task.question_object?.has_characters_limitation;
    },

    maxCharacters() {
      return this.hasCharactersLimitation
        ? this.task.question_object?.characters_count?.maximum
        : 5000;
    },

    minCharacters() {
      return this.hasCharactersLimitation
        ? this.task.question_object?.characters_count?.minimum
        : 0;
    },

    hasWordsLimitation() {
      return this.task.question_object?.has_words_limitation;
    },

    maxWords() {
      return this.hasWordsLimitation
        ? this.task.question_object?.words_count?.maximum
        : 2000;
    },

    minWords() {
      return this.hasWordsLimitation
        ? this.task.question_object?.words_count?.minimum
        : 0;
    }
  },
  watch: {
    save() {
      this.answer();
    },
    savePreview() {
      this.answerPreview();
    },
    page() {
      this.getQuestion();
    },
    comments() {
      if (this.comments.length >= this.maxCharacters) {
        this.comments = this.comments
          .split("")
          .splice(0, this.maxCharacters)
          .join("");
      }

      const words = this.comments.split(/\s+/);

      const wordsCount = words.length;

      if (wordsCount > this.maxWords) {
        this.comments = words.splice(0, this.maxWords).join(" ");
      }
    }
  },
  methods: {
    markSelection({ target }) {
      if (target.nodeName === "SPAN" && !this.disabled) {
        const mark = this.marks.find(mark => mark.mark === target.innerText);

        if (target.classList.contains("active")) {
          const index = this.reactions.indexOf(
            this.reactions.find(
              reaction => reaction.mark.mark === target.innerText
            )
          );

          if (this.reactions[index].label === this.optionLabel) {
            target.classList.remove("active");
            target.style.background = "";
            target.style.color = "#6b6b6b";
          } else {
            target.style.background = this.colorTask;
            target.style.color = "#ffffff";

            this.reactions.push({
              label: this.optionLabel,
              mark: mark
            });
          }

          this.reactions.splice(index, 1);
        } else {
          target.classList.add("active");
          target.style.background = this.colorTask;
          target.style.color = "#ffffff";

          this.reactions.push({
            label: this.optionLabel,
            mark: mark
          });
        }
      }
    },
    getOptions() {
      if (this.customTagging) {
        return this.options.map(option => ({
          label: option.label,
          label_index: option.index,
          marks: this.marks.map(mark => ({
            index: mark.index,
            text: mark.mark,
            highlighted: this.reactions.find(
              reaction =>
                reaction.mark.index === mark.index &&
                reaction.label === option.label
            )
              ? 1
              : 0
          }))
        }));
      } else {
        return [
          {
            label: "Default",
            label_index: 0,
            marks: this.marks.map(mark => ({
              index: mark.index,
              text: mark.mark,
              highlighted: this.reactions.find(
                reaction => reaction.mark.index === mark.index
              )
                ? 1
                : 0
            }))
          }
        ];
      }
    },
    answerPreview() {
      const mandatoryOptions = this.options
        .filter(option => option.mandatory)
        .map(option => option.label);

      const missingOptions = [];

      mandatoryOptions.forEach(option => {
        !this.reactions.filter(reaction => reaction.label === option).length &&
          missingOptions.push(option);
      });

      if (!this.loading) {
        if (this.reactions.length) {
          if (this.reactions.length >= this.min) {
            if (this.reactions.length <= this.max) {
              if (!missingOptions.length) {
                if (this.obligatoryComment && !this.comments.length) {
                  this.$toast.error(
                    this.translation.answer_task.obligatory_comment_tost
                  );

                  return;
                }

                if (
                  this.comments.length &&
                  this.hasCharactersLimitation &&
                  this.comments.length < this.minCharacters
                ) {
                  this.$toast.error(
                    `${this.translation.answer_task.min_characters} ${this.minCharacters} ${this.translation.answer_task.characters}`
                  );

                  return;
                }

                if (this.comments.length && this.hasWordsLimitation) {
                  const wordsCount = this.comments.split(/\s+/).length;

                  if (wordsCount < this.minWords) {
                    this.$toast.error(
                      `${this.translation.answer_task.min_word} ${this.minWords} ${this.translation.answer_task.words}`
                    );

                    return;
                  }
                }

                this.loading = true;

                const answer = {
                  text: this.task.question_object.text,
                  comment: this.comments,
                  options: this.getOptions(),
                  reactions: this.reactions
                };

                const body = {
                  task_id: this.task.id,
                  has_attachment: 0,
                  has_video: 0,
                  has_image: 0,
                  raw_answer: this.$refs.taskText.innerHTML,
                  answer
                };

                api
                  .post("/answer/send/preview", body, {
                    headers: {
                      "Content-Type": "application/json",
                      Authorization: `Bearer ${this.$store.getters.token}`
                    }
                  })
                  .then(r => {
                    if (r.status === 200) {
                      this.$toast.success(
                        this.translation.answer_task.success.reply_registered
                      );
                      this.getTasks("save");
                      this.loading = false;
                    }
                  })
                  .catch(() => {
                    this.$toast.error(
                      this.translation.answer_task.error.reply_not_registered
                    );
                    this.loading = false;
                  });
              } else {
                const message =
                  missingOptions.length > 1
                    ? this.translation.answer_task.reaction.are_mandatory
                    : this.translation.answer_task.reaction.is_mandatory;

                let text = "";
                missingOptions.forEach(option => (text += `"${option}" `));
                this.$toast.error(`${text} ${message}`);
              }
            } else {
              this.$toast.error(
                `${this.translation.answer_task.reaction.maximum_number}: ${this.max}`
              );
            }
          } else {
            this.$toast.error(
              `${this.translation.answer_task.reaction.minimum_number}: ${this.min}`
            );
          }
        } else {
          this.$toast.error(this.translation.answer_task.error.empty_answer);
        }
      }
    },
    answer() {
      const mandatoryOptions = this.options
        .filter(option => option.mandatory)
        .map(option => option.label);

      const missingOptions = [];

      mandatoryOptions.forEach(option => {
        !this.reactions.filter(reaction => reaction.label === option).length &&
          missingOptions.push(option);
      });

      if (!this.loading) {
        if (this.reactions.length) {
          if (this.reactions.length >= this.min) {
            if (this.reactions.length <= this.max) {
              if (!missingOptions.length) {
                if (this.obligatoryComment && !this.comments.length) {
                  this.$toast.error(
                    this.translation.answer_task.obligatory_comment_tost
                  );

                  return;
                }

                if (
                  this.comments.length &&
                  this.hasCharactersLimitation &&
                  this.comments.length < this.minCharacters
                ) {
                  this.$toast.error(
                    `${this.translation.answer_task.min_characters} ${this.minCharacters} ${this.translation.answer_task.characters}`
                  );

                  return;
                }

                if (this.comments.length && this.hasWordsLimitation) {
                  const wordsCount = this.comments.split(/\s+/).length;

                  if (wordsCount < this.minWords) {
                    this.$toast.error(
                      `${this.translation.answer_task.min_word} ${this.minWords} ${this.translation.answer_task.words}`
                    );

                    return;
                  }
                }

                this.loading = true;

                const answer = {
                  text: this.task.question_object.text,
                  comment: this.comments,
                  options: this.getOptions(),
                  reactions: this.reactions
                };

                const body = {
                  task_id: this.task.id,
                  participant_id: this.$store.getters.info.user.sub,
                  has_attachment: 0,
                  has_video: 0,
                  has_image: 0,
                  raw_answer: this.$refs.taskText.innerHTML,
                  answer
                };

                api
                  .post("/answer/send", body, {
                    headers: {
                      "Content-Type": "application/json",
                      Authorization: `Bearer ${this.$store.getters.token}`
                    }
                  })
                  .then(r => {
                    if (r.status === 200) {
                      this.$toast.success(
                        this.translation.answer_task.success.reply_registered
                      );
                      this.getTasks("save");
                      this.loading = false;
                    }
                  })
                  .catch(() => {
                    this.$toast.error(
                      this.translation.answer_task.error.reply_not_registered
                    );
                    this.loading = false;
                  });
              } else {
                const message =
                  missingOptions.length > 1
                    ? this.translation.answer_task.reaction.are_mandatory
                    : this.translation.answer_task.reaction.is_mandatory;

                let text = "";
                missingOptions.forEach(option => (text += `"${option}" `));
                this.$toast.error(`${text} ${message}`);
              }
            } else {
              this.$toast.error(
                `${this.translation.answer_task.reaction.maximum_number}: ${this.max}`
              );
            }
          } else {
            this.$toast.error(
              `${this.translation.answer_task.reaction.minimum_number}: ${this.min}`
            );
          }
        } else {
          this.$toast.error(this.translation.answer_task.error.empty_answer);
        }
      }
    },
    getQuestion() {
      const regexp = /<mark[^>]*>(.*?)<\/mark>/g;

      this.taggedText = this.textTask.replace(regexp, "<span>$1</span>");

      this.init();
    },
    init() {
      if (this.task.is_answered) {
        this.comments = this.task.answer.answer_object.comment;
        this.reactions = this.task.answer.answer_object.reactions;
        this.taggedText = this.task.answer.raw_answer;
        this.disabled = true;
      } else if (this.task.answer && this.$route.query._preview) {
        if (this.customTagging) {
          this.optionLabel = this.options[0].label;
          this.colorTask = this.options[0].color;
        } else {
          this.colorTask = this.$store.getters.theme[2];
          this.optionLabel = "Default";
        }
        this.comments = this.task.answer.answer_object.comment;
        this.reactions = this.task.answer.answer_object.reactions;
        this.taggedText = this.task.answer.raw_answer;
      } else {
        if (this.customTagging) {
          this.optionLabel = this.options[0].label;
          this.colorTask = this.options[0].color;
        } else {
          this.colorTask = this.$store.getters.theme[2];
          this.optionLabel = "Default";
        }

        this.comments = "";
        this.reactions = [];
        this.disabled = false;
      }
    }
  },
  created() {
    this.getQuestion();
  },
  mounted() {
    if (this.$store.getters.info.user.type === userTypesText.participant) {
      const taskWrapper = document.querySelector(".task-wrapper");

      taskWrapper.addEventListener("contextmenu", e => {
        e.preventDefault();
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.task-text {
  display: flex;
  flex-direction: column;
  color: #6b6b6b;
  overflow-wrap: break-word;
}

.comments {
  label {
    color: #6b6b6b;
  }
  textarea {
    width: 100%;
    display: block;
    border: 1px solid #b3b3b3;
    border-radius: 10px;
  }
}

.select-box {
  display: inline-block;
  padding: 20px;
  border-radius: 20px;
  color: #6b6b6b;
  font-weight: bold;
  font-size: 1.2rem;
}

.reactions {
  margin-top: 20px;
  p {
    margin-right: 10px;
    font-weight: bold;
    color: #6b6b6b;
  }
  div {
    margin-bottom: 15px;
    display: flex;
    .time {
      display: flex;
      align-items: center;
      font-size: 2rem;
      font-weight: bold;
      color: #6b6b6b;
      background: #efefef;
      padding: 10px;
      border-radius: 10px;
    }

    .comment {
      display: flex;
      flex-direction: column;
      margin-left: 20px;
      font-weight: bold;
      color: #6b6b6b;
      div {
        display: flex;
        justify-content: space-between;
        align-items: center;
      }
      textarea {
        margin-top: 10px;
      }
    }
  }
}

.break-label {
  word-wrap: break-word;
  word-break: break-word;
}

.btn-remove {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 25px;
  height: 25px;
  font-weight: bold;
  color: #fff;
  font-size: 1rem;
  border-radius: 50%;
  white-space: nowrap;
  background: #df2938;
  align-self: flex-start;
}

.text-wrapper {
  color: #6b6b6b;
  overflow-wrap: break-word;
  font-size: 1.1rem;
  line-height: 1.8rem;
  border: 1px dotted #b3b3b3;
  padding: 10px;
  border-radius: 5px;
}

.btn-color {
  color: #fff;
  font-weight: bold;
  &.active {
    box-shadow: 0 0 0 0.3rem rgba(0, 0, 0, 0.1);
  }
}
</style>
