var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"row task-wrapper"},[(!_vm.loading)?_c('div',{staticClass:"col"},[_c('div',{staticClass:"task-text img-question"},[_c('p',{domProps:{"innerHTML":_vm._s(_vm.text)}})]),_c('div',{staticClass:"d-flex flex-wrap"},[_c('attachment',{attrs:{"attachments":_vm.task.attachments,"deleteFile":null}})],1),_c('div',{staticClass:"d-md-block d-none"},[(_vm.matrix.length)?_c('div',{staticClass:"table-responsive pr-3 pb-4",style:({ background: _vm.$store.getters.theme[1] })},[_c('table',{staticClass:"table table-borderless table-sm"},[_c('tbody',[_c('tr',[_c('td',{attrs:{"colspan":"1"}}),_vm._l((_vm.cols),function(col,cIndex){
var _obj;
return _c('td',{key:("col-" + cIndex),staticClass:"col-table",style:(( _obj = {}, _obj['min-width'] = col.value.length > 50
                      ? col.value.length > 100
                        ? col.value.length > 200
                          ? '500px'
                          : '250px'
                        : '150px'
                      : '100px', _obj )),attrs:{"scope":"col"}},[_vm._v(" "+_vm._s(col.value)+" ")])})],2),_vm._l((_vm.rows),function(row,rIndex){
                var _obj;
return _c('tr',{key:("row-" + rIndex)},[_c('td',{staticClass:"row-table",style:(( _obj = {}, _obj['min-width'] = row.value.length > 50
                      ? row.value.length > 100
                        ? row.value.length > 200
                          ? '500px'
                          : '250px'
                        : '150px'
                      : '100px', _obj )),attrs:{"scope":"row"}},[_vm._v(" "+_vm._s(row.value)+" ")]),_vm._l((_vm.getValues(row)),function(value,vIndex){return _c('td',{key:("value-" + vIndex),staticClass:"value-table",class:{ active: value.value },on:{"click":function($event){return _vm.toggleValue(value)}}},[_c('p',{class:{ disabled: _vm.isDisabled(value) }},[_c('b-icon',{attrs:{"icon":"x"}})],1)])})],2)})],2)])]):_vm._e(),_c('div',{staticClass:"comments"},[(
            _vm.task.question_object.has_custom_comment_label &&
              _vm.task.question_object.comment_label &&
              _vm.task.question_object.comment_label.length
          )?_c('label',{attrs:{"for":"comments"}},[_vm._v(_vm._s(_vm.task.question_object.comment_label))]):_c('label',{attrs:{"for":"comments"}},[_vm._v(" "+_vm._s(_vm.translation.answer_task.comments)+" ")]),_c('textarea',{directives:[{name:"model",rawName:"v-model",value:(_vm.comments),expression:"comments"}],staticClass:"form-control",attrs:{"id":"textarea","maxlength":_vm.maxCharacters,"rows":"3","max-rows":"6","disabled":_vm.task.is_answered},domProps:{"value":(_vm.comments)},on:{"input":function($event){if($event.target.composing){ return; }_vm.comments=$event.target.value}}}),_c('characterAndWordCounter',{attrs:{"count":_vm.hasWordsLimitation
              ? this.comments.length
                ? this.comments.trim().split(/\s+/).length
                : 0
              : _vm.comments.length,"max":_vm.hasWordsLimitation ? _vm.maxWords : _vm.maxCharacters,"label":_vm.hasWordsLimitation
              ? _vm.translation.answer_task.words
              : _vm.translation.answer_task.characters}})],1)]),_c('div',{staticClass:"d-md-none"},[_c('div',_vm._l((_vm.cols),function(col,cIndex){return _c('div',{key:cIndex},[_c('button',{staticClass:"btn col-mobile-btn px-2 py-1",style:({ background: _vm.$store.getters.theme[1] }),attrs:{"id":("col-" + cIndex)},on:{"click":function($event){return _vm.openRows(("rows-" + cIndex), ("col-" + cIndex))}}},[_c('div',[_vm._v(" "+_vm._s(col.value)+" ")]),_c('b-icon',{attrs:{"icon":"caret-down-fill","aria-hidden":"true"}})],1),_c('div',{staticClass:"row-mobile-wrapper",attrs:{"id":("rows-" + cIndex)}},_vm._l((_vm.rows),function(row,rIndex){return _c('div',{key:rIndex},[_c('b-form-checkbox',{attrs:{"id":("matrix-" + cIndex + "-" + rIndex),"name":("matrix-" + cIndex + "-" + rIndex),"disabled":(_vm.isDisabled(_vm.matrix[cIndex][rIndex]) &&
                    !_vm.matrix[cIndex][rIndex].value) ||
                    _vm.task.is_answered},model:{value:(_vm.matrix[cIndex][rIndex].value),callback:function ($$v) {_vm.$set(_vm.matrix[cIndex][rIndex], "value", $$v)},expression:"matrix[cIndex][rIndex].value"}},[_vm._v(" "+_vm._s(row.value)+" ")])],1)}),0)])}),0),_c('div',{staticClass:"comments"},[(
            _vm.task.question_object.has_custom_comment_label &&
              _vm.task.question_object.comment_label &&
              _vm.task.question_object.comment_label.length
          )?_c('label',{attrs:{"for":"comments"}},[_vm._v(_vm._s(_vm.task.question_object.comment_label))]):_c('label',{attrs:{"for":"comments"}},[_vm._v(" "+_vm._s(_vm.translation.answer_task.comments)+" ")]),_c('textarea',{directives:[{name:"model",rawName:"v-model",value:(_vm.comments),expression:"comments"}],staticClass:"form-control",attrs:{"id":"textarea","maxlength":_vm.maxCharacters,"rows":"3","max-rows":"6","disabled":_vm.task.is_answered},domProps:{"value":(_vm.comments)},on:{"input":function($event){if($event.target.composing){ return; }_vm.comments=$event.target.value}}}),_c('characterAndWordCounter',{attrs:{"count":_vm.hasWordsLimitation
              ? this.comments.length
                ? this.comments.trim().split(/\s+/).length
                : 0
              : _vm.comments.length,"max":_vm.hasWordsLimitation ? _vm.maxWords : _vm.maxCharacters,"label":_vm.hasWordsLimitation
              ? _vm.translation.answer_task.words
              : _vm.translation.answer_task.characters}})],1)])]):_vm._e(),(_vm.loading)?_c('div',{staticClass:"w-100 d-flex justify-content-center"},[_c('loader')],1):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }