<template>
  <div>
    <div class="d-flex flex-wrap">
      <div v-if="answer.answer_object.files">
        <div v-for="media in answer.answer_object.files" :key="media.name">
          <div class="attachment mr-3 mb-2">
            <div class="d-flex py-2 px-3" @click="download(media.url)">
              <b-icon icon="paperclip" aria-hidden="true"></b-icon>
              <div class="ml-2">
                <a class="mb-0">{{ media.name }}</a>
                <p class="mb-0">{{ formatBytes(media.size) }}</p>
              </div>
            </div>
          </div>
          <p class="mb-4 ml-2">
            {{ media.description }}
          </p>
        </div>
      </div>
      <attachment
        v-else
        :attachments="answer.answer_object.attachments"
        :deleteFile="null"
      />
    </div>
    <p
      class="mt-2"
      v-if="answer.answer_object && answer.answer_object.comment.length"
    >
      <span :style="{ color: $store.getters.theme[3] }" class="comments">
        {{ translation.answer_task.comments }}
      </span>
      {{ answer.answer_object.comment }}
    </p>
  </div>
</template>

<script>
import attachment from "@/components/general/attachment.vue";
import { formatBytes } from "@/helpers.js";

export default {
  props: {
    answer: {
      required: true
    }
  },
  components: { attachment },
  methods: {
    formatBytes,
    download(url) {
      window.open(url, "_blank");
      window.focus();
    }
  }
};
</script>

<style lang="scss" scoped>
.comments {
  display: block;
  text-transform: uppercase;
  font-weight: bold;
}
</style>
