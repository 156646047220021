<template>
  <div>
    <div v-if="question.length" class="question">
      {{ question }}
    </div>
    <Bar
      ref="chart"
      v-if="chartData && chartOptions"
      :chart-options="chartOptions"
      :chart-data="chartData"
      :chart-id="chartId"
      :dataset-id-key="datasetIdKey"
      :plugins="plugins"
      :css-classes="cssClasses"
      :styles="styles"
      :width="width"
      :height="height"
    />
    <div class="d-flex justify-content-end">
      <button
        :class="{ hover: !pptLoader }"
        class="btn-download"
        :disabled="pptLoader"
        @click="exportPPT"
      >
        <div
          v-if="pptLoader"
          class="d-flex justify-content-center align-items-center"
        >
          <loader2 color="#6b6b6b" class="mr-1" />
          {{ translation.replies.charts_tab.download_chart }}
        </div>
        <div v-else>
          <b-icon icon="download" aria-hidden="true"></b-icon>
          {{ translation.replies.charts_tab.download_chart }}
        </div>
      </button>
    </div>
  </div>
</template>

<script>
import { Bar } from "vue-chartjs/legacy";
import pptxgen from "pptxgenjs";
import loader2 from "@/components/general/loader2.vue";

import {
  Chart as ChartJS,
  Title,
  Tooltip,
  Legend,
  BarElement,
  CategoryScale,
  LinearScale
} from "chart.js";

ChartJS.register(
  Title,
  Tooltip,
  Legend,
  BarElement,
  CategoryScale,
  LinearScale
);

export default {
  name: "BarChart",
  components: {
    Bar,
    loader2
  },
  props: {
    chartId: {
      type: String,
      default: "bar-chart"
    },
    datasetIdKey: {
      type: String,
      default: "label"
    },
    width: {
      type: Number,
      default: 400
    },
    height: {
      type: Number,
      default: 600
    },
    cssClasses: {
      default: "",
      type: String
    },
    styles: {
      type: Object,
      default: () => {}
    },
    plugins: {
      type: Array,
      default: () => []
    },
    colors: {
      required: true
    },
    hasOneColor: {
      required: true
    },
    data: {
      required: true
    },
    getPercent: {
      required: true
    },
    isPercent: {
      required: true
    },
    question: {
      required: true
    },
    statement: {
      required: true
    }
  },
  data() {
    return {
      chartData: null,
      chartOptions: null,
      pptLoader: false
    };
  },
  methods: {
    async exportPPT() {
      const backgroundColor = this.hasOneColor
        ? [this.colors[0]]
        : [...this.colors].reverse();
      this.pptLoader = true;
      let slide;
      let pres = new pptxgen();

      slide = pres.addSlide();

      slide.addText(this.statement, {
        x: 0.2,
        y: 0.2,
        color: "6b6b6b",
        fontSize: 10
      });

      slide.addText(this.question, {
        x: 0.5,
        y: 0.5,
        color: "6b6b6b",
        fontSize: 10
      });

      const dataReversed = [...this.data].reverse();

      const labels = dataReversed.map(data => data.label);
      const data = dataReversed.map(item =>
        this.isPercent ? this.getPercent(item, dataReversed) : item.value
      );

      let options = {
        x: 0.8,
        y: 0.8,
        w: 7.0,
        h: 3.5,
        barDir: "bar",
        chartColors: backgroundColor.map(color => color.replace("#", "")),
        showLegend: false
      };

      if (this.isPercent) {
        options.valAxisMinVal = 0;
        options.valAxisMaxVal = 100;
      }

      slide.addChart(
        pres.charts.BAR,
        [
          {
            name: "",
            labels: labels,
            values: data
          }
        ],
        options
      );

      pres.writeFile({ fileName: "chart" });

      this.pptLoader = false;
    },
    formatLabel(label) {
      if (label.length > 30) {
        const words = label.split(" ");

        if (!words.length) return label;

        const newArray = [];
        let count = 0;
        let piece = "";

        for (let index = 0; index < words.length; index++) {
          piece += `${words[index]} `;
          if (count === 6) {
            newArray.push(piece);
            piece = "";
            count = 0;
          }
          count++;
        }

        if (piece.length) newArray.push(piece);

        return newArray;
      } else return label;
    },
    setData() {
      const backgroundColor = this.hasOneColor ? [this.colors[0]] : this.colors;

      const labels = this.data.map(data => this.formatLabel(data.label));
      const data = this.data.map(item =>
        this.isPercent ? this.getPercent(item, this.data) : item.value
      );

      this.chartData = {
        labels: labels,
        datasets: [
          {
            label: "",
            backgroundColor: backgroundColor,
            data: data
          }
        ]
      };
    },
    setOptions() {
      this.chartOptions = {
        responsive: true,
        maintainAspectRatio: false,
        indexAxis: "y",
        scales: {
          x: {
            min: 0
          }
        },
        plugins: {
          legend: {
            display: false
          }
        }
      };

      if (this.isPercent) {
        this.chartOptions.scales = {
          x: {
            min: 0,
            max: 100,

            ticks: {
              callback: value => `${value}%`
            },
            scaleLabel: {
              display: true
            }
          }
        };

        this.chartOptions.plugins.tooltip = {
          callbacks: {
            label: tooltipItem => `${tooltipItem.raw.toFixed(2)}%`
          }
        };
      }
    }
  },
  mounted() {
    this.setOptions();
    this.setData();
  }
};
</script>

<style lang="scss" scoped>
.btn-download {
  display: flex;
  align-items: center;
  background: none;
  border: none;
  font-size: 1.2rem;
  font-weight: bold;
  color: #6b6b6b;
  margin-top: 30px;
  &:focus {
    outline: none;
  }
  svg {
    margin-right: 5px;
  }
}

.question {
  color: #6b6b6b;
  text-align: center;
  margin-bottom: 30px;
}
</style>
