<template>
  <div class="container">
    <div class="row py-5">
      <div class="col">
        <div class="card-b mb-4 p-5">
          <h2
            class="h5 font-weight-bold mb-0"
            :style="{ color: $store.getters.theme[3] }"
          >
            {{ translation.terms_and_policies.edit_content }}
          </h2>
          <div class="d-md-flex d-block justify-content-center pt-5 pb-3">
            <div class="d-flex mr-5">
              <div
                :class="{ active: option === 'policy' }"
                class="box py-4 px-md-5 px-4 mb-3 mb-md-0"
              >
                {{ translation.terms_and_policies.privacy_policy }}
              </div>
              <div id="dropdown-policy">
                <button
                  class="btn-edit ml-2 mr-3"
                  @click="handleDrop('dropdown-policy')"
                >
                  <b-icon icon="pencil" aria-hidden="true"></b-icon>
                </button>
                <div id="dropdown">
                  <a @click="edit('policy')">
                    {{ translation.global.edit }}
                  </a>
                </div>
              </div>
            </div>
            <div class="d-flex">
              <div
                :class="{ active: option === 'terms' }"
                class="box py-4 px-md-5 px-4"
              >
                {{ translation.terms_and_policies.terms_of_use }}
              </div>
              <div id="dropdown-terms">
                <button
                  class="btn-edit ml-2 mr-3"
                  @click="handleDrop('dropdown-terms')"
                >
                  <b-icon icon="pencil" aria-hidden="true"></b-icon>
                </button>
                <div id="dropdown">
                  <a @click="edit('terms')">
                    {{ translation.global.edit }}
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div v-if="showEditor && !loaderText" class="show pt-4">
            <editor
              placeholder=""
              :text="text"
              :maxCharacters="maxCharacters"
              @charactersCount="count => (charactersCount = count)"
              @update="txt => (text = txt)"
            />
            <div class="d-flex justify-content-end">
              <button
                @click.prevent="save"
                class="btn save-btn my-4 d-flex justify-content-center"
              >
                <loader2 v-if="loaderSaveText" color="#6b6b6b" />
                <p v-if="!loaderSaveText" class="m-0">
                  {{ translation.global.save }}
                </p>
              </button>
            </div>
          </div>
          <div
            v-else-if="showEditor && loaderText"
            class="d-flex justify-content-center py-5"
          >
            <loader />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { api } from "@/services.js";
import editor from "@/components/general/editor.vue";
import loader from "@/components/general/loader.vue";
import loader2 from "@/components/general/loader2.vue";
import { userTypesText } from "@/constants";
import { outsideClick } from "@/helpers.js";

export default {
  components: { editor, loader, loader2 },
  data() {
    return {
      activities: [],
      reload: false,
      loader: false,
      loaderText: false,
      loaderSaveText: false,
      showEditor: false,
      option: "",
      text: "<p></p>",
      policyAndTerms: null,
      maxCharacters: 30000,
      charactersCount: 0,
      outsideClick,
      userTypesText
    };
  },
  computed: {
    nextActivity() {
      if (this.activities && this.activities.length) return this.activities[0];
      else return {};
    },
    filterActivities() {
      if (this.activities && this.activities.length >= 3)
        return this.activities.slice(0, 3);
      else return this.activities;
    }
  },
  watch: {
    text() {
      this.updateText();
    },
    reload() {
      this.getActivities();
    },
    option() {
      if (this.policyAndTerms) {
        if (this.option === "policy") {
          this.text = this.policyAndTerms.privacy_policy;
        } else if (this.option === "terms") {
          this.text = this.policyAndTerms.terms;
        }
      }
    }
  },
  methods: {
    getActivities() {
      this.loader = true;

      let url = `community/${this.$store.getters.community.id}/activities`;
      if (this.$store.getters.info.user.type === this.userTypesText.participant)
        url = `community/${this.$store.getters.community.id}/participant/${this.$store.getters.info.user.sub}/activities`;

      api
        .get(url, {
          headers: {
            Authorization: `Bearer ${this.$store.getters.token}`
          }
        })
        .then(r => {
          if (r.status === 200) {
            this.activities = r.data.community.activities;
          }
          this.loader = false;
        })
        .catch(() => {
          this.loader = false;
        });
    },
    getText() {
      this.loaderText = true;
      const termsAndPoliciesDefault = {
        privacy_policy: this.termsAndPoliciesDefault,
        terms: this.termsAndPoliciesDefault
      };
      api
        .get(
          `community/${this.$store.getters.community.id}/privacy-policy-terms`,
          {
            headers: {
              Authorization: `Bearer ${this.$store.getters.token}`
            }
          }
        )
        .then(r => {
          if (r.status === 200) {
            const noText = 1;
            if (+this.$store.getters.community.fk_privacy_policy_id !== noText)
              this.policyAndTerms = r.data;
            else {
              this.policyAndTerms = termsAndPoliciesDefault;
            }
          }
          this.loaderText = false;
        })
        .catch(() => {
          this.policyAndTerms = termsAndPoliciesDefault;
          this.loaderText = false;
        });
    },
    edit(option) {
      if (this.showEditor) {
        if (option === this.option) {
          this.option = "";
          this.showEditor = false;
        } else {
          this.option = option;
        }
      } else {
        this.option = option;
        this.showEditor = true;
      }
    },
    updateText() {
      if (this.option === "policy") {
        this.policyAndTerms.privacy_policy = this.text;
      } else {
        this.policyAndTerms.terms = this.text;
      }
    },
    save() {
      if (!this.loaderSaveText) {
        if (this.charactersCount <= this.maxCharacters) {
          this.loaderSaveText = true;

          let body = {
            data: { privacy_policy: this.policyAndTerms.privacy_policy }
          };
          if (this.option === "terms")
            body = {
              data: { terms: this.policyAndTerms.terms }
            };

          api
            .put(
              `community/${this.$store.getters.community.id}/privacy-policy-terms`,
              body,
              {
                headers: {
                  "Content-Type": "application/json",
                  Authorization: `Bearer ${this.$store.getters.token}`
                }
              }
            )
            .then(r => {
              if (r.status === 200) {
                const community = this.$store.getters.community;
                community.fk_privacy_policy_id = "2";
                this.$store.commit("UPDATE_COMMUNITY", community);
                if (this.option === "policy") {
                  this.$toast.success(
                    this.translation.terms_and_policies.success
                      .privacy_policy_successfully_changed
                  );
                } else {
                  this.$toast.success(
                    this.translation.terms_and_policies.success
                      .terms_of_use_successfully_changed
                  );
                }
              }
              this.loaderSaveText = false;
            })
            .catch(() => {
              this.loaderSaveText = false;
            });
        } else {
          this.$toast.error(this.translation.errors_global.you_have_exceeded);
        }
      }
    },
    handleDrop(drop) {
      const dropItem = document.querySelector(`#${drop}`);
      dropItem.children[1].classList.toggle("active");

      this.outsideClick(dropItem, ["click"], () => {
        dropItem.children[1].classList.remove("active");
      });
    }
  },
  created() {
    this.getActivities();
    this.getText();
  }
};
</script>

<style lang="scss" scoped>
.save-btn {
  text-align: center;
  text-transform: uppercase;
  color: #6b6b6b;
  font-weight: bold;
  background: #ebf2f2;
  letter-spacing: 0.16em;
  border-radius: 17px;
  border: none;
  padding: 5px 20px;
  align-self: flex-end;
  margin-top: 3rem;
  min-width: 162px;
}

.label {
  font-size: 0.875rem;
  color: #6b6b6b;
  margin-bottom: 15px;
}

.btn-edit {
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  color: #6b6b6b;
  font-size: 1rem;
  box-shadow: 0 0 10px rgba(123, 123, 123, 0.25);
  background: none;
  border: none;
  &:focus {
    outline: 0;
    transition: all 0.3s ease;
  }
}

.box {
  background-color: #f3f4f6;
  color: #6b6b6b;
  font-weight: bold;
  font-size: 1.2rem;
  text-transform: uppercase;
  border-radius: 20px;
  transition: all 0.5s ease;
  &.active {
    background-color: #6b6b6b;
    color: white;
  }
}

#dropdown-policy,
#dropdown-terms {
  position: relative;
  display: flex;
  justify-content: center;
}

#dropdown {
  display: none;
  animation: grow 400ms ease-in-out forwards;
  transform-origin: top center;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 4px;
  position: absolute;
  top: 40px;
  right: 10px;
  z-index: 5;
  background: #fff;
  width: 100px;
  &.active {
    display: block;
  }
  a {
    cursor: pointer;
    display: block;
    padding: 5px 10px;
    color: #999;
    text-decoration: none;
    font-weight: normal;
    &:hover {
      background: rgba(193, 193, 193, 0.4);
    }
    &:first-of-type {
      border-top-right-radius: 4px;
      border-top-left-radius: 4px;
    }
    &:last-of-type {
      border-bottom-right-radius: 4px;
      border-bottom-left-radius: 4px;
    }
    &.active {
      background: rgba(193, 193, 193, 0.25);
    }
  }
}

.show {
  animation: show 1.3s linear;
}
</style>
