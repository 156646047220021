var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"row task-wrapper"},[(!_vm.loading)?_c('div',{staticClass:"col"},[_c('div',{staticClass:"task-text img-question"},[_c('p',{domProps:{"innerHTML":_vm._s(_vm.text)}})]),_c('div',{staticClass:"d-flex flex-wrap"},[_c('attachment',{attrs:{"attachments":_vm.task.attachments,"deleteFile":null}})],1),_c('div',[(_vm.image)?_c('div',[_c('div',{staticClass:"d-flex flex-wrap"},_vm._l((_vm.ranking),function(card,index){return _c('div',{key:index,staticClass:"col-sm-6 col-md-4 col-lg-3 mb-4"},[(_vm.disabled)?_c('div',{staticClass:"disabled"}):_vm._e(),_c('div',{staticClass:"card-image",class:{ opacity: card.value },on:{"click":function($event){return _vm.setCardValue(index)}}},[_c('div',{style:({
                  background: _vm.$store.getters.theme[0],
                  pointerEvents:
                    _vm.$store.getters.info.user.type ===
                    _vm.userTypesText.participant
                      ? 'none'
                      : 'unset'
                })},[_c('div',{staticClass:"card-value",style:({
                    background: ("" + (card.value ? '#fff' : 'rgba(0,0,0,0)'))
                  })},[_vm._v(" "+_vm._s(card.value)+" "),(card.value)?_c('span',[_vm._v("º")]):_vm._e()]),(card.img.code.length)?_c('img',{attrs:{"src":card.img.code,"alt":card.text}}):_vm._e()]),_c('p',[_vm._v(" "+_vm._s(card.text)+" ")])])])}),0)]):_c('div',[_c('div',{staticClass:"d-flex flex-column align-items-center"},_vm._l((_vm.ranking),function(card,index){return _c('div',{key:index,staticClass:"col-md-8"},[(_vm.disabled)?_c('div',{staticClass:"disabled"}):_vm._e(),_c('div',{staticClass:"card-noimage p-3 mb-2",style:({
                'border-color': _vm.$store.getters.theme[1],
                background: ("" + (card.value ? _vm.$store.getters.theme[1] : '#fff')),
                color: _vm.$store.getters.theme[3]
              }),on:{"click":function($event){return _vm.setCardValue(index)}}},[_c('p',[_vm._v(" "+_vm._s(card.text)+" ")]),_c('div',{staticClass:"card-value",style:({
                  'border-color': _vm.$store.getters.theme[1],
                  background: ("" + (card.value ? _vm.$store.getters.theme[2] : '#fff'))
                })},[_vm._v(" "+_vm._s(card.value)+" "),(card.value)?_c('span',[_vm._v("º")]):_vm._e()])])])}),0)]),_c('div',{staticClass:"comments"},[(
            _vm.task.question_object.has_custom_comment_label &&
              _vm.task.question_object.comment_label &&
              _vm.task.question_object.comment_label.length
          )?_c('label',{attrs:{"for":"comments"}},[_vm._v(_vm._s(_vm.task.question_object.comment_label))]):_c('label',{attrs:{"for":"comments"}},[_vm._v(" "+_vm._s(_vm.translation.answer_task.comments)+" ")]),_c('textarea',{directives:[{name:"model",rawName:"v-model",value:(_vm.comments),expression:"comments"}],staticClass:"form-control",attrs:{"id":"textarea","maxlength":_vm.maxCharacters,"rows":"3","max-rows":"6","disabled":_vm.task.is_answered},domProps:{"value":(_vm.comments)},on:{"input":function($event){if($event.target.composing){ return; }_vm.comments=$event.target.value}}}),_c('characterAndWordCounter',{attrs:{"count":_vm.hasWordsLimitation
              ? this.comments.length
                ? this.comments.trim().split(/\s+/).length
                : 0
              : _vm.comments.length,"max":_vm.hasWordsLimitation ? _vm.maxWords : _vm.maxCharacters,"label":_vm.hasWordsLimitation
              ? _vm.translation.answer_task.words
              : _vm.translation.answer_task.characters}})],1)])]):_vm._e(),(_vm.loading)?_c('div',{staticClass:"w-100 d-flex justify-content-center"},[_c('loader')],1):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }