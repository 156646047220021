<template>
  <div>
    <div :id="`bubble-${chartId}`">
      <Bubble
        ref="chart"
        v-if="chartData && chartOptions"
        :chart-options="chartOptions"
        :chart-data="chartData"
        :chart-id="chartId"
        :dataset-id-key="datasetIdKey"
        :plugins="plugins"
        :css-classes="cssClasses"
        :styles="styles"
        :width="width"
        :height="height"
      />
    </div>
    <div class="d-flex justify-content-end">
      <button
        :class="{ hover: !pptLoader }"
        class="btn-download"
        :disabled="pptLoader"
        @click="exportPPT"
      >
        <div
          v-if="pptLoader"
          class="d-flex justify-content-center align-items-center"
        >
          <loader2 color="#6b6b6b" class="mr-1" />
          {{ translation.replies.charts_tab.download_chart }}
        </div>
        <div v-else>
          <b-icon icon="download" aria-hidden="true"></b-icon>
          {{ translation.replies.charts_tab.download_chart }}
        </div>
      </button>
    </div>
  </div>
</template>

<script>
import { Bubble } from "vue-chartjs/legacy";
import pptxgen from "pptxgenjs";
import * as htmlToImage from "html-to-image";
import loader2 from "@/components/general/loader2.vue";

import {
  Chart as ChartJS,
  Title,
  Tooltip,
  Legend,
  PointElement,
  LinearScale
} from "chart.js";

ChartJS.register(Title, Tooltip, Legend, PointElement, LinearScale);

export default {
  name: "BubbleChart",
  components: {
    Bubble,
    loader2
  },
  props: {
    chartId: {
      type: String,
      default: "bubble-chart"
    },
    datasetIdKey: {
      type: String,
      default: "label"
    },
    width: {
      type: Number,
      default: 400
    },
    height: {
      type: Number,
      default: 400
    },
    cssClasses: {
      default: "",
      type: String
    },
    styles: {
      type: Object,
      default: () => {}
    },
    plugins: {
      type: Array,
      default: () => []
    },
    reactions: {
      required: true
    },
    colors: {
      required: true
    },
    hasOneColor: {
      required: true
    }
  },
  data() {
    return {
      chartData: null,
      chartOptions: null,
      pptLoader: false
    };
  },
  methods: {
    async getChartImage(id) {
      let node = document.querySelector(`#bubble-${id}`);
      return await htmlToImage.toPng(node);
    },
    async exportPPT() {
      this.pptLoader = true;

      let slide;
      let pres = new pptxgen();

      const image = await this.getChartImage(this.chartId);

      slide = pres.addSlide();

      slide.addImage({
        data: image,
        x: 0.2,
        y: 0.2,
        w: "80%",
        h: "60%"
      });

      pres.writeFile({ fileName: "chart" });

      this.pptLoader = false;
    },
    setData() {
      const backgroundColor = this.hasOneColor ? [this.colors[0]] : this.colors;
      let count = 0;

      const reactions = this.reactions.map((reaction, index) => {
        if (backgroundColor.length === count) count = 0;

        const data = {
          label: `${reaction.icon} ${reaction.label}`,
          data: reaction.data.map(bubble => ({
            x: bubble.label,
            y: +index + 1,
            r: bubble.value + 5,
            label: reaction.label,
            icon: reaction.icon
          })),
          backgroundColor: backgroundColor[count],
          borderWidth: 10
        };

        count++;

        return data;
      });

      this.chartData = {
        datasets: reactions
      };
    },
    setOptions() {
      this.chartOptions = {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
          legend: {
            display: false
          },
          tooltip: {
            callbacks: {
              label: tooltipItem => {
                return `${tooltipItem.label}: ${tooltipItem.raw.r - 5} (${
                  tooltipItem.raw.x
                }s)`;
              }
            }
          }
        },
        scales: {
          y: {
            min: 0,
            max: this.reactions.length + 1,
            ticks: {
              stepSize: 1,
              callback: value => {
                if (this.reactions[value - 1])
                  return `${this.reactions[value - 1].icon} ${
                    this.reactions[value - 1].label
                  }`;
                else return "";
              }
            }
          },
          x: {
            ticks: {
              callback: value => {
                const date = new Date(0);
                date.setSeconds(value);

                return date.toISOString().substr(11, 8);
              }
            }
          }
        }
      };
    }
  },
  mounted() {
    this.setOptions();
    this.setData();
  }
};
</script>

<style lang="scss" scoped>
.btn-download {
  display: flex;
  align-items: center;
  background: none;
  border: none;
  font-size: 1.2rem;
  font-weight: bold;
  color: #6b6b6b;
  margin-top: 30px;
  &:focus {
    outline: none;
  }
  svg {
    margin-right: 5px;
  }
}

.question {
  color: #6b6b6b;
  text-align: center;
  margin-bottom: 30px;
}
</style>
