var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"header pt-2",class:{
      admin:
        _vm.$route.name === 'communities' || _vm.$route.name === 'createcommunity'
    },on:{"click":_vm.closeHeader}},[_c('div',{staticClass:"container"},[_c('div',{staticClass:"row justify-content-between"},[_c('div',{staticClass:"col-3 logo"},[_c('div',[_c('router-link',{attrs:{"to":{ name: 'feed' }}},[(
                  _vm.$store.getters.community &&
                    _vm.$store.getters.community.logo &&
                    _vm.$store.getters.community.logo.length
                )?_c('img',{staticClass:"img-fluid ",attrs:{"src":_vm.$store.getters.community.logo,"alt":"Logo"}}):_c('img',{attrs:{"src":require("@/img/logo-communities.png"),"alt":"Logo"}})])],1)]),(
            _vm.$route.name !== 'communities' && _vm.$route.name !== 'createcommunity'
          )?_c('div',{staticClass:"col-7 d-flex justify-content-center"},[_c('menu-desktop',{attrs:{"subActivity":_vm.subActivity,"subAdmin":_vm.subAdmin},on:{"subActivity":function (sub) { return (_vm.subActivity = sub); },"subAdmin":function (sub) { return (_vm.subAdmin = sub); }}})],1):_vm._e(),_c('div',{staticClass:"col-2"},[_c('div',{staticClass:"d-flex align-items-center justify-content-end"},[(
                _vm.$route.name !== 'communities' &&
                  _vm.$route.name !== 'createcommunity'
              )?_c('notification'):_vm._e(),_c('div',{staticClass:"user"},[_c('div',{attrs:{"id":"dropdown-profile"}},[_c('div',{staticClass:"dropdown-button",on:{"click":function($event){return _vm.handleDrop('dropdown-profile')}}},[_c('div',{staticClass:"profile-img"},[(_vm.$store.getters.info.user.picture)?_c('img',{attrs:{"src":_vm.$store.getters.info.user.picture,"alt":"Profile Picture"}}):_c('b-icon',{staticClass:"icon-profile",attrs:{"icon":"person-fill","aria-hidden":"true"}})],1),_c('p',{staticClass:"pb-1"},[_vm._v(" "+_vm._s(_vm.$store.getters.info.user.name.split(" ")[0])+" ")])]),_c('div',{attrs:{"id":"dropdown"}},[(
                      _vm.$route.name !== 'communities' &&
                        _vm.$route.name !== 'createcommunity'
                    )?_c('router-link',{class:{
                      disabled: _vm.$store.getters.is_over
                    },attrs:{"disabled":_vm.$store.getters.is_over,"to":{ name: 'account' }}},[_c('b-icon',{staticClass:"mr-1",attrs:{"icon":"gear-fill","aria-hidden":"true"}}),_vm._v(" "+_vm._s(_vm.translation.header.account_settings_text)+" ")],1):_vm._e(),_c('a',{staticClass:"pointer",on:{"click":_vm.logout}},[_c('b-icon',{staticClass:"mr-2",attrs:{"icon":"box-arrow-right","aria-hidden":"true"}}),_vm._v(" "+_vm._s(_vm.translation.header.logout)+" ")],1)],1)])])],1)])])])]),(_vm.subActivity)?_c('sub-header-activities'):_vm._e(),(_vm.subAdmin)?_c('sub-header-admin'):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }